import { BaseDialog } from '@h2oai/ui-kit';
import { useState } from 'react';

import CommitHistoryTable from './CommitHistoryTable';

interface CommitHistoryProps {
  onClose: () => void;
}

const CommitHistoryDialog = ({ onClose }: CommitHistoryProps) => {
  const [isHidden, setIsHidden] = useState(false);

  const handleCancel = () => {
    setIsHidden(true);
    onClose();
  };

  return (
    <div>
      <BaseDialog
        title="Firewall Management Commit History"
        hidden={isHidden}
        onDismiss={handleCancel}
        content={<CommitHistoryTable />}
        minWidth="50%"
        maxHeight="50%"
      />
    </div>
  );
};

export default CommitHistoryDialog;
