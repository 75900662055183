import { DeploymentComposition } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_composition_pb';
import { RegisteredModel } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_pb';
import { GetModelVersionForExperimentRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_service_pb';
import { RegisteredModelVersionService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_version_service_connect';
import { createClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IStyle, ITextFieldProps, MessageBarType } from '@fluentui/react';
import {
  Button,
  ClassNamesFromIStyles,
  CodeBlock,
  Dropdown,
  IH2OTheme,
  Info,
  KeyValuePairEditor,
  ListRow,
  Loader,
  Slider,
  TextField,
  buttonStylesPrimary,
  buttonStylesStealth,
  loaderStylesSpinnerDefault,
  loaderStylesSpinnerTag,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import { default as SchemaForm } from '@rjsf/core';
import Form from '@rjsf/fluent-ui';
import { RJSFSchema } from '@rjsf/utils';
import { UiSchema } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import Ajv2020 from 'ajv/dist/2020';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header/Header';
import { Job, Job_State } from '../../mlops/gen/ai/h2o/mlops/batch/v1/job_pb';
import { SourceSpec } from '../../mlops/gen/ai/h2o/mlops/batch/v1/source_spec_pb';
import { KubernetesResourceSpec } from '../../mlops/gen/ai/h2o/mlops/deployer/v1/kubernetes_resource_spec_pb';
import { useMLOpsService } from '../../mlops/hooks';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { formatError } from '../../utils/utils';
// TODO: Move Orchestrator imports to global context.
import { LoaderView } from '../Orchestrator/WorkflowDetail';
import { Tag, calculateDuration } from '../Orchestrator/WorkflowTabExecutions';
import { getRandomAdjective } from './adjectives';
import { JobItem } from './BatchScoring';
import { ENDPOINTS, ROUTES } from './constants';
import { DropdownOption, capitalize } from './DeploymentTabDetail';
import { DeploymentOptions } from './Models';
import ModelSelector from './ModelSelector';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

// Import ms-Grid and other grid related styles for schema lib to work correctly.
import '../Orchestrator/ExecutorPoolDetail.css';

// Use ajv 2020 for JSON schema validation instead of default ajv 2019 - https://github.com/rjsf-team/react-jsonschema-form/issues/3750.
const validator = customizeValidator({ AjvClass: Ajv2020 });

const uiSchema: UiSchema = {
  'ui:globalOptions': {
    duplicateKeySuffixSeparator: '_',
  },
  environmentalVariables: {
    additionalProperties: {
      'ui:title': 'Value',
    },
  },
};

const OUTPUT_BUFFER_SIZE = 250;

export type SecurityType = 'passphrase_plain' | 'passphrase_hashed' | 'passphrase_hashed_deprecated' | 'no_security';
type JobDetailParams = { job_id: string; project_id: string; action: string; item_name: string };

interface IBatchScoringDetailStyles {
  form: IStyle;
  formTitle?: IStyle;
  metadata: IStyle;
  formEditor?: IStyle;
  dialogInput?: IStyle;
  tooltip?: IStyle;
  infoLabel?: IStyle;
  formSection?: IStyle;
  formSectionTitle?: IStyle;
  sourceSinkContainer?: IStyle;
  codeblock?: IStyle;
}

export const getStateProps = (state?: Job_State) => {
  switch (state) {
    case Job_State.STATE_UNSPECIFIED:
      return { name: 'Unspecified', color: 'var(--h2o-gray900)' };
    case Job_State.CREATED:
      return { name: 'Created', color: 'var(--h2o-yellow500)' };
    case Job_State.SUBMITTED:
      return { name: 'Submitted', color: 'var(--h2o-yellow500)' };
    case Job_State.SUBMISSION_FAILED:
      return { name: 'Submission failed', color: 'var(--h2o-red500)' };
    case Job_State.RUNNING:
      return { name: 'Running', color: 'var(--h2o-blue500)' };
    case Job_State.PENDING:
      return { name: 'Pending', color: 'var(--h2o-yellow500)' };
    case Job_State.FINISHED:
      return { name: 'Finished', color: 'var(--h2o-green500)' };
    case Job_State.FAILED:
      return { name: 'Failed', color: 'var(--h2o-red500)' };
    case Job_State.CANCELLED:
      return { name: 'Cancelled', color: 'var(--h2o-gray500)' };
    case Job_State.CANCELLING:
      return { name: 'Cancelling', color: 'var(--h2o-yellow900)' };
    default:
      return { name: 'Unknown', color: 'var(--h2o-gray900)' };
  }
};

const batchScoringDetailStyles = (theme: IH2OTheme): IBatchScoringDetailStyles => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: 20,
      margin: '0px 40px',
    },
    formTitle: {
      marginBottom: 8,
    },
    metadata: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    formSectionTitle: {
      textTransform: 'uppercase',
      paddingBottom: 6,
    },
    formSection: {
      maxWidth: 820,
    },
    codeblock: {
      maxHeight: 500,
      overflowY: 'auto',
    },
    // TODO: Duplicate of Orchestrator styles - remove once schema component is separated.
    formEditor: {
      width: '30%',
      minWidth: 360,
      maxWidth: 460,
      outlineWidth: 1,
      outlineStyle: 'solid',
      outlineColor: theme.semanticColors?.inputBorder,
      borderRadius: 4,
      padding: 10,
      '.ms-Label[id*="title"]': {
        // TODO: Hide the main title.
        // TODO: .ms-Grid-row - Place key-value pairs side by side.
        fontWeight: 600,
        fontSize: '14px',
      },
      'span:has(.ms-Button--commandBar)': {
        float: 'left !important',
      },
      'label[for*="_newKey-"]': {
        // TODO: Add Key styles.
      },
      'label[for$="_newKey"]': {
        // TODO: Add Value styles.
      },
      'input[name*="_newKey"]': {
        // Add input styles.
      },
      'button:has(i[data-icon-name="Delete"])': {
        marginTop: 26,
        i: {
          color: theme.semanticColors?.buttonPrimaryText,
        },
        'i:hover': {
          color: theme.semanticColors?.buttonHoverText,
        },
      },
      '.ms-Button--commandBar': {
        color: theme.semanticColors?.buttonPrimaryText,
        backgroundColor: theme.semanticColors?.buttonInlineBackground,
        borderRadius: '4px',
        ':hover': {
          color: theme.semanticColors?.buttonHoverText,
        },
      },
      '.ms-Button--commandBar i': {
        color: theme.semanticColors?.buttonPrimaryText,
      },
      '.ms-Button--commandBar:hover i': {
        color: theme.semanticColors?.buttonHoverText,
      },
      '.ms-TextField-field[readonly]': {
        color: theme.semanticColors?.textDisabled,
      },
      '.field-object': {
        display: 'flex',
        flexDirection: 'column',
      },
      marginTop: 10,
    },
    dialogInput: {
      maxWidth: 460,
      marginRight: 12,
    },
    tooltip: {
      padding: 4,
    },
    infoLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    sourceSinkContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  }),
  getListRowStyles = (theme: IH2OTheme) => ({
    details: {
      padding: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    root: {
      borderRadius: 4,
      backgroundColor: theme.semanticColors?.contentBackground,
    },
    contents: {
      paddingTop: 4,
      paddingBottom: 16,
    },
    row: {
      '&:hover': {
        background: 'transparent',
      },
      '.h2o-ListCell-root:first-child': {
        paddingLeft: 20,
      },
      height: 80,
    },
  }),
  sliderContainerStyles = {
    root: {
      maxWidth: 460,
    },
  },
  listRowColumns = [
    {
      data: {
        headerFieldName: 'displayName',
        listCellProps: {
          emptyMessage: 'No Description',
          onRenderHeader: ({ displayName }: JobItem) => (
            <h3 style={{ padding: 0, margin: 0 }}>{`Execution of ${displayName}`}</h3>
          ),
        },
      },
      key: 'title',
      fieldName: 'createdBy',
      maxWidth: 700,
      minWidth: 400,
      name: 'Title',
    },
    {
      key: 'state',
      fieldName: 'state',
      maxWidth: 200,
      minWidth: 140,
      name: 'State',
      data: {
        listCellProps: {
          onRenderText: ({ state }: JobItem) => {
            return state === Job_State.RUNNING ? (
              <Loader
                label="Running"
                styles={[loaderStylesSpinnerDefault, loaderStylesSpinnerTag, { root: { position: 'relative' } }]}
              />
            ) : (
              <Tag title={getStateProps(state).name} color={getStateProps(state).color} />
            );
          },
        },
      },
    },
    {
      key: 'startTime',
      fieldName: 'startTimeLocal',
      maxWidth: 200,
      minWidth: 130,
      name: 'Started at',
    },
    {
      key: 'endTime',
      fieldName: 'endTimeLocal',
      maxWidth: 200,
      minWidth: 130,
      name: 'Ended at',
    },
    {
      key: 'duration',
      fieldName: 'duration',
      maxWidth: 240,
      minWidth: 180,
      name: 'Duration',
    },
    {
      key: 'buttons',
      name: '',
      maxWidth: 260,
      minWidth: 180,
      data: {
        listCellProps: {
          onRenderText: ({ onCancel, onDeleteJob, state, viewOnly, actionsDisabled }: JobItem) => (
            <>
              {state !== Job_State.CANCELLED &&
              state !== Job_State.CANCELLING &&
              state !== Job_State.FAILED &&
              state !== Job_State.FINISHED &&
              state !== Job_State.SUBMISSION_FAILED &&
              state !== Job_State.STATE_UNSPECIFIED &&
              !viewOnly ? (
                <Button
                  text="Cancel"
                  onClick={onCancel}
                  style={{ color: 'var(--h2o-yellow700)', borderColor: 'var(--h2o-yellow700)' }}
                  iconProps={{ iconName: 'Cancel', style: { color: 'var(--h2o-yellow700)' } }}
                  disabled={actionsDisabled}
                />
              ) : null}
              {(state === Job_State.CANCELLED ||
                state === Job_State.FAILED ||
                state === Job_State.FINISHED ||
                state === Job_State.SUBMISSION_FAILED) &&
              !viewOnly ? (
                <Button
                  text="Delete"
                  onClick={onDeleteJob}
                  style={{
                    color: 'var(--h2o-red400)',
                    borderColor: 'var(--h2o-red400)',
                  }}
                  disabled={actionsDisabled}
                  iconProps={{ iconName: 'Delete', style: { color: 'var(--h2o-red400)' } }}
                />
              ) : null}
            </>
          ),
        },
      },
    },
  ];

export const FormSection = (props: { title: string; children: React.ReactNode }) => {
  const theme = useTheme(),
    classNames = useClassNames<IBatchScoringDetailStyles, ClassNamesFromIStyles<IBatchScoringDetailStyles>>(
      'batchScoringDetail',
      batchScoringDetailStyles(theme)
    );

  return (
    <>
      <h3 className={classNames.formSectionTitle}>{props.title}</h3>
      <div className={classNames.formSection}>{props.children}</div>
    </>
  );
};

const useCircularBuffer = (maxSize: number) => {
  const [buffer, setBuffer] = React.useState<string[]>([]);

  const addLine = (line: string) => {
    setBuffer((prev) => {
      const newBuffer = [...prev, line];
      // Removes oldest line.
      if (newBuffer.length > maxSize) newBuffer.shift();
      return newBuffer;
    });
  };

  return { buffer, addLine };
};

const BatchScoringDetail = () => {
  const theme = useTheme(),
    classNames = useClassNames<IBatchScoringDetailStyles, ClassNamesFromIStyles<IBatchScoringDetailStyles>>(
      'batchScoringDetail',
      batchScoringDetailStyles(theme)
    ),
    history = useHistory(),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID, permissions } = useProjects(),
    { buffer, addLine } = useCircularBuffer(OUTPUT_BUFFER_SIZE),
    codeBlockRef = React.createRef<HTMLDivElement>(),
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    [sourceMimeTypeOptions, setSourceMimeTypeOptions] = React.useState<DropdownOption[]>([]),
    [sinkMimeTypeOptions, setSinkMimeTypeOptions] = React.useState<DropdownOption[]>([]),
    registeredModelVersionClient = createClient(RegisteredModelVersionService, storageTransport),
    mlopsService = useMLOpsService(),
    params = useParams<JobDetailParams>(),
    [job, setJob] = React.useState<Job>(),
    [sourceSpecs, setSourceSpecs] = React.useState<SourceSpec[]>(),
    [sourceOptions, setSourceOptions] = React.useState<DropdownOption[]>([]),
    [selectedSourceSpecKey, setSelectedSourceSpecKey] = React.useState(''),
    [currentSourceSchema, setCurrentSourceSchema] = React.useState<RJSFSchema>(),
    [sourceConfig, setSourceConfig] = React.useState({}),
    [sinkConfig, setSinkConfig] = React.useState({}),
    [sinkSpecs, setSinkSpecs] = React.useState<SourceSpec[]>(),
    [sinkOptions, setSinkOptions] = React.useState<DropdownOption[]>([]),
    [selectedSinkSpecKey, setSelectedSinkSpecKey] = React.useState(''),
    [currentSinkSchema, setCurrentSinkSchema] = React.useState<RJSFSchema>(),
    isNew = React.useMemo(() => params.job_id === 'start-new', [params.job_id]),
    isBatchScoreModel = React.useMemo(
      () => params.action === 'from-model' && params.item_name,
      [params.action, params.item_name]
    ),
    [jobName, setJobName] = React.useState<string>(
      isNew && !isBatchScoreModel ? `${capitalize(getRandomAdjective())} job` : ''
    ),
    [jobOutput, setJobOutput] = React.useState<string>(),
    [showJobOutput, setShowJobOutput] = React.useState(false),
    [defaultSelectedModelIds, setDefaultSelectedModelIds] = React.useState<string[]>([]),
    [defaultSelectedExperimentIds, setDefaultSelectedExperimentIds] = React.useState<string[]>([]),
    [selectedModels, setSelectedModels] = React.useState<(RegisteredModel | undefined)[]>([]),
    [requestsConfig, setRequestsConfig] = React.useState<{ [key: string]: string }>({}),
    [limitsConfig, setLimitsConfig] = React.useState<{ [key: string]: string }>({}),
    [defaultDeploymentComposition, setDefaultDeploymentComposition] = React.useState<DeploymentComposition>(),
    [deploymentCompositionOptions, setDeploymentCompositionOptions] = React.useState<
      Partial<DeploymentOptions> | undefined
    >(undefined),
    [sourceLocation, setSourceLocation] = React.useState<string>(''),
    [sinkLocation, setSinkLocation] = React.useState<string>(''),
    [selectedSourceMimeTypeKey, setSelectedSourceMimeTypeKey] = React.useState(''),
    [selectedSinkMimeTypeKey, setSelectedSinkMimeTypeKey] = React.useState(''),
    [replicas, setReplicas] = React.useState<number>(1),
    [miniBatchSize, setMiniBatchSize] = React.useState<number>(),
    [loading, setLoading] = React.useState(true),
    [isWidgetLoading, setIsWidgetLoading] = React.useState(true),
    [isAdvancedExpanded, setAdvancedExpanded] = React.useState(false),
    [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false),
    [showSourceConfigValidation, setShowSourceConfigValidation] = React.useState(false),
    [showSinkConfigValidation, setShowSinkConfigValidation] = React.useState(false),
    [widgetRowActionsDisabled, setWidgetRowActionsDisabled] = React.useState(false),
    sourceFormRef = React.createRef<SchemaForm>(),
    sinkFormRef = React.createRef<SchemaForm>(),
    [isStreaming, setIsStreaming] = React.useState(false),
    isTheNameAutoSet = React.useRef(false),
    loadStateRef = React.useRef({
      fetchJob: false,
      createJob: false,
      fetchSourceSpecs: false,
      fetchSinkSpecs: false,
      fetchingDefaultRuntimeKubernetesResourceSpec: false,
      fetchModelVersionForExperiment: false,
      cancellingJob: false,
      deletingJob: false,
    }),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchJob &&
        !loadStateRef.current.createJob &&
        !loadStateRef.current.fetchSourceSpecs &&
        !loadStateRef.current.fetchSinkSpecs &&
        !loadStateRef.current.fetchingDefaultRuntimeKubernetesResourceSpec &&
        !loadStateRef.current.fetchModelVersionForExperiment &&
        !loadStateRef.current.cancellingJob &&
        !loadStateRef.current.deletingJob
      ) {
        setLoading(false);
      }
    },
    fetchModelVersionForExperiment = React.useCallback(
      async (experimentId: string) => {
        loadStateRef.current.fetchModelVersionForExperiment = true;
        setIsWidgetLoading(true);
        try {
          const response = await registeredModelVersionClient.getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({ experimentId })
          );
          if (response && !response.registeredModelVersion) {
            console.error('No registered model version found in the response.');
          }
          const registeredModelVersion = response?.registeredModelVersion?.[0];
          setDefaultSelectedModelIds([registeredModelVersion?.registeredModelId || '']);
          setDefaultSelectedExperimentIds([registeredModelVersion?.experimentId || '']);
        } catch (err) {
          const message = `Failed to fetch model version for experiment: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.fetchModelVersionForExperiment = false;
          evaluateLoading();
        }
      },
      [registeredModelVersionClient, addToast, setDefaultSelectedModelIds, setDefaultSelectedExperimentIds]
    ),
    fetchJob = React.useCallback(
      async (name: string) => {
        loadStateRef.current.fetchJob = true;
        setLoading(true);

        try {
          const data = await mlopsService.getJob({ name });
          const job = data.job;
          setJob(job);
          setJobName(job?.displayName || '');
          setSelectedSourceSpecKey(job?.source?.spec || '');
          setSelectedSinkSpecKey(job?.sink?.spec || '');
          setSourceConfig(JSON.parse(job?.source?.config || '{}'));
          setSinkConfig(JSON.parse(job?.sink?.config || '{}'));
          setSourceLocation(job?.source?.location || '');
          setSinkLocation(job?.sink?.location || '');
          setSelectedSourceMimeTypeKey(job?.source?.mimeType || '');
          setSourceMimeTypeOptions(
            sourceSpecs
              ?.find((spec) => spec.name === job?.source?.spec)
              ?.supportedMimeTypes?.map(
                (mimeType: string) =>
                  ({
                    key: mimeType,
                    text: mimeType,
                  } as DropdownOption)
              ) || []
          );
          setSelectedSinkMimeTypeKey(job?.sink?.mimeType || '');
          setSinkMimeTypeOptions(
            sinkSpecs
              ?.find((spec) => spec.name === job?.sink?.spec)
              ?.supportedMimeTypes?.map(
                (mimeType) =>
                  ({
                    key: mimeType,
                    text: mimeType,
                  } as DropdownOption)
              ) || []
          );
          setRequestsConfig(job?.instanceSpec?.resourceSpec?.resourceRequirement?.requests || {});
          setLimitsConfig(job?.instanceSpec?.resourceSpec?.resourceRequirement?.limits || {});
          setReplicas(job?.instanceSpec?.resourceSpec?.replicas || 1);
          // TODO: Use service to obtain default instead of hard-coding.
          setMiniBatchSize(job?.batchParameters?.miniBatchSize || 64);
          setDefaultDeploymentComposition({
            experimentId: job?.instanceSpec?.deploymentComposition?.experimentId || '',
            artifactId: '', // Obsolote. Do not use.
            deployableArtifactTypeName: job?.instanceSpec?.deploymentComposition?.artifactType || '',
            artifactProcessorName: job?.instanceSpec?.deploymentComposition?.artifactProcessor || '',
            runtimeName: job?.instanceSpec?.deploymentComposition?.runtime || '',
            runtimeImage: job?.instanceSpec?.deploymentComposition?.runtimeImage || '',
          } as DeploymentComposition);
          setDefaultSelectedExperimentIds([job?.instanceSpec?.deploymentComposition?.experimentId || '']);

          fetchModelVersionForExperiment(job?.instanceSpec?.deploymentComposition?.experimentId || '');
        } catch (err) {
          const message = `Failed to fetch job: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
        loadStateRef.current.fetchJob = false;
        evaluateLoading();
      },
      [ACTIVE_PROJECT_ID, mlopsService, sourceSpecs, sinkSpecs]
    ),
    createJob = React.useCallback(async () => {
      if (!deploymentCompositionOptions?.firstModelDeploymentComposition) return;
      loadStateRef.current.createJob = true;
      setLoading(true);
      setIsSubmitDisabled(true);

      try {
        const createJobBody = {
          parent: `workspaces/${ACTIVE_PROJECT_ID}`,
          job: {
            displayName: jobName,
            source: {
              spec: selectedSourceSpecKey,
              config: JSON.stringify(sourceConfig),
              location: sourceLocation,
              mimeType: selectedSourceMimeTypeKey,
            },
            sink: {
              spec: selectedSinkSpecKey,
              config: JSON.stringify(sinkConfig),
              location: sinkLocation,
              mimeType: selectedSinkMimeTypeKey,
            },
            instanceSpec: {
              deploymentComposition: {
                ...deploymentCompositionOptions.firstModelDeploymentComposition,
                // TODO: Figure out why property names are different compared to old APIs.
                artifactType: deploymentCompositionOptions.firstModelDeploymentComposition.deployableArtifactTypeName,
                artifactProcessor: deploymentCompositionOptions.firstModelDeploymentComposition.artifactProcessorName,
                runtime: deploymentCompositionOptions.firstModelDeploymentComposition.runtimeName,
              },
              resourceSpec: {
                resourceRequirement: {
                  requests: requestsConfig,
                  limits: limitsConfig,
                },
                replicas,
                // TODO: Add support in the future.
                minimalAvailableReplicas: undefined,
              },
              environmentVariables: {}, // TODO: Key-value pairs
            },
            batchParameters: {
              miniBatchSize,
            },
            // TODO: Add support in the future.
            // modelRequestParameters: {
            //   requestContributions: ModelRequestParameters_ShapleyType.SHAPLEY_TYPE_UNSPECIFIED,
            //   requestPredictionIntervals: false,
            // },
          },
        };

        await mlopsService.createJob(createJobBody);
        history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.BATCH_SCORING}`);
      } catch (err) {
        const message = `Failed to create job: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.createJob = false;
        evaluateLoading();
        setIsSubmitDisabled(false);
      }
    }, [
      ACTIVE_PROJECT_ID,
      permissions,
      mlopsService,
      jobName,
      deploymentCompositionOptions,
      requestsConfig,
      limitsConfig,
      sourceConfig,
      sourceSpecs,
      selectedSourceMimeTypeKey,
      sourceLocation,
      sinkConfig,
      sinkSpecs,
      selectedSinkMimeTypeKey,
      sinkLocation,
      replicas,
    ]),
    listSourceSpecs = React.useCallback(async () => {
      loadStateRef.current.fetchSourceSpecs = true;
      setLoading(true);

      try {
        const data = await mlopsService.listSourceSpecs({});
        if (data && !data.sourceSpecs) {
          throw new Error('No source specs found in the response.');
        }
        setSourceSpecs(data.sourceSpecs);
        setSourceOptions(
          (data.sourceSpecs || []).map(
            (sourceSpec) =>
              ({
                key: sourceSpec.name,
                text: sourceSpec.displayName,
                data: sourceSpec,
              } as DropdownOption)
          )
        );
        if (isNew) {
          setSelectedSourceSpecKey(data.sourceSpecs?.[0]?.name || '');
          setSourceMimeTypeOptions(
            data.sourceSpecs?.[0]?.supportedMimeTypes?.map(
              (mimeType) =>
                ({
                  key: mimeType,
                  text: mimeType,
                } as DropdownOption)
            ) || []
          );
        }
      } catch (err) {
        const message = `Failed to fetch source specs: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.fetchSourceSpecs = false;
        evaluateLoading();
      }
    }, [mlopsService, isNew]),
    listSinkSpecs = React.useCallback(async () => {
      loadStateRef.current.fetchSinkSpecs = true;
      setLoading(true);

      try {
        const data = await mlopsService.listSinkSpecs({});
        if (data && !data.sinkSpecs) {
          throw new Error('No sink specs found in the response.');
        }
        setSinkSpecs(data.sinkSpecs);
        setSinkOptions(
          (data.sinkSpecs || []).map(
            (sinkSpec) =>
              ({
                key: sinkSpec.name,
                text: sinkSpec.displayName,
                data: sinkSpec,
              } as DropdownOption)
          )
        );
        if (isNew) {
          setSelectedSinkSpecKey(data.sinkSpecs?.[0]?.name || '');
          setSinkMimeTypeOptions(
            data.sinkSpecs?.[0]?.supportedMimeTypes?.map(
              (mimeType) =>
                ({
                  key: mimeType,
                  text: mimeType,
                } as DropdownOption)
            ) || []
          );
        }
      } catch (err) {
        const message = `Failed to fetch sink specs: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.fetchSinkSpecs = false;
        evaluateLoading();
      }
    }, [mlopsService, isNew]),
    getDefaultRuntimeKubernetesResourceSpec = React.useCallback(async () => {
      loadStateRef.current.fetchingDefaultRuntimeKubernetesResourceSpec = true;
      setIsWidgetLoading(true);
      try {
        const response = await mlopsService.getDefaultRuntimeKubernetesResourceSpec({});
        const resourceSpec: KubernetesResourceSpec | undefined = response?.defaultRuntimeKubernetesResourceSpec;
        if (response && !resourceSpec) {
          console.error('No default runtime Kubernetes resource spec found in the response.');
        }
        setRequestsConfig(resourceSpec?.kubernetesResourceRequirement?.requests || {});
        setLimitsConfig(resourceSpec?.kubernetesResourceRequirement?.limits || {});
      } catch (err) {
        const message = `Failed to fetch default runtime kubernetes resource spec: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.fetchingDefaultRuntimeKubernetesResourceSpec = false;
        evaluateLoading();
      }
    }, [mlopsService]),
    cancelJob = React.useCallback(
      async (id: string) => {
        loadStateRef.current.cancellingJob = true;
        setWidgetRowActionsDisabled(true);
        try {
          await mlopsService.cancelJob({ name: id });
          void fetchJob(id);
        } catch (err) {
          const message = `Failed to cancel job: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.cancellingJob = false;
          evaluateLoading();
          setWidgetRowActionsDisabled(false);
        }
      },
      [mlopsService, fetchJob, addToast]
    ),
    deleteJob = React.useCallback(
      async (id: string) => {
        loadStateRef.current.deletingJob = true;
        setWidgetRowActionsDisabled(true);
        try {
          await mlopsService.deleteJob({ name: id });
          history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.BATCH_SCORING}`);
        } catch (err) {
          const message = `Failed to delete job: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.deletingJob = false;
          evaluateLoading();
          setWidgetRowActionsDisabled(false);
        }
      },
      [mlopsService]
    ),
    streamJobOutput = React.useCallback(
      async (id: string) => {
        setIsStreaming(true);
        const response = await mlopsService.getJobOutput({ name: id });
        if (!response?.body) {
          throw new Error('No response body');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        let buffer = '';
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          buffer += decoder.decode(value, { stream: true });

          let boundary;
          while ((boundary = buffer.indexOf('\n')) >= 0) {
            // Assuming each JSON is newline-delimited.
            const chunk = buffer.slice(0, boundary).trim();
            buffer = buffer.slice(boundary + 1);

            if (chunk) {
              try {
                const jobOutput = JSON.parse(chunk);
                addLine(`${jobOutput.result.line}\n${jobOutput.result.error}`);
              } catch (err) {
                console.error('Failed to parse job output:', err);
              }
            }
          }
        }
        setIsStreaming(false);
      },
      [mlopsService]
    ),
    onActionClick = () => {
      if (
        !jobName ||
        !selectedSourceSpecKey ||
        !sourceConfig ||
        !selectedSinkSpecKey ||
        !sinkConfig ||
        !selectedSourceMimeTypeKey ||
        !sourceLocation ||
        !selectedSinkMimeTypeKey ||
        !sinkLocation ||
        (currentSourceSchema && !validator.isValid(currentSourceSchema, sourceConfig, {}))
      ) {
        // TODO: Merge all validation checks.
        setShowSourceConfigValidation(true);
        setShowSinkConfigValidation(true);
        return;
      }
      void createJob();
    },
    keyValuePairEditorStyles = React.useMemo(
      () => ({
        root: {
          backgroundColor: theme.semanticColors?.contentBackground,
          maxWidth: 460,
          border: `1px solid ${theme.semanticColors?.inputBorder}`,
          borderRadius: 4,
        },
      }),
      [theme.semanticColors?.contentBackground, theme.semanticColors?.inputBorder]
    );

  React.useEffect(() => {
    // TODO: Check if streaming is available for other states as well.
    if (!isNew && job?.state === Job_State.RUNNING && !isStreaming && showJobOutput) streamJobOutput(job?.name || '');
  }, [job?.state, job?.name, isNew, streamJobOutput, isStreaming, showJobOutput]);

  React.useEffect(() => {
    if (!isStreaming) return;
    // Updates the main state every 3 seconds.
    const interval = setInterval(() => {
      if (buffer) {
        setJobOutput(buffer.join('\n'));
        // Scrolls to the bottom of the code block on each update.
        if (codeBlockRef.current) {
          codeBlockRef.current.scrollTop = codeBlockRef.current.scrollHeight;
        }
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [buffer, isStreaming, showJobOutput]);

  React.useEffect(() => {
    // Auto-sets the job name to the model name if it's a new batch scoring job created from list of models.
    if (isTheNameAutoSet.current) return;
    if (isNew && isBatchScoreModel && selectedModels?.[0]) {
      setJobName(`${selectedModels?.[0]?.displayName} job` || '');
      isTheNameAutoSet.current = true;
    }
  }, [isNew, isBatchScoreModel, selectedModels]);

  React.useEffect(() => {
    void listSourceSpecs();
    void listSinkSpecs();
  }, [listSourceSpecs, listSinkSpecs]);

  React.useEffect(() => {
    setCurrentSourceSchema(
      JSON.parse(sourceSpecs?.find((spec) => spec.name === selectedSourceSpecKey)?.schema || '{}')
    );
  }, [selectedSourceSpecKey]);

  React.useEffect(() => {
    setCurrentSinkSchema(JSON.parse(sinkSpecs?.find((spec) => spec.name === selectedSinkSpecKey)?.schema || '{}'));
  }, [selectedSinkSpecKey]);

  React.useEffect(() => {
    if (sourceFormRef?.current) sourceFormRef.current?.submit();
  }, [currentSourceSchema, sourceFormRef.current]);

  React.useEffect(() => {
    if (sinkFormRef?.current) sinkFormRef.current?.submit();
  }, [currentSinkSchema, sinkFormRef.current]);

  React.useEffect(() => {
    if (isNew) void getDefaultRuntimeKubernetesResourceSpec();
  }, [isNew]);

  React.useEffect(() => {
    if (params.job_id && !isNew && ACTIVE_PROJECT_ID) {
      void fetchJob(`workspaces/${ACTIVE_PROJECT_ID}/jobs/${params.job_id}`);
    } else {
      loadStateRef.current.fetchJob = false;
      evaluateLoading();
    }
  }, [params.job_id, params.project_id, fetchJob, isNew, ACTIVE_PROJECT_ID]);

  React.useEffect(() => {
    // Collapses advanced settings when model is unselected.
    if (!selectedModels?.[0] && isAdvancedExpanded) {
      setAdvancedExpanded(false);
    }
  }, [selectedModels]);

  return (
    <PageWrapper>
      <Header customPageTitle={isNew ? 'Start new job' : job?.displayName || 'Job detail'} />
      {loading ? (
        <LoaderView loaderText="Loading batch scoring detail..." />
      ) : (
        <>
          <div className={classNames.form}>
            {job?.state ? (
              <>
                <ListRow
                  columns={listRowColumns}
                  data={{
                    ...job,
                    status: getStateProps(job?.state).name,
                    startTimeLocal: job?.startTime ? new Date(job?.startTime).toLocaleString() : 'N/A',
                    endTimeLocal: job?.endTime ? new Date(job?.endTime).toLocaleString() : 'N/A',
                    duration: calculateDuration(job?.startTime, job?.endTime),
                    createdBy: `Created by ${job?.creator}`,
                    viewOnly: !permissions?.canWrite,
                    onCancel: () => void cancelJob(job?.name || ''),
                    onDeleteJob: () => void deleteJob(job?.name || ''),
                    actionsDisabled: widgetRowActionsDisabled,
                  }}
                  contents={
                    !isNew && job?.state === Job_State.RUNNING ? (
                      <>
                        <Button
                          iconName="ChevronRight"
                          text="Job output"
                          onClick={(ev) => {
                            const icon = (ev.currentTarget as HTMLElement)?.querySelector('.ms-Icon');
                            if (icon) {
                              setShowJobOutput(!icon.classList?.contains('rotate90'));
                              icon.classList.toggle('rotate90');
                            }
                          }}
                          styles={[
                            buttonStylesStealth,
                            {
                              icon: {
                                fontSize: 14,
                                transitionDuration: '300ms',
                                transitionProperty: 'transform',
                              },
                              root: {
                                marginTop: 10,
                                '.ms-Icon.rotate90': {
                                  transform: 'rotate(90deg)',
                                },
                                backgroundColor: 'transparent',
                              },
                            },
                          ]}
                        />
                        {showJobOutput ? (
                          <>
                            <p>{`Output is limited to last ${OUTPUT_BUFFER_SIZE} lines.`}</p>
                            <div className={classNames.codeblock} ref={codeBlockRef}>
                              <CodeBlock>{jobOutput || 'Streaming output...'}</CodeBlock>
                            </div>
                          </>
                        ) : null}
                      </>
                    ) : undefined
                  }
                  styles={getListRowStyles(theme)}
                />
                <div style={{ height: 25 }}></div>
              </>
            ) : null}
            <TextField
              label="Job name"
              required
              onChange={(_ev, value) => {
                setJobName(value || '');
              }}
              value={jobName}
              disabled={!isNew || !permissions.canWrite}
              styles={{ root: { maxWidth: 360 } }}
            />
            <FormSection title="Model">
              <ModelSelector
                selectedModels={selectedModels}
                onSelectedModelsChange={setSelectedModels}
                defaultSelectedVersionId={isBatchScoreModel ? params.item_name : undefined}
                defaultSelectedModelIds={defaultSelectedModelIds}
                defaultSelectedExperimentIds={defaultSelectedExperimentIds}
                defaultDeploymentCompositionA={defaultDeploymentComposition}
                deploymentType={'single_model'}
                onChangeOptions={setDeploymentCompositionOptions}
                onContentLoadStart={() => setIsWidgetLoading(true)}
                onContentLoadFinish={() => setIsWidgetLoading(false)}
                readOnly={!isNew || !permissions.canWrite}
              />
              {selectedModels?.[0] ? (
                <>
                  <Button
                    iconName="ChevronRight"
                    text="Advanced settings"
                    onClick={(ev) => {
                      const icon = (ev.currentTarget as HTMLElement)?.querySelector('.ms-Icon');
                      if (icon) {
                        setAdvancedExpanded(!icon.classList?.contains('rotate90'));
                        icon.classList.toggle('rotate90');
                      }
                    }}
                    styles={[
                      buttonStylesStealth,
                      {
                        icon: {
                          fontSize: 14,
                          transitionDuration: '300ms',
                          transitionProperty: 'transform',
                        },
                        root: {
                          marginTop: 10,
                          '.ms-Icon.rotate90': {
                            transform: 'rotate(90deg)',
                          },
                          backgroundColor: 'transparent',
                        },
                      },
                    ]}
                  />
                  <span
                    // Hides form istead of removing it to keep form data for submission when advanced settings are collapsed.
                    style={isAdvancedExpanded ? undefined : { visibility: 'hidden', position: 'fixed' }}
                  >
                    <TextField
                      type="number"
                      label="Batch size"
                      value={`${miniBatchSize}`}
                      // TODO: Use service to obtain default instead of hard-coding.
                      placeholder="64"
                      onChange={(_ev, value) => {
                        setMiniBatchSize(parseInt(value || '0'));
                      }}
                      className={classNames.dialogInput}
                      styles={{ field: { paddingRight: 0 } }}
                      aria-labelledby="max-queue-field"
                      readOnly={!isNew || !permissions.canWrite}
                      onRenderLabel={(props?: ITextFieldProps) => (
                        <div id={'max-queue-field'} className={classNames.infoLabel}>
                          {props?.label}
                          <Info isTooltip className={classNames.tooltip}>
                            The number of the records send in one request to scorer. If not provided 64 will be used as
                            default.
                          </Info>
                        </div>
                      )}
                    />
                    <FormSection title="Kubernetes options">
                      {/* HACK: Render slider after load as it does not react on value changed externally.  */}
                      {isNew || (!isNew && !loading) ? (
                        <Slider
                          label="Replicas"
                          value={replicas}
                          min={1}
                          max={5}
                          onChange={(value) => setReplicas(Math.round(value))}
                          step={1}
                          disabled={!isNew || !permissions.canWrite}
                          sliderContainerStyles={sliderContainerStyles}
                        />
                      ) : null}
                      <p className={classNames.formTitle}>Requests</p>
                      <KeyValuePairEditor
                        config={requestsConfig}
                        onUpdateConfig={(newConfig) => setRequestsConfig(newConfig)}
                        styles={keyValuePairEditorStyles}
                        readOnly={!isNew || !permissions.canWrite}
                      />
                      <p className={classNames.formTitle}>Limits</p>
                      <KeyValuePairEditor
                        config={limitsConfig}
                        onUpdateConfig={(newConfig) => setLimitsConfig(newConfig)}
                        styles={keyValuePairEditorStyles}
                        readOnly={!isNew || !permissions.canWrite}
                      />
                    </FormSection>
                  </span>
                </>
              ) : null}
            </FormSection>
            <div style={{ height: 25 }}></div>
            <div className={classNames.sourceSinkContainer}>
              <div>
                <FormSection title="Source">
                  <Dropdown
                    label="Source spec"
                    selectedKey={selectedSourceSpecKey}
                    onChange={(_ev, option) => {
                      setSelectedSourceSpecKey(`${option?.key}`);
                      setSourceMimeTypeOptions(
                        option?.data?.supportedMimeTypes?.map(
                          (mimeType: string) =>
                            ({
                              key: mimeType,
                              text: mimeType,
                            } as DropdownOption)
                        )
                      );
                    }}
                    options={sourceOptions}
                    disabled={!isNew || !permissions.canWrite}
                    styles={{ root: { maxWidth: 360 } }}
                  />
                  {selectedSourceSpecKey && currentSourceSchema ? (
                    <Form
                      ref={sourceFormRef}
                      // Keep the id reused from Orchestrator in order for stylesheet reused from Orchestrator to work.
                      id={'executor-pool-config-form'}
                      validator={validator}
                      schema={currentSourceSchema}
                      formData={sourceConfig}
                      onChange={(schema) => {
                        setSourceConfig(schema.formData);
                      }}
                      liveValidate={isNew}
                      showErrorList={showSourceConfigValidation ? 'bottom' : false}
                      readonly={!permissions.canWrite || !isNew}
                      className={classNames.formEditor}
                      uiSchema={uiSchema}
                    >
                      <>{/* Specify empty body to hide form submit button. */}</>
                    </Form>
                  ) : null}
                  <Dropdown
                    label="Source MIME type"
                    required
                    selectedKey={selectedSourceMimeTypeKey}
                    onChange={(_ev, option) => {
                      setSelectedSourceMimeTypeKey(`${option?.key}`);
                    }}
                    options={sourceMimeTypeOptions}
                    disabled={!isNew}
                    styles={{ root: { maxWidth: 360 } }}
                  />
                  <TextField
                    label="Source location"
                    required
                    disabled={!isNew || !permissions.canWrite}
                    onChange={(_ev, value) => {
                      setSourceLocation(value || '');
                    }}
                    value={sourceLocation}
                    styles={{ root: { maxWidth: 360 } }}
                  />
                </FormSection>
                <div style={{ height: 25 }}></div>
              </div>
              <div>
                <FormSection title="Sink">
                  <Dropdown
                    label="Sink spec"
                    selectedKey={selectedSinkSpecKey}
                    onChange={(_ev, option) => {
                      setSelectedSinkSpecKey(`${option?.key}`);
                      setSinkMimeTypeOptions(
                        option?.data?.supportedMimeTypes?.map(
                          (mimeType: string) =>
                            ({
                              key: mimeType,
                              text: mimeType,
                            } as DropdownOption)
                        )
                      );
                    }}
                    options={sinkOptions}
                    disabled={!isNew || !permissions.canWrite}
                    styles={{ root: { maxWidth: 360 } }}
                  />
                  {selectedSinkSpecKey && currentSinkSchema ? (
                    <Form
                      ref={sinkFormRef}
                      // Keep the id reused from Orchestrator in order for stylesheet reused from Orchestrator to work.
                      id={'executor-pool-config-form'}
                      validator={validator}
                      schema={currentSinkSchema}
                      formData={sinkConfig}
                      onChange={(schema) => {
                        setSinkConfig(schema.formData);
                      }}
                      liveValidate={isNew}
                      showErrorList={showSinkConfigValidation ? 'bottom' : false}
                      readonly={!permissions.canWrite || !isNew}
                      className={classNames.formEditor}
                      uiSchema={uiSchema}
                    >
                      <>{/* Specify empty body to hide form submit button. */}</>
                    </Form>
                  ) : null}
                  <Dropdown
                    label="Sink MIME type"
                    required
                    selectedKey={selectedSinkMimeTypeKey}
                    onChange={(_ev, option) => {
                      setSelectedSinkMimeTypeKey(`${option?.key}`);
                    }}
                    options={sinkMimeTypeOptions}
                    disabled={!isNew}
                    styles={{ root: { maxWidth: 360 } }}
                  />
                  <TextField
                    label="Sink location"
                    required
                    disabled={!isNew || !permissions.canWrite}
                    onChange={(_ev, value) => {
                      setSinkLocation(value || '');
                    }}
                    value={sinkLocation}
                    styles={{ root: { maxWidth: 360 } }}
                  />
                </FormSection>
                <div style={{ height: 25 }}></div>
              </div>
            </div>
            {permissions.canWrite && isNew ? (
              <Button
                onClick={onActionClick}
                // TODO: Handle disabled based on form validation.
                disabled={
                  isWidgetLoading ||
                  !selectedModels.length ||
                  !jobName ||
                  !selectedSourceMimeTypeKey ||
                  !sourceLocation ||
                  !selectedSinkMimeTypeKey ||
                  !sinkLocation ||
                  isSubmitDisabled
                }
                styles={buttonStylesPrimary}
              >
                {'Start job'}
              </Button>
            ) : null}
          </div>
        </>
      )}
    </PageWrapper>
  );
};

export default BatchScoringDetail;
