import { FontWeights, IDetailsListStyles } from '@fluentui/react';
import { FontSizes, IH2OTheme, IItemStyles, Sizes, Transitions } from '@h2oai/ui-kit';

import { tagStyle } from '../../common/common.styles';

export const UserManagementTopStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'relative',
};

export const iconBtnStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const sidePanelBtnStyle = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'flex-end',
};

export const sidePanelCancelBtnStyle = {
  marginRight: 10,
};

export const sidePanelStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
};

export const tableAreaStyle = {
  display: 'flex',
};

export const userTableStyle = {
  width: '50%',
  marginRight: '20px',
};

export const adminTableStyle = {
  width: '50%',
};

export const menuBtnStyle = {
  marginLeft: '10px',
};

export const uploadTitleAreaStyle = {
  marginTop: Sizes.padding,
};

export const uploadTitleStyle = {
  root: { fontWeight: FontWeights.bold },
};

export const bulkImportStyle = {
  display: 'none',
};

export const sampleTextStyle = {
  padding: Sizes.padding,
};

export const sampleAreaStyle = (theme: IH2OTheme) => ({
  backgroundColor: theme.palette?.gray200,
  border: `1px solid ${theme.palette?.gray600}`,
  padding: Sizes.padding,
  marginTop: '10px',
});

export const uploadAreaStyle = (dragOver: boolean, theme: IH2OTheme): React.CSSProperties => ({
  border: `2px dashed ${dragOver ? theme.palette?.primary500 : theme.palette?.gray500}`,
  borderRadius: Sizes.borderRadius,
  padding: Sizes.padding,
  textAlign: 'center',
  cursor: 'pointer',
  backgroundColor: dragOver ? theme.palette?.primary100 : theme.palette?.gray200,
  marginTop: 10,
  transition: `background-color ${Transitions.short}, border ${Transitions.short}`,
});

export const tableContainerStyles = (theme: IH2OTheme): React.CSSProperties => ({
  backgroundColor: theme.palette?.white,
  padding: Sizes.padding,
  height: '550px',
  overflowY: 'hidden',
});

export const actionContainerStyles = (): React.CSSProperties => ({
  display: 'flex' as const,
  justifyContent: 'space-between',
  marginBottom: 16,
});

export const actionButtonContainerStyles = (): React.CSSProperties => ({
  display: 'flex' as const,
  gap: '8px',
});

export const searchStyle = {
  width: '300px',
};

export const userManagementTableStyles = (theme: IH2OTheme): Partial<IDetailsListStyles> => ({
  root: {
    selectors: {
      '.ms-DetailsHeader': {
        padding: Sizes.padding,
        paddingRight: 0,
        paddingLeft: 0,
      },
      '.ms-DetailsHeader-cell': {
        borderRight: '1px solid white',
        color: theme.palette?.gray700,
      },
      '.ms-DetailsRow-cell': {
        display: 'flex',
        alignItems: 'center',
        fontSize: FontSizes.xsmall,
        color: theme.palette?.gray600,
        padding: 0,
      },
      '.ms-DetailsRow:hover': {
        backgroundColor: theme.palette?.yellow100,
      },
      '.ms-DetailsRow.is-selected': {
        backgroundColor: theme.palette?.primary200,
      },
      '.ms-Check.is-checked': {
        selectors: {
          '::before': {
            backgroundColor: theme.palette?.primary600,
          },
          '.ms-Check-circle': {
            color: theme.palette?.primary600,
          },
          '.ms-Check-check': {
            color: `${theme.palette?.white} !important`,
          },
        },
      },
      '.ms-Check:hover .ms-Check-circle': {
        borderColor: `${theme.palette?.primary600} !important`,
      },
      '.ms-DetailsList-contentWrapper': {
        overflowY: 'auto',
        height: '450px',
      },
    },
  },
});

export const TableBackgroundStyle = (theme: IH2OTheme) => ({
  height: '300px',
  overflowY: 'auto' as const,
  backgroundColor: theme.palette?.white,
  padding: Sizes.padding,
});

export const pageTopButtonStyle: React.CSSProperties = {
  position: 'relative',
  paddingBottom: Sizes.buttonPaddingTopBottom,
};

export const stagedUserStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.blue200,
    color: theme.semanticColors?.categoryText,
  },
});

export const pendingActionUserStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.yellow200,
    color: theme.semanticColors?.categoryText,
  },
});

export const activeUserStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.green100,
    color: theme.semanticColors?.categoryText,
  },
});

export const passwordResetUserStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.yellow400,
    color: theme.semanticColors?.categoryText,
  },
});

export const passwordExpiredUserStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.red100,
    color: theme.semanticColors?.categoryText,
  },
});

export const lockedOutUserStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.yellow300,
    color: theme.semanticColors?.categoryText,
  },
});

export const suspendedUserStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.red200,
    color: theme.semanticColors?.categoryText,
  },
});

export const deactivatedUserStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.gray300,
    color: theme.semanticColors?.categoryText,
  },
});

export const unkownUserStatusStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.gray300,
    color: theme.semanticColors?.categoryText,
  },
});

export const confirmationTextStyles = {
  paddingBottom: Sizes.padding,
};

export const confirmationTableStyles = {
  maxHeight: '400px',
  overflowY: 'auto' as const,
  paddingBottom: Sizes.padding,
};
