import { useCallback, useEffect, useRef, useState } from 'react';

import { useEngine } from '../../../../../aiem/engine/hooks';
import { calculatedEngineSizes } from '../../../../../aiem/engine/services';
import { H2OEngineProfile } from '../../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import { H2OEngineSize, H2OEngineSizeLimits } from '../../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_service_pb';
import { BigIntString } from '../../../../../aiem/gen/runtime';
import { bytesToGibibytes, gibibytesToBytes } from '../../../../../aiem/utils';
import { H2OEngineProfileOptionKeyType, defaultH2ORawEngineSizeRequest } from '../../../constants';
import { MetadataLabelCell, MetadataRow, MetadataValueCell } from '../../MetadataTable/MetadataTable';
import SpinnerWithTooltip from '../../SpinnerWithTooltip/SpinnerWithTooltip';

const fetchEngineSize = async (basePath: string, datasetSize: number, limits: H2OEngineSizeLimits) => {
  const method = calculatedEngineSizes[H2OEngineProfileOptionKeyType.raw].calculate;
  const size: H2OEngineSize = await method(basePath, '', {
    datasetSizeBytes: datasetSize.toString(),
    limits,
  } as any);
  return size;
};

interface ConfigureCustomEngineSizeProps {
  constraintSet?: H2OEngineProfile;
  modifyEngine: any;
}

const clampValue = (value: number, min?: number, max?: number): number => {
  if (value < (min ?? Infinity)) {
    return min ?? value;
  }
  if (value > (max ?? -Infinity)) {
    return max ?? value;
  }
  return value;
};

export default function ConfigureCustomEngineSize({ constraintSet, modifyEngine }: ConfigureCustomEngineSizeProps) {
  const { basePath } = useEngine();
  const mostRecentRequestTime = useRef<number>(0);
  const [datasetSize, setDatasetSize] = useState(bytesToGibibytes(defaultH2ORawEngineSizeRequest.datasetSizeBytes));

  const calculateEngineSize = useCallback(
    async (datasetSize) => {
      const thisRequestTime = Date.now();
      const limits: H2OEngineSizeLimits = {
        memoryBytesPerNodeMin: constraintSet?.memoryBytesConstraint.min as BigIntString,
        memoryBytesPerNodeMax:
          constraintSet?.memoryBytesConstraint.max || (constraintSet?.memoryBytesConstraint.default as BigIntString),
        nodeCountMin: constraintSet?.nodeCountConstraint.min as BigIntString,
        nodeCountMax:
          constraintSet?.nodeCountConstraint.max || (constraintSet?.nodeCountConstraint.default as BigIntString),
      };
      mostRecentRequestTime.current = thisRequestTime;
      const size = await fetchEngineSize(basePath, datasetSize, limits);

      if (size && mostRecentRequestTime.current === thisRequestTime) {
        modifyEngine({
          ...(size.nodeCount
            ? {
                nodeCount: clampValue(
                  Number(size.nodeCount),
                  Number(constraintSet?.nodeCountConstraint?.min),
                  Number(constraintSet?.nodeCountConstraint?.max)
                ),
              }
            : {}),
          ...(size.memoryBytes
            ? { memoryBytes: clampValue(Number(size.memoryBytes), Number(constraintSet?.memoryBytesConstraint?.min)) }
            : {}),
        });
      }
    },
    [basePath, fetchEngineSize, constraintSet]
  );

  const onChangeDatasetSize = useCallback(
    (_, value: string) => {
      setDatasetSize(value);
      void calculateEngineSize(gibibytesToBytes(value));
    },
    [calculateEngineSize, setDatasetSize]
  );

  useEffect(() => {
    /* Do not add datasetSize to this dependency array. This effect is supposed 
       to occur only once after the constraints are loaded. */
    if (constraintSet) {
      void calculateEngineSize(gibibytesToBytes(datasetSize));
    }
  }, [constraintSet]);

  return (
    <MetadataRow>
      <MetadataLabelCell colspan={3}>Dataset Size</MetadataLabelCell>
      <MetadataValueCell loading={!constraintSet}>
        <SpinnerWithTooltip
          onChange={onChangeDatasetSize}
          value={Number(datasetSize) || 0}
          min={1}
          max={100000000}
          tooltip="How large is your dataset?"
          suffix="GiB"
        />
      </MetadataValueCell>
    </MetadataRow>
  );
}
