import { Dropdown, IDropdownOption, ITextFieldProps, Label, Stack, TextField, Toggle } from '@fluentui/react';
import { IStyleSetBase } from '@fluentui/style-utilities';
import { BasicList, IItemProps, Item, TextListEditor, basicListStylesInput, itemStylesDropdown } from '@h2oai/ui-kit';
import { useState } from 'react';

import { EntityField, EntityType } from '../../../aiem/entity/types';
import { bytesToGibibytes, gibibytesToBigIntStringBytes } from '../../../aiem/utils';
import { usePermissions } from '../../../authz/providers/PermissionsProvider/PermissionsProvider';
import { FluentNumberField } from '../../../components/AIEnginesPage/components/FluentNumberField/FluentNumberField';
import { LabelIconTooltip } from '../../../components/AIEnginesPage/components/LabelIconTooltip/LabelIconTooltip';
import { ICodeAreaStyles } from '../../../components/CodeArea/CodeArea.styles';
import { useFormAttributes } from '../../../utils/utils';
import { defaultEntityFormRowStyles } from './DefaultEntityFormRowStyles';
import { LabelAndDescription } from './LabelAndDescription';

export interface EntityFieldInputProps<EntityModel> {
  key?: string;
  field: EntityField<EntityModel>;
  model: EntityModel;
  onChange?: (name: keyof EntityModel, value: any) => void;
  onChangeMultiple?: (partialModel: Partial<EntityModel>) => void;
  entityType: EntityType;
  isCreate?: boolean;
  validate?: (valid: boolean) => void;
  disabled?: boolean;
  largeLabel?: boolean;
  compact?: boolean;
  textAreaStyles?: Partial<ICodeAreaStyles>;
  required?: boolean;
  rootStyles?: IStyleSetBase;
  editorWidth?: number | string;
}

interface NumberEntityFieldInputProps<EntityModel> extends EntityFieldInputProps<EntityModel> {
  convertToGibibytes?: boolean;
}

type FormRowProps = { singleRow?: boolean; children: React.ReactNode };

export const FormRow = ({ singleRow = false, children }: FormRowProps) => {
  const { inputContainerProps, inputRowProps, singleRowInputRowProps } = useFormAttributes();
  return (
    <Stack {...(singleRow ? singleRowInputRowProps : inputRowProps)} style={defaultEntityFormRowStyles}>
      <Stack {...inputContainerProps}>{children}</Stack>
    </Stack>
  );
};

export function TextEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  disabled = false,
  largeLabel = false,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label, description, required, inputDefaultValue } = field;
  const [fieldValue, setFieldValue] = useState<string>((model as any)[name] as string);
  const fieldComponent = (
    <TextField
      styles={{ root: { width: 300 } }}
      required={required}
      disabled={disabled}
      autoFocus
      label={largeLabel ? undefined : label}
      data-test={`admin-setting-${String(name)}-input`}
      value={fieldValue ?? (inputDefaultValue || '')}
      onChange={(_ev, value) => {
        setFieldValue(value || '');
        onChange && onChange(name, value);
      }}
      onRenderLabel={
        largeLabel
          ? undefined
          : (
              labelProps: ITextFieldProps | undefined,
              defaultRender: ((props?: ITextFieldProps | undefined) => JSX.Element | null) | undefined
            ) => (
              <LabelIconTooltip
                id={labelProps?.id}
                data-test={`admin-settings-${String(name)}-info`}
                label={defaultRender!(labelProps) as any}
                tooltip={description}
              />
            )
      }
    />
  );

  return largeLabel ? (
    <Stack horizontal tokens={{ childrenGap: 15 }} style={defaultEntityFormRowStyles}>
      {largeLabel && <LabelAndDescription label={label} required={field.required} description={description} />}
      {fieldComponent}
    </Stack>
  ) : (
    <FormRow>{fieldComponent}</FormRow>
  );
}

export function NumberEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  convertToGibibytes = false,
  disabled = false,
  largeLabel = false,
}: NumberEntityFieldInputProps<EntityModel>) {
  const { name, label, description, minimum } = field;
  const fieldValue: string = (model as any)[name] as string;
  const fieldComponent = (
    <FluentNumberField
      required={field.required}
      value={convertToGibibytes ? bytesToGibibytes(fieldValue) : fieldValue}
      min={Number(minimum) || undefined}
      label={largeLabel ? undefined : label}
      tooltip={description}
      disabled={disabled}
      onChange={(_ev, value) => {
        onChange && onChange!(name, convertToGibibytes ? gibibytesToBigIntStringBytes(Number(value)) : Number(value));
      }}
    />
  );

  return largeLabel ? (
    <Stack horizontal tokens={{ childrenGap: 15 }} style={defaultEntityFormRowStyles}>
      {largeLabel && <LabelAndDescription label={label} description={description} />}
      {largeLabel ? fieldComponent : <FormRow>{fieldComponent}</FormRow>}
    </Stack>
  ) : (
    <FormRow>{fieldComponent}</FormRow>
  );
}

export function BooleanEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  disabled = false,
  largeLabel = false,
  required = false,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label, description } = field;
  const checked: boolean = Boolean((model as any)[name]) as boolean;
  return (
    <Stack horizontal tokens={{ childrenGap: 15 }} style={defaultEntityFormRowStyles}>
      {largeLabel && <LabelAndDescription label={label} required={required} description={description} />}
      <Toggle
        checked={checked}
        onChange={(_ev, newValue) => (onChange ? onChange(name, newValue) : undefined)}
        inlineLabel
        label={largeLabel ? undefined : label}
        disabled={disabled}
      />
    </Stack>
  );
}

export function SelectEnumEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  largeLabel = false,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label, options, selectedOption, description, disabled } = field;
  const [fieldValue, setFieldValue] = useState<string>(((model as any)[name] as string) || selectedOption || '');
  const fieldComponent = options ? (
    <Dropdown
      disabled={disabled}
      styles={{ root: { width: 215 } }}
      options={options}
      selectedKey={fieldValue}
      onChange={(_ev, v) => {
        const value = v?.key as string;
        setFieldValue(value);
        onChange && onChange(name, value);
      }}
    />
  ) : (
    <>This field has an implementation error</>
  );

  return largeLabel ? (
    <Stack horizontal tokens={{ childrenGap: 15 }} style={defaultEntityFormRowStyles}>
      {largeLabel && <LabelAndDescription label={label} required={field.required} description={description} />}
      {fieldComponent}
    </Stack>
  ) : (
    <FormRow>
      <Label required={field.required}>{label}</Label>
      {fieldComponent}
    </FormRow>
  );
}

export function StringArrayEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  largeLabel = false,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label, description, disabled } = field;
  const items: string[] = (model as any)[name] as string[];

  const fieldComponent = (
    <TextListEditor
      items={items}
      textFieldProps={{ disabled }}
      itemProps={{ hasClose: !disabled } as IItemProps<any>}
      onChange={disabled ? () => null : (value: string[]) => (onChange ? onChange(name, value) : undefined)}
    />
  );

  return largeLabel ? (
    <Stack horizontal tokens={{ childrenGap: 15 }} style={defaultEntityFormRowStyles}>
      {largeLabel && <LabelAndDescription label={label} required={field.required} description={description} />}
      {fieldComponent}
    </Stack>
  ) : (
    <FormRow>
      <Label>{label}</Label>
      {fieldComponent}
    </FormRow>
  );
}

export function ReadOnlyStringArrayEntityModelField<EntityModel>({ field, model }: EntityFieldInputProps<EntityModel>) {
  const { name, label } = field;
  const items: string[] = (model as any)[name] as string[];
  return (
    <FormRow>
      <Label>{label}</Label>
      <BasicList
        title={label}
        styles={basicListStylesInput}
        idField="key"
        labelField="text"
        data={[{ key: '__input__', text: 'input' }, ...items?.map((d) => ({ key: d, text: d }))]}
        data-test="text-list-editor"
        horizontal
        itemRenderer={(d: IDropdownOption) => (
          <Item
            key={`${d.key}-string-array-item`}
            styles={itemStylesDropdown}
            data={d}
            idField="key"
            labelField="text"
          />
        )}
      />
    </FormRow>
  );
}

export function LatestAndAliasesEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  disabled = false,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label, permitActions } = field;
  const permissionsArray = usePermissions(permitActions || []);
  const permissions = permissionsArray.slice(0, -1); // All permissions except loading
  const canDoActions = permitActions?.length ? permissions.every(Boolean) : true;
  const originalItems = (model as any)[name] as string[];
  const [items, setItems] = useState<string[]>(originalItems);
  const [latest, setLatest] = useState<boolean>(originalItems.includes('latest'));
  const fieldDisabled = !canDoActions || disabled;

  return (
    <>
      <FormRow singleRow>
        <Toggle
          checked={latest}
          onChange={(_ev, checked) => {
            const newItems = [
              ...(checked ? ['latest'] : []),
              ...(checked ? items : items.filter((item) => item !== 'latest')),
            ];
            setItems(newItems);
            setLatest(Boolean(checked));
            onChange && onChange(name, newItems);
          }}
          inlineLabel
          label={'Latest'}
          disabled={fieldDisabled}
        />
      </FormRow>
      <FormRow>
        <Label>{label}</Label>
        <TextListEditor
          items={items}
          textFieldProps={{ disabled: fieldDisabled }}
          itemProps={{ hasClose: !fieldDisabled } as IItemProps<any>}
          onChange={
            fieldDisabled
              ? () => null
              : (value: string[]) => {
                  setItems(value);
                  setLatest(value.includes('latest'));
                  onChange && onChange(name, value);
                }
          }
        />
      </FormRow>
    </>
  );
}
