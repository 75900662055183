import { MessageBarType } from '@fluentui/react';
import { BasicTable, ConfirmDialog, MessageBar } from '@h2oai/ui-kit';
import { useState } from 'react';

import { AddMemberRequest } from '../../../../../admin-center/gen/user_management/user_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { useUser } from '../../../../../utils/hooks';
import { messageBarContainerStyles } from '../../../common/common.styles';
import { StatusLabel } from '../../../constants';
import { confirmationTableStyles, confirmationTextStyles } from '../UserManagement.styles';

interface IAddAsAdminConfirmationProps {
  users: { userName: string; userEmail: string; userStatus: StatusLabel }[];
  showDialog: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitSuccess: () => Promise<void>;
}

interface IAddAsAdminConfirmationListProps {
  users: { userName: string; userEmail: string; userStatus: StatusLabel }[];
  hasInvalidUsers: boolean;
}

export default function AddAsAdminConfirmation({
  users,
  showDialog,
  setShowDialog,
  onSubmitSuccess,
}: IAddAsAdminConfirmationProps) {
  const [isAddAsAdminPending, setIsAddAsAdminPending] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [showMessageBar, setShowMessagebar] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const service = useAdminCenterService();

  const appUser = useUser();

  const filteredUsers = users.filter((user) => appUser?.name !== user.userEmail);

  const handleAddAsAdmin = async () => {
    setIsAddAsAdminPending(true);
    const usersList = filteredUsers.map((user) => {
      const [firstName, ...lastNameParts] = user.userName.split(' ');
      return {
        firstName: firstName || '',
        lastName: lastNameParts.join(' ') || '',
        email: user.userEmail,
      };
    });

    try {
      const request: AddMemberRequest = {
        members: usersList,
        memberLevel: 'admin',
        modifiedBy: appUser?.name ?? '',
        timestamp: Date.now() / 1000,
      };
      const response = await service.addMembers(request);

      if (response.statusUpdate) {
        setResponseMessage(response.statusUpdate);
        setIsSuccess(true);
      } else {
        setResponseMessage('❌ Error: Failed to add users to admin group');
        setIsSuccess(false);
      }
      onSubmitSuccess();
    } catch (error) {
      setResponseMessage(`❌ Network error: ${error}`);
    } finally {
      setIsAddAsAdminPending(false);
      setShowDialog(false);
      setShowMessagebar(true);
    }
  };

  return (
    <>
      {showMessageBar && (
        <div style={messageBarContainerStyles}>
          <MessageBar
            timeout={4000}
            onDismiss={() => setShowMessagebar(false)}
            messageBarType={isSuccess ? MessageBarType.success : MessageBarType.error}
          >
            {responseMessage}
          </MessageBar>
        </div>
      )}
      <ConfirmDialog
        hidden={!showDialog}
        title="Confirm Add to Admin Group"
        confirmationButtonText="Okay"
        dismissalButtonText="Cancel"
        onConfirm={handleAddAsAdmin}
        onDismiss={() => {
          setShowDialog(false);
        }}
        content={
          <AddAsAdminConfirmationList users={filteredUsers} hasInvalidUsers={users.length !== filteredUsers.length} />
        }
        minWidth="500px"
        maxWidth="800px"
        confirmationButtonDisabled={isAddAsAdminPending || !filteredUsers.length}
      />
    </>
  );
}

function AddAsAdminConfirmationList({ users, hasInvalidUsers }: IAddAsAdminConfirmationListProps) {
  return (
    <div>
      {users.length > 0 ? (
        <>
          <p style={confirmationTextStyles}>Are you sure you want to add following users to the Admin Group?</p>
          <div style={confirmationTableStyles}>
            <BasicTable
              columns={[
                {
                  key: 'userName',
                  fieldName: 'userName',
                  name: 'User',
                  minWidth: 250,
                },
                {
                  key: 'userEmail',
                  fieldName: 'userEmail',
                  name: 'Email',
                  minWidth: 250,
                },
              ]}
              items={users}
            />
          </div>
          {hasInvalidUsers && (
            <p style={confirmationTextStyles}>
              <b>Note:</b>
              {"You can't add yourself as an Admin!"}
            </p>
          )}
        </>
      ) : (
        <>
          <p style={confirmationTextStyles}>{"You can't add yourself as an Admin!"}</p>
        </>
      )}
    </div>
  );
}
