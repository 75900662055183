import { Stack } from '@fluentui/react';
import { ConfirmDialog } from '@h2oai/ui-kit';
import { useEffect, useMemo, useState } from 'react';

import { AIEngine } from '../../../../aiem/engine/types';
import { User } from '../../../../authz/gen/ai/h2o/user/v1/user_pb';
import { useUsers } from '../../../../authz/providers/UsersProvider';
import { MembersDropdown } from '../../../../pages/ManageWorkspaces/components/ManageMembers/MembersDropdown';

export type AIEMMigrateCreatorProps = {
  hidden?: boolean;
  engine?: AIEngine;
  onConfirm?: (ownerId: string) => unknown;
  onDismiss?: () => void;
};

export const AIEMMigrateCreator = ({ engine, hidden = true, onConfirm, onDismiss }: AIEMMigrateCreatorProps) => {
  const { usersNameMap = {} } = useUsers();
  const [selectedUser, setSelectedUser] = useState<User>();
  const prepopulatedUser = useMemo(() => {
    if (engine?.creator && usersNameMap) {
      return usersNameMap[engine?.creator ?? ''];
    }
    return undefined;
  }, [engine?.creator, usersNameMap]);

  const handleConfirm = () => {
    if (onConfirm && selectedUser) onConfirm(selectedUser.name || '');
  };

  useEffect(() => {
    if (prepopulatedUser) setSelectedUser(prepopulatedUser);
  }, [prepopulatedUser]);

  return (
    <ConfirmDialog
      confirmationButtonText="Change"
      hidden={hidden}
      title="Change Engine Creator"
      content={
        <Stack>
          <p>Changing the creator of this engine will change the of all associated resources data.</p>
          <MembersDropdown
            prepopulatedUser={selectedUser}
            onUserChange={(option) => setSelectedUser(option as unknown as User)}
            disableOptions={[prepopulatedUser?.name || '']}
          />
        </Stack>
      }
      onConfirm={handleConfirm}
      onDismiss={onDismiss}
    />
  );
};
