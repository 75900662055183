import { IDetailsRowBaseProps } from '@fluentui/react';
import { IH2OTheme } from '@h2oai/ui-kit';

import { Entity } from '../../../../aiem/entity/types';
import { DAIEngineProfile } from '../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import { H2OEngineProfile } from '../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import { NotebookEngineProfile } from '../../../../aiem/gen/ai/h2o/engine/v1/notebook_engine_profile_pb';

export type EntityModelTypes = DAIEngineProfile | H2OEngineProfile | NotebookEngineProfile;

export enum EntityRowState {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  VIEW = 'VIEW',
}

export type ExpandedEntityWithState<EntityModel extends EntityModelTypes> = {
  model: EntityModel;
  rowState: EntityRowState;
};

export interface EngineProfilesColumnProps<EntityModel extends EntityModelTypes> {
  entity: Entity<EntityModel>;
  theme: IH2OTheme;
  formChanged: boolean;
  onSave: () => void;
  handleDeleteEntity: (entityName: string) => void;
  updateActiveEntityState: (model?: EntityModel, entityState?: EntityRowState) => void;
  activeEntity?: ExpandedEntityWithState<EntityModel>;
  permissions: AdminEngineManagerUserPermissions;
}

export type AdminEngineManagerUserPermissions = {
  canCreate: boolean;
  canView: boolean;
  canEdit: boolean;
  canDelete: boolean;
};

export interface EngineProfilesProps<EntityModel> {
  entity: Entity<EntityModel>;
  activeWorkspaceName?: string;
  userActions: string[];
}

export interface ListRowProps<EntityModel extends EntityModelTypes> {
  entity: Entity<EntityModel>;
  rowProps: IDetailsRowBaseProps;
  onChange: (model: EntityModel) => void;
  updateActiveEntityState: (model?: EntityModel, entityState?: EntityRowState) => void;
  canEdit: boolean;
  activeEntity?: ExpandedEntityWithState<EntityModel>;
}
