import { ColumnActionsMode, IButtonProps, IStyle, Stack, Text } from '@fluentui/react';
import {
  Checkbox,
  FontWeights,
  IconName,
  Item,
  Loader,
  itemStylesCategory,
  loaderStylesSpinnerButtonPrimary,
  loaderStylesSpinnerDefault,
  loaderStylesSpinnerXLarge,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useMemo } from 'react';

import { useEngine } from '../../../../aiem/engine/hooks';
import { AIEngine } from '../../../../aiem/engine/types';
import { isTransitionalState } from '../../../../aiem/engine/utils';
import { Engine, Engine_State, Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { getIdFromName, parseSeconds, secondsToFriendlyInterval } from '../../../../aiem/utils';
import { useUser } from '../../../../utils/hooks';
import { listIconSize } from '../../../../utils/utils';
import EmptyStateMessage from '../../../EmptyStateMessage/EmptyStateMessage';
import { ActionCell } from '../../../ListPages/ActionCell';
import Cell from '../../../ListPages/Cell';
import { IconCell } from '../../../ListPages/IconCell';
import List from '../../../ListPages/List';
import { MetadataCell, baseMetadataTextStyles, metadataTextStyles } from '../../../ListPages/MetadataCell';
import { TitleCell } from '../../../ListPages/TitleCell';
import { EngineLowStorageBadge, EngineResizingBadge, badgeLoaderStyles } from '../Badges';
import { EngineActionButton } from '../EngineActionButton/EngineActionButton';
import { LabelIconTooltip } from '../LabelIconTooltip/LabelIconTooltip';
import { tooltipMessages, versionLabelSeverityMapping } from './constants';

export type EngineListProps = {
  admin?: boolean;
  deleteEngine: (engine: AIEngine) => void;
  editEngine: (engine: AIEngine) => void;
  engines: Engine[] | undefined;
  loadingMsg?: string;
  openLegacyEngineLogs: (engine: AIEngine) => void;
  pauseEngine: (engine: AIEngine) => void;
  terminateEngine: (engine: AIEngine) => void;
  readLogs: (engine: AIEngine) => void;
  resumeEngine: (engine: AIEngine) => void;
  selectedEngineIds: string[];
  setSelectedEngineIds: (selectedIds: string[]) => void;
  updateEngine?: (engine: AIEngine) => void;
  upgradeEngine: (engine: AIEngine) => void;
  showResizeModal: (engine: AIEngine) => void;
  viewEngine: (engine: AIEngine) => void;
  migrateCreator?: (engine: AIEngine) => void;
};

export function EngineList({
  admin,
  deleteEngine,
  editEngine,
  engines,
  loadingMsg,
  openLegacyEngineLogs,
  pauseEngine,
  terminateEngine,
  readLogs,
  resumeEngine,
  selectedEngineIds,
  setSelectedEngineIds,
  upgradeEngine,
  showResizeModal,
  viewEngine,
  migrateCreator,
}: EngineListProps) {
  const { id } = useUser();
  const theme = useTheme(),
    loaderTheme = badgeLoaderStyles(theme),
    { engineTypeLogo, EngineStateMap, getEngineTypeData } = useEngine(),
    onClickSelectAll = (_: any, checked?: boolean) => {
      if (checked) {
        if (engines) {
          setSelectedEngineIds([...engines.map(({ uid }) => uid || '').filter((uid) => !!uid)]);
        }
      } else {
        setSelectedEngineIds([]);
      }
    },
    onRenderVersion = (version: string, upgradeAvailable: boolean, deprecated: boolean, deleted?: boolean) => {
      const versionText = `Version ${version}`;
      const versionStatus = deleted
        ? 'deleted'
        : upgradeAvailable
        ? 'upgradeAvailable'
        : deprecated
        ? 'deprecated'
        : '';
      const showTooltip = versionStatus !== '';
      const tooltipText = tooltipMessages[versionStatus];
      const severity = versionLabelSeverityMapping[versionStatus];

      return showTooltip ? (
        <LabelIconTooltip
          label={<Text styles={{ root: baseMetadataTextStyles as IStyle }}>{versionText}</Text>}
          iconName={IconName.WarningSolid}
          tooltip={tooltipText}
          severity={severity}
        />
      ) : (
        <Text styles={{ root: metadataTextStyles }}>{versionText}</Text>
      );
    },
    onRenderCheckboxCell = useCallback(
      (engine: Engine) => {
        const { uid: engineId = '' } = engine;
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: listIconSize,
              width: 40,
            }}
          >
            <Checkbox
              onChange={(_, checked) => {
                if (checked) {
                  setSelectedEngineIds([...selectedEngineIds, engineId]);
                } else {
                  setSelectedEngineIds(selectedEngineIds.filter((id) => id !== engineId));
                }
              }}
              checked={selectedEngineIds?.includes(engineId)}
            />
          </div>
        );
      },
      [selectedEngineIds, setSelectedEngineIds]
    ),
    onRenderIconCell = useCallback((engine: Engine) => {
      const { src, backgroundColor } = engineTypeLogo[engine.type!];
      return <IconCell size={listIconSize} src={src} styles={{ backgroundColor }} />;
    }, []),
    onRenderTitleCell = useCallback((engine: Engine) => {
      const { name, displayName } = engine;
      return <TitleCell title={displayName || 'Untitled engine'} subtitle={getIdFromName(name)!} />;
    }, []),
    onRenderCreatorCell = useCallback((engine: Engine) => {
      const { creatorDisplayName } = engine;
      return <MetadataCell title="Creator" metadata={[creatorDisplayName || '']} />;
    }, []),
    onRenderTypeCell = useCallback((engine: Engine) => {
      const { type, version, upgradeAvailable, deprecatedVersion: deprecated, deletedVersion } = engine;
      const engineTypeData = getEngineTypeData(type);
      return (
        <MetadataCell
          title="Type"
          metadata={[
            engineTypeData?.title || 'Unspecified',
            onRenderVersion(version || '', !!upgradeAvailable, !!deprecated, !!deletedVersion),
          ]}
        />
      );
    }, []),
    onRenderDetailsCell = useCallback((engine: Engine) => {
      const createdDate = engine.createTime;
      const resumeDate = engine.resumeTime;
      const detailsMetadata = [];
      if (createdDate) {
        detailsMetadata.push(`Created ${createdDate}`);
      }
      if (resumeDate) {
        detailsMetadata.push(`Last resumed ${resumeDate}`);
      }
      return <MetadataCell title="Details" metadata={detailsMetadata} />;
    }, []),
    onRenderStatusCell = useCallback((engine: Engine) => {
      const { state } = engine;
      const engineStateData = state ? EngineStateMap.get(state) : undefined;
      return (
        <Cell>
          <Stack tokens={{ childrenGap: 8 }}>
            <span style={{ fontWeight: FontWeights.bold }}>Status</span>
            <Stack tokens={{ childrenGap: 4 }}>
              <div style={{ display: 'inline-block' }}>
                <Item
                  styles={[
                    itemStylesCategory,
                    {
                      root: {
                        background: engineStateData?.backgroundColor || theme.semanticColors?.categoryBackground,
                        color: engineStateData?.color || theme.semanticColors?.categoryText,
                      },
                    },
                  ]}
                  labelField="label"
                  data={{
                    label: engineStateData?.title || 'Unknown',
                  }}
                  loading={!!(engineStateData?.id && isTransitionalState(engineStateData.id as Engine_State))}
                  loaderProps={{
                    label: engineStateData?.title,
                    styles: [loaderStylesSpinnerDefault, loaderStylesSpinnerButtonPrimary, loaderTheme],
                  }}
                />
              </div>
              <EngineResizingBadge engine={engine} />
              <EngineLowStorageBadge engine={engine} />
            </Stack>
          </Stack>
        </Cell>
      );
    }, []),
    onRenderIdleCell = useCallback((engine: Engine) => {
      const { state, type } = engine,
        driverless = type === Engine_Type.DRIVERLESS_AI;
      if (state !== Engine_State.RUNNING || !engine.currentIdleDuration) {
        return null;
      }
      const {
          maxIdleDuration: maxIdleDurationStr,
          currentIdleDuration: currentIdleDurationStr,
          maxRunningDuration: maxRunningDurationStr,
        } = engine,
        maxIdleDuration = parseSeconds(maxIdleDurationStr),
        currentIdleDuration = parseSeconds(currentIdleDurationStr),
        idleTimeRemaining = maxIdleDuration - currentIdleDuration,
        maxRunningDuration = parseSeconds(maxRunningDurationStr);

      const tooltip = `
      This engine will automatically ${
        driverless ? 'pause' : 'terminate'
      } after being idle for ${secondsToFriendlyInterval(maxIdleDuration)}
      or running for ${secondsToFriendlyInterval(maxRunningDuration)}.
      ${driverless ? '' : 'H2O-3 instances cannot be resumed after termination.'}
      `;
      return (
        <MetadataCell
          title={driverless ? 'Time to Auto-Pause' : 'Time to Auto-Terminate'}
          metadata={[`${secondsToFriendlyInterval(idleTimeRemaining)}`]}
          tooltip={tooltip}
        />
      );
    }, []),
    onRenderActionsCell = useCallback((engine: Engine) => {
      const { name, creator, type } = engine;
      const aiEngine: AIEngine = { ...engine, engineType: type } as AIEngine;
      const owner = id === creator?.split('/')[1];
      return (
        <ActionCell
          primaryButton={
            <EngineActionButton
              isAdmin={admin}
              engine={aiEngine}
              viewEngine={() => viewEngine(aiEngine)}
              editEngine={() => editEngine(aiEngine)}
              resumeEngine={() => resumeEngine(aiEngine)}
              pauseEngine={() => pauseEngine(aiEngine)}
              terminateEngine={() => terminateEngine(aiEngine)}
              deleteEngine={() => deleteEngine(aiEngine)}
              upgradeEngine={() => upgradeEngine(aiEngine)}
              showResizeModal={() => showResizeModal(aiEngine)}
              migrateCreator={() => migrateCreator && migrateCreator(aiEngine)}
              readLogs={() => readLogs(aiEngine)}
              openLegacyEngineLogs={() => openLegacyEngineLogs(aiEngine)}
            />
          }
          secondaryButtonProps={
            {
              // TODO: Check VISIT when a Notebook Engine can run
              disabled: engine.state !== Engine_State.RUNNING || !aiEngine.loginUrl || !owner,
              'data-test': `${getIdFromName(name)}--go-to-button`,
              title: 'Visit',
              href: aiEngine.loginUrl,
              target: '_blank',
              type: 'link',
              text: 'Visit',
            } as IButtonProps
          }
        />
      );
    }, []),
    columns = useMemo(
      () => [
        {
          key: 'checkbox',
          name: '',
          className: 'engine-checkbox',
          fieldName: 'checkbox',
          minWidth: 24,
          maxWidth: 24,
          onRender: onRenderCheckboxCell,
        },
        {
          key: 'icon',
          name: '',
          className: 'engine-icon',
          fieldName: 'icon',
          minWidth: listIconSize,
          maxWidth: listIconSize,
          onRender: onRenderIconCell,
        },
        {
          key: 'name-uid',
          name: 'Name and Id',
          fieldName: 'name-id',
          minWidth: 100,
          maxWidth: 300,
          onRender: onRenderTitleCell,
        },
        {
          key: 'creator',
          name: 'Creator',
          fieldName: 'creatorDisplayName',
          minWidth: 100,
          maxWidth: 180,
          onRender: onRenderCreatorCell,
        },
        {
          key: 'type',
          name: 'Type',
          fieldName: 'type',
          minWidth: 120,
          maxWidth: 160,
          onRender: onRenderTypeCell,
        },
        {
          key: 'details',
          name: 'Details',
          fieldName: 'details',
          minWidth: 150,
          maxWidth: 360,
          onRender: onRenderDetailsCell,
        },
        {
          key: 'status',
          name: 'Status',
          fieldName: 'status',
          minWidth: 300,
          maxWidth: 400,
          columnActionsMode: ColumnActionsMode.disabled,
          onRender: onRenderStatusCell,
        },
        {
          key: 'idle',
          name: 'Stop if Idle till',
          fieldName: 'idle',
          minWidth: 177,
          maxWidth: 177,
          columnActionsMode: ColumnActionsMode.disabled,
          onRender: onRenderIdleCell,
        },
        {
          key: 'actions',
          name: 'Actions',
          fieldName: 'actions',
          minWidth: 280,
          maxWidth: 280,
          columnActionsMode: ColumnActionsMode.disabled,
          onRender: onRenderActionsCell,
        },
      ],
      [
        admin,
        onRenderCheckboxCell,
        onRenderIconCell,
        onRenderTitleCell,
        onRenderCreatorCell,
        onRenderTypeCell,
        onRenderDetailsCell,
        onRenderStatusCell,
        onRenderIdleCell,
        onRenderActionsCell,
      ]
    );

  // TODO: Create custom loading/err/empty component wrapper.
  if (loadingMsg) return <Loader styles={loaderStylesSpinnerXLarge} label={loadingMsg} />;
  if (!engines?.length) return <EmptyStateMessage message="No engines found" />;

  return (
    <List
      dataTest="engines-list"
      items={engines}
      columns={columns}
      showSelectAllButton
      onClickSelectAll={onClickSelectAll}
      onShouldVirtualize={process.env.NODE_ENV === 'test' ? () => false : undefined}
    />
  );
}
