import { IStyle } from '@fluentui/react';
import { BoxShadow, FontSizes, FontWeights, IH2OTheme, IItemStyles, Sizes } from '@h2oai/ui-kit';

import { tagStyle } from '../../common/common.styles';

export interface IEnvInfoPageStyles {
  container: IStyle;
  content: IStyle;
  header: IStyle;
  titleWrapper: IStyle;
  description: IStyle;
  cardHeader: IStyle;
  card: IStyle;
  versionInfo: IStyle;
  ipList: IStyle;
  ipItem: IStyle;
  ipStatus: IStyle;
  statusDot: IStyle;
}

export const envInfoStyles = (theme: IH2OTheme): Partial<IEnvInfoPageStyles> => ({
  container: {
    minHeight: '100%',
  },
  content: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: Sizes.padding,
  },
  header: {
    marginBottom: '32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px',
    fontSize: FontSizes.xxlarge,
    fontWeight: FontWeights.bold,
  },
  description: {
    marginBottom: '16px',
    fontSize: FontSizes.small,
  },
  card: {
    backgroundColor: theme.palette?.white,
    padding: Sizes.padding,
    borderRadius: Sizes.borderRadius,
    boxShadow: BoxShadow,
  },
  cardHeader: {
    fontSize: FontSizes.large,
    fontWeight: FontWeights.semiBold,
    marginBottom: '16px',
  },
  versionInfo: {
    fontWeight: FontWeights.semiBold,
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  ipList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  ipItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '8px',
    paddingRight: '8px',
    backgroundColor: theme.palette?.gray200,
    border: `1px solid ${theme.palette?.gray300}`,
    borderRadius: Sizes.borderRadius,
  },
  ipStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    fontFamily: 'monospace',
  },
  statusDot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: theme.palette?.green300,
  },
});

export const versionTagStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.primary200,
    color: theme.semanticColors?.categoryText,
    margin: '0 !important',
  },
});

export const loaderStyles = (theme: IH2OTheme) => ({
  progressBar: {
    background: `linear-gradient(to right, ${theme.palette?.gray200} 0%, ${theme.palette?.primary500} 50%, ${theme.palette?.gray200} 100%)`,
  },
});
