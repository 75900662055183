import {
  DriverlessEngineProfileOptionKeyType,
  H2OEngineProfileOptionKeyType,
} from '../../components/AIEnginesPage/constants';
import { DAIEngine, DAIEngine_State } from '../gen/ai/h2o/engine/v1/dai_engine_pb';
import {
  CreateDAIEngineRequest,
  DAIEngineServiceMigrateCreatorRequest,
  DAIEngineServiceResizeStorageRequest,
  DAIEngineServiceUpgradeDAIEngineVersionRequest,
  DAIEngineServiceUpgradeDAIEngineVersionResponse,
  ListDAIEnginesRequest,
  UpdateDAIEngineRequest,
} from '../gen/ai/h2o/engine/v1/dai_engine_service_pb';
import { DAIEngineVersion } from '../gen/ai/h2o/engine/v1/dai_engine_version_pb';
import { ListDAIEngineVersionsRequest } from '../gen/ai/h2o/engine/v1/dai_engine_version_service_pb';
import { Engine, Engine_State, Engine_Type } from '../gen/ai/h2o/engine/v1/engine_pb';
import { ListEnginesRequest } from '../gen/ai/h2o/engine/v1/engine_service_pb';
import { H2OEngine, H2OEngine_State } from '../gen/ai/h2o/engine/v1/h2o_engine_pb';
import { CreateH2OEngineRequest, ListH2OEnginesRequest } from '../gen/ai/h2o/engine/v1/h2o_engine_service_pb';
import { H2OEngineVersion } from '../gen/ai/h2o/engine/v1/h2o_engine_version_pb';
import { ListH2OEngineVersionsRequest } from '../gen/ai/h2o/engine/v1/h2o_engine_version_service_pb';
import { NotebookEngine } from '../gen/ai/h2o/engine/v1/notebook_engine_pb';
import {
  CreateNotebookEngineRequest,
  ListNotebookEnginesRequest,
  UpdateNotebookEngineRequest,
} from '../gen/ai/h2o/engine/v1/notebook_engine_service_pb';
import { BigIntString, RequestConfig } from '../gen/runtime';
import { CalculateH2OEngineSizeCompressedDataset, CalculateH2OEngineSizeRawDataset } from './services';

export type AIEMError = {
  code: number;
  message: string;
  details: any[];
};

export enum AIEMOpType {
  checkId = 'checkId',
  create = 'create',
  edit = 'edit',
  get = 'get',
  list = 'list',
  view = 'view',
  resume = 'resume',
  pause = 'pause',
  terminate = 'terminate',
  delete = 'delete',
  update = 'update',
  upgrade = 'upgrade',
  constraintSet = 'constraintSet',
  openLog = 'openLog',
  downloadLog = 'downloadLog',
  migrateCreator = 'migrateCreator',
  resize = 'resize',
}

export type EngineAttributes = {
  engineType?: Engine_Type;
  op?: AIEMOpType;
  id?: string;
  loginUrl?: string;
  engineNewVersion?: string;
  newCreator?: string;
  newSize?: string;
};

export type AIEngine = EngineAttributes & Engine & DAIEngine & H2OEngine & NotebookEngine;

export type EngineConstraintSet = any;

export type EngineVersionAttributes = {
  isDefault?: boolean;
  key?: string;
  type?: Engine_Type;
};

export interface V1RawConstraintNumeric {
  min?: string;
  max?: string | null;
  default?: string;
}

export interface V1RawConstraintDuration {
  min?: string;
  max?: string | null;
  default?: string;
}

export interface V1RawDAIEngineConstraintSet {
  name?: string;
  cpu?: V1RawConstraintNumeric;
  gpu?: V1RawConstraintNumeric;
  memoryBytes?: V1RawConstraintNumeric;
  storageBytes?: V1RawConstraintNumeric;
  maxIdleDuration?: V1RawConstraintDuration;
  maxRunningDuration?: V1RawConstraintDuration;
}

export interface V1RawH2OEngineConstraintSet {
  name?: string;
  cpu?: V1RawConstraintNumeric;
  gpu?: V1RawConstraintNumeric;
  memoryBytes?: V1RawConstraintNumeric;
  nodeCount?: V1RawConstraintNumeric;
  maxIdleDuration?: V1RawConstraintDuration;
  maxRunningDuration?: V1RawConstraintDuration;
}
export interface V1GetRawDAIEngineConstraintSetResponse {
  daiEngineConstraintSet: V1RawDAIEngineConstraintSet;
}

export interface V1GetRawH2OEngineConstraintSetResponse {
  h2oEngineConstraintSet: V1RawH2OEngineConstraintSet;
}

export type EngineVersion = (DAIEngineVersion | H2OEngineVersion) & EngineVersionAttributes;
export type CreateEngineRequest = EngineAttributes &
  (CreateDAIEngineRequest | CreateH2OEngineRequest | CreateNotebookEngineRequest);

export type EngineState = Engine_State | DAIEngine_State | H2OEngine_State;

export type EngineListRequest =
  | ListEnginesRequest
  | ListDAIEnginesRequest
  | ListH2OEnginesRequest
  | ListNotebookEnginesRequest;
export type EngineUpdateRequest = UpdateDAIEngineRequest | UpdateNotebookEngineRequest;
export type EngineUpgradeRequest = DAIEngineServiceUpgradeDAIEngineVersionRequest;
export type EngineMigrateCreatorRequest = DAIEngineServiceMigrateCreatorRequest;
export type EngineResizeStorageRequest = DAIEngineServiceResizeStorageRequest;

// generic responses
export type EngineListResponse = {
  engines: Array<Engine | DAIEngine | H2OEngine | NotebookEngine>;
  nextPageToken?: string;
  totalSize?: number;
};
export type EngineResponse = {
  engine: AIEngine | Engine | DAIEngine | H2OEngine | NotebookEngine;
};

type ItemOp = (engine: AIEngine, abort?: boolean) => Promise<EngineResponse>;
type ListOp = (request: EngineListRequest) => Promise<EngineListResponse>;
type UpgradeOp = (request: AIEngine) => Promise<DAIEngineServiceUpgradeDAIEngineVersionResponse>;

type EndpointTypeFunction = {
  [P in keyof Engine_Type as string]: ItemOp | ListOp | UpgradeOp;
};

export type Endpoint = {
  [P in keyof AIEMOpType as string]: EndpointTypeFunction;
};

type EngineVersionListRequest = ListDAIEngineVersionsRequest | ListH2OEngineVersionsRequest;

type EngineTypeAttributes = {
  text: string;
  listVersions: (requestConfig: RequestConfig, request?: EngineVersionListRequest) => Promise<EngineVersion[]>;
};

export type ValidEngineType = Engine_Type.DRIVERLESS_AI | Engine_Type.H2O | Engine_Type.NOTEBOOK;

export type EngineTypeService = {
  [P in ValidEngineType]: EngineTypeAttributes;
};

export type EngineProfileOption = {
  key: DriverlessEngineProfileOptionKeyType | H2OEngineProfileOptionKeyType;
  text: string;
  dataTest: string;
  cpu?: number;
  gpu?: number;
  nodeCount?: number;
  memoryBytes?: BigIntString;
  storageBytes?: BigIntString;
};

export type CalculatedH2OEngineSizeAttributes = {
  calculate: typeof CalculateH2OEngineSizeCompressedDataset | typeof CalculateH2OEngineSizeRawDataset;
  label: string;
};

export type H2OEngineSizeService = {
  [P in
    | H2OEngineProfileOptionKeyType.raw
    | H2OEngineProfileOptionKeyType.compressed]: CalculatedH2OEngineSizeAttributes;
};
