// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file aiUnitHourly/aiUnitHourly.proto (package aiUnitHourlyCummulative, syntax proto3)
/* eslint-disable */

import { RPC } from "../runtime";

/**
 * @generated from message aiUnitHourlyCummulative.AiUnitHourlyCummulativeRequest
 */
export type AiUnitHourlyCummulativeRequest = {
/**
 * Selected month in YYYY-MM format
 *
 * @generated from field: string month = 1;
 */
month?: string;
}
;
/**
 * @generated from message aiUnitHourlyCummulative.AiUnitHourlyComponentRequest
 */
export type AiUnitHourlyComponentRequest = {
/**
 * Selected month in YYYY-MM format
 *
 * @generated from field: string month = 1;
 */
month?: string;
}
;
/**
 * @generated from message aiUnitHourlyCummulative.AiUnitHourlySummaryRequest
 */
export type AiUnitHourlySummaryRequest = {
}
;
/**
 * @generated from message aiUnitHourlyCummulative.AiUnitHourlyCummulativeResponse
 */
export type AiUnitHourlyCummulativeResponse = {
/**
 * Data for contract period
 *
 * @generated from field: repeated aiUnitHourlyCummulative.AiUnitHourlyCummulativeEvent events = 1;
 */
events?: AiUnitHourlyCummulativeEvent[];
/**
 * Data for selected month
 *
 * @generated from field: repeated aiUnitHourlyCummulative.AiUnitHourlyCummulativeEvent monthly_events = 2;
 */
monthlyEvents?: AiUnitHourlyCummulativeEvent[];
/**
 * Threshold value for contract period
 *
 * @generated from field: float threshold = 3;
 */
threshold?: number;
/**
 * Threshold value for selected month
 *
 * @generated from field: float monthly_threshold = 4;
 */
monthlyThreshold?: number;
/**
 * Max percenatage of consumption
 *
 * @generated from field: float max_percentage = 5;
 */
maxPercentage?: number;
/**
 * Max percenatage of monthly consumption
 *
 * @generated from field: float monthly_max_percentage = 6;
 */
monthlyMaxPercentage?: number;
}
;
/**
 * @generated from message aiUnitHourlyCummulative.AiUnitHourlyComponentResponse
 */
export type AiUnitHourlyComponentResponse = {
/**
 * Data for contract period
 *
 * @generated from field: repeated aiUnitHourlyCummulative.AiUnitHourlyComponentEvent events = 1;
 */
events?: AiUnitHourlyComponentEvent[];
/**
 * Data for selected month
 *
 * @generated from field: repeated aiUnitHourlyCummulative.AiUnitHourlyComponentEvent monthly_events = 2;
 */
monthlyEvents?: AiUnitHourlyComponentEvent[];
}
;
/**
 * @generated from message aiUnitHourlyCummulative.AiUnitHourlySummaryResponse
 */
export type AiUnitHourlySummaryResponse = {
/**
 * Contract start date
 *
 * @generated from field: string envStartDate = 1;
 */
envStartDate?: string;
/**
 * Contract end date
 *
 * @generated from field: string envEndDate = 2;
 */
envEndDate?: string;
/**
 * Purchased AI Unit
 *
 * @generated from field: string purchasedAIUnits = 3;
 */
purchasedAIUnits?: string;
}
;
/**
 * @generated from message aiUnitHourlyCummulative.AiUnitHourlyCummulativeEvent
 */
export type AiUnitHourlyCummulativeEvent = {
/**
 * YYYY-MM-DD
 *
 * @generated from field: string date = 1;
 */
date?: string;
/**
 * YYYY-MM
 *
 * @generated from field: string month = 2;
 */
month?: string;
/**
 * YYYY
 *
 * @generated from field: string year = 3;
 */
year?: string;
/**
 * AI unit count
 *
 * @generated from field: float ai_units = 4;
 */
aiUnits?: number;
/**
 * Cummulative ai units count
 *
 * @generated from field: float cum_ai_units = 5;
 */
cumAiUnits?: number;
}
;
/**
 * @generated from message aiUnitHourlyCummulative.AiUnitHourlyComponentEvent
 */
export type AiUnitHourlyComponentEvent = {
/**
 * YYYY-MM-DD
 *
 * @generated from field: string date = 1;
 */
date?: string;
/**
 * YYYY-MM
 *
 * @generated from field: string month = 2;
 */
month?: string;
/**
 * YYYY
 *
 * @generated from field: string year = 3;
 */
year?: string;
/**
 * Reource
 *
 * @generated from field: string resource = 4;
 */
resource?: string;
/**
 * AI unit count
 *
 * @generated from field: float ai_units = 5;
 */
aiUnits?: number;
}
;
/**
 * @generated from message aiUnitHourlyCummulative.AiUnitHourlyResponse
 */
export type AiUnitHourlyResponse = {
/**
 * Data for contract period
 *
 * @generated from field: repeated aiUnitHourlyCummulative.AiUnitHourlyEvent all_events = 1;
 */
allEvents?: AiUnitHourlyEvent[];
}
;
/**
 * @generated from message aiUnitHourlyCummulative.AiUnitHourlyEvent
 */
export type AiUnitHourlyEvent = {
/**
 * YYYY-MM-DD
 *
 * @generated from field: string date = 1;
 */
date?: string;
/**
 * YYYY-MM
 *
 * @generated from field: string month = 2;
 */
month?: string;
/**
 * YYYY
 *
 * @generated from field: string year = 3;
 */
year?: string;
/**
 * Resource
 *
 * @generated from field: string resource = 4;
 */
resource?: string;
/**
 * AI unit count
 *
 * @generated from field: float ai_units = 5;
 */
aiUnits?: number;
}
;
/**
 * @generated from rpc aiUnitHourlyCummulative.AiUnitHourlyService.GetHourlyAiUnitCummulativeUsage
 */
export const AiUnitHourlyService_GetHourlyAiUnitCummulativeUsage = new RPC<AiUnitHourlyCummulativeRequest,AiUnitHourlyCummulativeResponse>("GET", "/getHourlyAiUnitCummulativeUsage");
/**
 * @generated from rpc aiUnitHourlyCummulative.AiUnitHourlyService.GetHourlyAiUnitComponentUsage
 */
export const AiUnitHourlyService_GetHourlyAiUnitComponentUsage = new RPC<AiUnitHourlyComponentRequest,AiUnitHourlyComponentResponse>("GET", "/getHourlyAiUnitComponentUsage");
/**
 * @generated from rpc aiUnitHourlyCummulative.AiUnitHourlyService.GetHourlyAiUnitSummary
 */
export const AiUnitHourlyService_GetHourlyAiUnitSummary = new RPC<AiUnitHourlySummaryRequest,AiUnitHourlySummaryResponse>("GET", "/getHourlyAiUnitSummary");
