import { MessageBarType } from '@fluentui/react';
import { DetailsList, Item, MessageBar, useTheme } from '@h2oai/ui-kit';
import { useEffect, useMemo, useState } from 'react';

import { AiemEventsRequest, AiemEventsResponse } from '../../../../../admin-center/gen/aiem/aiem_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { customTableStyles } from '../../../common/common.styles';
import { getDummyList } from '../../../common/utils';
import {
  AIEM_ENGINE_NAME_PREFIX,
  AiemColName,
  AiemColNameKey,
  AiemEventName,
  AiemEventOriginalName,
  AiemOriginalType,
  AiemTypesTag,
  NA,
} from '../../../constants';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { aiemTableStyle, deletedEventStyles, pausedEventStyles, startedEventStyles } from '../AIEM.styles';

interface AIEM {
  engineDate: string;
  engineTime: string;
  engineUser: string;
  engineType: string;
  engineEvent: string;
  engineVersion: string;
  engineName: string;
  engineCPU: number;
  engineMemory: number;
  engineGPU: number;
  engineStorage: number;
}

interface AIEMTableProps {
  startDate: string;
  endDate: string;
  showComputeResource: boolean;
  searchQuery: string;
}

const dummyList = getDummyList<AIEM>(5);

export default function AIEMTable({ startDate, endDate, showComputeResource, searchQuery }: AIEMTableProps) {
  const service = useAdminCenterService();
  const theme = useTheme();
  const [aiemData, setAiemData] = useState<AIEM[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAIEMEvents = async () => {
    setIsLoading(true);
    try {
      const request: AiemEventsRequest = {
        startDate: startDate,
        endDate: endDate,
      };

      const response: AiemEventsResponse = await service.getAIEMEvents(request);

      const formattedData = (response.events ?? []).map((event) => {
        const utcDate = event.ts ? new Date(event.ts) : null;
        const getAiemTypeColumnEntry = (aiemType: string | undefined): string => {
          if (!aiemType) return NA;
          return aiemType === AiemOriginalType.dai
            ? AiemTypesTag.dai
            : aiemType === AiemOriginalType.h2o3
            ? AiemTypesTag.h2o3
            : aiemType.toLowerCase() === AiemOriginalType.nan
            ? NA
            : aiemType;
        };

        const formatEngineName = (name: string | undefined): string => {
          if (!name) return NA;
          return name.includes(AIEM_ENGINE_NAME_PREFIX) ? name.split('/').pop() || name : name;
        };

        const AIEM_EVENT_MAP: Record<string, string> = {
          [AiemEventOriginalName.start]: AiemEventName.start,
          [AiemEventOriginalName.pause]: AiemEventName.pause,
          [AiemEventOriginalName.delete]: AiemEventName.delete,
          nan: NA,
        };
        const getAiemEventColumnEntry = (event: string | undefined): string => {
          if (!event) return NA;
          return AIEM_EVENT_MAP[event.toLowerCase()] || event;
        };

        return {
          engineDate: utcDate
            ? utcDate.toLocaleDateString('en-CA', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
            : '-',
          engineTime: utcDate
            ? utcDate.toLocaleTimeString('en-US', {
                hour12: false,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
            : '-',
          engineUser: event.userName ?? '-',
          engineType: getAiemTypeColumnEntry(event.type) ?? '-',
          engineEvent: getAiemEventColumnEntry(event.stream) ?? '-',
          engineVersion: event.version ?? '-',
          engineName: formatEngineName(event.engineName) ?? '-',
          engineCPU: event.cpu ?? 0,
          engineMemory: event.memory ?? 0,
          engineGPU: event.gpu ?? 0,
          engineStorage: event.storage ?? 0,
        };
      });

      setAiemData(formattedData);
    } catch (error) {
      console.error('Error fetching login events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAIEMEvents();
  }, [startDate, endDate, showComputeResource]);

  const filteredData = useMemo(() => {
    const searchTerm = searchQuery.toLowerCase();
    return aiemData.filter(
      (item) => item.engineName.toLowerCase().includes(searchTerm) || item.engineUser.toLowerCase().includes(searchTerm)
    );
  }, [aiemData, searchQuery]);

  const computeResourceColumns = [
    {
      key: AiemColNameKey.engineCPU,
      fieldName: AiemColNameKey.engineCPU,
      name: AiemColName.engineCPU,
      minWidth: 200,
      onRender: (item: AIEM) => <>{!isLoading ? item.engineCPU : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AiemColNameKey.engineMemory,
      fieldName: AiemColNameKey.engineMemory,
      name: AiemColName.engineMemory,
      minWidth: 200,
      onRender: (item: AIEM) => <>{!isLoading ? item.engineMemory : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AiemColNameKey.engineGPU,
      fieldName: AiemColNameKey.engineGPU,
      name: AiemColName.engineGPU,
      minWidth: 200,
      onRender: (item: AIEM) => <>{!isLoading ? item.engineGPU : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AiemColNameKey.engineStorage,
      fieldName: AiemColNameKey.engineStorage,
      name: AiemColName.engineStorage,
      minWidth: 200,
      onRender: (item: AIEM) => <>{!isLoading ? item.engineStorage : <SkeletonLoader width="65%" height="100%" />}</>,
    },
  ];

  const baseColumns = [
    {
      key: AiemColNameKey.engineDate,
      fieldName: AiemColNameKey.engineDate,
      name: AiemColName.engineDate,
      minWidth: 200,
      onRender: (item: AIEM) => <>{!isLoading ? item.engineDate : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AiemColNameKey.engineTime,
      fieldName: AiemColNameKey.engineTime,
      name: AiemColName.engineTime,
      minWidth: 200,
      onRender: (item: AIEM) => <>{!isLoading ? item.engineTime : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AiemColNameKey.engineUser,
      fieldName: AiemColNameKey.engineUser,
      name: AiemColName.engineUser,
      minWidth: 200,
      onRender: (item: AIEM) => <>{!isLoading ? item.engineUser : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AiemColNameKey.engineType,
      fieldName: AiemColNameKey.engineType,
      name: AiemColName.engineType,
      minWidth: 200,
      onRender: (item: AIEM) => <>{!isLoading ? item.engineType : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AiemColNameKey.engineEvent,
      fieldName: AiemColNameKey.engineEvent,
      name: AiemColName.engineEvent,
      minWidth: 200,
      onRender: (item: any) => {
        if (isLoading) return <SkeletonLoader width="65%" height="100%" />;
        const event = item.engineEvent as AiemEventName;
        const eventStyles = {
          [AiemEventName.start]: startedEventStyles,
          [AiemEventName.delete]: deletedEventStyles,
          [AiemEventName.pause]: pausedEventStyles,
        };
        return (
          <div>
            <Item
              data={{
                id: '',
                title: event,
              }}
              styles={eventStyles[event] || {}}
              idField="id"
              labelField="title"
            />
          </div>
        );
      },
    },
    {
      key: AiemColNameKey.engineVersion,
      fieldName: AiemColNameKey.engineVersion,
      name: AiemColName.engineVersion,
      minWidth: 200,
      onRender: (item: AIEM) => <>{!isLoading ? item.engineVersion : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AiemColNameKey.engineName,
      fieldName: AiemColNameKey.engineName,
      name: AiemColName.engineName,
      minWidth: 250,
      onRender: (item: AIEM) => <>{!isLoading ? item.engineName : <SkeletonLoader width="65%" height="100%" />}</>,
    },
  ];

  return (
    <div style={aiemTableStyle(theme)}>
      {!isLoading && aiemData.length === 0 ? (
        <MessageBar messageBarType={MessageBarType.warning}>
          {'No data available for the selected date range'}
        </MessageBar>
      ) : (
        <DetailsList
          columns={[...baseColumns, ...(showComputeResource ? computeResourceColumns : [])]}
          styles={customTableStyles(theme, '480px')}
          items={isLoading ? dummyList : filteredData}
        />
      )}
    </div>
  );
}
