import { Stack } from '@fluentui/react';
import {
  IH2OTheme,
  IKeyValuePairEditorProps,
  IKeyValuePairEditorStyles,
  KeyValuePairEditor,
  KeyValuePairValidationFn,
  KeyValueValidation,
  Sizes,
} from '@h2oai/ui-kit';
import { useState } from 'react';

import { EntityFieldInputProps } from './BasicEntityModelComponents';
import { defaultEntityFormRowStyles } from './DefaultEntityFormRowStyles';
import { LabelAndDescription } from './LabelAndDescription';

const keyValuePairEditorStyles: (t: IH2OTheme) => Partial<IKeyValuePairEditorStyles> = (theme: IH2OTheme) => {
  return {
    root: {
      maxWidth: `830px`,
    },
    header: {
      border: `solid 1px ${theme.semanticColors?.inputBorder}`,
      borderBottom: 'none',
    },
    body: {
      backgroundColor: theme.semanticColors?.contentBackground,
      border: `solid 1px ${theme.semanticColors?.inputBorder}`,
      borderRadius: `0 0 ${Sizes.borderRadius}px ${Sizes.borderRadius}px`,
    },
  };
};

export function KeyValuePairEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  large,
  rootStyles = {},
  editorWidth,
}: EntityFieldInputProps<EntityModel> & { large?: boolean }) {
  const { name, label, description, disabled } = field;
  const [config, setConfig] = useState<Record<string, string>>((model as any)[name]);

  const validation: KeyValuePairValidationFn = (keyValue: {
    key?: string | undefined;
    value?: string | undefined;
  }): KeyValueValidation => {
    const message: KeyValueValidation = {};
    if (!keyValue.key) {
      message.keyValidation = 'The key may not be empty.';
    }
    if (!keyValue.value) {
      message.valueValidation = 'The value may not be empty.';
    }
    return message;
  };

  const props: IKeyValuePairEditorProps = {
    config,
    onUpdateConfig: (newConfig: Record<string, string>) => {
      setConfig(newConfig);
      onChange!(name, newConfig);
    },
    validation,
  };

  return (
    <Stack
      horizontal={large}
      tokens={{ childrenGap: 15 }}
      styles={
        large
          ? { root: defaultEntityFormRowStyles }
          : { root: { maxWidth: '455px', ...defaultEntityFormRowStyles, ...rootStyles } }
      }
    >
      <LabelAndDescription label={label} light={!large} description={description} />
      <KeyValuePairEditor
        {...props}
        readOnly={disabled}
        styles={keyValuePairEditorStyles}
        style={editorWidth ? { width: editorWidth } : {}}
      />
    </Stack>
  );
}
