// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/notebook_engine.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { ProfileConstraintDuration, ProfileConstraintNumeric } from "./profile_constraint_pb";
import type { BigIntString } from "../../../../runtime";

/**
 * The original NotebookEngineProfile data used by NotebookEngine when using the NotebookEngineProfile.
 * For more info about each field see the original NotebookEngineProfile resource.
 *
 * @generated from message ai.h2o.engine.v1.NotebookEngineProfileInfo
 */
export type NotebookEngineProfileInfo = {
/**
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * @generated from field: string display_name = 2;
 */
displayName?: string;
/**
 * @generated from field: int32 priority = 3;
 */
priority?: number;
/**
 * @generated from field: bool enabled = 4;
 */
enabled?: boolean;
/**
 * @generated from field: bool assigned_oidc_roles_enabled = 5;
 */
assignedOidcRolesEnabled?: boolean;
/**
 * @generated from field: repeated string assigned_oidc_roles = 6;
 */
assignedOidcRoles?: string[];
/**
 * @generated from field: optional int32 max_running_engines = 7;
 */
maxRunningEngines?: number;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric cpu_constraint = 8;
 */
cpuConstraint?: ProfileConstraintNumeric;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric gpu_constraint = 9;
 */
gpuConstraint?: ProfileConstraintNumeric;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric memory_bytes_constraint = 10;
 */
memoryBytesConstraint?: ProfileConstraintNumeric;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric storage_bytes_constraint = 11;
 */
storageBytesConstraint?: ProfileConstraintNumeric;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintDuration max_idle_duration_constraint = 12;
 */
maxIdleDurationConstraint?: ProfileConstraintDuration;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintDuration max_running_duration_constraint = 13;
 */
maxRunningDurationConstraint?: ProfileConstraintDuration;
/**
 * @generated from field: string yaml_pod_template_spec = 14;
 */
yamlPodTemplateSpec?: string;
/**
 * @generated from field: string yaml_gpu_tolerations = 15;
 */
yamlGpuTolerations?: string;
/**
 * @generated from field: google.protobuf.Timestamp create_time = 16;
 */
createTime?: string;
/**
 * @generated from field: optional google.protobuf.Timestamp update_time = 17;
 */
updateTime?: string;
/**
 * @generated from field: string creator = 18;
 */
creator?: string;
/**
 * @generated from field: string updater = 19;
 */
updater?: string;
/**
 * @generated from field: string creator_display_name = 20;
 */
creatorDisplayName?: string;
/**
 * @generated from field: string updater_display_name = 21;
 */
updaterDisplayName?: string;
}
;
/**
 * The original NotebookEngineImage data used by NotebookEngine when using the NotebookEngineImage.
 * For more info about each field see the original NotebookEngineImage resource.
 *
 * @generated from message ai.h2o.engine.v1.NotebookEngineImageInfo
 */
export type NotebookEngineImageInfo = {
/**
 * @generated from field: string image = 1;
 */
image?: string;
}
;
/**
 * @generated from message ai.h2o.engine.v1.NotebookEngine
 */
export type NotebookEngine = {
/**
 * Output only. The resource name of the NotebookEngine.
 * Format: `workspaces/*\/notebookEngines/*`.
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Optional. Human-readable name.
 *
 * @generated from field: string display_name = 2;
 */
displayName?: string;
/**
 * Required. The resource name of the NotebookEngineProfile that is assigned to this NotebookEngine.
 * Format is `workspaces/*\/notebookEngineProfiles/*`.
 *
 * @generated from field: string profile = 3;
 */
profile: string;
/**
 * Required. Immutable. The resource name of the NotebookEngineImage that is assigned to this NotebookEngine.
 * Format is `workspaces/*\/notebookEngineImages/*`.
 *
 * @generated from field: string notebook_image = 4;
 */
notebookImage: string;
/**
 * Non-empty default. The amount of CPU units requested by this NotebookEngine.
 *
 * @generated from field: optional int32 cpu = 5;
 */
cpu?: number;
/**
 * Non-empty default. The amount of GPU units requested by this NotebookEngine.
 *
 * @generated from field: optional int32 gpu = 6;
 */
gpu?: number;
/**
 * Non-empty default. The amount of memory in bytes requested by this NotebookEngine.
 *
 * @generated from field: optional int64 memory_bytes = 7;
 */
memoryBytes?: BigIntString;
/**
 * Non-empty default. The amount of storage requested by this NotebookEngine.
 *
 * @generated from field: optional int64 storage_bytes = 8;
 */
storageBytes?: BigIntString;
/**
 * Non-empty default. Maximum time the NotebookEngine can be idle. When exceeded, the NotebookEngine will pause.
 *
 * @generated from field: optional google.protobuf.Duration max_idle_duration = 9;
 */
maxIdleDuration?: string | null;
/**
 * Non-empty default. Maximum time the NotebookEngine can be running. When exceeded, the NotebookEngine will pause.
 *
 * @generated from field: optional google.protobuf.Duration max_running_duration = 10;
 */
maxRunningDuration?: string | null;
/**
 * Output only. The current state of the NotebookEngine.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngine.State state = 11;
 */
state?: NotebookEngine_State;
/**
 * Output only. Indicates whether changes to the resource are in progress.
 *
 * @generated from field: bool reconciling = 12;
 */
reconciling?: boolean;
/**
 * Output only. Globally unique identifier of the resource.
 *
 * @generated from field: string uid = 13;
 */
uid?: string;
/**
 * Output only. NotebookEngineImage data used during the last NotebookEngine startup from the assigned notebook_image.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineImageInfo notebook_image_info = 14;
 */
notebookImageInfo?: NotebookEngineImageInfo;
/**
 * Output only. NotebookEngineProfile data used during the last NotebookEngine startup from the assigned profile.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineProfileInfo profile_info = 15;
 */
profileInfo?: NotebookEngineProfileInfo;
/**
 * Output only. Name of entity that created the NotebookEngineProfile.
 *
 * @generated from field: string creator = 16;
 */
creator?: string;
/**
 * Output only. Name of entity that last updated the NotebookEngineProfile.
 *
 * @generated from field: string updater = 17;
 */
updater?: string;
/**
 * Output only. Human-readable name of entity that created the NotebookEngineProfile.
 *
 * @generated from field: string creator_display_name = 18;
 */
creatorDisplayName?: string;
/**
 * Output only. Human-readable name of entity that last updated the NotebookEngineProfile.
 *
 * @generated from field: string updater_display_name = 19;
 */
updaterDisplayName?: string;
/**
 * Output only. Time when the NotebookEngine was created.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 20;
 */
createTime?: string;
/**
 * Output only. Time when the NotebookEngine was last updated.
 *
 * @generated from field: optional google.protobuf.Timestamp update_time = 21;
 */
updateTime?: string;
/**
 * Output only. Time when the NotebookEngine was resumed.
 *
 * @generated from field: optional google.protobuf.Timestamp resume_time = 22;
 */
resumeTime?: string;
/**
 * Output only. Optional. Time when the NotebookEngine was deleted.
 *
 * @generated from field: optional google.protobuf.Timestamp delete_time = 23;
 */
deleteTime?: string;
/**
 * Output only. Optional. Current time the NotebookEngine is idle.
 *
 * @generated from field: optional google.protobuf.Duration current_idle_duration = 24;
 */
currentIdleDuration?: string;
/**
 * Output only. Optional. Current time the NotebookEngine is running.
 *
 * @generated from field: optional google.protobuf.Duration current_running_duration = 25;
 */
currentRunningDuration?: string;
}
;
/**
 * The state of the NotebookEngine.
 *
 * @generated from enum ai.h2o.engine.v1.NotebookEngine.State
 */
export enum NotebookEngine_State {
/**
 * NotebookEngine state is unspecified or unknown.
 *
 * @generated from enum value: STATE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "STATE_UNSPECIFIED",

/**
 * NotebookEngine is starting.
 *
 * @generated from enum value: STATE_STARTING = 1;
 */
STARTING = "STATE_STARTING",

/**
 * NotebookEngine is running and can be used.
 *
 * @generated from enum value: STATE_RUNNING = 2;
 */
RUNNING = "STATE_RUNNING",

/**
 * NotebookEngine is pausing.
 *
 * @generated from enum value: STATE_PAUSING = 3;
 */
PAUSING = "STATE_PAUSING",

/**
 * NotebookEngine is paused and can be resumed again.
 *
 * @generated from enum value: STATE_PAUSED = 4;
 */
PAUSED = "STATE_PAUSED",

/**
 * NotebookEngine has failed and can be resumed again.
 *
 * @generated from enum value: STATE_FAILED = 5;
 */
FAILED = "STATE_FAILED",

/**
 * NotebookEngine is being deleted.
 *
 * @generated from enum value: STATE_DELETING = 6;
 */
DELETING = "STATE_DELETING",
}
