import { IDetailsListStyles, IRawStyle } from '@fluentui/react';
import { CommonStyles, FontSizes, FontWeights, IH2OTheme, Sizes } from '@h2oai/ui-kit';

export const customTableStyles = (theme: IH2OTheme, contentHeight?: string): Partial<IDetailsListStyles> => ({
  root: {
    selectors: {
      '.ms-DetailsHeader': {
        padding: Sizes.padding,
        paddingRight: 0,
        paddingLeft: 0,
      },
      '.ms-DetailsHeader-cell': {
        borderRight: '1px solid white',
        color: theme.palette?.gray700,
      },
      '.ms-DetailsRow-cell': {
        display: 'flex',
        alignItems: 'center',
        fontSize: FontSizes.xsmall,
        color: theme.palette?.gray600,
        padding: Sizes.padding,
      },
      '.ms-DetailsRow:hover': {
        backgroundColor: theme.palette?.yellow100,
      },
      '.ms-DetailsList-contentWrapper': {
        overflowY: 'auto',
        height: contentHeight ? contentHeight : '450px',
        width: 'fit-content',
      },
    },
  },
});

export const tagStyle = (theme: IH2OTheme): IRawStyle => ({
  height: 20,
  lineHeight: 20,
  padding: '0 4px',
  borderRadius: Sizes.borderRadius,
  fontSize: FontSizes.xxsmall,
  fontWeight: FontWeights.semiBold,
  marginRight: 4,
  backgroundColor: theme.semanticColors?.tagBackground,
  color: theme.semanticColors?.tagText,
  ...CommonStyles.truncateString,
  '&.loading': {
    overflow: 'visible',
  },
});

export const messageBarContainerStyles = {
  position: 'fixed' as const,
  top: Sizes.padding,
  right: Sizes.padding,
  zIndex: 9999999,
  width: 'auto',
};
