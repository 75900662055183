import { MessageBarType } from '@fluentui/react';
import { DetailsList, MessageBar, useTheme } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import {
  GaugeMetricDailyStatRequest,
  MlopsGaugeMetricResponse,
} from '../../../../../admin-center/gen/mlops/gauge_metric/gaugemetric_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { customTableStyles } from '../../../common/common.styles';
import { MLOpsGaugeType, MLOpsTotalCountKey } from '../../../constants';
import { GaugeMetricTableStyle } from '../MLOps.styles';

interface GaugeMetricSummaryProps {
  date: string;
}

interface DailyGaugeMetric {
  ts: string;
  type: string;
  totalCount: number;
}

export default function GaugeMetricTable({ date }: GaugeMetricSummaryProps) {
  const service = useAdminCenterService();
  const theme = useTheme();
  const [gaugeMetricSummaryData, setGaugeMetricSummaryData] = useState<DailyGaugeMetric[]>([]);

  const fetchGaugeMetricDailyEvents = async () => {
    try {
      const request: GaugeMetricDailyStatRequest = {
        date: date,
      };

      const response: MlopsGaugeMetricResponse = await service.getMlopsGaugeDailyMetrics(request);
      const formattedData = (response.events ?? []).map((event) => {
        return {
          ts: event.ts ?? '-',
          type: event.type ?? '-',
          totalCount: event.totalCount ?? 0,
        };
      });

      setGaugeMetricSummaryData(formattedData);
    } catch (error) {
      console.error('Error fetching gauge metric events:', error);
    }
  };

  useEffect(() => {
    fetchGaugeMetricDailyEvents();
  }, [date]);

  const metricKeyMap: Record<string, string> = {
    [MLOpsGaugeType.user]: MLOpsTotalCountKey.user,
    [MLOpsGaugeType.project]: MLOpsTotalCountKey.project,
    [MLOpsGaugeType.dataset]: MLOpsTotalCountKey.dataset,
    [MLOpsGaugeType.experiment]: MLOpsTotalCountKey.experiment,
    [MLOpsGaugeType.registeredModel]: MLOpsTotalCountKey.registeredModel,
    [MLOpsGaugeType.registeredModelVersion]: MLOpsTotalCountKey.registeredModelVersion,
    [MLOpsGaugeType.deployment]: MLOpsTotalCountKey.deployment,
  };

  // Create the metricData object
  const metricData = gaugeMetricSummaryData.reduce(
    (acc, event) => {
      const metricKey = metricKeyMap[event.type];
      if (metricKey) {
        acc[metricKey as keyof typeof acc] = event.totalCount ?? 0;
      }
      return acc;
    },
    {
      userTotalCount: 0,
      projectTotalCount: 0,
      datasetTotalCount: 0,
      experimentTotalCount: 0,
      registedModelTotalCount: 0,
      registedModelVersionsTotalCount: 0,
      deploymentTotalCount: 0,
    }
  );

  return (
    <div style={GaugeMetricTableStyle(theme)}>
      {[metricData].length === 0 ? (
        <MessageBar messageBarType={MessageBarType.warning}>{'MLOps data are empty'}</MessageBar>
      ) : (
        <DetailsList
          columns={[
            {
              key: MLOpsTotalCountKey.user,
              fieldName: MLOpsTotalCountKey.user,
              name: MLOpsGaugeType.user,
              minWidth: 200,
            },
            {
              key: MLOpsTotalCountKey.project,
              fieldName: MLOpsTotalCountKey.project,
              name: MLOpsGaugeType.project,
              minWidth: 200,
            },
            {
              key: MLOpsTotalCountKey.dataset,
              fieldName: MLOpsTotalCountKey.dataset,
              name: MLOpsGaugeType.dataset,
              minWidth: 200,
            },
            {
              key: MLOpsTotalCountKey.experiment,
              fieldName: MLOpsTotalCountKey.experiment,
              name: MLOpsGaugeType.experiment,
              minWidth: 200,
            },
            {
              key: MLOpsTotalCountKey.registeredModel,
              fieldName: MLOpsTotalCountKey.registeredModel,
              name: MLOpsGaugeType.registeredModel,
              minWidth: 200,
            },
            {
              key: MLOpsTotalCountKey.registeredModelVersion,
              fieldName: MLOpsTotalCountKey.registeredModelVersion,
              name: MLOpsGaugeType.registeredModelVersion,
              minWidth: 200,
            },
            {
              key: MLOpsTotalCountKey.deployment,
              fieldName: MLOpsTotalCountKey.deployment,
              name: MLOpsGaugeType.deployment,
              minWidth: 200,
            },
          ]}
          items={[metricData]}
          styles={customTableStyles(theme, '50px')}
        />
      )}
    </div>
  );
}
