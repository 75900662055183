import { DeploymentComposition } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_composition_pb';
import {
  Deployment,
  ShadowDeployment,
  ShadowElement,
  SingleDeployment,
  SplitDeployment,
  SplitElement,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_pb';
import {
  CreateDeploymentRequest,
  DeleteDeploymentRequest,
  UpdateModelDeploymentRequest,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_service_pb';
import { DeploymentStatus } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_status_pb';
import { KubernetesConfigurationShortcut } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/kubernetes_configuration_pb';
import {
  KubernetesResourceRequirement,
  KubernetesResourceSpec,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/kubernetes_resource_spec_pb';
import { GetDefaultRuntimeKubernetesResourceSpecRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/kubernetes_resource_spec_service_pb';
import { Monitor } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/monitor_pb';
import {
  AuthenticationPassphrase,
  DisabledStateSecurityType,
  PassphraseHashSecurityType,
  Security,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/security_pb';
import { DeploymentService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_service_connect';
import { KubernetesResourceSpecService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/kubernetes_resource_spec_service_connect';
import { RegisteredModel } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_pb';
import { GetModelVersionForExperimentRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_service_pb';
import { RegisteredModelVersionService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_version_service_connect';
import { PartialMessage } from '@bufbuild/protobuf';
import { Client, createClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IDropdownOption, IStyle, MessageBarType, Toggle } from '@fluentui/react';
import {
  Button,
  Dropdown,
  IH2OTheme,
  Info,
  KeyValuePairEditor,
  ListRow,
  Slider,
  TextField,
  buttonStylesPrimary,
  buttonStylesStealth,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
// TODO: Move to global context.
import { Tag } from '../Orchestrator/WorkflowTabExecutions';
import { getRandomAdjective } from './adjectives';
import { FormSection } from './BatchScoringDetail';
import { ENDPOINTS, ROUTES } from './constants';
import { SecurityType } from './DeploymentDetail';
import { DeploymentItem, getStateProps } from './Deployments';
import { DeploymentOptions } from './Models';
import ModelSelector from './ModelSelector';
import { useProjects } from './ProjectProvider';

export interface IDeploymentTabDetailProps {
  deployment?: Deployment;
  deploymentStatus?: DeploymentStatus;
  deploymentEnvironmentId?: string;
  deploymentClient: Client<typeof DeploymentService>;
  defaultSecurityLevel?: SecurityType;
  deployModelVersionId?: string;
  isNew: boolean;
  isNotHealthy: boolean;
}

export type DeploymentTypeKey = 'single_model' | 'ab_test' | 'champion_challenger';
export type DropdownOption = { key: string | number; text: string };

interface IDeploymentTabDetailStyles {
  form: IStyle;
  formTitle?: IStyle;
  metadata: IStyle;
}

const DEFAULT_TRAFFIC_WEIGHT = 50;
const MAX_TRAFFIC_WIGHT = 100;

const deploymentTabDetailStyles = (theme: IH2OTheme): IDeploymentTabDetailStyles => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: 20,
      margin: '0px 40px',
      // TODO: Remove once theme is used.
      borderColor: theme.semanticColors?.bodyBackground,
    },
    formTitle: {
      marginBottom: 8,
    },
    metadata: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
  }),
  listRowColumns = [
    {
      data: {
        headerFieldName: 'displayName',
        listCellProps: {
          emptyMessage: 'No Description',
          onRenderHeader: ({ displayName }: DeploymentItem) => <h3 style={{ padding: 0, margin: 0 }}>{displayName}</h3>,
        },
      },
      key: 'title',
      fieldName: 'createdBy',
      maxWidth: 600,
      minWidth: 300,
      name: 'Title',
    },
    {
      key: 'status',
      fieldName: 'status',
      maxWidth: 200,
      minWidth: 140,
      name: 'Status',
      data: {
        listCellProps: {
          onRenderText: ({ status }: DeploymentItem) => {
            return <Tag title={getStateProps(status?.state).name} color={getStateProps(status?.state).color} />;
          },
        },
      },
    },
    {
      key: 'createdTime',
      fieldName: 'createdTimeLocal',
      maxWidth: 200,
      minWidth: 130,
      name: 'Created at',
    },
    {
      key: 'lastModifiedTime',
      fieldName: 'lastModifiedTimeLocal',
      maxWidth: 200,
      minWidth: 130,
      name: 'Last modified at',
    },
    {
      key: 'buttons',
      name: '',
      maxWidth: 260,
      minWidth: 180,
      data: {
        listCellProps: {
          onRenderText: ({ onDeleteDeployment, viewOnly, actionsDisabled }: DeploymentItem) => (
            <>
              {!viewOnly ? (
                <Button
                  text="Delete"
                  onClick={onDeleteDeployment}
                  style={{
                    color: 'var(--h2o-red400)',
                    borderColor: 'var(--h2o-red400)',
                  }}
                  disabled={actionsDisabled}
                  iconProps={{ iconName: 'Delete', style: { color: 'var(--h2o-red400)' } }}
                />
              ) : null}
            </>
          ),
        },
      },
    },
  ],
  sliderContainerStyles = {
    root: {
      maxWidth: 460,
    },
  },
  getListRowStyles = (theme: IH2OTheme) => ({
    details: {
      padding: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    root: {
      borderRadius: 4,
      backgroundColor: theme.semanticColors?.contentBackground,
    },
    contents: {
      paddingTop: 4,
      paddingBottom: 16,
    },
    row: {
      '&:hover': {
        background: 'transparent',
      },
      '.h2o-ListCell-root:first-child': {
        paddingLeft: 20,
      },
      height: 80,
    },
  }),
  dropdownOptions: DropdownOption[] = [
    { key: 'single_model', text: 'Single model' },
    { key: 'ab_test', text: 'A/B Test' },
    { key: 'champion_challenger', text: 'Champion/Challenger' },
  ],
  getDeploymentType = (deploymentCase?: string) => {
    switch (deploymentCase) {
      case 'singleDeployment':
        return 'single_model';
      case 'splitDeployment':
        return 'ab_test';
      case 'shadowDeployment':
        return 'champion_challenger';
      default:
        return 'single_model';
    }
  },
  getSecurityDropdownOptions = (securityLevel?: SecurityType, isNew?: boolean) => {
    const item_plain = { key: 'passphrase_plain', text: 'Passphrase (Stored as plain text)' },
      item_hashed = { key: 'passphrase_hashed', text: 'Passphrase (Stored hashed)' },
      item_no_security = { key: 'no_security', text: 'No security' };

    if (isNew) {
      return [item_plain, item_hashed, item_no_security] as DropdownOption[];
    } else if (securityLevel === 'passphrase_hashed_deprecated') {
      return [
        item_hashed,
        { key: 'passphrase_hashed_deprecated', text: 'Passphrase (BCRYPT-Hashed) [DEPRECATED]', disabled: true },
      ] as DropdownOption[];
    } else if (securityLevel === 'passphrase_hashed') {
      return [item_hashed, item_plain] as DropdownOption[];
    } else if (securityLevel === 'passphrase_plain') {
      return [item_plain, item_hashed] as DropdownOption[];
    } else {
      return [item_plain, item_hashed, item_no_security] as DropdownOption[];
    }
  },
  getRandomHash = () =>
    new Array(20)
      .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
      .map((x) =>
        (function (chars) {
          const umax = Math.pow(2, 32),
            r = new Uint32Array(1),
            max = umax - (umax % chars.length);
          do {
            crypto.getRandomValues(r);
          } while (r[0] > max);
          return chars[r[0] % chars.length];
        })(x)
      )
      .join(''),
  getPBKDF2Hash = async (passphrase: string) => {
    const encoder = new TextEncoder(),
      passwordBuffer = encoder.encode(passphrase),
      salt = crypto.getRandomValues(new Uint8Array(16)),
      iterations = 100000,
      key = await crypto.subtle.importKey('raw', passwordBuffer, 'PBKDF2', false, ['deriveBits', 'deriveKey']),
      hashFunc = 'sha256',
      derivedKey = await crypto.subtle.deriveKey(
        {
          name: 'PBKDF2',
          salt,
          iterations,
          hash: 'SHA-256',
        },
        key,
        { name: 'AES-GCM', length: 256 },
        true,
        ['encrypt', 'decrypt']
      ),
      b64Salt = btoa(String.fromCharCode(...salt)),
      b64DerivedKey = btoa(String.fromCharCode(...new Uint8Array(await crypto.subtle.exportKey('raw', derivedKey))));
    return `pbkdf2:${hashFunc}:${iterations}$${b64Salt}$${b64DerivedKey}`;
  };

export const capitalize = <T extends string>(text: T) =>
  text ? ((text[0].toUpperCase() + text.slice(1)) as Capitalize<typeof text>) : text;

export const DeploymentTabDetail = ({
  deployment,
  deploymentStatus,
  deploymentEnvironmentId,
  deploymentClient,
  deployModelVersionId,
  defaultSecurityLevel,
  isNew,
  isNotHealthy,
}: IDeploymentTabDetailProps) => {
  const theme = useTheme(),
    classNames = useClassNames<IDeploymentTabDetailStyles, ClassNamesFromIStyles<IDeploymentTabDetailStyles>>(
      'deploymentTabDetail',
      deploymentTabDetailStyles(theme)
    ),
    history = useHistory(),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID, permissions } = useProjects(),
    [deploymentType, setDeploymentType] = React.useState<DeploymentTypeKey>(
      dropdownOptions[0].key as DeploymentTypeKey
    ),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    registeredModelVersionClient = createClient(RegisteredModelVersionService, storageTransport),
    kubeResourceSpecClient = createClient(KubernetesResourceSpecService, deploymentTransport),
    [selectedModels, setSelectedModels] = React.useState<(RegisteredModel | undefined)[]>([]),
    [defaultDeploymentCompositionA, setDefaultDeploymentCompositionA] = React.useState<
      DeploymentComposition | undefined
    >(),
    [defaultDeploymentCompositionB, setDefaultDeploymentCompositionB] = React.useState<
      DeploymentComposition | undefined
    >(),
    [defaultSelectedModelIds, setDefaultSelectedModelIds] = React.useState<string[]>(),
    [defaultSelectedExperimentIds, setDefaultSelectedExperimentIds] = React.useState<string[]>(),
    [requestsConfig, setRequestsConfig] = React.useState<{ [key: string]: string }>({}),
    [limitsConfig, setLimitsConfig] = React.useState<{ [key: string]: string }>({}),
    [trafficDistribution, setTrafficDistribution] = React.useState<number>(DEFAULT_TRAFFIC_WEIGHT),
    [isWidgetLoading, setIsWidgetLoading] = React.useState(true),
    [isMonitoringEnabled, setIsMonitoringEnabled] = React.useState<boolean>(false),
    [deploymentOptions, setDeploymentOptions] = React.useState<Partial<DeploymentOptions> | undefined>(undefined),
    [deploymentName, setDeploymentName] = React.useState<string>(
      isNew ? `${capitalize(getRandomAdjective())} deployment` : ''
    ),
    [deploymentDescription, setDeploymentDescription] = React.useState<string>(''),
    [passphrase, setPassphrase] = React.useState(getRandomHash()),
    [securityLevel, setSecurityLevel] = React.useState<SecurityType>(defaultSecurityLevel || 'passphrase_plain'),
    [replicas, setReplicas] = React.useState<number>(1),
    // TODO: Handle loading.
    [loading, setLoading] = React.useState(true),
    [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false),
    [widgetRowActionsDisabled, setWidgetRowActionsDisabled] = React.useState(false),
    [isAdvancedExpanded, setAdvancedExpanded] = React.useState(false),
    loadStateRef = React.useRef({
      createDeployment: false,
      updateDeployment: false,
      fetchingExistingDeploymentDetails: false,
      fetchingDefaultRuntimeKubernetesResourceSpec: false,
      deletingDeployment: false,
    }),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.createDeployment &&
        !loadStateRef.current.updateDeployment &&
        !loadStateRef.current.fetchingExistingDeploymentDetails &&
        !loadStateRef.current.fetchingDefaultRuntimeKubernetesResourceSpec &&
        !loadStateRef.current.deletingDeployment
      ) {
        setLoading(false);
      }
    },
    onChangeDeploymentType = React.useCallback((_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
      if (item) {
        setDeploymentType(item.key as DeploymentTypeKey);
        setTrafficDistribution(50);
        setSelectedModels((selectedModels) => (item.key === 'single_model' ? [selectedModels?.[0]] : selectedModels));
      }
    }, []),
    onChangeTrafficDistribution = (value?: number) => {
      if (value) setTrafficDistribution(Number(value));
    },
    deploymentDetailProps = React.useMemo(
      () => ({
        createdBy: deployment?.userInfo?.ownerName || deployment?.userInfo?.ownerId || '',
        createdTime: deployment?.createdTime
          ? new Date(Number(deployment?.createdTime?.seconds) * 1000).toLocaleString()
          : '',
        lastModifiedTime: deployment?.lastModifiedTime
          ? new Date(Number(deployment?.lastModifiedTime?.seconds) * 1000).toLocaleString()
          : '',
      }),
      [deployment]
    ),
    getDefaultRuntimeKubernetesResourceSpec = React.useCallback(async () => {
      loadStateRef.current.fetchingDefaultRuntimeKubernetesResourceSpec = true;
      setIsWidgetLoading(true);
      try {
        const response = await kubeResourceSpecClient.getDefaultRuntimeKubernetesResourceSpec(
          new GetDefaultRuntimeKubernetesResourceSpecRequest({})
        );
        const resourceSpec: KubernetesResourceSpec | undefined = response?.defaultRuntimeKubernetesResourceSpec;
        if (response && !resourceSpec) {
          console.error('No default runtime Kubernetes resource spec found in the response.');
        }
        setRequestsConfig(resourceSpec?.kubernetesResourceRequirement?.requests || {});
        setLimitsConfig(resourceSpec?.kubernetesResourceRequirement?.limits || {});
      } catch (err) {
        const message = `Failed to fetch default runtime kubernetes resource spec: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.fetchingDefaultRuntimeKubernetesResourceSpec = false;
        evaluateLoading();
      }
    }, []),
    createDeployment = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID || !deploymentOptions || !deploymentEnvironmentId) {
        return;
      }
      loadStateRef.current.createDeployment = true;
      setLoading(true);
      setIsSubmitDisabled(true);
      try {
        const primaryElement = {
          deploymentComposition: deploymentOptions.firstModelDeploymentComposition,
          weight: deploymentType === 'ab_test' ? trafficDistribution : undefined,
          environmentSpec: {
            case: 'kubernetesResourceSpec',
            value: new KubernetesResourceSpec({
              replicas,
              kubernetesResourceRequirement: new KubernetesResourceRequirement({
                requests: requestsConfig,
                limits: limitsConfig,
              }),
            }),
          },
          environmentShortcut: {
            case: 'kubernetesConfigurationShortcut',
            value: new KubernetesConfigurationShortcut({
              // TODO: Add support for toleration and affinity into the UI.
              kubernetesAffinityShortcutName: '',
              kubernetesTolerationShortcutName: '',
            }),
          },
        };
        const secondaryElement = {
          deploymentComposition: deploymentOptions.secondModelDeploymentComposition,
          weight: deploymentType === 'ab_test' ? MAX_TRAFFIC_WIGHT - trafficDistribution : undefined,
          environmentSpec: {
            case: 'kubernetesResourceSpec',
            value: new KubernetesResourceSpec({
              replicas,
              kubernetesResourceRequirement: new KubernetesResourceRequirement({
                requests: requestsConfig,
                limits: limitsConfig,
              }),
            }),
          },
          environmentShortcut: {
            case: 'kubernetesConfigurationShortcut',
            value: new KubernetesConfigurationShortcut({
              kubernetesAffinityShortcutName: '',
              kubernetesTolerationShortcutName: '',
            }),
          },
        };

        const deployCreateDeploymentRequest = new CreateDeploymentRequest({
          deployment: new Deployment({
            projectId: ACTIVE_PROJECT_ID,
            deploymentEnvironmentId,
            deployment:
              deploymentType === 'single_model'
                ? {
                    case: 'singleDeployment',
                    value: new SingleDeployment(primaryElement as PartialMessage<SingleDeployment>),
                  }
                : deploymentType === 'ab_test'
                ? {
                    case: 'splitDeployment',
                    value: new SplitDeployment({
                      splitElements: [
                        primaryElement as PartialMessage<SplitElement>,
                        secondaryElement as PartialMessage<SplitElement>,
                      ],
                    }),
                  }
                : {
                    case: 'shadowDeployment',
                    value: new ShadowDeployment({
                      primaryElement: primaryElement as PartialMessage<ShadowElement>,
                      secondaryElement: secondaryElement as PartialMessage<ShadowElement>,
                    }),
                  },
            security:
              // TODO: Add support for token auth.
              securityLevel === 'no_security'
                ? new Security({
                    authentication: {
                      case: 'disabledSecurity',
                      value: DisabledStateSecurityType.DISABLED,
                    },
                  })
                : new Security({
                    authentication: {
                      case: 'passphrase',
                      value: new AuthenticationPassphrase({
                        // TODO: Check correct use of security option.
                        passphraseHashType:
                          securityLevel === 'passphrase_plain'
                            ? PassphraseHashSecurityType.PASSPHRASE_HASH_TYPE_PLAINTEXT
                            : securityLevel === 'passphrase_hashed_deprecated'
                            ? PassphraseHashSecurityType.PASSPHRASE_HASH_TYPE_BCRYPT
                            : PassphraseHashSecurityType.PASSPHRASE_HASH_TYPE_PBKDF2,
                        hash: securityLevel === 'passphrase_hashed' ? await getPBKDF2Hash(passphrase) : passphrase,
                      }),
                    },
                  }),
            displayName: deploymentName,
            description: deploymentDescription,
            monitor: new Monitor({
              enable: isMonitoringEnabled,
              // TODO: Add drift monitoring configuration in the UI - driftMonitoringEnabled,
              storeScoringTransactionEnable: false,
            }),
            // TODO: Add monitoring options.
          }),
        });

        await deploymentClient.createDeployment(deployCreateDeploymentRequest);

        const message = `Deployment "${deploymentName}" created successfully.`;
        addToast({
          messageBarType: MessageBarType.success,
          message,
        });
        history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}`);
      } catch (err) {
        const message = `Failed to create deployment: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.createDeployment = false;
        evaluateLoading();
        setIsSubmitDisabled(false);
      }
    }, [
      ACTIVE_PROJECT_ID,
      addToast,
      deploymentEnvironmentId,
      deploymentOptions,
      deploymentName,
      deploymentDescription,
      isMonitoringEnabled,
      deploymentType,
      passphrase,
      securityLevel,
      trafficDistribution,
      requestsConfig,
      limitsConfig,
      replicas,
    ]),
    updateDeployment = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID || !deploymentOptions || !deploymentEnvironmentId) {
        return;
      }
      loadStateRef.current.updateDeployment = true;
      setLoading(true);
      setIsSubmitDisabled(true);
      try {
        const primaryElement = {
          deploymentComposition: deploymentOptions.firstModelDeploymentComposition,
          weight: deploymentType === 'ab_test' ? trafficDistribution : undefined,
          environmentSpec: {
            case: 'kubernetesResourceSpec',
            value: new KubernetesResourceSpec({
              replicas,
              kubernetesResourceRequirement: new KubernetesResourceRequirement({
                requests: requestsConfig,
                limits: limitsConfig,
              }),
            }),
          },
          environmentShortcut: {
            case: 'kubernetesConfigurationShortcut',
            value: new KubernetesConfigurationShortcut({
              // TODO: Add support for toleration and affinity into the UI.
              kubernetesAffinityShortcutName: '',
              kubernetesTolerationShortcutName: '',
            }),
          },
        };
        const secondaryElement = {
          deploymentComposition: deploymentOptions.secondModelDeploymentComposition,
          weight: deploymentType === 'ab_test' ? MAX_TRAFFIC_WIGHT - trafficDistribution : undefined,
          environmentSpec: {
            case: 'kubernetesResourceSpec',
            value: new KubernetesResourceSpec({
              replicas,
              kubernetesResourceRequirement: new KubernetesResourceRequirement({
                requests: requestsConfig,
                limits: limitsConfig,
              }),
            }),
          },
          environmentShortcut: {
            case: 'kubernetesConfigurationShortcut',
            value: new KubernetesConfigurationShortcut({
              kubernetesAffinityShortcutName: '',
              kubernetesTolerationShortcutName: '',
            }),
          },
        };

        const updateModelDeploymentRequest = new UpdateModelDeploymentRequest({
          deployment: new Deployment({
            id: deployment?.id,
            projectId: ACTIVE_PROJECT_ID,
            deploymentEnvironmentId,
            deployment:
              deploymentType === 'single_model'
                ? {
                    case: 'singleDeployment',
                    value: new SingleDeployment(primaryElement as PartialMessage<SingleDeployment>),
                  }
                : deploymentType === 'ab_test'
                ? {
                    case: 'splitDeployment',
                    value: new SplitDeployment({
                      splitElements: [
                        primaryElement as PartialMessage<SplitElement>,
                        secondaryElement as PartialMessage<SplitElement>,
                      ],
                    }),
                  }
                : {
                    case: 'shadowDeployment',
                    value: new ShadowDeployment({
                      primaryElement: primaryElement as PartialMessage<ShadowElement>,
                      secondaryElement: secondaryElement as PartialMessage<ShadowElement>,
                    }),
                  },
            security: new Security({
              authentication: {
                case: 'passphrase',
                value: new AuthenticationPassphrase({
                  // TODO: Check correct use of security option.
                  passphraseHashType: PassphraseHashSecurityType.PASSPHRASE_HASH_TYPE_PLAINTEXT,
                  hash: 'plain_text',
                }),
              },
            }),
            // TODO: Add support for updating deployment name and description when supported by the API.
            displayName: deploymentName,
            description: deploymentDescription,
            createdTime: deployment?.createdTime,
            lastModifiedTime: deployment?.lastModifiedTime,
            revisionId: deployment?.revisionId,
            revisionCreateTime: deployment?.revisionCreateTime,
            userInfo: deployment?.userInfo,
            customCors: deployment?.customCors,
            monitor: new Monitor({
              enable: isMonitoringEnabled,
              // TODO: Add drift monitoring configuration in the UI - driftMonitoringEnabled,
              storeScoringTransactionEnable: true,
            }),
            // TODO: Add support for updating deployment monitoring options.
            monitoringOptions: deployment?.monitoringOptions,
          }),
        });

        const response = await deploymentClient.updateModelDeployment(updateModelDeploymentRequest);

        if (response && response.deployment) {
          const deploymentId = response.deployment.id;
          const message = `Deployment "${deploymentName}" (${deploymentId}) updated successfully.`;
          addToast({
            messageBarType: MessageBarType.success,
            message,
          });
          history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}`);
        } else {
          console.error('Failed to update deployment: No deployment information in response.');
        }
      } catch (err) {
        const message = `Failed to update deployment: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.updateDeployment = false;
        evaluateLoading();
        setIsSubmitDisabled(false);
      }
    }, [
      ACTIVE_PROJECT_ID,
      addToast,
      deploymentEnvironmentId,
      deploymentOptions,
      deploymentName,
      deploymentDescription,
      isMonitoringEnabled,
      deployment?.id,
      trafficDistribution,
      deploymentType,
      requestsConfig,
      limitsConfig,
      replicas,
    ]),
    deleteDeployment = React.useCallback(
      async (deploymentId: string) => {
        loadStateRef.current.deletingDeployment = true;
        setWidgetRowActionsDisabled(true);
        try {
          const deleteDeploymentBody = new DeleteDeploymentRequest({
            id: deploymentId,
          });
          await deploymentClient.deleteDeployment(deleteDeploymentBody);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Deployment deleted successfully.',
          });
          history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}`);
        } catch (err) {
          const message = `Failed to delete deployment: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.deletingDeployment = false;
          evaluateLoading();
          setWidgetRowActionsDisabled(false);
        }
      },
      [addToast, ACTIVE_PROJECT_ID]
    ),
    keyValuePairEditorStyles = React.useMemo(
      () => ({
        root: {
          backgroundColor: theme.semanticColors?.contentBackground,
          maxWidth: 460,
          border: `1px solid ${theme.semanticColors?.inputBorder}`,
          borderRadius: 4,
        },
      }),
      [theme.semanticColors?.contentBackground, theme.semanticColors?.inputBorder]
    );

  React.useEffect(() => {
    if (!ACTIVE_PROJECT_ID) return;
    if (deployment) {
      setLoading(true);
      loadStateRef.current.fetchingExistingDeploymentDetails = true;

      setDeploymentName(deployment.displayName || '');
      setDeploymentType(getDeploymentType(deployment.deployment?.case));
      setPassphrase(
        deployment.security?.authentication?.case === 'passphrase' && securityLevel === 'passphrase_plain'
          ? deployment.security.authentication.value.hash
          : getRandomHash()
      );
      setDeploymentDescription(deployment.description || '');
      if (deployment.deployment?.case === 'singleDeployment') {
        setDefaultDeploymentCompositionA(deployment.deployment.value.deploymentComposition);
        setRequestsConfig(
          deployment.deployment?.value.environmentSpec?.value?.kubernetesResourceRequirement?.requests || {}
        );
        setLimitsConfig(
          deployment.deployment?.value.environmentSpec?.value?.kubernetesResourceRequirement?.limits || {}
        );
        setReplicas(deployment.deployment.value.environmentSpec?.value?.replicas || 1);

        registeredModelVersionClient
          .getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({
              experimentId: deployment.deployment.value.deploymentComposition?.experimentId || '',
            })
          )
          .then((response) => {
            const registeredModelVersion = response?.registeredModelVersion?.[0];
            setDefaultSelectedModelIds([registeredModelVersion?.registeredModelId || '']);
            setDefaultSelectedExperimentIds([registeredModelVersion?.experimentId || '']);
          })
          .catch((err) => {
            const message = `Failed to fetch model version for experiment: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
          });
      }
      if (deployment.deployment?.case === 'splitDeployment') {
        setDefaultDeploymentCompositionA(deployment.deployment.value.splitElements[0].deploymentComposition);
        setDefaultDeploymentCompositionB(deployment.deployment.value.splitElements[1].deploymentComposition);
        setTrafficDistribution(deployment.deployment.value.splitElements[0].weight || DEFAULT_TRAFFIC_WEIGHT);
        setRequestsConfig(
          deployment.deployment?.value?.splitElements[0].environmentSpec?.value?.kubernetesResourceRequirement
            ?.requests || {}
        );
        setLimitsConfig(
          deployment.deployment?.value.splitElements[0].environmentSpec?.value?.kubernetesResourceRequirement?.limits ||
            {}
        );
        setReplicas(deployment.deployment.value.splitElements[0].environmentSpec?.value?.replicas || 1);

        const firstRequest = registeredModelVersionClient
          .getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({
              experimentId: deployment.deployment.value.splitElements[0].deploymentComposition?.experimentId || '',
            })
          )
          .then((response) => response?.registeredModelVersion?.[0])
          .catch((err) => {
            const message = `Failed to fetch model version for experiment: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
          });

        const secondRequest = registeredModelVersionClient
          .getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({
              experimentId: deployment.deployment.value.splitElements[1].deploymentComposition?.experimentId || '',
            })
          )
          .then((response) => response?.registeredModelVersion?.[0])
          .catch((err) => {
            const message = `Failed to fetch second model version for experiment: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
          });

        Promise.all([firstRequest, secondRequest]).then(([firstModelVersion, secondModelVersion]) => {
          setDefaultSelectedModelIds([
            firstModelVersion?.registeredModelId || '',
            secondModelVersion?.registeredModelId || '',
          ]);
          setDefaultSelectedExperimentIds([
            firstModelVersion?.experimentId || '',
            secondModelVersion?.experimentId || '',
          ]);
        });
      }

      if (deployment.deployment?.case === 'shadowDeployment') {
        setDefaultDeploymentCompositionA(deployment.deployment.value.primaryElement?.deploymentComposition);
        setDefaultDeploymentCompositionB(deployment.deployment.value.secondaryElement?.deploymentComposition);
        setRequestsConfig(
          deployment.deployment?.value?.primaryElement?.environmentSpec?.value?.kubernetesResourceRequirement
            ?.requests || {}
        );
        setLimitsConfig(
          deployment.deployment?.value.primaryElement?.environmentSpec?.value?.kubernetesResourceRequirement?.limits ||
            {}
        );
        setReplicas(deployment.deployment.value.primaryElement?.environmentSpec?.value?.replicas || 1);

        const primaryRequest = registeredModelVersionClient
          .getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({
              experimentId: deployment.deployment.value.primaryElement?.deploymentComposition?.experimentId || '',
            })
          )
          .then((response) => response?.registeredModelVersion?.[0])
          .catch((err) => {
            const message = `Failed to fetch model version for experiment: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
          });

        const secondaryRequest = registeredModelVersionClient
          .getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({
              experimentId: deployment.deployment.value.secondaryElement?.deploymentComposition?.experimentId || '',
            })
          )
          .then((response) => response?.registeredModelVersion?.[0])
          .catch((err) => {
            const message = `Failed to fetch second model version for experiment: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
          });

        Promise.all([primaryRequest, secondaryRequest]).then(([firstModelVersion, secondModelVersion]) => {
          setDefaultSelectedModelIds([
            firstModelVersion?.registeredModelId || '',
            secondModelVersion?.registeredModelId || '',
          ]);
          setDefaultSelectedExperimentIds([
            firstModelVersion?.experimentId || '',
            secondModelVersion?.experimentId || '',
          ]);
        });
      }

      setIsMonitoringEnabled(deployment.monitor?.enable || false);
      loadStateRef.current.fetchingExistingDeploymentDetails = false;
      evaluateLoading();
    }
  }, [deployment, ACTIVE_PROJECT_ID]);

  React.useEffect(() => {
    setSecurityLevel(securityLevel);
  }, [defaultSecurityLevel]);

  React.useEffect(() => {
    if (isNew) void getDefaultRuntimeKubernetesResourceSpec();
  }, [isNew]);

  React.useEffect(() => {
    // Disables submit button if deployment options are not set.
    if (!deploymentType) return;
    if (deploymentType === 'single_model') {
      setIsSubmitDisabled(!deploymentOptions?.firstModelDeploymentComposition);
    } else {
      setIsSubmitDisabled(
        !deploymentOptions?.firstModelDeploymentComposition || !deploymentOptions?.secondModelDeploymentComposition
      );
    }
  }, [
    deploymentType,
    deploymentOptions?.firstModelDeploymentComposition,
    deploymentOptions?.secondModelDeploymentComposition,
  ]);

  React.useEffect(() => {
    // Collapses advanced settings when model is unselected.
    if (!selectedModels?.[0] && isAdvancedExpanded) {
      setAdvancedExpanded(false);
    }
  }, [selectedModels]);

  return (
    <>
      <div className={classNames.form}>
        {deploymentStatus?.state ? (
          <>
            <ListRow
              columns={listRowColumns}
              contents={<div>{deploymentStatus?.message || 'No details available.'}</div>}
              data={{
                ...deployment,
                status: deploymentStatus,
                createdBy: `Created by ${deploymentDetailProps.createdBy}`,
                lastModifiedTimeLocal: deploymentDetailProps.lastModifiedTime,
                createdTimeLocal: deploymentDetailProps.createdTime,
                viewOnly: !permissions?.canWrite,
                onDeleteDeployment: () => void deleteDeployment(deployment?.id || ''),
                actionsDisabled: widgetRowActionsDisabled,
              }}
              styles={getListRowStyles(theme)}
            />
            <div style={{ height: 25 }}></div>
          </>
        ) : null}
        <TextField
          label="Deployment name"
          required
          onChange={(_ev, value) => {
            setDeploymentName(value || '');
          }}
          value={deploymentName}
          disabled={!isNew || !permissions.canWrite}
          styles={{ root: { maxWidth: 360 } }}
        />
        <TextField
          label="Deployment description"
          onChange={(_ev, value) => {
            setDeploymentDescription(value || '');
          }}
          value={deploymentDescription}
          disabled={!isNew || !permissions.canWrite}
          styles={{ root: { maxWidth: 360 } }}
        />
        <Dropdown
          label="Deployment type"
          selectedKey={deploymentType}
          onChange={onChangeDeploymentType}
          options={dropdownOptions}
          disabled={isNotHealthy || !permissions.canWrite || !isNew}
          styles={{ root: { maxWidth: 360 } }}
        />
        <FormSection title={`Model${deploymentType !== 'single_model' ? 's' : ''}`}>
          <ModelSelector
            selectedModels={selectedModels}
            onSelectedModelsChange={setSelectedModels}
            defaultSelectedVersionId={deployModelVersionId}
            defaultSelectedModelIds={defaultSelectedModelIds}
            defaultSelectedExperimentIds={defaultSelectedExperimentIds}
            defaultDeploymentCompositionA={defaultDeploymentCompositionA}
            defaultDeploymentCompositionB={defaultDeploymentCompositionB}
            trafficDistribution={trafficDistribution}
            onTrafficDistributionChange={onChangeTrafficDistribution}
            deploymentType={deploymentType}
            onChangeOptions={setDeploymentOptions}
            onContentLoadStart={() => setIsWidgetLoading(true)}
            onContentLoadFinish={() => setIsWidgetLoading(false)}
            readOnly={!isNew || !permissions.canWrite}
          />
        </FormSection>
        <Button
          iconName="ChevronRight"
          text="Advanced settings"
          onClick={(ev) => {
            const icon = (ev.currentTarget as HTMLElement)?.querySelector('.ms-Icon');
            if (icon) {
              setAdvancedExpanded(!icon.classList?.contains('rotate90'));
              icon.classList.toggle('rotate90');
            }
          }}
          styles={[
            buttonStylesStealth,
            {
              icon: {
                fontSize: 14,
                transitionDuration: '300ms',
                transitionProperty: 'transform',
              },
              root: {
                marginTop: 10,
                '.ms-Icon.rotate90': {
                  transform: 'rotate(90deg)',
                },
                backgroundColor: 'transparent',
              },
            },
          ]}
        />
        <span
          // Hides form istead of removing it to keep form data for submission when advanced settings are collapsed.
          style={isAdvancedExpanded ? undefined : { visibility: 'hidden', position: 'fixed' }}
        >
          <FormSection title="Kubernetes options">
            {deploymentType !== 'single_model' ? (
              <Info style={{ marginTop: 10, marginBottom: 10 }} styles={{ text: { alignContent: 'center' } }}>
                The below resource configurations will be applied for each individual model in the deployment.
              </Info>
            ) : null}
            {/* HACK: Render slider after load as it does not react on value changed externally.  */}
            {isNew || (!isNew && !loading) ? (
              <Slider
                label="Replicas"
                value={replicas}
                min={1}
                max={5}
                onChange={(value) => setReplicas(Math.round(value))}
                step={1}
                disabled={isNotHealthy || !permissions.canWrite}
                sliderContainerStyles={sliderContainerStyles}
              />
            ) : null}
            <p className={classNames.formTitle}>Requests</p>
            <KeyValuePairEditor
              config={requestsConfig}
              onUpdateConfig={(newConfig) => setRequestsConfig(newConfig)}
              styles={keyValuePairEditorStyles}
              readOnly={isNotHealthy || !permissions.canWrite}
            />
            <p className={classNames.formTitle}>Limits</p>
            <KeyValuePairEditor
              config={limitsConfig}
              onUpdateConfig={(newConfig) => setLimitsConfig(newConfig)}
              styles={keyValuePairEditorStyles}
              readOnly={isNotHealthy || !permissions.canWrite}
            />
          </FormSection>
          <FormSection title="Monitoring">
            <Toggle
              label="Enable monitoring"
              onText="Yes"
              offText="No"
              checked={isMonitoringEnabled}
              disabled={isNotHealthy || !permissions.canWrite}
              onChange={(_ev, checked) => {
                setIsMonitoringEnabled(checked || false);
              }}
            />
          </FormSection>
          <FormSection title="Security">
            <Dropdown
              label="Level"
              disabled={isNotHealthy || !permissions.canWrite}
              options={getSecurityDropdownOptions(defaultSecurityLevel, isNew)}
              selectedKey={securityLevel}
              onChange={(_ev, item) => {
                setSecurityLevel(item?.key as SecurityType);
                if (isNew && securityLevel !== 'no_security') setPassphrase(getRandomHash());
              }}
              styles={{ root: { maxWidth: 360 } }}
              required
            />
            {(securityLevel === 'passphrase_plain' ||
              (securityLevel === 'passphrase_hashed' && (isNew || defaultSecurityLevel !== 'passphrase_hashed'))) && (
              <TextField
                label="Passphrase"
                disabled={isNotHealthy || !permissions.canWrite}
                type={!isNew ? 'password' : undefined}
                canRevealPassword={!isNew}
                value={passphrase}
                onChange={(_ev, value) => {
                  setPassphrase(value || '');
                }}
                styles={{ root: { maxWidth: 360 } }}
              />
            )}
            {securityLevel !== 'no_security' && isNew && (
              <Info style={{ marginTop: 10 }} styles={{ text: { alignContent: 'center' } }}>
                {securityLevel === 'passphrase_plain'
                  ? 'The passphrase is stored in plain text in the database. If you forget the passphrase, it will still be visible in the UI after deployment.'
                  : 'The passphrase is stored as a hashed value in the database. You must save this passphrase somewhere else for your future use. If you forgot the passphrase, it will not be visible in the UI after deployment.'}
              </Info>
            )}
          </FormSection>
        </span>
        <div style={{ height: 25 }}></div>
        {permissions.canWrite ? (
          <Button
            // TODO: Add onCreateDeploymentClick function with form validation.
            onClick={isNew ? createDeployment : updateDeployment}
            // TODO: Handle disabled based on form validation.
            disabled={
              isWidgetLoading ||
              (deploymentType === 'single_model' ? !selectedModels.length : selectedModels.length < 2) ||
              // Handles case where model has no version.
              (deploymentType === 'single_model' &&
                !deploymentOptions?.firstModelDeploymentComposition?.experimentId) ||
              (deploymentType !== 'single_model' &&
                !deploymentOptions?.firstModelDeploymentComposition?.experimentId) ||
              (deploymentType !== 'single_model' &&
                !deploymentOptions?.secondModelDeploymentComposition?.experimentId) ||
              isNotHealthy ||
              !deploymentName ||
              isSubmitDisabled
            }
            styles={buttonStylesPrimary}
          >
            {isNew ? 'Deploy' : 'Save changes'}
          </Button>
        ) : null}
      </div>
    </>
  );
};
