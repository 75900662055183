import { useCallback } from 'react';

import { FilterCondition, useEngine } from '../aiem/engine/hooks';
import AIEnginesPage from '../components/AIEnginesPage/AIEnginesPage';
import { useUser } from '../utils/hooks';

export default function MyAIEnginesPage() {
  const { isAdmin } = useUser();
  const { searchEngines } = useEngine();

  const fetchEngines = useCallback(
    async (filter?: FilterCondition[], parent?: string) => {
      return await searchEngines(filter || [], parent);
    },
    [searchEngines]
  );

  return <AIEnginesPage title="My AI Engines" fetchEngines={fetchEngines} admin={isAdmin} />;
}
