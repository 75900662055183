// Valid Twirp error types. Most error types are equivalent to gRPC status codes
// and follow the same semantics.
// From twitchtv/twirp@v8.1.0+incompatible/errors.go
export enum ErrorCode {
  Oops = 'oops',
  Canceled = 'canceled',
  Unknown = 'unknown',
  InvalidArgument = 'invalid_argument',
  Malformed = 'malformed',
  DeadlineExceeded = 'deadline_exceeded',
  NotFound = 'not_found',
  BadRoute = 'bad_route',
  AlreadyExists = 'already_exists',
  PermissionDenied = 'permission_denied',
  Unauthenticated = 'unauthenticated',
  ResourceExhausted = 'resource_exhausted',
  FailedPrecondition = 'failed_precondition',
  Aborted = 'aborted',
  OutOfRange = 'out_of_range',
  Unimplemented = 'unimplemented',
  Internal = 'internal',
  Unavailable = 'unavailable',
  DataLoss = 'data_loss',
  NoError = 'ok',
  BadGateway = 'bad_gateway', // this case will be needed to be treated in the HTTP Header level because twirp won't throw it
  UnreachableOrigin = 'unreachable_origin', // this case will be needed to be treated in the HTTP Header level because twirp won't throw it
}

export const AllHTTPStatus = [
  200, // Ok
  400, // BadRequest
  401, // Unauthorized
  403, // Conflict
  404, // Not Found
  408, // Request Timeout
  409, // Conflict
  412, // Precondition Failed
  429, // Too Many Requests
  500, // Internal Server Error
  501, // Not Implemented
  502, // Bad Gateway
  503, // Service Unavailable
  523, // Unreachable Origin
  0, // Oops
] as const;
type HTTPStatusTuple = typeof AllHTTPStatus;
export type HTTPStatus = HTTPStatusTuple[number];

// this is not currently being used but it is a sample on
// how to test possible values through the tuple
export function isHTTPStatus(value: number): value is HTTPStatus {
  return AllHTTPStatus.includes(value as HTTPStatus);
}

export type HTTPResponse = {
  code?: ErrorCode;
  message?: string;
  status?: HTTPStatus;
  fatal?: boolean;
};

export const getHTTPResponse = (response: HTTPResponse): HTTPResponse => {
  const { code, message: originalMessage } = response;
  let status: HTTPStatus = 200,
    message = originalMessage;
  if (!code) {
    return { fatal: true, message: 'invalid usage of HTTP Response Handler: missing twirp code' };
  }
  switch (code) {
    case ErrorCode.Oops:
      status = 0;
      message = 'Oops, something went wrong.';
      break;
    case ErrorCode.NoError:
      status = 200;
      break;
    case ErrorCode.InvalidArgument:
    case ErrorCode.Malformed:
    case ErrorCode.OutOfRange:
      message = 'Looks like part or all of your request is not accurate';
      status = 400;
      break;
    case ErrorCode.Unauthenticated:
      status = 401;
      break;
    case ErrorCode.PermissionDenied:
      status = 403;
      break;
    case ErrorCode.BadRoute:
    case ErrorCode.NotFound:
      status = 404;
      break;
    case ErrorCode.Canceled:
    case ErrorCode.DeadlineExceeded:
      status = 408;
      break;
    case ErrorCode.Aborted:
    case ErrorCode.AlreadyExists:
      status = 409;
      break;
    case ErrorCode.FailedPrecondition:
      status = 412;
      break;
    case ErrorCode.ResourceExhausted:
      status = 429;
      break;
    case ErrorCode.DataLoss:
    case ErrorCode.Internal:
    case ErrorCode.Unknown:
      message = 'Internal server error. Please refresh this page or try again later';
      status = 500;
      break;
    case ErrorCode.Unimplemented:
      status = 501;
      break;
    case ErrorCode.BadGateway: // this is not from twirp, but could happen between UI and Network
      message = "Somewhere along the way, we are losing connection. Let's try again later, please";
      status = 502;
      break;
    case ErrorCode.Unavailable:
      message = 'Looks like some of our resources are currently unavailable';
      status = 503;
      break;
    case ErrorCode.UnreachableOrigin:
      message = 'We are currently unable to reach out our origin. Please, try again later';
      status = 523;
      break;
    default:
      throw new Error('undetected HTTP Code');
  }
  return { ...response, status, message };
};
