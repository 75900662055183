import { MessageBarType } from '@fluentui/react';
import { ConfirmDialog, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useRoles } from '../../authz/providers/RoleProvider';
import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';
import WidgetList from '../../components/WidgetList/WidgetList';
import { ExecutorPool } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/executor_pool_pb';
import { ListExecutorPoolsResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/executor_pool_service_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { formatError } from '../../utils/utils';
import { ENDPOINTS } from './apiEndpoints';
import NavigationWrapper from './NavigationWrapper';
import { ContextMenuIconButton } from './Workflows';
import { useWorkspaces } from './WorkspaceProvider';

type ExecutorPoolItem = ExecutorPool & {
  maxExec: string | number;
  maxQueueSizePerExec: string | number;
  onClickDelete: () => void;
  onClickEdit: () => void;
  createTimeLocal?: string;
  viewOnly?: boolean;
};

const columns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'name',
    minWidth: 180,
    maxWidth: 360,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName, onClickEdit }: ExecutorPoolItem) =>
          RowHeaderTitle({ title: displayName, onClick: onClickEdit }),
        emptyMessage: 'No Description',
        iconProps: {
          iconName: 'Devices2',
        },
      },
    },
  },
  {
    key: 'queueSize',
    name: 'Queue size',
    fieldName: 'queueSize',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'maxQueueSize',
    name: 'Max queue size',
    fieldName: 'maxQueueSizePerExec',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'maxExecutors',
    name: 'Max executors',
    fieldName: 'maxExec',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'creator',
    name: 'Creator',
    fieldName: 'creatorDisplayName',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'createTime',
    name: 'Created at',
    fieldName: 'createTimeLocal',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 140,
    maxWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No description',
        onRenderText: ({ onClickDelete, onClickEdit, viewOnly }: ExecutorPoolItem) => (
          <ContextMenuIconButton
            items={[
              {
                key: 'edit',
                text: viewOnly ? 'View' : 'Edit',
                onClick: onClickEdit,
                iconProps: { iconName: viewOnly ? 'RedEye' : 'Edit', style: { color: 'var(--h2o-gray900)' } },
              },
              {
                key: 'delete',
                text: 'Delete',
                onClick: onClickDelete,
                style: { color: 'var(--h2o-red400)', display: viewOnly ? 'none' : undefined },
                iconProps: {
                  iconName: 'Delete',
                  style: { color: 'var(--h2o-red400)' },
                },
              },
            ]}
          />
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

const ExecutorPools = () => {
  const history = useHistory(),
    orchestratorService = useOrchestratorService(),
    { addToast } = useToast(),
    { permissions } = useRoles(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false),
    [dialogAction, setDialogAction] = React.useState<() => unknown>(),
    [loading, setLoading] = React.useState(true),
    [isSearchStr, setIsSearchStr] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [executorPoolItems, setExecutorPoolItems] = React.useState<ExecutorPoolItem[]>(),
    closeDeleteDialog = () => setIsDeleteDialogOpen(false),
    onEditExecutorPool = (e: ExecutorPool) => history.push(`/orchestrator/${e.name}`),
    onDeleteExecutorPool = (id: string) => {
      setDialogAction(() => () => {
        void deleteExecutorPool(id);
        closeDeleteDialog();
      });
      setIsDeleteDialogOpen(true);
    },
    onCreateButtonClick = () =>
      history.push(`/orchestrator/${ACTIVE_WORKSPACE_NAME}${ENDPOINTS.EXECUTOR_POOLS}/create-new`),
    fetchExecutorPools = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (pageToken) setIsLoadingMore(true);
        else if (filter || filter === `display_name = ""`) setIsLoadingSearch(true);
        else setLoading(true);
        try {
          const data: ListExecutorPoolsResponse = await orchestratorService.getExecutorPools({
            parent: ACTIVE_WORKSPACE_NAME || '',
            pageSize: 20,
            pageToken,
            orderBy: 'create_time desc',
            filter: filter === `display_name = ""` ? undefined : filter,
          });
          const newExecutorPoolItems: ExecutorPoolItem[] | undefined = data?.executorPools
            ? data.executorPools.map((e) => ({
                ...e,
                maxExec: e.maxExecutors || 'Unlimited',
                maxQueueSizePerExec: e.maxQueueSizePerExecutor || '1',
                onClickDelete: () => onDeleteExecutorPool(e.name || ''),
                onClickEdit: () => onEditExecutorPool(e),
                createTimeLocal: e.createTime ? new Date(e.createTime).toLocaleString() : undefined,
                viewOnly: !permissions.canEditRunnables,
              }))
            : undefined;
          if (data && !newExecutorPoolItems) console.error('No executor pools found in the response.');
          setNextPageToken(data?.nextPageToken || undefined);
          setExecutorPoolItems((items) =>
            pageToken ? [...(items || []), ...(newExecutorPoolItems || [])] : newExecutorPoolItems
          );
        } catch (err) {
          const message = `Failed to fetch executor pools: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          setLoading(false);
          setIsLoadingMore(false);
          setIsLoadingSearch(false);
        }
      },
      [ACTIVE_WORKSPACE_NAME, orchestratorService, permissions]
    ),
    deleteExecutorPool = React.useCallback(
      async (id: string) => {
        try {
          await orchestratorService.deleteExecutorPool({ name: id });
          void fetchExecutorPools();
        } catch (err) {
          const message = `Failed to delete executor pool: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [orchestratorService, fetchExecutorPools]
    );

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) {
      void fetchExecutorPools();
      // TODO: Cleanup running requests on unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME]);

  React.useEffect(() => {
    setExecutorPoolItems((items) =>
      items?.[0]?.viewOnly === !permissions.canEditRunnables
        ? items
        : items?.map((item) => ({
            ...item,
            viewOnly: !permissions.canEditRunnables,
          }))
    );
  }, [permissions]);

  return (
    <NavigationWrapper>
      {executorPoolItems?.length || isSearchStr || loading ? <Header customPageTitle="Executor pools" /> : null}
      <ConfirmDialog
        title="Delete Executor Pool"
        hidden={!isDeleteDialogOpen}
        onConfirm={dialogAction!}
        onDismiss={closeDeleteDialog}
        msg="Are you sure you want to delete this executor pool? Once it is deleted it cannot be restored."
        confirmationButtonText="Delete"
        dismissalButtonText="Cancel"
        // TODO: Add danger button style.
      />
      <WidgetList
        columns={columns}
        items={executorPoolItems}
        loading={loading}
        isLoadingSearch={isLoadingSearch}
        isLoadingMore={isLoadingMore}
        onLoadMore={nextPageToken ? () => void fetchExecutorPools(nextPageToken) : undefined}
        searchProps={{
          placeholder: 'Search executor pools',
          onSearchChange: (value) => {
            setIsSearchStr(!!value);
            void fetchExecutorPools(undefined, `display_name = '${value}'`);
          },
        }}
        actionProps={
          permissions.canEditRunnables
            ? {
                actionIcon: 'Add',
                actionTitle: 'Create executor pool',
                onActionClick: onCreateButtonClick,
              }
            : undefined
        }
        NoItemsContent={NoItemView({
          title: 'Executor Pools',
          description: `There are no executor pools created in this workspace. ${
            permissions.canEditRunnables ? 'Create the first one.' : ''
          }`,
          actionTitle: permissions.canEditRunnables ? 'Create executor pool' : undefined,
          onActionClick: onCreateButtonClick,
          actionIcon: 'Add',
          backgroundImage: `url(${require('./assets/devices2.png')})`,
          isBackgroundPattern: true,
        })}
        ErrorContent={FailedToLoadView({
          title: 'Failed to load executor pools',
          description: 'Please try again later. If the problem persists, contact our support.',
          actionTitle: 'Retry',
          onActionClick: fetchExecutorPools,
          actionIcon: 'Refresh',
        })}
      />
    </NavigationWrapper>
  );
};

export default ExecutorPools;
