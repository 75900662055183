import { MessageBarType } from '@fluentui/react';
import { DetailsList, MessageBar, useTheme } from '@h2oai/ui-kit';
import { useEffect, useMemo, useState } from 'react';

import { LoginEventsRequest, LoginEventsResponse } from '../../../../../admin-center/gen/user_login/userlogin_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { customTableStyles } from '../../../common/common.styles';
import { getDummyList } from '../../../common/utils';
import { UserLoginColumnKey, UserLoginColumnName } from '../../../constants';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { TableStyle } from '../UserLogin.styles';

interface UserLogin {
  userLoginName: string;
  userLoginDate: string;
  userLoginTime: string;
}

interface UserLoginTableProps {
  startDate: string;
  endDate: string;
  searchQuery: string;
}

const dummyList = getDummyList<UserLogin>(8);

export default function UserLoginTable({ startDate, endDate, searchQuery }: UserLoginTableProps) {
  const service = useAdminCenterService();
  const theme = useTheme();
  const [userLoginData, setUserLoginData] = useState<UserLogin[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchLoginEvents = async () => {
    setIsLoading(true);
    try {
      const request: LoginEventsRequest = {
        startDate: startDate,
        endDate: endDate,
      };

      const response: LoginEventsResponse = await service.getUserLoginEvents(request);

      const formattedData = (response.events ?? []).map((event) => {
        const utcDate = event.ts ? new Date(event.ts) : null;

        return {
          userLoginName: event.userName ?? '-',
          userLoginDate: utcDate
            ? utcDate.toLocaleDateString('en-CA', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
            : '-',
          userLoginTime: utcDate
            ? utcDate.toLocaleTimeString('en-US', {
                hour12: false,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
            : '-',
        };
      });

      setUserLoginData(formattedData);
    } catch (error) {
      console.error('Error fetching login events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLoginEvents();
  }, [startDate, endDate]);

  const filteredData = useMemo(() => {
    const searchTerm = searchQuery.toLowerCase();
    return userLoginData.filter(
      (item) =>
        item.userLoginDate.toLowerCase().includes(searchTerm) ||
        item.userLoginName.toLowerCase().includes(searchTerm) ||
        item.userLoginTime.toLowerCase().includes(searchTerm)
    );
  }, [userLoginData, searchQuery]);

  const columns = [
    {
      key: UserLoginColumnKey.name,
      fieldName: UserLoginColumnKey.name,
      name: UserLoginColumnName.name,
      minWidth: 200,
      onRender: (item: UserLogin) => (
        <>{!isLoading ? item.userLoginName : <SkeletonLoader width="65%" height="100%" />}</>
      ),
    },
    {
      key: UserLoginColumnKey.date,
      fieldName: UserLoginColumnKey.date,
      name: UserLoginColumnName.date,
      minWidth: 200,
      onRender: (item: UserLogin) => (
        <>{!isLoading ? item.userLoginDate : <SkeletonLoader width="65%" height="100%" />}</>
      ),
    },
    {
      key: UserLoginColumnKey.time,
      fieldName: UserLoginColumnKey.time,
      name: UserLoginColumnName.time,
      minWidth: 200,
      onRender: (item: UserLogin) => (
        <>{!isLoading ? item.userLoginTime : <SkeletonLoader width="65%" height="100%" />}</>
      ),
    },
  ];

  return (
    <div>
      <div style={TableStyle(theme)}>
        {!isLoading && userLoginData.length === 0 ? (
          <MessageBar messageBarType={MessageBarType.warning}>
            {'No data available for the selected date range'}
          </MessageBar>
        ) : (
          <DetailsList
            columns={columns}
            styles={customTableStyles(theme, '250px')}
            items={isLoading ? dummyList : filteredData}
          />
        )}
      </div>
    </div>
  );
}
