import { MessageBarType, Stack } from '@fluentui/react';
import { Button, buttonStylesGhost, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import {
  DeleteWorkspaceResponse,
  UndeleteWorkspaceResponse,
} from '../../authz/gen/ai/h2o/workspace/v1/workspace_api_pb';
import { Workspace } from '../../authz/gen/ai/h2o/workspace/v1/workspace_pb';
import { useAuthzService } from '../../authz/hooks';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { LocalStorageKeys, useLocalStorage } from '../../utils/hooks';
import { arrayToObject, formatError } from '../../utils/utils';
import { appstoreRoutePaths } from '../Routes';

type WorkspaceContextType = {
  workspaces?: Workspace[];
  activeWorkspace?: Workspace;
  workspacesNameMap?: { [key: string]: Workspace };
  ACTIVE_WORKSPACE_NAME?: string;
  activeWorkspaceInitialized: boolean;
  loading?: boolean;
  isLoadingMore?: boolean;
  onLoadMore?: () => void;
  activateWorkspace: (name: string) => void;
  deleteWorkspace: (workspaceName: string) => Promise<DeleteWorkspaceResponse | null>;
  undeleteWorkspace: (workspaceName: string) => Promise<UndeleteWorkspaceResponse | null>;
  fetchWorkspaces: (pageToken?: string, filter?: string) => void;
  createWorkspace: (displayName: string) => void;
  isLoadingSearch?: boolean;
  defaultWorkspace?: Workspace;
};

type SwitcherProps = {
  currentWorkspace: string;
  destinationWorkspace: string;
  activateDestinationWorkspace: () => void;
};

const WorkspaceContext = React.createContext<WorkspaceContextType | undefined>(undefined);

const getWorkspaceFromUrl = (pathname: string) => {
  // TODO: Make it work for all apps supporting workspaces.
  return pathname.includes('/orchestrator/workspaces/') && !pathname.endsWith('workspaces/')
    ? `workspaces/${pathname.replace('/orchestrator/', '').split('/')[1]}`
    : undefined;
};

// TODO: Decouple Workspaces functionality from others

const WorkspaceProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation(),
    authzService = useAuthzService(),
    history = useHistory(),
    { addToast, removeAllToasts } = useToast(),
    { pathname } = location,
    [activeWorkspace, setActiveWorkspace, loadingActiveWorkspace] = useLocalStorage<Workspace | undefined>(
      LocalStorageKeys.ACTIVE_WORKSPACE
    ),
    skipProceedDialogRef = React.useRef(false),
    loadStateRef = React.useRef({
      fetchWorkspaces: false,
      createWorkspace: false,
    }),
    [activeWorkspaceInitialized, setActiveWorkspaceInitialized] = React.useState(false),
    [loading, setLoading] = React.useState(true),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [workspaces, setWorkspaces] = React.useState<Workspace[]>(),
    [workspaceSwitcherProps, setWorkspaceSwitcherProps] = React.useState<SwitcherProps>(),
    [workspaceNotFound, setWorkspaceNotFound] = React.useState(false),
    defaultWorkspace = React.useMemo(() => {
      return workspaces?.find((workspace) => !!workspace.personalWorkspaceSubject);
    }, [workspaces]),
    defaultAppstoreWorkspace = React.useMemo(() => {
      return workspaces?.find(({ name }) => name === 'workspaces/appstore');
    }, [workspaces]),
    isAppstoreRoute = React.useMemo(() => {
      return matchPath(pathname, appstoreRoutePaths);
    }, [pathname]),
    workspaceNamesToDisplayNames: { [key: string]: string } = React.useMemo(
      () =>
        workspaces
          ? workspaces.reduce(
              (acc, w) => ({ ...acc, [w.name || '']: w.displayName || '' }),
              {} as { [key: string]: string }
            )
          : {},
      [workspaces]
    ),
    [workspacesNameMap, setWorkspacesNameMap] = React.useState<{ [key: string]: Workspace } | undefined>(),
    evaluateLoading = () => {
      if (!loadStateRef.current.fetchWorkspaces && !loadStateRef.current.createWorkspace) {
        setLoading(false);
      }
    },
    fetchWorkspaces = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        loadStateRef.current.fetchWorkspaces = true;
        if (pageToken) setIsLoadingMore(true);
        else if (filter || filter === `""`) setIsLoadingSearch(true);
        else setLoading(true);
        try {
          const data = await authzService.getWorkspaces({
            pageSize: 20,
            pageToken,
            filter: filter === `""` ? undefined : filter,
          });
          const workspaceItems: Workspace[] | undefined = data?.workspaces;
          if (data && !workspaceItems) console.error('No workspaces found in the response.');
          setNextPageToken(data?.nextPageToken || undefined);
          setWorkspaces((items) => (pageToken ? [...(items || []), ...(workspaceItems || [])] : workspaceItems));
        } catch (err) {
          const message = `Failed to fetch workspaces: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setWorkspaces(undefined);
        } finally {
          loadStateRef.current.fetchWorkspaces = false;
          evaluateLoading();
          setIsLoadingMore(false);
          setIsLoadingSearch(false);
        }
      },
      [authzService, addToast]
    ),
    updatePreferences = React.useCallback(
      (workspaceToActivate: string, toDashboard = false) => {
        setActiveWorkspace({
          name: workspaceToActivate,
          displayName: workspaceNamesToDisplayNames[workspaceToActivate || ''],
        });

        if (toDashboard) {
          history.push(`/orchestrator/${workspaceToActivate}`);
        } else if (pathname.startsWith('/orchestrator/workspaces/') && !pathname.endsWith('workspaces/')) {
          const pathnameEnd = pathname.replace('/orchestrator/workspaces/', '').split(/\/(.*)/s)[1];
          const path = pathnameEnd?.endsWith('/create-new') ? pathnameEnd.replace('/create-new', '') : pathnameEnd;
          history.push(`/orchestrator/${workspaceToActivate}${path ? `/${path}` : ''}`);
        }

        evaluateLoading();
        skipProceedDialogRef.current = false;
      },
      [pathname, history, workspaceNamesToDisplayNames]
    ),
    activateWorkspace = React.useCallback(
      (workspaceToActivate: string) => {
        if (!workspaces) {
          const message = `Workspaces are not loaded yet.`;
          console.error(message);
          addToast({ messageBarType: MessageBarType.error, message });
          return;
        }
        const workspace = workspaces?.find((w) => w.name === workspaceToActivate);
        if (workspace) {
          if (workspaceToActivate !== activeWorkspace) {
            skipProceedDialogRef.current = true;
            updatePreferences(workspaceToActivate);
          }
        } else {
          const message = `Workspace you are trying to activate was not found.`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [workspaces, activeWorkspace, updatePreferences]
    ),
    createWorkspace = React.useCallback(
      async (displayName: string) => {
        loadStateRef.current.createWorkspace = true;
        setLoading(true);
        try {
          const data = await authzService.createWorkspace({ workspace: { displayName } });
          const workspaceName = data?.workspace?.name;
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Workspace created successfully.',
          });
          await fetchWorkspaces();
          skipProceedDialogRef.current = true;
          if (workspaceName) updatePreferences(workspaceName, true);
        } catch (err) {
          const message = `Failed to create workspace: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.createWorkspace = false;
          evaluateLoading();
        }
      },
      [fetchWorkspaces, updatePreferences]
    ),
    undeleteWorkspace = React.useCallback(
      async (workspaceName: string) => {
        try {
          const response = await authzService.undeleteWorkspace({ name: workspaceName });
          removeAllToasts();
          await fetchWorkspaces();
          return response;
        } catch (err) {
          const message = `Failed to delete workspace: ${formatError(err)}`;
          console.error(message);
          addToast({ messageBarType: MessageBarType.error, message });
          return null;
        }
      },
      [authzService, fetchWorkspaces, removeAllToasts, addToast, formatError]
    ),
    deleteWorkspace = React.useCallback(
      async (workspaceName: string) => {
        try {
          const response = await authzService.deleteWorkspace({ name: workspaceName });
          // TODO: This Toast doesn't appear for some reason. Investigate
          addToast({
            messageBarType: MessageBarType.success,
            message: (
              <Stack>
                <p>Workspace successfully deleted.</p>
                <Stack horizontalAlign="end">
                  <Button onClick={() => undeleteWorkspace(workspaceName)} styles={buttonStylesGhost}>
                    Undo
                  </Button>
                </Stack>
              </Stack>
            ),
          });
          await fetchWorkspaces();
          return response;
        } catch (err) {
          const message = `Failed to delete workspace: ${formatError(err)}`;
          console.error(message);
          addToast({ messageBarType: MessageBarType.error, message });
          return null;
        }
      },
      [fetchWorkspaces, undeleteWorkspace, authzService, addToast, formatError]
    );

  React.useEffect(() => {
    void fetchWorkspaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isAppstoreRoute) return;
    // If a user visits a non-Appstore route while the Appstore default Workspace is active, we switch to the default Workspace.
    if (activeWorkspace?.name === defaultAppstoreWorkspace?.name) {
      activateWorkspace(defaultWorkspace?.name || '');
    }
  }, [
    pathname,
    isAppstoreRoute,
    activeWorkspace,
    defaultAppstoreWorkspace,
    defaultWorkspace,
    addToast,
    removeAllToasts,
  ]);

  React.useEffect(() => {
    setWorkspacesNameMap(arrayToObject(workspaces || [], 'name'));
  }, [workspaces]);

  React.useEffect(() => {
    if (loadingActiveWorkspace || loading) return;
    if (workspaces) {
      if (!activeWorkspace?.name) {
        activateWorkspace(defaultWorkspace?.name || '');
      } else {
        const workspaceNameFromUrl = getWorkspaceFromUrl(pathname),
          isWorkspaceFromUrlAvailable = workspaceNameFromUrl && workspaces.find((w) => w.name === workspaceNameFromUrl);
        if (
          isWorkspaceFromUrlAvailable &&
          workspaceNameFromUrl !== activeWorkspace?.name &&
          !skipProceedDialogRef.current
        ) {
          setWorkspaceSwitcherProps({
            currentWorkspace: workspaceNamesToDisplayNames[activeWorkspace?.name] || '',
            destinationWorkspace: workspaceNamesToDisplayNames[workspaceNameFromUrl] || '',
            activateDestinationWorkspace: () => {
              activateWorkspace(workspaceNameFromUrl);
              setWorkspaceSwitcherProps(undefined);
            },
          });
        } else {
          if (workspaceNameFromUrl && !isWorkspaceFromUrlAvailable) setWorkspaceNotFound(true);
        }
      }
    }
    // Set active workspace as initialized after everything is loaded and set for WorkspaceRedirect to work properly.
    setActiveWorkspaceInitialized(true);
  }, [workspaces, activeWorkspace, pathname, loading, activeWorkspace, loadingActiveWorkspace]);

  const value = {
    workspaces,
    activeWorkspace,
    deleteWorkspace,
    undeleteWorkspace,
    workspacesNameMap,
    activateWorkspace,
    ACTIVE_WORKSPACE_NAME: activeWorkspace?.name,
    activeWorkspaceInitialized,
    loading,
    fetchWorkspaces,
    createWorkspace,
    isLoadingMore,
    onLoadMore: nextPageToken ? () => void fetchWorkspaces(nextPageToken) : undefined,
    isLoadingSearch,
    defaultWorkspace,
  };

  return (
    <WorkspaceContext.Provider value={value}>
      {workspaceSwitcherProps ? (
        <NoItemView
          actionIcon="NavigateForward"
          title={`The URL is not from your active workspace`}
          description={`Do you want to leave ${workspaceSwitcherProps.currentWorkspace} and activate ${workspaceSwitcherProps.destinationWorkspace}?`}
          actionTitle={`Proceed to ${workspaceSwitcherProps.destinationWorkspace}`}
          onActionClick={workspaceSwitcherProps.activateDestinationWorkspace}
        />
      ) : workspaceNotFound ? (
        <NoItemView
          actionIcon="NavigateForward"
          title="Workspace not found"
          description="The workspace you are trying to access does not exist or you do not have access to it."
          actionTitle="Go to workspace selection"
          onActionClick={() => {
            setWorkspaceNotFound(false);
            history.push('/orchestrator/workspaces');
          }}
        />
      ) : (
        children
      )}
    </WorkspaceContext.Provider>
  );
};

const useWorkspaces = () => {
  const context = React.useContext(WorkspaceContext);
  if (!context) {
    throw new Error('useWorkspaces must be used within a WorkspaceProvider');
  }
  return context;
};

export { WorkspaceProvider, useWorkspaces };
