import { Button, Dropdown, Panel, TextField, buttonStylesGhost, buttonStylesPrimary, useTheme } from '@h2oai/ui-kit';
import React, { useCallback, useState } from 'react';

import { AddMemberRequest } from '../../../../../admin-center/gen/user_management/user_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { useUser } from '../../../../../utils/hooks';
import { UserRoleOption, UserRoleOptionKey } from '../../../constants';
import { sidePanelBtnStyle, sidePanelCancelBtnStyle } from '../UserManagement.styles';

interface IPPanelProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitSuccess: () => Promise<void>;
}

export default function UMSidePanel({ isOpen, setOpen, onSubmitSuccess }: IPPanelProps) {
  const service = useAdminCenterService();
  const theme = useTheme();
  const user = useUser();
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [firstNameValue, setFirstNameValue] = useState('');
  const handleFirstNameChange = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setFirstNameValue(newValue || '');
  };

  const [lastNameValue, setLastNameValue] = useState('');
  const handleLastNameChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setLastNameValue(newValue || '');
  };

  const [emailValue, setEmailValue] = useState('');
  const handleEmailChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setEmailValue(newValue || '');
  };

  const roleOptions = [
    { key: UserRoleOptionKey.user, text: UserRoleOption.user },
    { key: UserRoleOptionKey.admin, text: UserRoleOption.admin },
  ];
  const [selectedRoleKey, setSelectedRoleKey] = useState(UserRoleOptionKey.user);
  const onRoleChange = (_event: React.FormEvent<HTMLDivElement>, item: any) => {
    const { key } = item;
    setSelectedRoleKey(key);
  };

  const dismissPanel = useCallback(() => {
    setTimeout(() => {
      setFirstNameValue('');
      setLastNameValue('');
      setEmailValue('');
      setSelectedRoleKey(UserRoleOptionKey.user);
      setOpen(false);
    }, 500);
  }, [setOpen, setFirstNameValue, setLastNameValue, setEmailValue, setSelectedRoleKey]);
  const isFormValid = useCallback(() => {
    return [firstNameValue, lastNameValue, emailValue, selectedRoleKey].every(Boolean);
  }, [firstNameValue, lastNameValue, emailValue, selectedRoleKey]);

  const handleSubmitClick = useCallback(async () => {
    if (!isFormValid()) return;

    try {
      const request: AddMemberRequest = {
        members: [
          {
            firstName: firstNameValue ?? '',
            lastName: lastNameValue ?? '',
            email: emailValue ?? '',
          },
        ],
        memberLevel: selectedRoleKey ?? UserRoleOptionKey.user,
        modifiedBy: user?.name ?? '',
        timestamp: Date.now() / 1000,
      };
      const response = await service.addMembers(request);
      if (response.statusUpdate) {
        setResponseMessage(response.statusUpdate);
      } else {
        setResponseMessage('❌ Error: Failed to update');
      }
      await onSubmitSuccess();
    } catch (error) {
      setResponseMessage(`❌ Network error: ${error}`);
    }
  }, [firstNameValue, lastNameValue, emailValue, isFormValid, onSubmitSuccess]);

  const onRenderFooterContent = useCallback(
    () => (
      <>
        <div style={sidePanelBtnStyle}>
          <Button styles={buttonStylesGhost} text="Cancel" onClick={dismissPanel} style={sidePanelCancelBtnStyle} />
          <Button styles={buttonStylesPrimary} text="Add User" onClick={handleSubmitClick} disabled={!isFormValid()} />
        </div>
      </>
    ),
    [dismissPanel, handleSubmitClick, isFormValid]
  );

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      onRenderFooterContent={onRenderFooterContent}
      customWidth="500px"
      type={7}
    >
      <h2>
        <strong>Add User</strong>
      </h2>
      <TextField
        label="First Name"
        placeholder="First name here"
        value={firstNameValue}
        onChange={handleFirstNameChange}
        required={true}
      />
      <TextField
        label="Last Name"
        placeholder="Last name here"
        value={lastNameValue}
        onChange={handleLastNameChange}
        required={true}
      />
      <TextField
        label="Email"
        placeholder="Email here"
        value={emailValue}
        onChange={handleEmailChange}
        required={true}
      />
      <Dropdown
        label="Role"
        placeholder="Select role"
        options={roleOptions}
        selectedKey={selectedRoleKey}
        onChange={onRoleChange}
        required={true}
      />
      {responseMessage && (
        <p
          style={{
            marginTop: 10,
            color: responseMessage.includes('❌') ? theme.palette?.red300 : theme.palette?.green300,
          }}
        >
          {responseMessage}
        </p>
      )}
    </Panel>
  );
}
