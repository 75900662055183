import { IColumn } from '@fluentui/react';
import { ListCell } from '@h2oai/ui-kit';

import { onRenderBooleanCell } from '../../utils/render';
import { NotebookEngineProfile } from '../gen/ai/h2o/engine/v1/notebook_engine_profile_pb';
import {
  CreateNotebookEngineProfileRequest,
  CreateNotebookEngineProfileResponse,
  DeleteNotebookEngineProfileRequest,
  DeleteNotebookEngineProfileResponse,
  GetNotebookEngineProfileRequest,
  GetNotebookEngineProfileResponse,
  ListNotebookEngineProfilesRequest,
  ListNotebookEngineProfilesResponse,
  NotebookEngineProfileService_CreateNotebookEngineProfile,
  NotebookEngineProfileService_DeleteNotebookEngineProfile,
  NotebookEngineProfileService_GetNotebookEngineProfile,
  NotebookEngineProfileService_ListNotebookEngineProfiles,
  NotebookEngineProfileService_UpdateNotebookEngineProfile,
  UpdateNotebookEngineProfileRequest,
  UpdateNotebookEngineProfileResponse,
} from '../gen/ai/h2o/engine/v1/notebook_engine_profile_service_pb';
import { BigIntString } from '../gen/runtime';
import { engineProfileBaseFields, engineProfileFieldsConfig } from './constants';
import { Entity, EntityAction, EntityActionType, EntityField, EntityType } from './types';
import { onRender, onRenderDisplayAndId } from './utils';

const entityType = EntityType.NotebookEngineProfile;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateNotebookEngineProfile',
    rpc: NotebookEngineProfileService_CreateNotebookEngineProfile,
    type: EntityActionType.Create,
    responseKey: 'notebookEngineProfile',
    requestNameKey: 'notebookEngineProfileId',
    requestPayloadKey: 'notebookEngineProfile',
    requestNameKeyPrefix: 'notebookEngineProfile/',
    entityType,
  } as EntityAction<NotebookEngineProfile, CreateNotebookEngineProfileRequest, CreateNotebookEngineProfileResponse>,
  [EntityActionType.Get]: {
    name: 'GetNotebookEngineProfile',
    rpc: NotebookEngineProfileService_GetNotebookEngineProfile,
    type: EntityActionType.Get,
    requestNameKey: 'name' as unknown,
    responseKey: 'notebookEngineProfile',
    entityType,
  } as EntityAction<NotebookEngineProfile, GetNotebookEngineProfileRequest, GetNotebookEngineProfileResponse>,
  [EntityActionType.List]: {
    name: 'ListNotebookEngineProfile',
    rpc: NotebookEngineProfileService_ListNotebookEngineProfiles,
    type: EntityActionType.List,
    responseKey: 'notebookEngineProfiles',
    entityType,
  } as EntityAction<NotebookEngineProfile, ListNotebookEngineProfilesRequest, ListNotebookEngineProfilesResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateNotebookEngineProfile',
    rpc: NotebookEngineProfileService_UpdateNotebookEngineProfile,
    type: EntityActionType.Update,
    responseKey: 'notebookEngineProfile',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'notebookEngineProfile',
    entityType,
  } as EntityAction<NotebookEngineProfile, UpdateNotebookEngineProfileRequest, UpdateNotebookEngineProfileResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteNotebookEngineProfile',
    rpc: NotebookEngineProfileService_DeleteNotebookEngineProfile,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<NotebookEngineProfile, DeleteNotebookEngineProfileRequest, DeleteNotebookEngineProfileResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'icon',
    name: 'Icon',
    minWidth: 50,
    maxWidth: 50,
    onRender: () => <ListCell styles={{ root: { paddingLeft: 12 } }} iconProps={{ iconName: 'Settings' }} />,
  },
  {
    key: 'displayName',
    name: 'Display Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 250,
    onRender: onRenderDisplayAndId,
  },
  {
    key: 'priority',
    name: 'Priority',
    fieldName: 'priority',
    minWidth: 80,
    maxWidth: 120,
    onRender,
  },
  {
    key: 'enabled',
    name: 'Enabled',
    fieldName: 'enabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

const engineProfileFields = engineProfileFieldsConfig('Notebook');

export const fields: EntityField<NotebookEngineProfile>[] = [
  ...engineProfileBaseFields('Notebook'),
  engineProfileFields.storageBytesConstraint,
  engineProfileFields.maxIdleDurationConstraint,
  engineProfileFields.maxRunningDurationConstraint,
  engineProfileFields.maxRunningEngines,
  engineProfileFields.yamlPodTemplateSpec,
  engineProfileFields.yamlGpuTolerations,
];

export const notebookEngineProfileEntity: Entity<NotebookEngineProfile> = {
  type: EntityType.NotebookEngineProfile,
  name: 'notebookEngineProfiles',
  createButtonLabel: 'Add Notebook Engine Profile',
  displayName: 'Notebook Engine Profile',
  displayNameKey: 'displayName',
  actions,
  columns,
  fields,
  requestNameKeyPrefix: 'workspaces/global/notebookEngineProfiles/',
  emptyModel: {
    name: 'new-notebook-engine-profile-id',
    displayName: 'New Notebook Engine Profile',
    priority: 0,
    enabled: true,
    assignedOidcRolesEnabled: false,
    assignedOidcRoles: [],
    maxRunningEngines: 0,
    cpuConstraint: {
      min: '1' as BigIntString,
      default: '2' as BigIntString,
    },
    gpuConstraint: {
      min: '0' as BigIntString,
      default: '0' as BigIntString,
    },
    memoryBytesConstraint: {
      min: '4294967296' as BigIntString,
      default: '8589934592' as BigIntString,
    },
    storageBytesConstraint: {
      min: '4294967296' as BigIntString,
      default: '8589934592' as BigIntString,
    },
    maxIdleDurationConstraint: {
      min: '0s' as BigIntString,
      default: '3600s' as BigIntString,
    },
    maxRunningDurationConstraint: {
      min: '0s' as BigIntString,
      default: '432000s' as BigIntString,
    },
    yamlPodTemplateSpec: '',
    yamlGpuTolerations: '',
  },
  canBeReordered: false,
};
