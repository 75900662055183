import { ListJobsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/batch/v1/job_service_pb';
import { IStyle, MessageBarType, mergeStyles } from '@fluentui/react';
import {
  Button,
  IH2OTheme,
  Loader,
  TooltipHost,
  buttonStylesStealth,
  calloutContentStylesTooltip,
  loaderStylesSpinnerDefault,
  loaderStylesSpinnerTag,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Job_State } from '../../mlops/gen/ai/h2o/mlops/batch/v1/job_pb';
import { ListJobsResponse } from '../../mlops/gen/ai/h2o/mlops/batch/v1/job_service_pb';
import { useMLOpsService } from '../../mlops/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import { Tag, calculateDuration } from '../Orchestrator/WorkflowTabExecutions';
import { JobItem } from './BatchScoring';
import { getStateProps } from './BatchScoringDetail';
import { useProjects } from './ProjectProvider';

interface IJobsChartStyles {
  header: IStyle;
  loader: IStyle;
  buttonActive: IStyle;
  chartWidgetContainer: IStyle;
  chartRow: IStyle;
  tooltipContent: IStyle;
  bar: IStyle;
  centerItems: IStyle;
  loaderWrapper: IStyle;
}

const jobsChartStyles = (theme: IH2OTheme): IJobsChartStyles => {
    return {
      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      loader: { position: 'relative', height: 22 },
      buttonActive: {
        backgroundColor: theme.palette?.gray300,
        borderRadius: 6,
      },
      chartWidgetContainer: {
        height: 200,
        padding: 20,
        backgroundColor: theme.semanticColors?.contentBackground,
        borderRadius: 4,
      },
      chartRow: {
        display: 'flex',
        alignItems: 'flex-end',
        backgroundColor: theme.semanticColors?.contentBackground,
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        overflowX: 'scroll',
        textWrap: 'nowrap',
        // TODO: Handle max width properly.
        maxWidth: '60vw',
        // Hide scrollbar for Chrome, Safari and Opera.
        '::-webkit-scrollbar': {
          display: 'none',
        },
        // Hide scrollbar for IE, Edge and Firefox.
        '-ms-overflow-style': 'none', // IE and Edge
        'scrollbar-width': 'none', // Firefox
      },
      tooltipContent: {
        padding: 8,
      },
      bar: {
        width: 16,
        height: 150,
        backgroundColor: '#A4DBA6',
        borderRadius: 4,
        marginRight: 4,
        marginLeft: 4,
        marginTop: 10,
        transition: 'background-color 0.3s',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: '#B2FAA1',
        },
      },
      centerItems: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      },
      loaderWrapper: {
        height: 200,
      },
    };
  },
  loaderStyles = [
    loaderStylesSpinnerDefault,
    loaderStylesSpinnerTag,
    {
      root: {
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
      },
      // TODO: Use theme.
      label: { color: 'var(--h2o-primary200, #FFECB3)' },
      circle: {
        borderColor:
          // TODO: Use theme.
          'var(--h2o-primary700, #BF8F00) var(--h2o-primary200, #FFECB3) var(--h2o-primary200, #FFECB3);',
      },
    },
  ],
  getDateFilter = (period?: 'last-8-hours' | 'last-24-hours' | 'last-week') => {
    switch (period) {
      case 'last-8-hours':
        return `start_time >= timestamp('${new Date(Date.now() - 8 * 60 * 60 * 1000).toISOString()}')`;
      case 'last-24-hours':
        return `start_time >= timestamp('${new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString()}')`;
      case 'last-week':
        return `start_time >= timestamp('${new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString()}')`;
      default:
        return undefined;
    }
  },
  getDurationInSeconds = (start?: string, end?: string) => {
    if (!start) return 0;
    const startTime = new Date(start).getTime();
    const endTime = end ? new Date(end).getTime() : Date.now();
    return Math.floor((endTime - startTime) / 1000);
  };

const JobsChart = () => {
  const theme = useTheme(),
    classNames = useClassNames<IJobsChartStyles, ClassNamesFromIStyles<IJobsChartStyles>>(
      'jobsChart',
      jobsChartStyles(theme)
    ),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID } = useProjects(),
    mlopsService = useMLOpsService(),
    history = useHistory(),
    [filterBy, setFilterBy] = React.useState<'last-8-hours' | 'last-24-hours' | 'last-week'>('last-24-hours'),
    [jobItems, setJobItems] = React.useState<JobItem[]>(),
    [durationInterval, setDurationInterval] = React.useState<{ min: number; max: number }>({ min: 0, max: 100 }),
    [loading, setLoading] = React.useState(true),
    loadStateRef = React.useRef({
      fetchJobs: false,
    }),
    evaluateLoading = () => {
      if (!loadStateRef.current.fetchJobs) {
        setLoading(false);
      }
    },
    fetchJobs = React.useCallback(async () => {
      loadStateRef.current.fetchJobs = true;
      setLoading(true);

      try {
        const listJobsBody = new ListJobsRequest({
          parent: `workspaces/${ACTIVE_PROJECT_ID}`,
          filter: getDateFilter(filterBy),
          orderBy: 'start_time desc',
        });

        const data: ListJobsResponse = await mlopsService.listJobs(listJobsBody);

        const newJobItems: JobItem[] | undefined = data?.jobs
          ? data.jobs.map(
              (item) =>
                ({
                  ...item,
                  createTimeLocal: item.createTime ? new Date(item.createTime).toLocaleString() : undefined,
                  endTimeLocal: item.endTime ? new Date(item.endTime).toLocaleString() : undefined,
                  startTimeLocal: item.startTime ? new Date(item.startTime).toLocaleString() : undefined,
                  createdByName: item.creator, // TODO: Check if it is user display name.
                  duration: calculateDuration(item.startTime, item.endTime),
                  onEdit: () => history.push(`/mlops/projects/${item.name?.replace('workspaces/', '')}`),
                } as JobItem)
            )
          : undefined;
        if (data && !newJobItems) console.error('No jobs found in the response.');
        setJobItems(newJobItems);
      } catch (err) {
        const message = `Failed to fetch jobs: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setJobItems(undefined);
      } finally {
        loadStateRef.current.fetchJobs = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, mlopsService, filterBy, history, addToast]);

  React.useEffect(() => {
    if (ACTIVE_PROJECT_ID) {
      void fetchJobs();
      // TODO: Cleanup running requests on unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_PROJECT_ID, filterBy]);

  React.useEffect(() => {
    if (jobItems?.length) {
      const durations = jobItems.map((item) => getDurationInSeconds(item.startTime, item.endTime));
      setDurationInterval({
        min: Math.min(...durations),
        max: Math.max(...durations),
      });
    }
  }, [jobItems]);

  return (
    <div>
      <div className={classNames.header}>
        <h3>Batch scoring job runs</h3>
        {!loading ? (
          <div>
            <Button
              styles={buttonStylesStealth}
              text="8h"
              onClick={() => setFilterBy('last-8-hours')}
              className={filterBy === 'last-8-hours' ? classNames.buttonActive : undefined}
            />
            <Button
              styles={buttonStylesStealth}
              text="24h"
              onClick={() => setFilterBy('last-24-hours')}
              className={filterBy === 'last-24-hours' ? classNames.buttonActive : undefined}
            />
            <Button
              styles={buttonStylesStealth}
              text="1w"
              onClick={() => setFilterBy('last-week')}
              className={filterBy === 'last-week' ? classNames.buttonActive : undefined}
            />
          </div>
        ) : null}
      </div>
      {loading ? (
        <div className={mergeStyles(classNames.centerItems, classNames.loaderWrapper)}>
          <Loader />
        </div>
      ) : (
        <div className={classNames.chartWidgetContainer}>
          <div className={classNames.chartRow}>
            {jobItems?.length ? (
              jobItems?.map((item, key) => (
                <TooltipHost
                  key={`bar-item-${key}`}
                  calloutContentStyles={calloutContentStylesTooltip}
                  content={
                    <>
                      <div key={item.name} className={classNames.tooltipContent}>
                        <h2>{item.displayName}</h2>
                        <p>{item.creator}</p>
                        <p>
                          <b>Started at</b> {item.startTimeLocal}
                        </p>
                        {item?.endTime ? (
                          <p>
                            <b>Ended at</b> {item.endTimeLocal}
                          </p>
                        ) : null}
                        <p>
                          <b>Duration:</b> {item.duration}
                        </p>
                        <>
                          {item.state === Job_State.RUNNING ? (
                            <Loader label="Running" styles={loaderStyles} className={classNames.loader} />
                          ) : (
                            <Tag title={getStateProps(item.state).name} color={getStateProps(item.state).color} />
                          )}
                        </>
                      </div>
                    </>
                  }
                >
                  <div
                    className={classNames.bar}
                    onClick={item.onEdit}
                    style={{
                      backgroundColor: getStateProps(item.state).color,
                      /* Calculate height based on duration. Heights are distributed between 50px and 150px. */
                      height: item?.duration
                        ? Math.max(
                            50,
                            Math.min(
                              150,
                              50 +
                                (getDurationInSeconds(item.startTime, item.endTime) - durationInterval.min) /
                                  ((durationInterval.max - durationInterval.min) / 100)
                            )
                          )
                        : 50,
                    }}
                  />
                </TooltipHost>
              ))
            ) : (
              <div className={classNames.centerItems}>No jobs to show in selected period.</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default JobsChart;
