// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/authorization/v1/query_api.proto (package ai.h2o.authorization.v1, syntax proto3)
/* eslint-disable */

import { RPC } from "../../../../runtime";

/**
 * @generated from message ai.h2o.authorization.v1.ResolvePermissionRequest
 */
export type ResolvePermissionRequest = {
/**
 * Name of the subject. No wildcards allowed.
 *
 * @generated from field: string subject = 1;
 */
subject?: string;
/**
 * Name of the resource. No wildcards allowed.
 *
 * @generated from field: string resource = 2;
 */
resource?: string;
/**
 * Name of the action. No wildcards allowed.
 *
 * @generated from field: string action = 3;
 */
action?: string;
/**
 * Optional. Context in which the action is performed. No wildcards allowed.
 *
 * @generated from field: map<string, string> context = 4;
 */
context?: { [key: string]: string };
}
;
/**
 * @generated from message ai.h2o.authorization.v1.ResolvePermissionResponse
 */
export type ResolvePermissionResponse = {
/**
 * True when action is allowed, false otherwise.
 *
 * @generated from field: bool allowed = 1;
 */
allowed?: boolean;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.ResolveActionsRequest
 */
export type ResolveActionsRequest = {
/**
 * Name of the subject. No wildcards allowed.
 * If it's omitted the identity of the caller is resolved and and used for the
 * permission resolution.
 *
 * @generated from field: string subject = 1;
 */
subject?: string;
/**
 * Name of the resource. No wildcards allowed.
 *
 * @generated from field: string resource = 2;
 */
resource?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.ResolveActionsResponse
 */
export type ResolveActionsResponse = {
/**
 * Names of the allowed actions.
 *
 * @generated from field: repeated string actions = 1;
 */
actions?: string[];
}
;
/**
 * @generated from message ai.h2o.authorization.v1.BatchFilterResourcesRequest
 */
export type BatchFilterResourcesRequest = {
/**
 * Name of the subject. No wildcards allowed.
 *
 * @generated from field: string subject = 1;
 */
subject?: string;
/**
 * Name of the action. No wildcards allowed.
 *
 * @generated from field: string action = 2;
 */
action?: string;
/**
 * Name of the resource. No wildcards allowed.
 *
 * @generated from field: repeated string resources = 3;
 */
resources?: string[];
/**
 * Optional. Context in which the action is performed. No wildcards allowed.
 *
 * @generated from field: map<string, string> context = 4;
 */
context?: { [key: string]: string };
}
;
/**
 * @generated from message ai.h2o.authorization.v1.BatchFilterResourcesResponse
 */
export type BatchFilterResourcesResponse = {
/**
 * @generated from field: repeated string resources = 1;
 */
resources?: string[];
}
;
/**
 * @generated from message ai.h2o.authorization.v1.BatchResolvePermissionsRequest
 */
export type BatchResolvePermissionsRequest = {
/**
 * @generated from field: repeated ai.h2o.authorization.v1.ResolvePermissionRequest requests = 1;
 */
requests?: ResolvePermissionRequest[];
}
;
/**
 * @generated from message ai.h2o.authorization.v1.BatchResolvePermissionsResponse
 */
export type BatchResolvePermissionsResponse = {
/**
 * @generated from field: repeated ai.h2o.authorization.v1.ResolvePermissionResponse responses = 1;
 */
responses?: ResolvePermissionResponse[];
}
;
/**
 * Resolves whether the subjects is allowed to perform particular action on
 * the particular resource.
 *
 * @generated from rpc ai.h2o.authorization.v1.QueryService.ResolvePermission
 */
export const QueryService_ResolvePermission = new RPC<ResolvePermissionRequest,ResolvePermissionResponse>("POST", "/v1/query:resolve");
/**
 * Returns the list of actions the subject is allowed to perform on
 * the resource.
 *
 * @generated from rpc ai.h2o.authorization.v1.QueryService.ResolveActions
 */
export const QueryService_ResolveActions = new RPC<ResolveActionsRequest,ResolveActionsResponse>("POST", "/v1/query:resolveActions");
/**
 * Returns list of resources from the input given subject is allowed to
 * perform the action on. Resources are returned in the same order as they are
 * provided in the input.
 *
 * @generated from rpc ai.h2o.authorization.v1.QueryService.BatchFilterResources
 */
export const QueryService_BatchFilterResources = new RPC<BatchFilterResourcesRequest,BatchFilterResourcesResponse>("POST", "/v1/query:batchFilterResources");
/**
 * Resolves all multiple requests in one call.
 *
 * @generated from rpc ai.h2o.authorization.v1.QueryService.BatchResolvePermissions
 */
export const QueryService_BatchResolvePermissions = new RPC<BatchResolvePermissionsRequest,BatchResolvePermissionsResponse>("POST", "/v1/query:batchResolve");
