import {
  ShareProjectRequest,
  UnshareProjectRequest,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/project_service_pb';
import { Operator } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/query_pb';
import { Role } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/role_pb';
import { Sharing, SharingType } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/sharing_pb';
import { User } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/user_pb';
import {
  ListUsersRequest,
  ListUsersResponse,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/user_service_pb';
import { ProjectService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/project_service_connect';
import { UserService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/user_service_connect';
import { createClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import {
  Check,
  CheckboxVisibility,
  IChoiceGroupOption,
  IComboBox,
  IComboBoxOption,
  IContextualMenuItem,
  IObjectWithKey,
  ISelection,
  IStyle,
  Icon,
  MessageBarType,
  SelectAllVisibility,
  Selection,
  SelectionMode,
} from '@fluentui/react';
import {
  Button,
  ChoiceGroup,
  ComboBox,
  ConfirmDialog,
  DetailsList,
  IH2OTheme,
  Loader,
  Persona,
  Search,
  WidgetItem,
  buttonStylesStealth,
  choiceGroupStylesBox,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';

import Header from '../../components/Header/Header';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

type SharedUserItem = User & {
  key: string;
  sharing: Sharing;
  role: Role;
  onRoleChange: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => Promise<void>;
  onRoleDelete: (sharingId: string) => Promise<void>;
  disabled?: boolean;
};

type UserItem = User & {
  disabled?: boolean;
};

interface IProjectSettingsStyles {
  container: IStyle;
  title: IStyle;
  pageWrapper: IStyle;
  toolbar: IStyle;
  toolbarButton: IStyle;
  searchBar: IStyle;
  noBindingContainer: IStyle;
  roleComboBox: IStyle;
}

const projectSettingsStyles = (theme: IH2OTheme): IProjectSettingsStyles => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '10px 40px 20px 40px',
        margin: '0px 20px',
      },
      title: {
        marginTop: 0,
        // TODO: Remove once theme is used somewhere else.
        color: theme.semanticColors?.textPrimary,
      },
      pageWrapper: {
        padding: 10,
        // Adds extra margin to prevent "Need help?" button from overlapping.
        margin: '10px 60px',
        backgroundColor: theme?.semanticColors?.contentBackground,
        borderRadius: 4,
      },
      toolbar: {
        padding: '12px 6px',
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      toolbarButton: {
        marginRight: 2,
      },
      searchBar: {
        flexGrow: 1,
        maxWidth: 380,
        marginLeft: 18,
        marginRight: 8,
      },
      noBindingContainer: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        minHeight: 450,
        whiteSpace: 'pre-line',
      },
      roleComboBox: {
        display: 'flex',
        alignItems: 'center',
      },
    };
  },
  cellStyles = {
    display: 'flex',
    alignItems: 'center',
  };

const ProjectSettings = () => {
  const theme = useTheme(),
    classNames = useClassNames<IProjectSettingsStyles, ClassNamesFromIStyles<IProjectSettingsStyles>>(
      'projectSettings',
      projectSettingsStyles(theme)
    ),
    loadStateRef = React.useRef({
      fetchUsers: false,
      sharing: false,
      unsharing: false,
    }),
    // List users.
    [sharedUserItems, setSharedUserItems] = React.useState<SharedUserItem[] | undefined>(undefined),
    [filteredSharedUserItems, setFilteredSharedUserItems] = React.useState<SharedUserItem[] | undefined>(undefined),
    // Dialog users.
    [userItems, setUserItems] = React.useState<User[] | undefined>(undefined),
    [filterSearchUserItems, setFilterSearchUserItems] = React.useState<UserItem[]>(),
    [selectedUser, setSelectedUser] = React.useState<UserItem>(),
    // TODO: Use "isLoadingSearch" when supporting list search.
    [, setIsLoadingSearch] = React.useState(false),
    [, setIsLoadingMore] = React.useState(false),
    [isLoadingFilterSearch, setIsLoadingFilterSearch] = React.useState(false),
    [dialogRoleOptions, setDialogRoleOptions] = React.useState<IChoiceGroupOption[]>(),
    [selectedRole, setSelectedRole] = React.useState<string | undefined>(),
    [isAddUserDialogOpen, setIsAddUserDialogOpen] = React.useState(false),
    searchCalloutRef = React.useRef<HTMLDivElement | null>(null),
    searchTimeoutRef = React.useRef<number>(),
    // Other.
    [loading, setLoading] = React.useState(false),
    [isSomeItemSelected, setIsSomeItemSelected] = React.useState(false),
    [isAllSelected, setIsAllSelected] = React.useState(false),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID, activeProject, projectSharings, sharedUsers, listProjectSharings, roles } = useProjects(),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    transport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}/storage/`,
    }),
    userClient = createClient(UserService, transport),
    projectClient = createClient(ProjectService, transport),
    evaluateLoading = () => {
      if (!loadStateRef.current.fetchUsers && !loadStateRef.current.sharing && !loadStateRef.current.unsharing) {
        setLoading(false);
      }
    },
    listUsers = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        loadStateRef.current.fetchUsers = true;
        if (pageToken) setIsLoadingMore(true);
        else if (filter || filter === `""`) setIsLoadingSearch(true);
        else setLoading(true);
        try {
          const listUsersBody = new ListUsersRequest(
            filter
              ? {
                  filter: {
                    query: {
                      clause: [
                        {
                          propertyConstraint: [
                            {
                              property: {
                                propertyType: {
                                  value: 'username',
                                  case: 'field',
                                },
                              },
                              operator: Operator.CONTAINS,
                              value: {
                                value: {
                                  case: 'stringValue',
                                  value: filter,
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                }
              : {}
          );
          let users: User[] = [],
            morePagesAvailable = true,
            nextPageToken;

          while (morePagesAvailable) {
            // TODO: Add lazy-load pagination support once search (filter) is implemented on the backend.
            const response: ListUsersResponse = await userClient.listUsers({
              ...listUsersBody,
              paging: nextPageToken
                ? {
                    pageToken: nextPageToken,
                  }
                : undefined,
            });
            users = [...users, ...(response?.user || [])];
            morePagesAvailable = !!response?.paging?.nextPageToken;
            nextPageToken = response?.paging?.nextPageToken;
          }

          if (!users) console.error('No users found in the response.');
          const newUserItems = users?.map(
            (user) => ({ ...user, disabled: !!sharedUserItems?.find((u) => u.id === user.id) } as UserItem)
          );
          setUserItems(newUserItems);
          setFilterSearchUserItems(newUserItems);
        } catch (err) {
          const message = `Failed to fetch users: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setUserItems(undefined);
        } finally {
          loadStateRef.current.fetchUsers = false;
          evaluateLoading();
          setIsLoadingMore(false);
          setIsLoadingSearch(false);
        }
      },
      [addToast, userClient, sharedUserItems]
    ),
    shareProject = React.useCallback(
      async (userId: string, restrictionRoleId: string) => {
        loadStateRef.current.sharing = true;
        setLoading(true);
        try {
          const shareProjectBody = new ShareProjectRequest({
            projectId: ACTIVE_PROJECT_ID,
            userId,
            restrictionRoleId,
          });
          await projectClient.shareProject(shareProjectBody);
          void listProjectSharings();
          addToast({
            messageBarType: MessageBarType.success,
            message: `User has been successfully added to the project.`,
          });
        } catch (err) {
          const message = `Failed to share project: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
        loadStateRef.current.sharing = false;
        evaluateLoading();
      },
      [addToast, listProjectSharings, projectClient, ACTIVE_PROJECT_ID]
    ),
    unshareProject = React.useCallback(
      async (sharingId: string) => {
        loadStateRef.current.unsharing = true;
        setLoading(true);
        try {
          const unshareProjectBody = new UnshareProjectRequest({
            sharingId,
            projectId: ACTIVE_PROJECT_ID,
          });
          await projectClient.unshareProject(unshareProjectBody);
          await listProjectSharings();
          addToast({
            messageBarType: MessageBarType.success,
            message: `User has been successfully removed from the project.`,
          });
        } catch (err) {
          const message = `Failed to unshare project: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
        loadStateRef.current.unsharing = false;
        evaluateLoading();
      },
      [addToast, listProjectSharings, projectClient, ACTIVE_PROJECT_ID]
    ),
    openAddUserDialog = () => {
      // TODO: Add pagination and lazy load for fetching users when search is implemented on the backend.
      void listUsers();
      setIsAddUserDialogOpen(true);
    },
    rowColumns = React.useMemo(
      () =>
        roles
          ? [
              {
                key: 'name',
                fieldName: 'username',
                name: 'User',
                minWidth: 180,
                maxWidth: 260,
                onRender: ({ id, primaryEmail, username }: SharedUserItem) => (
                  // TODO: Use picture of the actual user.
                  <Persona key="name" optionalText={id} secondaryText={primaryEmail} size={12} text={username || id} />
                ),
              },
              {
                key: 'roleBindings',
                name: 'Role',
                minWidth: 200,
                maxWidth: 300,
                onRender: ({ role, sharing, onRoleChange, onRoleDelete, id }: SharedUserItem) => (
                  <div className={classNames.roleComboBox}>
                    {sharing?.type !== SharingType.OWNER ? (
                      <>
                        <ComboBox
                          options={roles
                            .filter((role) => role.displayName !== 'Nobody')
                            .map((role) => ({
                              key: role.id || '',
                              text: role.displayName || '',
                              data: { userId: id, roleId: role?.id, sharingId: sharing?.id },
                              selected: role.id === sharing?.restrictionRoleId,
                              disabled: sharing?.type === SharingType.OWNER,
                            }))}
                          selectedKey={sharing?.restrictionRoleId}
                          onChange={onRoleChange}
                          onRenderOption={(option) => {
                            return (
                              <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: 16 }}>{option?.text}</div>
                              </div>
                            );
                          }}
                          styles={{ root: { flex: 1 } }}
                        />
                        <Button
                          text="Remove"
                          onClick={() => onRoleDelete(sharing?.id || '')}
                          disabled={!role?.id}
                          styles={{ root: { marginLeft: 8 } }}
                          iconProps={{ iconName: 'Delete' }}
                        />
                      </>
                    ) : (
                      <>Owner</>
                    )}
                  </div>
                ),
              },
            ]
          : [],
      [roles]
    ),
    selection = React.useMemo(
      () =>
        new Selection({
          onSelectionChanged: () => {
            setIsSomeItemSelected(selection.getSelectedCount() > 0);
            setIsAllSelected(selection.isAllSelected());
          },
          canSelectItem: (item: SharedUserItem) => item.sharing?.type !== SharingType.OWNER,
        }),
      []
    ),
    onSearchChange = React.useCallback(
      (searchText: string) => {
        // TODO: Implement search of project sharings where user name or email contains searchText when supported by API.
        if (!searchText) {
          setFilteredSharedUserItems(sharedUserItems);
          return;
        }

        // Local filtering.
        setFilteredSharedUserItems(
          sharedUserItems?.filter((user) => user.username?.toLowerCase().includes(searchText.trim().toLowerCase()))
        );
      },
      [sharedUserItems]
    ),
    onDeleteSelected = React.useCallback(async () => {
      const selectedItems = selection.getSelection() as SharedUserItem[];
      for (const item of selectedItems) {
        if (item.sharing.type !== SharingType.OWNER) {
          await unshareProject(item.sharing.id || '');
        }
      }
    }, [selection, unshareProject]),
    onSetRoleForSelected = React.useCallback(
      async (
        _ev: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined,
        option: IContextualMenuItem | undefined
      ) => {
        if (!option || !ACTIVE_PROJECT_ID) return;
        const selectedItems = selection.getSelection() as SharedUserItem[];
        for (const item of selectedItems) {
          await shareProject(item.id, option.key as string);
        }
      },
      [selection, shareProject]
    ),
    onRenderDetailsHeader = React.useCallback(
      (props, defaultRender) => (
        <>
          <div className={classNames.toolbar}>
            <div onClick={() => selection.toggleAllSelected()} style={{ zIndex: 1, margin: 8 }}>
              <Check checked={isAllSelected} />
            </div>
            <Search
              // TODO: Handle loading once items are fetched on search.
              // loadingMessage=""
              onSearchTextChange={onSearchChange}
              placeholder={`Search by user name or email address`}
              className={classNames.searchBar}
            />
            {isSomeItemSelected ? (
              <>
                <Button
                  text="Delete all roles"
                  iconProps={{ iconName: 'Delete', style: { fontSize: 16 } }}
                  styles={buttonStylesStealth}
                  className={classNames.toolbarButton}
                  onClick={onDeleteSelected}
                />
                <Button
                  text="Set role"
                  iconProps={{ iconName: 'Add', style: { fontSize: 16 } }}
                  styles={buttonStylesStealth}
                  className={classNames.toolbarButton}
                  menuItems={roles
                    ?.filter((role) => role.displayName !== 'Nobody')
                    .map((role) => ({
                      key: role.id || '',
                      text: role.displayName || '',
                      onClick: (_ev, option) => {
                        onSetRoleForSelected(_ev, option);
                        return;
                      },
                    }))}
                />
              </>
            ) : null}
          </div>
          {defaultRender?.({
            ...props!,
            selectAllVisibility: SelectAllVisibility.hidden,
            styles: {
              ...props!.styles,
              root: {
                padding: 0,
                margin: 0,
                height: 22,
                lineHeight: 22,
                backgroundColor: theme.semanticColors?.contentBackground,
                '.ms-DetailsHeader-cell': {
                  height: 22,
                },
              },
            },
          })}
        </>
      ),
      [isAllSelected, isSomeItemSelected, selection, onSearchChange, onDeleteSelected, onSetRoleForSelected]
    ),
    closeAddUserDialog = () => {
      setIsAddUserDialogOpen(false);
      setSelectedRole(undefined);
      setSelectedUser(undefined);
    },
    addUser = React.useCallback(() => {
      shareProject(selectedUser?.id || '', selectedRole || '');
      closeAddUserDialog();
    }, [selectedUser, selectedRole, shareProject, closeAddUserDialog]),
    onFilterSearchChange = React.useCallback(
      (searchText: string) => {
        setIsLoadingFilterSearch(!!searchText);
        if (searchTimeoutRef.current) window.clearTimeout(searchTimeoutRef.current);
        searchTimeoutRef.current = window.setTimeout(async () => {
          if (!searchText) {
            setFilterSearchUserItems(userItems);
            return;
          }
          try {
            // TODO: Add pagination and filter support once search is implemented on the backend.
            // await listUsers(undefined, searchText);

            // Local filtering.
            setFilterSearchUserItems(
              userItems?.filter(
                (user) =>
                  user.username?.toLowerCase().includes(searchText.trim().toLowerCase()) ||
                  user.primaryEmail?.toLowerCase().includes(searchText.trim().toLowerCase())
              )
            );
          } catch (err) {
            const message = `Failed to fetch users: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
          } finally {
            searchTimeoutRef.current = undefined;
            setIsLoadingFilterSearch(false);
          }
        }, 500);
      },
      [userItems]
    );

  React.useEffect(() => {
    if (!sharedUsers || !projectSharings || !roles || !ACTIVE_PROJECT_ID) return;
    const newSharedUserItems = sharedUsers?.map((user) => {
      // TODO: Change when multiple role support added on the backend.
      const sharing = projectSharings?.find((sharing) => sharing.userId === user.id);
      const role = roles?.find((role) => role.id === sharing?.restrictionRoleId) || ({} as Role);
      return {
        ...user,
        key: user.id,
        sharing,
        role,
        onRoleChange: async (_ev, option) => {
          if (!option) return;
          await unshareProject(sharing?.id || '');
          await shareProject(user.id, option.key as string);
        },
        onRoleDelete: async (sharingId) => {
          await unshareProject(sharingId);
        },
      } as SharedUserItem;
    });
    setSharedUserItems(newSharedUserItems);
    setFilteredSharedUserItems(newSharedUserItems);
    // Fix "unshareProject" and "shareProject" dependencies causing infinite loop.
  }, [sharedUsers, projectSharings, roles, ACTIVE_PROJECT_ID]);

  React.useEffect(() => {
    if (!roles) return;
    setDialogRoleOptions(
      roles
        .filter((role) => role.displayName !== 'Nobody')
        .map((role) => ({
          key: role.id || '',
          text: role.displayName || '',
          checked: false,
        }))
    );
  }, [roles]);

  return (
    <PageWrapper>
      <Header actionTitle="Add people" actionIcon="AddFriend" onActionClick={openAddUserDialog} />
      <div className={classNames.pageWrapper}>
        <ConfirmDialog
          title="Add user"
          hidden={!isAddUserDialogOpen}
          onConfirm={addUser}
          onDismiss={closeAddUserDialog}
          confirmationButtonDisabled={!selectedUser || !selectedRole}
          msg=""
          confirmationButtonText="Add"
          dismissalButtonText="Cancel"
          modalProps={{ isBlocking: false }}
          content={
            <>
              <Search
                placeholder="Search by name or email"
                onSearchTextChange={onFilterSearchChange}
                searchResultItems={filterSearchUserItems}
                hasSearchResult
                loadingMessage={isLoadingFilterSearch ? 'Searching users...' : undefined}
                onRenderSearchResultItemActions={(item) =>
                  !item.disabled ? (
                    <Button
                      text="Add"
                      iconProps={{ iconName: 'Add' }}
                      onClick={() => {
                        setSelectedUser(item);
                        // HACK: Dismiss callout.
                        searchCalloutRef.current?.parentElement?.click();
                      }}
                    />
                  ) : (
                    <p>User already added</p>
                  )
                }
                calloutProps={{
                  calloutMaxWidth: 519,
                  dismissOnTargetClick: true,
                  ref: searchCalloutRef,
                }}
                searchResultItemFields={{
                  titleField: 'username',
                  descriptionField: 'primaryEmail',
                  idField: 'name',
                }}
              />
              <p style={{ margin: '30px 0px' }}>
                {selectedUser ? (
                  <>
                    Add <b>{selectedUser?.username}</b> into the project with the following role?
                  </>
                ) : (
                  'Please select the user first.'
                )}
              </p>
              {dialogRoleOptions ? (
                <ChoiceGroup
                  label="Role to assign"
                  options={dialogRoleOptions}
                  // styles={{ label: { fontWeight: 600 } }}
                  selectedKey={selectedRole}
                  styles={choiceGroupStylesBox}
                  required
                  disabled={!selectedUser}
                  onChange={(_ev, option) => {
                    setSelectedRole(option?.key as string);
                  }}
                />
              ) : (
                <p>MLOps roles could not be loaded. Please try again later or contact our support.</p>
              )}
            </>
          }
        />
        <WidgetItem
          data={{
            title: activeProject?.displayName || 'Project access control',
            description: 'Manage roles and permissions for users in this project',
            iconName: 'Favicon',
            id: 'project-name',
          }}
          descriptionField="description"
          iconNameField="iconName"
          titleField="title"
          idField={'id'}
        />
        {sharedUserItems?.length ? (
          <>
            <DetailsList
              columns={rowColumns}
              items={filteredSharedUserItems || []}
              selectionMode={SelectionMode.multiple}
              checkboxVisibility={CheckboxVisibility.always}
              // TODO: Fix type.
              selection={selection as unknown as ISelection<IObjectWithKey>}
              selectionPreservedOnEmptyClick
              onRenderDetailsHeader={onRenderDetailsHeader}
              styles={{
                root: {
                  minHeight: filteredSharedUserItems?.length ? 450 : undefined,
                  height: filteredSharedUserItems?.length ? undefined : 50,
                },
              }}
              rowStyles={{
                cell: cellStyles,
                checkCell: cellStyles,
              }}
            />
            {!filteredSharedUserItems?.length ? (
              <div className={classNames.noBindingContainer}>
                <Icon iconName="SearchIssue" style={{ fontSize: 48, padding: 20 }} />
                {'No users found matching the search criteria.\nPlease try a different search term.'}
              </div>
            ) : null}
          </>
        ) : (
          <div className={classNames.noBindingContainer}>
            {!loading && sharedUserItems && sharedUserItems?.length === 0 ? (
              <>
                <Icon iconName="AddGroup" style={{ fontSize: 48, padding: 20 }} />
                {`No MLOps users are assigned to this project.\nStart with adding someone.`}
              </>
            ) : (
              <Loader label="Loading project users" />
            )}
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default ProjectSettings;
