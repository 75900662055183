import { IDetailsRowProps, MessageBarType, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Button,
  buttonStylesGhost,
  detailsListStylesDefault,
  useAppNavigationContext,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Workspace } from '../../authz/gen/ai/h2o/workspace/v1/workspace_pb';
import List from '../../components/ListPages/List';
import ListPage from '../../components/ListPages/ListPage';
import { SecureStoreEntityType } from '../../secure-store/entity/types';
import { Secret_State } from '../../secure-store/gen/ai/h2o/securestore/v1/secret_pb';
import { BytesString } from '../../secure-store/gen/runtime';
import { useSecureStoreService } from '../../secure-store/hooks';
import { encodeBytes, formatError } from '../../utils/utils';
import { useWorkspaces } from '../Orchestrator/WorkspaceProvider';
import { onRenderRow, secretServiceColumns } from './components/parts';
import { SecretEditPanel } from './components/SecretEditPanel';
import { SecureStoreViewPanel } from './components/SecureStoreViewPanel';
import { ExtendedSecret } from './types';

const SecretServicePage = () => {
  const { activeWorkspace } = useWorkspaces();
  const { enableWorkspacesDropdown, disableWorkspacesDropdown } = useAppNavigationContext();
  const secureStoreService = useSecureStoreService();

  const theme = useTheme();
  const { addToast, removeAllToasts } = useToast();

  const [pageLoading, { setTrue: showPageLoading, setFalse: hidePageLoading }] = useBoolean(false);
  const [isCreatePanelOpen, { setTrue: showCreatePanel, setFalse: hideCreatePanel }] = useBoolean(false);
  const [fetchSecretsError, setFetchSecretsError] = useState<string>();
  const [secretRows, setSecretRows] = useState<ExtendedSecret[]>([]);
  const [expandedGroupKeys, setExpandedGroupKeys] = useState<string[]>([]);
  const [loadingGroupKeys, setLoadingGroupKeys] = useState<string[]>([]);
  const [secretForNewVersion, setSecretForNewVersion] = useState<string>();
  const [viewSecret, setViewSecret] = useState<ExtendedSecret>();

  const loadSecrets = useCallback(async () => {
    if (pageLoading) return;
    setFetchSecretsError('');
    showPageLoading();

    try {
      const { secrets = [] } = await secureStoreService.listSecrets({
        parent: activeWorkspace?.name || '',
        pageSize: 1000,
        showDeleted: true,
      });

      setSecretRows(
        secrets.map((secret) => ({
          ...secret,
          name: secret.name || '',
          data: [],
        }))
      );
    } catch (err: any) {
      const message = `Failed to fetch secrets: ${formatError(err)}`;

      setSecretRows([]);
      setFetchSecretsError(message);
      addToast({ messageBarType: MessageBarType.error, message });
    } finally {
      hidePageLoading();
    }
  }, [activeWorkspace, secureStoreService, setSecretRows, pageLoading]);

  const checkIfSecretExist = useCallback(
    async (value: string, workspace: Workspace) => {
      try {
        await secureStoreService.getSecret({ name: `${workspace.name}/secrets/${value}` });
        return true;
      } catch (error: any) {
        console.error('Error checking secret existence:', error);
        return false;
      }
    },
    [secureStoreService]
  );

  const loadSecretVersions = useCallback(
    async (secretName: string) => {
      if (viewSecret?.state === Secret_State.DELETED) return;

      setLoadingGroupKeys((prevKeys) => [...prevKeys, secretName]);

      try {
        const { secretVersions = [] } = await secureStoreService.listSecretVersions({
          parent: secretName,
          pageSize: 1000,
        });

        setSecretRows((prevKeys) =>
          prevKeys.map((row) => ({
            ...row,
            data: row.name === secretName ? [...secretVersions] : row.data,
          }))
        );

        if (viewSecret) {
          setViewSecret({ ...viewSecret, data: [...secretVersions] });
        }
      } catch (err) {
        const message = `Failed to fetch secrets: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setLoadingGroupKeys((prevKeys) => prevKeys.filter((key) => key !== secretName));
      }
    },
    [secretRows, viewSecret, secureStoreService, addToast, setSecretRows, setLoadingGroupKeys, setViewSecret]
  );
  const revealSecret = useCallback(
    async (secretVersionName: string) => {
      try {
        const data = await secureStoreService.revealSecretVersion({ name: secretVersionName });

        if (!data) return;

        const updatedSecretRows = secretRows.map((secret) => ({
          ...secret,
          data: secret.data.map((secretVersion) =>
            secretVersion.name === secretVersionName
              ? { ...secretVersion, value: data.value as BytesString }
              : secretVersion
          ),
        }));

        setSecretRows(updatedSecretRows);

        if (viewSecret) {
          const updatedViewSecret: ExtendedSecret = {
            ...viewSecret,
            data: viewSecret.data.map((secretVersion) => ({
              ...secretVersion,
              value: (secretVersion.name === secretVersionName ? data.value : secretVersion.value) as BytesString,
            })),
          };

          setViewSecret(updatedViewSecret);
        }
      } catch (err) {
        const message = `Failed to reveal secret: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      }
    },
    [secretRows, viewSecret, secureStoreService, addToast, setSecretRows, setViewSecret]
  );
  const createSecretVersion = useCallback(
    async (secretName: string, secretValue: string) => {
      const data = await secureStoreService.createSecretVersion({
        parent: secretName,
        secretVersion: { value: encodeBytes(secretValue) as BytesString },
      });

      if (secretForNewVersion) {
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Secret version has been successfully created',
        });

        await loadSecretVersions(secretName);

        setSecretForNewVersion(undefined);
      }

      hideCreatePanel();

      return data;
    },
    [secretForNewVersion, secureStoreService, addToast, setSecretForNewVersion]
  );
  const handleCreateSecret = useCallback(
    async (secretValue: string, secretId: string) => {
      try {
        const { secret } = await secureStoreService.createSecret({
          parent: activeWorkspace?.name || '',
          secret: {},
          secretId,
        });

        if (!secret?.name) return;

        await createSecretVersion(secret.name, secretValue);

        addToast({
          messageBarType: MessageBarType.success,
          message: 'Secret has been successfully created',
        });

        await loadSecrets();
        await loadSecretVersions(secret.name);

        setExpandedGroupKeys((prevKeys) => [...prevKeys, secret.name || '']);
      } catch (err) {
        const message = `Failed to fetch secrets: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      }
    },
    [
      activeWorkspace?.name,
      createSecretVersion,
      loadSecrets,
      loadSecretVersions,
      addToast,
      expandedGroupKeys,
      setExpandedGroupKeys,
    ]
  );
  const handleDeleteSecret = useCallback(
    async (secretName: string) => {
      removeAllToasts();

      try {
        await secureStoreService.deleteSecret({ name: secretName });

        addToast({
          messageBarType: MessageBarType.success,
          message: (
            <Stack>
              <p>Workspace successfully deleted.</p>
              <Stack horizontalAlign="end">
                <Button onClick={() => handleUnDeleteSecret(secretName)} styles={buttonStylesGhost}>
                  Undelete Secret
                </Button>
              </Stack>
            </Stack>
          ),
        });

        loadSecrets();
      } catch (err) {
        const message = `Failed to delete secret: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      }
    },
    [secureStoreService, loadSecrets, addToast]
  );
  const handleUnDeleteSecret = useCallback(
    async (secretName: string) => {
      try {
        await secureStoreService.unDeleteSecret({ name: secretName });

        removeAllToasts();
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Secret has been successfully restored',
        });
        loadSecrets();
      } catch (err) {
        const message = `Failed to restore secret: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      }
    },
    [secureStoreService, loadSecrets, addToast]
  );
  const handleSaveNew = (secretValue: string, secretName: string) => {
    if (secretForNewVersion) {
      void createSecretVersion(secretForNewVersion, secretValue);
    } else {
      void handleCreateSecret(secretValue, secretName);
    }
  };

  const toggleExpandSecretVersions = useCallback(
    (rowKey: string) => {
      const expanded = expandedGroupKeys.includes(rowKey);

      setExpandedGroupKeys(
        expanded ? expandedGroupKeys.filter((key) => key !== rowKey) : [...expandedGroupKeys, rowKey]
      );

      if (!expanded) {
        void loadSecretVersions(rowKey);
      } else {
        setSecretRows(secretRows.map((row) => (row.name === rowKey ? { ...row, data: [] } : row)));
      }
    },
    [expandedGroupKeys, secretRows, loadSecretVersions, setExpandedGroupKeys, setSecretRows]
  );

  const columns = useMemo(
    () =>
      secretServiceColumns({
        secretRows,
        expandedGroupKeys,
        loadingGroupKeys,
        revealSecret,
        handleDeleteSecret,
        handleUnDeleteSecret,
        showCreatePanel,
        setSecretForNewVersion,
        toggleExpandSecretVersions,
        setViewSecret,
        theme,
      }),
    [secretRows, expandedGroupKeys, loadingGroupKeys, handleDeleteSecret, toggleExpandSecretVersions, theme]
  );

  useEffect(() => {
    if (activeWorkspace) void loadSecrets();
  }, [activeWorkspace]);
  useEffect(() => {
    if (viewSecret && !viewSecret.data.length) void loadSecretVersions(viewSecret.name);
  }, [viewSecret]);
  useEffect(() => {
    enableWorkspacesDropdown();
    return disableWorkspacesDropdown;
  }, []);

  return (
    <>
      <ListPage
        error={fetchSecretsError}
        loading={pageLoading}
        copy={{
          title: 'Secrets',
          subtitle: '',
          loadingMessage: 'Loading Secrets...',
          noDataTitle: 'No secrets found',
          noDataMessage: '',
        }}
        primaryButtonProps={{
          text: 'Create Secret',
          disabled: !!fetchSecretsError,
          onClick: showCreatePanel,
        }}
        showNoResults={secretRows.length === 0}
        showSearchInput={false}
      >
        <List
          styles={detailsListStylesDefault}
          rowStyles={{}}
          dataTest="app-list"
          items={secretRows || []}
          columns={columns}
          onRenderRow={(rowProps?: IDetailsRowProps) => {
            return onRenderRow(rowProps, loadingGroupKeys.includes(rowProps?.item.name), theme);
          }}
        />
      </ListPage>

      <SecureStoreViewPanel
        isOpen={!!viewSecret}
        data-test="secret-view-panel"
        panelTitle="Secret details"
        item={viewSecret}
        type={SecureStoreEntityType.SecretService}
        onDismiss={() => setViewSecret(undefined)}
        revealSecret={revealSecret}
      />

      {activeWorkspace && (
        <SecretEditPanel
          isOpen={isCreatePanelOpen}
          onDismiss={hideCreatePanel}
          onSave={handleSaveNew}
          checkIfExist={(value) => checkIfSecretExist(value, activeWorkspace)}
        />
      )}
    </>
  );
};

export default SecretServicePage;
