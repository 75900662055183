import { MessageBarType } from '@fluentui/react';
import { DetailsList, IconButton, MessageBar, useTheme } from '@h2oai/ui-kit';
import { useState } from 'react';

import { FwPortLocalDeleteRequest } from '../../../../../admin-center/gen/fw_management/fw_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { useUser } from '../../../../../utils/hooks';
import { customTableStyles, messageBarContainerStyles } from '../../../common/common.styles';
import { getDummyList } from '../../../common/utils';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { TableBackgroundStyle, deleteButtonStyle } from '../FWManagement.styles';

interface OutboundPortItem {
  outboundPort: string;
  outboundPortProtocol: string;
}

interface PortTableProps {
  fwPorts: string[];
  onSubmitSuccess: () => Promise<void>;
  isLoading: boolean;
}

const dummyList = getDummyList<OutboundPortItem>(5);

export default function PortTable({ fwPorts, onSubmitSuccess, isLoading }: PortTableProps) {
  const service = useAdminCenterService();
  const theme = useTheme();
  const user = useUser();
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [showMessageBar, setShowMessagebar] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const portData: OutboundPortItem[] = fwPorts.map((port) => {
    const [outboundPort, protocol] = port.split('-');
    return {
      outboundPort,
      outboundPortProtocol: protocol ? protocol.toUpperCase() : '-',
    };
  });

  const handleDelete = async (portToDelete: string, protocolToDelete: string) => {
    try {
      const request: FwPortLocalDeleteRequest = {
        deletedFwPorts: [`${portToDelete}-${protocolToDelete.toLowerCase()}`],
        modifiedBy: user?.name ?? '',
        timestamp: Date.now() / 1000,
      };
      const response = await service.deleteLocalFwPorts(request);

      if (response.statusUpdate) {
        setResponseMessage(response.statusUpdate);
        setIsSuccess(true);
      } else {
        setResponseMessage('❌ Error: Failed to delete');
        setIsSuccess(false);
      }

      await onSubmitSuccess();
    } catch (error) {
      setResponseMessage(`❌ Network error: ${error}`);
    } finally {
      setShowMessagebar(true);
    }
  };

  const renderActionCell = (item: any): JSX.Element => {
    return (
      <div style={deleteButtonStyle}>
        <IconButton
          iconProps={{
            iconName: 'Delete',
          }}
          onClick={() => handleDelete(item.outboundPort, item.outboundPortProtocol)}
        />
      </div>
    );
  };
  return (
    <div style={TableBackgroundStyle(theme)}>
      {showMessageBar && (
        <div style={messageBarContainerStyles}>
          <MessageBar
            timeout={4000}
            onDismiss={() => setShowMessagebar(false)}
            messageBarType={isSuccess ? MessageBarType.success : MessageBarType.error}
          >
            {responseMessage}
          </MessageBar>
        </div>
      )}
      {!isLoading && portData.length === 0 ? (
        <MessageBar messageBarType={MessageBarType.warning}>{'No data available'}</MessageBar>
      ) : (
        <DetailsList
          columns={[
            {
              key: 'outboundPort',
              fieldName: 'outboundPort',
              name: 'Outbound Ports',
              minWidth: 200,
              onRender: (item: OutboundPortItem) => (
                <>{!isLoading ? item.outboundPort : <SkeletonLoader width="65%" height="100%" />}</>
              ),
            },
            {
              key: 'outboundPortProtocol',
              fieldName: 'outboundPortProtocol',
              name: 'Protocol',
              minWidth: 200,
              onRender: (item: OutboundPortItem) => (
                <>{!isLoading ? item.outboundPortProtocol : <SkeletonLoader width="65%" height="100%" />}</>
              ),
            },
            {
              key: 'portDltAction',
              fieldName: 'portDltAction',
              name: '',
              minWidth: 150,
              onRender: (item: OutboundPortItem) => (
                <>{!isLoading ? renderActionCell(item) : <SkeletonLoader width="65%" height="100%" />}</>
              ),
            },
          ]}
          styles={customTableStyles(theme, '200px')}
          items={isLoading ? dummyList : portData}
        />
      )}
    </div>
  );
}
