import { IButtonProps, IStackProps, Stack } from '@fluentui/react';
import {
  Button,
  buttonStylesDefault,
  buttonStylesPrimary,
  buttonStylesSplit,
  buttonStylesSplitGhost,
} from '@h2oai/ui-kit';
import { CSSTransition } from 'react-transition-group';

import { restrictionTooltipMessage } from '../../../../authz/providers/PermissionsProvider/PermissionsProvider';
import { EngineProfilesColumnProps, EntityModelTypes, EntityRowState } from './types';

const rowStackProps: IStackProps = {
  style: { height: '100%' },
  horizontal: true,
  horizontalAlign: 'end',
  verticalAlign: 'center',
};

export const onRenderActionsCell = <EntityModel extends EntityModelTypes>(
  group: EntityModel,
  props: EngineProfilesColumnProps<EntityModel>,
  index?: number
) => {
  const { entity, theme, activeEntity, onSave, handleDeleteEntity, updateActiveEntityState, permissions } = props;
  const { canCreate, canEdit, canView, canDelete } = permissions;
  const canInteract = canCreate || canEdit;
  const isNewModelItem = activeEntity?.rowState === EntityRowState.CREATE && index === 0;
  const expanded = (!!activeEntity && activeEntity.model.name === group.name) || isNewModelItem;

  const isCreate = expanded && activeEntity?.rowState === EntityRowState.CREATE;
  const isEdit = expanded && activeEntity?.rowState === EntityRowState.EDIT;
  const isView = expanded && activeEntity?.rowState === EntityRowState.VIEW;

  const toggleActiveEntity = (event?: any) => (model?: EntityModel, entityState?: EntityRowState) => {
    event?.stopPropagation();
    updateActiveEntityState(model, entityState);
  };

  const mainControlsProps: IButtonProps = {
    text: isView ? 'Close' : 'View',
    primaryActionButtonProps: { 'data-test': `${group.name}--view-button` } as IButtonProps,
    primaryDisabled: !canView,
    onClick: (event) => toggleActiveEntity(event)(group, isEdit || isView ? undefined : EntityRowState.VIEW),
  };

  return (
    <Stack {...rowStackProps}>
      <Stack
        tokens={{ childrenGap: 8 }}
        styles={{ root: { position: 'relative', paddingRight: 8 } }}
        horizontalAlign="end"
      >
        <CSSTransition in={isCreate || isEdit} timeout={300} classNames="slide" unmountOnExit>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Button styles={buttonStylesDefault} onClick={(event) => toggleActiveEntity(event)()}>
              {isCreate || isEdit ? 'Cancel' : 'Close'}
            </Button>
            {(isCreate || isEdit) && (
              <Button
                styles={buttonStylesPrimary}
                onClick={canInteract ? onSave : () => null}
                disabled={!isCreate && !props.formChanged}
                tooltip={restrictionTooltipMessage(!canInteract && props.formChanged)}
              >
                {isCreate ? 'Add' : 'Update'} {entity.displayName}
              </Button>
            )}
          </Stack>
        </CSSTransition>
        <CSSTransition in={!isCreate && !isEdit} timeout={300} classNames="slide" unmountOnExit>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Button
              {...mainControlsProps}
              split
              styles={[buttonStylesSplit, buttonStylesSplitGhost, { root: { width: 80 } }]}
              primaryDisabled={!canView}
              splitButtonMenuProps={{ 'data-test': `${group.name}--more-button` } as IButtonProps}
              menuItems={[
                {
                  key: 'edit-entity',
                  text: 'Edit',
                  disabled: !canEdit,
                  'data-test': `${group.name}--edit-button`,
                  onClick: !canEdit ? () => null : (event) => toggleActiveEntity(event)(group, EntityRowState.EDIT),
                  style: { ...(canEdit ? {} : { opacity: 0.5 }) },
                  iconProps: { iconName: 'Edit' },
                },
                {
                  key: 'delete-entity',
                  text: 'Delete',
                  disabled: !canDelete,
                  'data-test': `${group.name}--delete-button`,
                  onClick: !canDelete ? () => null : () => handleDeleteEntity(group.name || ''),
                  style: { color: theme.semanticColors?.buttonDanger, ...(canDelete ? {} : { opacity: 0.5 }) },
                  iconProps: {
                    iconName: 'Delete',
                    style: { color: 'var(--h2o-red400)', ...(canDelete ? {} : { opacity: 0.5 }) },
                  },
                },
              ]}
              tooltip={restrictionTooltipMessage(!canView)}
            />
          </Stack>
        </CSSTransition>
      </Stack>
    </Stack>
  );
};
