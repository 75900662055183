import { DatePicker, Search, useTheme } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { LoginEventsRequest, LoginStatisticsResponse } from '../../admin-center/gen/user_login/userlogin_pb';
import { useAdminCenterService } from '../../admin-center/hooks';
import {
  InfoLabel,
  InfoValue,
  UserLoginPageStatStyles,
  endDatePickerStyle,
  loginTopStyles,
  menuItemStyle,
  searchAreaStyle,
  startDatePickerStyle,
  tableAreaStyle,
} from './components/UserLogin/UserLogin.styles';
import UserLoginTable from './components/UserLogin/UserLoginTable/UserLoginTable';
import PageWrapper from './PageWrapper';

export default function UserLogin() {
  const service = useAdminCenterService();
  const theme = useTheme();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  sevenDaysAgo.setHours(0, 0, 0, 0);

  const [stats, setStats] = useState<LoginStatisticsResponse | null>(null);
  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [searchQuery, setSearchQuery] = useState('');
  const [endDate, setEndDate] = useState<Date>(today);

  const handleStartDateChange = (date: Date | null | undefined) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null | undefined) => {
    if (date) {
      setEndDate(date);
    }
  };

  const fetchLoginStats = async () => {
    try {
      const request: LoginEventsRequest = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };

      const response = await service.getUserLoginEventsStats(request);
      setStats(response);
    } catch (error) {
      console.error('Error fetching login statistics:', error);
    }
  };

  useEffect(() => {
    fetchLoginStats();
  }, [startDate, endDate]);

  return (
    <PageWrapper>
      <div>
        <div style={loginTopStyles}>
          <p>User login events updates hourly. All dates and times displayed are in your time zone.</p>
          <div style={menuItemStyle}>
            <DatePicker
              placeholder="Select start date"
              style={startDatePickerStyle}
              value={startDate}
              onSelectDate={handleStartDateChange}
            />
            <DatePicker
              placeholder="Select end date"
              style={endDatePickerStyle}
              value={endDate}
              onSelectDate={handleEndDateChange}
            />
          </div>
        </div>
      </div>

      <div style={UserLoginPageStatStyles(theme)}>
        <div>
          <p style={InfoLabel}>Total login count:</p>
          <p style={InfoValue}>{stats?.totalLoginCount ?? '-'}</p>
        </div>
        <div>
          <p style={InfoLabel}>Unique user count:</p>
          <p style={InfoValue}>{stats?.uniqueUserCount ?? '-'}</p>
        </div>
        <div>
          <p style={InfoLabel}>Most logged in user:</p>
          <p style={InfoValue}>{stats?.mostLoggedInUser ?? '-'}</p>
        </div>
        <div>
          <p style={InfoLabel}>Unique user domains:</p>
          <p style={InfoValue}>{stats?.uniqueUserDomains ?? '-'}</p>
        </div>
        <div>
          <p style={InfoLabel}>Most logged user domain:</p>
          <p style={InfoValue}>{stats?.mostLoggedUserDomain ?? '-'}</p>
        </div>
      </div>

      <div style={searchAreaStyle}>
        <Search
          emptyMessage="No results found"
          placeholder="Search user logins..."
          onSearchTextChange={(text: string) => setSearchQuery(text)}
          style={{ width: '250px', marginRight: '20px' }}
        />
      </div>
      <div style={tableAreaStyle}>
        <UserLoginTable startDate={startDate.toISOString()} endDate={endDate.toISOString()} searchQuery={searchQuery} />
      </div>
    </PageWrapper>
  );
}
