import { type IColumn } from '@fluentui/react';

import { EntityActionType, EntityFieldType } from '../../components/AdminSettings/Entity/constants';
import type { Entity, EntityAction, EntityField } from '../../components/AdminSettings/Entity/types';
import { getOptionsFromEnum, onRenderDisplayName } from '../../components/AdminSettings/Entity/utils';
import { NotebookKernelEntityType } from '../../notebook/entity/types';
import { onRenderBooleanCell, onRenderDataCell } from '../../utils/render';
import { ImagePullPolicy } from '../gen/ai/h2o/engine/v1/base_types_pb';
import { NotebookEngineImage } from '../gen/ai/h2o/engine/v1/notebook_engine_image_pb';
import {
  CreateNotebookEngineImageRequest,
  CreateNotebookEngineImageResponse,
  DeleteNotebookEngineImageRequest,
  DeleteNotebookEngineImageResponse,
  GetNotebookEngineImageRequest,
  GetNotebookEngineImageResponse,
  ListNotebookEngineImagesRequest,
  ListNotebookEngineImagesResponse,
  NotebookEngineImageService_CreateNotebookEngineImage,
  NotebookEngineImageService_DeleteNotebookEngineImage,
  NotebookEngineImageService_GetNotebookEngineImage,
  NotebookEngineImageService_ListNotebookEngineImages,
  NotebookEngineImageService_UpdateNotebookEngineImage,
  UpdateNotebookEngineImageRequest,
  UpdateNotebookEngineImageResponse,
} from '../gen/ai/h2o/engine/v1/notebook_engine_image_service_pb';

const entityType = NotebookKernelEntityType.NotebookImage;
const required = true;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateNotebookEngineImage',
    rpc: NotebookEngineImageService_CreateNotebookEngineImage,
    type: EntityActionType.Create,
    responseKey: 'notebookEngineImage',
    requestNameKey: 'notebookEngineImageId',
    requestPayloadKey: 'notebookEngineImage',
    requestNameKeyPrefix: 'notebookEngineImage/',
    entityType,
  } as EntityAction<
    NotebookEngineImage,
    NotebookKernelEntityType,
    CreateNotebookEngineImageRequest,
    CreateNotebookEngineImageResponse
  >,
  [EntityActionType.Get]: {
    name: 'GetNotebookEngineImage',
    rpc: NotebookEngineImageService_GetNotebookEngineImage,
    type: EntityActionType.Get,
    responseKey: 'notebookEngineImage',
    entityType,
  } as EntityAction<
    NotebookEngineImage,
    NotebookKernelEntityType,
    GetNotebookEngineImageRequest,
    GetNotebookEngineImageResponse
  >,
  [EntityActionType.List]: {
    name: 'ListNotebookEngineImage',
    rpc: NotebookEngineImageService_ListNotebookEngineImages,
    type: EntityActionType.List,
    responseKey: 'notebookEngineImages',
    entityType,
  } as EntityAction<
    NotebookEngineImage,
    NotebookKernelEntityType,
    ListNotebookEngineImagesRequest,
    ListNotebookEngineImagesResponse
  >,
  [EntityActionType.Update]: {
    name: 'UpdateNotebookEngineImage',
    rpc: NotebookEngineImageService_UpdateNotebookEngineImage,
    type: EntityActionType.Update,
    entityType,
    responseKey: 'notebookEngineImage',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'notebookEngineImage',
  } as EntityAction<
    NotebookEngineImage,
    NotebookKernelEntityType,
    UpdateNotebookEngineImageRequest,
    UpdateNotebookEngineImageResponse
  >,
  [EntityActionType.Delete]: {
    name: 'DeleteNotebookEngineImage',
    rpc: NotebookEngineImageService_DeleteNotebookEngineImage,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<
    NotebookEngineImage,
    NotebookKernelEntityType,
    DeleteNotebookEngineImageRequest,
    DeleteNotebookEngineImageResponse
  >,
};

export const columns: IColumn[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 350,
    onRender: onRenderDisplayName,
  },
  {
    key: 'enabled',
    name: 'Enabled',
    fieldName: 'enabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'image',
    name: 'Image',
    fieldName: 'image',
    minWidth: 350,
    onRender: onRenderDataCell,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<NotebookEngineImage>[] = [
  {
    name: 'name',
    type: EntityFieldType.IdOnDisplayAndId,
    label: 'Name',
    description: 'Notebook Image resource name.',
    required,
  },
  {
    name: 'displayName',
    type: EntityFieldType.DisplayOnDisplayAndId,
    label: 'Display Name',
    description: 'Human-readable name of Notebook Image',
    required,
  },
  {
    name: 'enabled',
    type: EntityFieldType.Boolean,
    label: 'Enabled',
  },
  {
    name: 'image',
    type: EntityFieldType.Text,
    label: 'Image',
    required,
  },
  {
    name: 'imagePullPolicy',
    type: EntityFieldType.SelectEnum,
    label: 'Image pull policy',
    options: getOptionsFromEnum(ImagePullPolicy, 'IMAGE_PULL_POLICY_', [ImagePullPolicy.UNSPECIFIED]),
  },
  {
    name: 'imagePullSecrets',
    type: EntityFieldType.StringArray,
    label: 'Image pull secrets',
  },
];

export const notebookEngineImageEntity: Entity<NotebookEngineImage, NotebookKernelEntityType> = {
  type: entityType,
  name: 'NotebookEngineImage',
  createButtonLabel: '',
  displayName: 'Notebook Engine Image',
  displayNameKey: 'displayName',
  requestNameKeyPrefix: 'workspaces/global/notebookEngineImages/',
  actions,
  columns,
  fields,
  emptyModel: {
    name: 'new-notebook-engine-image',
    displayName: 'New Notebook Engine Image',
    image: '',
    enabled: true,
    imagePullPolicy: ImagePullPolicy.ALWAYS,
    imagePullSecrets: [],
  },
  canBeReordered: false,
};
