import { MessageBarType } from '@fluentui/react';
import {
  Button,
  Item,
  Loader,
  LoaderType,
  MessageBar,
  buttonStylesPrimary,
  useClassNames,
  useTheme,
} from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { NatGwRequest } from '../../admin-center/gen/environment_info/natgw_pb';
import { useAdminCenterService } from '../../admin-center/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { IEnvInfoPageStyles, envInfoStyles, loaderStyles, versionTagStyles } from './components/EnvInfo/EnvInfo.styles';
import PageWrapper from './PageWrapper';

const managedCloudVersion = process.env.REACT_APP_MC_VERSION || 'Unknown Version';

export default function EnvInfo() {
  const [natGwIps, setNatGwIps] = useState<string[]>([]);
  const [loadIpsSuccess, setLoadIpsSuccess] = useState<boolean>(true);
  const service = useAdminCenterService();

  const theme = useTheme();
  const classNames = useClassNames<IEnvInfoPageStyles, ClassNamesFromIStyles<IEnvInfoPageStyles>>(
    'envInfo',
    envInfoStyles(theme)
  );

  const fetchIps = async () => {
    try {
      const req: NatGwRequest = {};
      const response = await service.getNatGwPublicIps(req);
      const ips = response.publicIps || [];

      setNatGwIps(ips);
    } catch (error) {
      setLoadIpsSuccess(false);
      console.error('Error fetching NAT gateway IPs:', error);
    }
  };

  useEffect(() => {
    fetchIps();
  }, []);

  const handleReleaseNotesButtonClick = () => {
    window.open('https://docs.h2o.ai/haic-documentation/mc-release-notes/', '_blank');
  };

  return (
    <PageWrapper>
      <div className={classNames.container}>
        <div className={classNames.content}>
          <header className={classNames.header}>
            <div>
              <div className={classNames.titleWrapper}>Admin Center ⚙️</div>
              <div className={classNames.description}>
                Find useful information regarding the technical details of your environment.
              </div>
            </div>
            <Button
              styles={buttonStylesPrimary}
              iconName="FileSymlink"
              text="Managed Cloud Release Notes"
              onClick={handleReleaseNotesButtonClick}
            />
          </header>

          <div className={classNames.versionInfo}>
            <p>Managed Cloud Version:</p>
            <Item
              data={{
                id: 0,
                name: managedCloudVersion,
              }}
              styles={versionTagStyles}
              idField="id"
              labelField="name"
            />
          </div>

          <div className={classNames.card}>
            <div className={classNames.cardHeader}>NAT Gateway Public IPs</div>
            <div className={classNames.ipList}>
              {!loadIpsSuccess ? (
                <MessageBar messageBarType={MessageBarType.error}>{"Couldn't load NAT Gateway IPs"}</MessageBar>
              ) : natGwIps.length > 0 ? (
                natGwIps.map((ip, index) => (
                  <div key={index} className={classNames.ipItem}>
                    <div className={classNames.ipStatus}>
                      <div className={classNames.statusDot} />
                      <p>{ip}</p>
                    </div>
                  </div>
                ))
              ) : (
                <Loader styles={loaderStyles(theme)} type={LoaderType.progressIndicator} />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
