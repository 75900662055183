import { IH2OTheme, IItemStyles, Sizes } from '@h2oai/ui-kit';

import { tagStyle } from '../../common/common.styles';

export const aiemTopStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '50px',
};

export const aiemMenuStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: 'auto',
  alignItems: 'center',
};

export const aiemTableStyle = (theme: IH2OTheme) => ({
  backgroundColor: theme.palette?.white,
  padding: Sizes.padding,
  height: '600px',
  overflowY: 'hidden' as 'auto' | 'hidden' | 'scroll' | 'visible',
  overflowX: 'auto' as 'auto' | 'hidden' | 'scroll' | 'visible',
});

export const startedEventStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.green100,
    color: theme.semanticColors?.categoryText,
  },
});

export const deletedEventStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.red100,
    color: theme.semanticColors?.categoryText,
  },
});

export const pausedEventStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.yellow200,
    color: theme.semanticColors?.categoryText,
  },
});
