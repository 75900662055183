// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/mlops/batch/v1/sink_spec_service.proto (package ai.h2o.mlops.batch.v1, syntax proto3)
/* eslint-disable */

import type { SinkSpec } from "./sink_spec_pb";
import { RPC } from "../../../../../runtime";

/**
 * Request message for GetSinkSpec
 *
 * @generated from message ai.h2o.mlops.batch.v1.GetSinkSpecRequest
 */
export type GetSinkSpecRequest = {
/**
 * Required. SinkSpec resource name.
 * Format: sinkSpecs/{spec}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetSinkSpec
 *
 * @generated from message ai.h2o.mlops.batch.v1.GetSinkSpecResponse
 */
export type GetSinkSpecResponse = {
/**
 * The requested spec.
 *
 * @generated from field: ai.h2o.mlops.batch.v1.SinkSpec sink_spec = 1;
 */
sinkSpec?: SinkSpec;
}
;
/**
 * Request message ListSinkSpecs
 * (-- api-linter: core::0158::request-page-size-field=disabled
 *     aip.dev/not-precedent: There will be only couple of specs. --)
 * (-- api-linter: core::0158::request-page-token-field=disabled
 *     aip.dev/not-precedent: There will be only couple of specs. --)
 *
 * @generated from message ai.h2o.mlops.batch.v1.ListSinkSpecsRequest
 */
export type ListSinkSpecsRequest = {
}
;
/**
 * Response message for ListSinkSpecs
 * (-- api-linter: core::0158::response-next-page-token-field=disabled
 *     aip.dev/not-precedent: There will be only couple of specs. --)
 *
 * @generated from message ai.h2o.mlops.batch.v1.ListSinkSpecsResponse
 */
export type ListSinkSpecsResponse = {
/**
 * Collection of specs.
 *
 * @generated from field: repeated ai.h2o.mlops.batch.v1.SinkSpec sink_specs = 1;
 */
sinkSpecs?: SinkSpec[];
}
;
/**
 * Returns a specific SinkSpec.
 *
 * @generated from rpc ai.h2o.mlops.batch.v1.SinkSpecService.GetSinkSpec
 */
export const SinkSpecService_GetSinkSpec = new RPC<GetSinkSpecRequest,GetSinkSpecResponse>("GET", "/v1/{name=sinkSpecs/*}");
/**
 * Returns a collection of SinkSpecs.
 *
 * @generated from rpc ai.h2o.mlops.batch.v1.SinkSpecService.ListSinkSpecs
 */
export const SinkSpecService_ListSinkSpecs = new RPC<ListSinkSpecsRequest,ListSinkSpecsResponse>("GET", "/v1/sinkSpecs");
