import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { AppInstance } from '../../ai.h2o.cloud.appstore';
import { AppService } from '../../services/api';
import { getInstanceAppTitleVersion, handleErrMsg } from '../../utils/utils';
import InstanceLogPage, { InstanceLogType } from '../InstanceLogPage/InstanceLogPage';
import { useWorkspaces } from '../Orchestrator/WorkspaceProvider';
import { RoutePaths } from '../Routes';

export interface AppInstanceLogPageProps {
  instanceId: string;
}

function AppInstanceLogPage({ instanceId }: AppInstanceLogPageProps) {
  // state
  const [appInstance, setAppInstance] = useState<AppInstance | null>(null),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    [websocketUrl, setWebsocketUrl] = useState<URL>(),
    // utilities
    { addToast } = useToast(),
    // callbacks
    getInstance = useCallback(async () => {
      try {
        const { instance: appInstance } = await AppService.getAppInstance({
          id: instanceId,
          includeAppDetails: true,
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        setAppInstance(appInstance);
      } catch ({ message }) {
        addToast({
          messageBarType: MessageBarType.error,
          message: `An error occurred while loading the app instance: ${handleErrMsg(message as string)}`,
        });
      }
    }, [instanceId, addToast]);

  // effects
  useEffect(() => {
    const adminPath = RoutePaths.ADMIN_INSTANCELOG.split(':')[0];
    const pathname = window.location.pathname.includes(adminPath)
      ? `/v1/adminlog/${instanceId}`
      : `/v1/log/${instanceId}`;
    setWebsocketUrl(new URL(`${window.location.origin}${pathname}`));
  }, [instanceId]);
  useEffect(() => {
    getInstance();
  }, [instanceId, getInstance]);
  const instanceName = getInstanceAppTitleVersion(appInstance);
  return (
    <InstanceLogPage
      instanceType={InstanceLogType.APP}
      instanceName={instanceName}
      instanceId={instanceId}
      enablePreviousLog={true}
      websocketUrl={websocketUrl}
      instance={appInstance || undefined}
    />
  );
}

export default AppInstanceLogPage;
