// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file environment_info/natgw.proto (package environment_info, syntax proto3)
/* eslint-disable */

import { RPC } from "../runtime";

/**
 * @generated from message environment_info.NatGwRequest
 */
export type NatGwRequest = {
}
;
/**
 * @generated from message environment_info.NatGwResponse
 */
export type NatGwResponse = {
/**
 * @generated from field: repeated string public_ips = 1;
 */
publicIps?: string[];
}
;
/**
 * @generated from rpc environment_info.NatGwService.GetNatGwIps
 */
export const NatGwService_GetNatGwIps = new RPC<NatGwRequest,NatGwResponse>("GET", "/getNatGWIP");
