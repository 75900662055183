import { PanelType, Stack, Text } from '@fluentui/react';
import { Button, FontSizes, Item, Panel, TextWithCopy, defaultPalette, itemStylesTag } from '@h2oai/ui-kit';
import { useMemo } from 'react';

import { EntityType } from '../../../aiem/entity/types';
import { ImagePullPolicy } from '../../../aiem/gen/ai/h2o/engine/v1/base_types_pb';
import { DAIEngineVersion } from '../../../aiem/gen/ai/h2o/engine/v1/dai_engine_version_pb';
import { H2OEngineVersion } from '../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_version_pb';
import { getIdFromName } from '../../../aiem/utils';
import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { formatDateWithTime } from '../../../utils/utils';

export const ImagePullPolicyMap = new Map<ImagePullPolicy, string>([
  [ImagePullPolicy.ALWAYS, 'Always'],
  [ImagePullPolicy.IF_NOT_PRESENT, 'If Not Present'],
  [ImagePullPolicy.NEVER, 'Never'],
  [ImagePullPolicy.UNSPECIFIED, 'Unspecified'],
]);

const viewLabel = { styles: { root: { fontSize: FontSizes.xsmall, color: defaultPalette?.gray500, width: '40%' } } };
const viewValue = {
  styles: {
    root: {
      fontSize: FontSizes.xsmall,
      color: defaultPalette?.gray900,
      whiteSpace: 'nowrap',
      maxWidth: '60%',
      overflow: 'hidden' as unknown as 'hidden', // not sure why this is needed, but it is
    },
  },
};

const renderEngineVersion = (version?: DAIEngineVersion): JSX.Element => {
  if (!version) {
    return <></>;
  }
  return (
    <>
      <Stack horizontal>
        <Text {...viewLabel}>Name</Text>
        <Text {...viewValue}>{getIdFromName(version.name) || '-'}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Aliases</Text>
        {version.aliases?.length
          ? version.aliases.map((d: string) => (
              <Item
                key={`key_${d}`}
                styles={itemStylesTag}
                idField="id"
                labelField="title"
                styleField="style"
                data={{
                  id: d,
                  title: d,
                  style: {
                    backgroundColor: defaultPalette.gray200,
                    color: defaultPalette.gray800,
                  },
                }}
              />
            ))
          : '-'}
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Deprecated</Text>
        <Text {...viewValue}>{version.deprecated ? 'Yes' : 'No'}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Image</Text>
        <Text {...viewValue} title={version.image}>
          <TextWithCopy text={version.image} />
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Image pull policy</Text>
        <Text {...viewValue}>
          {version.imagePullPolicy ? ImagePullPolicyMap.get(version.imagePullPolicy) || '-' : '-'}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Image pull secrets</Text>
        <Text {...viewValue}>{version.imagePullSecrets?.join(', ') || '-'}</Text>
      </Stack>

      <Stack horizontal>
        <Text {...viewLabel}>Created by</Text>
        <Text {...viewValue} title={version.creatorDisplayName}>
          {version.creatorDisplayName}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Created date</Text>
        <Text {...viewValue} title={version.createTime}>
          {formatDateWithTime(new Date(version.createTime || ''))}
        </Text>
      </Stack>

      {version.updaterDisplayName && (
        <Stack horizontal>
          <Text {...viewLabel}>Updated by</Text>
          <Text {...viewValue} title={version.updaterDisplayName}>
            {version.updaterDisplayName}
          </Text>
        </Stack>
      )}
      {version.updateTime && (
        <Stack horizontal>
          <Text {...viewLabel}>Update date</Text>
          <Text {...viewValue} title={version.updateTime}>
            {formatDateWithTime(new Date(version.updateTime))}
          </Text>
        </Stack>
      )}
    </>
  );
};

export interface IAIEMSettingsViewPanelProps<EntityModel> {
  item?: EntityModel;
  onDismiss: () => any;
  panelTitle: string;
  type: EntityType;
}

export function AIEMSettingsViewPanel<EntityModel>(props: IAIEMSettingsViewPanelProps<EntityModel>) {
  const { item, type, onDismiss, panelTitle } = props;

  const content = useMemo(() => {
    switch (type) {
      case EntityType.DAIEngineVersion:
      case EntityType.H2OEngineVersion:
        return renderEngineVersion(item as unknown as DAIEngineVersion | H2OEngineVersion);

      default:
        return null;
    }
  }, [type, item]);

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText={panelTitle}
      isFooterAtBottom
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
      onRenderFooterContent={() => {
        return (
          <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
            <Button text="Close" onClick={onDismiss} />
          </Stack>
        );
      }}
    >
      <Stack tokens={{ childrenGap: 20 }}>{content}</Stack>
    </Panel>
  );
}
