import { IH2OTheme, IItemStyles } from '@h2oai/ui-kit';

import { tagStyle } from '../../../common/common.styles';

export const createdEventStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.green100,
    color: theme.semanticColors?.categoryText,
  },
});

export const deletedEventStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.red100,
    color: theme.semanticColors?.categoryText,
  },
});

export const likedEventStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.blue200,
    color: theme.semanticColors?.categoryText,
  },
});

export const pinnedEventStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.yellow200,
    color: theme.semanticColors?.categoryText,
  },
});

export const updatedEventStyles = (theme: IH2OTheme): Partial<IItemStyles> => ({
  root: {
    ...tagStyle(theme),
    backgroundColor: theme.palette?.gray300,
    color: theme.semanticColors?.categoryText,
  },
});
