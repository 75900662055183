import { DatePicker, Pivot, Search } from '@h2oai/ui-kit';
import { useState } from 'react';

import {
  appEventTopStyles,
  dateAreaStyle,
  endDateStyle,
  searchAreaStyle,
  searchStyle,
  startDateStyle,
  tableAreaStyle,
} from './components/AppEvent/AppEvent.styles';
import AppTable from './components/AppEvent/AppTable/AppTable';
import InstanceTable from './components/AppEvent/InstanceTable/InstanceTable';
import PageWrapper from './PageWrapper';

export default function AppEvent() {
  const [searchQuery, setSearchQuery] = useState('');
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  sevenDaysAgo.setHours(0, 0, 0, 0);

  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date>(today);

  const handleStartDateChange = (date: Date | null | undefined) => {
    if (date) {
      setStartDate(date);
    }
  };
  const handleEndDateChange = (date: Date | null | undefined) => {
    if (date) {
      setEndDate(date);
    }
  };

  return (
    <PageWrapper>
      <div>
        <p>Application events updates hourly. All dates and times displayed are in your time zone.</p>
      </div>
      <div style={appEventTopStyles}>
        <div style={searchAreaStyle}>
          <Search
            emptyMessage="No results found"
            placeholder="Search events..."
            onSearchTextChange={(text: string) => setSearchQuery(text)}
            style={searchStyle}
          />
        </div>
        <div style={dateAreaStyle}>
          <DatePicker
            placeholder="Select start date"
            style={startDateStyle}
            value={startDate}
            onSelectDate={handleStartDateChange}
          />
          <DatePicker
            placeholder="Select end date"
            style={endDateStyle}
            value={endDate}
            onSelectDate={handleEndDateChange}
          />
        </div>
      </div>
      <div>
        <Pivot
          items={[
            {
              content: (
                <div style={tableAreaStyle}>
                  <AppTable
                    startDate={startDate.toISOString()}
                    endDate={endDate.toISOString()}
                    searchQuery={searchQuery}
                  />
                </div>
              ),
              headerText: 'Applications',
            },
            {
              content: (
                <div style={tableAreaStyle}>
                  <InstanceTable
                    startDate={startDate.toISOString()}
                    endDate={endDate.toISOString()}
                    searchQuery={searchQuery}
                  />
                </div>
              ),
              headerText: 'Instances',
            },
          ]}
        />
      </div>
    </PageWrapper>
  );
}
