import { H2OEngineProfileOptionKeyType } from '../../components/AIEnginesPage/constants';
import { fetchWrapRPC } from '../../services/api';
import { defaultBasePath, defaultEnginesListRequest, defaultWorkspaceName } from '../defaults';
import { DAIEngine, DAIEngine_State } from '../gen/ai/h2o/engine/v1/dai_engine_pb';
import {
  CreateDAIEngineResponse,
  DAIEngineServiceMigrateCreatorResponse,
  DAIEngineServiceUpgradeDAIEngineVersionResponse,
  DAIEngineService_CreateDAIEngine,
  DAIEngineService_DeleteDAIEngine,
  DAIEngineService_GetDAIEngine,
  DAIEngineService_ListDAIEngines,
  DAIEngineService_MigrateCreator,
  DAIEngineService_PauseDAIEngine,
  DAIEngineService_ResizeStorage,
  DAIEngineService_ResumeDAIEngine,
  DAIEngineService_UpdateDAIEngine,
  DAIEngineService_UpgradeDAIEngineVersion,
  GetDAIEngineResponse,
  ListDAIEnginesResponse,
  PauseDAIEngineResponse,
  ResumeDAIEngineResponse,
  UpdateDAIEngineResponse,
} from '../gen/ai/h2o/engine/v1/dai_engine_service_pb';
import { DAIEngineVersion } from '../gen/ai/h2o/engine/v1/dai_engine_version_pb';
import { DAIEngineVersionService_ListDAIEngineVersions } from '../gen/ai/h2o/engine/v1/dai_engine_version_service_pb';
import { Engine_Type } from '../gen/ai/h2o/engine/v1/engine_pb';
import { EngineService_ListEngines, type ListEnginesResponse } from '../gen/ai/h2o/engine/v1/engine_service_pb';
import { H2OEngine, H2OEngine_State } from '../gen/ai/h2o/engine/v1/h2o_engine_pb';
import {
  CalculateH2OEngineSizeCompressedDatasetRequest,
  CalculateH2OEngineSizeRawDatasetRequest,
  CreateH2OEngineResponse,
  GetH2OEngineResponse,
  H2OEngineService_CalculateH2OEngineSizeCompressedDataset,
  H2OEngineService_CalculateH2OEngineSizeRawDataset,
  H2OEngineService_CreateH2OEngine,
  H2OEngineService_DeleteH2OEngine,
  H2OEngineService_GetH2OEngine,
  H2OEngineService_ListH2OEngines,
  H2OEngineService_TerminateH2OEngine,
  H2OEngineSize,
  ListH2OEnginesResponse,
} from '../gen/ai/h2o/engine/v1/h2o_engine_service_pb';
import { H2OEngineVersion } from '../gen/ai/h2o/engine/v1/h2o_engine_version_pb';
import { H2OEngineVersionService_ListH2OEngineVersions } from '../gen/ai/h2o/engine/v1/h2o_engine_version_service_pb';
import { NotebookEngineImageService_ListNotebookEngineImages } from '../gen/ai/h2o/engine/v1/notebook_engine_image_service_pb';
import { NotebookEngine, NotebookEngine_State } from '../gen/ai/h2o/engine/v1/notebook_engine_pb';
import {
  CreateNotebookEngineResponse,
  GetNotebookEngineResponse,
  ListNotebookEnginesResponse,
  NotebookEngineService_CreateNotebookEngine,
  NotebookEngineService_DeleteNotebookEngine,
  NotebookEngineService_GetNotebookEngine,
  NotebookEngineService_ListNotebookEngines,
  NotebookEngineService_PauseNotebookEngine,
  NotebookEngineService_ResumeNotebookEngine,
  NotebookEngineService_UpdateNotebookEngine,
  PauseNotebookEngineResponse,
  ResumeNotebookEngineResponse,
  UpdateNotebookEngineResponse,
} from '../gen/ai/h2o/engine/v1/notebook_engine_service_pb';
import { RPC, RequestConfig, toBigIntString } from '../gen/runtime';
import { generateId } from '../utils';
import {
  AIEMOpType,
  AIEngine,
  CreateEngineRequest,
  Endpoint,
  EngineListRequest,
  EngineListResponse,
  EngineMigrateCreatorRequest,
  EngineResizeStorageRequest,
  EngineResponse,
  EngineTypeService,
  EngineUpdateRequest,
  EngineUpgradeRequest,
  H2OEngineSizeService,
} from './types';

export enum ConstraintSetType {
  DAI_ENGINE_CONSTRAINT_SET = 'daiEngineConstraintSet',
  H2O_ENGINE_CONSTRAINT_SET = 'h2oEngineConstraintSet',
}

const toEngineListResponse = (
  originalResponse: EngineListResponse,
  sourceField:
    | keyof ListEnginesResponse
    | keyof ListDAIEnginesResponse
    | keyof ListH2OEnginesResponse
    | keyof ListNotebookEnginesResponse
): EngineListResponse => ({
  engines: originalResponse[sourceField],
  nextPageToken: originalResponse.nextPageToken,
  totalSize: originalResponse.totalSize,
});

const castEngineResponse = (
  originalResponse: EngineResponse,
  sourceField:
    | keyof CreateDAIEngineResponse
    | keyof CreateNotebookEngineResponse
    | keyof CreateH2OEngineResponse
    | keyof GetDAIEngineResponse
    | keyof GetNotebookEngineResponse
    | keyof GetH2OEngineResponse
    | keyof PauseDAIEngineResponse
    | keyof PauseNotebookEngineResponse
    | keyof ResumeDAIEngineResponse
    | keyof ResumeNotebookEngineResponse
    | keyof UpdateDAIEngineResponse
    | keyof UpdateNotebookEngineResponse
    | keyof DAIEngineServiceUpgradeDAIEngineVersionResponse
    | keyof DAIEngineServiceMigrateCreatorResponse
) => ({
  engine: originalResponse[sourceField],
});

const abortableBag: { [P in keyof AIEMOpType as string]: boolean } = {
  [AIEMOpType.checkId]: false,
  [AIEMOpType.list]: false,
};

let controller = new AbortController(),
  signal = controller.signal;

const abortController = () => {
    controller.abort();
    controller = new AbortController();
    signal = controller.signal;
  },
  considerAborting = (op: AIEMOpType) => {
    if (abortableBag[op]) abortController();
    abortableBag[op] = true;
  },
  abortableRequest = async (request: any, op: AIEMOpType) => {
    considerAborting(op);
    try {
      return await request();
    } catch (error) {
      if (error instanceof Response) {
        if (error.status === 403) return {};
        if (error.headers.get('content-type')?.includes('application/json')) {
          const json = await error.json();
          if (json.error?.code === 20) return {};
          throw json;
        }
      }
      throw error;
    } finally {
      abortableBag[op] = false;
    }
  };

export const getEndpointCall = (requestConfig: RequestConfig, op: AIEMOpType, type = Engine_Type.UNSPECIFIED) => {
  const invalidOpError = () => new Error('Invalid Operation: Bad Engine or Parameter Name');
  const serviceCaller = <RequestMessage, ResponseMessage>(
    rpc: RPC<RequestMessage, ResponseMessage>,
    request: RequestMessage,
    abortable = false
  ) => fetchWrapRPC(rpc, requestConfig)(request, abortable ? { signal } : undefined);

  const endpoints: Endpoint = {
    [AIEMOpType.checkId]: {
      [Engine_Type.DRIVERLESS_AI]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        try {
          return castEngineResponse(
            (await abortableRequest(
              () => serviceCaller(DAIEngineService_GetDAIEngine, { name }, true),
              AIEMOpType.checkId
            )) as EngineResponse,
            'daiEngine'
          );
        } catch (error) {
          if (error instanceof Response && error.status === 403) {
            return { engine: {} as AIEngine };
          }
          throw error;
        }
      },
      [Engine_Type.NOTEBOOK]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        try {
          return castEngineResponse(
            (await abortableRequest(
              () => serviceCaller(NotebookEngineService_GetNotebookEngine, { name }, true),
              AIEMOpType.checkId
            )) as EngineResponse,
            'daiEngine'
          );
        } catch (error) {
          if (error instanceof Response && error.status === 403) {
            return { engine: {} as AIEngine };
          }
          throw error;
        }
      },
      [Engine_Type.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        try {
          return castEngineResponse(
            (await abortableRequest(
              () => serviceCaller(H2OEngineService_GetH2OEngine, { name }, true),
              AIEMOpType.checkId
            )) as EngineResponse,
            'h2oEngine'
          );
        } catch (error) {
          if (error instanceof Response && error.status === 403) {
            return { engine: {} as AIEngine };
          }
          throw error;
        }
      },
    },
    [AIEMOpType.create]: {
      [Engine_Type.DRIVERLESS_AI]: async (engine: AIEngine, _, parent = defaultWorkspaceName) => {
        const req: CreateEngineRequest = {
          daiEngine: {
            ...(engine as DAIEngine),
            state: DAIEngine_State.UNSPECIFIED,
          },
          parent,
          daiEngineId: engine.id || generateId(engine.displayName!),
        };
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_CreateDAIEngine, req)) as EngineResponse,
          'daiEngine'
        );
      },
      [Engine_Type.NOTEBOOK]: async (engine: AIEngine, _, parent = defaultWorkspaceName) => {
        const req: CreateEngineRequest = {
          notebookEngine: {
            ...(engine as NotebookEngine),
            state: NotebookEngine_State.UNSPECIFIED,
          },
          parent,
          notebookEngineId: engine.id || generateId(engine.displayName!),
        };
        return castEngineResponse(
          (await serviceCaller(NotebookEngineService_CreateNotebookEngine, req)) as EngineResponse,
          'notebookEngine'
        );
      },
      [Engine_Type.H2O]: async (engine: AIEngine, _, parent = defaultWorkspaceName) => {
        const req: CreateEngineRequest = {
          h2oEngine: {
            ...(engine as H2OEngine),
            state: H2OEngine_State.UNSPECIFIED,
          },
          parent,
          h2oEngineId: engine.id || generateId(engine.displayName!),
        };
        return castEngineResponse(
          (await serviceCaller(H2OEngineService_CreateH2OEngine, req)) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.get]: {
      [Engine_Type.DRIVERLESS_AI]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_GetDAIEngine, { name })) as EngineResponse,
          'daiEngine'
        );
      },
      [Engine_Type.NOTEBOOK]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(NotebookEngineService_GetNotebookEngine, { name })) as EngineResponse,
          'notebookEngine'
        );
      },
      [Engine_Type.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(H2OEngineService_GetH2OEngine, { name })) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.list]: {
      [Engine_Type.UNSPECIFIED]: async (req = defaultEnginesListRequest) => {
        return toEngineListResponse(
          (await abortableRequest(
            () => serviceCaller(EngineService_ListEngines, req),
            AIEMOpType.checkId
          )) as EngineListResponse,
          'engines'
        );
      },
      [Engine_Type.DRIVERLESS_AI]: async (req: EngineListRequest = defaultEnginesListRequest) => {
        return toEngineListResponse(
          (await abortableRequest(
            serviceCaller(DAIEngineService_ListDAIEngines, req, true),
            AIEMOpType.checkId
          )) as EngineListResponse,
          'daiEngines'
        );
      },
      [Engine_Type.NOTEBOOK]: async (req: EngineListRequest = defaultEnginesListRequest) => {
        return toEngineListResponse(
          (await abortableRequest(
            serviceCaller(NotebookEngineService_ListNotebookEngines, req, true),
            AIEMOpType.checkId
          )) as EngineListResponse,
          'notebookEngines'
        );
      },
      [Engine_Type.H2O]: async (req: EngineListRequest = defaultEnginesListRequest) => {
        return toEngineListResponse(
          (await abortableRequest(
            serviceCaller(H2OEngineService_ListH2OEngines, req, true),
            AIEMOpType.checkId
          )) as EngineListResponse,
          'h2oEngines'
        );
      },
    },
    [AIEMOpType.resume]: {
      [Engine_Type.DRIVERLESS_AI]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_ResumeDAIEngine, { name })) as EngineResponse,
          'daiEngine'
        );
      },
      [Engine_Type.NOTEBOOK]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(NotebookEngineService_ResumeNotebookEngine, { name })) as EngineResponse,
          'notebookEngine'
        );
      },
    },
    [AIEMOpType.pause]: {
      [Engine_Type.DRIVERLESS_AI]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_PauseDAIEngine, { name })) as EngineResponse,
          'daiEngine'
        );
      },
      [Engine_Type.NOTEBOOK]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(NotebookEngineService_PauseNotebookEngine, { name })) as EngineResponse,
          'notebookEngine'
        );
      },
    },
    [AIEMOpType.terminate]: {
      [Engine_Type.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(H2OEngineService_TerminateH2OEngine, { name })) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.delete]: {
      [Engine_Type.DRIVERLESS_AI]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_DeleteDAIEngine, { name })) as EngineResponse,
          'daiEngine'
        );
      },
      [Engine_Type.NOTEBOOK]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(NotebookEngineService_DeleteNotebookEngine, { name })) as EngineResponse,
          'notebookEngine'
        );
      },
      [Engine_Type.H2O]: async ({ name }: AIEngine) => {
        if (!name) throw invalidOpError();
        return castEngineResponse(
          (await serviceCaller(H2OEngineService_DeleteH2OEngine, { name })) as EngineResponse,
          'h2oEngine'
        );
      },
    },
    [AIEMOpType.update]: {
      [Engine_Type.DRIVERLESS_AI]: async (engine: AIEngine) => {
        const req: EngineUpdateRequest = {
          daiEngine: engine as DAIEngine,
          updateMask: '*',
        };
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_UpdateDAIEngine, req)) as EngineResponse,
          'daiEngine'
        );
      },
      [Engine_Type.NOTEBOOK]: async (engine: AIEngine) => {
        const req: EngineUpdateRequest = {
          notebookEngine: engine as NotebookEngine,
          updateMask: '*',
        };
        return castEngineResponse(
          (await serviceCaller(NotebookEngineService_UpdateNotebookEngine, req)) as EngineResponse,
          'notebookEngine'
        );
      },
    },
    [AIEMOpType.upgrade]: {
      [Engine_Type.DRIVERLESS_AI]: async (engine: AIEngine) => {
        if (!engine || !engine.name) throw invalidOpError();
        if (!engine.engineNewVersion) engine.engineNewVersion = 'latest';
        const req: EngineUpgradeRequest = {
          daiEngine: engine.name,
          newDaiEngineVersion: engine.engineNewVersion,
        };
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_UpgradeDAIEngineVersion, req)) as EngineResponse,
          'daiEngine'
        );
      },
    },
    [AIEMOpType.resize]: {
      [Engine_Type.DRIVERLESS_AI]: async (engine: AIEngine) => {
        if (!engine || !engine.name || !engine.newSize) throw invalidOpError();
        const req: EngineResizeStorageRequest = {
          daiEngine: engine.name,
          newStorageBytes: toBigIntString(engine.newSize),
        };
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_ResizeStorage, req)) as EngineResponse,
          'daiEngine'
        );
      },
    },
    [AIEMOpType.migrateCreator]: {
      [Engine_Type.DRIVERLESS_AI]: async (engine: AIEngine) => {
        const req: EngineMigrateCreatorRequest = {
          daiEngine: engine.name!,
          newCreator: engine.newCreator!,
        };
        return castEngineResponse(
          (await serviceCaller(DAIEngineService_MigrateCreator, req)) as EngineResponse,
          'daiEngine'
        );
      },
    },
  };
  return endpoints[op][type];
};

export const constraintSetTypeMap = new Map<Engine_Type, ConstraintSetType>([
  [Engine_Type.DRIVERLESS_AI, ConstraintSetType.DAI_ENGINE_CONSTRAINT_SET],
  [Engine_Type.H2O, ConstraintSetType.H2O_ENGINE_CONSTRAINT_SET],
]);

export const engineTypeService: EngineTypeService = {
  [Engine_Type.DRIVERLESS_AI]: {
    text: 'DAI',
    listVersions: async (
      requestConfig: RequestConfig,
      request = { parent: 'workspaces/global' }
    ): Promise<DAIEngineVersion[]> => {
      const requestService = async () => {
        return await fetchWrapRPC(DAIEngineVersionService_ListDAIEngineVersions, requestConfig)(request);
      };
      return (await requestService())['daiEngineVersions'] || [];
    },
  },
  [Engine_Type.NOTEBOOK]: {
    text: 'Notebook',
    listVersions: async (
      requestConfig: RequestConfig,
      request = { parent: 'workspaces/global' }
    ): Promise<DAIEngineVersion[]> => {
      const requestService = async () => {
        return await fetchWrapRPC(NotebookEngineImageService_ListNotebookEngineImages, requestConfig)(request);
      };
      return (await requestService())['notebookEngineImages'] || [];
    },
  },
  [Engine_Type.H2O]: {
    text: 'H2O',
    listVersions: async (
      requestConfig: RequestConfig,
      request = { parent: 'workspaces/global' }
    ): Promise<H2OEngineVersion[]> => {
      const requestService = async () => {
        return await fetchWrapRPC(H2OEngineVersionService_ListH2OEngineVersions, requestConfig)(request);
      };
      return (await requestService())['h2oEngineVersions'] || [];
    },
  },
};

export const CalculateH2OEngineSizeCompressedDataset = async (
  basePath = defaultBasePath,
  bearerToken = '',
  request = {} as CalculateH2OEngineSizeCompressedDatasetRequest
): Promise<H2OEngineSize> => {
  const response = await fetchWrapRPC(H2OEngineService_CalculateH2OEngineSizeCompressedDataset, {
    basePath,
    bearerToken,
  })(request);
  return response.h2oEngineSize || {};
};

export const CalculateH2OEngineSizeRawDataset = async (
  basePath = defaultBasePath,
  bearerToken = '',
  request = {} as CalculateH2OEngineSizeRawDatasetRequest
): Promise<H2OEngineSize> => {
  const response = await fetchWrapRPC(H2OEngineService_CalculateH2OEngineSizeRawDataset, { basePath, bearerToken })(
    request
  );
  return response.h2oEngineSize || {};
};

export const calculatedEngineSizes: H2OEngineSizeService = {
  [H2OEngineProfileOptionKeyType.raw]: {
    label: 'Raw',
    calculate: CalculateH2OEngineSizeRawDataset,
  },
  [H2OEngineProfileOptionKeyType.compressed]: {
    label: 'Compressed',
    calculate: CalculateH2OEngineSizeCompressedDataset,
  },
};
