import { AIEngine } from '../../../../../aiem/engine/types';
import { DAIEngineProfile } from '../../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import { H2OEngineProfile } from '../../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import EngineProfileTableRows from './EngineProfileTableRows';

interface DisplayPresetEngineProfileProps {
  engine: AIEngine;
  constraintSet?: H2OEngineProfile;
  profile?: DAIEngineProfile;
}

export default function DisplayPresetEngineProfile({ engine, constraintSet }: DisplayPresetEngineProfileProps) {
  return <EngineProfileTableRows loading={!constraintSet} engine={engine} />;
}
