import { Pivot, useTheme } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import {
  AiUnitHourlySummaryRequest,
  AiUnitHourlySummaryResponse,
} from '../../admin-center/gen/aiUnitHourly/aiUnitHourly_pb';
import { useAdminCenterService } from '../../admin-center/hooks';
import { AiUnitHourlyStyles, InfoLabel, InfoValue } from './components/AIUnitHourly/AIUnitHourly.styles';
import ComponentSummary from './components/AIUnitHourly/ComponentSummary/ComponentSummary';
import CumulativeSummary from './components/AIUnitHourly/CumulativeSummary/CumulativeSummary';
import PageWrapper from './PageWrapper';

export default function AIUnitHourly() {
  const service = useAdminCenterService();
  const theme = useTheme();
  const [stats, setStats] = useState<AiUnitHourlySummaryResponse | null>(null);

  const fetchAiUnitHourly = async () => {
    try {
      const request: AiUnitHourlySummaryRequest = {};

      const response = await service.getHourlyAiUnitSummary(request);
      setStats(response);
    } catch (error) {
      console.error('Error fetching login statistics:', error);
    }
  };

  useEffect(() => {
    fetchAiUnitHourly();
  }, []);

  return (
    <PageWrapper>
      <div style={AiUnitHourlyStyles(theme)}>
        <div>
          <p style={InfoLabel(theme)}>Environment start date:</p>
          <p style={InfoValue(theme)}>
            {stats?.envStartDate && stats?.envStartDate !== '0000-00-00' ? stats.envStartDate : 'N/A'}
          </p>
        </div>
        <div>
          <p style={InfoLabel(theme)}>Environment expiration date:</p>
          <p style={InfoValue(theme)}>
            {stats?.envEndDate && stats?.envEndDate !== '0000-00-00' ? stats.envEndDate : 'N/A'}
          </p>
        </div>
        <div>
          <p style={InfoLabel(theme)}>Purchased AI Units:</p>
          <p style={InfoValue(theme)}>{stats?.purchasedAIUnits || 'N/A'}</p>
        </div>
      </div>
      <div>
        <Pivot
          items={[
            {
              content: <CumulativeSummary />,
              headerText: 'Cumulative Summary',
            },
            {
              content: <ComponentSummary />,
              headerText: 'Component Summary',
            },
          ]}
        />
      </div>
    </PageWrapper>
  );
}
