import { concatStyleSets } from '@fluentui/react';
import {
  Button,
  type IButtonProps,
  IconName,
  buttonStylesSplit,
  buttonStylesSplitSecondary,
  buttonStylesSplitSmall as uiKitButtonStylesSplitSmall,
  useTheme,
} from '@h2oai/ui-kit';

import { restrictionTooltipMessage } from '../../../authz/providers/PermissionsProvider/PermissionsProvider';
import { Entity } from '../Entity/types';

export interface EntityModelItemActionButtonProps<EntityModel, EntityType extends string> {
  model: EntityModel;
  entity: Entity<EntityModel, EntityType>;
  onEdit?: (model: EntityModel) => void;
  onView?: (model: EntityModel) => void;
  onDelete?: (model: EntityModel) => void;
  onSetLatest?: (model: EntityModel) => void;
  disableEdit: boolean;
  disableDelete: boolean;
}

const buttonStylesSplitSmall = concatStyleSets(uiKitButtonStylesSplitSmall, { root: { width: 60 } });

export const EntityModelItemActionButton = <EntityModel, EntityType extends string>(
  props: EntityModelItemActionButtonProps<EntityModel, EntityType>
) => {
  const { entity, model, onEdit, onView, onDelete, onSetLatest, disableEdit, disableDelete } = props;
  const { palette, semanticColors } = useTheme();
  const buttonProps: IButtonProps = { split: true, menuIconName: IconName.ChevronDown };
  const aliasesFieldName = 'aliases';
  const aliasesField = model[aliasesFieldName] as string[] | undefined;
  const canBeLatest = aliasesField && !aliasesField.includes('latest');
  const menuItems = [
    ...(canBeLatest
      ? [
          {
            key: 'setLatest',
            text: 'Set Latest',
            onClick: disableEdit ? () => null : () => onSetLatest!(model),
            disabled: disableEdit,
            style: { color: semanticColors?.textPrimary, maxHeight: 32 },
            iconProps: { iconName: 'ChevronUpSmall' },
          },
        ]
      : []),
    {
      key: 'delete',
      text: 'Delete',
      onClick: disableDelete ? () => null : () => onDelete!(model),
      iconProps: { iconName: 'Delete', style: { color: 'var(--h2o-red400)' } },
      style: { color: palette?.red500, maxHeight: 32, ...(disableDelete ? { opacity: 0.5 } : {}) },
      disabled: disableDelete,
    },
  ];
  const primaryAction = entity.actions.Update ? onEdit : onView;
  const primaryText = entity.actions.Update ? 'Edit' : 'View';

  return (
    <>
      <Button
        {...buttonProps}
        menuItems={menuItems}
        styles={[buttonStylesSplit, buttonStylesSplitSecondary, buttonStylesSplitSmall]}
        onClick={() => primaryAction!(model)}
        primaryDisabled={disableEdit}
        text={primaryText}
        tooltip={restrictionTooltipMessage(disableEdit)}
      />
    </>
  );
};
