// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file app_event/instanceEvent/instanceevent.proto (package instanceEvent, syntax proto3)
/* eslint-disable */

import { RPC } from "../../runtime";

/**
 * @generated from message instanceEvent.InstanceEventsRequest
 */
export type InstanceEventsRequest = {
/**
 * @generated from field: string start_date = 1;
 */
startDate?: string;
/**
 * @generated from field: string end_date = 2;
 */
endDate?: string;
}
;
/**
 * @generated from message instanceEvent.InstanceEventsResponse
 */
export type InstanceEventsResponse = {
/**
 * @generated from field: repeated instanceEvent.InstanceEvent events = 1;
 */
events?: InstanceEvent[];
}
;
/**
 * @generated from message instanceEvent.InstanceEvent
 */
export type InstanceEvent = {
/**
 * Timestamp
 *
 * @generated from field: string ts = 1;
 */
ts?: string;
/**
 * Stream
 *
 * @generated from field: string stream = 2;
 */
stream?: string;
/**
 * User name
 *
 * @generated from field: string user_name = 3;
 */
userName?: string;
/**
 * Label
 *
 * @generated from field: string label = 4;
 */
label?: string;
}
;
/**
 * @generated from rpc instanceEvent.InstanceEventService.GetInstanceEvents
 */
export const InstanceEventService_GetInstanceEvents = new RPC<InstanceEventsRequest,InstanceEventsResponse>("GET", "/getInstanceEvents");
