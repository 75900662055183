import { Deployment } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_pb';
import {
  DeleteDeploymentRequest,
  ListProjectDeploymentsRequest,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_service_pb';
import {
  DeploymentState,
  DeploymentStatus,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_status_pb';
import { ListDeploymentStatusesRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_status_service_pb';
import { ConfigurableEndpoint } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/endpoint_pb';
import { ListEndpointsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/endpoint_service_pb';
import { FilterRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/listing_pb';
import { DeploymentService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_service_connect';
import { DeploymentStatusService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_status_service_connect';
import { EndpointService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/endpoint_service_connect';
import { DeploymentEnvironment } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_pb';
import { ListDeploymentEnvironmentsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_service_pb';
import { Operator } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/query_pb';
import { DeploymentEnvironmentService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/deployment_environment_service_connect';
import { createClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IDropdownOption, IStyle, MessageBarType } from '@fluentui/react';
import {
  ClassNamesFromIStyles,
  Dropdown,
  IH2OTheme,
  Info,
  Link,
  TextWithCopy,
  dropdownStylesInlineLeft,
  linkStylesBlack,
  textWithCopyStylesBorder,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';
import WidgetList from '../../components/WidgetList/WidgetList';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { formatError } from '../../utils/utils';
import { ContextMenuIconButton } from '../Orchestrator/Workflows';
import { Tag } from '../Orchestrator/WorkflowTabExecutions';
import { useConfirmDialog } from './ConfirmDialogProvider';
import { ENDPOINTS, ROUTES } from './constants';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

export type DeploymentItem = Deployment & {
  createdTimeLocal: string;
  createdByName: string;
  status: DeploymentStatus;
  onDeleteDeployment?: () => void;
  onEdit?: () => void;
  onQuickScore: () => void;
  contents?: JSX.Element;
  type: string;
  viewOnly?: boolean;
  actionsDisabled?: boolean;
};

interface IDeploymentsStyles {
  showAllDeployments: IStyle;
}

const deploymentsStyles = (theme: IH2OTheme): IDeploymentsStyles => {
  return {
    showAllDeployments: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
      borderWidth: 1,
      borderTopStyle: 'solid',
      borderColor: theme.semanticColors?.inputBorder,
      borderRadius: 4,
      // TODO: Use theme colors.
      backgroundImage: 'linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255, 1) 90%)',
      '&:hover': {
        borderColor: theme.semanticColors?.buttonPrimaryBackground,
        cursor: 'pointer',
        button: {
          textDecoration: 'none',
        },
        'button:active': {
          textDecoration: 'none',
        },
      },
      span: {
        color: theme.semanticColors?.textSecondary,
      },
      '&:hover span': {
        color: theme.semanticColors?.textPrimary,
      },
    },
  };
};

export const getFilter = (filter: string, propertyName: string) =>
    ({
      query: {
        clause: [
          {
            propertyConstraint: [
              {
                property: {
                  propertyType: {
                    value: propertyName,
                    case: 'field',
                  },
                },
                operator: Operator.EQUAL_TO,
                value: {
                  value: {
                    case: 'idValue',
                    value: filter,
                  },
                },
              },
            ],
          },
        ],
      },
    } as FilterRequest),
  getDeploymentTypeDisplayName = (deploymentCase?: string) => {
    switch (deploymentCase) {
      case 'singleDeployment':
        return 'Single model';
      case 'splitDeployment':
        return 'A/B Test';
      case 'shadowDeployment':
        return 'Champion/Challenger';
      default:
        return 'Single model';
    }
  },
  // TODO: Find out how to theme this.
  getStateProps = (state?: DeploymentState) => {
    switch (state) {
      case DeploymentState.LAUNCHING:
        return { name: 'Launching', color: 'var(--h2o-yellow500)' };
      case DeploymentState.FAILED:
        return { name: 'Failed', color: 'var(--h2o-red500)' };
      case DeploymentState.HEALTHY:
        return { name: 'Healthy', color: 'var(--h2o-green500)' };
      case DeploymentState.UNHEALTY:
        return { name: 'Unhealthy', color: 'var(--h2o-red500)' };
      case DeploymentState.TERMINATING:
        return { name: 'Terminating', color: 'var(--h2o-yellow500)' };
      case DeploymentState.PENDING:
        return { name: 'Pending', color: 'var(--h2o-yellow500)' };
      default:
        return { name: 'Unknown', color: 'var(--h2o-gray900)' };
    }
  };

const columns = [
    {
      key: 'title',
      name: 'Title',
      fieldName: 'description',
      minWidth: 200,
      data: {
        headerFieldName: 'displayName',
        listCellProps: {
          onRenderHeader: ({ displayName, onEdit }: DeploymentItem) =>
            RowHeaderTitle({ title: displayName, onClick: onEdit }),
          iconProps: {
            iconName: 'PublishContent',
          },
        },
      },
    },
    {
      key: 'type',
      name: 'Type',
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 120,
    },
    {
      key: 'user',
      name: 'Created by',
      fieldName: 'createdByName',
      minWidth: 180,
      maxWidth: 220,
    },
    {
      key: 'createdAt',
      name: 'Created at',
      fieldName: 'createdTimeLocal',
      minWidth: 180,
      maxWidth: 250,
    },
    {
      key: 'status',
      name: '',
      fieldName: 'status',
      minWidth: 170,
      maxWidth: 180,
      data: {
        listCellProps: {
          styles: {
            root: {
              justifyContent: 'center',
            },
          },
          onRenderText: ({ status }: DeploymentItem) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Tag title={getStateProps(status?.state).name} color={getStateProps(status?.state).color} />
              {status?.message && <Info isTooltip>{status?.message}</Info>}
            </div>
          ),
        },
      },
    },
    {
      key: 'buttons',
      name: '',
      minWidth: 130,
      data: {
        listCellProps: {
          emptyMessage: '',
          onRenderText: ({ onDeleteDeployment, onEdit, onQuickScore, viewOnly, status }: DeploymentItem) => (
            // TODO: Use theme prop for colors.
            <ContextMenuIconButton
              items={[
                {
                  key: viewOnly ? 'view' : 'edit',
                  text: viewOnly ? 'View' : 'Edit',
                  onClick: onEdit,
                  iconProps: { iconName: viewOnly ? 'RedEye' : 'Edit', style: { color: 'var(--h2o-gray900)' } },
                },
                {
                  key: 'quickScore',
                  text: 'Quick score',
                  onClick: onQuickScore,
                  style: {
                    color: 'var(--h2o-gray900)',
                    display: viewOnly || status.state !== DeploymentState.HEALTHY ? 'none' : undefined,
                  },
                  iconProps: { iconName: 'TableComputed', style: { color: 'var(--h2o-gray900)' } },
                },
                {
                  key: 'deleteDeployment',
                  text: 'Delete deployment',
                  onClick: onDeleteDeployment,
                  style: { color: 'var(--h2o-red400)', display: viewOnly ? 'none' : undefined },
                  iconProps: { iconName: 'Delete', style: { color: 'var(--h2o-red400)' } },
                },
              ]}
            />
          ),
          styles: {
            root: {
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'end',
              marginRight: 9,
            },
          },
        },
      },
    },
  ],
  widgetColumns = [
    {
      key: 'title',
      name: 'Title',
      // TODO: Fix styling.
      // fieldName: 'description',
      minWidth: 200,
      data: {
        headerFieldName: 'displayName',
        listCellProps: {
          onRenderHeader: ({ displayName, onEdit, status }: DeploymentItem) => (
            <>
              {RowHeaderTitle({ title: displayName, onClick: onEdit })}
              <div
                style={{
                  marginTop: 4,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Tag title={getStateProps(status?.state).name} color={getStateProps(status?.state).color} />
                {status?.message && <Info isTooltip>{status?.message}</Info>}
              </div>
            </>
          ),
        },
      },
    },
    {
      key: 'type',
      name: 'Type',
      fieldName: 'type',
      minWidth: 120,
      maxWidth: 120,
    },
    {
      key: 'buttons',
      name: '',
      minWidth: 130,
      data: {
        listCellProps: {
          emptyMessage: '',
          onRenderText: ({ onDeleteDeployment, onEdit, viewOnly }: DeploymentItem) => (
            // TODO: Use theme prop for colors.
            <ContextMenuIconButton
              items={[
                {
                  key: viewOnly ? 'view' : 'edit',
                  text: viewOnly ? 'View' : 'Edit',
                  onClick: onEdit,
                  iconProps: { iconName: viewOnly ? 'RedEye' : 'Edit', style: { color: 'var(--h2o-gray900)' } },
                },
                {
                  key: 'deleteDeployment',
                  text: 'Delete deployment',
                  onClick: onDeleteDeployment,
                  style: { color: 'var(--h2o-red400)', display: viewOnly ? 'none' : undefined },
                  iconProps: { iconName: 'Delete', style: { color: 'var(--h2o-red400)' } },
                },
              ]}
            />
          ),
          styles: {
            root: {
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'end',
              marginRight: 9,
            },
          },
        },
      },
    },
  ],
  EndpointOptionsCopy = ({
    endpointOptions,
    defaultSelectedKey,
  }: {
    endpointOptions: IDropdownOption[];
    defaultSelectedKey?: string;
  }) => {
    const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
      defaultSelectedKey || (endpointOptions[0]?.key as string)
    );

    return (
      <TextWithCopy
        hasBorder
        header={
          <Dropdown
            options={endpointOptions}
            styles={dropdownStylesInlineLeft}
            onChange={(_, option) => setSelectedKey(option?.key as string)}
            defaultSelectedKey={selectedKey}
          />
        }
        styles={[
          textWithCopyStylesBorder,
          {
            header: {
              padding: 0,
              minWidth: 200,
              border: 0,
              borderRadius: 'none',
            },
            root: {
              width: '100%',
            },
          },
        ]}
        text={selectedKey || 'No endpoint'}
      />
    );
  };

const Deployments = ({ isWidget = false }: { isWidget?: boolean }) => {
  const theme = useTheme(),
    classNames = useClassNames<IDeploymentsStyles, ClassNamesFromIStyles<IDeploymentsStyles>>(
      'deploymentsStyles',
      deploymentsStyles(theme)
    ),
    history = useHistory(),
    { addToast } = useToast(),
    { showDialog } = useConfirmDialog(),
    { ACTIVE_PROJECT_ID, permissions } = useProjects(),
    loadStateRef = React.useRef({
      fetchDeployments: false,
      fetchDeploymentStatuses: false,
      fetchDeploymentEnvironments: false,
      fetchEndpoints: false,
      deletingDeployment: false,
    }),
    [loading, setLoading] = React.useState(true),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [isSearchStr, setIsSearchStr] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [lastRefreshed, setLastRefreshed] = React.useState<Date>(),
    [deploymentItems, setDeploymentItems] = React.useState<DeploymentItem[]>(),
    [deploymentStatusItems, setDeploymentStatusItems] = React.useState<DeploymentStatus[]>(),
    [deploymentEnvironments, setDeploymentEnvironments] = React.useState<DeploymentEnvironment[]>(),
    [endpoints, setEndpoints] = React.useState<ConfigurableEndpoint[]>(),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    deploymentClient = createClient(DeploymentService, deploymentTransport),
    deploymentStatusClient = createClient(DeploymentStatusService, deploymentTransport),
    endpointClient = createClient(EndpointService, deploymentTransport),
    deploymentEnvironmentClient = createClient(DeploymentEnvironmentService, storageTransport),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchDeployments &&
        !loadStateRef.current.fetchDeploymentStatuses &&
        !loadStateRef.current.fetchDeploymentEnvironments &&
        !loadStateRef.current.fetchEndpoints &&
        !loadStateRef.current.deletingDeployment
      ) {
        setLoading(false);
        setLastRefreshed(new Date());
      }
    },
    onAction = () => history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/create-new`),
    getEndpoints = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID || !deploymentEnvironments?.[0].id) return;
      loadStateRef.current.fetchEndpoints = true;
      setLoading(true);
      try {
        const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${deploymentEnvironments?.[0].id}`,
          listEndpointsBody = new ListEndpointsRequest({
            parent: parentEnv,
          }),
          response = await endpointClient.listEndpoints(listEndpointsBody),
          endpoints: ConfigurableEndpoint[] | undefined = response?.endpoints;
        setEndpoints(endpoints);
        if (response && !endpoints) console.error('No endpoints found in the response.');
      } catch (err) {
        const message = `Failed to fetch endpoints: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setEndpoints(undefined);
      } finally {
        loadStateRef.current.fetchEndpoints = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, addToast, deploymentEnvironments]),
    // TODO: Move to global scope.
    getDeploymentEnvironments = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchDeploymentEnvironments = true;
      setLoading(true);
      try {
        const listDeploymentEnvironmentsRequest = new ListDeploymentEnvironmentsRequest({
            projectId: ACTIVE_PROJECT_ID,
          }),
          response = await deploymentEnvironmentClient.listDeploymentEnvironments(listDeploymentEnvironmentsRequest),
          deploymentEnvironments: DeploymentEnvironment[] | undefined = response?.deploymentEnvironment;
        setDeploymentEnvironments(deploymentEnvironments);
        if (response && !deploymentEnvironments) console.error('No deployment environments found in the response.');
      } catch (err) {
        const message = `Failed to fetch deployment environments: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setDeploymentEnvironments(undefined);
      } finally {
        loadStateRef.current.fetchDeploymentEnvironments = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, addToast]),
    deleteDeployment = React.useCallback(
      async (deploymentId: string) => {
        loadStateRef.current.deletingDeployment = true;
        try {
          const deleteDeploymentBody = new DeleteDeploymentRequest({
            id: deploymentId,
          });
          await deploymentClient.deleteDeployment(deleteDeploymentBody);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Deployment deleted successfully.',
          });
        } catch (err) {
          const message = `Failed to delete deployment: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.deletingDeployment = false;
          evaluateLoading();
          refreshItems();
        }
      },
      [addToast, ACTIVE_PROJECT_ID]
    ),
    fetchDeployments = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (!ACTIVE_PROJECT_ID || !deploymentEnvironments?.[0].id) return;
        loadStateRef.current.fetchDeployments = true;
        if (pageToken) setIsLoadingMore(true);
        else if (filter || filter === `""`) setIsLoadingSearch(true);
        else setLoading(true);
        try {
          const listDeploymentsBody = new ListProjectDeploymentsRequest({
            projectId: ACTIVE_PROJECT_ID,
            filter: filter ? getFilter(filter, 'display_name') : undefined,
            paging: {
              pageSize: 20,
              pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
            },
          });
          const response = await deploymentClient.listProjectDeployments(listDeploymentsBody);
          const deploymentItems: Deployment[] | undefined = response?.deployment;
          if (response && !deploymentItems) console.error('No deployments found in the response.');
          setNextPageToken(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );
          // The hardcoded fallback value is for local mocks.
          const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${deploymentEnvironments?.[0].id}`;
          const newItems: DeploymentItem[] | undefined = deploymentItems?.map((item) => {
            const deploymentStatusItem =
                deploymentStatusItems?.find((status) => status.deploymentId === item.id) || ({} as DeploymentStatus),
              defaultKey = deploymentStatusItem?.scorer?.score?.url || '',
              [urlPrefix, urlSuffix] = defaultKey.includes(item.id) ? defaultKey.split(item.id) : ['', ''],
              endpointOptions: IDropdownOption[] = [];
            if (defaultKey) {
              endpointOptions.push({
                key: defaultKey,
                text: 'Scorer (default)',
              });
            }
            if (endpoints?.length) {
              endpointOptions.push(
                ...endpoints
                  .filter((endpoint) => endpoint.target.includes(`${parentEnv}/deployments/${item.id}`))
                  .map((endpoint) => ({
                    key: `${urlPrefix}${endpoint.path}${urlSuffix}`,
                    text: endpoint.displayName,
                  }))
              );
            }
            return {
              ...item,
              createdTimeLocal:
                item.createdTime?.seconds !== undefined
                  ? new Date(Number(item.createdTime.seconds) * 1000).toLocaleString()
                  : '',
              createdByName: item.userInfo?.ownerName || item.userInfo?.ownerId || '',
              status: deploymentStatusItem,
              type: getDeploymentTypeDisplayName(item.deployment?.case),
              onDeleteDeployment: () => {
                showDialog?.({
                  title: 'Delete deployment?',
                  onConfirm: () => {
                    void deleteDeployment(item.id);
                  },
                });
              },
              onEdit: () => history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/${item.id}`),
              onQuickScore: () =>
                void history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/${item.id}/scoring`),
              viewOnly: !permissions?.canWrite,
              contents: (
                <div
                  style={{
                    marginLeft: isWidget ? 0 : 48,
                    marginBottom: 16,
                  }}
                >
                  <h4 style={{ margin: 0, marginBottom: 6 }}>Endpoints</h4>
                  {endpointOptions.length ? (
                    <EndpointOptionsCopy endpointOptions={endpointOptions} defaultSelectedKey={defaultKey} />
                  ) : (
                    <div style={{ minHeight: 32, display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}>
                      No endpoints available
                    </div>
                  )}
                </div>
              ),
            } as DeploymentItem;
          });
          setDeploymentItems((items) => (pageToken ? [...(items || []), ...(newItems || [])] : newItems));
        } catch (err) {
          const message = `Failed to fetch deployments: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setDeploymentItems(undefined);
        } finally {
          loadStateRef.current.fetchDeployments = false;
          evaluateLoading();
          setIsLoadingMore(false);
          setIsLoadingSearch(false);
        }
      },
      [
        addToast,
        ACTIVE_PROJECT_ID,
        deploymentEnvironments,
        endpoints,
        deploymentStatusItems,
        deleteDeployment,
        permissions,
        isWidget,
      ]
    ),
    fetchDeploymentStatuses = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchDeploymentStatuses = true;
      try {
        const listDeploymentStatusesBody = new ListDeploymentStatusesRequest({
          projectId: ACTIVE_PROJECT_ID,
        });
        const response = await deploymentStatusClient.listDeploymentStatuses(listDeploymentStatusesBody);
        const deploymentStatusItems: DeploymentStatus[] | undefined = response?.deploymentStatus;
        if (response && !deploymentStatusItems) console.error('No deployment statuses found in the response.');
        setDeploymentStatusItems(deploymentStatusItems);
      } catch (err) {
        const message = `Failed to fetch deployment statuses: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setDeploymentStatusItems(undefined);
      } finally {
        loadStateRef.current.fetchDeploymentStatuses = false;
        evaluateLoading();
      }
    }, [addToast, ACTIVE_PROJECT_ID]),
    refreshItems = () => {
      void getDeploymentEnvironments();
      void fetchDeploymentStatuses();
      void getEndpoints();
      void fetchDeployments();
    },
    headerActionProps = React.useMemo(
      () =>
        permissions?.canWrite && !isWidget
          ? {
              actionTitle: 'Create deployment',
              onActionClick: onAction,
              actionIcon: 'Add',
            }
          : undefined,
      [permissions, onAction, isWidget]
    ),
    widgetListProps = {
      columns: isWidget ? widgetColumns : columns,
      items: isWidget
        ? deploymentItems?.filter((item) => item?.status?.state === DeploymentState.HEALTHY)
        : deploymentItems,
      loading: !!loading,
      delayLoader: false,
      isLoadingMore: isLoadingMore,
      onLoadMore: nextPageToken && !isWidget ? () => void fetchDeployments(nextPageToken) : undefined,
      isLoadingSearch,
      lastRefreshed: isWidget ? undefined : lastRefreshed?.toLocaleString(),
      onRefresh: refreshItems,
      searchProps: !isWidget
        ? {
            placeholder: 'Search deployments',
            // TODO: Check if "value" is correct.
            onSearchChange: (value: string) => {
              setIsSearchStr(!!value);
              void fetchDeployments(undefined, value);
            },
          }
        : undefined,
      NoItemsContent: NoItemView({
        title: isWidget ? 'No healthy deployments' : 'No deployments',
        description: isWidget
          ? 'There are no healthy deployments to display.'
          : 'There are no deployments available in this project. Deploy your models now.',
        ...headerActionProps,
      }),
      ErrorContent: FailedToLoadView({
        title: 'Failed to load deployments',
        description: 'Please try again later. If the problem persists, contact our support.',
        actionTitle: 'Retry',
        onActionClick: fetchDeployments,
        actionIcon: 'Refresh',
      }),
      compact: isWidget,
    },
    onShowAllDeployments = () => history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}`);

  React.useEffect(() => void fetchDeploymentStatuses(), [fetchDeploymentStatuses]);

  React.useEffect(() => void getDeploymentEnvironments(), [getDeploymentEnvironments]);

  React.useEffect(() => {
    if (deploymentEnvironments) void getEndpoints();
  }, [getEndpoints, deploymentEnvironments]);

  React.useEffect(() => {
    if (endpoints && deploymentStatusItems) fetchDeployments();
  }, [endpoints, deploymentStatusItems, fetchDeployments]);

  React.useEffect(() => {
    if (deploymentItems && deploymentStatusItems) {
      const newItems: DeploymentItem[] = deploymentItems.map(
        (item) =>
          ({
            ...item,
            status: deploymentStatusItems.find((status) => status.deploymentId === item.id) || ({} as DeploymentStatus),
          } as DeploymentItem)
      );
      setDeploymentItems(newItems);
    }
  }, [deploymentStatusItems]);

  return isWidget ? (
    <>
      <h3>Active deployments</h3>
      <WidgetList {...widgetListProps} />
      <div className={classNames.showAllDeployments} onClick={onShowAllDeployments}>
        <Link styles={linkStylesBlack}>Show all deployments</Link>
      </div>
    </>
  ) : (
    <PageWrapper>
      {deploymentItems?.length || isSearchStr || loading ? <Header {...headerActionProps} /> : null}
      <WidgetList {...widgetListProps} />
    </PageWrapper>
  );
};

export default Deployments;
