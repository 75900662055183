/* tslint:disable */

// This file has been generated by https://github.com/h2oai/protoc-gen-twirp_ts.
// Do not edit.
import {
  createTwirpRequest,
  Fetch,
  throwTwirpError
} from './twirp'

export enum AppPreconditionStatus {
  STATUS_UNSPECIFIED = 'STATUS_UNSPECIFIED',
  FAILED = 'FAILED',
  PASSED = 'PASSED',
  WARNING = 'WARNING'
}
export enum AppAttribute {
  ATTRIBUTE_UNSPECIFIED = 'ATTRIBUTE_UNSPECIFIED',
  SUPPORTED = 'SUPPORTED',
  DEPRECATED = 'DEPRECATED',
  MVP = 'MVP',
  PREVIEW = 'PREVIEW'
}
export enum AppInstanceLifecycle {
  APP_INSTANCE_LIFECYCLE_UNSPECIFIED = 'APP_INSTANCE_LIFECYCLE_UNSPECIFIED',
  APP_INSTANCE_LIFECYCLE_ON_DEMAND = 'APP_INSTANCE_LIFECYCLE_ON_DEMAND',
  APP_INSTANCE_LIFECYCLE_MANAGED = 'APP_INSTANCE_LIFECYCLE_MANAGED',
  APP_INSTANCE_LIFECYCLE_EXTERNAL = 'APP_INSTANCE_LIFECYCLE_EXTERNAL',
  APP_INSTANCE_LIFECYCLE_LINK = 'APP_INSTANCE_LIFECYCLE_LINK'
}
export enum AppRoutingMode {
  APP_ROUTING_MODE_UNSPECIFIED = 'APP_ROUTING_MODE_UNSPECIFIED',
  APP_ROUTING_MODE_DOMAIN = 'APP_ROUTING_MODE_DOMAIN',
  APP_ROUTING_MODE_BASE_URL = 'APP_ROUTING_MODE_BASE_URL'
}
export enum MetadataType {
  METADATA_TYPE_UNSPECIFIED = 'METADATA_TYPE_UNSPECIFIED',
  REQUIREMENTS = 'REQUIREMENTS',
  PACKAGES = 'PACKAGES',
  FILES = 'FILES',
  APP = 'APP'
}


export interface HomePageAppWidgetConfig {
  title: string
  apps: string[]
  
}

export interface HomePageConfig {
  enabled: boolean
  widgetTopLeft: HomePageAppWidgetConfig
  widgetTopRight: HomePageAppWidgetConfig
  widgetBottomLeft: HomePageAppWidgetConfig
  
}

export interface GetEnvironmentDataRequest {
}

export interface GetEnvironmentDataResponse {
  defaultEmailAddress: string
  navLinks: NavLink[]
  tagManagerId: string
  bookTimeLink: string
  environmentName: string
  federationEnabled: boolean
  osanoEnabled: boolean
  homePage: HomePageConfig
  appDownloadEnabled: boolean
  platformUsageEnabled: boolean
  publicModeEnabled: boolean
  cloudInstanceName: string
  appStoreName: string
  beamerEnabled: boolean
  userInactivityTimeoutSeconds: number
  newMlopsUiEnabled: boolean
  appUploadEnabled: boolean
  cliDownloadEnabled: boolean
  logoUrl: string
  workspacesEnabled: boolean
  adminCenterEnabled: boolean
  
}

export interface NavLink {
  label: string
  url: string
  
}

export interface RuntimeVersion {
  name: string
  image: string
  deprecated: boolean
  default: boolean
  packageManagerCommand: string
  aliases: string[]
  
}

export interface ListRuntimeVersionsRequest {
}

export interface ListRuntimeVersionsResponse {
  runtimeVersions: RuntimeVersion[]
  
}

export interface LaunchProfiles {
  name: string
  description: string
  memoryLimit: number
  memoryReservation: number
  cpuLimit: number
  cpuReservation: number
  gpuCount: number
  
}

export interface ListLaunchProfilesRequest {
}

export interface ListLaunchProfilesResponse {
  launchProfiles: LaunchProfiles[]
  
}

export interface Notice {
  severity: string
  title: string
  content: string
  
}

export interface ListNoticesRequest {
}

export interface ListNoticesResponse {
  notices: Notice[]
  
}

export interface GetApiAccessConfigRequest {
}

export interface GetApiAccessConfigResponse {
  serverAddress: string
  oidcProvider: string
  cliClientId: string
  platformClientId: string
  platformTokenUrl: string
  
}

export interface ListAllowedGPUTypesRequest {
}

export interface ListAllowedGPUTypesResponse {
  allowedGpuTypes: string[]
  
}

export interface Alias {
  id: string
  createTime: string
  updateTime: string
  instanceId: string
  alias: string
  primary: boolean
  parent: string
  
}

export interface CreateAliasRequest {
  alias: Alias
  
}

export interface CreateAliasResponse {
  alias: Alias
  
}

export interface GetAliasRequest {
  id: string
  aliasName: string
  
}

export interface GetAliasResponse {
  alias: Alias
  
}

export interface ListAliasesRequest {
  instanceId: string
  parent: string
  
}

export interface ListAliasesResponse {
  aliases: Alias[]
  
}

export interface DeleteAliasRequest {
  id: string
  aliasName: string
  
}

export interface DeleteAliasResponse {
}

export interface AssignAliasRequest {
  id: string
  aliasName: string
  instanceId: string
  parent: string
  
}

export interface AssignAliasResponse {
  alias: Alias
  
}

export interface PromoteAliasToPrimaryRequest {
  id: string
  aliasName: string
  
}

export interface PromoteAliasToPrimaryResponse {
  alias: Alias
  
}

export interface Tag {
  id: string
  createTime: string
  updateTime: string
  name: string
  title: string
  description: string
  color: string
  adminRoles: string[]
  visitorRoles: string[]
  isCategory: boolean
  
}

export interface CreateTagRequest {
  tag: Tag
  
}

export interface CreateTagResponse {
  tag: Tag
  
}

export interface GetTagRequest {
  id: string
  
}

export interface GetTagResponse {
  tag: Tag
  
}

export interface ListTagsRequest {
}

export interface ListTagsResponse {
  tags: Tag[]
  
}

export interface UpdateTagRequest {
  tag: Tag
  
}

export interface UpdateTagResponse {
  tag: Tag
  
}

export interface DeleteTagRequest {
  id: string
  
}

export interface DeleteTagResponse {
}

export interface AssignTagRequest {
  appId: string
  id: string
  
}

export interface AssignTagResponse {
}

export interface UnassignTagRequest {
  appId: string
  id: string
  
}

export interface UnassignTagResponse {
}

export interface ListCategoriesRequest {
}

export interface ListCategoriesResponse {
  categories: Tag[]
  
}

export interface TagAssignment {
  id: string
  assignTime: string
  name: string
  title: string
  description: string
  color: string
  hidden: boolean
  isCategory: boolean
  
}

export interface User {
  name: string
  id: string
  isAdmin: boolean
  hasFullAccess: boolean
  visitorModeEnabled: boolean
  picture: string
  isPublic: boolean
  isSuperAdmin: boolean
  
}

export interface CheckAuthRequest {
}

export interface CheckAuthResponse {
  user: User
  
}

export interface GetUserAuthStatusRequest {
  id: string
  
}

export interface GetUserAuthStatusResponse {
  sessions: User[]
  
}

export interface LogoutUserRequest {
  id: string
  
}

export interface LogoutUserResponse {
}

export interface IAMPolicy {
  id: string
  createTime: string
  updateTime: string
  author: string
  name: string
  description: string
  resources: string[]
  statements: IAMPolicyStatement[]
  
}

export interface IAMPolicyStatement {
  id: string
  subjects: string[]
  actions: string[]
  attributes: string[]
  effect: IAMPolicyStatement_Effect
  
}

export enum IAMPolicyStatement_Effect {
  EFFECT_UNSPECIFIED = 'EFFECT_UNSPECIFIED',
  DENY = 'DENY',
  ALLOW = 'ALLOW'
}

export interface ListIAMPoliciesRequest {
}

export interface ListIAMPoliciesResponse {
  policies: IAMPolicy[]
  
}

export interface GetIAMPolicyRequest {
  id: string
  
}

export interface GetIAMPolicyResponse {
  policy: IAMPolicy
  
}

export interface DeleteIAMPolicyRequest {
  id: string
  
}

export interface DeleteIAMPolicyResponse {
}

export interface CreateIAMPolicyRequest {
  policy: IAMPolicy
  
}

export interface CreateIAMPolicyResponse {
  policy: IAMPolicy
  
}

export interface AppPrecondition {
  displayName: string
  description: string
  status: AppPreconditionStatus
  
}

export interface RefreshAppPreconditionsRequest {
  id: string
  
}

export interface RefreshAppPreconditionsResponse {
  conditions: AppPrecondition[]
  conditionsSatisfied: boolean
  
}

export interface GetAppPreconditionsRequest {
  id: string
  
}

export interface GetAppPreconditionsResponse {
  conditions: AppPrecondition[]
  conditionsSatisfied: boolean
  
}

export interface App {
  id: string
  createTime: string
  updateTime: string
  owner: string
  name: string
  title: string
  description: string
  keywords: string[]
  version: string
  bundleLocation: string
  iconLocation: string
  screenshotLocations: string[]
  visibility: App_Visibility
  tags: TagAssignment[]
  longDescription: string
  runtimeVersion: string
  preference: AppPreference
  instanceLifecycle: AppInstanceLifecycle
  runnable: boolean
  routingMode: AppRoutingMode
  preview: boolean
  federated: boolean
  link: AppLink
  serviceAccount: string
  customImage: string
  attributes: AppAttribute[]
  conditionsStatus: AppPreconditionStatus
  profile: string
  
}

export enum App_Visibility {
  VISIBILITY_UNSPECIFIED = 'VISIBILITY_UNSPECIFIED',
  PRIVATE = 'PRIVATE',
  ALL_USERS = 'ALL_USERS',
  PUBLIC = 'PUBLIC'
}

export interface AppPreference {
  liked: boolean
  pinned: boolean
  likes: number
  popularity: number
  personalPopularity: number
  
}

export interface AppLink {
  location: string
  
}

export interface AppInstance {
  id: string
  createTime: string
  updateTime: string
  appId: string
  visibility: AppInstance_Visibility
  location: string
  status: AppInstance_Status
  owner: string
  appDetails: App
  readOnly: boolean
  suspendable: boolean
  suspendAfter: string
  profile: string
  parent: string
  
}

export enum AppInstance_Visibility {
  VISIBILITY_UNSPECIFIED = 'VISIBILITY_UNSPECIFIED',
  PRIVATE = 'PRIVATE',
  ALL_USERS = 'ALL_USERS',
  PUBLIC = 'PUBLIC'
}

export enum AppInstance_Status {
  STATUS_UNSPECIFIED = 'STATUS_UNSPECIFIED',
  STATUS_UNKNOWN = 'STATUS_UNKNOWN',
  PENDING = 'PENDING',
  DEPLOYED = 'DEPLOYED',
  FAILED = 'FAILED',
  SUSPENDED = 'SUSPENDED'
}

export interface ImportAppRequest {
  uploadId: string
  visibility: App_Visibility
  customImage: string
  force: boolean
  profile: string
  storagePath: string
  
}

export interface ImportAppResponse {
  app: App
  
}

export interface ValidateAppTomlRequest {
  appToml: string
  
}

export interface ValidateAppTomlResponse {
}

export interface GetAppRequest {
  id: string
  
}

export interface GetAppResponse {
  app: App
  
}

export interface ListAppsRequest {
  offset: number
  limit: number
  visibility: App_Visibility
  allUsers: boolean
  name: string
  latestVersions: boolean
  withPreference: boolean
  tags: string[]
  conditionsStatus: AppPreconditionStatus
  visibilities: App_Visibility[]
  
}

export interface ListAppsResponse {
  apps: App[]
  
}

export interface UpdateAppRequest {
  id: string
  visibility: App_Visibility
  
}

export interface UpdateAppResponse {
  app: App
  
}

export interface DeleteAppRequest {
  id: string
  
}

export interface DeleteAppResponse {
}

export interface AppPreferenceValue {
  value: boolean
  
}

export interface UpdateAppPreferenceRequest {
  id: string
  liked: AppPreferenceValue
  pinned: AppPreferenceValue
  
}

export interface UpdateAppPreferenceResponse {
  app: App
  
}

export interface RunAppRequest {
  id: string
  visibility: AppInstance_Visibility
  profile: string
  parent: string
  
}

export interface RunAppResponse {
  instance: AppInstance
  
}

export interface TerminateAppInstanceRequest {
  id: string
  parent: string
  
}

export interface TerminateAppInstanceResponse {
}

export interface GetAppInstanceRequest {
  id: string
  includeAppDetails: boolean
  parent: string
  
}

export interface GetAppInstanceResponse {
  instance: AppInstance
  
}

export interface ListAppInstancesRequest {
  appId: string
  includeAppDetails: boolean
  visibility: AppInstance_Visibility
  allUsers: boolean
  parent: string
  allWorkspaces: boolean
  
}

export interface ListAppInstancesResponse {
  instances: AppInstance[]
  
}

export interface UpdateAppInstanceRequest {
  id: string
  visibility: AppInstance_Visibility
  parent: string
  
}

export interface UpdateAppInstanceResponse {
  instance: AppInstance
  
}

export interface GetAppInstanceStatusRequest {
  id: string
  parent: string
  
}

export interface GetAppInstanceStatusResponse {
  status: string
  terminationReason: string
  terminationMessage: string
  terminationExitCode: number
  restartCount: number
  lastExitTime: string
  
}

export interface SetAppInstanceSuspensionRequest {
  id: string
  suspend: boolean
  parent: string
  
}

export interface SetAppInstanceAutoSuspendRequest {
  id: string
  disabled: boolean
  parent: string
  
}

export interface SetAppInstanceAutoSuspendResponse {
  instance: AppInstance
  
}

export interface SetAppInstanceSuspensionResponse {
  instance: AppInstance
  
}

export interface GetUserAppStatusRequest {
  id: string
  
}

export interface GetUserAppStatusResponse {
  apps: App[]
  instances: AppInstance[]
  
}

export interface Metadata {
  requirements: string
  packages: string
  bundleFiles: string[]
  appToml: string
  
}

export interface GetAppMetadataRequest {
  appId: string
  
}

export interface GetAppMetadataResponse {
  metadata: Metadata
  
}

export interface SetAppServiceAccountRequest {
  id: string
  serviceAccount: string
  
}

export interface SetAppServiceAccountResponse {
  app: App
  
}

export interface SetAppCustomImageRequest {
  id: string
  customImage: string
  
}

export interface SetAppCustomImageResponse {
  app: App
  
}

export interface AddAppAttributesRequest {
  id: string
  attributes: AppAttribute[]
  
}

export interface AddAppAttributesResponse {
  app: App
  
}

export interface RemoveAppAttributesRequest {
  id: string
  attributes: AppAttribute[]
  
}

export interface RemoveAppAttributesResponse {
  app: App
  
}

export interface RedeployAppInstanceRequest {
  id: string
  parent: string
  
}

export interface RedeployAppInstanceResponse {
  instance: AppInstance
  
}

export interface GetAppLocationRequest {
  id: string
  
}

export interface GetAppLocationResponse {
  location: string
  
}

export interface SetAppDefaultProfileRequest {
  id: string
  profile: string
  
}

export interface SetAppDefaultProfileResponse {
  app: App
  
}

export interface SetAppRuntimeVersionRequest {
  id: string
  runtimeVersion: string
  
}

export interface SetAppRuntimeVersionResponse {
  app: App
  
}

export interface RefreshFederatedAppsRequest {
}

export interface RefreshFederatedAppsResponse {
  apps: FederatedApp[]
  
}

export interface ListFederatedAppsRequest {
}

export interface ListFederatedAppsResponse {
  apps: FederatedApp[]
  
}

export interface GetFederatedAppRequest {
  id: string
  
}

export interface GetFederatedAppResponse {
  app: FederatedApp
  
}

export interface FederatedApp {
  id: string
  createTime: string
  updateTime: string
  name: string
  version: string
  title: string
  description: string
  longDescription: string
  owner: string
  iconLocation: string
  screenshotLocations: string[]
  setupGuide: string
  runtimeVersion: string
  preview: boolean
  runnable: boolean
  conditionsSatisfied: boolean
  tags: TagAssignment[]
  visibility: App_Visibility
  
}

export interface Secret {
  name: string
  visibility: Secret_Visibility
  data: Secret_DataEntry[]
  parent: string
  
}

export enum Secret_Visibility {
  VISIBILITY_UNSPECIFIED = 'VISIBILITY_UNSPECIFIED',
  PRIVATE = 'PRIVATE',
  ALL_USERS = 'ALL_USERS',
  APP = 'APP'
}



export interface Secret_DataEntry {
  key: string
  value: string
  
}

export interface CreateSecretRequest {
  name: string
  data: CreateSecretRequest_DataEntry[]
  visibility: Secret_Visibility
  parent: string
  allowMissingParent: boolean
  
}

export interface CreateSecretRequest_DataEntry {
  key: string
  value: string
  
}

export interface UpdateSecretRequest {
  name: string
  replace: boolean
  data: UpdateSecretRequest_DataEntry[]
  visibility: Secret_Visibility
  parent: string
  
}

export interface UpdateSecretRequest_DataEntry {
  key: string
  value: string
  
}

export interface UpdateSecretResponse {
  secret: Secret
  
}

export interface CreateSecretResponse {
  secret: Secret
  
}

export interface ListSecretsRequest {
  visibility: Secret_Visibility
  parent: string
  
}

export interface ListSecretsResponse {
  secrets: Secret[]
  
}

export interface DeleteSecretRequest {
  name: string
  visibility: Secret_Visibility
  parent: string
  
}

export interface DeleteSecretResponse {
}

export interface GetSecretRequest {
  name: string
  visibility: Secret_Visibility
  parent: string
  
}

export interface GetSecretResponse {
  secret: Secret
  
}



// Services
export interface EnvService {
  getEnvironmentData: (data: GetEnvironmentDataRequest, headers?: object) => Promise<GetEnvironmentDataResponse>
  listRuntimeVersions: (data: ListRuntimeVersionsRequest, headers?: object) => Promise<ListRuntimeVersionsResponse>
  listNotices: (data: ListNoticesRequest, headers?: object) => Promise<ListNoticesResponse>
  getApiAccessConfig: (data: GetApiAccessConfigRequest, headers?: object) => Promise<GetApiAccessConfigResponse>
  listAllowedGPUTypes: (data: ListAllowedGPUTypesRequest, headers?: object) => Promise<ListAllowedGPUTypesResponse>
  listLaunchProfiles: (data: ListLaunchProfilesRequest, headers?: object) => Promise<ListLaunchProfilesResponse>
}

export class EnvServiceImpl implements EnvService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.EnvService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public getEnvironmentData(params: GetEnvironmentDataRequest, headers: object = {}): Promise<GetEnvironmentDataResponse> {
    return this.fetch(
      this.url('GetEnvironmentData'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listRuntimeVersions(params: ListRuntimeVersionsRequest, headers: object = {}): Promise<ListRuntimeVersionsResponse> {
    return this.fetch(
      this.url('ListRuntimeVersions'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listNotices(params: ListNoticesRequest, headers: object = {}): Promise<ListNoticesResponse> {
    return this.fetch(
      this.url('ListNotices'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getApiAccessConfig(params: GetApiAccessConfigRequest, headers: object = {}): Promise<GetApiAccessConfigResponse> {
    return this.fetch(
      this.url('GetApiAccessConfig'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listAllowedGPUTypes(params: ListAllowedGPUTypesRequest, headers: object = {}): Promise<ListAllowedGPUTypesResponse> {
    return this.fetch(
      this.url('ListAllowedGPUTypes'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listLaunchProfiles(params: ListLaunchProfilesRequest, headers: object = {}): Promise<ListLaunchProfilesResponse> {
    return this.fetch(
      this.url('ListLaunchProfiles'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface AliasService {
  createAlias: (data: CreateAliasRequest, headers?: object) => Promise<CreateAliasResponse>
  getAlias: (data: GetAliasRequest, headers?: object) => Promise<GetAliasResponse>
  listAliases: (data: ListAliasesRequest, headers?: object) => Promise<ListAliasesResponse>
  deleteAlias: (data: DeleteAliasRequest, headers?: object) => Promise<DeleteAliasResponse>
  assignAlias: (data: AssignAliasRequest, headers?: object) => Promise<AssignAliasResponse>
  promoteAliasToPrimary: (data: PromoteAliasToPrimaryRequest, headers?: object) => Promise<PromoteAliasToPrimaryResponse>
}

export class AliasServiceImpl implements AliasService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.AliasService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public createAlias(params: CreateAliasRequest, headers: object = {}): Promise<CreateAliasResponse> {
    return this.fetch(
      this.url('CreateAlias'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAlias(params: GetAliasRequest, headers: object = {}): Promise<GetAliasResponse> {
    return this.fetch(
      this.url('GetAlias'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listAliases(params: ListAliasesRequest, headers: object = {}): Promise<ListAliasesResponse> {
    return this.fetch(
      this.url('ListAliases'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public deleteAlias(params: DeleteAliasRequest, headers: object = {}): Promise<DeleteAliasResponse> {
    return this.fetch(
      this.url('DeleteAlias'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public assignAlias(params: AssignAliasRequest, headers: object = {}): Promise<AssignAliasResponse> {
    return this.fetch(
      this.url('AssignAlias'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public promoteAliasToPrimary(params: PromoteAliasToPrimaryRequest, headers: object = {}): Promise<PromoteAliasToPrimaryResponse> {
    return this.fetch(
      this.url('PromoteAliasToPrimary'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface AdminAliasService {
  createAlias: (data: CreateAliasRequest, headers?: object) => Promise<CreateAliasResponse>
  getAlias: (data: GetAliasRequest, headers?: object) => Promise<GetAliasResponse>
  listAliases: (data: ListAliasesRequest, headers?: object) => Promise<ListAliasesResponse>
  deleteAlias: (data: DeleteAliasRequest, headers?: object) => Promise<DeleteAliasResponse>
  assignAlias: (data: AssignAliasRequest, headers?: object) => Promise<AssignAliasResponse>
  promoteAliasToPrimary: (data: PromoteAliasToPrimaryRequest, headers?: object) => Promise<PromoteAliasToPrimaryResponse>
}

export class AdminAliasServiceImpl implements AdminAliasService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.AdminAliasService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public createAlias(params: CreateAliasRequest, headers: object = {}): Promise<CreateAliasResponse> {
    return this.fetch(
      this.url('CreateAlias'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAlias(params: GetAliasRequest, headers: object = {}): Promise<GetAliasResponse> {
    return this.fetch(
      this.url('GetAlias'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listAliases(params: ListAliasesRequest, headers: object = {}): Promise<ListAliasesResponse> {
    return this.fetch(
      this.url('ListAliases'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public deleteAlias(params: DeleteAliasRequest, headers: object = {}): Promise<DeleteAliasResponse> {
    return this.fetch(
      this.url('DeleteAlias'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public assignAlias(params: AssignAliasRequest, headers: object = {}): Promise<AssignAliasResponse> {
    return this.fetch(
      this.url('AssignAlias'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public promoteAliasToPrimary(params: PromoteAliasToPrimaryRequest, headers: object = {}): Promise<PromoteAliasToPrimaryResponse> {
    return this.fetch(
      this.url('PromoteAliasToPrimary'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface TagService {
  getTag: (data: GetTagRequest, headers?: object) => Promise<GetTagResponse>
  listTags: (data: ListTagsRequest, headers?: object) => Promise<ListTagsResponse>
  updateTag: (data: UpdateTagRequest, headers?: object) => Promise<UpdateTagResponse>
  assignTag: (data: AssignTagRequest, headers?: object) => Promise<AssignTagResponse>
  unassignTag: (data: UnassignTagRequest, headers?: object) => Promise<UnassignTagResponse>
  listCategories: (data: ListCategoriesRequest, headers?: object) => Promise<ListCategoriesResponse>
}

export class TagServiceImpl implements TagService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.TagService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public getTag(params: GetTagRequest, headers: object = {}): Promise<GetTagResponse> {
    return this.fetch(
      this.url('GetTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listTags(params: ListTagsRequest, headers: object = {}): Promise<ListTagsResponse> {
    return this.fetch(
      this.url('ListTags'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public updateTag(params: UpdateTagRequest, headers: object = {}): Promise<UpdateTagResponse> {
    return this.fetch(
      this.url('UpdateTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public assignTag(params: AssignTagRequest, headers: object = {}): Promise<AssignTagResponse> {
    return this.fetch(
      this.url('AssignTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public unassignTag(params: UnassignTagRequest, headers: object = {}): Promise<UnassignTagResponse> {
    return this.fetch(
      this.url('UnassignTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listCategories(params: ListCategoriesRequest, headers: object = {}): Promise<ListCategoriesResponse> {
    return this.fetch(
      this.url('ListCategories'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface AdminTagService {
  createTag: (data: CreateTagRequest, headers?: object) => Promise<CreateTagResponse>
  getTag: (data: GetTagRequest, headers?: object) => Promise<GetTagResponse>
  listTags: (data: ListTagsRequest, headers?: object) => Promise<ListTagsResponse>
  updateTag: (data: UpdateTagRequest, headers?: object) => Promise<UpdateTagResponse>
  deleteTag: (data: DeleteTagRequest, headers?: object) => Promise<DeleteTagResponse>
  assignTag: (data: AssignTagRequest, headers?: object) => Promise<AssignTagResponse>
  unassignTag: (data: UnassignTagRequest, headers?: object) => Promise<UnassignTagResponse>
  listCategories: (data: ListCategoriesRequest, headers?: object) => Promise<ListCategoriesResponse>
}

export class AdminTagServiceImpl implements AdminTagService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.AdminTagService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public createTag(params: CreateTagRequest, headers: object = {}): Promise<CreateTagResponse> {
    return this.fetch(
      this.url('CreateTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getTag(params: GetTagRequest, headers: object = {}): Promise<GetTagResponse> {
    return this.fetch(
      this.url('GetTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listTags(params: ListTagsRequest, headers: object = {}): Promise<ListTagsResponse> {
    return this.fetch(
      this.url('ListTags'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public updateTag(params: UpdateTagRequest, headers: object = {}): Promise<UpdateTagResponse> {
    return this.fetch(
      this.url('UpdateTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public deleteTag(params: DeleteTagRequest, headers: object = {}): Promise<DeleteTagResponse> {
    return this.fetch(
      this.url('DeleteTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public assignTag(params: AssignTagRequest, headers: object = {}): Promise<AssignTagResponse> {
    return this.fetch(
      this.url('AssignTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public unassignTag(params: UnassignTagRequest, headers: object = {}): Promise<UnassignTagResponse> {
    return this.fetch(
      this.url('UnassignTag'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listCategories(params: ListCategoriesRequest, headers: object = {}): Promise<ListCategoriesResponse> {
    return this.fetch(
      this.url('ListCategories'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface AuthService {
  checkAuth: (data: CheckAuthRequest, headers?: object) => Promise<CheckAuthResponse>
}

export class AuthServiceImpl implements AuthService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.AuthService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public checkAuth(params: CheckAuthRequest, headers: object = {}): Promise<CheckAuthResponse> {
    return this.fetch(
      this.url('CheckAuth'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface AdminAuthService {
  getUserAuthStatus: (data: GetUserAuthStatusRequest, headers?: object) => Promise<GetUserAuthStatusResponse>
  logoutUser: (data: LogoutUserRequest, headers?: object) => Promise<LogoutUserResponse>
  listIAMPolicies: (data: ListIAMPoliciesRequest, headers?: object) => Promise<ListIAMPoliciesResponse>
  getIAMPolicy: (data: GetIAMPolicyRequest, headers?: object) => Promise<GetIAMPolicyResponse>
  deleteIAMPolicy: (data: DeleteIAMPolicyRequest, headers?: object) => Promise<DeleteIAMPolicyResponse>
  createIAMPolicy: (data: CreateIAMPolicyRequest, headers?: object) => Promise<CreateIAMPolicyResponse>
}

export class AdminAuthServiceImpl implements AdminAuthService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.AdminAuthService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public getUserAuthStatus(params: GetUserAuthStatusRequest, headers: object = {}): Promise<GetUserAuthStatusResponse> {
    return this.fetch(
      this.url('GetUserAuthStatus'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public logoutUser(params: LogoutUserRequest, headers: object = {}): Promise<LogoutUserResponse> {
    return this.fetch(
      this.url('LogoutUser'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listIAMPolicies(params: ListIAMPoliciesRequest, headers: object = {}): Promise<ListIAMPoliciesResponse> {
    return this.fetch(
      this.url('ListIAMPolicies'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getIAMPolicy(params: GetIAMPolicyRequest, headers: object = {}): Promise<GetIAMPolicyResponse> {
    return this.fetch(
      this.url('GetIAMPolicy'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public deleteIAMPolicy(params: DeleteIAMPolicyRequest, headers: object = {}): Promise<DeleteIAMPolicyResponse> {
    return this.fetch(
      this.url('DeleteIAMPolicy'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public createIAMPolicy(params: CreateIAMPolicyRequest, headers: object = {}): Promise<CreateIAMPolicyResponse> {
    return this.fetch(
      this.url('CreateIAMPolicy'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface PreconditionService {
  refreshAppPreconditions: (data: RefreshAppPreconditionsRequest, headers?: object) => Promise<RefreshAppPreconditionsResponse>
  getAppPreconditions: (data: GetAppPreconditionsRequest, headers?: object) => Promise<GetAppPreconditionsResponse>
}

export class PreconditionServiceImpl implements PreconditionService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.PreconditionService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public refreshAppPreconditions(params: RefreshAppPreconditionsRequest, headers: object = {}): Promise<RefreshAppPreconditionsResponse> {
    return this.fetch(
      this.url('RefreshAppPreconditions'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAppPreconditions(params: GetAppPreconditionsRequest, headers: object = {}): Promise<GetAppPreconditionsResponse> {
    return this.fetch(
      this.url('GetAppPreconditions'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface AdminPreconditionService {
  refreshAppPreconditions: (data: RefreshAppPreconditionsRequest, headers?: object) => Promise<RefreshAppPreconditionsResponse>
  getAppPreconditions: (data: GetAppPreconditionsRequest, headers?: object) => Promise<GetAppPreconditionsResponse>
}

export class AdminPreconditionServiceImpl implements AdminPreconditionService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.AdminPreconditionService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public refreshAppPreconditions(params: RefreshAppPreconditionsRequest, headers: object = {}): Promise<RefreshAppPreconditionsResponse> {
    return this.fetch(
      this.url('RefreshAppPreconditions'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAppPreconditions(params: GetAppPreconditionsRequest, headers: object = {}): Promise<GetAppPreconditionsResponse> {
    return this.fetch(
      this.url('GetAppPreconditions'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface AppService {
  importApp: (data: ImportAppRequest, headers?: object) => Promise<ImportAppResponse>
  validateAppToml: (data: ValidateAppTomlRequest, headers?: object) => Promise<ValidateAppTomlResponse>
  getApp: (data: GetAppRequest, headers?: object) => Promise<GetAppResponse>
  listApps: (data: ListAppsRequest, headers?: object) => Promise<ListAppsResponse>
  updateApp: (data: UpdateAppRequest, headers?: object) => Promise<UpdateAppResponse>
  deleteApp: (data: DeleteAppRequest, headers?: object) => Promise<DeleteAppResponse>
  updateAppPreference: (data: UpdateAppPreferenceRequest, headers?: object) => Promise<UpdateAppPreferenceResponse>
  runApp: (data: RunAppRequest, headers?: object) => Promise<RunAppResponse>
  terminateAppInstance: (data: TerminateAppInstanceRequest, headers?: object) => Promise<TerminateAppInstanceResponse>
  getAppInstance: (data: GetAppInstanceRequest, headers?: object) => Promise<GetAppInstanceResponse>
  listAppInstances: (data: ListAppInstancesRequest, headers?: object) => Promise<ListAppInstancesResponse>
  updateAppInstance: (data: UpdateAppInstanceRequest, headers?: object) => Promise<UpdateAppInstanceResponse>
  getAppInstanceStatus: (data: GetAppInstanceStatusRequest, headers?: object) => Promise<GetAppInstanceStatusResponse>
  setAppInstanceSuspension: (data: SetAppInstanceSuspensionRequest, headers?: object) => Promise<SetAppInstanceSuspensionResponse>
  setAppInstanceAutoSuspend: (data: SetAppInstanceAutoSuspendRequest, headers?: object) => Promise<SetAppInstanceAutoSuspendResponse>
  getAppMetadata: (data: GetAppMetadataRequest, headers?: object) => Promise<GetAppMetadataResponse>
  setAppServiceAccount: (data: SetAppServiceAccountRequest, headers?: object) => Promise<SetAppServiceAccountResponse>
  setAppCustomImage: (data: SetAppCustomImageRequest, headers?: object) => Promise<SetAppCustomImageResponse>
  addAppAttributes: (data: AddAppAttributesRequest, headers?: object) => Promise<AddAppAttributesResponse>
  removeAppAttributes: (data: RemoveAppAttributesRequest, headers?: object) => Promise<RemoveAppAttributesResponse>
  getAppLocation: (data: GetAppLocationRequest, headers?: object) => Promise<GetAppLocationResponse>
  setAppDefaultProfile: (data: SetAppDefaultProfileRequest, headers?: object) => Promise<SetAppDefaultProfileResponse>
  setAppRuntimeVersion: (data: SetAppRuntimeVersionRequest, headers?: object) => Promise<SetAppRuntimeVersionResponse>
}

export class AppServiceImpl implements AppService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.AppService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public importApp(params: ImportAppRequest, headers: object = {}): Promise<ImportAppResponse> {
    return this.fetch(
      this.url('ImportApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public validateAppToml(params: ValidateAppTomlRequest, headers: object = {}): Promise<ValidateAppTomlResponse> {
    return this.fetch(
      this.url('ValidateAppToml'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getApp(params: GetAppRequest, headers: object = {}): Promise<GetAppResponse> {
    return this.fetch(
      this.url('GetApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listApps(params: ListAppsRequest, headers: object = {}): Promise<ListAppsResponse> {
    return this.fetch(
      this.url('ListApps'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public updateApp(params: UpdateAppRequest, headers: object = {}): Promise<UpdateAppResponse> {
    return this.fetch(
      this.url('UpdateApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public deleteApp(params: DeleteAppRequest, headers: object = {}): Promise<DeleteAppResponse> {
    return this.fetch(
      this.url('DeleteApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public updateAppPreference(params: UpdateAppPreferenceRequest, headers: object = {}): Promise<UpdateAppPreferenceResponse> {
    return this.fetch(
      this.url('UpdateAppPreference'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public runApp(params: RunAppRequest, headers: object = {}): Promise<RunAppResponse> {
    return this.fetch(
      this.url('RunApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public terminateAppInstance(params: TerminateAppInstanceRequest, headers: object = {}): Promise<TerminateAppInstanceResponse> {
    return this.fetch(
      this.url('TerminateAppInstance'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAppInstance(params: GetAppInstanceRequest, headers: object = {}): Promise<GetAppInstanceResponse> {
    return this.fetch(
      this.url('GetAppInstance'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listAppInstances(params: ListAppInstancesRequest, headers: object = {}): Promise<ListAppInstancesResponse> {
    return this.fetch(
      this.url('ListAppInstances'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public updateAppInstance(params: UpdateAppInstanceRequest, headers: object = {}): Promise<UpdateAppInstanceResponse> {
    return this.fetch(
      this.url('UpdateAppInstance'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAppInstanceStatus(params: GetAppInstanceStatusRequest, headers: object = {}): Promise<GetAppInstanceStatusResponse> {
    return this.fetch(
      this.url('GetAppInstanceStatus'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppInstanceSuspension(params: SetAppInstanceSuspensionRequest, headers: object = {}): Promise<SetAppInstanceSuspensionResponse> {
    return this.fetch(
      this.url('SetAppInstanceSuspension'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppInstanceAutoSuspend(params: SetAppInstanceAutoSuspendRequest, headers: object = {}): Promise<SetAppInstanceAutoSuspendResponse> {
    return this.fetch(
      this.url('SetAppInstanceAutoSuspend'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAppMetadata(params: GetAppMetadataRequest, headers: object = {}): Promise<GetAppMetadataResponse> {
    return this.fetch(
      this.url('GetAppMetadata'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppServiceAccount(params: SetAppServiceAccountRequest, headers: object = {}): Promise<SetAppServiceAccountResponse> {
    return this.fetch(
      this.url('SetAppServiceAccount'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppCustomImage(params: SetAppCustomImageRequest, headers: object = {}): Promise<SetAppCustomImageResponse> {
    return this.fetch(
      this.url('SetAppCustomImage'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public addAppAttributes(params: AddAppAttributesRequest, headers: object = {}): Promise<AddAppAttributesResponse> {
    return this.fetch(
      this.url('AddAppAttributes'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public removeAppAttributes(params: RemoveAppAttributesRequest, headers: object = {}): Promise<RemoveAppAttributesResponse> {
    return this.fetch(
      this.url('RemoveAppAttributes'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAppLocation(params: GetAppLocationRequest, headers: object = {}): Promise<GetAppLocationResponse> {
    return this.fetch(
      this.url('GetAppLocation'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppDefaultProfile(params: SetAppDefaultProfileRequest, headers: object = {}): Promise<SetAppDefaultProfileResponse> {
    return this.fetch(
      this.url('SetAppDefaultProfile'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppRuntimeVersion(params: SetAppRuntimeVersionRequest, headers: object = {}): Promise<SetAppRuntimeVersionResponse> {
    return this.fetch(
      this.url('SetAppRuntimeVersion'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface AdminAppService {
  importApp: (data: ImportAppRequest, headers?: object) => Promise<ImportAppResponse>
  getApp: (data: GetAppRequest, headers?: object) => Promise<GetAppResponse>
  listApps: (data: ListAppsRequest, headers?: object) => Promise<ListAppsResponse>
  updateApp: (data: UpdateAppRequest, headers?: object) => Promise<UpdateAppResponse>
  deleteApp: (data: DeleteAppRequest, headers?: object) => Promise<DeleteAppResponse>
  runApp: (data: RunAppRequest, headers?: object) => Promise<RunAppResponse>
  terminateAppInstance: (data: TerminateAppInstanceRequest, headers?: object) => Promise<TerminateAppInstanceResponse>
  getAppInstance: (data: GetAppInstanceRequest, headers?: object) => Promise<GetAppInstanceResponse>
  listAppInstances: (data: ListAppInstancesRequest, headers?: object) => Promise<ListAppInstancesResponse>
  updateAppInstance: (data: UpdateAppInstanceRequest, headers?: object) => Promise<UpdateAppInstanceResponse>
  getAppInstanceStatus: (data: GetAppInstanceStatusRequest, headers?: object) => Promise<GetAppInstanceStatusResponse>
  setAppInstanceSuspension: (data: SetAppInstanceSuspensionRequest, headers?: object) => Promise<SetAppInstanceSuspensionResponse>
  setAppInstanceAutoSuspend: (data: SetAppInstanceAutoSuspendRequest, headers?: object) => Promise<SetAppInstanceAutoSuspendResponse>
  getUserAppStatus: (data: GetUserAppStatusRequest, headers?: object) => Promise<GetUserAppStatusResponse>
  getAppMetadata: (data: GetAppMetadataRequest, headers?: object) => Promise<GetAppMetadataResponse>
  setAppServiceAccount: (data: SetAppServiceAccountRequest, headers?: object) => Promise<SetAppServiceAccountResponse>
  setAppCustomImage: (data: SetAppCustomImageRequest, headers?: object) => Promise<SetAppCustomImageResponse>
  addAppAttributes: (data: AddAppAttributesRequest, headers?: object) => Promise<AddAppAttributesResponse>
  removeAppAttributes: (data: RemoveAppAttributesRequest, headers?: object) => Promise<RemoveAppAttributesResponse>
  redeployAppInstance: (data: RedeployAppInstanceRequest, headers?: object) => Promise<RedeployAppInstanceResponse>
  setAppDefaultProfile: (data: SetAppDefaultProfileRequest, headers?: object) => Promise<SetAppDefaultProfileResponse>
  setAppRuntimeVersion: (data: SetAppRuntimeVersionRequest, headers?: object) => Promise<SetAppRuntimeVersionResponse>
}

export class AdminAppServiceImpl implements AdminAppService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.AdminAppService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public importApp(params: ImportAppRequest, headers: object = {}): Promise<ImportAppResponse> {
    return this.fetch(
      this.url('ImportApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getApp(params: GetAppRequest, headers: object = {}): Promise<GetAppResponse> {
    return this.fetch(
      this.url('GetApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listApps(params: ListAppsRequest, headers: object = {}): Promise<ListAppsResponse> {
    return this.fetch(
      this.url('ListApps'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public updateApp(params: UpdateAppRequest, headers: object = {}): Promise<UpdateAppResponse> {
    return this.fetch(
      this.url('UpdateApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public deleteApp(params: DeleteAppRequest, headers: object = {}): Promise<DeleteAppResponse> {
    return this.fetch(
      this.url('DeleteApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public runApp(params: RunAppRequest, headers: object = {}): Promise<RunAppResponse> {
    return this.fetch(
      this.url('RunApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public terminateAppInstance(params: TerminateAppInstanceRequest, headers: object = {}): Promise<TerminateAppInstanceResponse> {
    return this.fetch(
      this.url('TerminateAppInstance'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAppInstance(params: GetAppInstanceRequest, headers: object = {}): Promise<GetAppInstanceResponse> {
    return this.fetch(
      this.url('GetAppInstance'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listAppInstances(params: ListAppInstancesRequest, headers: object = {}): Promise<ListAppInstancesResponse> {
    return this.fetch(
      this.url('ListAppInstances'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public updateAppInstance(params: UpdateAppInstanceRequest, headers: object = {}): Promise<UpdateAppInstanceResponse> {
    return this.fetch(
      this.url('UpdateAppInstance'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAppInstanceStatus(params: GetAppInstanceStatusRequest, headers: object = {}): Promise<GetAppInstanceStatusResponse> {
    return this.fetch(
      this.url('GetAppInstanceStatus'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppInstanceSuspension(params: SetAppInstanceSuspensionRequest, headers: object = {}): Promise<SetAppInstanceSuspensionResponse> {
    return this.fetch(
      this.url('SetAppInstanceSuspension'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppInstanceAutoSuspend(params: SetAppInstanceAutoSuspendRequest, headers: object = {}): Promise<SetAppInstanceAutoSuspendResponse> {
    return this.fetch(
      this.url('SetAppInstanceAutoSuspend'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getUserAppStatus(params: GetUserAppStatusRequest, headers: object = {}): Promise<GetUserAppStatusResponse> {
    return this.fetch(
      this.url('GetUserAppStatus'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getAppMetadata(params: GetAppMetadataRequest, headers: object = {}): Promise<GetAppMetadataResponse> {
    return this.fetch(
      this.url('GetAppMetadata'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppServiceAccount(params: SetAppServiceAccountRequest, headers: object = {}): Promise<SetAppServiceAccountResponse> {
    return this.fetch(
      this.url('SetAppServiceAccount'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppCustomImage(params: SetAppCustomImageRequest, headers: object = {}): Promise<SetAppCustomImageResponse> {
    return this.fetch(
      this.url('SetAppCustomImage'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public addAppAttributes(params: AddAppAttributesRequest, headers: object = {}): Promise<AddAppAttributesResponse> {
    return this.fetch(
      this.url('AddAppAttributes'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public removeAppAttributes(params: RemoveAppAttributesRequest, headers: object = {}): Promise<RemoveAppAttributesResponse> {
    return this.fetch(
      this.url('RemoveAppAttributes'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public redeployAppInstance(params: RedeployAppInstanceRequest, headers: object = {}): Promise<RedeployAppInstanceResponse> {
    return this.fetch(
      this.url('RedeployAppInstance'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppDefaultProfile(params: SetAppDefaultProfileRequest, headers: object = {}): Promise<SetAppDefaultProfileResponse> {
    return this.fetch(
      this.url('SetAppDefaultProfile'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public setAppRuntimeVersion(params: SetAppRuntimeVersionRequest, headers: object = {}): Promise<SetAppRuntimeVersionResponse> {
    return this.fetch(
      this.url('SetAppRuntimeVersion'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface AdminFederationService {
  refreshFederatedApps: (data: RefreshFederatedAppsRequest, headers?: object) => Promise<RefreshFederatedAppsResponse>
  listFederatedApps: (data: ListFederatedAppsRequest, headers?: object) => Promise<ListFederatedAppsResponse>
  getFederatedApp: (data: GetFederatedAppRequest, headers?: object) => Promise<GetFederatedAppResponse>
}

export class AdminFederationServiceImpl implements AdminFederationService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.AdminFederationService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public refreshFederatedApps(params: RefreshFederatedAppsRequest, headers: object = {}): Promise<RefreshFederatedAppsResponse> {
    return this.fetch(
      this.url('RefreshFederatedApps'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listFederatedApps(params: ListFederatedAppsRequest, headers: object = {}): Promise<ListFederatedAppsResponse> {
    return this.fetch(
      this.url('ListFederatedApps'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getFederatedApp(params: GetFederatedAppRequest, headers: object = {}): Promise<GetFederatedAppResponse> {
    return this.fetch(
      this.url('GetFederatedApp'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface SecretsService {
  createSecret: (data: CreateSecretRequest, headers?: object) => Promise<CreateSecretResponse>
  updateSecret: (data: UpdateSecretRequest, headers?: object) => Promise<UpdateSecretResponse>
  listSecrets: (data: ListSecretsRequest, headers?: object) => Promise<ListSecretsResponse>
  deleteSecret: (data: DeleteSecretRequest, headers?: object) => Promise<DeleteSecretResponse>
  getSecret: (data: GetSecretRequest, headers?: object) => Promise<GetSecretResponse>
}

export class SecretsServiceImpl implements SecretsService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.SecretsService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public createSecret(params: CreateSecretRequest, headers: object = {}): Promise<CreateSecretResponse> {
    return this.fetch(
      this.url('CreateSecret'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public updateSecret(params: UpdateSecretRequest, headers: object = {}): Promise<UpdateSecretResponse> {
    return this.fetch(
      this.url('UpdateSecret'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listSecrets(params: ListSecretsRequest, headers: object = {}): Promise<ListSecretsResponse> {
    return this.fetch(
      this.url('ListSecrets'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public deleteSecret(params: DeleteSecretRequest, headers: object = {}): Promise<DeleteSecretResponse> {
    return this.fetch(
      this.url('DeleteSecret'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getSecret(params: GetSecretRequest, headers: object = {}): Promise<GetSecretResponse> {
    return this.fetch(
      this.url('GetSecret'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}export interface AdminSecretsService {
  createSecret: (data: CreateSecretRequest, headers?: object) => Promise<CreateSecretResponse>
  updateSecret: (data: UpdateSecretRequest, headers?: object) => Promise<UpdateSecretResponse>
  listSecrets: (data: ListSecretsRequest, headers?: object) => Promise<ListSecretsResponse>
  deleteSecret: (data: DeleteSecretRequest, headers?: object) => Promise<DeleteSecretResponse>
  getSecret: (data: GetSecretRequest, headers?: object) => Promise<GetSecretResponse>
}

export class AdminSecretsServiceImpl implements AdminSecretsService {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/ai.h2o.cloud.appstore.v1.AdminSecretsService/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public createSecret(params: CreateSecretRequest, headers: object = {}): Promise<CreateSecretResponse> {
    return this.fetch(
      this.url('CreateSecret'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public updateSecret(params: UpdateSecretRequest, headers: object = {}): Promise<UpdateSecretResponse> {
    return this.fetch(
      this.url('UpdateSecret'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public listSecrets(params: ListSecretsRequest, headers: object = {}): Promise<ListSecretsResponse> {
    return this.fetch(
      this.url('ListSecrets'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public deleteSecret(params: DeleteSecretRequest, headers: object = {}): Promise<DeleteSecretResponse> {
    return this.fetch(
      this.url('DeleteSecret'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getSecret(params: GetSecretRequest, headers: object = {}): Promise<GetSecretResponse> {
    return this.fetch(
      this.url('GetSecret'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}