import { FontSizes, FontWeights, IH2OTheme, Sizes } from '@h2oai/ui-kit';

export const aiUnitHourlyTopStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '50px',
};

export const plotStyle = {
  width: '100%',
  height: '500px',
};

export const PageStyle = {
  height: `calc(100vh - 500px)`,
  overflowY: 'auto' as 'auto' | 'hidden' | 'scroll' | 'visible',
};

export const MonthDropdownStyle = {
  display: 'flex',
  marginRight: '20px',
  marginLeft: '50%',
  width: 'auto',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const AiUnitHourlyStyles = (theme: IH2OTheme) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: '20px',
  padding: Sizes.padding,
  marginBottom: '30px',
  backgroundColor: theme.palette?.white,
  borderRadius: Sizes.borderRadius,
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  fontFamily: "'Roboto', sans-serif",
  color: theme.palette?.gray300,
});

export const InfoLabel = (theme: IH2OTheme) => ({
  fontWeight: FontWeights.bold,
  color: theme.palette?.gray500,
});

export const InfoValue = (theme: IH2OTheme) => ({
  fontWeight: FontWeights.normal,
  color: theme.palette?.black,
  fontSize: FontSizes.small,
});
