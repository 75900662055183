import { PanelType, Stack, Text } from '@fluentui/react';
import { Button, KeyValuePairEditor, Panel, TextWithCopy } from '@h2oai/ui-kit';
import { useMemo } from 'react';

import { NotebookEngineImage } from '../../../aiem/gen/ai/h2o/engine/v1/notebook_engine_image_pb';
import { renderViewFieldElement } from '../../../components/AdminSettings/components/SidePannel/utils';
import {
  adminSettingsViewPanelLabel,
  adminSettingsViewPanelValue,
} from '../../../components/AdminSettings/components/SidePannel/viewPannel.styles';
import {
  booleanToReadable,
  bytesToGiBWithPostfix,
  getResourceId,
} from '../../../components/AdminSettings/Entity/utils';
import { getBestDateTimeUnitInfoPair, getIdFromName } from '../../../components/AdminSettings/utils';
import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { CodeArea } from '../../../components/CodeArea/CodeArea';
import { imagePullPolicyMap, kernelImageTypeMap } from '../../../notebook/constants';
import { NotebookKernelEntityType } from '../../../notebook/entity/types';
import type { KernelImage } from '../../../notebook/gen/ai/h2o/notebook/v1/kernel_image_pb';
import type { KernelTemplate } from '../../../notebook/gen/ai/h2o/notebook/v1/kernel_template_pb';
import type { NotebookKernelSpec } from '../../../notebook/gen/ai/h2o/notebook/v1/notebook_kernel_spec_pb';
import { formatDateWithTime } from '../../../utils/utils';
import { NotebookSettingsViewPanelProps } from './types';

const renderKernelImageEntityView = (kernelImage?: KernelImage) => {
  if (!kernelImage) {
    return <></>;
  }

  return (
    <>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Display name</Text>
        <Text {...adminSettingsViewPanelValue}>{kernelImage.displayName}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>ID</Text>
        <Text {...adminSettingsViewPanelValue}>{getIdFromName(kernelImage.name || '')}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Type</Text>
        <Text {...adminSettingsViewPanelValue}>
          {renderViewFieldElement(kernelImageTypeMap[kernelImage.type], kernelImageTypeMap[kernelImage.type])}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Image</Text>
        <Text {...adminSettingsViewPanelValue} title={kernelImage.image}>
          {renderViewFieldElement(kernelImage.image, <TextWithCopy text={kernelImage.image} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Disabled</Text>
        <Text {...adminSettingsViewPanelValue}>{booleanToReadable(kernelImage.disabled)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Image pull policy</Text>
        <Text {...adminSettingsViewPanelValue}>
          {renderViewFieldElement(kernelImage.imagePullPolicy, imagePullPolicyMap[kernelImage.imagePullPolicy || ''])}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Image pull secrets</Text>
        <Text {...adminSettingsViewPanelValue}>{kernelImage.imagePullSecrets?.join(', ') || '-'}</Text>
      </Stack>
    </>
  );
};
const renderKernelTemplateEntityView = (kernelTemplate?: KernelTemplate) => {
  if (!kernelTemplate) {
    return <></>;
  }
  const [numberProp, unitProp] = getBestDateTimeUnitInfoPair(kernelTemplate.maxIdleDuration);

  return (
    <>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Name</Text>
        <Text {...adminSettingsViewPanelValue}>{getResourceId(kernelTemplate.name)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>MilliCPU Request</Text>
        <Text {...adminSettingsViewPanelValue}>{renderViewFieldElement(kernelTemplate.milliCpuRequest)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>MilliCPU Limit</Text>
        <Text {...adminSettingsViewPanelValue}>{renderViewFieldElement(kernelTemplate.milliCpuLimit)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>GPU Resource</Text>
        <Text {...adminSettingsViewPanelValue}>{renderViewFieldElement(kernelTemplate.gpuResource)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>GPU</Text>
        <Text {...adminSettingsViewPanelValue}>{renderViewFieldElement(kernelTemplate.gpu)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Memory Request</Text>
        <Text {...adminSettingsViewPanelValue}>
          {renderViewFieldElement(
            kernelTemplate.memoryBytesRequest,
            bytesToGiBWithPostfix(kernelTemplate.memoryBytesRequest)
          )}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Memory Limit</Text>
        <Text {...adminSettingsViewPanelValue}>
          {renderViewFieldElement(
            kernelTemplate.memoryBytesLimit,
            bytesToGiBWithPostfix(kernelTemplate.memoryBytesLimit)
          )}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Storage</Text>
        <Text {...adminSettingsViewPanelValue}>
          {renderViewFieldElement(kernelTemplate.storageBytes, bytesToGiBWithPostfix(kernelTemplate.storageBytes))}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Storage Class Name</Text>
        <Text {...adminSettingsViewPanelValue}>{renderViewFieldElement(kernelTemplate.storageClassName)}</Text>
      </Stack>
      <Stack>
        <Text {...adminSettingsViewPanelLabel} style={{ marginBottom: 8 }}>
          Environmental Variables
        </Text>
        <KeyValuePairEditor config={kernelTemplate.environmentalVariables || {}} onUpdateConfig={() => null} readOnly />
      </Stack>
      <Stack>
        <Text {...adminSettingsViewPanelLabel} style={{ marginBottom: 8 }}>
          YAML Pod Template Spec
        </Text>
        <CodeArea cols={30} rows={15} readOnly defaultValue={kernelTemplate.yamlPodTemplateSpec} />
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Disabled</Text>
        <Text {...adminSettingsViewPanelValue}>{booleanToReadable(kernelTemplate.disabled)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Max Idle Duration</Text>
        <Text {...adminSettingsViewPanelValue}>
          {numberProp} {numberProp === 1 ? unitProp?.singular : unitProp?.plural}
        </Text>
      </Stack>
    </>
  );
};
const renderKernelSpecEntityView = (kernelSpec?: NotebookKernelSpec) => {
  if (!kernelSpec) {
    return <></>;
  }

  return (
    <>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Display name</Text>
        <Text {...adminSettingsViewPanelValue}>{kernelSpec.displayName}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>ID</Text>
        <Text {...adminSettingsViewPanelValue}>{getIdFromName(kernelSpec.name || '')}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Kernel Image</Text>
        <Text {...adminSettingsViewPanelValue}>{getResourceId(kernelSpec.kernelImage)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Kernel Template</Text>
        <Text {...adminSettingsViewPanelValue}>{getResourceId(kernelSpec.kernelTemplate)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Disabled</Text>
        <Text {...adminSettingsViewPanelValue}>{booleanToReadable(kernelSpec.disabled)}</Text>
      </Stack>
    </>
  );
};
const renderNotebookImageEntityView = (notebookImage?: NotebookEngineImage) => {
  if (!notebookImage) {
    return <></>;
  }

  return (
    <>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Display name</Text>
        <Text {...adminSettingsViewPanelValue}>{notebookImage.displayName}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>ID</Text>
        <Text {...adminSettingsViewPanelValue}>{getIdFromName(notebookImage.name || '')}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Image</Text>
        <Text {...adminSettingsViewPanelValue} title={notebookImage.image}>
          {renderViewFieldElement(notebookImage.image, <TextWithCopy text={notebookImage.image} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Enabled</Text>
        <Text {...adminSettingsViewPanelValue}>{booleanToReadable(notebookImage.enabled)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Image pull policy</Text>
        <Text {...adminSettingsViewPanelValue}>
          {renderViewFieldElement(
            notebookImage.imagePullPolicy,
            imagePullPolicyMap[notebookImage.imagePullPolicy || '']
          )}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Image pull secrets</Text>
        <Text {...adminSettingsViewPanelValue}>{notebookImage.imagePullSecrets?.join(', ') || '-'}</Text>
      </Stack>

      {notebookImage.createTime && (
        <Stack horizontal>
          <Text {...adminSettingsViewPanelLabel}>Create date</Text>
          <Text {...adminSettingsViewPanelValue}>{formatDateWithTime(new Date(notebookImage.createTime))}</Text>
        </Stack>
      )}
      {notebookImage.creatorDisplayName && (
        <Stack horizontal>
          <Text {...adminSettingsViewPanelLabel}>Created by</Text>
          <Text {...adminSettingsViewPanelValue}>{notebookImage.creatorDisplayName}</Text>
        </Stack>
      )}
      {notebookImage.updateTime && (
        <Stack horizontal>
          <Text {...adminSettingsViewPanelLabel}>Updated date</Text>
          <Text {...adminSettingsViewPanelValue}>{formatDateWithTime(new Date(notebookImage.updateTime))}</Text>
        </Stack>
      )}
      {notebookImage.updaterDisplayName && (
        <Stack horizontal>
          <Text {...adminSettingsViewPanelLabel}>Updated by</Text>
          <Text {...adminSettingsViewPanelValue}>{notebookImage.updaterDisplayName}</Text>
        </Stack>
      )}
    </>
  );
};

export const NotebookSettingsViewPanel = (props: NotebookSettingsViewPanelProps) => {
  const { item, type, onDismiss, panelTitle } = props;
  const content = useMemo(() => {
    switch (type) {
      case NotebookKernelEntityType.KernelImage:
        return renderKernelImageEntityView(item as unknown as KernelImage);
      case NotebookKernelEntityType.KernelTemplate:
        return renderKernelTemplateEntityView(item as unknown as KernelTemplate);
      case NotebookKernelEntityType.KernelSpec:
        return renderKernelSpecEntityView(item as unknown as NotebookKernelSpec);
      case NotebookKernelEntityType.NotebookImage:
        return renderNotebookImageEntityView(item as unknown as NotebookEngineImage);

      default:
        return null;
    }
  }, [type, item]);

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText={panelTitle}
      isFooterAtBottom
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
      onRenderFooterContent={() => {
        return (
          <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
            <Button text="Close" onClick={onDismiss} />
          </Stack>
        );
      }}
    >
      <Stack tokens={{ childrenGap: 20 }}>{content}</Stack>
    </Panel>
  );
};
