// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file aiem/aiem.proto (package aiem, syntax proto3)
/* eslint-disable */

import { RPC } from "../runtime";

/**
 * @generated from message aiem.AiemEventsRequest
 */
export type AiemEventsRequest = {
/**
 * @generated from field: string start_date = 1;
 */
startDate?: string;
/**
 * @generated from field: string end_date = 2;
 */
endDate?: string;
}
;
/**
 * @generated from message aiem.AiemEventsResponse
 */
export type AiemEventsResponse = {
/**
 * @generated from field: repeated aiem.AiemEvent events = 1;
 */
events?: AiemEvent[];
}
;
/**
 * @generated from message aiem.AiemEvent
 */
export type AiemEvent = {
/**
 * Timestamp
 *
 * @generated from field: string ts = 1;
 */
ts?: string;
/**
 * Stream
 *
 * @generated from field: string stream = 2;
 */
stream?: string;
/**
 * User name
 *
 * @generated from field: string user_name = 3;
 */
userName?: string;
/**
 * Engine Name
 *
 * @generated from field: string engineName = 4;
 */
engineName?: string;
/**
 * Engine Type
 *
 * @generated from field: string type = 5;
 */
type?: string;
/**
 * Version
 *
 * @generated from field: string version = 6;
 */
version?: string;
/**
 * CPU count
 *
 * @generated from field: int32 cpu = 7;
 */
cpu?: number;
/**
 * Memory count
 *
 * @generated from field: int32 memory = 8;
 */
memory?: number;
/**
 * GPU count
 *
 * @generated from field: int32 gpu = 9;
 */
gpu?: number;
/**
 * Storage value
 *
 * @generated from field: int32 storage = 10;
 */
storage?: number;
/**
 * AI unit count
 *
 * @generated from field: float ai_units = 11;
 */
aiUnits?: number;
}
;
/**
 * @generated from rpc aiem.AiemService.GetAiemEvents
 */
export const AiemService_GetAiemEvents = new RPC<AiemEventsRequest,AiemEventsResponse>("GET", "/getAiemEvents");
