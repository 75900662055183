import { useTheme } from '@h2oai/ui-kit';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { getAiUnitComponentColor } from '../../../common/utils';
import { plotStyle } from '../AIUnitHourly.styles';

interface AiUnitHourlyComponentEvent {
  date: string;
  month: string;
  year: string;
  resource: string;
  aiUnits: number;
}

interface ComponentPlotProps {
  events: AiUnitHourlyComponentEvent[];
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ComponentMonthPlot = ({ events }: ComponentPlotProps) => {
  const theme = useTheme();
  // Get all unique dates and resources
  const uniqueDates = Array.from(new Set(events.map((event) => event.date))).sort();
  const uniqueResources = Array.from(new Set(events.map((event) => event.resource)));

  // Map resources to datasets, ensuring all dates are covered
  const datasets = uniqueResources.map((resource) => {
    const resourceData = uniqueDates.map((date) => {
      // Find the event matching the resource and date
      const event = events.find((e) => e.resource === resource && e.date === date);
      return event ? event.aiUnits : 0;
    });

    return {
      label: resource,
      data: resourceData,
      borderColor: getAiUnitComponentColor(theme, resource, ''),
      backgroundColor: getAiUnitComponentColor(theme, resource, ''),
      borderWidth: 2,
      fill: false,
      tension: 0.4,
      pointRadius: 0,
    };
  });

  // Prepare the data object for Chart.js
  const data = {
    labels: uniqueDates,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: 'Entire Month',
        font: {
          size: 20,
        },
      },
      subtitle: {
        display: true,
        text: 'AI Unit Hour Usage',
        font: {
          size: 16,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'AI Units',
        },
      },
    },
  };

  return (
    <div style={plotStyle}>
      <Line data={data} options={options} />
    </div>
  );
};

export default ComponentMonthPlot;
