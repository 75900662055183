import { IStyle, mergeStyles } from '@fluentui/react';
import { IH2OTheme, Loader, mediaDesktop, mediaNoDesktop, useClassNames, useTheme } from '@h2oai/ui-kit';
import React from 'react';

import { NoItemView } from '../../components/NoItemView/NoItemView';
import { ClassNamesFromIStyles } from '../../utils/models';
import Deployments from './Deployments';
import JobsChart from './JobsChart';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

interface IDashboardStyles {
  dasboard: IStyle;
  title: IStyle;
  widget: IStyle;
  jobsWidget: IStyle;
  widgetsContainer: IStyle;
  centerItems: IStyle;
  chartWrapper: IStyle;
  deploymentsWidget: IStyle;
}

const dashboardStyles = (theme: IH2OTheme): IDashboardStyles => {
  return {
    dasboard: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '10px 40px 20px 40px',
      margin: '0px 20px',
    },
    title: {
      marginTop: 0,
      // TODO: Remove once theme is used somewhere else.
      color: theme.semanticColors?.textPrimary,
    },
    widget: {
      backgroundColor: theme.semanticColors?.contentBackground,
      padding: 20,
      borderWidth: 1,
      borderRadius: 4,
      borderStyle: 'solid',
      borderColor: theme.semanticColors?.inputBorder,
    },
    widgetsContainer: {
      display: 'flex',
      flexGrow: 1,
      width: '100%',
      ...mediaNoDesktop({
        flexWrap: 'wrap',
      }),
    },
    jobsWidget: {
      ...mediaDesktop({
        width: 'calc(64% - 20px)',
      }),
      ...mediaNoDesktop({
        marginTop: 15,
        width: 'calc(100% - 20px)',
      }),
    },
    deploymentsWidget: {
      ...mediaDesktop({
        marginRight: 20,
        width: '36%',
        flexGrow: 1,
      }),
      ...mediaNoDesktop({
        width: 'calc(100% - 20px)',
      }),
      display: 'flex',
      flexDirection: 'column',
    },
    centerItems: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
    },
    chartWrapper: {
      marginBottom: 20,
    },
  };
};

const Dashboard = () => {
  const theme = useTheme(),
    classNames = useClassNames<IDashboardStyles, ClassNamesFromIStyles<IDashboardStyles>>(
      'dashboard',
      dashboardStyles(theme)
    ),
    { permissions } = useProjects(),
    // TODO: Handle loading.
    [loading] = React.useState(false);

  return (
    <PageWrapper>
      <div className={classNames.dasboard}>
        <h1 className={classNames.title}>Dashboard</h1>
        <div className={classNames.widgetsContainer}>
          {permissions.canRead ? (
            loading ? (
              <div className={classNames.centerItems}>
                <Loader label="Preparing the dasboard..." />
              </div>
            ) : (
              <>
                <div className={mergeStyles(classNames.widget, classNames.deploymentsWidget)}>
                  <Deployments isWidget />
                </div>
                <div className={mergeStyles(classNames.widget, classNames.jobsWidget)}>
                  <div className={classNames.chartWrapper}>
                    <JobsChart />
                  </div>
                  {/* TODO: Add batch scoring jobs list with pivot filter based on job state. */}
                  {/* <PivotFilter items={pivotItems} onFilterChange={onPivotFilterChange}>
                    <Jobs filterBy={pivotFilter} noItemsViewShadowed={false} />
                  </PivotFilter> */}
                </div>
              </>
            )
          ) : (
            NoItemView({
              title: 'Nothing to show here',
              description: 'For accessing the dashboard you need to have read permissions.',
            })
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default Dashboard;
