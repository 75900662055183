import { MessageBarType } from '@fluentui/react';
import { BasicTable, ConfirmDialog, MessageBar } from '@h2oai/ui-kit';
import { useState } from 'react';

import { ResendActivationEmailsRequest } from '../../../../../admin-center/gen/user_management/user_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { messageBarContainerStyles } from '../../../common/common.styles';
import { StatusLabel } from '../../../constants';
import { confirmationTableStyles, confirmationTextStyles } from '../UserManagement.styles';

interface IResendEmailConfirmationProps {
  users: { userName: string; userEmail: string; userStatus: StatusLabel }[];
  showDialog: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IResendEmailConfirmationListProps {
  users: { userName: string; userEmail: string; userStatus: StatusLabel }[];
  hasInvalidUsers: boolean;
}

export default function ResendEmailConfirmation({ users, showDialog, setShowDialog }: IResendEmailConfirmationProps) {
  const [isResendEmailPending, setIsResendEmailPending] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [showMessageBar, setShowMessagebar] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const service = useAdminCenterService();

  const actionType: Record<string, string> = {
    Active: 'Password Reset Link',
    Staged: 'Account Activation Link',
    'Pending User Action': 'Account Activation Link',
  };
  const filteredUsers = users
    .filter((user) => actionType[user.userStatus])
    .map((user) => ({
      ...user,
      actionType: actionType[user.userStatus],
    }));

  const handleResendEmail = async () => {
    setIsResendEmailPending(true);
    const emails = filteredUsers.map((user) => user.userEmail);

    try {
      const request: ResendActivationEmailsRequest = {
        userEmails: emails,
      };
      const response = await service.resendActivationEmail(request);

      if (response.isSuccess) {
        setResponseMessage('Emails were sent successfully!');
        setIsSuccess(true);
      } else {
        setResponseMessage('❌ Error: Failed to send emails');
        setIsSuccess(false);
      }
    } catch (error) {
      setResponseMessage(`❌ Network error: ${error}`);
    } finally {
      setIsResendEmailPending(false);
      setShowDialog(false);
      setShowMessagebar(true);
    }
  };

  return (
    <>
      {showMessageBar && (
        <div style={messageBarContainerStyles}>
          <MessageBar
            timeout={4000}
            onDismiss={() => setShowMessagebar(false)}
            messageBarType={isSuccess ? MessageBarType.success : MessageBarType.error}
          >
            {responseMessage}
          </MessageBar>
        </div>
      )}
      <ConfirmDialog
        hidden={!showDialog}
        title="Confirm Resend Email"
        confirmationButtonText="Okay"
        dismissalButtonText="Cancel"
        onConfirm={handleResendEmail}
        onDismiss={() => {
          setShowDialog(false);
        }}
        content={
          <ResendEmailConfirmationList users={filteredUsers} hasInvalidUsers={users.length !== filteredUsers.length} />
        }
        minWidth="500px"
        maxWidth="800px"
        confirmationButtonDisabled={isResendEmailPending || !filteredUsers.length}
      />
    </>
  );
}

function ResendEmailConfirmationList({ users, hasInvalidUsers }: IResendEmailConfirmationListProps) {
  return (
    <div>
      {users.length > 0 ? (
        <>
          <p style={confirmationTextStyles}>Are you sure you want to resend emails to the following users?</p>
          <div style={confirmationTableStyles}>
            <BasicTable
              columns={[
                {
                  key: 'userName',
                  fieldName: 'userName',
                  name: 'User',
                  minWidth: 250,
                },
                {
                  key: 'userEmail',
                  fieldName: 'userEmail',
                  name: 'Email',
                  minWidth: 250,
                },
                {
                  key: 'actionType',
                  fieldName: 'actionType',
                  name: 'Action',
                  minWidth: 250,
                },
              ]}
              items={users}
            />
          </div>
          {hasInvalidUsers && (
            <p style={confirmationTextStyles}>
              <b>Note:</b> Users with statuses other than <b>Active</b>, <b>Staged</b>, and <b>Pending User Action</b>{' '}
              statuses are ignored.
            </p>
          )}
        </>
      ) : (
        <>
          <p style={confirmationTextStyles}>
            Resend email feature is only available for users with <b>Active</b>, <b>Staged</b>, and{' '}
            <b>Pending User Action</b> statuses.
          </p>
        </>
      )}
    </div>
  );
}
