import { type ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';

import { useWorkspaces } from '../../../pages/Orchestrator/WorkspaceProvider';
import { useUser } from '../../../utils/hooks';
import {
  BatchResolvePermissionsResponse,
  ResolvePermissionRequest,
  ResolvePermissionResponse,
} from '../../gen/ai/h2o/authorization/v1/query_api_pb';
import { useAuthzService } from '../../hooks';
import { PermissionsContextType, PermissionsMap, WorkspacePermissionsMap } from './types';

export const restrictionTooltipMessage = (showTooltip: boolean) =>
  showTooltip ? 'You are not authorized to perform this action.' : '';

export const PermissionsContext = createContext<PermissionsContextType | undefined>(undefined);

export const permissionBindingsParam = `//workspaceserver/`;

const mapKeysToValues = (keys: string[], values?: ResolvePermissionResponse[]): PermissionsMap => {
  return keys.reduce((acc, key, index) => {
    acc[key] = values?.[index]?.allowed ?? false;
    return acc;
  }, {} as PermissionsMap);
};

const PermissionsProvider = ({ children }: { children: ReactNode }) => {
  const authzService = useAuthzService();
  const { ACTIVE_WORKSPACE_NAME } = useWorkspaces();
  const workspaceRef = useRef(ACTIVE_WORKSPACE_NAME);
  const { id } = useUser();
  const subject = `users/${id}`;

  async function waitForWorkspace(): Promise<string> {
    return new Promise((resolve) => {
      if (workspaceRef.current) {
        resolve(workspaceRef.current);
      } else {
        const interval = setInterval(() => {
          if (workspaceRef.current) {
            clearInterval(interval);
            resolve(workspaceRef.current);
          }
        }, 50);
      }
    });
  }

  const batchResolvePermission = async (
    params: ResolvePermissionRequest[]
  ): Promise<BatchResolvePermissionsResponse> => {
    const requests = params.map((param) => ({
      ...param,
      resource: `${permissionBindingsParam}${param.resource}`,
      subject,
    }));

    try {
      return await authzService.batchResolvePermission({ requests });
    } catch (error: any) {
      console.error(error);
      return error;
    }
  };

  const canDoActions = async (actions: string[]): Promise<PermissionsMap> => {
    const resource = await waitForWorkspace();

    const requestParams: ResolvePermissionRequest[] = actions.map((action) => ({ action, resource, subject }));
    const response = await batchResolvePermission(requestParams);

    return mapKeysToValues(actions, response.responses);
  };

  useEffect(() => {
    workspaceRef.current = ACTIVE_WORKSPACE_NAME;
  }, [ACTIVE_WORKSPACE_NAME]);

  const value: PermissionsContextType = { canDoActions, batchResolvePermission };

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

const usePermissions = (actions: string[]): boolean[] => {
  const { ACTIVE_WORKSPACE_NAME } = useWorkspaces();
  const prevActiveWorkspace = useRef(ACTIVE_WORKSPACE_NAME);
  const context = useContext(PermissionsContext);

  if (!context) throw new Error('usePermissions must be used within a PermissionsProvider');

  const [permissions, setPermissions] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const actionsToFetch = actions.filter((action) => permissions[action] === undefined);
    if (actionsToFetch.length === 0) return;

    setIsLoading(true);
    context
      .canDoActions(actionsToFetch)
      .then((fetchedPermissions) => {
        setPermissions((prev) => ({ ...prev, ...fetchedPermissions }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [context, actions, permissions, setIsLoading]);

  useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME !== prevActiveWorkspace.current) {
      setPermissions({});
      prevActiveWorkspace.current = ACTIVE_WORKSPACE_NAME;
    }
  }, [ACTIVE_WORKSPACE_NAME]);

  return [...actions.map((action) => permissions[action] ?? false), isLoading];
};

const usePermissionsInWorkspace = (config: Record<string, string[]>): WorkspacePermissionsMap => {
  const context = useContext(PermissionsContext);

  if (!context) throw new Error('usePermissionsInWorkspace must be used within a PermissionsProvider');

  const { batchResolvePermission } = context;
  const [permissions, setPermissions] = useState<WorkspacePermissionsMap>({});

  useEffect(() => {
    const workspaceEntries = Object.entries(config);
    const missingRequests: ResolvePermissionRequest[] = workspaceEntries.flatMap(([resource, actions]) => {
      return actions
        .filter((action) => permissions[resource]?.[action] === undefined)
        .map((action) => ({ resource, action }));
    });

    if (missingRequests.length === 0) return;

    batchResolvePermission(missingRequests).then(({ responses }) => {
      setPermissions((prevPermissions) => {
        const updatedPermissions = { ...prevPermissions };

        missingRequests.forEach(({ resource, action }, index) => {
          if (!resource || !action) return;
          if (!updatedPermissions[resource]) updatedPermissions[resource] = {};

          updatedPermissions[resource][action] = responses?.[index]?.allowed ?? false;
        });

        return updatedPermissions;
      });
    });
  }, [config, permissions]);

  return permissions;
};

export { PermissionsProvider, usePermissions, usePermissionsInWorkspace };
