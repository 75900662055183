import { PanelType, Stack, Text } from '@fluentui/react';
import {
  Accordion,
  Button,
  FontSizes,
  Item,
  Panel,
  TextWithCopy,
  defaultPalette,
  itemStylesTag,
  useTheme,
} from '@h2oai/ui-kit';
import { useRef } from 'react';

import { AIEngine } from '../../../../aiem/engine/types';
import { DAIEngine } from '../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_pb';
import { Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { H2OEngine } from '../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_pb';
import { bytesToGibibytes, getIdFromName, secondsToHours } from '../../../../aiem/utils';
import { ImagePullPolicyMap } from '../../../../pages/AIEngineSettingsPage/components/AIEMSettingsViewPanel';
import { formatDateWithTime } from '../../../../utils/utils';
import { AdvancedConfiguration } from '../AdvancedConfiguration/AdvancedConfiguration';
import { Badges, EngineLowStorageBadge, EngineResizingBadge } from '../Badges';
import { EngineProfileInfo } from './EngineProfileInfo';

export function AIEMPanelView(props: { engine: AIEngine; onDismiss: () => void }) {
  const { engine } = props;
  const versionKey = engine.engineType === Engine_Type.H2O ? 'h2oEngineVersion' : 'daiEngineVersion';
  const versionInfoKey = engine.engineType === Engine_Type.H2O ? 'h2oEngineVersionInfo' : 'daiEngineVersionInfo';

  const { palette } = useTheme();
  const advancedConfigurationRef = useRef<any>();
  const sectionTitle = { styles: { root: { fontSize: FontSizes.small } } };
  const viewLabel = { styles: { root: { fontSize: FontSizes.xsmall, color: palette?.gray500 } } };
  const viewValue = { styles: { root: { fontSize: FontSizes.xsmall, color: palette?.gray900, maxWidth: '50%' } } };
  const engineVersionInfo = engine[versionInfoKey];

  return (
    <Panel
      isLightDismiss
      closeButtonAriaLabel="Close"
      customWidth="800px"
      headerText={engine.displayName || ``}
      isFooterAtBottom={true}
      isOpen={true}
      onDismiss={props.onDismiss}
      onRenderFooterContent={() => <Button text="Close" onClick={props.onDismiss} />}
      type={PanelType.custom}
    >
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 4 }}>
          <Badges state={props.engine.state} />
          <EngineLowStorageBadge engine={props.engine} />
          <EngineResizingBadge engine={props.engine} />
        </Stack>
        <Text {...sectionTitle}>Engine Details</Text>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Instance Name</Text>
          <Text {...viewValue}>{engine.displayName}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Engine ID</Text>
          <Text {...viewValue}>{getIdFromName(engine.name)}</Text>
        </Stack>
        {engine.profile && (
          <Stack horizontal horizontalAlign="space-between">
            <Text {...viewLabel}>Profile</Text>
            <Text {...viewValue}>{getIdFromName(engine.profile)}</Text>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>AI Engine</Text>
          <Badges type={engine.engineType} />
        </Stack>
        {engine?.creatorDisplayName && (
          <Stack horizontal horizontalAlign="space-between">
            <Text {...viewLabel}>Owner</Text>
            <Text {...viewValue}>{engine.creatorDisplayName}</Text>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Version</Text>
          <Text {...viewValue}>{getIdFromName(engine[versionKey])}</Text>
        </Stack>

        {engine.engineType === Engine_Type.H2O && (
          <Stack horizontal horizontalAlign="space-between">
            <Text {...viewLabel}>Number of Nodes</Text>
            <Text {...viewValue}>{(engine as H2OEngine).nodeCount}</Text>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>{engine.engineType === Engine_Type.H2O ? 'CPUs per Node' : 'CPU Units'}</Text>
          <Text {...viewValue}>{engine.cpu}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>{engine.engineType === Engine_Type.H2O ? 'GPUs per Node' : 'GPU Units'}</Text>
          <Text {...viewValue}>{engine.gpu}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>{engine.engineType === Engine_Type.H2O ? 'Memory per Node (GiB)' : 'Memory (GiB)'}</Text>
          <Text {...viewValue}>{bytesToGibibytes(engine.memoryBytes)}</Text>
        </Stack>
        {(engine.engineType === Engine_Type.DRIVERLESS_AI || engine.storageBytes) && (
          <Stack horizontal horizontalAlign="space-between">
            <Text {...viewLabel}>
              {engine.engineType === Engine_Type.H2O ? 'Allocated Storage per Node (GiB)' : 'Allocated Storage (GiB)'}
            </Text>
            <Text {...viewValue}>{bytesToGibibytes(engine.storageBytes)}</Text>
          </Stack>
        )}
        {engine.freeDiskSizeBytes && (
          <Stack horizontal horizontalAlign="space-between">
            <Text {...viewLabel}>Free storage (GiB)</Text>
            <Text {...viewValue}>{bytesToGibibytes(engine.freeDiskSizeBytes)}</Text>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Max Idle Time (Hrs)</Text>
          <Text>{secondsToHours(engine.maxIdleDuration!)}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Max Uptime (Hrs)</Text>
          <Text>{secondsToHours(engine.maxRunningDuration!)}</Text>
        </Stack>
        {engine.engineType === Engine_Type.DRIVERLESS_AI && (engine as DAIEngine).dataDirectoryStorageClass && (
          <Stack horizontal horizontalAlign="space-between">
            <Text {...viewLabel}>Storage Class Name</Text>
            <Text {...viewValue}>{(engine as DAIEngine).dataDirectoryStorageClass}</Text>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Created At</Text>
          <Text {...viewValue}>{engine.createTime?.toLocaleString()}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Last Resumed At</Text>
          <Text {...viewValue}>{engine.resumeTime?.toLocaleString()}</Text>
        </Stack>
        {engine.engineType === Engine_Type.DRIVERLESS_AI && (
          <Accordion
            title="Advanced Configuration (config.toml)"
            isClose
            styles={{
              title: { fontWeight: 400 },
              root: { marginTop: 12, height: 'unset !important' },
            }}
          >
            <AdvancedConfiguration
              readOnly={true}
              ref={advancedConfigurationRef}
              config={(engine as DAIEngine).config || {}}
              onConfigChange={() => null}
            />
          </Accordion>
        )}
        {engine.profileInfo && (
          <Accordion
            title="Last Used Profile Info"
            isClose
            styles={{
              title: { fontWeight: 400 },
              root: { marginTop: 12, height: 'unset !important' },
            }}
          >
            <EngineProfileInfo engineType={engine.engineType} profileInfo={engine.profileInfo} />
          </Accordion>
        )}
        {engineVersionInfo && (
          <Accordion
            title="Engine Version Info"
            isClose
            styles={{
              title: { fontWeight: 400 },
              root: { marginTop: 12, marginBottom: 40, height: 'unset !important' },
            }}
          >
            <Stack tokens={{ childrenGap: 20 }}>
              <Stack horizontal horizontalAlign="space-between">
                <Text {...viewLabel}>Name</Text>
                <Text {...viewValue}>{getIdFromName(engineVersionInfo.name) || '-'}</Text>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Text {...viewLabel}>Aliases</Text>
                {engineVersionInfo.aliases?.length
                  ? engineVersionInfo.aliases.map((d: string) => (
                      <Item
                        key={`key_${d}`}
                        styles={itemStylesTag}
                        idField="id"
                        labelField="title"
                        styleField="style"
                        data={{
                          id: d,
                          title: d,
                          style: {
                            backgroundColor: defaultPalette.gray200,
                            color: defaultPalette.gray800,
                          },
                        }}
                      />
                    ))
                  : '-'}
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Text {...viewLabel}>Deprecated</Text>
                <Text {...viewValue}>{engineVersionInfo.deprecated ? 'Yes' : 'No'}</Text>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Text {...viewLabel}>Image</Text>
                <Text {...viewValue} title={engineVersionInfo.image}>
                  <TextWithCopy text={engineVersionInfo.image} />
                </Text>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Text {...viewLabel}>Image pull policy</Text>
                <Text {...viewValue}>
                  {engineVersionInfo.imagePullPolicy
                    ? ImagePullPolicyMap.get(engineVersionInfo.imagePullPolicy) || '-'
                    : '-'}
                </Text>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Text {...viewLabel}>Image pull secrets</Text>
                <Text {...viewValue}>{engineVersionInfo.imagePullSecrets?.join(', ') || '-'}</Text>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Text {...viewLabel}>Created by</Text>
                <Text {...viewValue} title={engineVersionInfo.creatorDisplayName}>
                  {engineVersionInfo.creatorDisplayName}
                </Text>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Text {...viewLabel}>Created date</Text>
                <Text {...viewValue} title={engineVersionInfo.createTime}>
                  {formatDateWithTime(new Date(engineVersionInfo.createTime || ''))}
                </Text>
              </Stack>
              {engineVersionInfo.updaterDisplayName && (
                <Stack horizontal horizontalAlign="space-between">
                  <Text {...viewLabel}>Updated by</Text>
                  <Text {...viewValue} title={engineVersionInfo.updaterDisplayName}>
                    {engineVersionInfo.updaterDisplayName}
                  </Text>
                </Stack>
              )}
              {engineVersionInfo.updateTime && (
                <Stack horizontal horizontalAlign="space-between">
                  <Text {...viewLabel}>Update date</Text>
                  <Text {...viewValue} title={engineVersionInfo.updateTime}>
                    {formatDateWithTime(new Date(engineVersionInfo.updateTime))}
                  </Text>
                </Stack>
              )}
            </Stack>
          </Accordion>
        )}
      </Stack>
    </Panel>
  );
}
