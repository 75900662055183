import { useTheme } from '@h2oai/ui-kit';

import { skeletonLoaderStyles } from './SkeletonLoader.styles';

interface ISkeletonLoaderProps {
  width: string | number;
  height: string | number;
}

export default function SkeletonLoader({ width = '100%', height = '1rem' }: ISkeletonLoaderProps) {
  const theme = useTheme();
  return <div style={skeletonLoaderStyles(theme, width, height)} />;
}
