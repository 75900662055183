import { MessageBarType, Stack } from '@fluentui/react';
import {
  Button,
  IconName,
  Item,
  ListCell,
  TooltipHost,
  buttonStylesGhost,
  buttonStylesIcon,
  buttonStylesIconCard,
  itemStylesTag,
  useClassNames,
  useToast,
} from '@h2oai/ui-kit';
import { useCallback, useMemo } from 'react';

import { WorkspacesUserActionType } from '../../../../authz/userActions';
import { formatError } from '../../../../utils/utils';
import { useWorkspaces } from '../../../Orchestrator/WorkspaceProvider';
import { usersCircleListStyle } from './parts.styles';
import {
  UserCircleProps,
  UsersCircleListClassNames,
  UsersCircleListStyles,
  WorkspaceItemActionsProps,
  WorkspaceSharedWithCellProps,
} from './types';

export const getInitials = (fullName?: string) => {
  if (!fullName) return '';

  const trimmedName = fullName.trim();
  const names = trimmedName.split(' ');

  const firstName = names[0];
  const lastName = names[names.length - 1];

  if (names.length === 1) {
    return `${firstName.charAt(0).toUpperCase()}`;
  }

  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
};

export const getFirstItemsAndCount = (array: any[]) => {
  const items = array.slice(0, 5);
  const countLeft = array.length - items.length;

  return { items, countLeft };
};

export const UsersCircleList = (props: UserCircleProps) => {
  const { items, countLeft } = useMemo(() => {
    return getFirstItemsAndCount(props.users || []);
  }, [props.users]);
  const classNames = useClassNames<UsersCircleListStyles, UsersCircleListClassNames>(
    'UsersCircleList',
    usersCircleListStyle
  );

  return (
    <Stack horizontal className={classNames.root} verticalAlign="center">
      {items?.map((user) => (
        <div className={classNames.circle} key={user.name}>
          <span>{getInitials(user.displayName)}</span>
        </div>
      ))}
      {countLeft > 0 && <div className={classNames.usersNumber}>+{countLeft}</div>}
    </Stack>
  );
};

export const WorkspaceSharedWithCell = (props: WorkspaceSharedWithCellProps) => {
  const { users, header } = props;
  const members = useMemo(
    () => ({
      users: users.filter((item) => item.subject.includes('users')),
      groups: users.filter((item) => item.subject.includes('groups')),
    }),
    [users]
  );
  const tooltipContent = members.groups ? (
    <Stack>
      <Stack>Users: {members?.users.map((user) => user.displayName).join(', ')}</Stack>
      <Stack>Groups: {members?.groups.map((group) => group.displayName).join(', ')}</Stack>
    </Stack>
  ) : (
    users?.map((user) => user.displayName).join(', ')
  );

  return (
    <ListCell
      headerText={header}
      text={''}
      onRenderText={() => (
        <TooltipHost id="users-tooltip" content={tooltipContent}>
          <UsersCircleList users={users || []} />
        </TooltipHost>
      )}
    />
  );
};

export const WorkspaceItemActions = (props: WorkspaceItemActionsProps) => {
  const { workspace, userPermissions } = props;
  const canEdit = userPermissions[WorkspacesUserActionType.WORKSPACE_EDIT];
  const canDelete = userPermissions[WorkspacesUserActionType.WORKSPACE_DELETE];
  const { deleteWorkspace, undeleteWorkspace, ACTIVE_WORKSPACE_NAME, activateWorkspace, defaultWorkspace } =
    useWorkspaces();
  const isActive = ACTIVE_WORKSPACE_NAME === workspace.name;
  const { addToast, removeAllToasts } = useToast();
  const handleUndeleteWorkspace = useCallback(
    async (workspaceName: string) => {
      try {
        await undeleteWorkspace(workspaceName);
        removeAllToasts();
      } catch (error) {
        const message = `Failed to undelete workspace: ${formatError(error)}`;
        console.error(message);
        addToast({ messageBarType: MessageBarType.error, message });
      }
    },
    [undeleteWorkspace, removeAllToasts, addToast, formatError]
  );
  const handleDeleteWorkspace = useCallback(
    async (workspaceName: string) => {
      try {
        if (isActive && defaultWorkspace?.name) activateWorkspace(defaultWorkspace?.name);
        await deleteWorkspace(workspaceName);
        addToast({
          messageBarType: MessageBarType.success,
          message: (
            <Stack>
              <p>Workspace successfully deleted.</p>
              <Stack horizontalAlign="end">
                <Button onClick={() => handleUndeleteWorkspace(workspaceName)} styles={buttonStylesGhost}>
                  Undo Delete
                </Button>
              </Stack>
            </Stack>
          ),
        });
      } catch (error) {
        const message = `Failed to delete workspace: ${formatError(error)}`;
        console.error(message);
        addToast({ messageBarType: MessageBarType.error, message });
      }
    },
    [deleteWorkspace, handleUndeleteWorkspace, addToast, formatError]
  );

  return (
    <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign="end" verticalAlign="center">
      {workspace.name === ACTIVE_WORKSPACE_NAME && (
        <Item
          data={{
            style: {
              backgroundColor: 'transparent',
              padding: '0px 20px',
              fontSize: 14,
            },
            title: 'Active',
          }}
          labelField="title"
          styleField="style"
          styles={itemStylesTag}
        />
      )}

      <Button
        styles={[
          buttonStylesIcon,
          buttonStylesIconCard,
          { root: { minWidth: '32px', i: { fontSize: '24px', transform: 'rotate(90deg)' } } },
        ]}
        menuIconName={IconName.More}
        menuItems={[
          ...(isActive
            ? []
            : [
                {
                  key: 'workspace-set-active',
                  text: 'Set active',
                  onClick: () => {
                    activateWorkspace(workspace.name || '');
                  },
                  'data-test': 'workspace-set-active',
                  iconProps: {
                    iconName: 'Play',
                  },
                },
              ]),
          {
            key: 'workspace-edit',
            text: 'Edit',
            onClick: canEdit
              ? () => {
                  props.setWorkspaceForEdit(String(workspace.name));
                }
              : undefined,
            disabled: !canEdit,
            'data-test': 'workspace-edit-button',
            style: { ...(canDelete ? {} : { opacity: 0.5 }) },
            iconProps: {
              iconName: 'Edit',
            },
          },
          {
            key: 'workspace-delete',
            text: 'Delete',
            onClick: canDelete
              ? () => {
                  void handleDeleteWorkspace(String(workspace.name));
                }
              : undefined,
            disabled: !canDelete,
            'data-test': 'workspace-delete-button',
            style: { color: 'var(--h2o-red400)', ...(canDelete ? {} : { opacity: 0.5 }) },
            iconProps: {
              iconName: 'Delete',
              style: { color: 'var(--h2o-red400)' },
            },
          },
        ]}
      />
    </Stack>
  );
};
