import { Dispatch, ReactNode, SetStateAction, createContext, useMemo, useReducer } from 'react';

import { GetEnvironmentDataResponse, User } from '../ai.h2o.cloud.appstore';
import { ILeftPanelProps } from '../components/LeftPanel/LeftPanel';
import { AppStoreMenuInfo } from '../components/Navigation/types';
import { INotification, INotificationAction, NotificationActionType } from './models';

interface UserProviderProps {
  user: User;
  children: ReactNode;
}

export interface CloudPlatformDiscoveryConfig {
  aiEngineManagerApiUrl?: string;
  loggingServicesApiUrl?: string;
  telemetryApiUrl?: string;
  mlopsApiUrl?: string;
  orchestratorApiUrl?: string;
  notebookApiUrl?: string;
  secureStore?: string;
  authzUrl?: string;
  mcAdminCenterApiUrl?: string;
  h2oCloudEnvironment?: string;
  providerUrl: string;
  platformClientId: string;
}

export const UserContext = createContext<User>({} as User);

export const UserProvider = ({ children, user }: UserProviderProps) => (
  <UserContext.Provider value={user}>{children}</UserContext.Provider>
);

export interface EnvironmentAndMenu extends GetEnvironmentDataResponse {
  menu?: AppStoreMenuInfo;
}

interface EnvProviderProps {
  env: EnvironmentAndMenu | undefined;
  children: ReactNode;
}

export const EnvContext = createContext<EnvironmentAndMenu | undefined>({} as EnvironmentAndMenu);

export const EnvProvider = ({ children, env }: EnvProviderProps) => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);

interface CloudPlatformDiscoveryProviderProps {
  discovery: CloudPlatformDiscoveryConfig;
  children: ReactNode;
}

export const CloudPlatformDiscoveryContext = createContext<CloudPlatformDiscoveryConfig | undefined>(
  {} as CloudPlatformDiscoveryConfig
);

export const CloudPlatformDiscoveryProvider = ({ children, discovery }: CloudPlatformDiscoveryProviderProps) => (
  <CloudPlatformDiscoveryContext.Provider value={discovery}>{children}</CloudPlatformDiscoveryContext.Provider>
);

interface LeftPanelProviderProps {
  setLeftPanelProps: Dispatch<SetStateAction<ILeftPanelProps | undefined>>;
  children: ReactNode;
}

export const LeftPanelContext = createContext<Dispatch<SetStateAction<ILeftPanelProps | undefined>>>(() => null);

export const LeftPanelProvider = ({ children, setLeftPanelProps }: LeftPanelProviderProps) => (
  <LeftPanelContext.Provider value={setLeftPanelProps}>{children}</LeftPanelContext.Provider>
);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationContext = createContext<{
  messages: INotification[];
  dispatch: Dispatch<INotificationAction>;
}>({ messages: [], dispatch: () => null });

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [messages, dispatch] = useReducer((messages: INotification[], action: INotificationAction) => {
    switch (action.type) {
      case NotificationActionType.Add:
        return [...messages, { ...action.message }];
      case NotificationActionType.Remove:
        return messages.filter((n) => n.id !== action.message.id);
      default:
        return messages;
    }
  }, []);
  const value = useMemo(() => ({ messages, dispatch }), [messages]);
  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
