// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file user_login/userlogin.proto (package user_login, syntax proto3)
/* eslint-disable */

import { RPC } from "../runtime";

/**
 * @generated from message user_login.LoginEventsRequest
 */
export type LoginEventsRequest = {
/**
 * Start date in YYYY-MM-DD format
 *
 * @generated from field: string start_date = 1;
 */
startDate?: string;
/**
 * End date in YYYY-MM-DD format
 *
 * @generated from field: string end_date = 2;
 */
endDate?: string;
}
;
/**
 * @generated from message user_login.LoginEventsResponse
 */
export type LoginEventsResponse = {
/**
 * @generated from field: repeated user_login.LoginEvent events = 1;
 */
events?: LoginEvent[];
}
;
/**
 * @generated from message user_login.LoginEvent
 */
export type LoginEvent = {
/**
 * Timestamp
 *
 * @generated from field: string ts = 1;
 */
ts?: string;
/**
 * User name
 *
 * @generated from field: string user_name = 2;
 */
userName?: string;
}
;
/**
 * @generated from message user_login.LoginStatisticsResponse
 */
export type LoginStatisticsResponse = {
/**
 * Total login count
 *
 * @generated from field: int32 total_login_count = 1;
 */
totalLoginCount?: number;
/**
 * Unique user count
 *
 * @generated from field: int32 unique_user_count = 2;
 */
uniqueUserCount?: number;
/**
 * Most logged-in user
 *
 * @generated from field: string most_logged_in_user = 3;
 */
mostLoggedInUser?: string;
/**
 * Most logged-in user domain
 *
 * @generated from field: string most_logged_user_domain = 4;
 */
mostLoggedUserDomain?: string;
/**
 * List of unique user domains
 *
 * @generated from field: string unique_user_domains = 5;
 */
uniqueUserDomains?: string;
}
;
/**
 * @generated from rpc user_login.LoginService.GetLoginEvents
 */
export const LoginService_GetLoginEvents = new RPC<LoginEventsRequest,LoginEventsResponse>("GET", "/getLoginEvents");
/**
 * @generated from rpc user_login.LoginService.GetLoginStatistics
 */
export const LoginService_GetLoginStatistics = new RPC<LoginEventsRequest,LoginStatisticsResponse>("GET", "/getLoginStatistics");
