import { IDropdownOption } from '@fluentui/react';

import { AIEngine, EngineProfileOption } from '../../aiem/engine/types';
import { DAIEngine } from '../../aiem/gen/ai/h2o/engine/v1/dai_engine_pb';
import { H2OEngine } from '../../aiem/gen/ai/h2o/engine/v1/h2o_engine_pb';
import {
  CalculateH2OEngineSizeCompressedDatasetRequest,
  CalculateH2OEngineSizeRawDatasetRequest,
} from '../../aiem/gen/ai/h2o/engine/v1/h2o_engine_service_pb';
import { NotebookEngine } from '../../aiem/gen/ai/h2o/engine/v1/notebook_engine_pb';
import { toBigIntString } from '../../aiem/gen/runtime';
import { gibibytesToBigIntStringBytes } from '../../aiem/utils';

export enum DriverlessEngineProfileOptionKeyType {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xl = 'xl',
  xxl = 'xxl',
  custom = 'custom',
}

export enum H2OEngineProfileOptionKeyType {
  default = 'default',
  custom = 'custom',
  raw = 'raw',
  compressed = 'compressed',
}

export const customDAIEngineSizeOption: EngineProfileOption = {
  key: DriverlessEngineProfileOptionKeyType.custom,
  text: 'Custom',
  dataTest: 'dai-custom',
};

export const DriverlessEngineSizeOptions: EngineProfileOption[] = [
  {
    key: DriverlessEngineProfileOptionKeyType.small,
    text: 'View data, interpretability, results',
    dataTest: 'dai-m5.xlarge',
    cpu: 6,
    gpu: 0,
    memoryBytes: gibibytesToBigIntStringBytes(22),
    storageBytes: gibibytesToBigIntStringBytes(64),
  },
  {
    key: DriverlessEngineProfileOptionKeyType.medium,
    text: 'AutoML',
    dataTest: 'dai-m5.4xlarge',
    cpu: 14,
    gpu: 0,
    memoryBytes: gibibytesToBigIntStringBytes(54),
    storageBytes: gibibytesToBigIntStringBytes(64),
  },
  {
    key: DriverlessEngineProfileOptionKeyType.large,
    text: 'AutoML fast or on 10+GB of data',
    dataTest: 'dai-m5.12xlarge',
    cpu: 46,
    gpu: 0,
    memoryBytes: gibibytesToBigIntStringBytes(182),
    storageBytes: gibibytesToBigIntStringBytes(64),
  },
  {
    key: DriverlessEngineProfileOptionKeyType.xl,
    text: 'NLP or Image AutoML experiments',
    dataTest: 'dai-g4dn.4xlarge',
    cpu: 14,
    gpu: 1,
    memoryBytes: gibibytesToBigIntStringBytes(54),
    storageBytes: gibibytesToBigIntStringBytes(64),
  },
  {
    key: DriverlessEngineProfileOptionKeyType.xxl,
    text: 'NLP or Image AutoML experiments on 10+GB of data',
    dataTest: 'dai-g4dn.12xlarge',
    cpu: 46,
    gpu: 4,
    memoryBytes: gibibytesToBigIntStringBytes(182),
    storageBytes: gibibytesToBigIntStringBytes(64),
  },
  customDAIEngineSizeOption,
];

export const H2OEngineDefaultProfiles: IDropdownOption[] = [
  { key: '', text: 'Not set' },
  {
    key: H2OEngineProfileOptionKeyType.raw,
    text: 'Custom for Raw Data',
  },
  {
    key: H2OEngineProfileOptionKeyType.compressed,
    text: 'Custom for Compressed Data',
  },
];

export const H2OEngineSizeOptions: EngineProfileOption[] = [
  {
    key: H2OEngineProfileOptionKeyType.custom,
    text: 'Custom',
    dataTest: 'h2o-custom',
  },
  {
    key: H2OEngineProfileOptionKeyType.raw,
    text: 'Custom for Raw Data',
    dataTest: 'h2o-custom-raw',
  },
  {
    key: H2OEngineProfileOptionKeyType.compressed,
    text: 'Custom for Compressed Data',
    dataTest: 'h2o-custom-compressed',
  },
];
export const DefaultDriverlessEngineSize = DriverlessEngineProfileOptionKeyType.custom;
export const DefaultNotebookEngineSize = DriverlessEngineProfileOptionKeyType.custom;
export const DefaultH2OEngineSize = H2OEngineProfileOptionKeyType.custom;

const driverlessDefaultSize = DriverlessEngineSizeOptions.find((o) => o.key === DefaultDriverlessEngineSize);
const notebookDefaultSize = DriverlessEngineSizeOptions.find((o) => o.key === DefaultNotebookEngineSize);
const h2oDefaultSize = H2OEngineSizeOptions.find((o) => o.key === DefaultH2OEngineSize);

const emptyEngineSharedFields: Partial<AIEngine> = {
  id: undefined,
  displayName: undefined,
  maxIdleDuration: undefined,
  maxRunningDuration: undefined,
  annotations: undefined,
};

export const EmptyDAIEngine: DAIEngine = {
  ...emptyEngineSharedFields,
  cpu: driverlessDefaultSize?.cpu,
  gpu: driverlessDefaultSize?.gpu,
  memoryBytes: driverlessDefaultSize?.memoryBytes,
  storageBytes: driverlessDefaultSize?.storageBytes,
  daiEngineVersion: '',
  daiEngineVersionInfo: undefined,
  profile: '',
};

export const EmptyNotebookEngine: NotebookEngine = {
  ...emptyEngineSharedFields,
  cpu: notebookDefaultSize?.cpu,
  gpu: notebookDefaultSize?.gpu,
  memoryBytes: notebookDefaultSize?.memoryBytes,
  storageBytes: notebookDefaultSize?.storageBytes,
  profile: '',
  notebookImage: '',
};

export const EmptyH2OEngine: H2OEngine = {
  ...emptyEngineSharedFields,
  cpu: h2oDefaultSize?.cpu,
  gpu: h2oDefaultSize?.gpu,
  memoryBytes: h2oDefaultSize?.memoryBytes,
  nodeCount: 1,
  h2oEngineVersion: '',
  h2oEngineVersionInfo: undefined,
  profile: '',
};

export const defaultH2OCompressedEngineSizeRequest: Partial<CalculateH2OEngineSizeCompressedDatasetRequest> = {
  rowsCount: toBigIntString('10000'),
  columnsCount: toBigIntString('10'),
};

export const defaultH2ORawEngineSizeRequest: Partial<CalculateH2OEngineSizeRawDatasetRequest> = {
  datasetSizeBytes: gibibytesToBigIntStringBytes(10),
};

// This number is the percentage value of storage capacity at or below which a warning to the user will be displayed.
// e.g. 0.2 would be a 20% remaining free disk space threshold.
export const LowStorageWarningThreshold = 0.2;
