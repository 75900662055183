import { IColumn } from '@fluentui/react';
import { ListCell } from '@h2oai/ui-kit';

import { onRenderBooleanCell } from '../../utils/render';
import { H2OEngineProfile } from '../gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import {
  CreateH2OEngineProfileRequest,
  CreateH2OEngineProfileResponse,
  DeleteH2OEngineProfileRequest,
  DeleteH2OEngineProfileResponse,
  GetH2OEngineProfileRequest,
  GetH2OEngineProfileResponse,
  H2OEngineProfileService_CreateH2OEngineProfile,
  H2OEngineProfileService_DeleteH2OEngineProfile,
  H2OEngineProfileService_GetH2OEngineProfile,
  H2OEngineProfileService_ListH2OEngineProfiles,
  H2OEngineProfileService_UpdateH2OEngineProfile,
  ListH2OEngineProfilesRequest,
  ListH2OEngineProfilesResponse,
  UpdateH2OEngineProfileRequest,
  UpdateH2OEngineProfileResponse,
} from '../gen/ai/h2o/engine/v1/h2o_engine_profile_service_pb';
import { BigIntString } from '../gen/runtime';
import { engineProfileBaseFields, engineProfileFieldsConfig } from './constants';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';
import { onRender, onRenderDisplayAndId } from './utils';

const entityType = EntityType.H2OEngineProfile;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateH2OEngineProfile',
    rpc: H2OEngineProfileService_CreateH2OEngineProfile,
    type: EntityActionType.Create,
    responseKey: 'h2oEngineProfile',
    requestNameKey: 'h2oEngineProfileId',
    requestPayloadKey: 'h2oEngineProfile',
    requestNameKeyPrefix: 'h2oEngineProfile/',
    entityType,
  } as EntityAction<H2OEngineProfile, CreateH2OEngineProfileRequest, CreateH2OEngineProfileResponse>,
  [EntityActionType.Get]: {
    name: 'GetH2OEngineProfile',
    rpc: H2OEngineProfileService_GetH2OEngineProfile,
    type: EntityActionType.Get,
    requestNameKey: 'name' as unknown,
    responseKey: 'h2oEngineProfile',
    entityType,
  } as EntityAction<H2OEngineProfile, GetH2OEngineProfileRequest, GetH2OEngineProfileResponse>,
  [EntityActionType.List]: {
    name: 'ListH2OEngineProfile',
    rpc: H2OEngineProfileService_ListH2OEngineProfiles,
    type: EntityActionType.List,
    responseKey: 'h2oEngineProfiles',
    entityType,
  } as EntityAction<H2OEngineProfile, ListH2OEngineProfilesRequest, ListH2OEngineProfilesResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateH2OEngineProfile',
    rpc: H2OEngineProfileService_UpdateH2OEngineProfile,
    type: EntityActionType.Update,
    responseKey: 'h2oEngineProfile',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'h2oEngineProfile',
    entityType,
  } as EntityAction<H2OEngineProfile, UpdateH2OEngineProfileRequest, UpdateH2OEngineProfileResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteH2OEngineProfile',
    rpc: H2OEngineProfileService_DeleteH2OEngineProfile,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<H2OEngineProfile, DeleteH2OEngineProfileRequest, DeleteH2OEngineProfileResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'icon',
    name: 'Icon',
    minWidth: 50,
    maxWidth: 50,
    onRender: () => <ListCell styles={{ root: { paddingLeft: 12 } }} iconProps={{ iconName: 'Settings' }} />,
  },
  {
    key: 'displayName',
    name: 'Display Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 250,
    onRender: onRenderDisplayAndId,
  },
  {
    key: 'priority',
    name: 'Priority',
    fieldName: 'priority',
    minWidth: 80,
    maxWidth: 120,
    onRender,
  },
  {
    key: 'enabled',
    name: 'Enabled',
    fieldName: 'enabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

const engineProfileFields = engineProfileFieldsConfig('H2O');

export const fields: EntityField<H2OEngineProfile>[] = [
  ...engineProfileBaseFields('H2O'),
  engineProfileFields.nodeCountConstraint,
  engineProfileFields.maxIdleDurationConstraint,
  engineProfileFields.maxRunningDurationConstraint,
  engineProfileFields.maxRunningEngines,
  engineProfileFields.nodeCountConstraint,
  engineProfileFields.nodeCountConstraint,
  engineProfileFields.yamlPodTemplateSpec,
  engineProfileFields.yamlGpuTolerations,
  {
    name: 'gpuResourceName',
    type: EntityFieldType.Text,
    label: 'GPU Resource Name',
    description: 'Name of the Kubernetes resource name for GPUs.',
    inputDefaultValue: 'nvidia.com/gpu',
  },
];

export const h2oEngineProfileEntity: Entity<H2OEngineProfile> = {
  type: EntityType.H2OEngineProfile,
  name: 'H2OEngineProfile',
  createButtonLabel: 'Add H2O Engine Profile',
  displayName: 'H2O Engine Profile',
  displayNameKey: 'displayName',
  actions,
  columns,
  fields,
  requestNameKeyPrefix: 'workspaces/global/h2oEngineProfiles/',
  emptyModel: {
    name: 'new-h2o-engine-profile-id',
    displayName: 'New H2O Engine Profile',
    priority: 0,
    enabled: true,
    assignedOidcRolesEnabled: false,
    assignedOidcRoles: [],
    maxRunningEngines: 1,
    nodeCountConstraint: {
      min: '1' as BigIntString,
      default: '1' as BigIntString,
    },
    cpuConstraint: {
      min: '1' as BigIntString,
      default: '1' as BigIntString,
    },
    gpuConstraint: {
      min: '0' as BigIntString,
      default: '0' as BigIntString,
    },
    memoryBytesConstraint: {
      min: '2147483648' as BigIntString,
      default: '4294967296' as BigIntString,
    },
    maxIdleDurationConstraint: {
      min: '0s' as BigIntString,
      default: '3600s' as BigIntString,
    },
    maxRunningDurationConstraint: {
      min: '0s' as BigIntString,
      default: '432000s' as BigIntString,
    },
    yamlPodTemplateSpec: '',
    yamlGpuTolerations: '',
  },
  canBeReordered: false,
};
