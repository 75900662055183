import { AIEngine } from '../../../../../aiem/engine/types';
import { DAIEngineProfile } from '../../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import { Engine_Type } from '../../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { H2OEngine } from '../../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_pb';
import { bytesToGibibytes } from '../../../../../aiem/utils';
import { LabelIconTooltip, OutOfVersionSeverity } from '../../LabelIconTooltip/LabelIconTooltip';
import { MetadataLabelCell, MetadataRow, MetadataValueCell } from '../../MetadataTable/MetadataTable';

interface DisplayPresetEngineProfileProps {
  loading?: boolean;
  engine: AIEngine;
  profile?: DAIEngineProfile;
}

export default function EngineProfileTableRows({ loading, engine }: DisplayPresetEngineProfileProps) {
  const { engineType, cpu, gpu } = engine;
  return (
    <>
      <MetadataRow>
        <MetadataLabelCell colspan={3}>
          {engineType === Engine_Type.H2O ? 'CPUs per Node' : 'CPU Units'}
        </MetadataLabelCell>
        <MetadataValueCell loading={loading}>
          <LabelIconTooltip id={'cpu-info'} data-test="cpu-info" label={cpu} severity={OutOfVersionSeverity.Warning} />
        </MetadataValueCell>
      </MetadataRow>
      <MetadataRow>
        <MetadataLabelCell colspan={3}>
          {engineType === Engine_Type.H2O ? 'GPUs per Node' : 'GPU Units'}
        </MetadataLabelCell>
        <MetadataValueCell loading={loading}>
          <LabelIconTooltip id={'gpu-info'} data-test="gpu-info" label={gpu} severity={OutOfVersionSeverity.Warning} />
        </MetadataValueCell>
      </MetadataRow>
      {engineType === Engine_Type.H2O && (
        <MetadataRow>
          <MetadataLabelCell colspan={3}>Number of Nodes</MetadataLabelCell>
          <MetadataValueCell loading={loading}>{(engine as H2OEngine).nodeCount}</MetadataValueCell>
        </MetadataRow>
      )}
      <MetadataRow>
        <MetadataLabelCell colspan={3}>
          {engineType === Engine_Type.H2O ? 'Memory per Node' : 'Memory'}
        </MetadataLabelCell>
        <MetadataValueCell loading={loading}>
          <LabelIconTooltip
            id={'memory-info'}
            data-test="memory-info"
            label={`${bytesToGibibytes(engine.memoryBytes)} GiB`}
            severity={OutOfVersionSeverity.Warning}
          />
        </MetadataValueCell>
      </MetadataRow>
      {engineType === Engine_Type.DRIVERLESS_AI && (
        <MetadataRow>
          <MetadataLabelCell colspan={3}>Storage</MetadataLabelCell>
          <MetadataValueCell loading={loading}>
            <LabelIconTooltip
              id={'storage-info'}
              data-test="storage-info"
              label={`${bytesToGibibytes(engine.storageBytes)} GiB`}
              severity={OutOfVersionSeverity.Warning}
            />
          </MetadataValueCell>
        </MetadataRow>
      )}
    </>
  );
}
