import { IColumn } from '@fluentui/react';

import { onRenderBooleanCell } from '../../utils/render';
import { listIconSize } from '../../utils/utils';
import { ImagePullPolicy } from '../gen/ai/h2o/engine/v1/base_types_pb';
import { H2OEngineVersion } from '../gen/ai/h2o/engine/v1/h2o_engine_version_pb';
import {
  AssignH2OEngineVersionAliasesRequest,
  AssignH2OEngineVersionAliasesResponse,
  CreateH2OEngineVersionRequest,
  CreateH2OEngineVersionResponse,
  DeleteH2OEngineVersionRequest,
  DeleteH2OEngineVersionResponse,
  GetH2OEngineVersionRequest,
  GetH2OEngineVersionResponse,
  H2OEngineVersionService_AssignH2OEngineVersionAliases,
  H2OEngineVersionService_CreateH2OEngineVersion,
  H2OEngineVersionService_DeleteH2OEngineVersion,
  H2OEngineVersionService_GetH2OEngineVersion,
  H2OEngineVersionService_ListH2OEngineVersions,
  H2OEngineVersionService_UpdateH2OEngineVersion,
  ListH2OEngineVersionsRequest,
  ListH2OEngineVersionsResponse,
  UpdateH2OEngineVersionRequest,
  UpdateH2OEngineVersionResponse,
} from '../gen/ai/h2o/engine/v1/h2o_engine_version_service_pb';
import { AIEMUserActionType } from '../userActions';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';
import {
  getOptionsFromEnum,
  onRender,
  onRenderDAIIconCell,
  onRenderDisplayAndIdColumnWithTitle,
  onRenderStringArrayCell,
} from './utils';

const entityType = EntityType.H2OEngineVersion;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateH2OEngineVersion',
    rpc: H2OEngineVersionService_CreateH2OEngineVersion,
    type: EntityActionType.Create,
    responseKey: 'h2oEngineVersion',
    requestNameKey: 'h2oEngineVersionId',
    requestPayloadKey: 'h2oEngineVersion',
    requestNameKeyPrefix: 'h2oEngineVersion/',
    entityType,
  } as EntityAction<H2OEngineVersion, CreateH2OEngineVersionRequest, CreateH2OEngineVersionResponse>,
  [EntityActionType.Get]: {
    name: 'GetH2OEngineVersion',
    rpc: H2OEngineVersionService_GetH2OEngineVersion,
    type: EntityActionType.Get,
    requestNameKey: 'name' as unknown,
    responseKey: 'h2oEngineVersion',
    entityType,
  } as EntityAction<H2OEngineVersion, GetH2OEngineVersionRequest, GetH2OEngineVersionResponse>,
  [EntityActionType.List]: {
    name: 'ListH2OEngineVersion',
    rpc: H2OEngineVersionService_ListH2OEngineVersions,
    type: EntityActionType.List,
    responseKey: 'h2oEngineVersions',
    entityType,
  } as EntityAction<H2OEngineVersion, ListH2OEngineVersionsRequest, ListH2OEngineVersionsResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateH2OEngineVersion',
    rpc: H2OEngineVersionService_UpdateH2OEngineVersion,
    type: EntityActionType.Update,
    responseKey: 'h2oEngineVersion',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'h2oEngineVersion',
    entityType,
  } as EntityAction<H2OEngineVersion, UpdateH2OEngineVersionRequest, UpdateH2OEngineVersionResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteH2OEngineVersion',
    rpc: H2OEngineVersionService_DeleteH2OEngineVersion,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<H2OEngineVersion, DeleteH2OEngineVersionRequest, DeleteH2OEngineVersionResponse>,
  [EntityActionType.Assign]: {
    name: 'AssignH2OEngineVersionAliases',
    rpc: H2OEngineVersionService_AssignH2OEngineVersionAliases,
    type: EntityActionType.Assign,
    responseKey: 'h2oEngineVersions',
    requestNameKey: 'name',
    requestPayloadKey: 'aliases',
    payloadKey: 'aliases',
    entityType,
  } as EntityAction<H2OEngineVersion, AssignH2OEngineVersionAliasesRequest, AssignH2OEngineVersionAliasesResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'icon',
    name: 'Icon',
    fieldName: 'icon',
    minWidth: listIconSize,
    maxWidth: listIconSize,
    onRender: onRenderDAIIconCell,
  },
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 180,
    maxWidth: 180,
    onRender: onRenderDisplayAndIdColumnWithTitle,
  },
  {
    key: 'aliases',
    name: 'Aliases',
    fieldName: 'aliases',
    minWidth: 120,
    maxWidth: 120,
    onRender: onRenderStringArrayCell,
  },
  {
    key: 'deprecated',
    name: 'Deprecated',
    fieldName: 'deprecated',
    minWidth: 150,
    maxWidth: 150,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'image',
    name: 'Image',
    fieldName: 'image',
    minWidth: 200,
    maxWidth: 1000,
    onRender,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<H2OEngineVersion>[] = [
  {
    name: 'name',
    type: EntityFieldType.Semver,
    label: 'Name',
    description: 'H2O Engine Version resource name.',
    required: true,
    minimum: '1.10.4',
    readOnlyOnEdit: true,
  },
  {
    name: 'aliases',
    type: EntityFieldType.LatestAndAliases,
    label: 'Aliases',
    description: `
      Aliases for the specified Driverless AI Engine version.
      For example {"latest", "prerelease"}.`,
    permitActions: [AIEMUserActionType.H2O_ENGINE_VERSION_ASSIGN_ALIASES],
  },
  {
    name: 'deprecated',
    type: EntityFieldType.Boolean,
    label: 'Deprecated',
    description: 'Indicates whether DAIVersion is deprecated.',
  },
  {
    name: 'image',
    type: EntityFieldType.Text,
    label: 'Image',
    description: 'Name of the Docker image used when using this DAIEngineVersion.',
    required: true,
  },
  {
    name: 'imagePullPolicy',
    type: EntityFieldType.SelectEnum,
    label: 'Image pull policy',
    description: `
      Image pull policy applied when using this DAIVersion.
      When unset, server will choose a default one.`,
    options: getOptionsFromEnum(ImagePullPolicy, 'IMAGE_PULL_POLICY_', [ImagePullPolicy.UNSPECIFIED]),
  },
  {
    name: 'imagePullSecrets',
    type: EntityFieldType.StringArray,
    label: 'Image pull secrets',
    description: `
      List of references to k8s secrets that can be used for pulling an image of this DAIEngineVersion
      from a private container image registry or repository.`,
  },
];

export const h2oEngineVersionEntity: Entity<H2OEngineVersion> = {
  type: EntityType.H2OEngineVersion,
  name: 'h2oEngineVersions',
  createButtonLabel: 'Add H2O Engine Version',
  displayName: 'H2O Engine Version',
  displayNameKey: 'name',
  actions,
  columns,
  fields,
  requestNameKeyPrefix: 'workspaces/global/h2oEngineVersions/',
  emptyModel: {
    name: '',
    deprecated: false,
    aliases: [],
    image: '',
    imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
    imagePullSecrets: [],
  },
  canBeReordered: false,
};
