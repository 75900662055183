import { keyframes } from '@fluentui/react';
import { IH2OTheme } from '@h2oai/ui-kit';

export const skeletonLoaderStyles = (theme: IH2OTheme, width: string | number, height: string | number) => ({
  width,
  height,
  backgroundColor: theme.palette?.gray300,
  borderRadius: '4px',
  animationName: pulseAnimation,
  animationDuration: '1.5s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
});

const pulseAnimation = keyframes({
  '0%': { opacity: 1 },
  '50%': { opacity: 0.5 },
  '100%': { opacity: 1 },
});
