// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file aiUnitAlerting/aiUnitAlerting.proto (package aiUnitAlerting, syntax proto3)
/* eslint-disable */

import { RPC } from "../runtime";

/**
 * @generated from message aiUnitAlerting.UpdateAIUnitAlertingRequest
 */
export type UpdateAIUnitAlertingRequest = {
/**
 * @generated from field: int32 hour = 1;
 */
hour?: number;
/**
 * @generated from field: int32 minute = 2;
 */
minute?: number;
/**
 * @generated from field: string time_zone = 3;
 */
timeZone?: string;
/**
 * @generated from field: int32 threshold = 4;
 */
threshold?: number;
/**
 * @generated from field: repeated string users = 5;
 */
users?: string[];
}
;
/**
 * @generated from message aiUnitAlerting.UpdateAIUnitAlertingResponse
 */
export type UpdateAIUnitAlertingResponse = {
/**
 * @generated from field: bool success = 1;
 */
success?: boolean;
}
;
/**
 * @generated from message aiUnitAlerting.GetAIUnitAlertingRequest
 */
export type GetAIUnitAlertingRequest = {
}
;
/**
 * @generated from message aiUnitAlerting.GetAIUnitAlertingResponse
 */
export type GetAIUnitAlertingResponse = {
/**
 * @generated from field: bool success = 1;
 */
success?: boolean;
/**
 * @generated from field: int32 hour = 2;
 */
hour?: number;
/**
 * @generated from field: int32 minute = 3;
 */
minute?: number;
/**
 * @generated from field: string time_zone = 4;
 */
timeZone?: string;
/**
 * @generated from field: int32 threshold = 5;
 */
threshold?: number;
/**
 * @generated from field: repeated string users = 6;
 */
users?: string[];
}
;
/**
 * @generated from rpc aiUnitAlerting.AiUnitAlertingService.UpdateAIUnitAlertingData
 */
export const AiUnitAlertingService_UpdateAIUnitAlertingData = new RPC<UpdateAIUnitAlertingRequest,UpdateAIUnitAlertingResponse>("POST", "/updateAiUnitAlertingData");
/**
 * @generated from rpc aiUnitAlerting.AiUnitAlertingService.GetAIUnitAlertingData
 */
export const AiUnitAlertingService_GetAIUnitAlertingData = new RPC<GetAIUnitAlertingRequest,GetAIUnitAlertingResponse>("GET", "/getAiUnitAlertingData");
