import { Metadata } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/entity_pb';
import { ExperimentParameters } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/experiment_pb';
import { IStyle } from '@fluentui/react';
import { ClassNamesFromIStyles, CodeBlock, IH2OTheme, Pivot, useClassNames, useTheme } from '@h2oai/ui-kit';
import React from 'react';

interface IModelMetadataStyles {
  container: IStyle;
  metadata: IStyle;
  schemaTable: IStyle;
}
export const modelMetadataStyles = (theme: IH2OTheme): Partial<IModelMetadataStyles> => ({
  container: {
    padding: 20,
    borderRadius: 10,
  },
  metadata: {
    marginBottom: 20,
  },
  schemaTable: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: 20,
    '& th, & td': {
      border: `1px solid ${theme.semanticColors?.inputBorder}`,
      padding: 8,
      textAlign: 'left',
    },
    '& th': {
      // TODO: Theme this.
      backgroundColor: '#f4f4f4',
    },
    '& tr:hover': {
      // TODO: Theme this.
      backgroundColor: '#f9f9f9',
    },
  },
});

const snakeToText = (snakeStr: string) => {
    return snakeStr
      .split('_') // Split by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join(' '); // Join words with spaces
  },
  flexStyles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  fillStyles = {
    pivotContainerStyles: {
      root: {
        '.ms-Pivot-wrapper': flexStyles,
        ...flexStyles,
      },
    },
    styles: {
      itemContainer: {
        '> div': flexStyles,
        ...flexStyles,
      },
      root: {
        margin: '0px 20px',
      },
    },
  };

const ModelMetadataView = ({ metadata, parameters }: { metadata?: Metadata; parameters?: ExperimentParameters }) => {
  const theme = useTheme(),
    classNames = useClassNames<IModelMetadataStyles, ClassNamesFromIStyles<IModelMetadataStyles>>(
      'modelMetadata',
      modelMetadataStyles(theme)
    ),
    [selectedKey, setSelectedKey] = React.useState<'0' | '1'>('0'),
    getParsedJson = (jsonString: string) => {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
        return null;
      }
    };

  return (
    <Pivot
      {...fillStyles}
      onLinkClick={(item) => setSelectedKey((item?.props?.itemKey as '0' | '1') || '0')}
      selectedKey={selectedKey}
      placeholder={undefined}
      items={[
        {
          headerText: 'Parameters',
          content: (
            <div className={classNames.container}>
              {parameters ? (
                <table className={classNames.schemaTable}>
                  <thead>
                    <tr>
                      <th>Parameter</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(parameters).map(([key, value]) => (
                      <tr key={key}>
                        <td>{snakeToText(key)}</td>
                        <td>{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No parameters to display.</p>
              )}
            </div>
          ),
        },
        {
          headerText: 'Metadata',
          content: (
            <div className={classNames.container}>
              <div className={classNames.metadata}>
                {metadata ? (
                  <>
                    <>
                      <table className={classNames.schemaTable}>
                        <thead>
                          <tr>
                            <th>Metadata key</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(metadata?.values)
                            ?.filter((value) => value?.[1].value?.case === 'stringValue')
                            .map((value) => (
                              <tr key={value?.[0]}>
                                <td>{snakeToText(value?.[0])}</td>
                                <td>{value?.[1].value?.value}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                    {Object.entries(metadata?.values)
                      ?.filter((value) => value?.[1].value?.case === 'jsonValue')
                      .map((value) => {
                        if (value?.[1].value?.case === 'jsonValue') {
                          const parsedJson = getParsedJson(value?.[1].value?.value);
                          if (
                            Object.values(parsedJson?.[0]).length === 2 &&
                            parsedJson?.[0]?.name &&
                            parsedJson?.[0]?.type
                          ) {
                            return (
                              <>
                                <h3>{snakeToText(value?.[0])}</h3>
                                <table className={classNames.schemaTable}>
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Type</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {parsedJson
                                      ? parsedJson?.map((field: { name: string; type: string }, index: number) => (
                                          <tr key={index}>
                                            <td>{field.name}</td>
                                            <td>{field.type}</td>
                                          </tr>
                                        ))
                                      : null}
                                  </tbody>
                                </table>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <h3>{snakeToText(value?.[0])}</h3>
                                <CodeBlock showCopyButton>{JSON.stringify(parsedJson)}</CodeBlock>
                              </>
                            );
                          }
                        }
                        return null;
                      })}
                  </>
                ) : (
                  <p>No metadata do display.</p>
                )}
              </div>
            </div>
          ),
        },
      ]}
    />
  );
};

export default ModelMetadataView;
