// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/h2o_engine_version_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { H2OEngineVersion } from "./h2o_engine_version_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message Create method.
 *
 * @generated from message ai.h2o.engine.v1.CreateH2OEngineVersionRequest
 */
export type CreateH2OEngineVersionRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the h2oEngineVersion's parent workspace:
 * * actions/enginemanager/h2oEngineVersions/CREATE
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The H2OEngineVersion resource to create.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineVersion h2o_engine_version = 2;
 */
h2oEngineVersion: H2OEngineVersion;
/**
 * Specify the H2OEngineVersion ID, which will become a part of the H2OEngineVersion
 * resource name.
 *
 * It must:
 * - be in semver format (more segments than three segments allowed)
 * - contain max 63 characters
 *
 * Examples: "1.10.3", "1.10.3-alpha", "1.10.3.2", "1.10.3.2-alpha"
 *
 * @generated from field: string h2o_engine_version_id = 3;
 */
h2oEngineVersionId: string;
}
;
/**
 * Response message for Create method.
 *
 * @generated from message ai.h2o.engine.v1.CreateH2OEngineVersionResponse
 */
export type CreateH2OEngineVersionResponse = {
/**
 * Created H2OEngineVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineVersion h2o_engine_version = 1;
 */
h2oEngineVersion?: H2OEngineVersion;
}
;
/**
 * Request message for Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetH2OEngineVersionRequest
 */
export type GetH2OEngineVersionRequest = {
/**
 * H2OEngineVersion resource name.
 * Format: workspaces/{workspace}/h2oEngineVersions/{h2o_engine_version}
 *
 * Authorization may require the following permission on the h2oEngineVersion's parent workspace:
 * * actions/enginemanager/h2oEngineVersions/GET
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetH2OEngineVersionResponse
 */
export type GetH2OEngineVersionResponse = {
/**
 * H2OEngineVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineVersion h2o_engine_version = 1;
 */
h2oEngineVersion?: H2OEngineVersion;
}
;
/**
 * Request message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListH2OEngineVersionsRequest
 */
export type ListH2OEngineVersionsRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the h2oEngineVersion's parent workspace:
 * * actions/enginemanager/h2oEngineVersions/LIST
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of H2OEngineVersions to return in a response.
 * If unspecified (or set to 0), at most 50 H2OEngineVersions will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListH2OEngineVersionsResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListH2OEngineVersionsResponse
 */
export type ListH2OEngineVersionsResponse = {
/**
 * Collection of H2OEngineVersions.
 *
 * @generated from field: repeated ai.h2o.engine.v1.H2OEngineVersion h2o_engine_versions = 1;
 */
h2oEngineVersions?: H2OEngineVersion[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for Update method.
 *
 * @generated from message ai.h2o.engine.v1.UpdateH2OEngineVersionRequest
 */
export type UpdateH2OEngineVersionRequest = {
/**
 * Required. H2OEngineVersion resource.
 *
 * Authorization may require the following permission on the h2oEngineVersion's parent workspace:
 * * actions/enginemanager/h2oEngineVersions/UPDATE
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineVersion h2o_engine_version = 1;
 */
h2oEngineVersion: H2OEngineVersion;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are:
 * - deprecated
 * - image
 * - image_pull_policy
 * - image_pull_secrets
 *
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response for Update method.
 *
 * @generated from message ai.h2o.engine.v1.UpdateH2OEngineVersionResponse
 */
export type UpdateH2OEngineVersionResponse = {
/**
 * H2OEngineVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineVersion h2o_engine_version = 1;
 */
h2oEngineVersion?: H2OEngineVersion;
}
;
/**
 * Request message for Delete method.
 *
 * @generated from message ai.h2o.engine.v1.DeleteH2OEngineVersionRequest
 */
export type DeleteH2OEngineVersionRequest = {
/**
 * H2OEngineVersion resource name.
 * Format: workspaces/{workspace}/h2oEngineVersions/{h2o_engine_version}
 *
 * Authorization may require the following permission on the h2oEngineVersion's parent workspace:
 * * actions/enginemanager/h2oEngineVersions/DELETE
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for Delete method.
 *
 * @generated from message ai.h2o.engine.v1.DeleteH2OEngineVersionResponse
 */
export type DeleteH2OEngineVersionResponse = {
}
;
/**
 * Request message.
 *
 * @generated from message ai.h2o.engine.v1.AssignH2OEngineVersionAliasesRequest
 */
export type AssignH2OEngineVersionAliasesRequest = {
/**
 * H2OEngineVersion resource name.
 * Format: workspaces/{workspace}/h2oEngineVersions/{h2o_engine_version}
 *
 * Authorization may require the following permission on the h2oEngineVersion's parent workspace:
 * * actions/enginemanager/h2oEngineVersions/ASSIGN_ALIASES
 *
 * @generated from field: string name = 1;
 */
name: string;
/**
 * New resourceID aliases of the H2OEngineVersion.
 *
 * @generated from field: repeated string aliases = 2;
 */
aliases: string[];
}
;
/**
 * Response message.
 *
 * @generated from message ai.h2o.engine.v1.AssignH2OEngineVersionAliasesResponse
 */
export type AssignH2OEngineVersionAliasesResponse = {
/**
 * All H2OEngineVersions with updated resourceID aliases.
 *
 * @generated from field: repeated ai.h2o.engine.v1.H2OEngineVersion h2o_engine_versions = 1;
 */
h2oEngineVersions?: H2OEngineVersion[];
}
;
/**
 * Creates a new H2OEngineVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineVersionService.CreateH2OEngineVersion
 */
export const H2OEngineVersionService_CreateH2OEngineVersion = new RPC<CreateH2OEngineVersionRequest,CreateH2OEngineVersionResponse>("POST", "/v1/{parent=workspaces/*}/h2oEngineVersions", "h2oEngineVersion");
/**
 * Get H2OEngineVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineVersionService.GetH2OEngineVersion
 */
export const H2OEngineVersionService_GetH2OEngineVersion = new RPC<GetH2OEngineVersionRequest,GetH2OEngineVersionResponse>("GET", "/v1/{name=workspaces/*/h2oEngineVersions/*}");
/**
 * Returns a collection of H2OEngineVersions.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineVersionService.ListH2OEngineVersions
 */
export const H2OEngineVersionService_ListH2OEngineVersions = new RPC<ListH2OEngineVersionsRequest,ListH2OEngineVersionsResponse>("GET", "/v1/{parent=workspaces/*}/h2oEngineVersions");
/**
 * Updates a H2OEngineVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineVersionService.UpdateH2OEngineVersion
 */
export const H2OEngineVersionService_UpdateH2OEngineVersion = new RPC<UpdateH2OEngineVersionRequest,UpdateH2OEngineVersionResponse>("PATCH", "/v1/{h2oEngineVersion.name=workspaces/*/h2oEngineVersions/*}", "h2oEngineVersion");
/**
 * Deletes a H2OEngineVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineVersionService.DeleteH2OEngineVersion
 */
export const H2OEngineVersionService_DeleteH2OEngineVersion = new RPC<DeleteH2OEngineVersionRequest,DeleteH2OEngineVersionResponse>("DELETE", "/v1/{name=workspaces/*/h2oEngineVersions/*}");
/**
 * Assign new set of resourceID aliases to H2OEngineVersion.
 * This will replace existing H2OEngineVersion resourceID aliases with the new aliases.
 * If there are other H2OEngineVersions with the same alias that we try to assign to this H2OEngineVersion,
 * they will be deleted from the other H2OEngineVersions.
 *
 * Example 1 - two versions in the same workspace:
 * - h2ow1v1(name="workspaces/w1/h2oEngineVersions/v1", aliases=["latest", "bar"])
 * - h2ow1v2(name="workspaces/w1/h2oEngineVersions/v2", aliases=["baz", "foo"])
 * - AssignAliases(h2ow1v1, aliases=["latest", "baz"])
 * => h2ow1v1.aliases=["latest", "baz"] (changed)
 * => h2ow1v2.aliases=["foo"] (changed)
 *
 * Example 2 - two versions in different workspaces:
 * - h2ow1v1(name="workspaces/w1/h2oEngineVersions/v1", aliases=["latest", "bar"])
 * - h2ow2v1(name="workspaces/w2/h2oEngineVersions/v1", aliases=["latest", "baz"])
 * - AssignAliases(h2ow1v1, aliases=["latest", "baz"])
 * => h2ow1v1.aliases=["latest", "baz"] (changed)
 * => h2ow2v1.aliases=["latest", "baz"] (unchanged)
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineVersionService.AssignH2OEngineVersionAliases
 */
export const H2OEngineVersionService_AssignH2OEngineVersionAliases = new RPC<AssignH2OEngineVersionAliasesRequest,AssignH2OEngineVersionAliasesResponse>("POST", "/v1/{name=workspaces/*/h2oEngineVersions/*}:assignAliases");
