import { IH2OTheme } from '@h2oai/ui-kit';

import { resolveColor } from '../../../utils/utils';

export function getDummyList<T>(length: number, defaultValue?: T): T[] {
  if (defaultValue === undefined) {
    const inferredDefault = {} as Partial<T>;

    return Array.from({ length }, () => inferredDefault as T);
  }

  return Array.from({ length }, () => ({ ...defaultValue }));
}

export function getAiUnitComponentColor(theme: IH2OTheme, component: string, defaultColor: string): string {
  const colorMap: Record<string, string> = {
    'Document AI': theme.palette?.blue300 || defaultColor,
    h2oGPT: theme.palette?.green300 || defaultColor,
    'h2ogpte-playground': theme.palette?.red300 || defaultColor,
    MLOps: theme.palette?.red500 || defaultColor,
    'Document.ai': theme.palette?.red600 || defaultColor,
    'AIEM - DAI\\H2O-3': theme.palette?.yellow500 || defaultColor,
    'App-Store': theme.palette?.green600 || defaultColor,
    Steam: theme.palette?.yellow600 || defaultColor,
    'ML-API': theme.palette?.yellow400 || defaultColor,
    eScorer: theme.palette?.blue700 || defaultColor,
    'MLOps Deployments': theme.palette?.blue500 || defaultColor,
    'Notebook Workload': theme.palette?.blue900 || defaultColor,
    'AppStore Applications': theme.palette?.red900 || defaultColor,
  };

  return resolveColor(colorMap[component] || theme.palette?.gray300, defaultColor);
}

export function getGaugeMetricComponentColor(theme: IH2OTheme, component: string, defaultColor: string): string {
  const colorMap: Record<string, string> = {
    Users: theme.palette?.blue300 || defaultColor,
    Projects: theme.palette?.green300 || defaultColor,
    Datasets: theme.palette?.red300 || defaultColor,
    MLOps: theme.palette?.yellow500 || defaultColor,
    Experiments: theme.palette?.red600 || defaultColor,
    'Registered Models': theme.palette?.green500 || defaultColor,
    'Registered Model Versions': theme.palette?.blue500 || defaultColor,
    Deployments: theme.palette?.yellow700 || defaultColor,
  };

  return resolveColor(colorMap[component] || theme.palette?.gray300, defaultColor);
}
