import { IH2OTheme, Sizes } from '@h2oai/ui-kit';

export const fwManagementTopStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const fwTableOptionStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const fwTypeAreaStyle = {
  display: 'flex',
  marginTop: '20px',
};

export const sidePanelStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
};

export const sidePanelFooterStyle = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'flex-end',
};

export const titleAreaStyle = {
  width: '20%',
};

export const tableAreaStyle = {
  width: '80%',
};

export const titleStyle = {
  marginBottom: '0px',
};

export const tableStyle = {
  marginTop: '10px',
};

export const saveChangeButtonStyle = {
  marginLeft: '10px',
};

export const pageTopButtonStyle: React.CSSProperties = {
  position: 'relative',
  paddingBottom: Sizes.buttonPaddingTopBottom,
};

export const deleteButtonStyle = {
  display: 'flex',
  gap: '70px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '30px',
};

export const TableBackgroundStyle = (theme: IH2OTheme) => ({
  backgroundColor: theme.palette?.white,
  padding: Sizes.padding,
  height: '400px',
  overflowY: 'auto' as const,
});
