import { Project } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/project_pb';
import { IStyle } from '@fluentui/react';
import {
  Button,
  ConfirmDialog,
  IH2OTheme,
  Item,
  TextField,
  buttonStylesStealth,
  itemStylesTag,
  useClassNames,
  useTheme,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';
import WidgetList from '../../components/WidgetList/WidgetList';
import { ClassNamesFromIStyles } from '../../utils/models';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

type ProjectItem = Project & {
  users: string[];
  onClickSetDefault: () => void;
  onClickHeader: () => void;
  isActive: boolean;
  createdTimeString: string;
};

interface IProjectsStyles {
  wrapper: IStyle;
  content: IStyle;
  title: IStyle;
  description: IStyle;
  listWrapper: IStyle;
  titleContainer: IStyle;
}

const projectsStyles = (theme: IH2OTheme): Partial<IProjectsStyles> => ({
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.semanticColors?.bodyBackground,
  },
  content: {
    backgroundColor: theme.semanticColors?.contentBackground,
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
    borderRadius: 8,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth: 800,
    padding: 30,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 99,
  },
  title: {
    marginBottom: 0,
    marginTop: 20,
    animation: 'title-appearance 3s',
  },
  description: {
    color: theme.semanticColors?.textSecondary,
    animation: 'description-appearance 3s',
    animationDelay: '2s',
    animationFillMode: 'both',
  },
  listWrapper: {
    height: 350,
    width: 820,
    overflow: 'auto',
  },
});

const columns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'description',
    minWidth: 200,
    maxWidth: 800,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName, onClickHeader }: ProjectItem) =>
          RowHeaderTitle({ title: displayName, onClick: onClickHeader }),
        iconProps: {
          iconName: 'FolderHorizontal',
        },
      },
    },
  },
  {
    key: 'users',
    name: 'Users',
    fieldName: 'ownerId',
    minWidth: 150,
    maxWidth: 220,
  },
  {
    key: 'createdAt',
    name: 'Created at',
    fieldName: 'createdTimeString',
    minWidth: 150,
    maxWidth: 250,
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No Description',
        onRenderText: ({ onClickSetDefault, isActive }: ProjectItem) => (
          <>
            {!isActive ? (
              <Button onClick={onClickSetDefault} styles={buttonStylesStealth}>
                Set as default
              </Button>
            ) : (
              <Item
                data={{
                  style: {
                    backgroundColor: 'transparent',
                    padding: '0px 20px',
                    fontSize: 14,
                  },
                  title: 'Active',
                }}
                labelField="title"
                styleField="style"
                styles={itemStylesTag}
              />
            )}
          </>
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
            ':has(.h2o-Item-root)': {
              // TODO: Use theme color.
              backgroundImage: 'linear-gradient(to right, white , var(--h2o-green100, #D0F5D1))',
              borderRadius: 2,
            },
          },
        },
      },
    },
  },
];

const Projects: React.FC = () => {
  const {
      projects,
      ACTIVE_PROJECT_ID,
      activateProject,
      loading,
      fetchProjects,
      createProject,
      onLoadMore,
      isLoadingMore,
      isLoadingSearch,
    } = useProjects(),
    theme = useTheme(),
    classNames = useClassNames<IProjectsStyles, ClassNamesFromIStyles<IProjectsStyles>>(
      'projects',
      projectsStyles(theme)
    ),
    history = useHistory(),
    [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false),
    [projectName, setProjectName] = React.useState(''),
    [projectDescription, setProjectDescription] = React.useState(''),
    [showValidation, setShowValidation] = React.useState(false),
    closeDeleteDialog = () => {
      setProjectName('');
      setProjectDescription('');
      setShowValidation(false);
      setIsCreateDialogOpen(false);
    },
    dialogAction = () => {
      if (!projectName) {
        setShowValidation(true);
        return;
      }
      void createProject(projectName, projectDescription);
      closeDeleteDialog();
    },
    items: ProjectItem[] | undefined = React.useMemo(
      () =>
        projects?.map(
          (project) =>
            ({
              ...project,
              // TODO: Show list of users from authz when implementing access control.
              users: ['User'],
              onClickSetDefault: () => activateProject(project.id || ''),
              onClickHeader: () => {
                if (project.id !== ACTIVE_PROJECT_ID) {
                  activateProject(project.id || '', true);
                } else {
                  history.push(`/mlops/projects/${project.id}`);
                }
              },
              isActive: project.id === ACTIVE_PROJECT_ID,
              createdTimeString: new Date(Number(project.createdTime?.seconds) || 0).toLocaleString(),
            } as ProjectItem)
        ),
      [projects, activateProject, theme, ACTIVE_PROJECT_ID]
    ),
    onAction = () => setIsCreateDialogOpen(true),
    widgetListProps = {
      columns,
      items,
      loading: !!loading,
      delayLoader: false,
      isLoadingMore: isLoadingMore,
      onLoadMore,
      isLoadingSearch,
      searchProps: {
        placeholder: 'Search projects',
        // TODO: Check if "value" is correct.
        onSearchChange: (value: string) => void fetchProjects(undefined, value),
      },
      actionProps: {
        actionIcon: 'Add',
        actionTitle: 'Create project',
        onActionClick: onAction,
      },
      NoItemsContent: NoItemView({
        title: 'No projects',
        description: 'There are no projects available to select from. Create the first one to begin.',
        actionTitle: 'Create project',
        onActionClick: onAction,
        actionIcon: 'Add',
      }),
      ErrorContent: FailedToLoadView({
        title: 'Failed to load projects',
        description: 'Please try again later. If the problem persists, contact our support.',
        actionTitle: 'Retry',
        onActionClick: fetchProjects,
        actionIcon: 'Refresh',
      }),
    };

  return (
    <PageWrapper>
      <ConfirmDialog
        title="Create project"
        hidden={!isCreateDialogOpen}
        onConfirm={dialogAction!}
        onDismiss={closeDeleteDialog}
        confirmationButtonText="Create"
        dismissalButtonText="Cancel"
        modalProps={{ isBlocking: false }}
        content={
          <>
            <TextField
              label="Project name"
              value={projectName}
              onChange={(_e, newValue) => setProjectName(newValue || '')}
              required
              errorMessage={showValidation && !projectName ? 'Please enter a project name' : undefined}
            />
            <TextField
              label="Project description"
              value={projectDescription}
              onChange={(_e, newValue) => setProjectDescription(newValue || '')}
            />
          </>
        }
      />
      {!ACTIVE_PROJECT_ID ? (
        <div className={classNames.wrapper}>
          <div className={classNames.content}>
            <div className={classNames.titleContainer}>
              {!loading || isLoadingSearch ? (
                <>
                  <h1 className={classNames.title}>Welcome to MLOps</h1>
                  <p className={classNames.description}>Please select or create a project to begin.</p>
                </>
              ) : null}
            </div>
            <div className={classNames.listWrapper}>
              <WidgetList {...widgetListProps} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Header customPageTitle="Projects" />
          <WidgetList {...widgetListProps} />
        </>
      )}
    </PageWrapper>
  );
};

export default Projects;
