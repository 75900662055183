import { Icon, Stack, Text } from '@fluentui/react';
import { ConfirmDialog, IconName, Sizes, useTheme } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { AIEngine } from '../../../../aiem/engine/types';
import type { ProfileConstraintNumeric } from '../../../../aiem/gen/ai/h2o/engine/v1/profile_constraint_pb';
import { BigIntString } from '../../../../aiem/gen/runtime';
import { useEngineProfilesService } from '../../../../aiem/hooks';
import { bytesToGibibytes, gibibytesToBigIntStringBytes } from '../../../../aiem/utils';
import { generateTooltipMessage } from '../EngineConfiguration/components/ConfigureCustomEngineProfile';
import SpinnerWithTooltip from '../SpinnerWithTooltip/SpinnerWithTooltip';

export type EngineResizeDialogProps = {
  engine?: AIEngine | null;
  admin?: boolean;
  hidden: boolean;
  onConfirm: (newSize: BigIntString) => unknown;
  onDismiss: () => unknown;
};

export const EngineResizeDialog = ({ engine, hidden, onConfirm, onDismiss, admin }: EngineResizeDialogProps) => {
  const engineProfilesService = useEngineProfilesService();
  const theme = useTheme(),
    [constraintSet, setConstraintSet] = useState<ProfileConstraintNumeric>(),
    [minValue, setMinValue] = useState<number>(),
    [maxValue, setMaxValue] = useState<number>(),
    [newValue, setNewValue] = useState<number>(0),
    [maxStorageAlready, setMaxStorageAlready] = useState<boolean>(),
    title = `Resize engine ${engine?.displayName} `,
    warningMessage = maxStorageAlready
      ? `The engine is already at the maximum size. If additional sizing is necessary, contact your administrator`
      : `Storage may only be increased. This may take several hours to complete. You may continue using the engine
            when the storage is resizing. Once the resizing is complete you may need to restart your engine. Refrain
            from resizing your engine more than once a day.`,
    getEngineProfile = async () => {
      if (!engine?.profile) return;
      try {
        const profile = await engineProfilesService.getDAIEngineProfile({ name: engine?.profile });
        const constraintSet = profile.daiEngineProfile?.storageBytesConstraint;

        setConstraintSet(constraintSet);
        setNewValue(parseInt(bytesToGibibytes(engine?.storageBytes) || '0'));
        setMinValue(parseInt(bytesToGibibytes(engine?.storageBytes) || '0'));
        setMaxValue(admin ? Infinity : parseInt(bytesToGibibytes(constraintSet?.max) || 'Infinity'));
        setMaxStorageAlready(!admin && engine.storageBytes === constraintSet?.max);
      } catch (error: any) {
        console.error(error);
      }
    },
    clearState = () => {
      setTimeout(() => {
        // put this in a timeout to prevent jumpiness just before closing
        setConstraintSet(undefined);
        setMinValue(undefined);
        setMaxValue(undefined);
        setNewValue(0);
        setMaxStorageAlready(false);
      }, 300);
    },
    handleConfirm = () => {
      const newGibibytes = gibibytesToBigIntStringBytes(newValue);
      if (!newGibibytes) {
        onDismiss();
        return;
      }
      onConfirm(newGibibytes);
      clearState();
    },
    handleDismiss = () => {
      setConstraintSet(undefined);
      onDismiss();
      clearState();
    },
    content = (
      <Stack>
        <Text
          style={{
            background: theme.semanticColors?.messageBarBackgroundWarning,
            borderRadius: `${Sizes.borderRadius}px`,
            padding: `0 ${Sizes.padding}px`,
            marginBottom: `${Sizes.cardGap}px`,
            font: `14px/20px Inter`,
          }}
        >
          <p style={{ color: theme.palette?.yellow900 }}>
            <Icon
              iconName={IconName.WarningSolid}
              style={{ float: 'left', margin: '10px 12px 5px 0', fontSize: '2rem' }}
            />
            {warningMessage}
          </p>
        </Text>
        {maxStorageAlready ? null : (
          <Stack horizontal>
            <span style={{ lineHeight: '2rem', margin: '0 10px 0 0', fontWeight: '700' }}>Storage</span>
            <SpinnerWithTooltip
              disabled={!constraintSet}
              loading={!constraintSet}
              min={minValue}
              max={maxValue}
              tooltip={generateTooltipMessage('GiB', minValue, maxValue)}
              onChange={(_: any, value: number) => {
                setNewValue(value);
              }}
              value={newValue}
              suffix="GiB"
            />
          </Stack>
        )}
      </Stack>
    );

  useEffect(() => {
    if (!hidden && !constraintSet && engine?.name && engine?.engineType) void getEngineProfile();
  }, [hidden, engine]);
  return (
    <ConfirmDialog
      confirmationButtonText={'Resize'}
      hidden={hidden}
      content={content}
      title={title}
      onConfirm={handleConfirm}
      onDismiss={handleDismiss}
      confirmationButtonDisabled={
        !constraintSet || maxStorageAlready || newValue === parseInt(bytesToGibibytes(engine?.storageBytes) || '0')
      }
    />
  );
};
