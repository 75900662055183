// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/h2o_engine.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { ProfileConstraintDuration, ProfileConstraintNumeric } from "./profile_constraint_pb";
import type { ImagePullPolicy } from "./base_types_pb";
import type { BigIntString } from "../../../../runtime";

/**
 * The original H2OEngineProfile data used by H2OEngine when using the H2OEngineProfile.
 * For more info about each field see the original H2OEngineProfile resource.
 *
 * @generated from message ai.h2o.engine.v1.H2OEngineProfileInfo
 */
export type H2OEngineProfileInfo = {
/**
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * @generated from field: string display_name = 2;
 */
displayName?: string;
/**
 * @generated from field: int32 priority = 3;
 */
priority?: number;
/**
 * @generated from field: bool enabled = 4;
 */
enabled?: boolean;
/**
 * @generated from field: bool assigned_oidc_roles_enabled = 5;
 */
assignedOidcRolesEnabled?: boolean;
/**
 * @generated from field: repeated string assigned_oidc_roles = 6;
 */
assignedOidcRoles?: string[];
/**
 * @generated from field: optional int32 max_running_engines = 7;
 */
maxRunningEngines?: number;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric node_count_constraint = 8;
 */
nodeCountConstraint?: ProfileConstraintNumeric;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric cpu_constraint = 9;
 */
cpuConstraint?: ProfileConstraintNumeric;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric gpu_constraint = 10;
 */
gpuConstraint?: ProfileConstraintNumeric;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric memory_bytes_constraint = 11;
 */
memoryBytesConstraint?: ProfileConstraintNumeric;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintDuration max_idle_duration_constraint = 12;
 */
maxIdleDurationConstraint?: ProfileConstraintDuration;
/**
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintDuration max_running_duration_constraint = 13;
 */
maxRunningDurationConstraint?: ProfileConstraintDuration;
/**
 * @generated from field: string yaml_pod_template_spec = 14;
 */
yamlPodTemplateSpec?: string;
/**
 * @generated from field: string yaml_gpu_tolerations = 15;
 */
yamlGpuTolerations?: string;
/**
 * @generated from field: google.protobuf.Timestamp create_time = 16;
 */
createTime?: string;
/**
 * @generated from field: optional google.protobuf.Timestamp update_time = 17;
 */
updateTime?: string;
/**
 * @generated from field: string creator = 18;
 */
creator?: string;
/**
 * @generated from field: string updater = 19;
 */
updater?: string;
/**
 * @generated from field: string creator_display_name = 20;
 */
creatorDisplayName?: string;
/**
 * @generated from field: string updater_display_name = 21;
 */
updaterDisplayName?: string;
/**
 * @generated from field: string gpu_resource_name = 22;
 */
gpuResourceName?: string;
}
;
/**
 * The original H2OEngineVersion data used by H2OEngine when using the H2OEngineVersion.
 * For more info about each field see the original H2OEngineVersion resource.
 *
 * @generated from message ai.h2o.engine.v1.H2OEngineVersionInfo
 */
export type H2OEngineVersionInfo = {
/**
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * @generated from field: bool deprecated = 2;
 */
deprecated?: boolean;
/**
 * @generated from field: repeated string aliases = 3;
 */
aliases?: string[];
/**
 * @generated from field: string image = 4;
 */
image?: string;
/**
 * @generated from field: ai.h2o.engine.v1.ImagePullPolicy image_pull_policy = 5;
 */
imagePullPolicy?: ImagePullPolicy;
/**
 * @generated from field: repeated string image_pull_secrets = 6;
 */
imagePullSecrets?: string[];
/**
 * @generated from field: google.protobuf.Timestamp create_time = 7;
 */
createTime?: string;
/**
 * @generated from field: optional google.protobuf.Timestamp update_time = 8;
 */
updateTime?: string;
/**
 * @generated from field: string creator = 9;
 */
creator?: string;
/**
 * @generated from field: string updater = 10;
 */
updater?: string;
/**
 * @generated from field: string creator_display_name = 11;
 */
creatorDisplayName?: string;
/**
 * @generated from field: string updater_display_name = 12;
 */
updaterDisplayName?: string;
}
;
/**
 * Message representing H2O AI engine.
 * (-- api-linter: core::0164::declarative-friendly-required=disabled
 *     aip.dev/not-precedent: Not adding Undelete method because it requires
 *     changes on generic Engine and H2OEngine as well. Undelete method should
 *     be added later. --)
 *
 * @generated from message ai.h2o.engine.v1.H2OEngine
 */
export type H2OEngine = {
/**
 * H2OEngine resource name.
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Globally unique identifier of the resource.
 *
 * @generated from field: string uid = 2;
 */
uid?: string;
/**
 * Name of na entity that created the H2OEngine.
 *
 * @generated from field: string creator = 3;
 */
creator?: string;
/**
 * Human-readable name of creator.
 *
 * @generated from field: string creator_display_name = 4;
 */
creatorDisplayName?: string;
/**
 * Required. The resource name of the H2OEngineProfile that is used by this H2OEngine.
 * Format is `workspaces/*\/h2oEngineProfiles/*`.
 *
 * @generated from field: string profile = 5;
 */
profile: string;
/**
 * Output only. H2OEngineProfile data used by the H2OEngine from the assigned profile.
 *
 * @generated from field: optional ai.h2o.engine.v1.H2OEngineProfileInfo profile_info = 6;
 */
profileInfo?: H2OEngineProfileInfo;
/**
 * The current state of the H2OEngine.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngine.State state = 10;
 */
state?: H2OEngine_State;
/**
 * Indicates whether changes to the resource are in progress.
 * If true, current state of the H2OEngine does not match the intended state.
 *
 * @generated from field: bool reconciling = 11;
 */
reconciling?: boolean;
/**
 * Time when the H2OEngine creation was requested.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 21;
 */
createTime?: string;
/**
 * Time when the H2OEngine was deleted.
 *
 * @generated from field: optional google.protobuf.Timestamp delete_time = 22;
 */
deleteTime?: string;
/**
 * Time when the H2OEngine was last updated.
 *
 * @generated from field: optional google.protobuf.Timestamp update_time = 23;
 */
updateTime?: string;
/**
 * The URL address to access the engine API.
 *
 * @generated from field: string api_url = 25;
 */
apiUrl?: string;
/**
 * The URL address to initiate login flow.
 *
 * @generated from field: string login_url = 26;
 */
loginUrl?: string;
/**
 * Additional arbitrary metadata associated with the H2OEngine.
 *
 * Annotations are key/value pairs.
 * The key must:
 * - be 63 characters or less
 * - begin and end with an alphanumeric character ([a-z0-9A-Z])
 * - with dashes (-), underscores (_), dots (.), and alphanumerics between
 * - regex used for validation is: ^[A-Za-z0-9]([-A-Za-z0-9_.]{0,61}[A-Za-z0-9])?$
 *
 * @generated from field: map<string, string> annotations = 100;
 */
annotations?: { [key: string]: string };
/**
 * Human-readable name of the H2OEngine. Must contain at most 63 characters. Does not have to be unique.
 *
 * @generated from field: string display_name = 101;
 */
displayName?: string;
/**
 * The amount of CPU units per node requested by this H2OEngine.
 *
 * @generated from field: optional int32 cpu = 103;
 */
cpu?: number;
/**
 * The amount of GPU units per node requested by this H2OEngine.
 *
 * @generated from field: optional int32 gpu = 104;
 */
gpu?: number;
/**
 * The amount of memory in bytes per node requested by this H2OEngine.
 *
 * @generated from field: optional int64 memory_bytes = 105;
 */
memoryBytes?: BigIntString;
/**
 * Maximum time an H2OEngine can be idle. When exceeded, the H2OEngine will terminate.
 *
 * @generated from field: optional google.protobuf.Duration max_idle_duration = 106;
 */
maxIdleDuration?: string | null;
/**
 * Maximum time an H2OEngine can be running. When exceeded, the H2OEngine will terminate.
 *
 * @generated from field: optional google.protobuf.Duration max_running_duration = 107;
 */
maxRunningDuration?: string | null;
/**
 * Current time the H2OEngine is idle.
 *
 * @generated from field: optional google.protobuf.Duration current_idle_duration = 108;
 */
currentIdleDuration?: string;
/**
 * Current time the H2OEngine is running.
 *
 * @generated from field: optional google.protobuf.Duration current_running_duration = 109;
 */
currentRunningDuration?: string;
/**
 * The number of nodes requested by this H2OEngine.
 *
 * @generated from field: optional int32 node_count = 110;
 */
nodeCount?: number;
/**
 * Required. Immutable. H2OEngineVersion assigned to H2OEngine.
 * Format: workspaces/*\/h2oEngineVersions/*
 *
 * @generated from field: string h2o_engine_version = 113;
 */
h2oEngineVersion: string;
/**
 * Output only. H2OEngineVersion data used during the last H2OEngine startup from the assigned h2o_engine_version.
 *
 * @generated from field: optional ai.h2o.engine.v1.H2OEngineVersionInfo h2o_engine_version_info = 114;
 */
h2oEngineVersionInfo?: H2OEngineVersionInfo;
}
;
/**
 * All possible H2OEngine states.
 *
 * @generated from enum ai.h2o.engine.v1.H2OEngine.State
 */
export enum H2OEngine_State {
/**
 * H2OEngine state is unspecified or unknown.
 *
 * @generated from enum value: STATE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "STATE_UNSPECIFIED",

/**
 * H2OEngine is starting.
 *
 * @generated from enum value: STATE_STARTING = 1;
 */
STARTING = "STATE_STARTING",

/**
 * H2OEngine is running and can be used.
 *
 * @generated from enum value: STATE_RUNNING = 2;
 */
RUNNING = "STATE_RUNNING",

/**
 * H2OEngine is pausing. Deprecated, use STATE_TERMINATING.
 *
 * @generated from enum value: STATE_PAUSING = 3;
 */
PAUSING = "STATE_PAUSING",

/**
 * H2OEngine is paused and CANNOT be resumed again. Deprecated, use STATE_TERMINATED.
 *
 * @generated from enum value: STATE_PAUSED = 4;
 */
PAUSED = "STATE_PAUSED",

/**
 * H2OEngine has failed.
 *
 * @generated from enum value: STATE_FAILED = 5;
 */
FAILED = "STATE_FAILED",

/**
 * H2OEngine is being deleted.
 *
 * @generated from enum value: STATE_DELETING = 6;
 */
DELETING = "STATE_DELETING",

/**
 * H2OEngine is establishing a connection with the AI Engine Manager.
 *
 * @generated from enum value: STATE_CONNECTING = 7;
 */
CONNECTING = "STATE_CONNECTING",

/**
 * H2OEngine is terminating.
 *
 * @generated from enum value: STATE_TERMINATING = 8;
 */
TERMINATING = "STATE_TERMINATING",

/**
 * H2OEngine is terminated. This is a final state.
 *
 * @generated from enum value: STATE_TERMINATED = 9;
 */
TERMINATED = "STATE_TERMINATED",
}
