import { ListJobsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/batch/v1/job_service_pb';
import { MessageBarType } from '@fluentui/react';
import { Loader, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';
import WidgetList from '../../components/WidgetList/WidgetList';
import { Job, Job_State } from '../../mlops/gen/ai/h2o/mlops/batch/v1/job_pb';
import { ListJobsResponse } from '../../mlops/gen/ai/h2o/mlops/batch/v1/job_service_pb';
import { useMLOpsService } from '../../mlops/hooks';
import { formatError } from '../../utils/utils';
import { ContextMenuIconButton } from '../Orchestrator/Workflows';
import { Tag, calculateDuration } from '../Orchestrator/WorkflowTabExecutions';
import { getStateProps } from './BatchScoringDetail';
import { useConfirmDialog } from './ConfirmDialogProvider';
import { ROUTES } from './constants';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

export type JobItem = Job & {
  createTimeLocal: string;
  startTimeLocal?: string;
  endTimeLocal: string;
  createdByName: string;
  duration?: string;
  onDeleteJob?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
  viewOnly?: boolean;
  actionsDisabled?: boolean;
};

const columns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'name',
    minWidth: 180,
    maxWidth: 360,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName, onEdit }: JobItem) => RowHeaderTitle({ title: displayName, onClick: onEdit }),
        emptyMessage: 'No description',
        iconProps: {
          iconName: 'Rocket',
        },
      },
    },
  },
  {
    key: 'status',
    name: '',
    fieldName: 'state',
    minWidth: 90,
    maxWidth: 180,
    data: {
      listCellProps: {
        styles: {
          root: {
            justifyContent: 'center',
          },
        },
        onRenderText: ({ state }: JobItem) => {
          return state === Job_State.RUNNING ? (
            <Loader label="Running" />
          ) : (
            <Tag title={getStateProps(state).name} color={getStateProps(state).color} />
          );
        },
      },
    },
  },
  {
    key: 'duration',
    name: 'Duration',
    fieldName: 'duration',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'creator',
    name: 'Creator',
    fieldName: 'createdByName',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'createTime',
    name: 'Created at',
    fieldName: 'createTimeLocal',
    minWidth: 90,
    maxWidth: 180,
  },
  {
    key: 'startTime',
    name: 'Started at',
    fieldName: 'startTimeLocal',
    minWidth: 90,
    maxWidth: 180,
    data: {
      listCellProps: {
        onRenderText: ({ startTimeLocal, startTime }: JobItem) => {
          return startTimeLocal || startTime || 'N/A';
        },
      },
    },
  },
  {
    key: 'endTime',
    name: 'Ended at',
    fieldName: 'endTimeLocal',
    minWidth: 90,
    maxWidth: 180,
    data: {
      listCellProps: {
        onRenderText: ({ endTimeLocal, endTime }: JobItem) => {
          return endTimeLocal || endTime || 'N/A';
        },
      },
    },
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 140,
    maxWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No description',
        onRenderText: ({ onDeleteJob, viewOnly, onEdit, onCancel, state }: JobItem) => (
          <ContextMenuIconButton
            items={[
              {
                key: 'view',
                text: 'View',
                onClick: onEdit,
                iconProps: { iconName: 'RedEye', style: { color: 'var(--h2o-gray900)' } },
              },
              {
                key: 'cancel',
                text: 'Cancel execution',
                onClick: onCancel,
                iconProps: { iconName: 'Cancel', style: { color: 'var(--h2o-yellow700)' } },
                style: {
                  color: 'var(--h2o-yellow700)',
                  display:
                    state !== Job_State.CANCELLED &&
                    state !== Job_State.CANCELLING &&
                    state !== Job_State.FAILED &&
                    state !== Job_State.FINISHED &&
                    state !== Job_State.SUBMISSION_FAILED &&
                    state !== Job_State.STATE_UNSPECIFIED &&
                    !viewOnly
                      ? undefined
                      : 'none',
                },
              },
              {
                key: 'delete',
                text: 'Delete',
                onClick: onDeleteJob,
                style: {
                  color: 'var(--h2o-red400)',
                  display:
                    (state === Job_State.CANCELLED ||
                      state === Job_State.FAILED ||
                      state === Job_State.FINISHED ||
                      state === Job_State.SUBMISSION_FAILED) &&
                    !viewOnly
                      ? undefined
                      : 'none',
                },
                iconProps: { iconName: 'Delete', style: { color: 'var(--h2o-red400)' } },
              },
            ]}
          />
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

const BatchScoring = () => {
  const history = useHistory(),
    { addToast } = useToast(),
    { showDialog } = useConfirmDialog(),
    { ACTIVE_PROJECT_ID, permissions } = useProjects(),
    loadStateRef = React.useRef({
      fetchJobs: false,
      cancellingJob: false,
      deletingJob: false,
    }),
    mlopsService = useMLOpsService(),
    [loading, setLoading] = React.useState(true),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [lastRefreshed, setLastRefreshed] = React.useState<Date>(),
    [isSearchStr, setIsSearchStr] = React.useState(false),
    [jobItems, setJobItems] = React.useState<JobItem[]>(),
    evaluateLoading = () => {
      if (!loadStateRef.current.fetchJobs && !loadStateRef.current.cancellingJob && !loadStateRef.current.deletingJob) {
        setLoading(false);
        setLastRefreshed(new Date());
      }
    },
    onCreateButtonClick = () => history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.BATCH_SCORING}/start-new`),
    fetchJobs = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (pageToken) {
          setIsLoadingMore(true);
        } else if (filter || filter === `display_name = ""`) {
          setIsLoadingSearch(true);
        } else {
          loadStateRef.current.fetchJobs = true;
          setLoading(true);
        }

        try {
          const listJobsBody = new ListJobsRequest({
            parent: `workspaces/${ACTIVE_PROJECT_ID}`,
            pageSize: 20,
            pageToken,
            orderBy: 'start_time desc',
            filter: filter === `display_name = ""` ? undefined : filter,
          });

          const data: ListJobsResponse = await mlopsService.listJobs(listJobsBody);

          const newJobItems: JobItem[] | undefined = data?.jobs
            ? data.jobs.map(
                (item) =>
                  ({
                    ...item,
                    createTimeLocal: item.createTime ? new Date(item.createTime).toLocaleString() : undefined,
                    startTimeLocal: item.startTime ? new Date(item.startTime).toLocaleString() : undefined,
                    endTimeLocal: item.endTime ? new Date(item.endTime).toLocaleString() : undefined,
                    createdByName: item.creator, // TODO: Check if it is display name.
                    duration: calculateDuration(item.startTime, item.endTime),
                    onDeleteJob: () => {
                      showDialog?.({
                        title: 'Delete job?',
                        description:
                          'Are you sure you want to delete this job? Once it is deleted it cannot be restored.',
                        onConfirm: () => {
                          void deleteJob(item.name || '');
                        },
                      });
                    },
                    onEdit: () => history.push(`/mlops/projects/${item.name?.replace('workspaces/', '')}`),
                    onCancel: () => cancelJob(item.name || ''),
                    viewOnly: !permissions?.canWrite,
                  } as JobItem)
              )
            : undefined;
          if (data && !newJobItems) console.error('No jobs found in the response.');
          setNextPageToken(data?.nextPageToken || undefined);
          setJobItems((items) => (pageToken ? [...(items || []), ...(newJobItems || [])] : newJobItems));
        } catch (err) {
          const message = `Failed to fetch jobs: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setJobItems(undefined);
        } finally {
          loadStateRef.current.fetchJobs = false;
          evaluateLoading();
        }
      },
      [ACTIVE_PROJECT_ID, permissions, mlopsService]
    ),
    cancelJob = React.useCallback(
      async (id: string) => {
        loadStateRef.current.cancellingJob = true;
        try {
          await mlopsService.cancelJob({ name: id });
          void fetchJobs();
        } catch (err) {
          const message = `Failed to cancel job: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.cancellingJob = false;
          evaluateLoading();
        }
      },
      [mlopsService, fetchJobs, addToast]
    ),
    deleteJob = React.useCallback(
      async (id: string) => {
        loadStateRef.current.deletingJob = true;
        try {
          await mlopsService.deleteJob({ name: id });
          void fetchJobs();
        } catch (err) {
          const message = `Failed to delete job: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.deletingJob = false;
          evaluateLoading();
        }
      },
      [mlopsService, fetchJobs]
    ),
    refreshItems = () => {
      void fetchJobs();
    },
    headerActionProps = React.useMemo(
      () =>
        permissions?.canWrite
          ? {
              actionTitle: 'Start new job',
              onActionClick: onCreateButtonClick,
              actionIcon: 'Add',
            }
          : undefined,
      [permissions, onCreateButtonClick]
    );

  React.useEffect(() => {
    if (ACTIVE_PROJECT_ID) fetchJobs();
  }, [ACTIVE_PROJECT_ID, fetchJobs]);

  return (
    <PageWrapper>
      {jobItems?.length || isSearchStr || loading ? <Header {...headerActionProps} /> : null}
      <WidgetList
        columns={columns}
        items={jobItems}
        loading={loading}
        isLoadingSearch={isLoadingSearch}
        isLoadingMore={isLoadingMore}
        onLoadMore={nextPageToken ? () => void fetchJobs(nextPageToken) : undefined}
        lastRefreshed={lastRefreshed?.toLocaleString()}
        onRefresh={refreshItems}
        searchProps={{
          placeholder: 'Search jobs',
          onSearchChange: (value) => {
            setIsSearchStr(!!value);
            void fetchJobs(undefined, `display_name = '${value}'`);
          },
        }}
        NoItemsContent={NoItemView({
          title: 'No batch scoring jobs',
          description: `There are no jobs to display in this project. ${
            permissions.canWrite ? 'Start a new job.' : ''
          }`,
          actionTitle: permissions.canWrite ? 'Start new job' : undefined,
          onActionClick: onCreateButtonClick,
          actionIcon: 'Add',
        })}
        ErrorContent={FailedToLoadView({
          title: 'Failed to load jobs',
          description: 'Please try again later. If the problem persists, contact our support.',
          actionTitle: 'Retry',
          onActionClick: fetchJobs,
          actionIcon: 'Refresh',
        })}
      />
    </PageWrapper>
  );
};

export default BatchScoring;
