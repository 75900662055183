import { Button, buttonStylesDefault, buttonStylesPrimary } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { GetAdminRequest, GetUserRequest } from '../../admin-center/gen/user_management/user_management_pb';
import { useAdminCenterService } from '../../admin-center/hooks';
import AdminTable from './components/UserManagement/AdminTable/AdminTable';
import BulkImportSidePanel from './components/UserManagement/BulkImportSidePanel/BulkImportSidePanel';
import UMCommitHistoryDialog from './components/UserManagement/CommitTable/UMCommitHistoryDialog';
import UMSidePanel from './components/UserManagement/UMSidePanel/UMSidePanel';
import {
  UserManagementTopStyles,
  adminTableStyle,
  menuBtnStyle,
  pageTopButtonStyle,
  sidePanelStyle,
  tableAreaStyle,
  userTableStyle,
} from './components/UserManagement/UserManagement.styles';
import UserTable from './components/UserManagement/UserTable/UserTable';
import PageWrapper from './PageWrapper';

interface UserProfile {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  id: string | undefined;
  status: string | undefined;
  login: string | undefined;
}

export default function UserManagement() {
  const service = useAdminCenterService();
  const [isOpen, setOpen] = useState(false);
  const [isBulkImport, setIsBulkImport] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [admins, setAdmins] = useState<UserProfile[]>([]);

  const [showCommitDialog, setShowCommitDialog] = useState(false);
  const handleAddButtonClick = () => {
    setOpen(true);
  };
  const handleShowCommitHistory = () => {
    setShowCommitDialog(true);
  };
  const handleAddBulkButtonClick = () => {
    setIsBulkImport(true);
  };
  const handleExportUsers = () => {
    // Construct file content
    let fileContent = `Total Users: ${users.length}\nTotal Admins: ${admins.length}\n\n`;

    fileContent += 'Users:\n';
    users.forEach((user, index) => {
      fileContent += `${index + 1}. ${user.email || ''} - ${user.status || 'N/A'}\n`;
    });

    fileContent += '\nAdmins:\n';
    admins.forEach((admin, index) => {
      fileContent += `${index + 1}. ${admin.email || ''} - ${admin.status || 'N/A'}\n`;
    });

    // Create Blob and download as file
    const blob = new Blob([fileContent], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'users_export.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchUserManagementData = async () => {
    setIsLoading(true);
    try {
      const userReq: GetUserRequest = {};
      const adminReq: GetAdminRequest = {};
      const userResponse = await service.getUsers(userReq);
      const adminResponse = await service.getAdmins(adminReq);
      const users =
        userResponse.users?.map((user) => ({
          firstName: user.firstName ?? 'Unknown',
          lastName: user.lastName ?? 'Unknown',
          email: user.email ?? 'Unknown',
          id: user.id ?? 'Unknown',
          status: user.status ?? 'Unknown',
          login: user.login ?? 'Unknown',
        })) || [];
      const admins =
        adminResponse.admins?.map((admin) => ({
          firstName: admin.firstName ?? 'Unknown',
          lastName: admin.lastName ?? 'Unknown',
          email: admin.email ?? 'Unknown',
          id: admin.id ?? 'Unknown',
          status: admin.status ?? 'Unknown',
          login: admin.login ?? 'Unknown',
        })) || [];

      setUsers(users);
      setAdmins(admins);
    } catch (error) {
      console.error('Error fetching User data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserManagementData();
  }, []);

  return (
    <PageWrapper>
      <div style={UserManagementTopStyles}>
        <div style={pageTopButtonStyle}>
          <Button
            styles={buttonStylesDefault}
            text="Export Users"
            onClick={handleExportUsers}
            style={menuBtnStyle}
            disabled={users.length === 0 && admins.length === 0}
          />
          <Button
            styles={buttonStylesPrimary}
            text="Bulk Import"
            onClick={handleAddBulkButtonClick}
            style={menuBtnStyle}
          />
          <Button
            styles={buttonStylesDefault}
            text="Commit History"
            onClick={handleShowCommitHistory}
            style={menuBtnStyle}
          />
          <Button styles={buttonStylesPrimary} text="Add User" onClick={handleAddButtonClick} style={menuBtnStyle} />
        </div>
        {isOpen && (
          <div style={sidePanelStyle}>
            <UMSidePanel isOpen={isOpen} setOpen={setOpen} onSubmitSuccess={fetchUserManagementData} />
          </div>
        )}
        {showCommitDialog && <UMCommitHistoryDialog onClose={() => setShowCommitDialog(false)} />}
        {isBulkImport && (
          <div style={sidePanelStyle}>
            <BulkImportSidePanel
              isBulkImport={isBulkImport}
              setIsBulkImport={setIsBulkImport}
              onSubmitSuccess={fetchUserManagementData}
            />
          </div>
        )}
      </div>
      <div>
        <p>Use these section to manage who can access your cloud environment.</p>
      </div>
      <div style={tableAreaStyle}>
        <div style={userTableStyle}>
          <div>
            <h3>
              Users <span>{users && `(${users.length})`}</span>
            </h3>
          </div>
          <div>
            <UserTable users={users} isLoading={isLoading} onSubmitSuccess={fetchUserManagementData} />
          </div>
        </div>
        <div style={adminTableStyle}>
          <div>
            <h3>
              Admins <span>{admins && `(${admins.length})`}</span>
            </h3>
          </div>
          <div>
            <AdminTable admins={admins} isLoading={isLoading} onSubmitSuccess={fetchUserManagementData} />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
