import { fetchWrapRPC, fetchWrapRPCRawResponse } from '../services/api';
import {
  CancelJobRequest,
  CreateJobRequest,
  DeleteJobRequest,
  GetJobOutputRequest,
  GetJobRequest,
  JobService_CancelJob,
  JobService_CreateJob,
  JobService_DeleteJob,
  JobService_GetJob,
  JobService_GetJobOutput,
  JobService_ListJobs,
  ListJobsRequest,
} from './gen/ai/h2o/mlops/batch/v1/job_service_pb';
import { ListSinkSpecsRequest, SinkSpecService_ListSinkSpecs } from './gen/ai/h2o/mlops/batch/v1/sink_spec_service_pb';
import {
  ListSourceSpecsRequest,
  SourceSpecService_ListSourceSpecs,
} from './gen/ai/h2o/mlops/batch/v1/source_spec_service_pb';
import {
  GetDefaultRuntimeKubernetesResourceSpecRequest,
  KubernetesResourceSpecService_GetDefaultRuntimeKubernetesResourceSpec,
} from './gen/ai/h2o/mlops/deployer/v1/kubernetes_resource_spec_service_pb';

export class MLOpsServiceImpl {
  private basePath: string;
  constructor(basePath?: string) {
    this.basePath = basePath || '';
  }

  public setBasePath = (path: string) => {
    this.basePath = path;
  };

  public async listJobs(req: ListJobsRequest) {
    // TODO: Remove basePath modification once generated ts api is fixed.
    return await fetchWrapRPC(JobService_ListJobs, { basePath: `${this.basePath}/deploymentServer/batch` })({ ...req });
  }

  public async deleteJob(req: DeleteJobRequest) {
    // TODO: Remove basePath modification once generated ts api is fixed.
    return await fetchWrapRPC(JobService_DeleteJob, { basePath: `${this.basePath}/deploymentServer/batch` })({
      ...req,
    });
  }

  public async createJob(req: CreateJobRequest) {
    // TODO: Remove basePath modification once generated ts api is fixed.
    return await fetchWrapRPC(JobService_CreateJob, { basePath: `${this.basePath}/deploymentServer/batch` })({
      ...req,
    });
  }

  public async cancelJob(req: CancelJobRequest) {
    // TODO: Remove basePath modification once generated ts api is fixed.
    return await fetchWrapRPC(JobService_CancelJob, { basePath: `${this.basePath}/deploymentServer/batch` })({
      ...req,
    });
  }

  public getJobOutput(jobId: GetJobOutputRequest) {
    // TODO: Remove basePath modification once generated ts api is fixed.
    return fetchWrapRPCRawResponse(JobService_GetJobOutput, { basePath: `${this.basePath}/deploymentServer/batch` })({
      ...jobId,
    });
  }

  public async listSourceSpecs(req: ListSourceSpecsRequest) {
    // TODO: Remove basePath modification once generated ts api is fixed.
    return await fetchWrapRPC(SourceSpecService_ListSourceSpecs, {
      basePath: `${this.basePath}/deploymentServer/batch`,
    })({
      ...req,
    });
  }

  public async listSinkSpecs(req: ListSinkSpecsRequest) {
    // TODO: Remove basePath modification once generated ts api is fixed.
    return await fetchWrapRPC(SinkSpecService_ListSinkSpecs, {
      basePath: `${this.basePath}/deploymentServer/batch`,
    })({
      ...req,
    });
  }

  public async getDefaultRuntimeKubernetesResourceSpec(req: GetDefaultRuntimeKubernetesResourceSpecRequest) {
    // TODO: Remove basePath modification once generated ts api is fixed.
    return await fetchWrapRPC(KubernetesResourceSpecService_GetDefaultRuntimeKubernetesResourceSpec, {
      basePath: `${this.basePath}/deployer`,
    })({
      ...req,
    });
  }

  public async getJob(req: GetJobRequest) {
    // TODO: Remove basePath modification once generated ts api is fixed.
    return await fetchWrapRPC(JobService_GetJob, { basePath: `${this.basePath}/deploymentServer/batch` })({
      ...req,
    });
  }
}
