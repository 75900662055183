export const ROUTES = {
  MODELS: '/models',
  DEPLOYMENTS: '/deployments',
  PROJECT_SETTINGS: '/settings',
  BATCH_SCORING: '/jobs',
};

export const ENDPOINTS = {
  storage: '/storage',
  ingest: '/ingest',
  deployment: '/deployer',
  deploymentServer: '/deploymentServer',
};
