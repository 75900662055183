import { BasicTable, useTheme } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import {
  GetUmCommitHistoryRequest,
  GetUmCommitHistoryResponse,
} from '../../../../../admin-center/gen/user_management/user_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { TableBackgroundStyle } from '../UserManagement.styles';

export default function CommitHistoryTable() {
  const service = useAdminCenterService();
  const theme = useTheme();
  const [commitHistory, setCommitHistory] = useState<GetUmCommitHistoryResponse | null>(null);
  const fetchFwCommitHistory = async () => {
    try {
      const request: GetUmCommitHistoryRequest = {};

      const response = await service.getUmCommitHistory(request);
      setCommitHistory(response);
    } catch (error) {
      console.error('Error fetching user commits:', error);
    }
  };

  useEffect(() => {
    fetchFwCommitHistory();
  }, []);

  const formatTimestamp = (timestamp?: number) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp * 1000).toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const umData =
    commitHistory?.commits
      ?.sort((a, b) => (b.timestamp ?? 0) - (a.timestamp ?? 0))
      .map((commit) => ({
        commitBy: commit.modifiedBy,
        commitAt: formatTimestamp(commit.timestamp),
        action: commit.action ?? '',
        group: commit.group ?? '',
        actionType: commit.actionType ?? '',
        entry: (commit.entry ?? []).join(', '),
      })) ?? [];

  return (
    <div style={TableBackgroundStyle(theme)}>
      <BasicTable
        columns={[
          {
            key: 'commitBy',
            fieldName: 'commitBy',
            name: 'Commit By',
            minWidth: 250,
          },
          {
            key: 'commitAt',
            fieldName: 'commitAt',
            name: 'Commit At',
            minWidth: 250,
          },
          {
            key: 'action',
            fieldName: 'action',
            name: 'Action',
            minWidth: 150,
          },
          {
            key: 'group',
            fieldName: 'group',
            name: 'Group',
            minWidth: 150,
          },
          {
            key: 'actionType',
            fieldName: 'actionType',
            name: 'Action Type',
            minWidth: 150,
          },
          {
            key: 'entry',
            fieldName: 'entry',
            name: 'Entry',
            minWidth: 350,
          },
        ]}
        items={umData}
      />
    </div>
  );
}
