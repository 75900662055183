import { DAIEngineProfile, DAIEngineProfile_ConfigEditability } from '../gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import { H2OEngineProfile } from '../gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import { NotebookEngineProfile } from '../gen/ai/h2o/engine/v1/notebook_engine_profile_pb';
import { ConstraintType } from '../types';
import { EntityField, EntityFieldType } from './types';
import { getOptionsFromEnum } from './utils';

type EntityFieldEngineProfile = DAIEngineProfile | H2OEngineProfile | NotebookEngineProfile;

const required = true;

type EngineType = 'DAI' | 'H2O' | 'Notebook';

export const engineProfileFieldsConfig = (engineType: EngineType): Record<string, EntityField<any>> => ({
  name: {
    name: 'name',
    type: EntityFieldType.IdOnDisplayAndId,
    label: 'Name',
    description: `${engineType} Engine Profile resource name.`,
    required,
  },
  displayName: {
    name: 'displayName',
    type: EntityFieldType.DisplayOnDisplayAndId,
    label: 'Display Name',
    description: `Human-readable name of ${engineType} Engine Profile.`,
    required,
  },
  enabled: {
    name: 'enabled',
    type: EntityFieldType.Boolean,
    label: 'Enabled',
    description: `Enabled profiles can be used with engines.`,
  },
  priority: {
    name: 'priority',
    type: EntityFieldType.Number,
    label: 'Priority',
    description: 'Determines ordering of profiles. A lower value means higher priority.',
  },
  assignedOidcRolesEnabled: {
    name: 'assignedOidcRolesEnabled',
    type: EntityFieldType.Boolean,
    label: 'Enforce OIDC Roles',
    description: 'When enabled, only specified OIDC roles have access to this profile.',
  },
  assignedOidcRoles: {
    name: 'assignedOidcRoles',
    type: EntityFieldType.StringArray,
    label: 'Assigned Oidc Roles',
    description: 'List of OIDC roles that have access to this profile.',
  },
  cpuConstraint: {
    name: 'cpuConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'CPU Constraint',
    description: `Constraint for engine's CPU Units.`,
    constraintType: ConstraintType.CPU,
    minimum: 1,
  },
  gpuConstraint: {
    name: 'gpuConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'GPU Constraint',
    description: `Constraint for engine's GPU Units.`,
    constraintType: ConstraintType.GPU,
  },
  nodeCountConstraint: {
    name: 'nodeCountConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Node Count Constraint',
    description: `Constraint for each ${engineType}Engine's node count that uses this profile.`,
    constraintType: ConstraintType.NODECOUNT,
  },
  memoryBytesConstraint: {
    name: 'memoryBytesConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Memory Constraint',
    description: `Constraint for engine's memory.`,
    constraintType: ConstraintType.MEMORYBYTES,
  },
  storageBytesConstraint: {
    name: 'storageBytesConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Storage Constraint',
    description: `Constraint for engine's storage.`,
    constraintType: ConstraintType.STORAGEBYTES,
  },
  maxIdleDurationConstraint: {
    name: 'maxIdleDurationConstraint',
    type: EntityFieldType.ConstraintDuration,
    label: 'Max Idle Duration Constraint',
    description: `Constraint for engine's Max Idle Time.`,
    constraintType: ConstraintType.MAXIDLEDURATION,
  },
  maxRunningDurationConstraint: {
    name: 'maxRunningDurationConstraint',
    type: EntityFieldType.ConstraintDuration,
    label: 'Max Running Duration Constraint',
    description: `Constraint for engine's Max Up Time.`,
    constraintType: ConstraintType.MAXRUNNINGDURATION,
  },
  maxNonInteractionDuration: {
    name: 'maxNonInteractionDuration',
    type: EntityFieldType.Duration,
    label: 'Max Non Interaction Duration',
    description: `Maximum duration without any user activity before the engine is considered idle.`,
  },
  maxUnusedDuration: {
    name: 'maxUnusedDuration',
    type: EntityFieldType.Duration,
    label: 'Max Unused Duration',
    description: `
    Maximum duration an engine can be paused before it is DELETED to save resources.
    Use with caution. Deleted engines cannot be recovered.
    `,
  },
  maxRunningEngines: {
    name: 'maxRunningEngines',
    type: EntityFieldType.Number,
    label: 'Max Running Engines',
    description: 'Maximum number of engines per user that can be running using this profile.',
    minimum: 1,
  },
  tritonEnabled: {
    name: 'tritonEnabled',
    type: EntityFieldType.Boolean,
    label: 'Triton Enabled',
    description: "Enables DAI's built-in Triton inference server.",
  },
  configEditability: {
    name: 'configEditability',
    type: EntityFieldType.SelectEnum,
    label: `${engineType} Config Editability`,
    description: "Specifies the user's ability to modify the engine configuration.",
    options: getOptionsFromEnum(DAIEngineProfile_ConfigEditability, 'CONFIG_EDITABILITY_', [
      DAIEngineProfile_ConfigEditability.UNSPECIFIED,
    ]),
    selectedOption: DAIEngineProfile_ConfigEditability.FULL,
  },
  baseConfiguration: {
    name: 'baseConfiguration',
    type: EntityFieldType.KeyValuePair,
    label: `Base ${engineType} Configuration`,
    description: `Base configuration is the initial configuration before user's configuration or overrides are considered.`,
  },
  configurationOverride: {
    name: 'configurationOverride',
    type: EntityFieldType.KeyValuePair,
    label: `${engineType} Configuration Override`,
    description: `Configuration override is applied on top of all other configurations.`,
  },
  yamlPodTemplateSpec: {
    name: 'yamlPodTemplateSpec',
    type: EntityFieldType.Yaml,
    label: 'Custom Pod Template (YAML)',
    description: `Custom pod template specification in the YAML format that will be merged with the generated Pod specification.`,
  },
  yamlGpuTolerations: {
    name: 'yamlGpuTolerations',
    type: EntityFieldType.Yaml,
    label: 'Custom GPU Tolerations (YAML)',
    description: `YAML representation of custom Pod toleration when the engine has GPU Units > 0.`,
  },
});

export const engineProfileBaseFields = (engineType: EngineType): EntityField<EntityFieldEngineProfile>[] => [
  engineProfileFieldsConfig(engineType).name,
  engineProfileFieldsConfig(engineType).displayName,
  engineProfileFieldsConfig(engineType).enabled,
  engineProfileFieldsConfig(engineType).priority,
  engineProfileFieldsConfig(engineType).assignedOidcRolesEnabled,
  engineProfileFieldsConfig(engineType).assignedOidcRoles,
  engineProfileFieldsConfig(engineType).cpuConstraint,
  engineProfileFieldsConfig(engineType).gpuConstraint,
  engineProfileFieldsConfig(engineType).memoryBytesConstraint,
];
