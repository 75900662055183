import { useEffect } from 'react';

import { MLOpsService } from '../services/api';
import { useCloudPlatformDiscovery } from '../utils/hooks';

export const useMLOpsService = () => {
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '';
  useEffect(() => {
    MLOpsService.setBasePath(mlopsApiUrl);
  }, [mlopsApiUrl]);

  return MLOpsService;
};
