import { IH2OTheme, Sizes } from '@h2oai/ui-kit';

export const loginTopStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '50px',
};

export const cardStyle = {
  backgroundColor: 'white',
  padding: '20px',
  margin: '20px',
  overflowY: 'auto',
};

export const menuItemStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: 'auto',
  alignItems: 'center',
};

export const startDatePickerStyle = {
  marginRight: '10px',
  width: '170px',
};

export const endDatePickerStyle = {
  width: '170px',
};

export const searchAreaStyle = {
  display: 'flex',
  alignItems: 'center',
};

export const searchStyle = {
  width: '250px',
  marginRight: '20px',
};

export const tableAreaStyle = {
  marginTop: '10px',
};

export const TableStyle = (theme: IH2OTheme): React.CSSProperties => ({
  backgroundColor: theme.palette?.white,
  padding: Sizes.padding,
  height: '360px',
  overflowY: 'auto' as 'auto' | 'hidden' | 'scroll' | 'visible',
  overflowX: 'auto' as 'auto' | 'hidden' | 'scroll' | 'visible',
});

export const UserLoginPageStatStyles = (theme: IH2OTheme) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: '20px',
  padding: Sizes.padding,
  marginBottom: '30px',
  backgroundColor: theme.palette?.white,
  borderRadius: Sizes.borderRadius,
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  fontFamily: "'Roboto', sans-serif",
  color: theme.palette?.gray300,
});

export const InfoLabel = {
  fontWeight: 'bold',
  color: '#555',
};

export const InfoValue = {
  fontWeight: 'normal',
  color: '#000',
  fontSize: '16px',
};
