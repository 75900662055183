import { MessageBarType } from '@fluentui/react';
import { DetailsList, IconButton, MessageBar, useTheme } from '@h2oai/ui-kit';
import { useState } from 'react';

import { FwIpLocalDeleteRequest } from '../../../../../admin-center/gen/fw_management/fw_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { useUser } from '../../../../../utils/hooks';
import { customTableStyles, messageBarContainerStyles } from '../../../common/common.styles';
import { getDummyList } from '../../../common/utils';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { TableBackgroundStyle, deleteButtonStyle } from '../FWManagement.styles';

interface InboundIpItem {
  inboundIP: string;
}

interface IPTableProps {
  fwIps: string[];
  onSubmitSuccess: () => Promise<void>;
  isLoading: boolean;
}

const dummyList = getDummyList<InboundIpItem>(5);

export default function IPTable({ fwIps, onSubmitSuccess, isLoading }: IPTableProps) {
  const service = useAdminCenterService();
  const theme = useTheme();
  const user = useUser();
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const ipData: InboundIpItem[] = fwIps.map((ip) => ({ inboundIP: ip }));
  const [showMessageBar, setShowMessagebar] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleDelete = async (ipToDelete: string) => {
    try {
      const request: FwIpLocalDeleteRequest = {
        deletedFwIps: [ipToDelete],
        modifiedBy: user?.name ?? '',
        timestamp: Date.now() / 1000,
      };
      const response = await service.deleteLocalFwIps(request);

      if (response.statusUpdate) {
        setResponseMessage(response.statusUpdate);
        setIsSuccess(true);
      } else {
        setResponseMessage('❌ Error: Failed to delete');
        setIsSuccess(false);
      }

      await onSubmitSuccess();
    } catch (error) {
      setResponseMessage(`❌ Network error: ${error}`);
      setIsSuccess(false);
    } finally {
      setShowMessagebar(true);
    }
  };

  const renderActionCell = (item: any): JSX.Element => {
    return (
      <div style={deleteButtonStyle}>
        <IconButton
          iconProps={{
            iconName: 'Delete',
          }}
          onClick={() => handleDelete(item.inboundIP)}
        />
      </div>
    );
  };
  return (
    <div style={TableBackgroundStyle(theme)}>
      {showMessageBar && (
        <div style={messageBarContainerStyles}>
          <MessageBar
            timeout={4000}
            onDismiss={() => setShowMessagebar(false)}
            messageBarType={isSuccess ? MessageBarType.success : MessageBarType.error}
          >
            {responseMessage}
          </MessageBar>
        </div>
      )}
      {!isLoading && ipData.length === 0 ? (
        <MessageBar messageBarType={MessageBarType.warning}>{'No data available'}</MessageBar>
      ) : (
        <DetailsList
          columns={[
            {
              key: 'inboundIP',
              fieldName: 'inboundIP',
              name: 'Inbound IPs',
              minWidth: 200,
              onRender: (item: InboundIpItem) => (
                <>{!isLoading ? item.inboundIP : <SkeletonLoader width="65%" height="100%" />}</>
              ),
            },
            {
              key: 'ipDltAction',
              fieldName: 'ipDltAction',
              name: '',
              minWidth: 150,
              onRender: (item: InboundIpItem) => (
                <>{!isLoading ? renderActionCell(item) : <SkeletonLoader width="65%" height="100%" />}</>
              ),
            },
          ]}
          styles={customTableStyles(theme, '200px')}
          items={isLoading ? dummyList : ipData}
        />
      )}
    </div>
  );
}
