// Minimal read-only API for working with the Groups.

// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/user/v1/group_api.proto (package ai.h2o.user.v1, syntax proto3)
/* eslint-disable */

import type { Group } from "./group_pb";
import { RPC } from "../../../../runtime";

/**
 * @generated from message ai.h2o.user.v1.GetGroupRequest
 */
export type GetGroupRequest = {
/**
 * Name of the requested group. For example: "groups/<UUID>".
 *
 * @generated from field: string name = 1;
 */
name?: string;
}
;
/**
 * @generated from message ai.h2o.user.v1.GetGroupResponse
 */
export type GetGroupResponse = {
/**
 * @generated from field: ai.h2o.user.v1.Group group = 1;
 */
group?: Group;
}
;
/**
 * @generated from message ai.h2o.user.v1.ListGroupsRequest
 */
export type ListGroupsRequest = {
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 *
 * @generated from field: int32 page_size = 1;
 */
pageSize?: number;
/**
 * @generated from field: string page_token = 2;
 */
pageToken?: string;
/**
 * When used, server will only return groups that match the filter.
 *
 * Due to the limitations of the backends, the filter is limited to
 * single string literal that matches against the fields based on the
 * backend. Usually it's the identifier part of the group name and the
 * display name.
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
}
;
/**
 * @generated from message ai.h2o.user.v1.ListGroupsResponse
 */
export type ListGroupsResponse = {
/**
 * @generated from field: repeated ai.h2o.user.v1.Group groups = 1;
 */
groups?: Group[];
/**
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * @generated from message ai.h2o.user.v1.ListUserGroupsRequest
 */
export type ListUserGroupsRequest = {
/**
 * @generated from field: int32 page_size = 1;
 */
pageSize?: number;
/**
 * @generated from field: string page_token = 2;
 */
pageToken?: string;
/**
 * Name of the user whose groups are requested. For example: "users/<UUID>".
 *
 * @generated from field: string parent = 3;
 */
parent?: string;
}
;
/**
 * @generated from message ai.h2o.user.v1.ListUserGroupsResponse
 */
export type ListUserGroupsResponse = {
/**
 * @generated from field: repeated ai.h2o.user.v1.Group groups = 1;
 */
groups?: Group[];
/**
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * @generated from rpc ai.h2o.user.v1.GroupService.GetGroup
 */
export const GroupService_GetGroup = new RPC<GetGroupRequest,GetGroupResponse>("GET", "/v1/{name=groups/*}");
/**
 * @generated from rpc ai.h2o.user.v1.GroupService.ListGroups
 */
export const GroupService_ListGroups = new RPC<ListGroupsRequest,ListGroupsResponse>("GET", "/v1/groups");
/**
 * @generated from rpc ai.h2o.user.v1.GroupService.ListUserGroups
 */
export const GroupService_ListUserGroups = new RPC<ListUserGroupsRequest,ListUserGroupsResponse>("GET", "/v1/{parent=users/*}/groups");
