// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/mlops/batch/v1/job.proto (package ai.h2o.mlops.batch.v1, syntax proto3)
/* eslint-disable */

/**
 * Job is entity representing Batch Scoring Job
 *
 * @generated from message ai.h2o.mlops.batch.v1.Job
 */
export type Job = {
/**
 * Resource name.
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Optional. Human-readable name.
 *
 * @generated from field: string display_name = 2;
 */
displayName?: string;
/**
 * @generated from field: ai.h2o.mlops.batch.v1.Source source = 3;
 */
source: Source;
/**
 * @generated from field: ai.h2o.mlops.batch.v1.Sink sink = 4;
 */
sink: Sink;
/**
 * @generated from field: ai.h2o.mlops.batch.v1.InstanceSpec instance_spec = 5;
 */
instanceSpec: InstanceSpec;
/**
 * @generated from field: ai.h2o.mlops.batch.v1.BatchParameters batch_parameters = 6;
 */
batchParameters?: BatchParameters;
/**
 * @generated from field: ai.h2o.mlops.batch.v1.ModelRequestParameters model_request_parameters = 7;
 */
modelRequestParameters?: ModelRequestParameters;
/**
 * The current state of the Job.
 *
 * @generated from field: ai.h2o.mlops.batch.v1.Job.State state = 8;
 */
state?: Job_State;
/**
 * Output only. Name of an entity that created the Job.
 *
 * @generated from field: string creator = 9;
 */
creator?: string;
/**
 * Output only. Time when the job was created.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 10;
 */
createTime?: string;
/**
 * Time when the Job started executing.
 *
 * @generated from field: google.protobuf.Timestamp start_time = 11;
 */
startTime?: string;
/**
 * Time when the Job completed.
 *
 * @generated from field: google.protobuf.Timestamp end_time = 12;
 */
endTime?: string;
}
;
/**
 * @generated from enum ai.h2o.mlops.batch.v1.Job.State
 */
export enum Job_State {
/**
 * State is unspecified or unknown.
 *
 * @generated from enum value: STATE_UNSPECIFIED = 0;
 */
STATE_UNSPECIFIED = "STATE_UNSPECIFIED",

/**
 * Job is created and waiting for submission
 *
 * @generated from enum value: CREATED = 1;
 */
CREATED = "CREATED",

/**
 * Job is submitted and waiting for starting up
 *
 * @generated from enum value: SUBMITTED = 2;
 */
SUBMITTED = "SUBMITTED",

/**
 * Job is being executed.
 *
 * @generated from enum value: RUNNING = 3;
 */
RUNNING = "RUNNING",

/**
 * Job is waiting for scorer to be ready. Possible changes are from Submitted -> Pending or Running -> Pending.
 *
 * @generated from enum value: PENDING = 4;
 */
PENDING = "PENDING",

/**
 * Job has completed successfully.
 *
 * @generated from enum value: FINISHED = 5;
 */
FINISHED = "FINISHED",

/**
 * Job has completed with an error.
 *
 * @generated from enum value: FAILED = 6;
 */
FAILED = "FAILED",

/**
 * Job has been cancelled.
 *
 * @generated from enum value: CANCELLED = 7;
 */
CANCELLED = "CANCELLED",

/**
 * Job submission failed
 *
 * @generated from enum value: SUBMISSION_FAILED = 8;
 */
SUBMISSION_FAILED = "SUBMISSION_FAILED",

/**
 * Job cancellation has been started.
 *
 * @generated from enum value: CANCELLING = 9;
 */
CANCELLING = "CANCELLING",
}
/**
 * @generated from message ai.h2o.mlops.batch.v1.Source
 */
export type Source = {
/**
 * @generated from field: string spec = 1;
 */
spec: string;
/**
 * @generated from field: string config = 2;
 */
config: string;
/**
 * @generated from field: string location = 3;
 */
location: string;
/**
 * Specifies the mime type for file-based sources. This value is used to determine how the file data should be interpreted and processed.
 * Common mime types include:
 * - text/csv: For CSV (Comma-Separated Values) files.
 * - application/jsonl: For JSON Lines (newline-delimited JSON) format, typically used for structured data with one JSON object per line.
 * - application/json: For regular JSON files containing structured data.
 * - image/*: For image files of any format (e.g., image/png, image/jpeg).
 *
 * Providing an appropriate mime type is crucial for the correct handling of file data. If not specified, default behavior may apply.
 *
 * @generated from field: string mime_type = 4;
 */
mimeType?: string;
}
;
/**
 * @generated from message ai.h2o.mlops.batch.v1.Sink
 */
export type Sink = {
/**
 * @generated from field: string spec = 1;
 */
spec: string;
/**
 * @generated from field: string config = 2;
 */
config: string;
/**
 * @generated from field: string location = 3;
 */
location: string;
/**
 * Specifies the mime type for file-based sinks. This value determines the format used when writing data to the output files.
 * Common mime types include:
 * - text/csv: For CSV (Comma-Separated Values) format, used for structured tabular data.
 * - application/jsonl: For JSON Lines (newline-delimited JSON) format, where each line is a separate JSON object.
 * - application/json: For standard JSON format, used for structured data in a hierarchical or nested structure.
 *
 * Specifying the correct mime type is essential to ensure data is output in the desired format. If not specified, the system may apply a default format that may not meet expectations.
 *
 * @generated from field: string mime_type = 4;
 */
mimeType?: string;
}
;
/**
 * @generated from message ai.h2o.mlops.batch.v1.BatchParameters
 */
export type BatchParameters = {
/**
 * The number of the records send in one request to scorer. If not provided 64 will be used as default.
 *
 * @generated from field: int32 mini_batch_size = 1;
 */
miniBatchSize?: number;
}
;
/**
 * @generated from message ai.h2o.mlops.batch.v1.ModelRequestParameters
 */
export type ModelRequestParameters = {
/**
 * @generated from field: string id_field = 1;
 */
idField?: string;
/**
 * @generated from field: ai.h2o.mlops.batch.v1.ModelRequestParameters.ShapleyType request_contributions = 2;
 */
requestContributions?: ModelRequestParameters_ShapleyType;
/**
 * @generated from field: bool request_prediction_intervals = 3;
 */
requestPredictionIntervals?: boolean;
}
;
/**
 * @generated from enum ai.h2o.mlops.batch.v1.ModelRequestParameters.ShapleyType
 */
export enum ModelRequestParameters_ShapleyType {
/**
 * @generated from enum value: SHAPLEY_TYPE_UNSPECIFIED = 0;
 */
SHAPLEY_TYPE_UNSPECIFIED = "SHAPLEY_TYPE_UNSPECIFIED",

/**
 * @generated from enum value: NONE = 1;
 */
NONE = "NONE",

/**
 * @generated from enum value: ORIGINAL = 2;
 */
ORIGINAL = "ORIGINAL",

/**
 * @generated from enum value: TRANSFORMED = 3;
 */
TRANSFORMED = "TRANSFORMED",
}
/**
 * @generated from message ai.h2o.mlops.batch.v1.InstanceSpec
 */
export type InstanceSpec = {
/**
 * Required. The deployment composition.
 *
 * @generated from field: ai.h2o.mlops.batch.v1.DeploymentComposition deployment_composition = 2;
 */
deploymentComposition: DeploymentComposition;
/**
 * Optional. Kubernetes resource profile to use for the deployment.
 *
 * @generated from field: ai.h2o.mlops.batch.v1.ResourceSpec resource_spec = 3;
 */
resourceSpec?: ResourceSpec;
/**
 * Optional. The environment variables to set in the scorer runtime.
 *
 * @generated from field: map<string, string> environment_variables = 4;
 */
environmentVariables?: { [key: string]: string };
}
;
/**
 * DeploymentComposition represents a deployment composition of a model.
 *
 * @generated from message ai.h2o.mlops.batch.v1.DeploymentComposition
 */
export type DeploymentComposition = {
/**
 * Required. ID of the experiment hosting the model to be deployed.
 *
 * @generated from field: string experiment_id = 1;
 */
experimentId: string;
/**
 * Required. Identifying name of the deployable artifact type.
 *
 * @generated from field: string artifact_type = 3;
 */
artifactType: string;
/**
 * Required. Identifying name of the artifact processor.
 *
 * @generated from field: string artifact_processor = 4;
 */
artifactProcessor: string;
/**
 * Required. Identifying name of the runtime.
 *
 * @generated from field: string runtime = 5;
 */
runtime: string;
/**
 * Optional. If specified, runtime image will be overwritten with this value if allowed.
 *
 * @generated from field: string runtime_image = 6;
 */
runtimeImage?: string;
}
;
/**
 * ResourceRequirement describes the scorer container resource requirements.
 *
 * @generated from message ai.h2o.mlops.batch.v1.ResourceRequirement
 */
export type ResourceRequirement = {
/**
 * Optional. Resource requests for the scorer container.
 * If requests is omitted, it defaults to limits if that is explicitly
 * specified, otherwise to an implementation-defined value.
 *
 * @generated from field: map<string, string> requests = 1;
 */
requests?: { [key: string]: string };
/**
 * Optional. Resource limits for the scorer container.
 * If not specified, there are no limits.
 *
 * @generated from field: map<string, string> limits = 2;
 */
limits?: { [key: string]: string };
}
;
/**
 * ResourceSpec describes the resource specification for the pod.
 *
 * @generated from message ai.h2o.mlops.batch.v1.ResourceSpec
 */
export type ResourceSpec = {
/**
 * Optional. Resource requirements for the scorer container.
 *
 * @generated from field: ai.h2o.mlops.batch.v1.ResourceRequirement resource_requirement = 1;
 */
resourceRequirement?: ResourceRequirement;
/**
 * Optional. Number of replicas for the scorer container.
 * Defaults to 1.
 *
 * @generated from field: int32 replicas = 2;
 */
replicas?: number;
/**
 * Optional. Number of available replicas for the scorer container which should available for processing batch scoring job.
 * Defaults is ceil(50% * replicas)
 * It cannot be greater than replicas
 *
 * @generated from field: int32 minimal_available_replicas = 3;
 */
minimalAvailableReplicas?: number;
}
;
