// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file aiUnitMax/aiUnitMax.proto (package aiUnitMax, syntax proto3)
/* eslint-disable */

import { RPC } from "../runtime";

/**
 * @generated from message aiUnitMax.AiUnitMaxRequest
 */
export type AiUnitMaxRequest = {
/**
 * Start date in YYYY-MM-DD format
 *
 * @generated from field: string start_date = 1;
 */
startDate?: string;
/**
 * End date in YYYY-MM-DD format
 *
 * @generated from field: string end_date = 2;
 */
endDate?: string;
}
;
/**
 * @generated from message aiUnitMax.AiUnitMaxResponse
 */
export type AiUnitMaxResponse = {
/**
 * @generated from field: repeated aiUnitMax.AiUnitMaxConsumptionEvent events = 1;
 */
events?: AiUnitMaxConsumptionEvent[];
}
;
/**
 * @generated from message aiUnitMax.AiUnitMaxConsumptionEvent
 */
export type AiUnitMaxConsumptionEvent = {
/**
 * Time Interval
 *
 * @generated from field: string time_interval = 1;
 */
timeInterval?: string;
/**
 * Resource name
 *
 * @generated from field: string resource = 2;
 */
resource?: string;
/**
 * AI unit count
 *
 * @generated from field: float ai_units = 3;
 */
aiUnits?: number;
}
;
/**
 * @generated from message aiUnitMax.AiUnitMaxStatsRequest
 */
export type AiUnitMaxStatsRequest = {
}
;
/**
 * @generated from message aiUnitMax.AiUnitMaxStatsResponse
 */
export type AiUnitMaxStatsResponse = {
/**
 * Maximum AI unit count
 *
 * @generated from field: float total_max_ai_units = 1;
 */
totalMaxAiUnits?: number;
/**
 * Maximum AI unit count
 *
 * @generated from field: float purchased_ai_units = 2;
 */
purchasedAiUnits?: number;
}
;
/**
 * @generated from rpc aiUnitMax.AiUnitMaxService.GetHourlyAiUnitMaxConsumption
 */
export const AiUnitMaxService_GetHourlyAiUnitMaxConsumption = new RPC<AiUnitMaxRequest,AiUnitMaxResponse>("GET", "/getHourlyAiUnitMaxConsumption");
/**
 * @generated from rpc aiUnitMax.AiUnitMaxService.GetAiUnitMaxStats
 */
export const AiUnitMaxService_GetAiUnitMaxStats = new RPC<AiUnitMaxStatsRequest,AiUnitMaxStatsResponse>("GET", "/getAiUnitMaxStats");
