import { PanelType } from '@fluentui/react';
import { Panel } from '@h2oai/ui-kit';

import { Entity, HasName } from '../../../aiem/entity/types';
import { EntitiesMap } from '../../../components/AdminSettings/components/types';
import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { AddEditModelForm } from './AddEditModelForm';

export interface IAIEMSettingsEditPanelProps<EntityModel, EntityType extends string> {
  panelTitle: string;
  onDismiss: () => any;
  onSave: () => any;
  isCreate: boolean;
  entity: Entity<EntityModel>;
  model?: EntityModel;
  entitiesMap?: EntitiesMap<EntityType>;
  requestPath?: string;
  activeWorkspaceName?: string;
}

export function AIEMSettingsEditPanel<EntityModel extends HasName, EntityType extends string>(
  props: IAIEMSettingsEditPanelProps<EntityModel, EntityType>
) {
  const { panelTitle, onDismiss, isCreate, onSave, entity, model, entitiesMap, requestPath, activeWorkspaceName } =
    props;
  if (!model) {
    return <></>;
  }
  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText={panelTitle}
      isFooterAtBottom
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
    >
      <AddEditModelForm
        entity={entity}
        model={model}
        isCreate={isCreate}
        onSave={onSave}
        onDismiss={onDismiss}
        entitiesMap={entitiesMap}
        requestPath={requestPath}
        activeWorkspaceName={activeWorkspaceName}
      />
    </Panel>
  );
}
