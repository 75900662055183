import { Text } from '@fluentui/react';
import { Button, Dropdown, Panel, buttonStylesGhost, buttonStylesPrimary, useTheme } from '@h2oai/ui-kit';
import React, { useCallback, useState } from 'react';

import { AddMemberRequest } from '../../../../../admin-center/gen/user_management/user_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { useUser } from '../../../../../utils/hooks';
import { UserRoleOption, UserRoleOptionKey } from '../../../constants';
import {
  bulkImportStyle,
  sampleAreaStyle,
  sampleTextStyle,
  sidePanelBtnStyle,
  sidePanelCancelBtnStyle,
  uploadAreaStyle,
  uploadTitleAreaStyle,
  uploadTitleStyle,
} from '../UserManagement.styles';

interface IPPanelProps {
  isBulkImport: boolean;
  setIsBulkImport: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitSuccess: () => Promise<void>;
}

interface MemberInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export default function BulkImportSidePanel({ isBulkImport, setIsBulkImport, onSubmitSuccess }: IPPanelProps) {
  const service = useAdminCenterService();
  const theme = useTheme();
  const user = useUser();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [dragOver, setDragOver] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const roleOptions = [
    { key: UserRoleOptionKey.user, text: UserRoleOption.user },
    { key: UserRoleOptionKey.admin, text: UserRoleOption.admin },
  ];
  const [selectedRoleKey, setSelectedRoleKey] = useState(UserRoleOptionKey.user);
  const onRoleChange = (_event: React.FormEvent<HTMLDivElement>, item: any) => {
    const { key } = item;
    setSelectedRoleKey(key);
  };

  const dismissPanel = useCallback(() => {
    setTimeout(() => {
      setSelectedRoleKey(UserRoleOptionKey.user);
      setIsBulkImport(false);
    }, 500);
  }, [setIsBulkImport, setSelectedRoleKey]);
  const isFormValid = useCallback(() => {
    return selectedRoleKey !== null && selectedRoleKey !== undefined;
  }, [selectedRoleKey]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      validateAndSetFile(event.target.files[0]);
    }
  };

  const validateAndSetFile = (file: File) => {
    if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
      setSelectedFile(file);
      setErrorMessage(null);
    } else {
      setSelectedFile(null);
      setErrorMessage('❌ Invalid file type. Please upload a CSV file.');
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);

    if (event.dataTransfer.files.length > 0) {
      validateAndSetFile(event.dataTransfer.files[0]);
    }
  };

  const handleSubmitClick = useCallback(async () => {
    if (!isFormValid() || !selectedFile) return;

    try {
      const text = await selectedFile.text();
      const rows = text
        .split('\n')
        .map((row) => row.trim())
        .filter((row) => row);

      const validMembers: MemberInfo[] = [];
      const errors: string[] = [];

      rows.forEach((row, index) => {
        const columns = row.split(',').map((col) => col.trim());

        if (columns.length < 3) {
          errors.push(`Row ${index + 1}: Missing columns`);
          return;
        }

        const [email, firstName, lastName] = columns;

        // Validate names and email format
        if (!firstName || !lastName || !email) {
          errors.push(`Row ${index + 1}: Column data missing`);
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          errors.push(`Row ${index + 1}: Invalid email format`);
        } else {
          validMembers.push({ firstName, lastName, email });
        }
      });

      if (errors.length > 0) {
        setErrorMessage(errors.join('\n'));
      } else {
        setErrorMessage(null);
      }

      if (validMembers.length === 0) {
        setErrorMessage('No valid entries found.');
        return;
      }

      const request: AddMemberRequest = {
        members: validMembers,
        memberLevel: selectedRoleKey ?? UserRoleOptionKey.user,
        modifiedBy: user?.name ?? '',
        timestamp: Date.now() / 1000,
      };

      const response = await service.addMembers(request);

      if (response.statusUpdate) {
        setResponseMessage(response.statusUpdate);
      } else {
        setErrorMessage('Failed to update members.');
      }

      await onSubmitSuccess();
    } catch (error) {
      setErrorMessage(`Network error: ${error}`);
    }
  }, [isFormValid, selectedFile, selectedRoleKey, user, onSubmitSuccess]);

  const onRenderFooterContent = useCallback(
    () => (
      <>
        <div style={sidePanelBtnStyle}>
          <Button styles={buttonStylesGhost} text="Cancel" onClick={dismissPanel} style={sidePanelCancelBtnStyle} />
          <Button
            styles={buttonStylesPrimary}
            text="Add User"
            onClick={handleSubmitClick}
            disabled={!(isFormValid() && !!selectedFile)}
          />
        </div>
      </>
    ),
    [dismissPanel, handleSubmitClick, isFormValid, selectedFile]
  );

  return (
    <Panel
      isLightDismiss
      isOpen={isBulkImport}
      onDismiss={dismissPanel}
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      onRenderFooterContent={onRenderFooterContent}
      customWidth="500px"
      type={7}
    >
      <h2>
        <strong>Add Users/Admins</strong>
      </h2>
      <Dropdown
        label="Role"
        placeholder="Select role"
        options={roleOptions}
        selectedKey={selectedRoleKey}
        onChange={onRoleChange}
        required={true}
      />
      <div style={uploadTitleAreaStyle}>
        <Text variant="mediumPlus" styles={uploadTitleStyle}>
          Upload CSV
        </Text>
      </div>
      <div
        style={uploadAreaStyle(dragOver, theme)}
        onClick={() => document.getElementById('file-upload')?.click()}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input type="file" id="file-upload" accept=".csv" onChange={handleFileChange} style={bulkImportStyle} />
        <Text variant="small">
          {selectedFile ? `✔ Selected File: ${selectedFile.name}` : 'Drag & Drop CSV here or Click to Browse'}
        </Text>
      </div>
      <div style={sampleAreaStyle(theme)}>
        <strong>Sample CSV Format:</strong>
        <pre style={sampleTextStyle}>
          {`john@example.com,John,Doe
jane@example.com,Jane,Dane`}
        </pre>
      </div>

      {responseMessage && (
        <p
          style={{
            marginTop: 10,
            color: responseMessage.includes('❌') ? theme.palette?.red300 : theme.palette?.green300,
          }}
        >
          {responseMessage}
        </p>
      )}
      {errorMessage && (
        <Text variant="small" styles={{ root: { color: theme.palette?.red300, marginTop: 5 } }}>
          {errorMessage}
        </Text>
      )}
    </Panel>
  );
}
