import { MessageBarType } from '@fluentui/react';
import { Button, DatePicker, Dropdown, MessageBar, Sizes, buttonStylesDefault, useTheme } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { AiUnitMaxStatsRequest, AiUnitMaxStatsResponse } from '../../admin-center/gen/aiUnitMax/aiUnitMax_pb';
import { useAdminCenterService } from '../../admin-center/hooks';
import { messageBarContainerStyles } from './common/common.styles';
import {
  AiUnitConsumptionTopStyles,
  AiUnitMaxStyles,
  InfoLabel,
  InfoValue,
} from './components/AIUnitConsumption/AIUnitConsumption.styles';
import AlertSidePanel from './components/AIUnitConsumption/AlertSidePanel';
import ConsumptionPlot from './components/AIUnitConsumption/ConsumptionPlot';
import { AiUnitMaxTimeFrame, AiUnitMaxTimeFrameKey } from './constants';
import PageWrapper from './PageWrapper';

const timeFrames = [
  {
    key: AiUnitMaxTimeFrameKey.daily,
    text: AiUnitMaxTimeFrame.daily,
  },
  {
    key: AiUnitMaxTimeFrameKey.weekly,
    text: AiUnitMaxTimeFrame.weekly,
  },
  {
    key: AiUnitMaxTimeFrameKey.monthly,
    text: AiUnitMaxTimeFrame.monthly,
  },
];

export default function AIUnitConsumption() {
  const service = useAdminCenterService();
  const theme = useTheme();
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  const handleAddButtonClick = () => {
    setSidePanelOpen(true);
  };

  const [selectedTimeFrameKey, setSelectedTimeFrameKey] = useState<string>(AiUnitMaxTimeFrameKey.daily);
  const onTimeFrmaeChange = (_event: React.FormEvent<HTMLDivElement>, item: any) => {
    const { key } = item;
    setSelectedTimeFrameKey(key);
  };

  const [stats, setStats] = useState<AiUnitMaxStatsResponse | null>(null);
  const [isPercentageHigh, setIsPercentageHigh] = useState<boolean>(true);
  const [alertResponseMessage, setAlertResponseMessage] = useState<string | null>(null);
  const [showMessageBar, setShowMessagebar] = useState<boolean>(false);
  const [isAlertRequestSuccess, setIsAlertRequestSuccess] = useState<boolean>(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  sevenDaysAgo.setHours(0, 0, 0, 0);

  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date>(today);

  const handleStartDateChange = (date: Date | null | undefined) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null | undefined) => {
    if (date) {
      setEndDate(date);
    }
  };

  const fetchAiUnitMaxStats = async () => {
    try {
      const request: AiUnitMaxStatsRequest = {};
      const response = await service.getAiUnitMaxStats(request);
      setStats(response);

      const maxAiUnitsConsumed = response?.totalMaxAiUnits;
      if (maxAiUnitsConsumed == null || isNaN(Number(maxAiUnitsConsumed))) {
        throw new Error('Invalid response: totalMaxAiUnits is missing or not a valid number.');
      }

      if (stats?.purchasedAiUnits && stats.purchasedAiUnits !== 0) {
        const percentageUsed = (maxAiUnitsConsumed / stats?.purchasedAiUnits) * 100;
        setIsPercentageHigh(percentageUsed > 90);
      } else {
        setIsPercentageHigh(false);
      }
    } catch (error) {
      console.error('Error fetching login statistics:', error);
    }
  };

  useEffect(() => {
    fetchAiUnitMaxStats();
  }, []);

  function setAlertMessageBar(isSuccess: boolean) {
    if (isSuccess) {
      setIsAlertRequestSuccess(true);
      setAlertResponseMessage('AI unit usage alert data updated successfully');
    } else {
      setIsAlertRequestSuccess(false);
      setAlertResponseMessage('An error occured while updating AI unit usage alert data');
    }
    setShowMessagebar(true);
  }

  return (
    <PageWrapper>
      {showMessageBar && (
        <div style={messageBarContainerStyles}>
          <MessageBar
            timeout={4000}
            onDismiss={() => setShowMessagebar(false)}
            messageBarType={isAlertRequestSuccess ? MessageBarType.success : MessageBarType.error}
          >
            {alertResponseMessage}
          </MessageBar>
        </div>
      )}
      <div style={AiUnitConsumptionTopStyles}>
        <div style={{ position: 'relative', paddingBottom: Sizes.buttonPaddingTopBottom }}>
          <Button styles={buttonStylesDefault} text="AI Unit Alerts" onClick={handleAddButtonClick} />
          {isSidePanelOpen && (
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              <AlertSidePanel
                isOpen={isSidePanelOpen}
                setOpen={setSidePanelOpen}
                setAlertMessageBar={setAlertMessageBar}
              />
            </div>
          )}
        </div>
      </div>
      <div style={AiUnitMaxStyles(theme)}>
        <div>
          <p style={InfoLabel(theme)}>Purchased AI Unit:</p>
          <p style={InfoValue(theme)}>{stats?.purchasedAiUnits ?? 0}</p>
        </div>
        <div>
          <p style={InfoLabel(theme)}>Maximum Capacity Used:</p>
          <p style={InfoValue(theme)}>{stats?.totalMaxAiUnits ?? '-'}</p>
        </div>
        <div>
          <p style={InfoLabel(theme)}>Overall Maximum Consumption Percentage:</p>
          <p style={InfoValue(theme)}>
            Maximum AI unit consumption is{' '}
            <span style={{ color: isPercentageHigh ? 'red' : 'green' }}>
              {isPercentageHigh ? 'more than 90%' : 'less than 90%'}
            </span>{' '}
            out of the purchased units!
          </p>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          marginRight: '20px',
          marginLeft: '50%',
          width: 'auto',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Dropdown
          placeholder="Select time frame"
          options={timeFrames}
          selectedKey={selectedTimeFrameKey}
          onChange={onTimeFrmaeChange}
          width={'100%'}
          style={{ marginRight: '20px' }}
        />
        <DatePicker
          placeholder="Start date"
          style={{ marginRight: '10px', width: '170px', marginLeft: '10px' }}
          value={startDate}
          onSelectDate={handleStartDateChange}
        />
        <DatePicker
          placeholder="End date"
          style={{ marginRight: '10px', width: '170px' }}
          value={endDate}
          onSelectDate={handleEndDateChange}
        />
      </div>

      <div>
        <ConsumptionPlot
          startDate={startDate.toISOString()}
          endDate={endDate.toISOString()}
          timeFrameKey={selectedTimeFrameKey}
        />
      </div>
    </PageWrapper>
  );
}
