import { IStackStyles, Stack, Text } from '@fluentui/react';
import {
  Button,
  IButtonProps,
  buttonStylesDanger,
  buttonStylesPrimary,
  useHaicPageTitle,
  useTheme,
} from '@h2oai/ui-kit';

import { RoutePaths } from '../../pages/Routes';
import { useEnv } from '../../utils/hooks';
import { ListPageFilter, ListPageSearchAndFilter } from './ListPageSearchAndFilter';
import ListPageShell from './ListPageShell';
import { ListPageDownloading, ListPageError, ListPageLoading, ListPageNoData } from './SpecialListPages';
import StatsCard, { StatsCardProps } from './StatsCard';

export interface ListPageAction {
  dangerous?: boolean; // dangerous actions are styled differently
  disabled?: boolean;
  ['data-test']: string;
  href?: string;
  key: string;
  onClick?: () => void;
  text: string;
}

export interface ListPageCopy {
  title: string;
  subtitle: string;
  noDataTitle?: string;
  noDataMessage: string;
  loadingMessage: string;
  downloadingMessage?: string;
  noFilterResultsMessage?: string;
  searchPlaceholder?: string;
}

export interface ListPageProps {
  actions?: ListPageAction[];
  activeFilters?: ListPageFilter[];
  children: React.ReactNode;
  copy: ListPageCopy;
  disableActions?: boolean;
  downloading?: boolean;
  error?: string;
  loading?: boolean;
  parentPage?: RoutePaths;
  primaryButtonProps?: IButtonProps;
  onChangeSearchText?: (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void;
  onOpenFilterPanel?: () => void;
  onRemoveFilter?: (filter: ListPageFilter) => void;
  onResetFilters?: () => void;
  searchText?: string;
  showFilterButton?: boolean;
  showSearchInput?: boolean;
  showPageTitle?: boolean;
  showNoDataPage?: boolean;
  showNoResults?: boolean;
  statsCards?: StatsCardProps[];
  stackStyles?: Partial<IStackStyles>;
}

function ListPage({
  actions,
  activeFilters,
  children,
  copy,
  disableActions,
  downloading,
  error,
  loading,
  parentPage,
  primaryButtonProps,
  onChangeSearchText,
  onOpenFilterPanel,
  onRemoveFilter,
  onResetFilters,
  searchText,
  showPageTitle = true,
  showFilterButton = false,
  showSearchInput = true,
  showNoDataPage = false,
  showNoResults = false,
  statsCards,
  stackStyles,
}: ListPageProps) {
  const theme = useTheme();
  const env = useEnv();
  useHaicPageTitle(copy.title, env?.cloudInstanceName);

  const baseProps = {
    copy,
    parentPage,
    primaryButtonProps,
    stackStyles,
    showPageTitle,
  };
  if (error) {
    return <ListPageError {...baseProps} error={error} />;
  } else if (loading) {
    return <ListPageLoading {...baseProps} />;
  } else if (downloading) {
    return <ListPageDownloading {...baseProps} />;
  } else if (showNoDataPage) {
    return <ListPageNoData {...baseProps} />;
  }

  const showStatsCards = statsCards && statsCards.length > 0,
    showActionButton = actions && actions.length > 0;
  return (
    <>
      <ListPageShell {...baseProps}>
        {showStatsCards && (
          <Stack horizontal tokens={{ childrenGap: 16 }}>
            {statsCards.map((card) => (
              <StatsCard key={card.title} title={card.title} stat={card.stat} />
            ))}
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between" style={{ marginTop: 20 }}>
          {(showSearchInput || showFilterButton) && (
            <ListPageSearchAndFilter
              searchPlaceholder={copy.searchPlaceholder}
              activeFilters={activeFilters}
              searchText={searchText}
              onChangeSearchText={onChangeSearchText}
              showFilterButton={showFilterButton}
              onOpenFilterPanel={onOpenFilterPanel}
              onRemoveFilter={onRemoveFilter}
              onResetFilters={onResetFilters}
              showSearchInput={showSearchInput}
            />
          )}

          {showActionButton && (
            <Stack horizontal>
              {actions.length === 1 ? (
                <Button
                  disabled={disableActions || actions[0].disabled}
                  href={actions[0].href}
                  styles={actions[0].dangerous ? buttonStylesDanger : buttonStylesPrimary}
                  text={actions[0].text}
                  onClick={actions[0].onClick}
                />
              ) : (
                <Button
                  disabled={disableActions}
                  text="Actions"
                  menuItems={actions?.map((action) => {
                    const { dangerous, disabled, ...actionSchema } = action;
                    return {
                      ...actionSchema,
                      disabled: action.disabled,
                      style: dangerous
                        ? { color: theme.palette?.red500, opacity: action.disabled ? 0.5 : 1 }
                        : undefined,
                    };
                  })}
                />
              )}
            </Stack>
          )}
        </Stack>
        {showNoResults ? (
          <Text variant="large" styles={{ root: { margin: '12px 0 0 12px' } }}>
            {copy?.noFilterResultsMessage || 'No results found'}
          </Text>
        ) : (
          children
        )}
      </ListPageShell>
    </>
  );
}

export default ListPage;
