import { IH2OTheme, useTheme } from '@h2oai/ui-kit';
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { useEffect, useState } from 'react';

import { AiUnitMaxRequest, AiUnitMaxResponse } from '../../../../admin-center/gen/aiUnitMax/aiUnitMax_pb';
import { useAdminCenterService } from '../../../../admin-center/hooks';
import { resolveColor } from '../../../../utils/utils';
import { AiUnitMaxTimeFrameKey, NA } from '../../constants';

Chart.register(BarElement, BarController, CategoryScale, LinearScale, Title, Tooltip, Legend);

interface AiUnitMax {
  timeInterval: string;
  resource: string;
  aiUnits: number;
}

interface AIUnitMaxPlotProps {
  startDate: string;
  endDate: string;
  timeFrameKey: string;
}

const ConsumptionPlot = ({ startDate, endDate, timeFrameKey }: AIUnitMaxPlotProps) => {
  const [chart, setChart] = useState<Chart | null>(null);
  const service = useAdminCenterService();
  const theme = useTheme();

  const generateChartData = (data: AiUnitMax[]) => {
    const groupedData: { [key: string]: { [key: string]: number } } = {};

    // Group data by timeInterval and resource
    data.forEach(({ timeInterval, resource, aiUnits }) => {
      if (!groupedData[timeInterval]) {
        groupedData[timeInterval] = {};
      }
      groupedData[timeInterval][resource] = aiUnits;
    });

    // Extract labels (time intervals) and datasets
    const labels = Object.keys(groupedData).sort();
    const resources = Array.from(new Set(data.map((item) => item.resource)));
    const datasets = resources.map((resource) => ({
      label: resource,
      data: labels.map((label) => groupedData[label]?.[resource] || 0),
      backgroundColor: getResourceColor(resource, theme),
    }));

    return { labels, datasets };
  };

  const getResourceColor = (resource: string, theme: IH2OTheme) => {
    const palette = theme.palette;
    const colorMap: { [key: string]: string | undefined } = {
      eScorer: resolveColor(palette?.blue400, '#3D93F5'),
      Apps: resolveColor(palette?.red300, '#ED5F5F'),
      'ML-API': resolveColor(palette?.gray300, '#D1D5DB'),
      'Doc.ai': resolveColor(palette?.green200, '#A4DBA6'),
      MLOps: resolveColor(palette?.yellow400, '#FFD659'),
      Notebooks: resolveColor(palette?.blue200, '#A6CFFF'),
      'h2oGPT + LLM': resolveColor(palette?.red100, '#FFD9D9'),
      AIEM: resolveColor(palette?.green400, '#5DA85F'),
    };
    return colorMap[resource] || '#808080';
  };

  const getStartOfWeek = (date: Date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust for Sunday being 0
    return new Date(date.setDate(diff)).toLocaleDateString('en-CA');
  };

  const getStartOfMonth = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('en-CA');
  };

  useEffect(() => {
    const fetchAndUpdateChartData = async () => {
      try {
        const request: AiUnitMaxRequest = {
          startDate: startDate,
          endDate: endDate,
        };

        const response: AiUnitMaxResponse = await service.getAiUnitMaxConsumption(request);

        let formattedData = (response.events ?? []).map((event) => {
          const utcDate = event.timeInterval ? new Date(event.timeInterval) : null;

          return {
            timeInterval: utcDate
              ? utcDate.toLocaleDateString('en-CA', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
              : '-',
            resource: event.resource ?? '-',
            aiUnits: event.aiUnits ?? 0,
          };
        });

        if (timeFrameKey === AiUnitMaxTimeFrameKey.weekly || timeFrameKey === AiUnitMaxTimeFrameKey.monthly) {
          const groupKey = timeFrameKey === AiUnitMaxTimeFrameKey.weekly ? 'week' : 'month';
          const groupedData = formattedData.reduce((acc, event) => {
            const date = new Date(event.timeInterval);
            const key = groupKey === 'week' ? getStartOfWeek(date) : getStartOfMonth(date);
            acc[key] = acc[key] || [];
            acc[key].push(event);
            return acc;
          }, {} as Record<string, typeof formattedData>);

          // Process each group (week or month)
          formattedData = Object.entries(groupedData).flatMap(([groupKey, events]) => {
            // Group events by their original date
            const dailySums = events.reduce((acc, event) => {
              const date = event.timeInterval;
              if (!acc[date]) {
                acc[date] = { ...event, aiUnits: 0 };
              }
              acc[date].aiUnits += event.aiUnits;
              return acc;
            }, {} as Record<string, { resource: string; timeInterval: string; aiUnits: number }>);

            const maxDay = Object.values(dailySums).reduce((max, curr) => (curr.aiUnits > max.aiUnits ? curr : max));

            return events
              .filter((event) => event.timeInterval === maxDay.timeInterval)
              .map((event) => ({
                ...event,
                timeInterval: groupKey,
              }));
          });
        }

        // Calculate the total AI units per day
        const dailyTotals: Record<string, number> = formattedData.reduce((acc, event) => {
          const { timeInterval, aiUnits } = event;
          if (timeInterval !== '-') {
            acc[timeInterval] = (acc[timeInterval] || 0) + aiUnits;
          }
          return acc;
        }, {} as Record<string, number>);

        // Find the maximum sum of AI units across dates
        const maxSum = Math.max(...Object.values(dailyTotals)).toFixed(1);

        // Generate chart data
        const chartData = generateChartData(formattedData);

        const canvas = document.getElementById('aiUnitConsumptionChart') as HTMLCanvasElement | null;

        if (canvas) {
          const ctx = canvas.getContext('2d');
          if (ctx) {
            if (chart) {
              chart.destroy();
            }

            const newChart = new Chart(ctx, {
              type: 'bar',
              data: chartData,
              options: {
                plugins: {
                  title: {
                    display: true,
                    text: `Max AI Unit Consumption: ${maxSum !== null ? maxSum : NA}`,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
                responsive: true,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'AI Units Max',
                    },
                    grid: {
                      color: 'rgba(0, 0, 0, 0.1)',
                    },
                  },
                },
              },
            });

            setChart(newChart);
          }
        }
      } catch (error) {
        console.error('Error fetching AI unit consumption data:', error);
      }
    };

    fetchAndUpdateChartData();
  }, [startDate, endDate, timeFrameKey]);

  return <canvas id="aiUnitConsumptionChart" width="400" height="100"></canvas>;
};

export default ConsumptionPlot;
