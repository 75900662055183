// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/notebook_engine_profile_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { NotebookEngineProfile } from "./notebook_engine_profile_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message Create method.
 *
 * @generated from message ai.h2o.engine.v1.CreateNotebookEngineProfileRequest
 */
export type CreateNotebookEngineProfileRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/notebookEngineProfiles/CREATE
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The NotebookEngineProfile resource to create.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineProfile notebook_engine_profile = 2;
 */
notebookEngineProfile: NotebookEngineProfile;
/**
 * Specify the NotebookEngineProfile ID, which will become a part of the NotebookEngineProfile
 * resource name.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string notebook_engine_profile_id = 3;
 */
notebookEngineProfileId: string;
}
;
/**
 * Response message for Create method.
 *
 * @generated from message ai.h2o.engine.v1.CreateNotebookEngineProfileResponse
 */
export type CreateNotebookEngineProfileResponse = {
/**
 * Created NotebookEngineProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineProfile notebook_engine_profile = 1;
 */
notebookEngineProfile?: NotebookEngineProfile;
}
;
/**
 * Request message for Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetNotebookEngineProfileRequest
 */
export type GetNotebookEngineProfileRequest = {
/**
 * NotebookEngineProfile resource name.
 * Format: workspaces/{workspace}/notebookEngineProfiles/{notebook_engine_profile}
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/notebookEngineProfiles/GET
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetNotebookEngineProfileResponse
 */
export type GetNotebookEngineProfileResponse = {
/**
 * NotebookEngineProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineProfile notebook_engine_profile = 1;
 */
notebookEngineProfile?: NotebookEngineProfile;
}
;
/**
 * Request message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListNotebookEngineProfilesRequest
 */
export type ListNotebookEngineProfilesRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/notebookEngineProfiles/LIST
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of NotebookEngineProfiles to return in a response.
 * If unspecified (or set to 0), at most 50 NotebookEngineProfiles will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListNotebookEngineProfilesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListNotebookEngineProfilesResponse
 */
export type ListNotebookEngineProfilesResponse = {
/**
 * Collection of NotebookEngineProfiles.
 *
 * @generated from field: repeated ai.h2o.engine.v1.NotebookEngineProfile notebook_engine_profiles = 1;
 */
notebookEngineProfiles?: NotebookEngineProfile[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for ListAssigned method.
 *
 * @generated from message ai.h2o.engine.v1.ListAssignedNotebookEngineProfilesRequest
 */
export type ListAssignedNotebookEngineProfilesRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/notebookEngineProfiles/LIST_ASSIGNED
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of NotebookEngineProfiles to return in a response.
 * If unspecified (or set to 0), at most 50 NotebookEngineProfiles will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListNotebookEngineProfilesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for ListAssigned method.
 * (-- api-linter: core::0132::response-unknown-fields=disabled
 *     aip.dev/not-precedent: Not a standard List method response. --)
 *
 * @generated from message ai.h2o.engine.v1.ListAssignedNotebookEngineProfilesResponse
 */
export type ListAssignedNotebookEngineProfilesResponse = {
/**
 * Collection of NotebookEngineProfiles.
 *
 * @generated from field: repeated ai.h2o.engine.v1.NotebookEngineProfile notebook_engine_profiles = 1;
 */
notebookEngineProfiles?: NotebookEngineProfile[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for Update method.
 *
 * @generated from message ai.h2o.engine.v1.UpdateNotebookEngineProfileRequest
 */
export type UpdateNotebookEngineProfileRequest = {
/**
 * Required. NotebookEngineProfile resource.
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/notebookEngineProfiles/UPDATE
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineProfile notebook_engine_profile = 1;
 */
notebookEngineProfile: NotebookEngineProfile;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are:
 * - display_name
 * - priority
 * - enabled
 * - assigned_oidc_roles_enabled
 * - assigned_oidc_roles
 * - max_running_engines
 * - cpu_constraint
 * - gpu_constraint
 * - memory_bytes_constraint
 * - storage_bytes_constraint
 * - max_idle_duration_constraint
 * - max_running_duration_constraint
 * - yaml_pod_template_spec
 * - yaml_gpu_tolerations
 *
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response for Update method.
 *
 * @generated from message ai.h2o.engine.v1.UpdateNotebookEngineProfileResponse
 */
export type UpdateNotebookEngineProfileResponse = {
/**
 * NotebookEngineProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineProfile notebook_engine_profile = 1;
 */
notebookEngineProfile?: NotebookEngineProfile;
}
;
/**
 * Request message for Delete method.
 *
 * @generated from message ai.h2o.engine.v1.DeleteNotebookEngineProfileRequest
 */
export type DeleteNotebookEngineProfileRequest = {
/**
 * NotebookEngine resource name.
 * Format: workspaces/{workspace}/notebookEngineProfiles/{notebook_engine_profile}
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/notebookEngineProfiles/DELETE
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for Delete method.
 *
 * @generated from message ai.h2o.engine.v1.DeleteNotebookEngineProfileResponse
 */
export type DeleteNotebookEngineProfileResponse = {
}
;
/**
 * Creates a new NotebookEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineProfileService.CreateNotebookEngineProfile
 */
export const NotebookEngineProfileService_CreateNotebookEngineProfile = new RPC<CreateNotebookEngineProfileRequest,CreateNotebookEngineProfileResponse>("POST", "/v1/{parent=workspaces/*}/notebookEngineProfiles", "notebookEngineProfile");
/**
 * Get NotebookEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineProfileService.GetNotebookEngineProfile
 */
export const NotebookEngineProfileService_GetNotebookEngineProfile = new RPC<GetNotebookEngineProfileRequest,GetNotebookEngineProfileResponse>("GET", "/v1/{name=workspaces/*/notebookEngineProfiles/*}");
/**
 * Returns a collection of NotebookEngineProfiles.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineProfileService.ListNotebookEngineProfiles
 */
export const NotebookEngineProfileService_ListNotebookEngineProfiles = new RPC<ListNotebookEngineProfilesRequest,ListNotebookEngineProfilesResponse>("GET", "/v1/{parent=workspaces/*}/notebookEngineProfiles");
/**
 * Returns assigned NotebookEngineProfiles that match OIDC roles of the caller.
 * (-- api-linter: core::0136::http-uri-suffix=disabled
 *     aip.dev/not-precedent: Not a standard List method. --)
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineProfileService.ListAssignedNotebookEngineProfiles
 */
export const NotebookEngineProfileService_ListAssignedNotebookEngineProfiles = new RPC<ListAssignedNotebookEngineProfilesRequest,ListAssignedNotebookEngineProfilesResponse>("GET", "/v1/{parent=workspaces/*}/notebookEngineProfiles:listAssigned");
/**
 * Updates a NotebookEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineProfileService.UpdateNotebookEngineProfile
 */
export const NotebookEngineProfileService_UpdateNotebookEngineProfile = new RPC<UpdateNotebookEngineProfileRequest,UpdateNotebookEngineProfileResponse>("PATCH", "/v1/{notebookEngineProfile.name=workspaces/*/notebookEngineProfiles/*}", "notebookEngineProfile");
/**
 * Deletes a NotebookEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineProfileService.DeleteNotebookEngineProfile
 */
export const NotebookEngineProfileService_DeleteNotebookEngineProfile = new RPC<DeleteNotebookEngineProfileRequest,DeleteNotebookEngineProfileResponse>("DELETE", "/v1/{name=workspaces/*/notebookEngineProfiles/*}");
