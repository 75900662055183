import { MessageBarType } from '@fluentui/react';
import { DetailsList, Item, MessageBar, useTheme } from '@h2oai/ui-kit';
import { useEffect, useMemo, useState } from 'react';

import {
  InstanceEventsRequest,
  InstanceEventsResponse,
} from '../../../../../admin-center/gen/app_event/instanceEvent/instanceevent_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { customTableStyles } from '../../../common/common.styles';
import { getDummyList } from '../../../common/utils';
import {
  InstanceEventName,
  InstanceEventOriginalName,
  InstanceEventTableColKey,
  InstanceEventTableColName,
  NA,
} from '../../../constants';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { tableStyle } from '../AppEvent.styles';
import {
  resumedEventStyles,
  startedEventStyles,
  suspendedEventStyles,
  terminatedEventStyles,
  updatedEventStyles,
} from './InstanceTable.styles';

interface InstanceEvent {
  instanceName: string;
  instanceEvent: string;
  instanceUser: string;
  instanceDate: string;
  instanceTime: string;
}

interface InstanceEventTableProps {
  startDate: string;
  endDate: string;
  searchQuery: string;
}

const dummyList = getDummyList<InstanceEvent>(8);

export default function InstanceTable({ startDate, endDate, searchQuery }: InstanceEventTableProps) {
  const service = useAdminCenterService();
  const theme = useTheme();
  const [instanceEventData, setInstanceEventData] = useState<InstanceEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchInstanceEvents = async () => {
    setIsLoading(true);
    try {
      const request: InstanceEventsRequest = {
        startDate: startDate,
        endDate: endDate,
      };

      const response: InstanceEventsResponse = await service.getInstanceEvents(request);

      const INSTANCE_EVENT_MAP: Record<string, string> = {
        [InstanceEventOriginalName.terminate]: InstanceEventName.terminate,
        [InstanceEventOriginalName.start]: InstanceEventName.start,
        [InstanceEventOriginalName.update]: InstanceEventName.update,
        [InstanceEventOriginalName.resume]: InstanceEventName.resume,
        [InstanceEventOriginalName.suspend]: InstanceEventName.suspend,
        nan: NA,
      };
      const getInstanceEventColumnEntry = (event: string | undefined): string => {
        if (!event) return NA;
        return INSTANCE_EVENT_MAP[event.toLowerCase()] || event;
      };

      const getInstanceTitleColumnEntry = (event: string | undefined): string => {
        if (!event) return NA;
        try {
          const parsedEvent = JSON.parse(event);
          if (parsedEvent && typeof parsedEvent === 'object' && 'appInstanceEvent' in parsedEvent) {
            const events = parsedEvent['appInstanceEvent'];
            const firstEventKey = Object.keys(events)[0];

            if (firstEventKey && events[firstEventKey] && events[firstEventKey]['instance']) {
              return events[firstEventKey]['app']['title'];
            }
          }
        } catch (e) {
          return NA;
        }
        return NA;
      };

      const formattedData = (response.events ?? []).map((event) => {
        const utcDate = event.ts ? new Date(event.ts) : null;

        return {
          instanceName: getInstanceTitleColumnEntry(event.label) ?? '-',
          instanceEvent: getInstanceEventColumnEntry(event.stream) ?? '-',
          instanceUser: event.userName ?? '-',
          instanceDate: utcDate
            ? utcDate.toLocaleDateString('en-CA', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
            : '-',
          instanceTime: utcDate
            ? utcDate.toLocaleTimeString('en-US', {
                hour12: false,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
            : '-',
        };
      });

      setInstanceEventData(formattedData);
    } catch (error) {
      console.error('Error fetching login events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInstanceEvents();
  }, [startDate, endDate]);

  const filteredData = useMemo(() => {
    const searchTerm = searchQuery.toLowerCase();
    return instanceEventData.filter(
      (item) =>
        item.instanceName.toLowerCase().includes(searchTerm) ||
        item.instanceEvent.toLowerCase().includes(searchTerm) ||
        item.instanceUser.toLowerCase().includes(searchTerm) ||
        item.instanceDate.toLowerCase().includes(searchTerm) ||
        item.instanceTime.toLowerCase().includes(searchTerm)
    );
  }, [instanceEventData, searchQuery]);

  const columns = [
    {
      key: InstanceEventTableColKey.name,
      fieldName: InstanceEventTableColKey.name,
      name: InstanceEventTableColName.name,
      minWidth: 200,
      onRender: (item: InstanceEvent) => (
        <>{!isLoading ? item.instanceName : <SkeletonLoader width="65%" height="100%" />}</>
      ),
    },
    {
      key: InstanceEventTableColKey.event,
      fieldName: InstanceEventTableColKey.event,
      name: InstanceEventTableColName.event,
      minWidth: 200,
      onRender: (item: any) => {
        if (isLoading) return <SkeletonLoader width="65%" height="100%" />;
        const event = item.instanceEvent as InstanceEventName;
        const eventStyles = {
          [InstanceEventName.start]: startedEventStyles,
          [InstanceEventName.terminate]: terminatedEventStyles,
          [InstanceEventName.resume]: resumedEventStyles,
          [InstanceEventName.suspend]: suspendedEventStyles,
          [InstanceEventName.update]: updatedEventStyles,
        };
        return (
          <div>
            <Item
              data={{
                id: '',
                title: event,
              }}
              styles={eventStyles[event] || {}}
              idField="id"
              labelField="title"
            />
          </div>
        );
      },
    },
    {
      key: InstanceEventTableColKey.user,
      fieldName: InstanceEventTableColKey.user,
      name: InstanceEventTableColName.user,
      minWidth: 200,
      onRender: (item: InstanceEvent) => (
        <>{!isLoading ? item.instanceUser : <SkeletonLoader width="65%" height="100%" />}</>
      ),
    },
    {
      key: InstanceEventTableColKey.date,
      fieldName: InstanceEventTableColKey.date,
      name: InstanceEventTableColName.date,
      minWidth: 200,
      onRender: (item: InstanceEvent) => (
        <>{!isLoading ? item.instanceDate : <SkeletonLoader width="65%" height="100%" />}</>
      ),
    },
    {
      key: InstanceEventTableColKey.time,
      fieldName: InstanceEventTableColKey.time,
      name: InstanceEventTableColName.time,
      minWidth: 200,
      onRender: (item: InstanceEvent) => (
        <>{!isLoading ? item.instanceTime : <SkeletonLoader width="65%" height="100%" />}</>
      ),
    },
  ];

  return (
    <div style={tableStyle(theme)}>
      {!isLoading && instanceEventData.length === 0 ? (
        <MessageBar messageBarType={MessageBarType.warning}>
          {'No data available for the selected date range'}
        </MessageBar>
      ) : (
        <DetailsList columns={columns} styles={customTableStyles(theme)} items={isLoading ? dummyList : filteredData} />
      )}
    </div>
  );
}
