import {
  AppNavigationProvider,
  GTagProvider,
  GTagProviderProps,
  Loader,
  loaderStylesSpinnerXLarge,
  setupGtm,
} from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';

import { GetEnvironmentDataResponse, User } from '../../ai.h2o.cloud.appstore';
import { AutoLogout } from '../../components/AutoLogout/AutoLogout';
import { getAppStoreMenuInfo } from '../../components/Navigation/utils';
import { AuthService, EnvService } from '../../services/api';
import { ErrorCode } from '../../services/HTTPResponse';
import { EnvProvider, EnvironmentAndMenu, UserProvider } from '../../utils/contexts';
import { useCloudPlatformDiscovery, useDynamicFavicon } from '../../utils/hooks';
import { fetchAppStoreVersion } from '../../utils/utils';
import { OopsPage } from '../ErrorPage';
import { Protected } from '../Protected/Protected';
import { Routes } from '../Routes';

function AuthPage() {
  const auth = useAuth();
  const history = useHistory();
  const platformDiscovery = useCloudPlatformDiscovery();
  const [haveCookie, setHaveCookie] = useState<boolean>(false);
  const [gTag, setGTag] = useState<GTagProviderProps>({ isGtmReady: false, user: {} });
  const [user, setUser] = useState<User | null>(null);
  const [env, setEnv] = useState<EnvironmentAndMenu | undefined>();
  const [loading, setLoading] = useState(true);
  const [oopsError, setOopsError] = useState(false);
  useDynamicFavicon(env?.logoUrl || './favicon.ico');

  const fetchEnvAndUser = async () => {
    try {
      setLoading(true);

      const [fetchEnvResponse, fetchUserResponse] = await Promise.all([
        EnvService.getEnvironmentData({}),
        AuthService.checkAuth({}),
      ]);
      const { user } = fetchUserResponse;

      if (!user) {
        void signOutUser();
        return;
      }

      setUser(user);

      if (user && fetchEnvResponse) {
        const { navLinks = [], bookTimeLink, homePage, federationEnabled, environmentName } = fetchEnvResponse;
        setEnv({
          ...fetchEnvResponse,
          menu: getAppStoreMenuInfo(
            !!platformDiscovery?.aiEngineManagerApiUrl,
            user,
            navLinks,
            bookTimeLink,
            homePage.enabled,
            federationEnabled,
            '',
            true,
            undefined,
            environmentName,
            fetchEnvResponse.platformUsageEnabled,
            fetchEnvResponse.publicModeEnabled && !user.hasFullAccess,
            !!platformDiscovery?.notebookApiUrl,
            !!platformDiscovery?.secureStore,
            fetchEnvResponse.cliDownloadEnabled,
            fetchEnvResponse.adminCenterEnabled
          ),
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.code === ErrorCode.PermissionDenied && history.location.pathname !== '/403') {
        history.push('403');
      } else if (error.code !== ErrorCode.Unauthenticated) {
        setOopsError(true);
      }
    } finally {
      setLoading(false);
    }
  };
  const signOutUser = async () => {
    const token = auth.user?.id_token;
    await auth.removeUser();
    await auth.signoutRedirect({ id_token_hint: token });
  };

  useEffect(() => {
    if (auth.isLoading) return;
    // now that we are loaded, be sure to add id_token_hint for automatic logouts:
    auth.events.addAccessTokenExpired(signOutUser);
    void fetchEnvAndUser();
  }, [auth.isLoading]);

  /* Set up Google Tag Manager after user and env responses are ready */
  useEffect(() => {
    const loadGtm = async (_envResponse: GetEnvironmentDataResponse, _user: User) => {
      try {
        const { tagManagerId } = _envResponse;
        const haicVersion = tagManagerId ? (await fetchAppStoreVersion()) || 'Unknown version' : '';
        setGTag(await setupGtm(TagManager, tagManagerId, _user, haicVersion));
      } catch (error) {
        console.error('Google tag manager initialization failed');
        console.error(error);
      }
    };
    if (user && env) loadGtm(env, user);
  }, [env, user]);

  /* Sign-in if unauthenticated and not in public mode */
  useEffect(() => {
    if (loading || env?.publicModeEnabled) return;
    if (!auth.isAuthenticated && !auth.isLoading) void auth.signinRedirect();
  }, [loading, env, auth.isLoading, auth.isAuthenticated]);

  /* Set cookie for Wave app authentication */
  useEffect(() => {
    const envURL = process.env.REACT_APP_API_PATH || '';

    if (auth.user?.id_token) {
      const controller = new AbortController();
      const signal = controller.signal;
      fetch(`${envURL}/auth/session`, {
        method: 'GET',
        signal,
      })
        .then(() => {
          setHaveCookie(true);
        })
        .catch((error) => {
          console.error('There was an error obtaining the session cookie:', error);
        });
      return () => {
        controller.abort();
      };
    }

    return;
  }, [auth.user?.id_token]);

  useEffect(() => {
    if (history.location.pathname.includes('logout')) {
      history.push('/');
    }
  }, []);

  // TODO: how to handle logout on one tab, while other tab is still open?

  const isAuthenticated = auth.user?.access_token && haveCookie;
  const dataReady = user && env;
  const showApp = dataReady && (isAuthenticated || env?.publicModeEnabled);

  if (history.location.pathname.includes('403') || history.location.pathname.includes('500')) {
    return <Routes />;
  }

  if (oopsError) return <OopsPage />;

  return showApp ? (
    <GTagProvider {...gTag}>
      <EnvProvider env={env}>
        <UserProvider user={user}>
          {auth.user && <AutoLogout />}
          <AppNavigationProvider>
            <Protected />
          </AppNavigationProvider>
        </UserProvider>
      </EnvProvider>
    </GTagProvider>
  ) : (
    <Loader styles={loaderStylesSpinnerXLarge} label="Loading..." />
  );
}

export default AuthPage;
