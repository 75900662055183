import { DefaultButton, IButtonStyles, IContextualMenuProps, Shimmer, Stack } from '@fluentui/react';
import { Checkbox, CommonStyles, FontSizes, IH2OTheme, useTheme } from '@h2oai/ui-kit';
import { CSSProperties, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { filterSummarizeSelection, useEngine, useEngineQueryParams } from '../../../aiem/engine/hooks';
import { EngineVersion } from '../../../aiem/engine/types';
import { Engine_Type } from '../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { VersionSelection } from '../filter.utils';

type ValidTypes = Engine_Type.DRIVERLESS_AI | Engine_Type.H2O;

type IContextualMenuItemTypeVersionData = {
  type: ValidTypes;
  version: string;
  checked: boolean;
};

type VersionSelectionsMap = {
  [P in Engine_Type as string]: VersionSelection;
};

const buttonId = 'engine-version-filter-button';
const getLabel = (selected = 0, total = 0) => `${selected} of ${total} selected`;

function getSubMenuContent(
  type: ValidTypes,
  menuVersionSelections: VersionSelectionsMap,
  onToggleVersion: (
    ev: FormEvent<HTMLElement | HTMLInputElement> | undefined,
    data: IContextualMenuItemTypeVersionData
  ) => void
) {
  const versionSelections = menuVersionSelections[type];
  if (!versionSelections) return [];
  const keys = Object.keys(versionSelections);
  const onRender = () =>
    keys.map((key) => (
      <div
        key={`${key}`}
        className="ms-ContextualMenu-link"
        style={{ ...(CommonStyles.flexCenterVertically as CSSProperties) }}
      >
        <Checkbox
          className="ms-ContextualMenu-checkbox"
          label={key}
          checked={versionSelections[key]}
          onChange={(ev, checked = false) => {
            onToggleVersion && onToggleVersion(ev, { type, version: key, checked });
          }}
        />
      </div>
    ));

  return [
    {
      key: type,
      text: type,
      canCheck: true,
      checked: false,
      onRender,
    },
  ];
}

const EngineVersionFilter = () => {
  const theme = useTheme();

  const buttonStylesDropdown = (theme: IH2OTheme): IButtonStyles => ({
    root: {
      color: theme.semanticColors?.inputReadOnlyText,
      backgroundColor: theme.palette?.white,
      borderColor: theme.semanticColors?.inputBorder,
      borderRadius: 4,
      minWidth: 220,
    },
    rootFocused: {
      backgroundColor: theme.palette?.white,
      borderColor: theme.semanticColors?.inputActiveBorder,
      color: theme.semanticColors?.inputReadOnlyText,
    },
    rootPressed: {
      backgroundColor: theme.palette?.white,
      borderColor: theme.semanticColors?.inputActiveBorder,
      color: theme.semanticColors?.inputReadOnlyText,
    },
    rootHovered: {
      backgroundColor: theme.palette?.white,
      borderColor: theme.semanticColors?.inputActiveBorder,
      color: theme.semanticColors?.inputReadOnlyText,
    },
    label: {
      color: theme.semanticColors?.inputReadOnlyText,
      fontSize: FontSizes.input,
      fontWeight: 'normal',
    },
  });

  const { listVersions } = useEngine();
  const {
    params: { versions: versionsParam },
    setTypeVersions,
  } = useEngineQueryParams();
  const [versionOptions, setVersionOptions] = useState({});

  const castVersionSelection = useCallback(
    (acc: VersionSelection, { key, type }: EngineVersion): VersionSelection => {
      return {
        ...acc,
        [key!]: type! in versionsParam && key! in versionsParam[type!] && versionsParam[type!][key!],
      } as VersionSelection;
    },
    [versionsParam]
  );

  const fetchVersions = useCallback(async () => {
    const [daiVersions, h2oVersions] = await Promise.all([
      await listVersions(Engine_Type.DRIVERLESS_AI),
      await listVersions(Engine_Type.H2O),
    ]);

    setVersionOptions({
      [Engine_Type.DRIVERLESS_AI]: daiVersions,
      [Engine_Type.H2O]: h2oVersions,
    });
  }, [castVersionSelection, listVersions, setVersionOptions]);

  const versionsWithSelections = useMemo(() => {
    return {
      [Engine_Type.DRIVERLESS_AI]: versionOptions[Engine_Type.DRIVERLESS_AI]?.reduce(castVersionSelection, {}),
      [Engine_Type.H2O]: versionOptions[Engine_Type.H2O]?.reduce(castVersionSelection, {}),
    };
  }, [versionOptions, castVersionSelection]);

  const daiVersionSelections = versionsWithSelections[Engine_Type.DRIVERLESS_AI],
    h2oVersionSelections = versionsWithSelections[Engine_Type.H2O];

  const { summary } = useMemo(() => filterSummarizeSelection(versionsWithSelections), [versionsWithSelections]);
  const { totalVersions, totalSelected } = summary;

  const onChangeVersion = useCallback(
    (ev: FormEvent<HTMLElement | HTMLInputElement> | undefined, data: IContextualMenuItemTypeVersionData): void => {
      ev && ev.preventDefault();
      const { type, version, checked } = (data || {}) as IContextualMenuItemTypeVersionData;
      const versionSelectionsOfType = versionsWithSelections[type];
      const result = {
        ...versionsWithSelections,
        [type]: {
          ...versionSelectionsOfType,
          [version]: checked,
        },
      };
      setTypeVersions(result);
    },
    [versionsWithSelections]
  );

  const menuProps: IContextualMenuProps = useMemo(() => {
    const types = summary?.types || {};
    const daiSummary = getLabel(
        types[Engine_Type.DRIVERLESS_AI]?.totalSelected,
        types[Engine_Type.DRIVERLESS_AI]?.totalVersions
      ),
      h2oSummary = getLabel(types[Engine_Type.H2O]?.totalSelected, types[Engine_Type.H2O]?.totalVersions);
    return {
      shouldFocusOnMount: true,
      items: [
        {
          key: Engine_Type.DRIVERLESS_AI,
          text: `Driverless AI: ${daiSummary}`,
          subMenuProps: {
            items: getSubMenuContent(Engine_Type.DRIVERLESS_AI, versionsWithSelections, onChangeVersion),
          },
        },
        {
          key: Engine_Type.H2O,
          text: `H2O 3: ${h2oSummary}`,
          subMenuProps: {
            items: getSubMenuContent(Engine_Type.H2O, versionsWithSelections, onChangeVersion),
          },
        },
      ],
    };
  }, [summary, versionsWithSelections, onChangeVersion]);

  useEffect(() => {
    fetchVersions();
  }, []);

  const showButton = h2oVersionSelections || daiVersionSelections;

  return (
    <Stack tokens={{ childrenGap: 4 }}>
      <label htmlFor={buttonId}>Engine & Version</label>
      {showButton ? (
        <DefaultButton
          id={buttonId}
          text={getLabel(totalSelected, totalVersions)}
          menuProps={menuProps}
          styles={buttonStylesDropdown(theme)}
        />
      ) : (
        <Shimmer width="100%" />
      )}
    </Stack>
  );
};

export default EngineVersionFilter;
