import { IDropdownOption, Icon, Stack } from '@fluentui/react';
import {
  Button,
  Dropdown,
  IKeyValuePairEditorClassnames,
  IKeyValuePairEditorStyles,
  IconName,
  buttonStylesGhost,
  buttonStylesPrimary,
  useClassNames,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { RoleBinding } from '../../../../authz/gen/ai/h2o/authorization/v1/role_binding_pb';
import { Group } from '../../../../authz/gen/ai/h2o/user/v1/group_pb';
import { User } from '../../../../authz/gen/ai/h2o/user/v1/user_pb';
import { useRoles } from '../../../../authz/providers/RoleProvider';
import { useUsers } from '../../../../authz/providers/UsersProvider';
import { groupRoles } from '../../utils';
import { MembersDropdown } from './MembersDropdown';
import { membersListStyles } from './styles';
import { MemberListItemProps } from './types';

export const MemberListItem = (props: MemberListItemProps) => {
  const { prepopulatedRoleBindings = [], usersInWorkspaceRolesList = [], updateRoleBindings } = props;

  const { allRoles } = useRoles();
  const { usersAndGroups: availableUsersAndGroups = [], usersAndGroupsNameMap } = useUsers();

  // If there are prepopulated role bindings, the user is selected in EDIT mode as the assigned one.
  const prepopulatedUserOrGroup = useMemo(() => {
    if (prepopulatedRoleBindings.length > 0 && usersAndGroupsNameMap) {
      return usersAndGroupsNameMap[prepopulatedRoleBindings[0].subject || ''];
    }
    return undefined;
  }, [prepopulatedRoleBindings, usersAndGroupsNameMap]);
  const isGroup = prepopulatedUserOrGroup && prepopulatedUserOrGroup.name?.includes('group');
  const [selectedUserOrGroup, setSelectedUserOrGroup] = useState<User | undefined>(prepopulatedUserOrGroup);
  const [selectedRoles, setSelectedRoles] = useState<RoleBinding[]>(prepopulatedRoleBindings);
  const [selectedRoleKeys, setSelectedRoleKeys] = useState<string[]>();
  const userRoleOptions = useMemo(() => {
    if (!allRoles) return [];

    const roleOptions: IDropdownOption[] = allRoles.map((role) => ({
      key: role.name || '',
      text: role.displayName || '',
    }));

    return groupRoles(roleOptions);
  }, [allRoles]);
  const selectedUsersAndGroupsInList = useMemo(() => {
    return availableUsersAndGroups
      .filter((user) => {
        const userName = user.name?.trim().toLowerCase();
        return userName && usersInWorkspaceRolesList.some((role) => role.trim().toLowerCase() === userName);
      })
      .map(({ name }) => name!)
      .filter(Boolean);
  }, [availableUsersAndGroups, usersInWorkspaceRolesList]);

  const handleRoleUpdate = useCallback(
    (_e, roleOption) => {
      if (roleOption?.selected) {
        setSelectedRoles([...selectedRoles, { role: String(roleOption.key), subject: selectedUserOrGroup?.name }]);
      } else {
        setSelectedRoles([...selectedRoles].filter((role) => role.role !== roleOption?.key));
      }

      if (prepopulatedUserOrGroup) {
        updateRoleBindings(
          [
            {
              role: String(roleOption.key),
              subject: selectedUserOrGroup?.name,
              name: prepopulatedRoleBindings.filter((binding) => binding.role === roleOption.key)[0].name,
            },
          ],
          roleOption?.selected
        );
      }
    },
    [selectedRoles, setSelectedRoles, selectedUserOrGroup]
  );
  const resetForm = useCallback(() => {
    setSelectedUserOrGroup({ name: '' });
    setSelectedRoles([]);
    setSelectedRoleKeys([]);
  }, [setSelectedUserOrGroup, setSelectedRoles, setSelectedRoleKeys]);
  const handleAddUser = useCallback(() => {
    updateRoleBindings(selectedRoles, true);
    resetForm();
  }, [selectedRoles, updateRoleBindings]);
  const handleRemoveAllUserBindings = useCallback(() => {
    updateRoleBindings(selectedRoles);
  }, [selectedRoles, updateRoleBindings]);

  useEffect(() => {
    if (selectedRoles) {
      setSelectedRoleKeys(selectedRoles.map((role) => role.role || ''));
    }
  }, [selectedRoles, setSelectedRoleKeys]);

  const classNames = useClassNames<IKeyValuePairEditorStyles, IKeyValuePairEditorClassnames>(
    'MembersList',
    membersListStyles
  );

  return (
    <Stack horizontal horizontalAlign={'space-between'} className={classNames.body}>
      <div className={classNames.keyColumn}>
        {prepopulatedUserOrGroup ? (
          <Stack horizontal verticalAlign="center">
            {isGroup && <Icon iconName={IconName.Group} style={{ marginRight: 10 }} title="Group" />}
            <span>{prepopulatedUserOrGroup?.displayName}</span>
          </Stack>
        ) : (
          <MembersDropdown
            onUserChange={(option) => setSelectedUserOrGroup(option as User | Group)}
            prepopulatedUser={selectedUserOrGroup}
            disableOptions={selectedUsersAndGroupsInList}
            fetchGroups
          />
        )}
      </div>
      <div className={classNames.valueColumn}>
        <Dropdown
          multiSelect
          selectedKeys={selectedRoleKeys}
          onChange={handleRoleUpdate}
          placeholder="Select Permission"
          options={userRoleOptions}
          disabled={!selectedUserOrGroup?.name}
        />
      </div>
      <div className={classNames.buttonColumn}>
        {!prepopulatedUserOrGroup && (
          <Button
            styles={[buttonStylesPrimary]}
            split
            text="Add User"
            onClick={handleAddUser}
            disabled={!selectedUserOrGroup || !selectedRoles?.length}
          />
        )}
        {prepopulatedUserOrGroup && (
          <Button styles={[buttonStylesGhost]} text="Remove" onClick={handleRemoveAllUserBindings} />
        )}
      </div>
    </Stack>
  );
};
