import { Button, Dropdown, Panel, TextField, buttonStylesGhost, buttonStylesPrimary, useTheme } from '@h2oai/ui-kit';
import React, { useCallback, useState } from 'react';

import { FwPortLocalUpdateRequest } from '../../../../../admin-center/gen/fw_management/fw_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { useUser } from '../../../../../utils/hooks';
import { protocolKey, protocolType } from '../../../constants';
import { sidePanelFooterStyle } from '../FWManagement.styles';

interface PortPanelProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitSuccess: () => Promise<void>;
}

export default function PortSidePanel({ isOpen, setOpen, onSubmitSuccess }: PortPanelProps) {
  const service = useAdminCenterService();
  const user = useUser();
  const theme = useTheme();
  const [portValue, setPortValue] = useState('');
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const handlePortChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setPortValue(newValue || '');
  };

  const [selectedProtocol, setSelectedProtocol] = useState(protocolKey.tcp);
  const onProtocolChange = (_event: React.FormEvent<HTMLDivElement>, item: any) => {
    const { key } = item;
    setSelectedProtocol(key);
  };
  const portOptions = [
    { key: protocolKey.tcp, text: protocolType.tcp },
    { key: protocolKey.udp, text: protocolType.udp },
  ];

  const dismissPanel = useCallback(() => {
    setOpen(false);
    setResponseMessage(null);
  }, [setOpen]);
  const isFormValid = useCallback(() => {
    return selectedProtocol && portValue !== null && portValue !== undefined && portValue !== '';
  }, [selectedProtocol, portValue]);

  const handleSubmitClick = useCallback(async () => {
    if (!isFormValid()) return;

    const portList: string[] =
      portValue && selectedProtocol
        ? portValue
            .split(',')
            .map((port) => port.trim())
            .filter((port) => port !== '' && !isNaN(Number(port)))
            .map((port) => `${port}-${selectedProtocol}`)
        : [];

    try {
      const request: FwPortLocalUpdateRequest = {
        newFwPorts: portList,
        modifiedBy: user?.name ?? '',
        timestamp: Date.now() / 1000,
      };
      const response = await service.updateLocalFwPorts(request);
      if (response.statusUpdate) {
        setResponseMessage(response.statusUpdate);
      } else {
        setResponseMessage('❌ Error: Failed to update');
      }
      await onSubmitSuccess();
    } catch (error) {
      setResponseMessage(`❌ Network error: ${error}`);
    }
  }, [portValue, onSubmitSuccess, isFormValid]);

  const onRenderFooterContent = useCallback(
    () => (
      <>
        <div style={sidePanelFooterStyle}>
          <Button styles={buttonStylesGhost} text="Cancel" onClick={dismissPanel} style={{ marginRight: 10 }} />
          <Button styles={buttonStylesPrimary} text="Add Port" onClick={handleSubmitClick} disabled={!isFormValid()} />
        </div>
      </>
    ),
    [dismissPanel, handleSubmitClick, isFormValid]
  );

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      onRenderFooterContent={onRenderFooterContent}
      customWidth="500px"
      type={7}
    >
      <h2>
        <strong>Add Outbound Port</strong>
      </h2>
      <h3>Enable outbound ports in your cloud environment</h3>
      <Dropdown
        label="Protocol"
        placeholder="Select protocol type"
        options={portOptions}
        selectedKey={selectedProtocol}
        onChange={onProtocolChange}
        required={true}
      />
      <TextField
        label="Outbound Port(s)"
        placeholder="Add comma seperated ports. Ex: 8080, 443"
        value={portValue}
        onChange={handlePortChange}
        required={true}
      />
      {responseMessage && (
        <p
          style={{
            marginTop: 10,
            color: responseMessage.includes('❌') ? theme.palette?.red300 : theme.palette?.green300,
          }}
        >
          {responseMessage}
        </p>
      )}
    </Panel>
  );
}
