import { ConfirmDialog } from '@h2oai/ui-kit';
import React from 'react';

type DialogProps = {
  title?: string;
  description?: string;
  confirmationButtonText?: string;
  onConfirm: () => void;
  dismissalButtonText?: string;
  onDismiss?: () => void;
  showCloseButton?: boolean;
};

type ConfirmDialogContextType = {
  showDialog?: (props: DialogProps) => void;
};

const defaultDialogProps: DialogProps = {
  title: 'Are you sure?',
  description: 'This action cannot be undone.',
  confirmationButtonText: 'Delete',
  dismissalButtonText: 'Cancel',
  onConfirm: () => {},
  onDismiss: () => {},
  showCloseButton: true,
};

const ConfirmDialogContext = React.createContext<ConfirmDialogContextType | undefined>(undefined);

const ConfirmDialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false),
    [dialogProps, setDialogProps] = React.useState<DialogProps>(defaultDialogProps);

  const value = {
    showDialog: (props: DialogProps) => {
      setDialogProps({ ...defaultDialogProps, ...props });
      setIsDialogOpen(true);
    },
  };

  // HACK: Dialog is rendered only when opened due to failing UI tests.
  // TODO: Fix the issue and remove this hack.
  return (
    <ConfirmDialogContext.Provider value={value}>
      {isDialogOpen ? (
        <ConfirmDialog
          confirmationButtonText={dialogProps.confirmationButtonText}
          onConfirm={() => {
            dialogProps.onConfirm();
            setIsDialogOpen(false);
          }}
          dismissalButtonText={dialogProps.dismissalButtonText}
          onDismiss={() => {
            dialogProps?.onDismiss?.();
            setIsDialogOpen(false);
          }}
          hidden={!isDialogOpen}
          dialogContentProps={{
            title: dialogProps.title,
            subText: dialogProps.description,
            showCloseButton: dialogProps.showCloseButton,
          }}
        />
      ) : null}
      {children}
    </ConfirmDialogContext.Provider>
  );
};

const useConfirmDialog = () => {
  const context = React.useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error('useConfirmDialog must be used within a ConfirmDialogProvider');
  }
  return context;
};

export { ConfirmDialogProvider, useConfirmDialog };
