import { type IComboBoxOption, Icon, Stack } from '@fluentui/react';
import {
  IconName,
  NextPageTokens,
  PaginatedSearchComboBox,
  TooltipHost,
  getUniqueOptionsByKeys,
  useTheme,
} from '@h2oai/ui-kit';
import { Link } from 'react-router-dom';

import type { Workspace } from '../../../authz/gen/ai/h2o/workspace/v1/workspace_pb';
import { useAuthzService } from '../../../authz/hooks';
import { useWorkspaces } from '../../../pages/Orchestrator/WorkspaceProvider';
import { RoutePaths } from '../../../pages/Routes';
import { manageWorkspacesWrapperStyles, membersDropdownWrapperStyles } from '../Navigation.styles';

interface WorkspacesDropdownProps {
  disabled: boolean;
}

const PAGE_SIZE = 100;

export const WorkspacesDropdown = (props: WorkspacesDropdownProps) => {
  const { activeWorkspace, activateWorkspace } = useWorkspaces();
  const theme = useTheme();
  const authzService = useAuthzService();

  const fetchWorkspaces = async (
    searchQuery: string,
    nextPageToken: NextPageTokens
  ): Promise<{
    items: IComboBoxOption[];
    nextPageToken: NextPageTokens;
  }> => {
    const data = await authzService.getWorkspaces({
      pageSize: PAGE_SIZE,
      pageToken: (nextPageToken as string) || undefined,
      filter: `"${searchQuery}"`,
    });

    const workspaceItems: IComboBoxOption[] =
      data?.workspaces?.map((workspace) => ({
        key: workspace.name || '',
        text: workspace.displayName || '',
        data: workspace,
      })) || [];

    return {
      // Merge the active workspace into the response
      items: getUniqueOptionsByKeys(
        workspaceItems,
        !searchQuery
          ? [
              {
                key: String(activeWorkspace?.name),
                text: String(activeWorkspace?.displayName),
                data: activeWorkspace,
              },
            ]
          : [],
        'key'
      ),
      nextPageToken: data?.nextPageToken || '',
    };
  };
  const handleWorkspaceChange = (option: IComboBoxOption) => {
    activateWorkspace((option as Workspace).name || '');
  };

  return activeWorkspace ? (
    <Stack styles={membersDropdownWrapperStyles(theme)} style={{ width: '100%' }}>
      <PaginatedSearchComboBox
        disabled={props.disabled}
        fetchItems={fetchWorkspaces}
        placeholder="Select Workspace"
        prepopulatedItem={
          activeWorkspace
            ? {
                key: activeWorkspace.name || '',
                text: activeWorkspace.displayName || '',
                data: activeWorkspace,
              }
            : undefined
        }
        onOptionChange={handleWorkspaceChange}
        onRenderLowerContent={() => (
          <Stack styles={manageWorkspacesWrapperStyles(theme)}>
            <Link to={RoutePaths.MANAGE_WORKSPACES}>Manage Workspaces</Link>
          </Stack>
        )}
      />
      {props.disabled && (
        <TooltipHost content="Switching workspaces is not supported on this page." id="disabled-workspaces-dropdown">
          <Icon iconName={IconName.InfoSolid} />
        </TooltipHost>
      )}
    </Stack>
  ) : null;
};
