import { Dropdown } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { AiUnitHourlyComponentRequest } from '../../../../../admin-center/gen/aiUnitHourly/aiUnitHourly_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { MonthDropdownStyle, PageStyle } from '../AIUnitHourly.styles';
import ComponentMonthPlot from './ComponentMonthPlot';
import ComponentPlot from './ComponentPlot';

interface AiUnitHourlyComponentEvent {
  date: string;
  month: string;
  year: string;
  resource: string;
  aiUnits: number;
  cumAiUnits: number;
}

interface HourlyAiUnitData {
  events: AiUnitHourlyComponentEvent[];
  monthlyEvents: AiUnitHourlyComponentEvent[];
  threshold: number;
  monthlyThreshold: number;
  maxPercentage: number;
  monthlyMaxPercentage: number;
}

const getCurrentMonth = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
};

export default function ComponentSummary() {
  const [selectedMonthKey, setSelectedMonthKey] = useState<string>(getCurrentMonth());
  const [componentData, setComponentData] = useState<HourlyAiUnitData | null>(null);
  const [months, setMonths] = useState<{ key: string; text: string }[]>([]);
  const service = useAdminCenterService();

  const onMonthChange = (_event: React.FormEvent<HTMLDivElement>, item: any) => {
    const { key } = item;
    setSelectedMonthKey(key);
  };

  const fetchHourlyAIUnitComponentData = async (month?: string) => {
    try {
      const request: AiUnitHourlyComponentRequest = {
        month: month || getCurrentMonth(),
      };

      const response = await service.getHourlyAiUnitComponentUsage(request);
      setComponentData(response as HourlyAiUnitData);

      if (response.events && response.events.length > 0) {
        const events = response.events as AiUnitHourlyComponentEvent[];
        const uniqueMonths = Array.from(new Set(events.map((event) => event.month))).map((month) => ({
          key: month,
          text: month,
        }));

        setMonths(uniqueMonths);
      } else {
        console.warn('No events found in the response.');
        setMonths([]);
      }
    } catch (error) {
      console.error('Error fetching Hourly ai unit valus:', error);
    }
  };

  useEffect(() => {
    fetchHourlyAIUnitComponentData(selectedMonthKey);
  }, [selectedMonthKey]);

  return (
    <div>
      <p>The following plot displays daily cumulative hourly usage per component.</p>
      <div style={PageStyle}>
        <ComponentPlot events={componentData?.events || []} />
        <div style={MonthDropdownStyle}>
          <Dropdown
            placeholder="Select time frame"
            options={months}
            selectedKey={selectedMonthKey}
            onChange={onMonthChange}
            width={'100%'}
            style={{ marginRight: '20px' }}
          />
        </div>
        <ComponentMonthPlot events={componentData?.monthlyEvents || []} />
      </div>
    </div>
  );
}
