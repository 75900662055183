import {
  CheckboxVisibility,
  DetailsListLayoutMode,
  IColumn,
  MessageBarType,
  Selection,
  SelectionMode,
} from '@fluentui/react';
import {
  Button,
  ConfirmDialog,
  DetailsList,
  Item,
  MessageBar,
  Search,
  buttonStylesDanger,
  buttonStylesGhost,
  buttonStylesPrimary,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { RemoveMemberRequest } from '../../../../../admin-center/gen/user_management/user_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { useUser } from '../../../../../utils/hooks';
import { messageBarContainerStyles } from '../../../common/common.styles';
import { getDummyList } from '../../../common/utils';
import { OktaStatusIdpLabel, OktaStatusLabel, UMUserTableColumnKey, UMUserTableColumnName } from '../../../constants';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import AddAsAdminConfirmation from '../AddAsAdminConfirmation/AddAsAdminConfirmation';
import ResendEmailConfirmation from '../ResendEmailConfirmation/ResendEmailConfirmation';
import {
  actionButtonContainerStyles,
  actionContainerStyles,
  activeUserStyles,
  deactivatedUserStyles,
  lockedOutUserStyles,
  passwordExpiredUserStyles,
  passwordResetUserStyles,
  pendingActionUserStyles,
  searchStyle,
  stagedUserStyles,
  suspendedUserStyles,
  tableContainerStyles,
  unkownUserStatusStyles,
  userManagementTableStyles,
} from '../UserManagement.styles';

interface UserProfile {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  id: string | undefined;
  status: string | undefined;
  login: string | undefined;
}

interface TableItem {
  userName: string;
  userEmail: string;
  userStatus: string;
}

interface UserTableProps {
  users: UserProfile[];
  isLoading: boolean;
  onSubmitSuccess: () => Promise<void>;
}

const dummyList = getDummyList<TableItem>(10);

export default function UserTable({ users, isLoading, onSubmitSuccess }: UserTableProps) {
  const service = useAdminCenterService();
  const theme = useTheme();
  const user = useUser();
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [tableSelections, setTableSelections] = useState<TableItem[]>([]);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);
  const [showResendEmailConfirmationDialog, setShowResendEmailConfirmationDialog] = useState<boolean>(false);
  const [showAddAsAdminsConfirmationDialog, setShowAddAsAdminsConfirmationDialog] = useState<boolean>(false);
  const [isDeletionPending, setIsDeletionPending] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showResponseMessage, setShowResponseMessage] = useState<boolean>(false);

  const selectionRef = useRef<Selection>(
    new Selection({
      onSelectionChanged: () => {
        const selectedItems = selectionRef.current.getSelection();
        handleSelectionChanged(selectedItems);
      },
      getKey: (item: any) => item.userEmail,
    })
  );

  const handleSelectionChanged = useCallback((items: any[]) => {
    setTableSelections(items);
  }, []);

  const userData = users.map((user) => ({
    userName: `${user.firstName || ''} ${user.lastName || ''}`,
    userStatus:
      (user.status?.endsWith('-IDP') ? OktaStatusIdpLabel[user.status] : OktaStatusLabel[user.status ?? '']) ||
      'Unknown',
    userEmail: user.email || 'Unknown',
  }));

  useEffect(() => {
    setTableSelections([]);
  }, [isLoading]);

  const handleDelete = async () => {
    setIsDeletionPending(true);
    const selectedUsers = tableSelections;
    const membersList = selectedUsers
      .filter((selectedUser) => user?.name !== selectedUser.userEmail)
      .map((selectedUser) => {
        const [firstName, ...lastNameParts] = selectedUser.userName.split(' ');
        return {
          firstName: firstName || '',
          lastName: lastNameParts.join(' ') || '',
          email: selectedUser.userEmail,
        };
      });

    if (membersList.length !== selectedUsers.length) {
      setResponseMessage('❌ Self-deletion is not allowed');
    }

    try {
      const request: RemoveMemberRequest = {
        members: membersList,
        memberLevel: 'user',
        modifiedBy: user?.name ?? '',
        timestamp: Date.now() / 1000,
      };
      const response = await service.removeMembers(request);

      if (response.statusUpdate) {
        setResponseMessage(response.statusUpdate);
      } else {
        setResponseMessage('❌ Error: Failed to delete');
      }

      await onSubmitSuccess();
    } catch (error) {
      setResponseMessage(`❌ Network error: ${error}`);
    } finally {
      setIsDeletionPending(false);
      setShowDeleteConfirmationDialog(false);
      setShowResponseMessage(true);
    }
  };

  const filteredData = useMemo(() => {
    const searchTerm = searchQuery.toLowerCase();
    return userData.filter(
      (item) =>
        item.userName.toLowerCase().includes(searchTerm) ||
        item.userEmail.toLowerCase().includes(searchTerm) ||
        item.userStatus.toLowerCase().includes(searchTerm)
    );
  }, [userData, searchQuery]);

  const columns: IColumn[] = [
    {
      key: UMUserTableColumnKey.name,
      fieldName: UMUserTableColumnKey.name,
      name: UMUserTableColumnName.name,
      minWidth: 60,
      maxWidth: 190,
      onRender: (item: TableItem) => <>{!isLoading ? item.userName : <SkeletonLoader width="65%" height="50%" />}</>,
    },
    {
      key: UMUserTableColumnKey.status,
      fieldName: UMUserTableColumnKey.status,
      name: UMUserTableColumnName.status,
      minWidth: 45,
      maxWidth: 110,
      onRender: (user: any) => {
        if (isLoading) return <SkeletonLoader width="65%" height="50%" />;
        const statusStylesMap = new Map([
          ['Active', activeUserStyles],
          ['Active - IdP', activeUserStyles],
          ['Staged', stagedUserStyles],
          ['Staged - IdP', stagedUserStyles],
          ['Pending User Action', pendingActionUserStyles],
          ['Pending User Action - IdP', pendingActionUserStyles],
          ['Password Reset', passwordResetUserStyles],
          ['Password Reset - IdP', passwordResetUserStyles],
          ['Password Expired', passwordExpiredUserStyles],
          ['Password Expired - IdP', passwordExpiredUserStyles],
          ['Locked Out', lockedOutUserStyles],
          ['Locked Out - IdP', lockedOutUserStyles],
          ['Suspended', suspendedUserStyles],
          ['Suspended - IdP', suspendedUserStyles],
          ['Deactivated', deactivatedUserStyles],
          ['Deactivated - IdP', deactivatedUserStyles],
        ]);

        return (
          <div>
            <Item
              data={{ id: '', title: user.userStatus }}
              styles={statusStylesMap.get(user.userStatus || '') || unkownUserStatusStyles}
              idField="id"
              labelField="title"
            />
          </div>
        );
      },
    },
    {
      key: UMUserTableColumnKey.email,
      fieldName: UMUserTableColumnKey.email,
      name: UMUserTableColumnName.email,
      minWidth: 180,
      onRender: (item: TableItem) => <>{!isLoading ? item.userEmail : <SkeletonLoader width="65%" height="50%" />}</>,
    },
  ];

  return (
    <>
      {showResponseMessage && (
        <div style={messageBarContainerStyles}>
          <MessageBar
            timeout={2000}
            onDismiss={() => setShowResponseMessage(false)}
            messageBarType={responseMessage?.includes('❌') ? MessageBarType.error : MessageBarType.success}
          >
            {responseMessage}
          </MessageBar>
        </div>
      )}
      <div>
        <div style={actionContainerStyles()}>
          <Search
            emptyMessage="No results found"
            placeholder="Search Users..."
            onSearchTextChange={(text: string) => setSearchQuery(text)}
            style={searchStyle}
          />
          <div style={actionButtonContainerStyles()}>
            <Button
              text="Resend Emails"
              styles={buttonStylesGhost}
              disabled={tableSelections.length === 0 || isLoading}
              onClick={() => setShowResendEmailConfirmationDialog(true)}
            />
            <Button
              text="Add as Admin"
              styles={buttonStylesPrimary}
              disabled={tableSelections.length === 0 || isLoading}
              onClick={() => setShowAddAsAdminsConfirmationDialog(true)}
            />
            <Button
              text="Delete"
              styles={buttonStylesDanger}
              disabled={tableSelections.length === 0 || isLoading}
              onClick={() => setShowDeleteConfirmationDialog(true)}
            />
          </div>
        </div>
        <div style={tableContainerStyles(theme)}>
          {!isLoading && users.length === 0 ? (
            <MessageBar messageBarType={MessageBarType.warning}>{'Users group is empty'}</MessageBar>
          ) : (
            <DetailsList
              columns={columns}
              selection={selectionRef.current}
              selectionPreservedOnEmptyClick={true}
              selectionMode={SelectionMode.multiple}
              checkboxVisibility={CheckboxVisibility.always}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              styles={userManagementTableStyles(theme)}
              items={isLoading ? dummyList : filteredData}
              setKey="userEmail"
            />
          )}
        </div>
      </div>
      <ConfirmDialog
        hidden={!showDeleteConfirmationDialog}
        title="Confirm Delete"
        msg={
          isDeletionPending
            ? 'Deleting users...'
            : tableSelections.length === 1
            ? 'Are you sure you want to remove the selected user from User Group?'
            : `Are you sure you want to remove ${tableSelections.length} users from User Group?`
        }
        confirmationButtonText={isDeletionPending ? '...' : 'Yes'}
        dismissalButtonText="Cancel"
        onConfirm={handleDelete}
        onDismiss={() => {
          setShowDeleteConfirmationDialog(false);
        }}
        confirmationButtonDisabled={isDeletionPending}
      />
      <ResendEmailConfirmation
        users={tableSelections}
        showDialog={showResendEmailConfirmationDialog}
        setShowDialog={setShowResendEmailConfirmationDialog}
      />
      <AddAsAdminConfirmation
        users={tableSelections}
        showDialog={showAddAsAdminsConfirmationDialog}
        setShowDialog={setShowAddAsAdminsConfirmationDialog}
        onSubmitSuccess={onSubmitSuccess}
      />
    </>
  );
}
