import { Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Loader, LoaderType, TextField, compareVersionString } from '@h2oai/ui-kit';
import { useEffect, useReducer, useRef, useState } from 'react';

import { useEntity } from '../../../aiem/entity/hooks';
import { validateSemVer } from '../../../aiem/entity/utils';
import { getIdFromName } from '../../../aiem/utils';
import { useDebouncedCallback, usePromiseCallback } from '../../../utils/hooks';
import { EntityFieldInputProps, FormRow } from './BasicEntityModelComponents';

const onRenderDescription = () => {
  return (
    <Stack styles={{ root: { height: 10, width: 20, paddingTop: 4 } }}>
      <Loader type={LoaderType.progressIndicator} />
    </Stack>
  );
};

interface SemverValidationState {
  available: boolean;
  valid: boolean;
  isMinimum: boolean;
}

enum SemverValidationAction {
  AVAILABLE = 'available',
  VALID = 'valid',
  IS_MINIMUM = 'isMinimum',
}

type SemverValidationActions =
  | { type: SemverValidationAction.AVAILABLE; value: boolean }
  | { type: SemverValidationAction.VALID; value: boolean }
  | { type: SemverValidationAction.IS_MINIMUM; value: boolean };

type SemverValidationReducerFunction = (
  state: SemverValidationState,
  action: SemverValidationActions
) => SemverValidationState;

const validationReducer: SemverValidationReducerFunction = (
  state: SemverValidationState,
  action: SemverValidationActions
): SemverValidationState => {
  const newState = { ...state };
  newState[action.type] = action.value;
  return newState;
};

export function SemverEntityModelField<EntityModel>({
  field,
  model,
  onChangeMultiple,
  entityType,
  isCreate = true,
  validate,
}: EntityFieldInputProps<EntityModel>) {
  const { checkId } = useEntity();
  const { name, label, readOnlyOnEdit, relayValueTo } = field;
  const [semverValidationState, semverValidationDispatch] = useReducer<SemverValidationReducerFunction>(
    validationReducer,
    {
      available: !isCreate,
      valid: !isCreate,
      isMinimum: !isCreate,
    }
  );
  const [fieldValue, setFieldValue] = useState<string>(getIdFromName((model as any)[name] as string));
  const checkErrorMessage = useRef<string>('');
  const minimumVersionErrorMessage = useRef<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [checking, { setFalse: doneChecking, setTrue: startChecking }] = useBoolean(false);
  const checkIdController = useRef<AbortController>();

  const [useChecker] = usePromiseCallback(
    (value: string) => {
      if (checking && checkIdController.current) {
        checkIdController.current?.abort();
      }
      checkIdController.current = new AbortController();
      return checkId(entityType, value, checkIdController.current);
    },
    [],
    {
      onError: (workerError) => {
        semverValidationDispatch({ type: SemverValidationAction.AVAILABLE, value: false });
        checkErrorMessage.current = workerError.message;
      },
      onSuccess: (isAvailable) => {
        semverValidationDispatch({ type: SemverValidationAction.AVAILABLE, value: isAvailable });
        checkErrorMessage.current = '';
      },
      onSettled: doneChecking,
    }
  );

  const debounceSemverCallback = useDebouncedCallback(useChecker, 300);
  // first, we check to see if the semver is valid
  // if it is, then we check to
  const onChangeSemver = async (_ev: any, value = '') => {
    const isValid = validateSemVer(value);
    const isMinimumVersion = !!(
      !field.minimum ||
      (field.minimum && compareVersionString(value, String(field.minimum)) >= 0)
    );
    minimumVersionErrorMessage.current = isMinimumVersion ? '' : `Minimum version is ${field.minimum}`;
    semverValidationDispatch({ type: SemverValidationAction.VALID, value: isValid });
    semverValidationDispatch({ type: SemverValidationAction.IS_MINIMUM, value: isMinimumVersion });
    if (isValid && isMinimumVersion) {
      startChecking();
      debounceSemverCallback(value);
    }
    setFieldValue(value || '');
    onChangeMultiple &&
      onChangeMultiple({
        [name]: value,
        ...(relayValueTo?.length
          ? relayValueTo.reduce((acc, curr) => ({ ...acc, [curr]: value }), {} as Partial<EntityModel>)
          : {}),
      } as Partial<EntityModel>);
  };

  useEffect(() => {
    setSuccessMessage('');
    setErrorMessage('');
    // versions can't be edited:
    if (!isCreate) {
      return;
    }
    if (!fieldValue) {
      validate && validate(false);
      return;
    }
    const { available, valid, isMinimum } = semverValidationState;
    // states and their messages:
    // available: true, valid: true, isMinimum: true: 'Valid and available.'
    // available: false, valid: false, isMinimum: false: 'Invalid Semver.'
    // available: true, valid: false, isMinimum: false: 'Invalid Semver.'
    // available: false, valid: true, isMinimum: false: 'Below minimum.'
    // available: false, valid: false, isMinimum: true: 'Invalid Semver.'
    // available: true, valid: true, isMinimum: false: 'Below minimum.'
    // available: false, valid: true, isMinimum: true: 'Semver already exists.'
    // available: true, valid: false, isMinimum: true: 'Invalid semver.'
    if (valid && available && isMinimum) {
      setSuccessMessage('Valid and available');
      validate && validate(true);
      return;
    }
    if (!valid) {
      setErrorMessage('Invalid Semver. Examples: "1.10.3", "1.10.3-alpha", "1.10.3.2", "1.10.3.2-alpha');
      validate && validate(false);
      return;
    }
    if (!isMinimum) {
      setErrorMessage(minimumVersionErrorMessage.current);
      validate && validate(false);
      return;
    }
    if (!available) {
      setErrorMessage('Semver already exists');
      validate && validate(false);
      return;
    }
    // for when unmounting:
    return () => {
      if (checkIdController && checking) checkIdController.current?.abort();
    };
  }, [semverValidationState]);

  return (
    <FormRow>
      <TextField
        readOnly={!isCreate && readOnlyOnEdit}
        label={label}
        value={fieldValue}
        successMessage={successMessage}
        errorMessage={errorMessage}
        onRenderDescription={checking ? onRenderDescription : undefined}
        onChange={onChangeSemver}
      />
    </FormRow>
  );
}
