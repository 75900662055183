import { Dropdown } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { AiUnitHourlyCummulativeRequest } from '../../../../../admin-center/gen/aiUnitHourly/aiUnitHourly_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { MonthDropdownStyle, PageStyle } from '../AIUnitHourly.styles';
import CumulativeMonthPlot from './CumulativeMonthPlot';
import CumulativePlot from './CumulativePlot';

interface AiUnitHourlyCummulativeEvent {
  date: string;
  month: string;
  year: string;
  aiUnits: number;
  cumAiUnits: number;
}

interface HourlyAiUnitData {
  events: AiUnitHourlyCummulativeEvent[];
  monthlyEvents: AiUnitHourlyCummulativeEvent[];
  threshold: number;
  monthlyThreshold: number;
  maxPercentage: number;
  monthlyMaxPercentage: number;
}

const getCurrentMonth = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
};

export default function CumulativeSummary() {
  const [selectedMonthKey, setSelectedMonthKey] = useState<string>(getCurrentMonth());
  const [cummulativeData, setCummulativeData] = useState<HourlyAiUnitData | null>(null);
  const [months, setMonths] = useState<{ key: string; text: string }[]>([]);
  const service = useAdminCenterService();

  const onMonthChange = (_event: React.FormEvent<HTMLDivElement>, item: any) => {
    const { key } = item;
    setSelectedMonthKey(key);
  };

  const fetchHourlyAIUnitCummulativeData = async (month?: string) => {
    try {
      const request: AiUnitHourlyCummulativeRequest = {
        month: month || getCurrentMonth(),
      };

      const response = await service.getHourlyAiUnitCummulativeUsage(request);
      setCummulativeData(response as HourlyAiUnitData);

      if (response.events && response.events.length > 0) {
        const events = response.events as AiUnitHourlyCummulativeEvent[];
        const uniqueMonths = Array.from(new Set(events.map((event) => event.month))).map((month) => ({
          key: month,
          text: month,
        }));

        setMonths(uniqueMonths);
      } else {
        console.warn('No events found in the response.');
        setMonths([]);
      }
    } catch (error) {
      console.error('Error fetching Hourly ai unit valus:', error);
    }
  };

  useEffect(() => {
    fetchHourlyAIUnitCummulativeData(selectedMonthKey);
  }, [selectedMonthKey]);

  return (
    <div>
      <p>The following plot displays daily cumulative hourly usage.</p>
      <div style={PageStyle}>
        <CumulativePlot
          events={cummulativeData?.events || []}
          threshold={cummulativeData?.threshold || 0}
          max_percentage={cummulativeData?.maxPercentage ?? 0}
        />
        <div style={MonthDropdownStyle}>
          <Dropdown
            placeholder="Select time frame"
            options={months}
            selectedKey={selectedMonthKey}
            onChange={onMonthChange}
            width={'100%'}
            style={{ marginRight: '20px' }}
          />
        </div>
        <CumulativeMonthPlot
          events={cummulativeData?.monthlyEvents || []}
          threshold={cummulativeData?.monthlyThreshold || 0}
          max_percentage={cummulativeData?.monthlyMaxPercentage ?? 0}
        />
      </div>
    </div>
  );
}
