import { IDetailsRowProps, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Button, buttonStylesPrimary, detailsListStylesDefault, useTheme } from '@h2oai/ui-kit';
import { useEffect, useMemo, useState } from 'react';

import { useEntity } from '../../../../aiem/entity/hooks';
import {
  restrictionTooltipMessage,
  usePermissions,
} from '../../../../authz/providers/PermissionsProvider/PermissionsProvider';
import List from '../../../../components/ListPages/List';
import ListPage from '../../../../components/ListPages/ListPage';
import { stackStylesInnerPivotPage } from '../../../../themes/themes';
import { ListRow } from './ListRow';
import { onRenderActionsCell } from './parts';
import {
  EngineProfilesColumnProps,
  EngineProfilesProps,
  EntityModelTypes,
  EntityRowState,
  ExpandedEntityWithState,
} from './types';

export function ListWithCollapsedSettings<EntityModel extends EntityModelTypes>(
  props: EngineProfilesProps<EntityModel>
) {
  const { entity, activeWorkspaceName = 'workspaces/global', userActions } = props;
  const [canCreate, canView, canList, canEdit, canDelete, permissionsLoading] = usePermissions(userActions);
  const theme = useTheme();
  const entityDataConnector = useEntity();
  const { columns, actions } = entity;
  const { List: listItemsAction } = actions || undefined;
  const [pageLoading, { setTrue: showPageLoading, setFalse: hidePageLoading }] = useBoolean(true);
  const [entityRows, setEntityRows] = useState<EntityModel[]>([]);
  const [activeEntity, setActiveEntity] = useState<ExpandedEntityWithState<EntityModel> | undefined>();
  const formChanged = useMemo(() => {
    if (!activeEntity) return false;
    const activeRow = entityRows.filter((row) => row.name === activeEntity.model.name);

    return (
      activeEntity.rowState === EntityRowState.EDIT &&
      JSON.stringify(activeRow[0]) !== JSON.stringify(activeEntity.model)
    );
  }, [activeEntity, entityRows]);

  const listItems = async () => {
    showPageLoading();

    const requestParams = {
      parent: activeWorkspaceName,
      pageSize: 1000,
    };

    try {
      const entities = await entityDataConnector.list(entity, requestParams);
      setEntityRows(entities || []);
    } catch (error: any) {
      console.error(error);
    }

    hidePageLoading();
  };
  const handleSave = async () => {
    const isCreate = activeEntity?.rowState === EntityRowState.CREATE;
    const action = isCreate ? entity.actions.Create : entity.actions.Update;
    const { requestNameKey, requestPayloadKey } = action || {
      requestNameKey: 'bad-action-key',
      requestPayloadKey: 'bad-action-payload',
    };
    const requestParams = {
      parent: activeWorkspaceName,
      [requestPayloadKey as string]: activeEntity?.model,
      [requestNameKey as string]: activeEntity?.model.name,
    };

    try {
      if (isCreate) {
        await entityDataConnector.create(entity, requestParams);
      } else {
        await entityDataConnector.update(entity, requestParams);
      }

      void listItems();
      setActiveEntity(undefined);
    } catch (error: any) {
      console.error(error);
    }

    hidePageLoading();
  };
  const handleNewEntity = () => {
    const newEntityRow = { ...entity.emptyModel };
    setEntityRows([newEntityRow, ...entityRows]);
    setActiveEntity({ model: newEntityRow, rowState: EntityRowState.CREATE });
  };
  const handleFormChange = (model: EntityModel) => {
    if (!activeEntity) return;
    setActiveEntity({ model, rowState: activeEntity?.rowState });
  };
  const updateActiveEntityState = (model?: EntityModel, rowState?: EntityRowState) => {
    if (activeEntity?.rowState === EntityRowState.CREATE) {
      const [, ...rest] = entityRows;
      setEntityRows(rest);
    }

    if (!model || !rowState) {
      return setActiveEntity(undefined);
    }

    setActiveEntity({ model, rowState });
  };
  const handleDeleteEntity = async (entityName: string) => {
    const requestParams = {
      parent: activeWorkspaceName,
      name: entityName,
    };

    try {
      const messageText = {
        subject: entityName,
        action: 'deleted ',
      };
      await entityDataConnector.erase(entity, requestParams, messageText);
      void listItems();
    } catch (error: any) {
      console.error(error);
    }
  };

  const permissionsProp = { canCreate, canView, canEdit, canDelete };
  const entityColumns = useMemo(() => {
    const entityProps: EngineProfilesColumnProps<EntityModel> = {
      entity,
      theme,
      activeEntity,
      formChanged,
      onSave: handleSave,
      handleDeleteEntity,
      updateActiveEntityState,
      permissions: permissionsProp,
    };

    return columns.map((column) =>
      column.key === 'actions'
        ? {
            ...column,
            onRender: (group: EntityModel, index?: number) => onRenderActionsCell(group, entityProps, index),
          }
        : column
    );
  }, [columns, activeEntity, permissionsProp]);

  useEffect(() => {
    if (listItemsAction) {
      void listItems();
    }
  }, []);

  return (
    <Stack tokens={{ childrenGap: 5 }} styles={{ root: { paddingTop: 5 } }}>
      <Stack horizontal horizontalAlign="end" style={{ position: 'absolute', top: '40px', right: '30px', height: '0' }}>
        <Button
          styles={[buttonStylesPrimary]}
          text={entity.createButtonLabel || `Add ${entity.displayName}`}
          onClick={handleNewEntity}
          disabled={!canCreate || !canList || !!activeEntity}
          tooltip={restrictionTooltipMessage(!canCreate || !canList)}
        />
      </Stack>
      {listItemsAction && (
        <ListPage
          loading={pageLoading}
          copy={{
            title: '',
            subtitle: '',
            loadingMessage: `Loading ${entity.displayName}s`,
            noDataMessage: 'No profiles found',
          }}
          showNoResults={entityRows.length === 0}
          showSearchInput={false}
          stackStyles={stackStylesInnerPivotPage}
          error={
            !permissionsLoading && !canList
              ? 'You are not authorized to access AI Engine Settings in this Workspace.'
              : ''
          }
        >
          <List
            styles={detailsListStylesDefault}
            dataTest="entities-list"
            items={entityRows || []}
            columns={entityColumns}
            onRenderRow={(rowProps?: IDetailsRowProps) => (
              <ListRow
                updateActiveEntityState={updateActiveEntityState}
                entity={entity}
                activeEntity={activeEntity}
                rowProps={rowProps as IDetailsRowProps}
                onChange={handleFormChange}
                canEdit={canEdit}
              />
            )}
          />
        </ListPage>
      )}
    </Stack>
  );
}
