// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file app_event/appEvent/appevent.proto (package appEvent, syntax proto3)
/* eslint-disable */

import { RPC } from "../../runtime";

/**
 * @generated from message appEvent.AppEventsRequest
 */
export type AppEventsRequest = {
/**
 * @generated from field: string start_date = 1;
 */
startDate?: string;
/**
 * @generated from field: string end_date = 2;
 */
endDate?: string;
}
;
/**
 * @generated from message appEvent.AppEventsResponse
 */
export type AppEventsResponse = {
/**
 * @generated from field: repeated appEvent.AppEvent events = 1;
 */
events?: AppEvent[];
}
;
/**
 * @generated from message appEvent.AppEvent
 */
export type AppEvent = {
/**
 * Timestamp
 *
 * @generated from field: string ts = 1;
 */
ts?: string;
/**
 * Stream
 *
 * @generated from field: string stream = 2;
 */
stream?: string;
/**
 * User name
 *
 * @generated from field: string user_name = 3;
 */
userName?: string;
/**
 * Label
 *
 * @generated from field: string label = 4;
 */
label?: string;
}
;
/**
 * @generated from rpc appEvent.AppEventService.GetAppEvents
 */
export const AppEventService_GetAppEvents = new RPC<AppEventsRequest,AppEventsResponse>("GET", "/getAppEvents");
