// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file fw_management/fw_management.proto (package fw_management, syntax proto3)
/* eslint-disable */

import { RPC } from "../runtime";

/**
 * @generated from message fw_management.FwIpRequest
 */
export type FwIpRequest = {
}
;
/**
 * @generated from message fw_management.FwIpResponse
 */
export type FwIpResponse = {
/**
 * @generated from field: repeated string fw_ips = 1;
 */
fwIps?: string[];
}
;
/**
 * @generated from message fw_management.FwPortRequest
 */
export type FwPortRequest = {
}
;
/**
 * @generated from message fw_management.FwPortResponse
 */
export type FwPortResponse = {
/**
 * @generated from field: repeated string fw_ports = 1;
 */
fwPorts?: string[];
}
;
/**
 * @generated from message fw_management.FwIpLocalUpdateRequest
 */
export type FwIpLocalUpdateRequest = {
/**
 * @generated from field: repeated string new_fw_ips = 1;
 */
newFwIps?: string[];
/**
 * @generated from field: string modified_by = 2;
 */
modifiedBy?: string;
/**
 * @generated from field: double timestamp = 3;
 */
timestamp?: number;
}
;
/**
 * @generated from message fw_management.FwIpLocalUpdateResponse
 */
export type FwIpLocalUpdateResponse = {
/**
 * @generated from field: string status_update = 1;
 */
statusUpdate?: string;
}
;
/**
 * @generated from message fw_management.FwPortLocalUpdateRequest
 */
export type FwPortLocalUpdateRequest = {
/**
 * @generated from field: repeated string new_fw_ports = 1;
 */
newFwPorts?: string[];
/**
 * @generated from field: string modified_by = 2;
 */
modifiedBy?: string;
/**
 * @generated from field: double timestamp = 3;
 */
timestamp?: number;
}
;
/**
 * @generated from message fw_management.FwPortLocalUpdateResponse
 */
export type FwPortLocalUpdateResponse = {
/**
 * @generated from field: string status_update = 1;
 */
statusUpdate?: string;
}
;
/**
 * @generated from message fw_management.FwIpLocalDeleteRequest
 */
export type FwIpLocalDeleteRequest = {
/**
 * @generated from field: repeated string deleted_fw_ips = 1;
 */
deletedFwIps?: string[];
/**
 * @generated from field: string modified_by = 2;
 */
modifiedBy?: string;
/**
 * @generated from field: double timestamp = 3;
 */
timestamp?: number;
}
;
/**
 * @generated from message fw_management.FwIpLocalDeleteResponse
 */
export type FwIpLocalDeleteResponse = {
/**
 * @generated from field: string status_update = 1;
 */
statusUpdate?: string;
}
;
/**
 * @generated from message fw_management.FwPortLocalDeleteRequest
 */
export type FwPortLocalDeleteRequest = {
/**
 * @generated from field: repeated string deleted_fw_ports = 1;
 */
deletedFwPorts?: string[];
/**
 * @generated from field: string modified_by = 2;
 */
modifiedBy?: string;
/**
 * @generated from field: double timestamp = 3;
 */
timestamp?: number;
}
;
/**
 * @generated from message fw_management.FwPortLocalDeleteResponse
 */
export type FwPortLocalDeleteResponse = {
/**
 * @generated from field: string status_update = 1;
 */
statusUpdate?: string;
}
;
/**
 * @generated from message fw_management.UpdateRemoteTfvarsRequest
 */
export type UpdateRemoteTfvarsRequest = {
}
;
/**
 * @generated from message fw_management.UpdateRemoteTfvarsResponse
 */
export type UpdateRemoteTfvarsResponse = {
/**
 * @generated from field: string status_update = 1;
 */
statusUpdate?: string;
}
;
/**
 * @generated from message fw_management.GetIsFileChangedRequest
 */
export type GetIsFileChangedRequest = {
}
;
/**
 * @generated from message fw_management.GetIsFileChangedResponse
 */
export type GetIsFileChangedResponse = {
/**
 * @generated from field: bool has_update = 1;
 */
hasUpdate?: boolean;
}
;
/**
 * @generated from message fw_management.GetFwCommitHistoryRequest
 */
export type GetFwCommitHistoryRequest = {
}
;
/**
 * @generated from message fw_management.GetFwCommitHistoryResponse
 */
export type GetFwCommitHistoryResponse = {
/**
 * @generated from field: repeated fw_management.Commit commits = 1;
 */
commits?: Commit[];
}
;
/**
 * @generated from message fw_management.Commit
 */
export type Commit = {
/**
 * @generated from field: string commit_id = 1;
 */
commitId?: string;
/**
 * @generated from field: repeated string ingress_extended = 2;
 */
ingressExtended?: string[];
/**
 * @generated from field: repeated string ingress_purged = 3;
 */
ingressPurged?: string[];
/**
 * @generated from field: repeated string ports_extended = 4;
 */
portsExtended?: string[];
/**
 * @generated from field: repeated string ports_purged = 5;
 */
portsPurged?: string[];
/**
 * @generated from field: string modified_by = 6;
 */
modifiedBy?: string;
/**
 * @generated from field: double timestamp = 7;
 */
timestamp?: number;
}
;
/**
 * @generated from rpc fw_management.FWManagementService.GetFwIps
 */
export const FWManagementService_GetFwIps = new RPC<FwIpRequest,FwIpResponse>("GET", "/getFwIps");
/**
 * @generated from rpc fw_management.FWManagementService.GetFwPorts
 */
export const FWManagementService_GetFwPorts = new RPC<FwPortRequest,FwPortResponse>("GET", "/getFwPorts");
/**
 * @generated from rpc fw_management.FWManagementService.UpdateLocalFwIps
 */
export const FWManagementService_UpdateLocalFwIps = new RPC<FwIpLocalUpdateRequest,FwIpLocalUpdateResponse>("GET", "/updateLocalFwIps");
/**
 * @generated from rpc fw_management.FWManagementService.UpdateLocalFwPorts
 */
export const FWManagementService_UpdateLocalFwPorts = new RPC<FwPortLocalUpdateRequest,FwPortLocalUpdateResponse>("GET", "/updateLocalFwPorts");
/**
 * @generated from rpc fw_management.FWManagementService.DeleteLocalFwIps
 */
export const FWManagementService_DeleteLocalFwIps = new RPC<FwIpLocalDeleteRequest,FwIpLocalDeleteResponse>("GET", "/deleteLocalFwIps");
/**
 * @generated from rpc fw_management.FWManagementService.DeleteLocalFwPorts
 */
export const FWManagementService_DeleteLocalFwPorts = new RPC<FwPortLocalDeleteRequest,FwPortLocalDeleteResponse>("GET", "/deleteLocalFwPorts");
/**
 * @generated from rpc fw_management.FWManagementService.UpdateRemoteTfvars
 */
export const FWManagementService_UpdateRemoteTfvars = new RPC<UpdateRemoteTfvarsRequest,UpdateRemoteTfvarsResponse>("GET", "/updateRemoteTfvars");
/**
 * @generated from rpc fw_management.FWManagementService.GetIsFileChanged
 */
export const FWManagementService_GetIsFileChanged = new RPC<GetIsFileChangedRequest,GetIsFileChangedResponse>("GET", "/getIsFileChanged");
/**
 * @generated from rpc fw_management.FWManagementService.GetFwCommitHistory
 */
export const FWManagementService_GetFwCommitHistory = new RPC<GetFwCommitHistoryRequest,GetFwCommitHistoryResponse>("GET", "/getFwCommitHistory");
