// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/dai_engine_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { DAIEngine } from "./dai_engine_pb";
import type { HttpBody } from "../../../../google/api/httpbody_pb";
import type { BigIntString } from "../../../../runtime";
import { RPC } from "../../../../runtime";

/**
 * Request message for creating a new DAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.CreateDAIEngineRequest
 */
export type CreateDAIEngineRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/CREATE
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The DAIEngine resource to create.
 * NON_EMPTY_DEFAULT fields that are not set in this request will be set to a default value.
 * Default value is determined by the workspace in which the engine is created. Each workspace has a defined
 * constraint set, which contains the default value for each field.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine dai_engine = 2;
 */
daiEngine: DAIEngine;
/**
 * Specify the DAIEngine ID, which will become a part of the DAIEngine
 * resource name.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string dai_engine_id = 3;
 */
daiEngineId: string;
/**
 * When set, request is only validated but no changes are made.
 *
 * @generated from field: bool validate_only = 10;
 */
validateOnly?: boolean;
}
;
/**
 * Response message for DAIEngineService.CreateDAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.CreateDAIEngineResponse
 */
export type CreateDAIEngineResponse = {
/**
 * DAIEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine dai_engine = 1;
 */
daiEngine?: DAIEngine;
}
;
/**
 * Request message for getting a single DAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.GetDAIEngineRequest
 */
export type GetDAIEngineRequest = {
/**
 * DAIEngine resource name.
 * Format: workspaces/{workspace}/daiEngines/{dai_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/GET
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DAIEngineService.GetDAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.GetDAIEngineResponse
 */
export type GetDAIEngineResponse = {
/**
 * DAIEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine dai_engine = 1;
 */
daiEngine?: DAIEngine;
}
;
/**
 * Request message for listing DAIEngines in a workspace.
 *
 * @generated from message ai.h2o.engine.v1.ListDAIEnginesRequest
 */
export type ListDAIEnginesRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/LIST
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of DAIEngines to return in a response.
 * If unspecified (or set to 0), at most 50 DAIEngines will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListDAIEnginesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * Used to specify the sorting order.
 *
 * When unset, DAIEngines are ordered by their time of creation in
 * descending order. This is equivalent to "create_time desc".
 *
 * When specified, the value must be a comma separated list of supported
 * fields. The supported fields are:
 * - name
 * - cpu
 * - gpu
 * - memory_bytes
 * - storage_bytes
 * - creator
 * - create_time
 * - update_time
 * - delete_time
 * - resume_time
 * - display_name
 * - max_idle_duration
 * - max_running_duration
 * - uid
 *
 * The default sorting order is ascending. For example: "name" and "name asc"
 * are equivalent values.
 *
 * To specify descending order for a field, append a " desc" suffix. For
 * example: "name desc".
 *
 * Redundant space characters are insignificant. For example these values are
 * all equal:
 * - "  name, cpu     desc"
 * - "name, cpu desc"
 * - "name   , cpu desc   "
 *
 * Undefined (empty) time is interpreted as a zero time (0s since epoch, i.e.
 * 1970-01-01T00:00:00Z).
 *
 * Undefined (empty) duration is interpreted as a zero duration (0 seconds).
 *
 * @generated from field: string order_by = 4;
 */
orderBy?: string;
/**
 * Used to filter DAIEngines. When unset, no filtering is applied.
 *
 * Filtering implements specification https://google.aip.dev/160.
 *
 * Supported filter fields:
 * - name
 * - dai_engine_id
 * - state
 * - cpu
 * - gpu
 * - memory_bytes
 * - storage_bytes
 * - creator
 * - creator_display_name
 * - create_time
 * - update_time
 * - delete_time
 * - resume_time
 * - reconciling
 * - uid
 * - display_name
 * - max_idle_duration
 * - max_running_duration
 * - current_idle_duration
 * - current_running_duration
 *
 * Examples:
 * - `cpu > 5`
 * - `gpu < 3`
 * - `cpu >= 5 AND gpu <= 3`
 * - `cpu != 5 OR gpu == 3`
 * - `NOT (cpu > 5 OR gpu < 3)`
 * - `-(cpu > 5 OR gpu < 3)`
 * - `NOT (cpu > 5 OR (gpu < 3))`
 * - `(cpu > 5 OR gpu < 3) AND memory_bytes != 1000`
 * - `(cpu > 5 OR gpu < 3) AND (NOT ((((memory_bytes = 1000)))))`
 * - `state = STATE_RUNNING AND create_time > "2012-04-21T11:30:00-04:00"`
 * - `max_running_duration > "1s" AND max_running_duration != "5s"`
 * - `reconciling = true OR reconciling = false` (supporting bool literals)
 * - `dai_engine_id = "*e*" OR display_name = "*e*"` (supporting prefix/suffix wildcard `*` for string fields equality comparison)
 *
 * @generated from field: string filter = 5;
 */
filter?: string;
}
;
/**
 * Response message for DAIEngineService.ListDAIEngines.
 *
 * @generated from message ai.h2o.engine.v1.ListDAIEnginesResponse
 */
export type ListDAIEnginesResponse = {
/**
 * Collection of DAIEngines.
 *
 * @generated from field: repeated ai.h2o.engine.v1.DAIEngine dai_engines = 1;
 */
daiEngines?: DAIEngine[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * Total number of DAIEngines matched by the List request.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Request message for resuming an existing DAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.ResumeDAIEngineRequest
 */
export type ResumeDAIEngineRequest = {
/**
 * DAIEngine resource name.
 * Format: workspaces/{workspace}/daiEngines/{dai_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/RESUME
 *
 * @generated from field: string name = 1;
 */
name: string;
/**
 * When set, request is validated but no changes are made.
 *
 * @generated from field: bool validate_only = 10;
 */
validateOnly?: boolean;
}
;
/**
 * Response for DAIEngineService.ResumeDAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.ResumeDAIEngineResponse
 */
export type ResumeDAIEngineResponse = {
/**
 * DAIEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine dai_engine = 1;
 */
daiEngine?: DAIEngine;
}
;
/**
 * Request message for pausing a running DAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.PauseDAIEngineRequest
 */
export type PauseDAIEngineRequest = {
/**
 * DAIEngine resource name.
 * Format: workspaces/{workspace}/daiEngines/{dai_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/PAUSE
 *
 * @generated from field: string name = 1;
 */
name: string;
/**
 * When set, request is validated but no changes are made.
 *
 * @generated from field: bool validate_only = 10;
 */
validateOnly?: boolean;
}
;
/**
 * Response for DAIEngineService.PauseDAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.PauseDAIEngineResponse
 */
export type PauseDAIEngineResponse = {
/**
 * DAIEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine dai_engine = 1;
 */
daiEngine?: DAIEngine;
}
;
/**
 * Request message for updating an existing DAIEngine.
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/UPDATE
 *
 * @generated from message ai.h2o.engine.v1.UpdateDAIEngineRequest
 */
export type UpdateDAIEngineRequest = {
/**
 * DAIEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine dai_engine = 1;
 */
daiEngine: DAIEngine;
/**
 * The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {"profile", "cpu", "gpu", "memory_bytes", "config", "annotations", "display_name",
 * "max_idle_duration", "max_running_duration"}.
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 10;
 */
updateMask: string;
/**
 * When set and the DAIEngine is not found, a new one is created.
 * In this situation, `update_mask` is ignored, i.e. all fields are applied
 * regardless of any provided update mask; but the update mask must be still
 * present.
 *
 * @generated from field: bool allow_missing = 11;
 */
allowMissing?: boolean;
/**
 * When set, request is validated but no changes are made.
 *
 * @generated from field: bool validate_only = 12;
 */
validateOnly?: boolean;
}
;
/**
 * Response for DAIEngineService.UpdateDAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDAIEngineResponse
 */
export type UpdateDAIEngineResponse = {
/**
 * DAIEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine dai_engine = 1;
 */
daiEngine?: DAIEngine;
}
;
/**
 * Request message for deleting an existing DAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.DeleteDAIEngineRequest
 */
export type DeleteDAIEngineRequest = {
/**
 * DAIEngine resource name.
 * Format: workspaces/{workspace}/daiEngines/{dai_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/DELETE
 *
 * @generated from field: string name = 1;
 */
name: string;
/**
 * When set and the DAIEngine is not found, the request will succeed
 * but no changes are made.
 *
 * @generated from field: bool allow_missing = 2;
 */
allowMissing?: boolean;
/**
 * When set, request is validated but no changes are made.
 *
 * @generated from field: bool validate_only = 10;
 */
validateOnly?: boolean;
}
;
/**
 * Response message for DAIEngineService.DeleteDAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.DeleteDAIEngineResponse
 */
export type DeleteDAIEngineResponse = {
/**
 * DAIEngine resource being deleted. May be empty in case delete was instant.
 *
 * @generated from field: optional ai.h2o.engine.v1.DAIEngine dai_engine = 1;
 */
daiEngine?: DAIEngine;
}
;
/**
 * Request message for downloading DAIEngines log.
 *
 * @generated from message ai.h2o.engine.v1.DAIEngineServiceDownloadLogsRequest
 */
export type DAIEngineServiceDownloadLogsRequest = {
/**
 * DAIEngine resource name.
 * Format: workspaces/{workspace}/daiEngines/{dai_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/DOWNLOAD_LOGS
 *
 * @generated from field: string dai_engine = 1;
 */
daiEngine: string;
}
;
/**
 * Response message for downloaded logs.
 *
 * @generated from message ai.h2o.engine.v1.DAIEngineServiceDownloadLogsResponse
 */
export type DAIEngineServiceDownloadLogsResponse = {
/**
 * Represents text logs file as an arbitrary HTTP body.
 *
 * @generated from field: google.api.HttpBody logs = 1;
 */
logs?: HttpBody;
}
;
/**
 * Request message.
 *
 * @generated from message ai.h2o.engine.v1.DAIEngineServiceUpgradeDAIEngineVersionRequest
 */
export type DAIEngineServiceUpgradeDAIEngineVersionRequest = {
/**
 * DAIEngine resource name.
 * Format: workspaces/{workspace}/daiEngines/{dai_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/UPGRADE_DAI_ENGINE_VERSION
 *
 * @generated from field: string dai_engine = 1;
 */
daiEngine: string;
/**
 * New dai_engine_version.
 * Format: workspaces/*\/daiEngineVersions/*
 *
 * @generated from field: string new_dai_engine_version = 2;
 */
newDaiEngineVersion: string;
}
;
/**
 * Response message.
 *
 * @generated from message ai.h2o.engine.v1.DAIEngineServiceUpgradeDAIEngineVersionResponse
 */
export type DAIEngineServiceUpgradeDAIEngineVersionResponse = {
/**
 * DAIEngine with upgraded dai_engine_version.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine dai_engine = 1;
 */
daiEngine?: DAIEngine;
}
;
/**
 * Request message for migrating the engine creator.
 *
 * @generated from message ai.h2o.engine.v1.DAIEngineServiceMigrateCreatorRequest
 */
export type DAIEngineServiceMigrateCreatorRequest = {
/**
 * DAIEngine resource name.
 * Format: workspaces/{workspace}/daiEngines/{dai_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/MIGRATE_CREATOR
 *
 * @generated from field: string dai_engine = 1;
 */
daiEngine: string;
/**
 * New creator value. Name of an entity that becomes the new creator of the DAIEngine. Format: users/397b8c16-f4cb-41dd-a5e9-5e838edb81ab
 *
 * @generated from field: string new_creator = 2;
 */
newCreator: string;
}
;
/**
 * Response message for migrating the engine creator.
 *
 * @generated from message ai.h2o.engine.v1.DAIEngineServiceMigrateCreatorResponse
 */
export type DAIEngineServiceMigrateCreatorResponse = {
/**
 * DAIEngine with migrated creator.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine dai_engine = 1;
 */
daiEngine?: DAIEngine;
}
;
/**
 * Request message for resizing DAIEngine storage.
 *
 * @generated from message ai.h2o.engine.v1.DAIEngineServiceResizeStorageRequest
 */
export type DAIEngineServiceResizeStorageRequest = {
/**
 * DAIEngine resource name.
 * Format: workspaces/{workspace}/daiEngines/{dai_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/daiEngines/RESIZE_STORAGE
 *
 * @generated from field: string dai_engine = 1;
 */
daiEngine: string;
/**
 * New storage size in bytes.
 *
 * @generated from field: int64 new_storage_bytes = 2;
 */
newStorageBytes: BigIntString;
}
;
/**
 * Response message for resizing the engine storage.
 *
 * @generated from message ai.h2o.engine.v1.DAIEngineServiceResizeStorageResponse
 */
export type DAIEngineServiceResizeStorageResponse = {
/**
 * DAIEngine with resized storage.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngine dai_engine = 1;
 */
daiEngine?: DAIEngine;
}
;
/**
 * Creates a new DAIEngine within a specified workspace.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.CreateDAIEngine
 */
export const DAIEngineService_CreateDAIEngine = new RPC<CreateDAIEngineRequest,CreateDAIEngineResponse>("POST", "/v1/{parent=workspaces/*}/daiEngines", "daiEngine");
/**
 * Returns a specific DAIEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.GetDAIEngine
 */
export const DAIEngineService_GetDAIEngine = new RPC<GetDAIEngineRequest,GetDAIEngineResponse>("GET", "/v1/{name=workspaces/*/daiEngines/*}");
/**
 * Returns a collection of DAIEngines within a workspace.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.ListDAIEngines
 */
export const DAIEngineService_ListDAIEngines = new RPC<ListDAIEnginesRequest,ListDAIEnginesResponse>("GET", "/v1/{parent=workspaces/*}/daiEngines");
/**
 * Resumes an existing DAIEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.ResumeDAIEngine
 */
export const DAIEngineService_ResumeDAIEngine = new RPC<ResumeDAIEngineRequest,ResumeDAIEngineResponse>("POST", "/v1/{name=workspaces/*/daiEngines/*}:resume");
/**
 * Pauses a DAIEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.PauseDAIEngine
 */
export const DAIEngineService_PauseDAIEngine = new RPC<PauseDAIEngineRequest,PauseDAIEngineResponse>("POST", "/v1/{name=workspaces/*/daiEngines/*}:pause");
/**
 * Updates a DAIEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.UpdateDAIEngine
 */
export const DAIEngineService_UpdateDAIEngine = new RPC<UpdateDAIEngineRequest,UpdateDAIEngineResponse>("PATCH", "/v1/{daiEngine.name=workspaces/*/daiEngines/*}", "daiEngine");
/**
 * Deletes a DAIEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.DeleteDAIEngine
 */
export const DAIEngineService_DeleteDAIEngine = new RPC<DeleteDAIEngineRequest,DeleteDAIEngineResponse>("DELETE", "/v1/{name=workspaces/*/daiEngines/*}");
/**
 * Download DAIEngine logs.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.DownloadLogs
 */
export const DAIEngineService_DownloadLogs = new RPC<DAIEngineServiceDownloadLogsRequest,DAIEngineServiceDownloadLogsResponse>("POST", "/v1/{daiEngine=workspaces/*/daiEngines/*}:downloadLogs");
/**
 * Upgrade DAIEngine's dai_engine_version.
 *
 * (-- api-linter: core::0136::http-uri-suffix=disabled
 *     aip.dev/not-precedent: Google linter incorrectly requires URI suffix ":upgradeDaiEngineVersion" --)
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.UpgradeDAIEngineVersion
 */
export const DAIEngineService_UpgradeDAIEngineVersion = new RPC<DAIEngineServiceUpgradeDAIEngineVersionRequest,DAIEngineServiceUpgradeDAIEngineVersionResponse>("POST", "/v1/{daiEngine=workspaces/*/daiEngines/*}:upgradeDAIEngineVersion");
/**
 * Migrate DAIEngine creator. Admin only.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.MigrateCreator
 */
export const DAIEngineService_MigrateCreator = new RPC<DAIEngineServiceMigrateCreatorRequest,DAIEngineServiceMigrateCreatorResponse>("POST", "/v1/{daiEngine=workspaces/*/daiEngines/*}:migrateCreator");
/**
 * Resize DAIEngine storage.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineService.ResizeStorage
 */
export const DAIEngineService_ResizeStorage = new RPC<DAIEngineServiceResizeStorageRequest,DAIEngineServiceResizeStorageResponse>("POST", "/v1/{daiEngine=workspaces/*/daiEngines/*}:resizeStorage");
