// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/mlops/batch/v1/source_spec_service.proto (package ai.h2o.mlops.batch.v1, syntax proto3)
/* eslint-disable */

import type { SourceSpec } from "./source_spec_pb";
import { RPC } from "../../../../../runtime";

/**
 * Request message for GetSourceSpec
 *
 * @generated from message ai.h2o.mlops.batch.v1.GetSourceSpecRequest
 */
export type GetSourceSpecRequest = {
/**
 * Required. SourceSpec resource name.
 * Format: sourceSpecs/{spec}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetSourceSpec
 *
 * @generated from message ai.h2o.mlops.batch.v1.GetSourceSpecResponse
 */
export type GetSourceSpecResponse = {
/**
 * The requested spec.
 *
 * @generated from field: ai.h2o.mlops.batch.v1.SourceSpec source_spec = 1;
 */
sourceSpec?: SourceSpec;
}
;
/**
 * Request message ListSourceSpecs
 * (-- api-linter: core::0158::request-page-size-field=disabled
 *     aip.dev/not-precedent: There will be only couple of specs. --)
 * (-- api-linter: core::0158::request-page-token-field=disabled
 *     aip.dev/not-precedent: There will be only couple of specs. --)
 *
 * @generated from message ai.h2o.mlops.batch.v1.ListSourceSpecsRequest
 */
export type ListSourceSpecsRequest = {
}
;
/**
 * Response message for ListSourceSpecs
 * (-- api-linter: core::0158::response-next-page-token-field=disabled
 *     aip.dev/not-precedent: There will be only couple of specs. --)
 *
 * @generated from message ai.h2o.mlops.batch.v1.ListSourceSpecsResponse
 */
export type ListSourceSpecsResponse = {
/**
 * Collection of specs.
 *
 * @generated from field: repeated ai.h2o.mlops.batch.v1.SourceSpec source_specs = 1;
 */
sourceSpecs?: SourceSpec[];
}
;
/**
 * Returns a specific SourceSpec.
 *
 * @generated from rpc ai.h2o.mlops.batch.v1.SourceSpecService.GetSourceSpec
 */
export const SourceSpecService_GetSourceSpec = new RPC<GetSourceSpecRequest,GetSourceSpecResponse>("GET", "/v1/{name=sourceSpecs/*}");
/**
 * Returns a collection of SourceSpecs.
 *
 * @generated from rpc ai.h2o.mlops.batch.v1.SourceSpecService.ListSourceSpecs
 */
export const SourceSpecService_ListSourceSpecs = new RPC<ListSourceSpecsRequest,ListSourceSpecsResponse>("GET", "/v1/sourceSpecs");
