export const defaultPort = 54936;
export const defaultBasePath = `http://localhost:${defaultPort}`;
export const NA = 'N/A';

export enum AiUnitMaxTimeFrameKey {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export enum AiUnitMaxTimeFrame {
  daily = 'Daily',
  weekly = 'Weekly',
  monthly = 'Monthly',
}

export enum AiUnitHourlyTab {
  cummulative = 'Cumulative Summary',
  component = 'Component Summary',
}

export enum AppEventTab {
  application = 'Applications',
  instance = 'Instances',
}

export enum AppEventOriginalName {
  create = 'ai/h2o/cloud/appstore/app/event/created',
  delete = 'ai/h2o/cloud/appstore/app/event/deleted',
  like = 'ai/h2o/cloud/appstore/app/event/liked',
  pin = 'ai/h2o/cloud/appstore/app/event/pinned',
  update = 'ai/h2o/cloud/appstore/app/event/updated',
}

export enum AppEventName {
  create = 'Created',
  delete = 'Deleted',
  like = 'Liked',
  pin = 'Pinned',
  update = 'Updated',
}

export enum AppEventTableColName {
  name = 'App Name',
  event = 'Event',
  user = 'User',
  date = 'Date',
  time = 'Time (HH:MM:SS)',
}

export enum AppEventTableColKey {
  name = 'appName',
  event = 'appEvent',
  user = 'appUser',
  date = 'appDate',
  time = 'appTime',
}

export enum InstanceEventOriginalName {
  terminate = 'ai/h2o/cloud/appstore/instance/event/terminated',
  start = 'ai/h2o/cloud/appstore/instance/event/started',
  update = 'ai/h2o/cloud/appstore/instance/event/updated',
  resume = 'ai/h2o/cloud/appstore/instance/event/resumed',
  suspend = 'ai/h2o/cloud/appstore/instance/event/suspended',
}

export enum InstanceEventName {
  terminate = 'Terminated',
  start = 'Started',
  update = 'Updated',
  resume = 'Resumed',
  suspend = 'Suspended',
}

export enum InstanceEventTableColName {
  name = 'Instance Name',
  event = 'Event',
  user = 'User',
  date = 'Date',
  time = 'Time (HH:MM:SS)',
}

export enum InstanceEventTableColKey {
  name = 'instanceName',
  event = 'instanceEvent',
  user = 'instanceUser',
  date = 'instanceDate',
  time = 'instanceTime',
}

export enum MLOpsGaugeTypeKey {
  user = 'users',
  project = 'projects',
  dataset = 'datasets',
  experiment = 'experiments',
  registeredModel = 'registeredModels',
  registeredModelVersion = 'registeredModelVersion',
  deployment = 'deployment',
}

export enum MLOpsGaugeType {
  user = 'Users',
  project = 'Projects',
  dataset = 'Datasets',
  experiment = 'Experiments',
  registeredModel = 'Registered Models',
  registeredModelVersion = 'Registered Model Versions',
  deployment = 'Deployments',
}

export enum MLOpsTotalCountKey {
  user = 'userTotalCount',
  project = 'projectTotalCount',
  dataset = 'datasetTotalCount',
  experiment = 'experimentTotalCount',
  registeredModel = 'registedModelTotalCount',
  registeredModelVersion = 'registedModelVersionsTotalCount',
  deployment = 'deploymentTotalCount',
}

export enum AiemColNameKey {
  engineDate = 'engineDate',
  engineTime = 'engineTime',
  engineUser = 'engineUser',
  engineType = 'engineType',
  engineEvent = 'engineEvent',
  engineVersion = 'engineVersion',
  engineName = 'engineName',
  engineCPU = 'engineCPU',
  engineMemory = 'engineMemory',
  engineGPU = 'engineGPU',
  engineStorage = 'engineStorage',
}

export enum AiemColName {
  engineDate = 'Date',
  engineTime = 'Time (HH:MM:SS)',
  engineUser = 'User',
  engineType = 'Type',
  engineEvent = 'Event',
  engineVersion = 'Version',
  engineName = 'Engine Name',
  engineCPU = 'CPU',
  engineMemory = 'Memory',
  engineGPU = 'GPU',
  engineStorage = 'Storage',
}

export enum AiemTypesTag {
  dai = 'Driverless AI',
  h2o3 = 'H2O3',
}

export enum AiemOriginalType {
  dai = 'TYPE_DRIVERLESS_AI',
  h2o3 = 'TYPE_H2O',
  nan = 'nan',
}

export enum AiemEventOriginalName {
  start = 'ai/h2o/engine/event/starting',
  pause = 'ai/h2o/engine/event/pausing',
  delete = 'ai/h2o/engine/event/deleting',
}

export enum AiemEventName {
  start = 'Started',
  pause = 'Paused',
  delete = 'Deleted',
}

export const AIEM_ENGINE_NAME_PREFIX = 'workspaces/default/daiEngines/';

export enum UserLoginColumnKey {
  name = 'userLoginName',
  date = 'userLoginDate',
  time = 'userLoginTime',
}

export enum UserLoginColumnName {
  name = 'User',
  date = 'Date',
  time = 'Time (HH:MM:SS)',
}

export enum UMAdminTableColumnKey {
  name = 'userName',
  status = 'userStatus',
  email = 'userEmail',
  action = 'userAction',
}

export enum UMAdminTableColumnName {
  name = 'Name',
  status = 'Status',
  email = 'Email',
  action = 'Actions',
}

export enum UMUserTableColumnKey {
  name = 'userName',
  status = 'userStatus',
  email = 'userEmail',
  action = 'userAction',
}

export enum UMUserTableColumnName {
  name = 'Name',
  status = 'Status',
  email = 'Email',
  action = 'Actions',
}

export enum UserRoleOptionKey {
  user = 'user',
  admin = 'admin',
}

export enum UserRoleOption {
  user = 'User',
  admin = 'Admin',
}

export enum protocolKey {
  tcp = 'tcp',
  udp = 'udp',
}

export enum protocolType {
  tcp = 'TCP',
  udp = 'UDP',
}

export const OktaStatusLabel: Record<string, string> = {
  STAGED: 'Staged',
  PROVISIONED: 'Pending User Action',
  ACTIVE: 'Active',
  RECOVERY: 'Password Reset',
  PASSWORD_EXPIRED: 'Password Expired',
  LOCKED_OUT: 'Locked Out',
  SUSPENDED: 'Suspended',
  DEPROVISIONED: 'Deactivated',
};

export const OktaStatusIdpLabel: Record<string, string> = {
  'STAGED-IDP': 'Staged - IdP',
  'PROVISIONED-IDP': 'Pending User Action - IdP',
  'ACTIVE-IDP': 'Active - IdP',
  'RECOVERY-IDP': 'Password Reset - IdP',
  'PASSWORD_EXPIRED-IDP': 'Password Expired - IdP',
  'LOCKED_OUT-IDP': 'Locked Out - IdP',
  'SUSPENDED-IDP': 'Suspended - IdP',
  'DEPROVISIONED-IDP': 'Deactivated - IdP',
};

export type StatusLabel = keyof typeof OktaStatusLabel | keyof typeof OktaStatusIdpLabel;
