import { daiEngineProfileEntity } from './DAIEngineProfile.entity';
import { daiEngineVersionEntity } from './DAIEngineVersion.entity';
import { h2oEngineProfileEntity } from './H2OEngineProfile.entity';
import { h2oEngineVersionEntity } from './H2OEngineVersion.entity';
import { notebookEngineProfileEntity } from './NotebookEngineProfile.entity';
import { Entity, EntityType } from './types';

export const AIEMSettingsEntitiesMap = new Map<EntityType, Entity<any>>([
  [EntityType.DAIEngineProfile, daiEngineProfileEntity],
  [EntityType.H2OEngineProfile, h2oEngineProfileEntity],
  [EntityType.NotebookEngineProfile, notebookEngineProfileEntity],
  [EntityType.DAIEngineVersion, daiEngineVersionEntity],
  [EntityType.H2OEngineVersion, h2oEngineVersionEntity],
]);
