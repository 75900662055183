import { useBoolean } from '@fluentui/react-hooks';
import {
  AppBarNavigation,
  BookTimeDialog,
  Button,
  ConfirmDialog,
  IconName,
  NavigationElementConfig,
  getPersonaProps,
  useAppNavigationContext,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';

import {
  AppInstance_Status,
  AppInstance_Visibility,
  AppPreconditionStatus,
  App_Visibility,
} from '../../ai.h2o.cloud.appstore';
import { useRoles } from '../../authz/providers/RoleProvider';
import { listServices } from '../../discovery-service/api';
import { getSortedAppsAndCategoryAppsMap } from '../../pages/AppStorePage/AppStorePage';
import { BaseCategory } from '../../pages/AppStorePage/AppStorePage.models';
import { useProjects } from '../../pages/MLOps/ProjectProvider';
import { useWorkspaces } from '../../pages/Orchestrator/WorkspaceProvider';
import { TagService } from '../../services/api';
import { useApp, useCloudPlatformDiscovery, useEnv, useInstance, useUser } from '../../utils/hooks';
import { HaicAboutModal } from '../HaicAboutModal/HaicAboutModal';
import { ProductLogo } from '../ProductLogo/ProductLogo';
import { WorkspacesDropdown } from './components/WorkspacesDropdown';
import { signInButtonStyles } from './Navigation.styles';
import type { NavigationConfig } from './types';
import { formatDataTestId, getNavigationConfig, getUserMenuItems } from './utils';

export const Navigation = () => {
  const user = useUser();
  const { ACTIVE_WORKSPACE_NAME } = useWorkspaces();
  const { ACTIVE_PROJECT_ID, permissions: MLOpsPermissions } = useProjects();
  const { disableWorkspacesDropdown, workspacesDisabled } = useAppNavigationContext();
  const auth = useAuth();
  const env = useEnv();
  const history = useHistory();
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const { permissions } = useRoles();
  const { getInstancesList } = useInstance();
  const theme = useTheme();
  const { getApps } = useApp();
  const publicRoutesOnly = env?.publicModeEnabled && !user.hasFullAccess;

  const [appVersion, setAppVersion] = useState('');
  const [instancePromptHidden, setInstancePromptHidden] = useState(true);
  const [instanceCount, setInstanceCount] = useState<number | null>(null);
  const [navigationConfig, setNavigationConfig] = useState<NavigationConfig>({
    navigationSectionConfig: {},
    userMenuItems: [],
  });
  const [bookTimeHidden, setBookTimeHidden] = useState(true);
  const [appStoreCategories, setAppStoreCategories] = useState<NavigationElementConfig[]>([]);
  const [hiddenAbout, { setFalse: showAbout, setTrue: hideAbout }] = useBoolean(true);

  const logout = useCallback(async () => {
    const token = auth.user?.id_token;

    setInstancePromptHidden(true);

    await auth.removeUser();
    await auth.signoutRedirect({ id_token_hint: token });
  }, []);
  const goToMyInstances = useCallback(() => {
    setInstancePromptHidden(true);
    history.push('/instances');
  }, [history, setInstancePromptHidden]);
  const onLogoutClick = useCallback(async () => {
    let count;

    try {
      const instances = await getInstancesList({
        appId: '',
        includeAppDetails: false,
        allUsers: false,
        visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
        allWorkspaces: false,
      });

      count = instances.filter((instance) =>
        [
          AppInstance_Status.DEPLOYED,
          AppInstance_Status.PENDING,
          AppInstance_Status.STATUS_UNSPECIFIED,
          AppInstance_Status.STATUS_UNKNOWN,
        ].includes(instance.status)
      ).length;
    } catch (err) {
      console.error(err);
      count = null;
    }

    setInstanceCount(count);

    count !== 0 ? setInstancePromptHidden(false) : await logout();
  }, [instanceCount, logout, getInstancesList]);
  const headerClick = () => history.push('/');

  const fetchAppStoreCategories = async () => {
    try {
      const [applications, categoryTags] = await Promise.all([
        getApps({
          limit: 1000,
          offset: 0,
          visibility: App_Visibility.VISIBILITY_UNSPECIFIED,
          allUsers: true,
          name: '',
          latestVersions: true,
          withPreference: true,
          tags: [],
          conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
          visibilities: [App_Visibility.ALL_USERS, App_Visibility.PUBLIC],
        }),
        TagService.listCategories({}),
      ]);
      const { appCategoryMap } = getSortedAppsAndCategoryAppsMap(categoryTags, applications);
      const appStoreCategoriesConfig = [...appCategoryMap]
        .filter(([, { name }]) => name !== BaseCategory.PinnedApps && name !== BaseCategory.MostPopular)
        .map((category) => {
          const [, { name, title, count }] = category;

          return {
            key: name,
            name: title,
            to: `/appstore?category=${name}`,
            dataTest: `app-store-${formatDataTestId(name)}--sub-main-menu-item`,
            disabled: !count,
            more: { text: count },
          };
        }) as unknown as NavigationElementConfig[];

      setAppStoreCategories([
        {
          key: 'all',
          name: 'All',
          exact: true,
          to: '/appstore',
          dataTest: `app-store-all--sub-main-menu-item`,
        },
        ...appStoreCategoriesConfig,
      ]);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  useEffect(() => {
    const setVersion = async () => {
      const servicesList = await listServices({ pageSize: 1000 });

      setAppVersion(servicesList?.filter((service) => service.name === 'services/appstore')[0].version || '');
    };

    void setVersion();

    if (env?.menu) {
      const userMenu = getUserMenuItems(env.menu || {}, { hasMenu: false }, onLogoutClick, history, showAbout);
      const navigationConfig = getNavigationConfig({
        userMenu,
        env,
        cloudPlatformDiscovery,
        permissions,
        MLOpsPermissions,
        appVersion,
        appStoreCategories,
        user,
        activeWorkspaceName: ACTIVE_WORKSPACE_NAME,
        activeProjectId: ACTIVE_PROJECT_ID,
      });

      setNavigationConfig(navigationConfig);
    }
  }, [
    env?.menu,
    cloudPlatformDiscovery,
    appVersion,
    workspacesDisabled,
    permissions,
    MLOpsPermissions,
    appStoreCategories,
    ACTIVE_WORKSPACE_NAME,
    ACTIVE_PROJECT_ID,
  ]);
  useEffect(() => {
    void fetchAppStoreCategories();

    // Disable the Workspaces dropdown by default
    // Can be enabled from any place by calling enableWorkspacesDropdown();
    disableWorkspacesDropdown();
  }, []);

  return (
    <>
      <AppBarNavigation
        logoElement={<ProductLogo />}
        personaProps={auth.user?.id_token ? getPersonaProps(env?.menu || {}) : undefined}
        onLogoClick={headerClick}
        onTitleClick={headerClick}
        userMenuItems={auth.user?.id_token ? navigationConfig.userMenuItems : undefined}
        navigationConfig={navigationConfig.navigationSectionConfig}
        hideNavigation={publicRoutesOnly}
        headerContent={!publicRoutesOnly && <WorkspacesDropdown disabled={workspacesDisabled} />}
      >
        {!auth.user?.id_token && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              text="Sign in"
              onClick={() => auth.signinRedirect()}
              styles={signInButtonStyles(theme)}
              iconName={IconName.ChevronRight}
            />
          </div>
        )}
      </AppBarNavigation>

      <ConfirmDialog
        hidden={instancePromptHidden}
        title="Terminate instances before logout?"
        content={
          <>
            You have <b>{instanceCount ?? 'Unknown'}</b> running app instance{instanceCount === 1 ? '' : 's'} that are
            consuming cluster resources, do you wish to review and terminate them before logging out?
          </>
        }
        confirmationButtonText="No, logout"
        dismissalButtonText="Yes, go to My Instances"
        onDismiss={goToMyInstances}
        onConfirm={logout}
      />
      <HaicAboutModal hidden={hiddenAbout} onDismiss={hideAbout} />
      <BookTimeDialog url={env?.menu?.bookTimeLink} onDismiss={() => setBookTimeHidden(true)} hidden={bookTimeHidden} />
    </>
  );
};
