import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { plotStyle } from '../AIUnitHourly.styles';

interface AiUnitHourlyCummulativeEvent {
  date: string;
  month: string;
  year: string;
  aiUnits: number;
  cumAiUnits: number;
}

interface CummulativePlotProps {
  events: AiUnitHourlyCummulativeEvent[];
  threshold: number;
  max_percentage: number;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CumulativePlot = ({ events, threshold, max_percentage }: CummulativePlotProps) => {
  const labels = events.map((event) => event.date);
  const cumulativeData = events.map((event) => event.cumAiUnits);
  const thresholdData = events.map(() => threshold);

  const data = {
    labels,
    datasets: [
      {
        label: 'AI Unit Threshold Value',
        data: thresholdData,
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        pointRadius: 0,
      },
      {
        label: 'Cumulative AI Unit Value',
        data: cumulativeData,
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: 'Entire Contract Period',
        font: {
          size: 20,
        },
      },
      subtitle: {
        display: true,
        text: `AI Unit Hour Usage: ${max_percentage.toFixed(2)}%`,
        font: {
          size: 16,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={plotStyle}>
      <Line data={data} options={options} />
    </div>
  );
};

export default CumulativePlot;
