import { DeploymentComposition } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_composition_pb';
import { Deployment } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_pb';
import { ListProjectDeploymentsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_service_pb';
import { FilterRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/listing_pb';
import { Security } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/security_pb';
import { DeploymentService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_service_connect';
import {
  BatchDeleteExperimentRequest,
  DeleteExperimentRequest,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/experiment_service_pb';
import { Operator } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/query_pb';
import { RegisteredModel } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_pb';
import {
  DeleteRegisteredModelRequest,
  ListRegisteredModelsRequest,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_service_pb';
import { RegisteredModelVersion } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_pb';
import {
  DeleteModelVersionRequest,
  ListModelVersionsForModelRequest,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_service_pb';
import { ExperimentService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/experiment_service_connect';
import { RegisteredModelService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_service_connect';
import { RegisteredModelVersionService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_version_service_connect';
import { createClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { CollapseAllVisibility, Dropdown, IDetailsGroupDividerProps, IGroup, MessageBarType } from '@fluentui/react';
import { Button, ListRow, ListRowType, buttonStylesLink, getGroupHeight, useTheme, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';
import WidgetList, { WidgetListProps } from '../../components/WidgetList/WidgetList';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { formatError } from '../../utils/utils';
import { ContextMenuIconButton } from '../Orchestrator/Workflows';
import { useConfirmDialog } from './ConfirmDialogProvider';
import { ENDPOINTS, ROUTES } from './constants';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

export type DeploymentOptions = {
  // Related to the first model.
  firstModelDeploymentComposition: DeploymentComposition;
  // Related to the second model.
  secondModelDeploymentComposition: DeploymentComposition;
  // General deployment options.
  displayName: string;
  description: string;
  deploymentEnvironmentId: string;
  monitoringEnabled: boolean;
  driftMonitoringEnabled: boolean;
  advanced: {
    toleration: string;
    affinity: string;
  };
  requests: Record<string, string>;
  limits: Record<string, string>;
  kubernetesOptions: {
    replicas: number;
  };
  security?: Security;
};

export type RegisteredModelItem = RegisteredModel & {
  createdTimeLocal: string;
  createdByName: string;
  deleteModel?: () => Promise<void>;
  onEdit: () => void;
  onAddNewVersion?: () => void;
  viewOnly?: boolean;
};

export type RegisteredModelVersionItem = RegisteredModelVersion & {
  displayName: string;
  description: string;
  createdTimeLocal: string;
  createdByName: string;
  deleteModelVersion?: () => Promise<void>;
  onDeployModelVersion?: () => void;
  onBatchScoreModelVersion?: () => void;
  viewOnly?: boolean;
  activeDeployments?: Deployment[];
  onNavigateToDeploymentDetail: (deploymentId: string) => () => void;
  contents?: JSX.Element;
  hasLeftOpenClose?: boolean;
  collapsed?: boolean;
};

export type RegisteredModelVersions = Record<string, RegisteredModelVersion[]>;

export type HistoryState = {
  from: string;
};

export const getDeploymentExperimentsIds = (deployment: Deployment) => {
  if (deployment?.deployment.case === 'singleDeployment') {
    const idModel = deployment?.deployment?.value.deploymentComposition?.experimentId;
    return [idModel];
  }
  if (deployment?.deployment.case === 'splitDeployment') {
    const idModelA = deployment?.deployment?.value?.splitElements[0]?.deploymentComposition?.experimentId;
    const idModelB = deployment?.deployment?.value?.splitElements[1]?.deploymentComposition?.experimentId;
    return [idModelA, idModelB];
  }
  if (deployment?.deployment.case === 'shadowDeployment') {
    const idModelA = deployment?.deployment?.value?.primaryElement?.deploymentComposition?.experimentId;
    const idModelB = deployment?.deployment?.value?.secondaryElement?.deploymentComposition?.experimentId;
    return [idModelA, idModelB];
  }
  return undefined;
};

const getFilterRequest = (filter: string) =>
    ({
      query: {
        clause: [
          {
            propertyConstraint: [
              {
                property: {
                  propertyType: {
                    // TODO: Make filter case insensitive.
                    value: 'name',
                    case: 'field',
                  },
                },
                operator: Operator.CONTAINS,
                value: {
                  value: {
                    case: 'stringValue',
                    value: filter,
                  },
                },
              },
            ],
          },
        ],
      },
    } as FilterRequest),
  columns = [
    {
      key: 'spacer',
      name: '',
      minWidth: 60,
      maxWidth: 60,
      data: {
        listCellProps: {
          styles: {
            root: {
              marginLeft: 24,
            },
          },
        },
      },
    },
    {
      key: 'version',
      name: 'Version',
      fieldName: 'version',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      key: 'user',
      name: 'Created by',
      fieldName: 'createdByName',
      minWidth: 150,
      maxWidth: 220,
    },
    {
      key: 'createdAt',
      name: 'Created at',
      fieldName: 'createdTimeLocal',
      minWidth: 150,
      maxWidth: 250,
    },
    {
      key: 'deployments',
      name: 'Active deployments',
      minWidth: 150,
      maxWidth: 150,
      fieldName: 'activeDeployments',
      data: {
        listCellProps: {
          onRenderText: ({ activeDeployments, onNavigateToDeploymentDetail }: RegisteredModelVersionItem) =>
            activeDeployments?.length ? (
              <div>
                <Dropdown
                  placeholder={`${activeDeployments?.length} ${
                    activeDeployments?.length === 1 ? 'deployment' : 'deployments'
                  }`}
                  options={
                    activeDeployments?.map((deployment) => ({
                      key: deployment.id,
                      text: deployment.displayName,
                    })) || []
                  }
                  onRenderItem={(item) => (
                    <Button
                      menuIconProps={{
                        iconName: 'Forward',
                        // TODO: Theme color.
                        style: { fontSize: 12, color: 'black' },
                      }}
                      text={item?.text}
                      onClick={() => onNavigateToDeploymentDetail(`${item!.key}`)()}
                      styles={[
                        buttonStylesLink,
                        {
                          textContainer: {
                            color: 'black',
                            fontSize: 12,
                          },
                          flexContainer: {
                            selectors: {
                              '&:hover span': { color: 'var(--h2o-blue500)' },
                              '&:hover i': { color: 'var(--h2o-blue500) !important' },
                            },
                          },
                          root: { paddingLeft: 8, paddingRight: 8 },
                        },
                      ]}
                    />
                  )}
                  calloutProps={{
                    calloutMaxWidth: 220,
                    calloutMinWidth: 200,
                    styles: {
                      root: {
                        padding: '8px 0px',
                      },
                    },
                  }}
                  styles={{
                    title: {
                      border: 'none',
                      padding: '0px 35px 0px 0px',
                      fontSize: 12,
                    },
                    caretDownWrapper: {
                      i: {
                        fontSize: 12,
                      },
                    },
                  }}
                />
              </div>
            ) : (
              'No deployments'
            ),
        },
      },
    },
    {
      key: 'buttons',
      name: '',
      minWidth: 200,
      data: {
        listCellProps: {
          emptyMessage: '',
          onRenderText: ({
            deleteModelVersion,
            viewOnly,
            onDeployModelVersion,
            onBatchScoreModelVersion,
          }: RegisteredModelVersionItem) =>
            // TODO: Use theme prop for colors.
            viewOnly ? null : (
              <ContextMenuIconButton
                items={[
                  {
                    key: 'deploy',
                    text: 'Deploy version',
                    onClick: onDeployModelVersion,
                    iconProps: { iconName: 'PublishContent', style: { color: 'var(--h2o-blue500)' } },
                    style: { color: 'var(--h2o-blue500)' },
                  },
                  {
                    key: 'batchScore',
                    text: 'Batch score',
                    onClick: onBatchScoreModelVersion,
                    iconProps: { iconName: 'AssessmentGroup', style: { color: 'var(--h2o-yellow700)' } },
                    style: { color: 'var(--h2o-yellow700)' },
                  },
                  {
                    key: 'delete',
                    text: 'Delete version',
                    // TODO: Implement onClickDelete.
                    onClick: () => {
                      (async () => {
                        await deleteModelVersion?.();
                      })();
                    },
                    style: { color: 'var(--h2o-red400)', display: viewOnly ? 'none' : undefined },
                    iconProps: {
                      iconName: 'DeleteRows',
                      style: { color: 'var(--h2o-red400)' },
                    },
                  },
                ]}
              />
            ),
          styles: {
            root: {
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'end',
            },
          },
        },
      },
    },
  ],
  headerColumns = [
    {
      // Empty item to add space for collapse/expand button.
      key: 'toggleSpacer',
      name: '',
      minWidth: 30,
      maxWidth: 30,
    },
    {
      key: 'title',
      name: 'Title',
      fieldName: 'description',
      minWidth: 200,
      maxWidth: 800,
      data: {
        headerFieldName: 'displayName',
        listCellProps: {
          onRenderHeader: ({ displayName, onEdit }: RegisteredModelItem) =>
            RowHeaderTitle({ title: displayName, onClick: onEdit }),
          iconProps: {
            iconName: 'HomeGroup',
          },
        },
      },
    },
    {
      key: 'versions',
      name: '',
      fieldName: 'versions',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      key: 'user',
      name: 'Created by',
      fieldName: 'createdByName',
      minWidth: 150,
      maxWidth: 220,
    },
    {
      key: 'createdAt',
      name: 'Created at',
      fieldName: 'createdTimeLocal',
      minWidth: 150,
      maxWidth: 250,
    },
    {
      key: 'buttons',
      name: '',
      minWidth: 200,
      data: {
        listCellProps: {
          emptyMessage: '',
          onRenderText: ({ deleteModel, onAddNewVersion, viewOnly }: RegisteredModelItem) =>
            // TODO: Use theme prop for colors.
            viewOnly ? null : (
              <ContextMenuIconButton
                items={[
                  {
                    key: 'uploadVersion',
                    text: 'Add new model version',
                    onClick: () => {
                      (async () => {
                        await onAddNewVersion?.();
                      })();
                    },
                    iconProps: {
                      iconName: 'Add',
                      style: { color: 'var(--h2o-gray900)' },
                    },
                  },
                  {
                    key: 'delete',
                    text: 'Delete model',
                    // TODO: Implement onClickDelete.
                    onClick: () => {
                      (async () => {
                        await deleteModel?.();
                      })();
                    },
                    style: { color: 'var(--h2o-red400)', display: viewOnly ? 'none' : undefined },
                    iconProps: {
                      iconName: 'Delete',
                      style: { color: 'var(--h2o-red400)' },
                    },
                  },
                ]}
              />
            ),
          styles: {
            root: {
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'end',
            },
          },
        },
      },
    },
  ];

const Models = () => {
  const theme = useTheme(),
    history = useHistory(),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID, permissions } = useProjects(),
    loadStateRef = React.useRef({
      fetchRegisteredModels: false,
      fetchModelVersions: false,
      fetchProjectDeployments: false,
      deletingExperiments: false,
    }),
    { showDialog } = useConfirmDialog(),
    [loading, setLoading] = React.useState(true),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [isSearchStr, setIsSearchStr] = React.useState(false),
    [nextPageTokenModels, setNextPageTokenModels] = React.useState<string>(),
    // TODO: Handle loading of all pages of deployments.
    [, setNextPageTokenDeployments] = React.useState<string>(),
    [registeredModelItems, setRegisteredModelItems] = React.useState<RegisteredModelItem[]>(),
    [registeredModelVersions, setRegisteredModelVersions] = React.useState<RegisteredModelVersions>(),
    [registeredModelVersionsItems, setRegisteredModelVersionsItems] = React.useState<RegisteredModelVersionItem[]>(),
    [deployments, setDeployments] = React.useState<Deployment[]>(),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    deploymentClient = createClient(DeploymentService, deploymentTransport),
    registeredModelClient = createClient(RegisteredModelService, storageTransport),
    registerModelVersionClient = createClient(RegisteredModelVersionService, storageTransport),
    experimentClient = createClient(ExperimentService, storageTransport),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchRegisteredModels &&
        !loadStateRef.current.fetchModelVersions &&
        !loadStateRef.current.fetchProjectDeployments &&
        !loadStateRef.current.deletingExperiments
      ) {
        setLoading(false);
      }
    },
    onAction = () => history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/add-new`),
    batchDeleteExperiment = React.useCallback(
      async (experimentIds: string[]) => {
        try {
          if (!ACTIVE_PROJECT_ID) return;
          loadStateRef.current.deletingExperiments = true;
          const deleteExperimentsBody = new BatchDeleteExperimentRequest({
            experimentRequest: experimentIds.map(
              (id) => new DeleteExperimentRequest({ id, projectId: ACTIVE_PROJECT_ID })
            ),
          });
          await experimentClient.batchDeleteExperiment(deleteExperimentsBody);
          await fetchRegisteredModels();
        } catch (err) {
          const message = `Failed to delete model experiments: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.deletingExperiments = false;
          evaluateLoading();
        }
      },
      [addToast, ACTIVE_PROJECT_ID]
    ),
    deleteModel = React.useCallback(
      async (modelId: string, relatedExperimentIds?: string[]) => {
        try {
          const deleteRegisteredModelBody = new DeleteRegisteredModelRequest({
            modelId,
          });
          await registeredModelClient.deleteRegisteredModel(deleteRegisteredModelBody);
          if (relatedExperimentIds) await batchDeleteExperiment(relatedExperimentIds);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Model deleted successfully.',
          });
          await fetchRegisteredModels();
        } catch (err) {
          const message = `Failed to delete model: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [addToast, ACTIVE_PROJECT_ID]
    ),
    deleteModelVersion = React.useCallback(
      async (modelVersionId: string, experimentId: string) => {
        try {
          const deleteModelVersionBody = new DeleteModelVersionRequest({
            id: modelVersionId,
          });
          await registerModelVersionClient.deleteModelVersion(deleteModelVersionBody);
          if (experimentId) await batchDeleteExperiment([experimentId]);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Model version deleted successfully.',
          });
          await fetchRegisteredModels();
        } catch (err) {
          const message = `Failed to delete model version: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [addToast, ACTIVE_PROJECT_ID]
    ),
    listModelVersionsForModel = React.useCallback(
      async (registeredModelId: string, modelName: string) => {
        loadStateRef.current.fetchModelVersions = true;
        try {
          const listModelVersionsForModelBody = new ListModelVersionsForModelRequest({
            registeredModelId,
          });
          const response = await registerModelVersionClient.listModelVersionsForModel(listModelVersionsForModelBody);
          const modelVersions = response?.modelVersions;
          if (!modelVersions) console.error('No model versions found in the response.');
          return modelVersions;
        } catch (err) {
          const message = `Failed to fetch model versions for ${modelName} (${registeredModelId}): ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.fetchModelVersions = false;
          evaluateLoading();
        }
      },
      [addToast, registerModelVersionClient]
    ),
    listVersionsForAllModels = React.useCallback(async () => {
      if (!registeredModelItems) return;
      const allModelVersions: RegisteredModelVersions = {};
      for (const item of registeredModelItems) {
        const modelVersions = await listModelVersionsForModel(item.id, item.displayName);
        if (modelVersions) allModelVersions[item.id] = modelVersions;
      }
      // TODO: Sort by first model version creation time as fetch calls may not be in order.
      setRegisteredModelVersions(allModelVersions);
    }, [registeredModelItems]),
    fetchRegisteredModels = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.fetchRegisteredModels = true;
        if (pageToken) setIsLoadingMore(true);
        else if (filter || filter === `""`) setIsLoadingSearch(true);
        else setLoading(true);
        try {
          const listRegisteredModelsBody = new ListRegisteredModelsRequest({
            projectId: ACTIVE_PROJECT_ID,
            paging: {
              pageSize: 20,
              pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
            },
            filter: filter ? getFilterRequest(filter) : undefined,
          });
          const response = await registeredModelClient.listRegisteredModels(listRegisteredModelsBody);
          const registeredModelItems: RegisteredModel[] | undefined = response?.registeredModels;
          if (response && !registeredModelItems) console.error('No registered models found in the response.');
          setNextPageTokenModels(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );
          const newItems: RegisteredModelItem[] | undefined = registeredModelItems?.map(
            (item) =>
              ({
                ...item,
                createdTimeLocal:
                  item.createdTime?.seconds !== undefined
                    ? new Date(Number(item.createdTime.seconds) * 1000).toLocaleString()
                    : '',
                // TODO: Display user name instead of the id.
                createdByName: item.createdBy,
                deleteModel: () => {
                  showDialog?.({
                    title: 'Delete model?',
                    description: 'This will delete the model and all its versions. This action cannot be undone.',
                    onConfirm: () => {
                      // Setter is used to avoid stale closure.
                      setRegisteredModelVersions((modelVersions) => {
                        const modelExperiments = modelVersions?.[item.id]?.map(
                          (modelVersion) => modelVersion.experimentId
                        );
                        deleteModel(item.id, modelExperiments);
                        return modelVersions;
                      });
                    },
                  });
                },
                onEdit: () => history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/${item.id}`),
                onAddNewVersion: () =>
                  history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/${item.id}/add-new-version`, {
                    from: 'models',
                  } as HistoryState),
                viewOnly: !permissions.canWrite,
                // TODO: Fix type.
              } as unknown as RegisteredModelItem)
          );
          setRegisteredModelItems((items) => (pageToken ? [...(items || []), ...(newItems || [])] : newItems));
        } catch (err) {
          const message = `Failed to fetch registered models: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setRegisteredModelItems(undefined);
        } finally {
          loadStateRef.current.fetchRegisteredModels = false;
          evaluateLoading();
          setIsLoadingMore(false);

          setIsLoadingSearch(false);
        }
      },
      [addToast, ACTIVE_PROJECT_ID, permissions, showDialog]
    ),
    fetchDeployments = React.useCallback(
      // TODO: Fetch all pages of deployments.
      async (pageToken?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.fetchProjectDeployments = true;
        setLoading(true);
        try {
          const listDeploymentsBody = new ListProjectDeploymentsRequest({
            projectId: ACTIVE_PROJECT_ID,
            paging: {
              pageSize: 20,
              pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
            },
          });
          const response = await deploymentClient.listProjectDeployments(listDeploymentsBody);
          const deploymentItems: Deployment[] | undefined = response?.deployment;
          if (response && !deploymentItems) console.error('No deployments found in the response.');
          setNextPageTokenDeployments(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );

          setDeployments((items) => (pageToken ? [...(items || []), ...(deploymentItems || [])] : deploymentItems));
        } catch (err) {
          const message = `Failed to fetch deployments: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setDeployments(undefined);
        } finally {
          loadStateRef.current.fetchProjectDeployments = false;
          evaluateLoading();
        }
      },
      [addToast, ACTIVE_PROJECT_ID, permissions]
    ),
    actionProps = React.useMemo(
      () =>
        permissions.canWrite
          ? {
              actionIcon: 'Add',
              actionTitle: 'Add model',
              onActionClick: onAction,
            }
          : undefined,
      [permissions]
    ),
    widgetListProps: WidgetListProps = {
      columns,
      items: registeredModelVersionsItems,
      loading: !!loading,
      delayLoader: false,
      isLoadingMore: isLoadingMore,
      onLoadMore: nextPageTokenModels ? () => void fetchRegisteredModels(nextPageTokenModels) : undefined,
      isLoadingSearch,
      searchProps: {
        placeholder: 'Search models',
        // TODO: Check if "value" is correct.
        onSearchChange: (value: string) => {
          setIsSearchStr(!!value);
          void fetchRegisteredModels(undefined, value);
        },
      },
      actionProps,
      NoItemsContent: NoItemView({
        title: 'Models',
        description: 'There are no models available in this project. Create the first one to begin.',
        ...actionProps,
      }),
      ErrorContent: FailedToLoadView({
        title: 'Failed to load models',
        description: 'Please try again later. If the problem persists, contact our support.',
        actionTitle: 'Retry',
        onActionClick: fetchRegisteredModels,
        actionIcon: 'Refresh',
      }),
      groupProps: {
        getGroupHeight: (group: IGroup) => getGroupHeight(group, ListRowType.GroupHeader),
        groupProps: {
          collapseAllVisibility: CollapseAllVisibility.hidden,
          onRenderHeader: (props?: IDetailsGroupDividerProps) => {
            if (!props) return null;
            const { group } = props;
            const item = group?.data?.data;
            const count = group?.count || 0;
            return (
              <ListRow
                data={item}
                columns={headerColumns}
                contents={item?.contents}
                hasLeftOpenClose={true}
                collapsed={group?.isCollapsed}
                hasOpenClose={count > 0}
                onOpen={() => {
                  // TODO:
                  props?.onToggleCollapse!(group!);
                  return true;
                }}
                styles={{
                  row: {
                    '&:hover': {
                      background: theme.semanticColors?.contentBackground,
                      '.h2o-ListCell-text': {
                        color: theme.semanticColors?.textSecondary,
                      },
                    },
                  },
                }}
              />
            );
          },
          showEmptyGroups: true,
        },
        groups: (registeredModelItems || []).map((item, itemIdx) => {
          const modelVersions = registeredModelVersions?.[item.id];
          let prevModelVersionsCount = 0;
          for (let i = 0; i < itemIdx; i++) {
            prevModelVersionsCount +=
              registeredModelItems?.[i]?.id && registeredModelVersions
                ? registeredModelVersions?.[registeredModelItems?.[i].id]?.length || 0
                : 0;
          }
          return {
            count: modelVersions?.length || 0,
            data: {
              data: {
                ...item,
                versions: (modelVersions?.length || 0) === 1 ? `1 version` : `${modelVersions?.length || 0} versions`,
              },
              hasGroupHeaderData: true,
              index: itemIdx,
              isLoaded: false,
            },
            isCollapsed: true,
            key: item.id,
            name: '',
            startIndex: prevModelVersionsCount,
          };
        }),
      },
    };

  React.useEffect(() => void fetchRegisteredModels(), [fetchRegisteredModels]);

  React.useEffect(() => {
    if (!deployments && ACTIVE_PROJECT_ID) {
      void fetchDeployments();
    }
  }, [fetchDeployments, ACTIVE_PROJECT_ID]);

  React.useEffect(() => {
    if (registeredModelItems) {
      void listVersionsForAllModels();
    }
  }, [registeredModelItems]);

  React.useEffect(() => {
    if (!registeredModelItems || !registeredModelVersions || !deployments) return;
    const registeredModelVersionsItems: RegisteredModelVersionItem[] = [];
    for (const [modelId, modelVersions] of Object.entries(registeredModelVersions || {})) {
      const sortedModelVersions = modelVersions.sort(
        (a, b) => Number(a.createdTime?.seconds || 0) - Number(b.createdTime?.seconds || 0)
      );
      for (const modelVersion of sortedModelVersions?.length ? sortedModelVersions : modelVersions) {
        registeredModelVersionsItems.push({
          ...modelVersion,
          // TODO: Optimize displayName and description lookup.
          displayName: registeredModelItems?.find((item) => item.id === modelId)?.displayName || '',
          description: registeredModelItems?.find((item) => item.id === modelId)?.description || '',
          createdTimeLocal:
            modelVersion.createdTime?.seconds !== undefined
              ? new Date(Number(modelVersion.createdTime.seconds) * 1000).toLocaleString()
              : '',
          createdByName: modelVersion.createdBy,
          deleteModelVersion: () => {
            showDialog?.({
              title: 'Delete model version?',
              description: `This will delete version ${modelVersion.version} of the model. This action cannot be undone.`,
              onConfirm: () => deleteModelVersion(modelVersion.id, modelVersion.experimentId),
            });
          },
          onDeployModelVersion: () => {
            history.push(
              `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/create-new/deploy/${modelVersion.id}`
            );
          },
          onBatchScoreModelVersion: () => {
            history.push(
              `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.BATCH_SCORING}/start-new/from-model/${modelVersion.id}`
            );
          },
          onNavigateToDeploymentDetail: (deploymentId: string) => () => {
            history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/${deploymentId}`);
          },
          // TODO: Optimize by pre-computing active deployments.
          activeDeployments: deployments?.filter((deployment) =>
            getDeploymentExperimentsIds(deployment)?.includes(modelVersion.experimentId)
          ),
        } as RegisteredModelVersionItem);
      }
    }
    setRegisteredModelVersionsItems(registeredModelVersionsItems);
  }, [registeredModelVersions, registeredModelItems, deployments, showDialog]);

  return (
    <PageWrapper>
      {registeredModelItems?.length || isSearchStr || loading ? <Header /> : null}
      <WidgetList {...widgetListProps} />
    </PageWrapper>
  );
};

export default Models;
