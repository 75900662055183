import { IComboBoxStyles } from '@fluentui/react';
import { FontSizes, FontWeights, IH2OTheme, Sizes } from '@h2oai/ui-kit';

export const AiUnitConsumptionTopStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const SidePanelButtonStyle = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'flex-end',
};

export const AlertInputStyle = {
  width: '100%',
  padding: '5px',
  marginBottom: '10px',
};

export const LoaderContainerStyle = (theme: IH2OTheme) => ({
  position: 'absolute' as const,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette?.white,
  opacity: 0.6,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 99,
});

export const AlertCheckboxStyle = (theme: IH2OTheme) => ({
  maxHeight: '20%',
  height: '500px',
  backgroundColor: theme.palette?.gray300,
  overflowY: 'scroll' as 'auto' | 'hidden' | 'scroll' | 'visible',
});

export const TimePickerStyle = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
};

export const UserListStyle = {
  marginBottom: '10px',
};

export const AiUnitMaxStyles = (theme: IH2OTheme) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: '20px',
  padding: Sizes.padding,
  marginBottom: '30px',
  backgroundColor: theme.palette?.white,
  borderRadius: Sizes.borderRadius,
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  fontFamily: "'Roboto', sans-serif",
  color: theme.palette?.gray300,
});

export const InfoLabel = (theme: IH2OTheme) => ({
  fontWeight: FontWeights.bold,
  color: theme.palette?.gray500,
});

export const InfoValue = (theme: IH2OTheme) => ({
  fontWeight: FontWeights.normal,
  color: theme.palette?.black,
  fontSize: FontSizes.small,
});

export const HourStyle = (theme: IH2OTheme) => ({
  padding: '5px 10px',
  borderRadius: Sizes.borderRadius,
  border: `1px solid ${theme.palette?.gray300}`,
  fontSize: FontSizes.small,
  marginRight: '5px',
  width: '60px',
  textAlign: 'center' as 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent',
});

export const MinuteStyle = (theme: IH2OTheme) => ({
  padding: '5px 10px',
  borderRadius: Sizes.borderRadius,
  border: `1px solid ${theme.palette?.gray300}`,
  fontSize: FontSizes.small,
  width: '60px',
  textAlign: 'center' as 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent',
});

export const ComboBoxStyles = (): Partial<IComboBoxStyles> => ({
  root: {
    marginBottom: '8px',
  },
});
