import { ISliderStyles } from '@fluentui/react';
import { ISliderProps, Loader, LoaderType, Slider, useTheme } from '@h2oai/ui-kit';
import { SyntheticEvent, useCallback } from 'react';

import {
  ProfileConstraintDuration,
  ProfileConstraintNumeric,
} from '../../../../aiem/gen/ai/h2o/engine/v1/profile_constraint_pb';
import { ConstraintBasicType, ConstraintType, ConstraintTypeMap } from '../../../../aiem/types';
import { bytesToGibibytes, gibibytesToBytes, hoursToSeconds, secondsToHours } from '../../../../aiem/utils';
import { getNumericValue } from '../../utils';
import { FluentNumberField, IFluentNumberFieldProps } from '../FluentNumberField/FluentNumberField';
import { LabelIconTooltip } from '../LabelIconTooltip/LabelIconTooltip';

export interface IConstraintInputProps {
  constraint?: ProfileConstraintNumeric | ProfileConstraintDuration;
  constraintType: ConstraintType;
  disabled?: boolean;
  id?: string;
  label?: string;
  onChange?: (event?: SyntheticEvent<HTMLElement, Event>, value?: string | number) => void;
  step?: number;
  tooltip?: string;
  value?: string | number | null;
}

const getDefaultMin = (constraint: ProfileConstraintNumeric | ProfileConstraintDuration): string => {
  // we always want to avoid negative numbers, so the default minimum will be zero
  // max may or may not be defined
  return constraint.min ?? '0';
};

const getValue = (
  constraint?: ProfileConstraintNumeric | ProfileConstraintDuration,
  existingValue?: string | number | null | undefined
): string | undefined => {
  if (!constraint) {
    return undefined;
  }
  if (typeof existingValue === 'number') {
    existingValue = existingValue.toString();
  }
  return existingValue ?? constraint.default ?? getDefaultMin(constraint);
};

export function ConstraintInput({
  constraint,
  constraintType,
  disabled,
  id,
  label,
  onChange,
  step = 1,
  tooltip,
  value,
}: IConstraintInputProps) {
  const theme = useTheme();
  const sliderStyles: Partial<ISliderStyles> = {
    slideBox: {
      padding: 0,
      height: 24,
      lineHeight: 24,
      ':hover, :active': {
        '.ms-Slider-thumb': {
          border: 0,
          backgroundColor: theme.semanticColors?.textQuaternary,
          cursor: 'pointer',
        },
        '.ms-Slider-active': {
          backgroundColor: theme.semanticColors?.textQuaternary,
        },
      },
    },
  };
  const convertToApiValue = useCallback((value: string | number | null | undefined): string | number | undefined => {
    switch (ConstraintTypeMap.get(constraintType)) {
      case ConstraintBasicType.TIME:
        return hoursToSeconds(value);
      case ConstraintBasicType.BYTES:
        return gibibytesToBytes(value);
      default:
        return value ?? undefined;
    }
  }, []);
  const convertFromApiValue = useCallback((value: string | number | null | undefined): string | undefined => {
    switch (ConstraintTypeMap.get(constraintType)) {
      case ConstraintBasicType.TIME:
        return secondsToHours(value);
      case ConstraintBasicType.BYTES:
        return bytesToGibibytes(value);
      default:
        return value === null ? undefined : typeof value === 'number' ? value.toString() : value;
    }
  }, []);

  const onChangeHandler = (
    event: SyntheticEvent<HTMLElement, Event> | undefined,
    value: string | number | undefined
  ) => {
    const apiValue = convertToApiValue(value);
    if (onChange) {
      onChange(event, apiValue);
    }
  };

  const numberFieldProps: IFluentNumberFieldProps = {
    id,
    disabled,
    step,
    label,
    tooltip,
    min: getNumericValue(convertFromApiValue(constraint?.min)),
    max: getNumericValue(convertFromApiValue(constraint?.max)),
    value: convertFromApiValue(getValue(constraint, value)),
    onChange: (event: SyntheticEvent<HTMLElement, Event>, newValue?: string | number) => {
      onChangeHandler(event, newValue);
    },
  };
  const sliderProps: ISliderProps = {
    id,
    disabled,
    step,
    label,
    min: getNumericValue(convertFromApiValue(constraint?.min)),
    max: getNumericValue(convertFromApiValue(constraint?.max)),
    value: getNumericValue(convertFromApiValue(getValue(constraint, value))),
    onChange: undefined,
    onChanged: (event, value, _range?) => {
      return onChangeHandler(event, value);
    },
    onRenderLabel: tooltip
      ? () => {
          return <LabelIconTooltip label={label} tooltip={tooltip} />;
        }
      : undefined,
    styles: sliderStyles,
  };

  if (!constraint) {
    return <Loader type={LoaderType.progressIndicator} />;
  } else if (constraint.max === constraint.min && constraint.max === constraint.default) {
    return <FluentNumberField {...numberFieldProps} fixed={true} />;
  } else if (!!convertFromApiValue(constraint?.max)) {
    return <Slider {...sliderProps} />;
  } else {
    return <FluentNumberField {...numberFieldProps} />;
  }
}
