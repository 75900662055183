import { IH2OTheme, Sizes } from '@h2oai/ui-kit';

export const appEventTopStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '50px',
};

export const dateAreaStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: 'auto',
  alignItems: 'center',
  marginBottom: '16px',
};

export const startDateStyle = {
  marginRight: '10px',
  width: '170px',
};

export const endDateStyle = {
  width: '170px',
};

export const searchAreaStyle = {
  display: 'flex',
  alignItems: 'center',
};

export const searchStyle = {
  width: '250px',
  marginRight: '20px',
  marginBottom: '16px',
};

export const tableAreaStyle = {
  marginTop: '10px',
};

export const tabStyle = (theme: IH2OTheme) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.palette?.gray300}`,
});

export const tableStyle = (theme: IH2OTheme) => ({
  backgroundColor: theme.palette?.white,
  padding: Sizes.padding,
  height: '556px',
  overflowY: 'auto' as 'auto' | 'hidden' | 'scroll' | 'visible',
  overflowX: 'auto' as 'auto' | 'hidden' | 'scroll' | 'visible',
});
