import { DeploymentComposition } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_composition_pb';
import { ListSuggestedScoringCompositionsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_profiling_service_pb';
import { DeploymentProfilingService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_profiling_service_connect';
import { Artifact } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_pb';
import { ListEntityArtifactsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_service_pb';
import { Operator } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/query_pb';
import { RegisteredModel } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_pb';
import { ListRegisteredModelsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_service_pb';
import { ListModelVersionsForModelRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_service_pb';
import { ArtifactService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/artifact_service_connect';
import { RegisteredModelService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_service_connect';
import { RegisteredModelVersionService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_version_service_connect';
import { createClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IDropdownOption, IStyle, MessageBarType, mergeStyles } from '@fluentui/react';
import {
  Dropdown,
  IH2OTheme,
  Info,
  Loader,
  Search,
  Slider,
  WidgetItem,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
// TODO: Move to global context.
import { WidgetIconButton } from '../Orchestrator/Runnables';
import { ENDPOINTS, ROUTES } from './constants';
import { DeploymentTypeKey, DropdownOption } from './DeploymentTabDetail';
import { DeploymentOptions, RegisteredModelItem, RegisteredModelVersions } from './Models';
import { useProjects } from './ProjectProvider';

interface IModelSelectorStyles {
  placeholderContainer: IStyle;
  placeholderContainerSmall: IStyle;
  placeholderContent: IStyle;
  placeholderContentSmall: IStyle;
  widgetLayout: IStyle;
  loader: IStyle;
  errorText: IStyle;
  sliderLabel: IStyle;
  sliderLabelLeft: IStyle;
  sliderLabelRight: IStyle;
  trafficDistributionTitle: IStyle;
  trafficDistributionTitleText: IStyle;
}

const modelSelectorStyles = (theme: IH2OTheme): Partial<IModelSelectorStyles> => ({
    placeholderContainer: {
      margin: '16px 0',
      height: 100, // Widget height.
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
      outlineWidth: 1,
      outlineStyle: 'dashed',
      outlineColor: theme.semanticColors?.inputBorder,
    },
    placeholderContainerSmall: {
      width: '50%',
    },
    placeholderContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: 452, // Callout width.
    },
    placeholderContentSmall: {
      minWidth: 280, // Callout width.
    },
    widgetLayout: {
      display: 'flex',
      gap: 16,
    },
    loader: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: 100, // Widget height.
    },
    errorText: {
      color: theme.semanticColors?.inputErrorMessageText,
    },
    sliderLabel: { display: 'flex', maxWidth: 460 },
    sliderLabelLeft: { flex: '1 1 0%', color: 'var(--h2o-gray500, #6B7280)', fontSize: 12 },
    sliderLabelRight: { flex: '1 1 0%', color: 'var(--h2o-gray500, #6B7280)', fontSize: 12, textAlign: 'right' },
    trafficDistributionTitle: { display: 'flex', alignItems: 'center' },
    trafficDistributionTitleText: { marginRight: 8 },
  }),
  widgetItemStyles = (theme: IH2OTheme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
      margin: '16px 0',
      height: 100,
      outlineWidth: 1,
      outlineStyle: 'solid',
      outlineColor: theme.semanticColors?.inputBorder,
      '.h2o-WidgetItem-actions': {
        flex: 3,
      },
      '.h2o-WidgetItem-content': {
        flex: 1,
      },
    },
  }),
  versionDropdownStyles = {
    root: {
      width: 58,
      marginRight: 8,
      marginLeft: 4,
    },
    title: {
      border: 'none',
      borderBottom: '1px solid black',
      borderRadius: 0,
      padding: 0,
      fontSize: 14,
      backgroundColor: 'transparent',
      lineHeight: 'unset',
      minHeight: 'unset',
    },
    caretDownWrapper: {
      lineHeight: 'unset',
      right: 4,
      i: {
        fontSize: 12,
      },
    },
  },
  actionStyle: React.CSSProperties = {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  sliderContainerStyles = {
    root: {
      maxWidth: 460,
    },
    minMaxLabel: {
      display: 'none',
    },
    input: {
      display: 'none',
    },
  },
  getWidgetActions = (
    onOpenSelection: () => void,
    onRemoveSelection: () => void,
    onAddNewVersion: () => void,
    classNames: ClassNamesFromIStyles<IModelSelectorStyles>,
    onSelectVersion: (experimentId: string) => void,
    versionOptions: IDropdownOption[],
    selectedVersionExperimentId?: string,
    readOnly?: boolean
  ) => (
    <div style={{ display: 'flex', flexGrow: 1 }}>
      {selectedVersionExperimentId && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>Model version</div>
          <Dropdown
            options={versionOptions}
            styles={versionDropdownStyles}
            selectedKey={selectedVersionExperimentId}
            onChange={(_, option) => onSelectVersion(option?.key as string)}
            disabled={readOnly || !versionOptions.length}
          />
        </div>
      )}
      {!versionOptions.length ? (
        <p className={classNames.errorText} style={{ marginLeft: 6, padding: 4 }}>
          This model has no versions.{' '}
          <button
            onClick={onAddNewVersion}
            style={{
              background: 'none',
              border: 'none',
              // TODO: Theme this.
              color: 'var(--h2o-red500, #BA2525)',
              textDecoration: 'underline',
              cursor: 'pointer',
              fontSize: 14,
              padding: 0,
            }}
          >
            Add version
          </button>{' '}
          to this model or select a different model.
        </p>
      ) : null}
      {!readOnly ? (
        <div style={actionStyle}>
          <WidgetIconButton iconName="OpenInNewTab" onClick={onOpenSelection} title="Open in new tab" />
          <WidgetIconButton
            title="Delete"
            iconName="Delete"
            iconColor="white"
            backgroundColor="red"
            hasBorder={false}
            onClick={onRemoveSelection}
          />
        </div>
      ) : null}
    </div>
  ),
  defaultWidgetData = {
    title: `Selected item does not exist.`,
    description: '',
    iconName: '',
  };

const ModelSelector = ({
  selectedModels,
  onSelectedModelsChange,
  defaultDeploymentCompositionA,
  defaultDeploymentCompositionB,
  trafficDistribution = 50,
  onTrafficDistributionChange,
  deploymentType,
  onChangeOptions,
  defaultSelectedVersionId,
  defaultSelectedModelIds,
  defaultSelectedExperimentIds,
  onContentLoadFinish,
  onContentLoadStart,
  readOnly,
}: {
  selectedModels: (RegisteredModel | undefined)[];
  onSelectedModelsChange: (models: (RegisteredModel | undefined)[]) => void;
  defaultDeploymentCompositionA?: DeploymentComposition;
  defaultDeploymentCompositionB?: DeploymentComposition;
  trafficDistribution?: number;
  onTrafficDistributionChange?: (value?: number) => void;
  deploymentType: DeploymentTypeKey;
  onChangeOptions: (options: Partial<DeploymentOptions>) => void;
  defaultSelectedVersionId?: string;
  defaultSelectedModelIds?: string[];
  defaultSelectedExperimentIds?: string[];
  onContentLoadFinish?: () => void;
  onContentLoadStart?: () => void;
  readOnly?: boolean;
}) => {
  const theme = useTheme(),
    classNames = useClassNames<IModelSelectorStyles, ClassNamesFromIStyles<IModelSelectorStyles>>(
      'modelSelectorStyles',
      modelSelectorStyles(theme)
    ),
    { addToast } = useToast(),
    history = useHistory(),
    shouldShowTwoWidgets = deploymentType === 'ab_test' || deploymentType === 'champion_challenger',
    [artifactCompositionOptionsA, setArtifactCompositionOptionsA] = React.useState<DropdownOption[]>([]),
    [selectedArtifactCompositionA, setSelectedArtifactCompositionA] = React.useState<string>(
      defaultDeploymentCompositionA
        ? `${defaultDeploymentCompositionA?.deployableArtifactTypeName || ''}-${
            defaultDeploymentCompositionA?.runtimeName || ''
          }`
        : ''
    ),
    [artifactCompositionOptionsB, setArtifactCompositionOptionsB] = React.useState<DropdownOption[]>([]),
    [selectedArtifactCompositionB, setSelectedArtifactCompositionB] = React.useState<string>(
      defaultDeploymentCompositionB
        ? `${defaultDeploymentCompositionB?.deployableArtifactTypeName || ''}-${
            defaultDeploymentCompositionB?.runtimeName || ''
          }`
        : ''
    ),
    { ACTIVE_PROJECT_ID } = useProjects(),
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    calloutARef = React.useRef<HTMLDivElement | null>(null),
    calloutBRef = React.useRef<HTMLDivElement | null>(null),
    artifactClient = createClient(ArtifactService, storageTransport),
    registeredModelVersionClient = createClient(RegisteredModelVersionService, storageTransport),
    registeredModelClient = createClient(RegisteredModelService, storageTransport),
    deploymentProfilingClient = createClient(DeploymentProfilingService, deploymentTransport),
    // TODO: Support loading more models.
    [, setIsLoadingMoreModels] = React.useState(false),
    [, setModelNextPageToken] = React.useState<string>(),
    [isLoadingModelSearch, setIsLoadingModelSearch] = React.useState(false),
    [registeredModelItems, setRegisteredModelItems] = React.useState<RegisteredModelItem[]>(),
    [registeredModelVersions, setRegisteredModelVersions] = React.useState<RegisteredModelVersions>(),
    [firstSelectedModelVersionOptions, setFirstSelectedModelVersionOptions] = React.useState<IDropdownOption[]>([]),
    [secondSelectedModelVersionOptions, setSecondSelectedModelVersionOptions] = React.useState<IDropdownOption[]>([]),
    [firstSelectedModelVersionExperimentId, setFirstSelectedModelVersionExperimentId] = React.useState(
      defaultSelectedExperimentIds?.[0]
    ),
    [secondSelectedModelVersionExperimentId, setSecondSelectedModelVersionExperimentId] = React.useState(
      defaultSelectedExperimentIds?.[1]
    ),
    [isLoading, setIsLoading] = React.useState(true),
    loadStateRef = React.useRef({
      fetchRegisteredModels: true,
      getRegisteredModelVersion: false,
      fetchEntityArtifacts: false,
      fetchingScoringCompositions: false,
    }),
    evaluateLoading = React.useCallback(() => {
      // TODO: Remove loading refs regarding ModelSelector.
      if (
        !loadStateRef.current.fetchRegisteredModels &&
        !loadStateRef.current.getRegisteredModelVersion &&
        !loadStateRef.current.fetchEntityArtifacts &&
        !loadStateRef.current.fetchingScoringCompositions
      ) {
        setIsLoading(false);
      }
    }, []),
    getScoringCompositions = React.useCallback(
      async (experimentId?: string) => {
        loadStateRef.current.fetchingScoringCompositions = true;
        setIsLoading(true);
        try {
          const listSuggestedScoringCompositionsRequest = new ListSuggestedScoringCompositionsRequest({
            experimentId,
          });
          const response = await deploymentProfilingClient.listSuggestedScoringCompositions(
            listSuggestedScoringCompositionsRequest
          );
          const deploymentCompositions: DeploymentComposition[] | undefined = response?.deploymentCompositions;
          if (response && !deploymentCompositions) console.error('No scoring compositions found in the response.');
          return deploymentCompositions;
        } catch (err) {
          const message = `Failed to fetch scoring compositions: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.fetchingScoringCompositions = false;
          evaluateLoading();
        }
      },
      [addToast]
    ),
    getEntityArtifacts = React.useCallback(
      async (entityId: string) => {
        if (!entityId) return;
        loadStateRef.current.fetchEntityArtifacts = true;
        setIsLoading(true);
        try {
          const listEntityArtifactsRequest = new ListEntityArtifactsRequest({
            // Experiment id.
            entityId,
          });
          const response = await artifactClient.listEntityArtifacts(listEntityArtifactsRequest);
          const artifacts: Artifact[] | undefined = response?.artifact;
          if (response && !artifacts) console.error('No artifacts found in the response.');
          return artifacts;
        } catch (err) {
          const message = `Failed to fetch artifacts: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.fetchEntityArtifacts = false;
          evaluateLoading();
        }
      },
      [addToast, evaluateLoading]
    ),
    getModelVersionsByModel = React.useCallback(
      async (modelId: string, modelName: string) => {
        loadStateRef.current.getRegisteredModelVersion = true;
        setIsLoading(true);
        try {
          const getModelVersionsByModelRequest = new ListModelVersionsForModelRequest({
            registeredModelId: modelId,
          });
          const response = await registeredModelVersionClient.listModelVersionsForModel(getModelVersionsByModelRequest);
          const modelVersions = response?.modelVersions;
          if (modelVersions) {
            return modelVersions;
          } else {
            console.error('No model versions found in the response.');
            return undefined;
          }
        } catch (err) {
          const message = `Failed to fetch model versions for ${modelName} (${modelId}): ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.getRegisteredModelVersion = false;
          evaluateLoading();
        }
      },
      [addToast, evaluateLoading]
    ),
    onSelectModelUpdateOptions = React.useCallback(async () => {
      if (!selectedModels.length) return;
      let firstModelOptions: Partial<DeploymentOptions> = {
        firstModelDeploymentComposition: undefined,
      };
      let secondModelOptions: Partial<DeploymentOptions> = {
        secondModelDeploymentComposition: undefined,
      };

      if (selectedModels?.[0] && firstSelectedModelVersionExperimentId) {
        const firstModelScoringCompositions = await getScoringCompositions(firstSelectedModelVersionExperimentId);

        if (!firstModelScoringCompositions) {
          const message = 'No experiment found for the model.';
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return;
        }

        setArtifactCompositionOptionsA(
          firstModelScoringCompositions.map((composition) => ({
            key: `${composition.deployableArtifactTypeName}-${composition.runtimeName}`,
            text: `${composition.deployableArtifactTypeName} (${composition.runtimeName})`,
            data: composition,
          }))
        );
        setSelectedArtifactCompositionA(
          firstModelScoringCompositions[0]
            ? `${firstModelScoringCompositions[0].deployableArtifactTypeName}-${firstModelScoringCompositions[0].runtimeName}`
            : ''
        );

        firstModelOptions = {
          firstModelDeploymentComposition: firstModelScoringCompositions[0],
        };
      }

      if (selectedModels?.[1] && secondSelectedModelVersionExperimentId) {
        const secondModelScoringCompositions = await getScoringCompositions(secondSelectedModelVersionExperimentId);

        if (!secondModelScoringCompositions) {
          const message = 'No experiment found for the second model.';
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return;
        }

        setArtifactCompositionOptionsB(
          secondModelScoringCompositions.map((composition) => ({
            key: `${composition.deployableArtifactTypeName}-${composition.runtimeName}`,
            text: `${composition.deployableArtifactTypeName} (${composition.runtimeName})`,
            data: composition,
          }))
        );
        setSelectedArtifactCompositionB(
          secondModelScoringCompositions[0]
            ? `${secondModelScoringCompositions[0].deployableArtifactTypeName}-${secondModelScoringCompositions[0].runtimeName}`
            : ''
        );

        secondModelOptions = {
          secondModelDeploymentComposition: secondModelScoringCompositions[0],
        };
      }
      onChangeOptions({ ...firstModelOptions, ...secondModelOptions });
    }, [
      addToast,
      getEntityArtifacts,
      getModelVersionsByModel,
      selectedModels,
      evaluateLoading,
      firstSelectedModelVersionExperimentId,
      secondSelectedModelVersionExperimentId,
    ]),
    fetchRegisteredModels = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.fetchRegisteredModels = true;
        if (pageToken) setIsLoadingMoreModels(true);
        else if (filter || filter === `""`) setIsLoadingModelSearch(true);
        else setIsLoading(true);
        try {
          const listRegisteredModelsBody = new ListRegisteredModelsRequest(
            filter
              ? {
                  projectId: ACTIVE_PROJECT_ID,
                  paging: {
                    pageSize: 20,
                    pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
                  },
                  filter: {
                    query: {
                      clause: [
                        {
                          propertyConstraint: [
                            {
                              property: {
                                propertyType: {
                                  // TODO: Make filter case insensitive.
                                  value: 'name',
                                  case: 'field',
                                },
                              },
                              operator: Operator.CONTAINS,
                              value: {
                                value: {
                                  case: 'stringValue',
                                  value: filter,
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                }
              : {
                  projectId: ACTIVE_PROJECT_ID,
                  paging: {
                    pageSize: 20,
                    pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
                  },
                }
          );
          const response = await registeredModelClient.listRegisteredModels(listRegisteredModelsBody);
          const registeredModelItems: RegisteredModel[] | undefined = response?.registeredModels;
          if (response && !registeredModelItems) console.error('No registered models found in the response.');
          setModelNextPageToken(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );
          const newItems: RegisteredModelItem[] | undefined = registeredModelItems?.map(
            (item) =>
              ({
                ...item,
                createdTimeLocal:
                  item.createdTime?.seconds !== undefined
                    ? new Date(Number(item.createdTime.seconds) * 1000).toLocaleString()
                    : '',
                // TODO: Display user name instead of the id.
                createdByName: item.createdBy,
              } as RegisteredModelItem)
          );
          setRegisteredModelItems((items) => (pageToken ? [...(items || []), ...(newItems || [])] : newItems));
        } catch (err) {
          const message = `Failed to fetch registered models: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setRegisteredModelItems(undefined);
        } finally {
          loadStateRef.current.fetchRegisteredModels = false;
          evaluateLoading();
          setIsLoadingMoreModels(false);
          setIsLoadingModelSearch(false);
        }
      },
      [addToast, ACTIVE_PROJECT_ID, evaluateLoading]
    ),
    listVersionsForAllModels = React.useCallback(async () => {
      if (!registeredModelItems) return;
      const allModelVersions: RegisteredModelVersions = {};
      for (const item of registeredModelItems) {
        const modelVersions = await getModelVersionsByModel(item.id, item.displayName);
        if (modelVersions) allModelVersions[item.id] = modelVersions;
      }
      // TODO: Sort by first model version creation time as fetch calls may not be in order.
      setRegisteredModelVersions(allModelVersions);
    }, [registeredModelItems]),
    searchProps = React.useMemo(
      () => ({
        emptyMessage: 'No results found',
        hasSearchResult: true,
        onRenderSearchResultItemActions: () => <></>,
        onSearchTextChange: (text?: string) => void fetchRegisteredModels(undefined, text),
        placeholder: `Search by model name`,
        searchResultItems: registeredModelItems,
        minCalloutWidth: deploymentType === 'single_model' ? 452 : 280,
        searchResultItemFields: {
          titleField: 'displayName',
          descriptionField: 'description',
          iconNameField: 'iconName',
        },
        onDismissCallout: () => void fetchRegisteredModels(),
        readOnly,
        styles: {
          root: {
            width: '100%',
          },
        },
        // TODO: Add loader.
        loadingMessage: isLoadingModelSearch ? 'Loading models...' : '',
      }),
      [fetchRegisteredModels, isLoadingModelSearch, registeredModelItems, deploymentType, readOnly]
    );

  React.useEffect(() => {
    if (registeredModelItems) {
      void listVersionsForAllModels();
    }
  }, [registeredModelItems]);

  React.useEffect(() => {
    if (registeredModelVersions) {
      if (selectedModels?.[0]) {
        const firstModelVersions = registeredModelVersions?.[selectedModels[0]?.id];
        setFirstSelectedModelVersionOptions(
          firstModelVersions?.map((version) => ({
            key: version.experimentId,
            text: `v${version.version}`,
          })) || []
        );
        if (firstModelVersions?.length) {
          const defaultModelVersion = defaultSelectedVersionId
            ? firstModelVersions?.find((version) => version.id === defaultSelectedVersionId)
            : undefined;
          const latestFirstModelVersion =
            defaultModelVersion ||
            firstModelVersions?.reduce((max, current) => (current.version > max.version ? current : max));

          setFirstSelectedModelVersionExperimentId(
            defaultSelectedExperimentIds?.[0] || latestFirstModelVersion.experimentId
          );
        }
      }
      if (selectedModels?.[1]) {
        const secondModelVersions = registeredModelVersions?.[selectedModels[1]?.id];
        setSecondSelectedModelVersionOptions(
          secondModelVersions?.map((version) => ({
            key: version.experimentId,
            text: `v${version.version}`,
          })) || []
        );
        if (secondModelVersions?.length) {
          const latestSecondModelVersion = secondModelVersions?.reduce((max, current) =>
            current.version > max.version ? current : max
          );
          setSecondSelectedModelVersionExperimentId(
            defaultSelectedExperimentIds?.[1] || latestSecondModelVersion.experimentId
          );
        }
      }
    }
  }, [registeredModelVersions, selectedModels, defaultSelectedVersionId, defaultSelectedExperimentIds]);

  React.useEffect(() => {
    // Select model with its version when quick deploy action is used.
    if (defaultSelectedVersionId && registeredModelVersions) {
      const defaultModelId = Object.entries(registeredModelVersions).find(([_key, value]) =>
        value.some((version) => version.id === defaultSelectedVersionId)
      )?.[0];
      if (defaultModelId) {
        const defaultModel = registeredModelItems?.find((model) => model.id === defaultModelId);
        if (defaultModel) {
          onSelectedModelsChange([defaultModel, selectedModels?.[1]]);
        }
      }
    }
  }, [defaultSelectedVersionId, registeredModelVersions]);

  // TODO: Update directly from model selection callout.
  React.useEffect(() => {
    onSelectModelUpdateOptions();
  }, [selectedModels, onSelectModelUpdateOptions]);

  React.useEffect(() => void fetchRegisteredModels(), [fetchRegisteredModels]);

  React.useEffect(() => onContentLoadStart?.(), []);

  React.useEffect(() => {
    // TODO: For some reason it is called sooner than expected.
    if (!isLoading) onContentLoadFinish?.();
  }, [isLoading]);

  React.useEffect(() => {
    if (!registeredModelItems || !defaultSelectedModelIds || !defaultSelectedModelIds.length) return;
    const models = registeredModelItems?.filter((item) => defaultSelectedModelIds?.includes(item.id)) || [];
    if (defaultSelectedModelIds?.[0] === defaultSelectedModelIds?.[1]) onSelectedModelsChange([models[0], models[0]]);
    else onSelectedModelsChange(models);
  }, [defaultSelectedModelIds, registeredModelItems]);

  // TODO: Make the model widget to start loading with the deployment detail page.
  return (
    <>
      <div className={classNames.widgetLayout}>
        {isLoading ? (
          <div className={classNames.loader}>
            <Loader label="Loading model widget..." />
          </div>
        ) : (
          <>
            {selectedModels?.[0] ? (
              <WidgetItem
                styles={widgetItemStyles(theme)}
                style={{ width: deploymentType === 'single_model' ? '100%' : '50%' }}
                actions={getWidgetActions(
                  () => {
                    window.open(
                      `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/${selectedModels[0]?.id}`,
                      '_blank'
                    );
                  },
                  () => {
                    onSelectedModelsChange([undefined, selectedModels?.[1]]);
                    setSelectedArtifactCompositionA('');
                    if (deploymentType === 'ab_test') {
                      onTrafficDistributionChange?.(50);
                    }
                    setFirstSelectedModelVersionExperimentId('');
                    setFirstSelectedModelVersionOptions([]);
                    setArtifactCompositionOptionsA([]);
                  },
                  () => {
                    history.push(
                      `/mlops/projects/${selectedModels?.[0]?.projectId}${ROUTES.MODELS}/${selectedModels?.[0]?.id}/add-new-version`,
                      { from: 'model-selector' }
                    );
                  },
                  classNames,
                  setFirstSelectedModelVersionExperimentId,
                  firstSelectedModelVersionOptions,
                  firstSelectedModelVersionExperimentId,
                  readOnly
                )}
                data={selectedModels[0] || defaultWidgetData}
                idField={'id'}
                titleField={'displayName'}
              />
            ) : (
              <div
                className={mergeStyles(
                  classNames.placeholderContainer,
                  deploymentType === 'single_model' ? '' : classNames.placeholderContainerSmall
                )}
              >
                <div
                  className={mergeStyles(
                    classNames.placeholderContent,
                    deploymentType === 'single_model' ? '' : classNames.placeholderContentSmall
                  )}
                >
                  <p>
                    {deploymentType === 'single_model'
                      ? 'Select a model.'
                      : deploymentType === 'ab_test'
                      ? 'Select Model A.'
                      : 'Select a CHAMPION model.'}
                  </p>
                  <Search
                    {...searchProps}
                    calloutProps={{ dismissOnTargetClick: true, ref: calloutARef }}
                    onClickItem={(item) => {
                      onSelectedModelsChange([item as RegisteredModel, selectedModels?.[1]]);
                      // HACK: Dismiss callout.
                      calloutARef.current?.parentElement?.click();
                    }}
                  />
                </div>
              </div>
            )}
            {shouldShowTwoWidgets ? (
              selectedModels?.[1] ? (
                <WidgetItem
                  styles={widgetItemStyles(theme)}
                  style={{ width: '50%' }}
                  actions={getWidgetActions(
                    () => {
                      window.open(
                        `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/${selectedModels[1]?.id}`,
                        '_blank'
                      );
                    },
                    () => {
                      onSelectedModelsChange([selectedModels[0], undefined]);
                      setSelectedArtifactCompositionB('');
                      if (deploymentType === 'ab_test') {
                        onTrafficDistributionChange?.(50);
                      }
                      setSecondSelectedModelVersionExperimentId('');
                      setSecondSelectedModelVersionOptions([]);
                      setArtifactCompositionOptionsB([]);
                    },
                    () => {
                      history.push(
                        `/mlops/projects/${selectedModels?.[1]?.projectId}${ROUTES.MODELS}/${selectedModels?.[1]?.id}/add-new-version`,
                        { from: 'model-selector' }
                      );
                    },
                    classNames,
                    setSecondSelectedModelVersionExperimentId,
                    secondSelectedModelVersionOptions,
                    secondSelectedModelVersionExperimentId,
                    readOnly
                  )}
                  data={selectedModels[1] || defaultWidgetData}
                  idField={'id'}
                  titleField={'displayName'}
                />
              ) : (
                <div className={mergeStyles(classNames.placeholderContainer, classNames.placeholderContainerSmall)}>
                  <div className={mergeStyles(classNames.placeholderContent, classNames.placeholderContentSmall)}>
                    <p>{deploymentType === 'ab_test' ? 'Select Model B.' : 'Select a CHALLENGER model.'}</p>
                    <Search
                      {...searchProps}
                      calloutProps={{ dismissOnTargetClick: true, ref: calloutBRef }}
                      onClickItem={(item) => {
                        onSelectedModelsChange([selectedModels[0], item as RegisteredModel]);
                        // HACK: Dismiss callout.
                        calloutBRef.current?.parentElement?.click();
                      }}
                    />
                  </div>
                </div>
              )
            ) : null}
          </>
        )}
      </div>
      {(selectedModels?.[0] || selectedModels?.[1]) && (
        <>
          <h4>Deployment parameters</h4>
          {selectedModels?.[0] ? (
            <>
              {deploymentType !== 'single_model' ? (
                <h5>{`${deploymentType === 'ab_test' ? 'Model A' : 'Champion model'}`}</h5>
              ) : null}
              <Dropdown
                label="Artifact type & Runtime"
                required
                options={artifactCompositionOptionsA}
                selectedKey={selectedArtifactCompositionA}
                onChange={(_ev, option) => {
                  setSelectedArtifactCompositionA(option?.key as string);
                  onChangeOptions({
                    firstModelDeploymentComposition: option?.data,
                  });
                }}
                disabled={!artifactCompositionOptionsA.length || !selectedModels.length || readOnly}
                styles={{ root: { maxWidth: 460 } }}
              />
              {!artifactCompositionOptionsA.length && !isLoading ? (
                <p className={classNames.errorText}>There are no available runtimes present to deploy this model.</p>
              ) : null}
            </>
          ) : null}
          {deploymentType !== 'single_model' && selectedModels?.[1] ? (
            <>
              <h5>{`${deploymentType === 'ab_test' ? 'Model B' : 'Challenger model'}`}</h5>
              <Dropdown
                label="Artifact type & Runtime"
                required
                options={artifactCompositionOptionsB}
                selectedKey={selectedArtifactCompositionB}
                onChange={(_ev, option) => {
                  setSelectedArtifactCompositionB(option?.key as string);
                  onChangeOptions({
                    firstModelDeploymentComposition: option?.data,
                  });
                }}
                disabled={!artifactCompositionOptionsB.length || !selectedModels.length || readOnly}
                styles={{ root: { maxWidth: 460 } }}
              />
              {!artifactCompositionOptionsB.length && !isLoading ? (
                <p className={classNames.errorText}>There are no available runtimes present to deploy this model.</p>
              ) : null}
            </>
          ) : null}
          {selectedModels?.[0] && selectedModels?.[1] && deploymentType === 'ab_test' ? (
            <>
              <div className={classNames.trafficDistributionTitle}>
                <h5 className={classNames.trafficDistributionTitleText}>Traffic distribution</h5>
                <Info isTooltip>Configure the distribution of incoming scoring requests to each model.</Info>
              </div>
              <Slider
                value={trafficDistribution}
                min={1}
                max={99}
                valueFormat={(value) => `${value}%`}
                onChange={(value) => onTrafficDistributionChange?.(value)}
                showValue={false}
                step={1}
                disabled={readOnly}
                styles={{
                  root: {
                    paddingTop: 0,
                  },
                }}
                sliderContainerStyles={sliderContainerStyles}
              />
              <div className={classNames.sliderLabel}>
                <div
                  className={classNames.sliderLabelLeft}
                >{`${trafficDistribution}% Model A (${selectedModels[0]?.displayName}) `}</div>
                <div className={classNames.sliderLabelRight}>{` ${100 - trafficDistribution}% Model B (${
                  selectedModels[1]?.displayName
                })`}</div>
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default ModelSelector;
