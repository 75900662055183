import { Stack } from '@fluentui/react';
import {
  FontSizes,
  type IButtonProps,
  type IH2OTheme,
  TextWithCopy,
  getDate,
  textWithCopyStylesPlain,
} from '@h2oai/ui-kit';
import type { ReactNode } from 'react';

import { type App, type AppInstance, AppInstance_Status, AppInstance_Visibility } from '../../../ai.h2o.cloud.appstore';
import { InstanceListType } from '../../../utils/models';
import {
  getInstanceAppTitleVersion,
  instanceStatusMap,
  instanceVisibilityIconMap,
  instanceVisibilityMap,
  listIconSize,
} from '../../../utils/utils';
import { AutoPauseLabel } from '../../AutoPauseLabel/AutoPauseLabel';
import { InstanceActionButton } from '../../InstanceActionButton/InstanceActionButton';
import { ActionCell } from '../../ListPages/ActionCell';
import { BadgeCell } from '../../ListPages/BadgeCell';
import { IconCell } from '../../ListPages/IconCell';
import { MetadataCell } from '../../ListPages/MetadataCell';
import { TitleCell } from '../../ListPages/TitleCell';
import type { GetRowActionsProps, InstanceListElement } from '../InstanceList.types';

export const getRowStatus = (theme: IH2OTheme, instance: InstanceListElement, hideTitle = false) => {
  const statusTextColorMap = {
    [AppInstance_Status.DEPLOYED]: theme.palette?.green900,
    [AppInstance_Status.FAILED]: theme.palette?.red900,
    [AppInstance_Status.PENDING]: theme.palette?.yellow900,
    [AppInstance_Status.STATUS_UNKNOWN]: theme.semanticColors?.textPrimary,
    [AppInstance_Status.SUSPENDED]: theme.palette?.gray900,
  };

  const statusBackgroundColorMap = {
    [AppInstance_Status.DEPLOYED]: theme.palette?.green100,
    [AppInstance_Status.FAILED]: theme.palette?.red100,
    [AppInstance_Status.PENDING]: theme.palette?.yellow100,
    [AppInstance_Status.STATUS_UNKNOWN]: theme.palette?.gray400,
    [AppInstance_Status.SUSPENDED]: theme.palette?.gray200,
  };

  return (
    <BadgeCell
      title={hideTitle ? '' : 'Status'}
      dataTest="instance-status-badge"
      badgeLabel={instanceStatusMap[instance.status]}
      badgeBackgroundColor={statusBackgroundColorMap[instance.status]}
      badgeTextColor={statusTextColorMap[instance.status]}
    />
  );
};

export const getRowIcon = (appDetails: App) => (
  <IconCell
    size={listIconSize}
    src={appDetails?.iconLocation ? `/v1/asset/${appDetails.iconLocation}` : '/logo512.png'}
  />
);

export const getRowVisibility = (instance: InstanceListElement, hideTitle = false) => {
  return (
    <BadgeCell
      title={hideTitle ? '' : 'Visibility'}
      dataTest="instance-visibility-badge"
      badgeLabel={instanceVisibilityMap[instance.visibility]}
      badgeIconName={instanceVisibilityIconMap[instance.visibility]}
    />
  );
};

export function getRowActions(props: GetRowActionsProps) {
  const {
    currentUserName,
    instance,
    updateVisibility,
    toggleVisibilityDialog,
    hideInstanceLogButton,
    goToInstanceLog,
    type,
    setInstanceSuspension,
    terminateInstance,
    goToInstance,
    status,
  } = props;

  const isOwner = currentUserName === instance.owner;
  const isVisibilityRestricted =
    instance.visibility === AppInstance_Visibility.PRIVATE ||
    instance.visibility === AppInstance_Visibility.VISIBILITY_UNSPECIFIED;
  const visitNotAllowed = !isOwner && isVisibilityRestricted;

  return (
    <ActionCell
      primaryButton={
        <div className="primary-button-wrapper">
          <InstanceActionButton
            instance={instance}
            loading={instance?.loading || false}
            terminating={instance?.deleting || false}
            onEditInstance={updateVisibility ? toggleVisibilityDialog : undefined}
            onViewInstanceLog={
              hideInstanceLogButton ? undefined : goToInstanceLog(instance, type === InstanceListType.admin)
            }
            setInstanceSuspension={setInstanceSuspension}
            terminateInstance={terminateInstance}
          />
        </div>
      }
      secondaryButtonProps={
        {
          'data-test': `${instance.id}--visit-instance-button`,
          text: 'Visit',
          title: 'Go to app location',
          onClick: goToInstance(instance),
          disabled: status !== AppInstance_Status.DEPLOYED || visitNotAllowed,
        } as IButtonProps
      }
    />
  );
}

export const getRowTitle = (
  instance: InstanceListElement,
  type: InstanceListType,
  instanceIdAliasMap: Map<string, string>,
  autoSuspend: '' | boolean,
  suspendAfter: string
) => {
  return (
    <TitleCell title={getInstanceAppTitleVersion(instance)} subtitle={instance.id}>
      <>
        {type === InstanceListType.admin && (
          <div style={{ display: 'flex', marginBottom: 2 }}>
            <div>
              {instanceIdAliasMap.get(instance.id) ? (
                <TextWithCopy
                  styles={[textWithCopyStylesPlain, { root: { lineHeight: '23px' } }]}
                  text={`${instanceIdAliasMap.get(instance.id)}.cloud.h2o.ai`}
                  header={'Alias:'}
                />
              ) : (
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: 8,
                    fontSize: FontSizes.textPrimary,
                    lineHeight: '20px',
                  }}
                >
                  Alias: No primary alias
                </span>
              )}
            </div>
          </div>
        )}
        {autoSuspend && <AutoPauseLabel suspendAfter={suspendAfter} />}
      </>
    </TitleCell>
  );
};

export const getRowDetails = (instance: InstanceListElement, hideTitle = false) => {
  const formatDate = (dateTime: string | undefined, label: string) => {
    const date = dateTime ? getDate(dateTime) : undefined;
    return date ? (
      <div className="row-details-container">
        <span className="row-details-label">{label} </span>
        <span>
          {date.toLocaleDateString()} {date.toLocaleTimeString()}
        </span>
      </div>
    ) : undefined;
  };

  const createdString = formatDate(instance.createTime, 'Created');
  const updatedString = formatDate(instance.updateTime, 'Updated');

  const metadata = [createdString, updatedString].filter(Boolean);

  return <MetadataCell title={hideTitle ? '' : 'Details'} metadata={metadata} />;
};

export const getAccordionRowHeader = (instance: AppInstance, theme: IH2OTheme): ReactNode => (
  <Stack className="accordion-header-title">
    <IconCell
      size={32}
      src={instance.appDetails.iconLocation ? `/v1/asset/${instance.appDetails.iconLocation}` : '/logo512.png'}
    />
    <span className="accordion-header-title-container">
      <strong>{getInstanceAppTitleVersion(instance)}</strong>
      <div className="accordion-header-title-labels">
        {getRowStatus(theme, instance, true)}
        {getRowVisibility(instance, true)}
      </div>
    </span>
  </Stack>
);
