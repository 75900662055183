import { useEffect } from 'react';

import { defaultBasePath } from '../pages/AdminCenterPage/constants';
import { AdminCenterService } from '../services/api';
import { useCloudPlatformDiscovery } from '../utils/hooks';

export const useAdminCenterService = () => {
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const mcAdminCenterApiUrl = cloudPlatformDiscovery?.mcAdminCenterApiUrl || defaultBasePath;
  useEffect(() => {
    AdminCenterService.setBasePath(mcAdminCenterApiUrl);
  }, [mcAdminCenterApiUrl]);

  return AdminCenterService;
};
