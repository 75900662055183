// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/dai_engine_version_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { DAIEngineVersion } from "./dai_engine_version_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message Create method.
 *
 * @generated from message ai.h2o.engine.v1.CreateDAIEngineVersionRequest
 */
export type CreateDAIEngineVersionRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the daiEngineVersion's parent workspace:
 * * actions/enginemanager/daiEngineVersions/CREATE
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The DAIEngineVersion resource to create.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineVersion dai_engine_version = 2;
 */
daiEngineVersion: DAIEngineVersion;
/**
 * Specify the DAIEngineVersion ID, which will become a part of the DAIEngineVersion
 * resource name.
 *
 * It must:
 * - be in semver format (more segments than three segments allowed)
 * - contain max 63 characters
 *
 * Examples: "1.10.3", "1.10.3-alpha", "1.10.3.2", "1.10.3.2-alpha"
 *
 * @generated from field: string dai_engine_version_id = 3;
 */
daiEngineVersionId: string;
}
;
/**
 * Response message for Create method.
 *
 * @generated from message ai.h2o.engine.v1.CreateDAIEngineVersionResponse
 */
export type CreateDAIEngineVersionResponse = {
/**
 * Created DAIEngineVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineVersion dai_engine_version = 1;
 */
daiEngineVersion?: DAIEngineVersion;
}
;
/**
 * Request message for Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetDAIEngineVersionRequest
 */
export type GetDAIEngineVersionRequest = {
/**
 * DAIEngineVersion resource name.
 * Format: workspaces/{workspace}/daiEngineVersions/{dai_engine_version}
 *
 * Authorization may require the following permission on the daiEngineVersion's parent workspace:
 * * actions/enginemanager/daiEngineVersions/GET
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetDAIEngineVersionResponse
 */
export type GetDAIEngineVersionResponse = {
/**
 * DAIEngineVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineVersion dai_engine_version = 1;
 */
daiEngineVersion?: DAIEngineVersion;
}
;
/**
 * Request message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListDAIEngineVersionsRequest
 */
export type ListDAIEngineVersionsRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the daiEngineVersion's parent workspace:
 * * actions/enginemanager/daiEngineVersions/LIST
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of DAIEngineVersions to return in a response.
 * If unspecified (or set to 0), at most 50 DAIEngineVersions will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListDAIEngineVersionsResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListDAIEngineVersionsResponse
 */
export type ListDAIEngineVersionsResponse = {
/**
 * Collection of DAIEngineVersions.
 *
 * @generated from field: repeated ai.h2o.engine.v1.DAIEngineVersion dai_engine_versions = 1;
 */
daiEngineVersions?: DAIEngineVersion[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for Update method.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDAIEngineVersionRequest
 */
export type UpdateDAIEngineVersionRequest = {
/**
 * Required. DAIEngineVersion resource.
 *
 * Authorization may require the following permission on the daiEngineVersion's parent workspace:
 * * actions/enginemanager/daiEngineVersions/UPDATE
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineVersion dai_engine_version = 1;
 */
daiEngineVersion: DAIEngineVersion;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are:
 * - deprecated
 * - image
 * - image_pull_policy
 * - image_pull_secrets
 *
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response for Update method.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDAIEngineVersionResponse
 */
export type UpdateDAIEngineVersionResponse = {
/**
 * DAIEngineVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineVersion dai_engine_version = 1;
 */
daiEngineVersion?: DAIEngineVersion;
}
;
/**
 * Request message for Delete method.
 *
 * @generated from message ai.h2o.engine.v1.DeleteDAIEngineVersionRequest
 */
export type DeleteDAIEngineVersionRequest = {
/**
 * DAIEngineVersion resource name.
 * Format: workspaces/{workspace}/daiEngineVersions/{dai_engine_version}
 *
 * Authorization may require the following permission on the daiEngineVersion's parent workspace:
 * * actions/enginemanager/daiEngineVersions/DELETE
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for Delete method.
 *
 * @generated from message ai.h2o.engine.v1.DeleteDAIEngineVersionResponse
 */
export type DeleteDAIEngineVersionResponse = {
}
;
/**
 * Request message.
 *
 * @generated from message ai.h2o.engine.v1.AssignDAIEngineVersionAliasesRequest
 */
export type AssignDAIEngineVersionAliasesRequest = {
/**
 * DAIEngineVersion resource name.
 * Format: workspaces/{workspace}/daiEngineVersions/{dai_engine_version}
 *
 * Authorization may require the following permission on the daiEngineVersion's parent workspace:
 * * actions/enginemanager/daiEngineVersions/ASSIGN_ALIASES
 *
 * @generated from field: string name = 1;
 */
name: string;
/**
 * New resourceID aliases of the DAIEngineVersion.
 *
 * @generated from field: repeated string aliases = 2;
 */
aliases: string[];
}
;
/**
 * Response message.
 *
 * @generated from message ai.h2o.engine.v1.AssignDAIEngineVersionAliasesResponse
 */
export type AssignDAIEngineVersionAliasesResponse = {
/**
 * All DAIEngineVersions with updated resourceID aliases.
 *
 * @generated from field: repeated ai.h2o.engine.v1.DAIEngineVersion dai_engine_versions = 1;
 */
daiEngineVersions?: DAIEngineVersion[];
}
;
/**
 * Creates a new DAIEngineVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineVersionService.CreateDAIEngineVersion
 */
export const DAIEngineVersionService_CreateDAIEngineVersion = new RPC<CreateDAIEngineVersionRequest,CreateDAIEngineVersionResponse>("POST", "/v1/{parent=workspaces/*}/daiEngineVersions", "daiEngineVersion");
/**
 * Get DAIEngineVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineVersionService.GetDAIEngineVersion
 */
export const DAIEngineVersionService_GetDAIEngineVersion = new RPC<GetDAIEngineVersionRequest,GetDAIEngineVersionResponse>("GET", "/v1/{name=workspaces/*/daiEngineVersions/*}");
/**
 * Returns a collection of DAIEngineVersions.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineVersionService.ListDAIEngineVersions
 */
export const DAIEngineVersionService_ListDAIEngineVersions = new RPC<ListDAIEngineVersionsRequest,ListDAIEngineVersionsResponse>("GET", "/v1/{parent=workspaces/*}/daiEngineVersions");
/**
 * Updates a DAIEngineVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineVersionService.UpdateDAIEngineVersion
 */
export const DAIEngineVersionService_UpdateDAIEngineVersion = new RPC<UpdateDAIEngineVersionRequest,UpdateDAIEngineVersionResponse>("PATCH", "/v1/{daiEngineVersion.name=workspaces/*/daiEngineVersions/*}", "daiEngineVersion");
/**
 * Deletes a DAIEngineVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineVersionService.DeleteDAIEngineVersion
 */
export const DAIEngineVersionService_DeleteDAIEngineVersion = new RPC<DeleteDAIEngineVersionRequest,DeleteDAIEngineVersionResponse>("DELETE", "/v1/{name=workspaces/*/daiEngineVersions/*}");
/**
 * Assign new set of resourceID aliases to DAIEngineVersion.
 * This will replace existing DAIEngineVersion resourceID aliases with the new aliases.
 * If there are other DAIEngineVersions with the same alias that we try to assign to this DAIEngineVersion,
 * they will be deleted from the other DAIEngineVersions.
 *
 * Example 1 - two versions in the same workspace:
 * - daiw1v1(name="workspaces/w1/daiEngineVersions/v1", aliases=["latest", "bar"])
 * - daiw1v2(name="workspaces/w1/daiEngineVersions/v2", aliases=["baz", "foo"])
 * - AssignAliases(daiw1v1, aliases=["latest", "baz"])
 * => daiw1v1.aliases=["latest", "baz"] (changed)
 * => daiw1v2.aliases=["foo"] (changed)
 *
 * Example 2 - two versions in different workspaces:
 * - daiw1v1(name="workspaces/w1/daiEngineVersions/v1", aliases=["latest", "bar"])
 * - daiw2v1(name="workspaces/w2/daiEngineVersions/v1", aliases=["latest", "baz"])
 * - AssignAliases(daiw1v1, aliases=["latest", "baz"])
 * => daiw1v1.aliases=["latest", "baz"] (changed)
 * => daiw2v1.aliases=["latest", "baz"] (unchanged)
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineVersionService.AssignDAIEngineVersionAliases
 */
export const DAIEngineVersionService_AssignDAIEngineVersionAliases = new RPC<AssignDAIEngineVersionAliasesRequest,AssignDAIEngineVersionAliasesResponse>("POST", "/v1/{name=workspaces/*/daiEngineVersions/*}:assignAliases");
