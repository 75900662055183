import { IconNamesInput } from '@fluentui/font-icons-mdl2/lib/IconNames';
import { IContextualMenuItem, concatStyleSets } from '@fluentui/react';
import {
  Button,
  IButtonProps,
  IconName,
  buttonStylesSplit,
  buttonStylesSplitDanger,
  buttonStylesSplitGhost,
  buttonStylesSplitPrimary,
  buttonStylesSplitSecondary,
  compareVersionString,
  loaderStylesSpinnerButtonPrimary,
  loaderStylesSpinnerButtonSecondary,
  buttonStylesSplitSmall as uiKitButtonStylesSplitSmall,
  useTheme,
} from '@h2oai/ui-kit';

import { hasOp } from '../../../../aiem/engine/conditions';
import { useEngine } from '../../../../aiem/engine/hooks';
import { AIEMOpType, AIEngine, EngineState } from '../../../../aiem/engine/types';
import { isTransitionalState } from '../../../../aiem/engine/utils';
import { DAIEngine_State } from '../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_pb';
import { Engine_State, Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { AIEMUserActionType } from '../../../../aiem/userActions';
import {
  restrictionTooltipMessage,
  usePermissions,
} from '../../../../authz/providers/PermissionsProvider/PermissionsProvider';
import { useCloudPlatformDiscovery } from '../../../../utils/hooks';

export interface EngineActionButtonProps {
  isAdmin?: boolean;
  engine: AIEngine;
  viewEngine?: (engine: AIEngine) => void;
  editEngine?: (engine: AIEngine) => void;
  deleteEngine?: (engine: AIEngine) => void;
  pauseEngine?: (engine: AIEngine) => void;
  terminateEngine?: (engine: AIEngine) => void;
  resumeEngine?: (engine: AIEngine) => void;
  updateEngine?: (engine: AIEngine) => void;
  upgradeEngine?: (engine: AIEngine) => void;
  showResizeModal?: (engine: AIEngine) => void;
  migrateCreator?: (engine: AIEngine) => void;
  readLogs?: (engine: AIEngine) => void;
  openLegacyEngineLogs?: (engine: AIEngine) => void;
}

const canResume = (type: Engine_Type, state: EngineState) => hasOp(AIEMOpType.resume, type, state);

const buttonStylesSplitSmall = concatStyleSets(uiKitButtonStylesSplitSmall, { root: { width: 125 } });

const aiEngineControlActions = [
  AIEMUserActionType.DAI_ENGINE_GET,
  AIEMUserActionType.DAI_ENGINE_UPDATE,
  AIEMUserActionType.DAI_ENGINE_DELETE,
  AIEMUserActionType.DAI_ENGINE_PAUSE,
  AIEMUserActionType.DAI_ENGINE_RESUME,
  AIEMUserActionType.DAI_ENGINE_UPGRADE,
  AIEMUserActionType.DAI_ENGINE_RESIZE,
  AIEMUserActionType.DAI_ENGINE_MIGRATE_CREATOR,
  AIEMUserActionType.H2O_ENGINE_TERMINATE,
];

export function EngineActionButton(props: EngineActionButtonProps) {
  const [
    canView,
    canUpdate,
    canDelete,
    canPause,
    canResumeEngine,
    canUpgrade,
    canResize,
    canMigrateCreator,
    canTerminate,
  ] = usePermissions(aiEngineControlActions);
  const { palette } = useTheme(),
    { EngineStateMap } = useEngine(),
    platformDiscovery = useCloudPlatformDiscovery(),
    hasLoggingService = Boolean(platformDiscovery?.loggingServicesApiUrl),
    { engine, viewEngine, resumeEngine, pauseEngine, terminateEngine, upgradeEngine } = props,
    { engineType, state: enumState, deprecatedVersion: deprecated, deletedVersion } = engine,
    isDAIEngine = engineType === Engine_Type.DRIVERLESS_AI,
    isH2OEngine = engineType === Engine_Type.H2O,
    getMenuItems = ({
      isAdmin,
      engine,
      viewEngine,
      editEngine,
      deleteEngine,
      pauseEngine,
      upgradeEngine,
      showResizeModal,
      migrateCreator,
      readLogs,
      openLegacyEngineLogs,
    }: EngineActionButtonProps): IContextualMenuItem[] => {
      const hasMenuItem = (op: AIEMOpType) => hasOp(op, engineType!, enumState!),
        menuItems: IContextualMenuItem[] = [];
      const { upgradeAvailable } = engine;
      if (engineType === Engine_Type.UNSPECIFIED) return [];
      if (hasMenuItem(AIEMOpType.view)) {
        menuItems.push({
          key: 'view',
          text: 'View Details',
          onClick: () => (canView ? viewEngine!(engine) : undefined),
          disabled: !canView,
        });
      }
      if (hasMenuItem(AIEMOpType.edit)) {
        menuItems.push({
          key: 'edit',
          text: 'Edit',
          onClick: () => (canUpdate ? editEngine!(engine) : undefined),
          disabled: !canUpdate,
        });
      }
      if (hasMenuItem(AIEMOpType.pause) && enumState !== String(Engine_State.RUNNING)) {
        menuItems.push({
          key: 'pause',
          text: 'Pause',
          onClick: () => (canPause ? pauseEngine!(engine) : undefined),
          disabled: !canPause,
        });
      }
      if (
        isAdmin &&
        isDAIEngine &&
        enumState === String(Engine_State.PAUSED) &&
        compareVersionString(engine.name || '', '1.10.5') > -1
      ) {
        menuItems.push({
          key: 'migrateCreator',
          text: 'Migrate Creator',
          onClick: () => (canMigrateCreator ? migrateCreator!(engine) : undefined),
          disabled: !canMigrateCreator,
        });
      }
      if (hasLoggingService) {
        menuItems.push({
          key: 'readLogs',
          text: 'Logs',
          onClick: () => readLogs!(engine),
        });
      } else if (hasMenuItem(AIEMOpType.openLog)) {
        menuItems.push({
          key: 'legacyReadLogs',
          text: 'Logs',
          onClick: () => openLegacyEngineLogs!(engine),
        });
      }
      if (hasMenuItem(AIEMOpType.upgrade) && upgradeAvailable) {
        menuItems.push({
          key: 'upgrade',
          text: 'Upgrade',
          onClick: () => (canUpgrade ? upgradeEngine!(engine) : undefined),
          disabled: !canUpgrade,
        });
      }
      if (hasMenuItem(AIEMOpType.resize) && !engine.storageResizing) {
        menuItems.push({
          key: 'resize',
          text: 'Resize',
          onClick: () => (canResize ? showResizeModal!(engine) : undefined),
          disabled: !canResize,
        });
      }
      if (engine.deprecatedVersion && !engine.deletedVersion) {
        menuItems.push({
          key: 'resume',
          text: 'Resume',
          onClick: () => (canResumeEngine ? resumeEngine!(engine) : undefined),
          disabled: !canResumeEngine,
        });
      }
      if (hasMenuItem(AIEMOpType.delete)) {
        menuItems.push({
          key: 'delete',
          text: 'Delete',
          onClick: () => (canDelete ? deleteEngine!(engine) : undefined),
          disabled: !canDelete,
          style: { color: palette?.red500, ...(canDelete ? {} : { opacity: 0.5 }) },
        });
      }
      return menuItems;
    },
    starting = String(enumState) === Engine_State.STARTING,
    stateData = enumState ? EngineStateMap.get(enumState) : undefined,
    { title } = stateData || { title: '' },
    menuItems = getMenuItems(props),
    buttonProps: IButtonProps = {
      split: true,
      menuIconName: IconName.ChevronDown,
      menuItems,
    },
    pauseButton = (
      <Button
        {...buttonProps}
        styles={[buttonStylesSplitSecondary, buttonStylesSplitSmall]}
        onClick={canPause ? () => pauseEngine!(engine) : () => null}
        iconName={IconName.Pause}
        text="Pause"
        primaryDisabled={!canPause}
        tooltip={restrictionTooltipMessage(!canPause)}
      />
    ),
    terminateButton = (
      <Button
        {...buttonProps}
        styles={[buttonStylesSplit, buttonStylesSplitDanger, buttonStylesSplitSmall]}
        onClick={canTerminate ? () => terminateEngine!(engine) : () => null}
        iconName={'PowerButton' as IconNamesInput}
        text="Terminate"
        primaryDisabled={!canTerminate}
        tooltip={restrictionTooltipMessage(!canTerminate)}
      />
    ),
    resumeButton = (
      <Button
        {...buttonProps}
        styles={[buttonStylesSplit, buttonStylesSplitPrimary, buttonStylesSplitSmall]}
        onClick={canResumeEngine ? () => resumeEngine!(engine) : () => null}
        iconName={IconName.MSNVideos}
        text="Resume"
        primaryDisabled={!canResumeEngine}
        tooltip={restrictionTooltipMessage(!canResumeEngine)}
      />
    ),
    viewButton = (
      <Button
        {...buttonProps}
        menuItems={menuItems.filter((item) => item.key !== 'view')}
        styles={[buttonStylesSplit, buttonStylesSplitGhost, buttonStylesSplitSmall]}
        onClick={canView ? () => viewEngine!(engine) : () => null}
        iconName={'View' as IconNamesInput}
        text="View"
        primaryDisabled={!canView}
        tooltip={restrictionTooltipMessage(!canView)}
      />
    ),
    transitionalButton = (
      <Button
        menuItems={menuItems}
        split
        loaderWithMenuButton
        loading
        loaderProps={{
          label: title,
          styles: starting ? loaderStylesSpinnerButtonPrimary : loaderStylesSpinnerButtonSecondary,
        }}
        styles={[starting ? buttonStylesSplitPrimary : buttonStylesSplitSecondary, buttonStylesSplitSmall]}
        onClick={() => {}}
      />
    ),
    upgradeButton = (
      <Button
        {...buttonProps}
        onClick={canUpgrade ? () => upgradeEngine!(engine) : () => null}
        iconName={IconName.AlertSolid}
        text="Upgrade"
        primaryDisabled={!canUpgrade}
        tooltip={restrictionTooltipMessage(!canUpgrade)}
      />
    );

  switch (true) {
    case deletedVersion:
    case deprecated:
      return upgradeButton;

    case isTransitionalState(enumState!):
      return transitionalButton;

    case canResume(engineType!, enumState!):
      return resumeButton;

    case enumState === String(DAIEngine_State.RUNNING) && !isH2OEngine:
      return pauseButton;

    case enumState === String(DAIEngine_State.RUNNING) && isH2OEngine:
      return terminateButton;

    default:
      return viewButton;
  }
}
