import { type IButtonProps, Stack } from '@fluentui/react';
import {
  Accordion,
  Button,
  Checkbox,
  TextWithCopy,
  buttonStylesLink,
  buttonStylesLinkUnderline,
  buttonStylesSplit,
  buttonStylesSplitGhost,
  uniq,
  useMediaQuery,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useMemo, useState } from 'react';

import { App, AppInstance_Visibility } from '../../ai.h2o.cloud.appstore';
import { useInstance } from '../../utils/hooks';
import type { AppGroup, AppGroupExtended } from '../../utils/models';
import { appVisibilityIconMap, appVisibilityMap, formatDateWithTime } from '../../utils/utils';
import { ActionCellContents } from '../ListPages/ActionCell';
import { BadgeCellContents } from '../ListPages/BadgeCell';
import { textCellStyles } from '../ListPages/Cell';
import List from '../ListPages/List';
import { TitleCellContents, copyTextCellStyles } from '../ListPages/TitleCell';
import CellWrapper from './CellWrapper';
import { getIconCell, getTagCell, getVisibilityCell, isGroupExpanded } from './components/parts';

export type AppListProps = {
  apps: App[] | null;
  appGroups: AppGroup[] | null;
  isAdmin: boolean;
  deleteApp: (app: App) => () => void;
  downloadApp: (app: App) => () => void;
  editApp: (app: App) => () => void;
  runApp: (app: App, visibility?: AppInstance_Visibility, profile?: '') => () => Promise<void>;
  onRunWithProfiles?: (app: App) => void | undefined;
  toggleVisibilityDialog?: (app?: App) => () => void;
  selectedAppIds: string[];
  setSelectedAppIds: (appIds: string[]) => void;
  deletionPendingAppIds: string[];
  editPendingAppIds: string[];
};

export function AppList({
  apps,
  appGroups,
  deleteApp,
  downloadApp,
  editApp,
  runApp,
  selectedAppIds,
  setSelectedAppIds,
  deletionPendingAppIds,
  editPendingAppIds,
  isAdmin,
  onRunWithProfiles,
}: AppListProps) {
  const theme = useTheme(),
    { isDesktop } = useMediaQuery(),
    [expandedGroupKeys, setExpandedGroupKeys] = useState<string[]>([]),
    { goToAppDetail } = useInstance(),
    allAppsSelected = apps?.length === selectedAppIds.length,
    onClickSelectAll = useCallback(() => {
      if (apps?.every((app) => selectedAppIds.includes(app.id))) {
        setSelectedAppIds([]);
      } else {
        setSelectedAppIds((apps || []).map((app) => app.id));
      }
    }, [allAppsSelected, apps, selectedAppIds, setSelectedAppIds]),
    rows = useMemo(() => {
      return appGroups?.map(
        (group): AppGroupExtended => ({
          ...group,
          editing: group.data.apps.some((app: App) => editPendingAppIds.includes(app.id)),
          deleting: group.data.apps.some((app: App) => deletionPendingAppIds.includes(app.id)),
        })
      );
    }, [appGroups, deletionPendingAppIds, editPendingAppIds]),
    onRenderCheckboxCell = useCallback(
      (group: AppGroup) => {
        const checkboxStyles = { checkbox: { margin: 12 } },
          groupAppIds = group.data.apps.map((app) => app.id),
          allAppsSelected = groupAppIds.every((id) => selectedAppIds.includes(id));
        return (
          <CellWrapper
            expanded={isGroupExpanded(group, expandedGroupKeys)}
            group={group}
            GroupCell={
              <Checkbox
                checked={allAppsSelected}
                onChange={() => {
                  if (allAppsSelected) {
                    setSelectedAppIds(selectedAppIds.filter((id) => !groupAppIds.includes(id)));
                  } else {
                    setSelectedAppIds(uniq([...selectedAppIds, ...groupAppIds]));
                  }
                }}
                styles={checkboxStyles}
              />
            }
            onRenderAppCell={(app) => {
              const appSelected = selectedAppIds.includes(app.id);
              return (
                <Checkbox
                  onChange={() =>
                    setSelectedAppIds(
                      appSelected ? selectedAppIds.filter((id) => id !== app.id) : [...selectedAppIds, app.id]
                    )
                  }
                  checked={appSelected}
                  styles={checkboxStyles}
                />
              );
            }}
          />
        );
      },
      [expandedGroupKeys, selectedAppIds, setSelectedAppIds]
    ),
    onRenderIconCell = useCallback(
      (group: AppGroup) => {
        return getIconCell(group, expandedGroupKeys);
      },
      [expandedGroupKeys]
    ),
    onRenderTitleCell = useCallback(
      (group: AppGroup) => (
        <CellWrapper
          label="App ID"
          expanded={isGroupExpanded(group, expandedGroupKeys)}
          group={group}
          GroupCell={
            <TitleCellContents
              title={group.name}
              subtitle={group.data.latestApp.title}
              copyTitle
              copySubtitle={false}
            />
          }
          onRenderAppCell={(app) => (
            <span key={app.id} style={textCellStyles} data-test={`${app.name}_${app.version}`}>
              <TextWithCopy text={app.id} styles={copyTextCellStyles} />
            </span>
          )}
        />
      ),
      [expandedGroupKeys]
    ),
    onRenderVersionCell = useCallback(
      (group: AppGroup) => {
        const text = `${group.count} version${group.count > 1 ? 's' : ''}`;
        const expanded = isGroupExpanded(group, expandedGroupKeys);
        return (
          <CellWrapper
            label="Versions"
            expanded={expanded}
            group={group}
            GroupCell={
              <Button
                styles={[buttonStylesLink, buttonStylesLinkUnderline]}
                text={text}
                onClick={() =>
                  setExpandedGroupKeys(
                    expanded ? expandedGroupKeys.filter((key) => key !== group.key) : [...expandedGroupKeys, group.key]
                  )
                }
              />
            }
            onRenderAppCell={(app) => <span>{app.version}</span>}
          />
        );
      },
      [expandedGroupKeys, setExpandedGroupKeys]
    ),
    onRenderOwnerCell = useCallback(
      (group: AppGroup) => (
        <CellWrapper
          label="Owner"
          expanded={isGroupExpanded(group, expandedGroupKeys)}
          group={group}
          GroupCell={<span style={textCellStyles}>{group.data.latestApp.owner}</span>}
          onRenderAppCell={(app) => <span style={textCellStyles}>{app.owner}</span>}
        />
      ),
      [expandedGroupKeys]
    ),
    onRenderTagCell = useCallback(
      (group: AppGroup, _, { currentWidth }) => getTagCell(group, expandedGroupKeys, currentWidth, theme, isAdmin),
      [expandedGroupKeys, isAdmin]
    ),
    onRenderVisibilityCell = useCallback(
      (group: AppGroup) => getVisibilityCell(group, expandedGroupKeys),
      [theme, expandedGroupKeys]
    ),
    onRenderDetailsCell = useCallback(
      (group: AppGroup) => {
        const expanded = isGroupExpanded(group, expandedGroupKeys);
        const {
          data: { latestApp },
        } = group;
        const cellContent = (
          <Stack tokens={{ childrenGap: 8 }}>
            <div>Created {formatDateWithTime(new Date(latestApp.createTime))}</div>
            <div>Updated {formatDateWithTime(new Date(latestApp.updateTime))}</div>
          </Stack>
        );

        return (
          <CellWrapper
            label="Details"
            expanded={expanded}
            group={group}
            GroupCell={cellContent}
            onRenderAppCell={() => cellContent}
          />
        );
      },
      [theme, expandedGroupKeys]
    ),
    onRenderActionsCell = useCallback(
      (group: AppGroup) => {
        const expanded = isGroupExpanded(group, expandedGroupKeys);
        return (
          <CellWrapper
            alignRight
            rootStyles={{ marginRight: 16 }}
            expanded={expanded}
            group={group}
            GroupCell={
              <ActionCellContents
                primaryButtonProps={
                  {
                    onClick: goToAppDetail(group.data.latestApp.id),
                    'data-test': `${group.data.latestApp.id}--view-button`,
                    text: 'View',
                  } as IButtonProps
                }
                expandButtonProps={
                  {
                    onClick: () =>
                      setExpandedGroupKeys(
                        expanded
                          ? expandedGroupKeys.filter((key) => key !== group.key)
                          : [...expandedGroupKeys, group.key]
                      ),
                    'data-test': `${group.data.latestApp.id}--expand-button`,
                  } as IButtonProps
                }
                expanded={expanded}
              />
            }
            onRenderAppCell={(app) => (
              <Button
                split
                styles={[buttonStylesSplit, buttonStylesSplitGhost, { root: { width: 80 } }]}
                onClick={goToAppDetail(app.id)}
                primaryActionButtonProps={
                  {
                    'data-test': `${app.id}--view-button`,
                  } as IButtonProps
                }
                splitButtonMenuProps={
                  {
                    'data-test': `${app.id}--more-button`,
                  } as IButtonProps
                }
                text="View"
                menuItems={[
                  {
                    key: 'edit-details',
                    text: 'Edit',
                    'data-test': `${app.id}--edit-button`,
                    onClick: editApp ? editApp(app) : undefined,
                  },
                  {
                    key: 'run-app',
                    text: 'Run',
                    'data-test': `${app.id}--run-button`,
                    onClick: runApp ? runApp(app) : undefined,
                  },
                  {
                    key: 'run-private-app',
                    text: 'Run in private',
                    'data-test': `${app.id}--run-private-button`,
                    onClick: runApp ? runApp(app, AppInstance_Visibility.PRIVATE) : undefined,
                  },
                  ...(onRunWithProfiles
                    ? [
                        {
                          key: 'run-with-profile',
                          text: 'Run with Profile',
                          'data-test': `${app.id}--run-with-profile-button`,
                          onClick: () => onRunWithProfiles!(app),
                        },
                      ]
                    : []),
                  {
                    key: 'download-app',
                    text: 'Download app',
                    'data-test': `${app.id}--download-button`,
                    onClick: downloadApp ? downloadApp(app) : undefined,
                  },
                  {
                    key: 'delete-app',
                    text: 'Delete app',
                    'data-test': `${app.id}--delete-button`,
                    onClick: deleteApp ? deleteApp(app) : undefined,
                    style: { color: theme.semanticColors?.buttonDanger },
                  },
                ]}
              />
            )}
          />
        );
      },
      [theme, editApp, deleteApp, expandedGroupKeys, setExpandedGroupKeys]
    ),
    columns = useMemo(
      () => [
        {
          key: 'checkbox',
          name: '',
          fieldName: 'checkbox',
          minWidth: 40,
          maxWidth: 40,
          onRender: onRenderCheckboxCell,
        },
        {
          key: 'icon',
          name: '',
          fieldName: 'icon',
          minWidth: 40,
          maxWidth: 40,
          onRender: onRenderIconCell,
        },
        {
          key: 'app-header',
          name: '',
          fieldName: 'name',
          minWidth: 220,
          maxWidth: 480,
          onRender: onRenderTitleCell,
        },
        {
          key: 'version-count',
          name: '',
          fieldName: 'count',
          minWidth: 100,
          maxWidth: 100,
          onRender: onRenderVersionCell,
        },
        ...(isAdmin
          ? [
              {
                key: 'owner',
                name: '',
                fieldName: 'owner',
                minWidth: 140,
                maxWidth: 140,
                onRender: onRenderOwnerCell,
              },
            ]
          : []),
        {
          key: 'tags',
          name: 'Tags',
          fieldName: 'tags',
          minWidth: 200,
          maxWidth: 200,
          onRender: onRenderTagCell,
        },
        {
          key: 'visibility',
          name: 'Visibility',
          fieldName: 'visibility',
          minWidth: 100,
          maxWidth: 100,
          onRender: onRenderVisibilityCell,
        },
        {
          key: 'details',
          name: 'Details',
          fieldName: 'createTime',
          minWidth: 240,
          maxWidth: 250,
          onRender: onRenderDetailsCell,
        },
        {
          key: 'actions',
          name: '',
          fieldName: 'actions',
          minWidth: 132,
          maxWidth: 132,
          onRender: onRenderActionsCell,
        },
      ],
      [
        onRenderCheckboxCell,
        onRenderIconCell,
        onRenderTitleCell,
        onRenderVersionCell,
        onRenderOwnerCell,
        onRenderTagCell,
        onRenderVisibilityCell,
        onRenderActionsCell,
        isAdmin,
      ]
    ),
    renderAccordionRowData = (app: App | null, appGroup: AppGroupExtended) => {
      const id = app ? app.id : appGroup.key;
      const tagCellWidth = app ? 300 : 200;
      const version = app ? `${app.version} v` : `${appGroup.count} version${appGroup.count > 1 ? 's' : ''}`;

      return (
        <div key={id} className="instance-accordion-row">
          <div className="instance-accordion-row-head">
            {!app && <div>{getIconCell(appGroup, expandedGroupKeys)}</div>}
            <div className="instance-accordion-row-title">
              <strong>{id}</strong>
              <div className="instance-accordion-row-info">
                <div>
                  <span>{version}</span>
                  <span>{getTagCell(appGroup, expandedGroupKeys, tagCellWidth, theme, isAdmin, true)}</span>
                </div>
                {app && (
                  <div className="instance-accordion-row-visibility">
                    <BadgeCellContents
                      badgeLabel={appVisibilityMap?.[app.visibility]}
                      badgeIconName={appVisibilityIconMap?.[app.visibility]}
                      dataTest="visibility-badge"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {app && (
            <div className="instance-accordion-row-actions">
              {/* Hard fix for the known issue with the dropdown button on mobile-view */}
              {/* https://github.com/microsoft/fluentui/issues/14303 */}
              {!isDesktop && (
                <Button onClick={goToAppDetail(id)} className="mobile-view-action">
                  View
                </Button>
              )}
              <Button
                split
                styles={[buttonStylesSplit, buttonStylesSplitGhost, { root: { width: 80 } }]}
                onClick={goToAppDetail(id)}
                primaryActionButtonProps={
                  {
                    'data-test': `${id}--view-button`,
                  } as IButtonProps
                }
                splitButtonMenuProps={
                  {
                    'data-test': `${id}--more-button`,
                  } as IButtonProps
                }
                text="View"
                menuItems={[
                  {
                    key: 'edit-details',
                    text: 'Edit',
                    'data-test': `${id}--edit-button`,
                    onClick: editApp ? editApp(app) : undefined,
                  },
                  {
                    key: 'run-app',
                    text: 'Run',
                    'data-test': `${id}--run-button`,
                    onClick: runApp ? runApp(app) : undefined,
                  },
                  {
                    key: 'run-private-app',
                    text: 'Run in private',
                    'data-test': `${id}--run-private-button`,
                    onClick: runApp ? runApp(app, AppInstance_Visibility.PRIVATE) : undefined,
                  },
                  ...(onRunWithProfiles
                    ? [
                        {
                          key: 'run-with-profile',
                          text: 'Run with Profile',
                          'data-test': `${id}--run-with-profile-button`,
                          onClick: () => onRunWithProfiles!(app),
                        },
                      ]
                    : []),
                  {
                    key: 'download-app',
                    text: 'Download app',
                    'data-test': `${id}--download-button`,
                    onClick: downloadApp ? downloadApp(app) : undefined,
                  },
                  {
                    key: 'delete-app',
                    text: 'Delete app',
                    'data-test': `${id}--delete-button`,
                    onClick: deleteApp ? deleteApp(app) : undefined,
                    style: { color: theme.semanticColors?.buttonDanger },
                  },
                ]}
              />
            </div>
          )}
        </div>
      );
    };

  return isDesktop ? (
    <List
      dataTest="app-list"
      items={rows || []}
      columns={columns}
      onClickSelectAll={onClickSelectAll}
      selectAllButtonChecked={apps?.every((app) => selectedAppIds.includes(app.id))}
      showSelectAllButton
    />
  ) : (
    <>
      {rows?.map((row) => (
        <Stack key={row.key}>
          <Accordion headerRenderer={() => renderAccordionRowData(null, row)} isClose>
            {row.data.apps?.map((app) => renderAccordionRowData(app, row))}
          </Accordion>
        </Stack>
      ))}
    </>
  );
}
