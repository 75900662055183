// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/mlops/deployer/v1/kubernetes_resource_spec_service.proto (package ai.h2o.deploy, syntax proto3)
/* eslint-disable */

import type { KubernetesResourceSpec } from "./kubernetes_resource_spec_pb";
import { RPC } from "../../../../../runtime";

/**
 * @generated from message ai.h2o.deploy.GetDefaultRuntimeKubernetesResourceSpecRequest
 */
export type GetDefaultRuntimeKubernetesResourceSpecRequest = {
}
;
/**
 * @generated from message ai.h2o.deploy.GetDefaultRuntimeKubernetesResourceSpecResponse
 */
export type GetDefaultRuntimeKubernetesResourceSpecResponse = {
/**
 * Default runtime K8s resource spec.
 *
 * @generated from field: ai.h2o.deploy.KubernetesResourceSpec default_runtime_kubernetes_resource_spec = 1;
 */
defaultRuntimeKubernetesResourceSpec?: KubernetesResourceSpec;
}
;
/**
 * Gets default runtime K8s resource spec.
 *
 * @generated from rpc ai.h2o.deploy.KubernetesResourceSpecService.GetDefaultRuntimeKubernetesResourceSpec
 */
export const KubernetesResourceSpecService_GetDefaultRuntimeKubernetesResourceSpec = new RPC<GetDefaultRuntimeKubernetesResourceSpecRequest,GetDefaultRuntimeKubernetesResourceSpecResponse>("POST", "/ai.h2o.deploy.KubernetesResourceSpecService/GetDefaultRuntimeKubernetesResourceSpec");
