import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import React, { useMemo } from 'react';

import { useUser } from '../../utils/hooks';
import { arrayToObject, formatError } from '../../utils/utils';
import { ListGroupsResponse } from '../gen/ai/h2o/user/v1/group_api_pb';
import { Group } from '../gen/ai/h2o/user/v1/group_pb';
import { ListUsersResponse } from '../gen/ai/h2o/user/v1/user_api_pb';
import { User } from '../gen/ai/h2o/user/v1/user_pb';
import { useAuthzService } from '../hooks';

type UsersContextType = {
  users?: User[];
  groups?: Group[];
  usersAndGroups?: (User | Group)[];
  listUsers: (searchFilter: string) => Promise<User[] | undefined>;
  listGroups: (searchFilter: string) => Promise<Group[] | undefined>;
  CURRENT_USER_NAME?: string;
  usersNameMap?: { [key: string]: User };
  groupsNameMap?: { [key: string]: Group };
  usersAndGroupsNameMap?: { [key: string]: Group | User };
};

const UsersContext = React.createContext<UsersContextType | undefined>(undefined);

const UsersProvider = ({ children }: { children: React.ReactNode }) => {
  const authzService = useAuthzService(),
    { addToast } = useToast(),
    user = useUser(),
    CURRENT_USER_NAME = `users/${user.id}`,
    [users, setUsers] = React.useState<User[] | undefined>(),
    [groups, setGroups] = React.useState<Group[] | undefined>(),
    [usersNameMap, setUsersNameMap] = React.useState<{ [key: string]: User } | undefined>(),
    [groupsNameMap, setGroupsNameMap] = React.useState<{ [key: string]: Group } | undefined>(),
    usersAndGroupsNameMap = useMemo(() => {
      return { ...usersNameMap, ...groupsNameMap };
    }, [usersNameMap, groupsNameMap]),
    // TODO: Handle loading state.
    [, setLoading] = React.useState(true),
    fetchUsers = React.useCallback(async () => {
      setLoading(true);
      try {
        let userItems: User[] = [],
          morePagesAvailable = true,
          nextPageToken;

        while (morePagesAvailable) {
          // TODO: Prevent performance issues when lot of users - fetch only users with role bindings to current workspace.
          const data: ListUsersResponse = await authzService.getUsers({ pageToken: nextPageToken });
          userItems = [...userItems, ...(data?.users || [])];
          morePagesAvailable = !!data.nextPageToken;
          nextPageToken = data?.nextPageToken;
        }

        if (!userItems) console.error('No users found in the response.');
        setUsers(userItems);
      } catch (err) {
        const message = `Failed to fetch users: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setUsers(undefined);
      } finally {
        setLoading(false);
      }
    }, [authzService, addToast, setUsers, setLoading]),
    fetchGroups = React.useCallback(async () => {
      setLoading(true);
      try {
        let groupItems: Group[] = [],
          morePagesAvailable = true,
          nextPageToken;

        while (morePagesAvailable) {
          // TODO: Prevent performance issues when lot of users - fetch only users with role bindings to current workspace.
          const data: ListGroupsResponse = await authzService.getGroups({ pageToken: nextPageToken });
          groupItems = [...groupItems, ...(data?.groups || [])];
          morePagesAvailable = !!data.nextPageToken;
          nextPageToken = data?.nextPageToken;
        }

        if (!groupItems) console.error('No groups found in the response.');
        setGroups(groupItems);
      } catch (err) {
        const message = `Failed to fetch groups: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setGroups(undefined);
      } finally {
        setLoading(false);
      }
    }, [authzService, addToast, setGroups, setLoading]),
    listUsers = async (searchFilter: string) => {
      try {
        const data = await authzService.getUsers({ filter: `'${searchFilter}'` });
        const userItems: User[] | undefined = data?.users;
        if (data && !data?.users) console.error('No users found in the response.');
        return userItems;
      } catch (err) {
        const message = `Failed to fetch users: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        return undefined;
      }
    },
    listGroups = async (searchFilter: string) => {
      try {
        const data = await authzService.getGroups({ filter: `'${searchFilter}'` });
        const groupItems: User[] | undefined = data?.groups;
        if (data && !data?.groups) console.error('No groups found in the response.');
        return groupItems;
      } catch (err) {
        const message = `Failed to fetch groups: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        return undefined;
      }
    };

  React.useEffect(() => {
    setUsersNameMap(arrayToObject(users || [], 'name'));
    setGroupsNameMap(arrayToObject(groups || [], 'name'));
  }, [users, groups]);

  React.useEffect(() => {
    void fetchUsers();
    void fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    users,
    groups,
    usersAndGroups: [...(users || []), ...(groups || [])],
    listUsers,
    listGroups,
    CURRENT_USER_NAME,
    usersNameMap,
    groupsNameMap,
    usersAndGroupsNameMap,
  };

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

const useUsers = () => {
  const context = React.useContext(UsersContext);
  if (!context) {
    throw new Error('useUsers must be used within a UserProvider');
  }
  return context;
};

export { UsersProvider, useUsers };
