import { IH2OTheme, Sizes } from '@h2oai/ui-kit';

export const mlopsTopStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: '50px',
};

export const mlopsSummaryTopStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '50px',
};

export const menuItemStyle = {
  display: 'flex',
  marginRight: '20px',
  width: '30%',
};

export const comboBoxStyle = {
  marginRight: '20px',
};

export const startDatePickerStyle = {
  marginRight: '10px',
  marginLeft: '10px',
  width: '170px',
};

export const endDatePickerStyle = {
  marginRight: '10px',
  width: '170px',
};

export const summaryDatePickerAreaStyle = {
  marginRight: '20px',
};

export const summaryDatePickerStyle = {
  marginRight: '50px',
  width: '170px',
};

export const GaugeMetricTableStyle = (theme: IH2OTheme) => ({
  heigt: '50px',
  backgroundColor: theme.palette?.white,
  padding: Sizes.padding,
  overflowX: 'auto' as 'auto' | 'hidden' | 'scroll' | 'visible',
  overflowY: 'auto' as 'auto' | 'hidden' | 'scroll' | 'visible',
});
