import { fetchWrapRPC } from '../services/api';
import {
  DAIEngineProfileService_GetDAIEngineProfile,
  DAIEngineProfileService_ListAssignedDAIEngineProfiles,
  GetDAIEngineProfileRequest,
  ListAssignedDAIEngineProfilesRequest,
} from './gen/ai/h2o/engine/v1/dai_engine_profile_service_pb';
import {
  H2OEngineProfileService_ListAssignedH2OEngineProfiles,
  ListAssignedH2OEngineProfilesRequest,
} from './gen/ai/h2o/engine/v1/h2o_engine_profile_service_pb';
import {
  ListNotebookEngineProfilesRequest,
  NotebookEngineProfileService_ListNotebookEngineProfiles,
} from './gen/ai/h2o/engine/v1/notebook_engine_profile_service_pb';

export class EngineProfilesServiceImpl {
  private basePath: string;
  constructor(basePath?: string) {
    this.basePath = basePath || '';
  }

  public setBasePath = (path: string) => {
    this.basePath = path;
  };

  // DAI Engine
  public async getDAIEngineProfile(req: GetDAIEngineProfileRequest) {
    return await fetchWrapRPC(DAIEngineProfileService_GetDAIEngineProfile, { basePath: this.basePath })({ ...req });
  }

  public async listAssignedDAIEngineProfiles(req: ListAssignedDAIEngineProfilesRequest) {
    return await fetchWrapRPC(DAIEngineProfileService_ListAssignedDAIEngineProfiles, { basePath: this.basePath })({
      ...req,
    });
  }

  // Notebook Engine
  public async listNotebookEngineProfiles(req: ListNotebookEngineProfilesRequest) {
    return await fetchWrapRPC(NotebookEngineProfileService_ListNotebookEngineProfiles, { basePath: this.basePath })({
      ...req,
    });
  }

  // H2O Engine
  public async listAssignedH2OEngineProfiles(req: ListAssignedH2OEngineProfilesRequest) {
    return await fetchWrapRPC(H2OEngineProfileService_ListAssignedH2OEngineProfiles, { basePath: this.basePath })({
      ...req,
    });
  }
}
