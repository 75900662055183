import { Stack, Toggle } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Dropdown } from '@h2oai/ui-kit';
import { useState } from 'react';

import { TimeUnit, maxSeconds, timeUnitInfoMap } from '../../../aiem/types';
import { getBestDateTimeUnitInfoPair, parseNumberAndTimeUnitToSeconds } from '../../../aiem/utils';
import SpinnerWithTooltip from '../../../components/AIEnginesPage/components/SpinnerWithTooltip/SpinnerWithTooltip';
import { EntityFieldInputProps } from './BasicEntityModelComponents';
import { defaultEntityFormRowStyles, defaultEntityLabelColumnWidth } from './DefaultEntityFormRowStyles';
import { LabelAndDescription } from './LabelAndDescription';

export function DurationModelField<EntityModel>({
  field,
  model,
  onChange: onChangeModel,
  compact,
}: EntityFieldInputProps<EntityModel>) {
  const { label, name, description, disabled } = field,
    value = (model as any)[name];

  const [numberProp, unitProp] = getBestDateTimeUnitInfoPair(value);
  const [number, setNumber] = useState<number>(numberProp);
  const defaultUnit = TimeUnit.Second,
    [unitKey, setUnitKey] = useState<TimeUnit>(unitProp?.type || defaultUnit);
  const [enabled, { toggle }] = useBoolean(Boolean(number));

  const options = Object.keys(timeUnitInfoMap).map((key) => ({
    key,
    text: timeUnitInfoMap[key].text,
  }));

  return (
    <Stack horizontal={!compact} styles={{ root: defaultEntityFormRowStyles }} tokens={{ childrenGap: 15 }}>
      <LabelAndDescription
        label={label}
        description={description}
        rootStyles={{ minWidth: compact ? 0 : defaultEntityLabelColumnWidth }}
      />
      <Stack horizontal tokens={{ childrenGap: 17 }}>
        <Stack styles={{ root: { paddingTop: 10 } }}>
          <Toggle
            disabled={disabled}
            checked={enabled}
            onChange={
              disabled
                ? () => null
                : (_, _checked) => {
                    toggle();
                    if (!_checked) {
                      setNumber(0);
                      setUnitKey(defaultUnit);
                      onChangeModel && onChangeModel(name, undefined);
                    }
                  }
            }
          />
        </Stack>
        <SpinnerWithTooltip
          onChange={
            disabled
              ? () => null
              : (_: any, value: number) => {
                  setNumber(value);
                  onChangeModel && onChangeModel(name, parseNumberAndTimeUnitToSeconds(value, unitKey!));
                }
          }
          value={number}
          min={0}
          max={maxSeconds}
          disabled={!enabled}
        />
        <Dropdown
          options={options}
          selectedKey={unitKey}
          width={200}
          onChange={
            disabled
              ? () => null
              : (_ev, v) => {
                  const value = v?.key as TimeUnit;
                  setUnitKey(value);
                  onChangeModel && onChangeModel(name, parseNumberAndTimeUnitToSeconds(number, value));
                }
          }
          disabled={!enabled}
        />
      </Stack>
    </Stack>
  );
}
