// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/mlops/batch/v1/job_service.proto (package ai.h2o.mlops.batch.v1, syntax proto3)
/* eslint-disable */

import type { Job } from "./job_pb";
import { RPC } from "../../../../../runtime";

/**
 * Request message for CreateJob
 *
 * @generated from message ai.h2o.mlops.batch.v1.CreateJobRequest
 */
export type CreateJobRequest = {
/**
 * Required. The resource name of the workspace to create Job in.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The ID to use for the job
 *
 * @generated from field: string job_id = 2;
 */
jobId?: string;
/**
 * Required. The Job resource to create.
 *
 * @generated from field: ai.h2o.mlops.batch.v1.Job job = 3;
 */
job: Job;
}
;
/**
 * Response message for CreateJob
 *
 * @generated from message ai.h2o.mlops.batch.v1.CreateJobResponse
 */
export type CreateJobResponse = {
/**
 * The created Job.
 *
 * @generated from field: ai.h2o.mlops.batch.v1.Job job = 1;
 */
job?: Job;
}
;
/**
 * Request message for GetJob
 *
 * @generated from message ai.h2o.mlops.batch.v1.GetJobRequest
 */
export type GetJobRequest = {
/**
 * Required. Job resource name.
 * Format: workspaces/{workspace}/jobs/{job}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetJob
 *
 * @generated from message ai.h2o.mlops.batch.v1.GetJobResponse
 */
export type GetJobResponse = {
/**
 * The requested job.
 *
 * @generated from field: ai.h2o.mlops.batch.v1.Job job = 1;
 */
job?: Job;
}
;
/**
 * Request message for CancelJob. It will stop processing job and kill all resources, but job will be available to get.
 *
 * @generated from message ai.h2o.mlops.batch.v1.CancelJobRequest
 */
export type CancelJobRequest = {
/**
 * Required. Job resource name.
 * Format: workspaces/{workspace}/jobs/{job}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for CancelJob
 *
 * @generated from message ai.h2o.mlops.batch.v1.CancelJobResponse
 */
export type CancelJobResponse = {
/**
 * The requested job.
 *
 * @generated from field: ai.h2o.mlops.batch.v1.Job job = 1;
 */
job?: Job;
}
;
/**
 * Request message for GetJobOutput
 *
 * @generated from message ai.h2o.mlops.batch.v1.GetJobOutputRequest
 */
export type GetJobOutputRequest = {
/**
 * Required. Job resource name.
 * Format: workspaces/{workspace}/jobs/{job}
 *
 * @generated from field: string name = 1;
 */
name: string;
/**
 * @generated from field: google.protobuf.Timestamp since_time = 2;
 */
sinceTime?: string | null;
}
;
/**
 * Response message for GetJobOutput.
 *
 * @generated from message ai.h2o.mlops.batch.v1.JobOutputResponse
 */
export type JobOutputResponse = {
/**
 * @generated from field: string app_type = 1;
 */
appType?: string;
/**
 * @generated from field: string pod_name = 2;
 */
podName?: string;
/**
 * @generated from field: string container_name = 3;
 */
containerName?: string;
/**
 * @generated from field: google.protobuf.Timestamp timestamp = 4;
 */
timestamp?: string | null;
/**
 * @generated from field: string line = 5;
 */
line?: string;
/**
 * @generated from field: string error = 6;
 */
error?: string;
}
;
/**
 * Request message for ListJobs
 *
 * @generated from message ai.h2o.mlops.batch.v1.ListJobsRequest
 */
export type ListJobsRequest = {
/**
 * Required. The resource name of the workspace to list runnables from.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return less items then specified.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * When used, server will only return job that match the filter.
 * Attempts to generally implement AIP-160 (https://aip.dev/160)
 * specification.
 *
 * Supported fields:
 *   * state
 *   * display_name
 *   * create_time
 *   * start_time
 *   * end_time
 *   * creator
 *
 * Examples:
 * display_name = 'Some display' OR create_time <= 2023-12-30T23:59:43Z
 * create_time >= 2023-12-30T23:59:43Z AND create_time <= 2024-03-20T08:00:00Z
 * NOT(display_name = 'Some display name' OR create_time <= 2023-12-30T23:59:43Z)
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
/**
 * When used, server will return jobs ordered by the specified fields.
 * Attempts to implement AIP-132 (https://aip.dev/132#ordering).
 * Supported fields are the ones supported by filter.
 *
 * @generated from field: string order_by = 5;
 */
orderBy?: string;
}
;
/**
 * Response message for ListJobs
 *
 * @generated from message ai.h2o.mlops.batch.v1.ListJobsResponse
 */
export type ListJobsResponse = {
/**
 * Collection of jobs.
 *
 * @generated from field: repeated ai.h2o.mlops.batch.v1.Job jobs = 1;
 */
jobs?: Job[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for DeleteJob
 * (-- api-linter: core::0135::force-field=disabled
 *     aip.dev/not-precedent: Executions will be always deleted, there is no point to move that on API level. --)
 *
 * @generated from message ai.h2o.mlops.batch.v1.DeleteJobRequest
 */
export type DeleteJobRequest = {
/**
 * Required. Job resource name.
 * Format: workspaces/{workspace}/jobs/{job}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteJob
 *
 * @generated from message ai.h2o.mlops.batch.v1.DeleteJobResponse
 */
export type DeleteJobResponse = {
}
;
/**
 * Create a Job
 *
 * @generated from rpc ai.h2o.mlops.batch.v1.JobService.CreateJob
 */
export const JobService_CreateJob = new RPC<CreateJobRequest,CreateJobResponse>("POST", "/v1/{parent=workspaces/*}/jobs", "job");
/**
 * Deletes a Job.
 *
 * @generated from rpc ai.h2o.mlops.batch.v1.JobService.DeleteJob
 */
export const JobService_DeleteJob = new RPC<DeleteJobRequest,DeleteJobResponse>("DELETE", "/v1/{name=workspaces/*/jobs/*}");
/**
 * Returns a specific Job.
 *
 * @generated from rpc ai.h2o.mlops.batch.v1.JobService.GetJob
 */
export const JobService_GetJob = new RPC<GetJobRequest,GetJobResponse>("GET", "/v1/{name=workspaces/*/jobs/*}");
/**
 * Cancel a running Job.
 *
 * @generated from rpc ai.h2o.mlops.batch.v1.JobService.CancelJob
 */
export const JobService_CancelJob = new RPC<CancelJobRequest,CancelJobResponse>("GET", "/v1/{name=workspaces/*/jobs/*}:cancel");
/**
 * Retrieve logs from Job.
 *
 * @generated from rpc ai.h2o.mlops.batch.v1.JobService.GetJobOutput
 */
export const JobService_GetJobOutput = new RPC<GetJobOutputRequest,JobOutputResponse>("GET", "/v1/{name=workspaces/*/jobs/*}:output");
/**
 * Returns a collection of JobExecution within a workspace.
 *
 * @generated from rpc ai.h2o.mlops.batch.v1.JobService.ListJobs
 */
export const JobService_ListJobs = new RPC<ListJobsRequest,ListJobsResponse>("GET", "/v1/{parent=workspaces/*}/jobs");
