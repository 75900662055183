import { Stack } from '@fluentui/react';
import { BasicCard, CardList, ICardListProps, Sizes, TooltipHost, useTheme } from '@h2oai/ui-kit';
import { useCallback, useState } from 'react';

import { useEngine } from '../../../../aiem/engine/hooks';
import { ValidEngineType } from '../../../../aiem/engine/types';
import { Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { restrictionTooltipMessage } from '../../../../authz/providers/PermissionsProvider/PermissionsProvider';

interface EngineTypeCard {
  id: string;
  title: string;
  description: string;
  imagePath: string;
  engineType: Engine_Type;
  disabled?: boolean;
}

export interface IEngineTypeSelectionProps {
  onEngineSelection: (engineType: Engine_Type) => void;
  engineType?: ValidEngineType;
  canCreateDAI?: boolean;
  canCreateNotebook?: boolean;
  canCreateH2O?: boolean;
}

export function EngineSelection(props: IEngineTypeSelectionProps) {
  const { onEngineSelection, engineType, canCreateDAI, canCreateNotebook, canCreateH2O } = props;
  const { engineTypeLogo } = useEngine();
  const { palette } = useTheme();
  const cards: EngineTypeCard[] = [
    {
      id: Engine_Type.DRIVERLESS_AI as string,
      title: 'Driverless AI',
      description:
        'Delivers industry leading autoML capabilities specifically designed to use AI to make AI, with automation ' +
        'encompassing data science best practices across key functional areas like data visualization, feature engineering, ' +
        'model development and validation, model documentation, machine learning interpretability and more.',
      imagePath: engineTypeLogo[Engine_Type.DRIVERLESS_AI].src,
      engineType: Engine_Type.DRIVERLESS_AI,
      disabled: !canCreateDAI,
    },
    {
      id: Engine_Type.NOTEBOOK as string,
      title: 'Notebook',
      description: `A notebook authoring application and editing environment integrated with H2O products, including popular frameworks for machine learning, data science, and visualization.`,
      imagePath: engineTypeLogo[Engine_Type.NOTEBOOK].src,
      engineType: Engine_Type.NOTEBOOK,
      disabled: !canCreateNotebook,
    },
    {
      id: Engine_Type.H2O as string,
      title: 'H2O',
      description:
        'H2O-3 is an open source, in-memory, distributed, fast, and scalable machine learning and predictive analytics ' +
        'platform that allows you to build machine learning models on big data and provides easy productionalization of ' +
        'those models in an enterprise environment.',
      imagePath: engineTypeLogo[Engine_Type.H2O].src,
      engineType: Engine_Type.H2O,
      disabled: !canCreateH2O,
    },
  ];
  const [selectedId, setSelectedId] = useState<string>(String(engineType));
  const selectEngine = useCallback((card: EngineTypeCard) => {
    setSelectedId(card.id);
    onEngineSelection(card.engineType);
  }, []);

  const getItemRenderer = (item: EngineTypeCard) => (
    <BasicCard<EngineTypeCard>
      data={item}
      data-test={`basic-card-${item.id}`}
      idField="id"
      titleField="title"
      imagePathField="imagePath"
      descriptionField="description"
      selected={item.id === selectedId}
      onClick={item.disabled ? () => null : selectEngine}
      height={'auto'}
      styles={{
        thumbnail: { backgroundColor: engineTypeLogo[item.id].backgroundColor || 'none', backgroundSize: 'contain' },
        root: {
          backgroundColor: palette?.white,
          ...(item.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}),
          '&:hover': { backgroundColor: palette?.gray200 },
        },
      }}
    />
  );

  const listConfig: ICardListProps<EngineTypeCard> = {
    columnCount: 1,
    idField: 'id',
    nameField: 'title',
    data: cards,
    gap: Sizes.padding,
    itemRenderer: (item: EngineTypeCard) => {
      if (item.disabled) {
        return (
          <TooltipHost content={<Stack style={{ padding: '0px 8px' }}>{restrictionTooltipMessage(true)}</Stack>}>
            {getItemRenderer(item)}
          </TooltipHost>
        );
      } else {
        return getItemRenderer(item);
      }
    },
  };
  return <CardList {...listConfig} />;
}
