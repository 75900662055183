import { IColumn } from '@fluentui/react';

import { onRenderBooleanCell } from '../../utils/render';
import { listIconSize } from '../../utils/utils';
import { ImagePullPolicy } from '../gen/ai/h2o/engine/v1/base_types_pb';
import { DAIEngineVersion } from '../gen/ai/h2o/engine/v1/dai_engine_version_pb';
import {
  AssignDAIEngineVersionAliasesRequest,
  AssignDAIEngineVersionAliasesResponse,
  CreateDAIEngineVersionRequest,
  CreateDAIEngineVersionResponse,
  DAIEngineVersionService_AssignDAIEngineVersionAliases,
  DAIEngineVersionService_CreateDAIEngineVersion,
  DAIEngineVersionService_DeleteDAIEngineVersion,
  DAIEngineVersionService_GetDAIEngineVersion,
  DAIEngineVersionService_ListDAIEngineVersions,
  DAIEngineVersionService_UpdateDAIEngineVersion,
  DeleteDAIEngineVersionRequest,
  DeleteDAIEngineVersionResponse,
  GetDAIEngineVersionRequest,
  GetDAIEngineVersionResponse,
  ListDAIEngineVersionsRequest,
  ListDAIEngineVersionsResponse,
  UpdateDAIEngineVersionRequest,
  UpdateDAIEngineVersionResponse,
} from '../gen/ai/h2o/engine/v1/dai_engine_version_service_pb';
import { AIEMUserActionType } from '../userActions';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';
import {
  getOptionsFromEnum,
  onRender,
  onRenderDAIIconCell,
  onRenderDisplayAndIdColumnWithTitle,
  onRenderStringArrayCell,
} from './utils';

const entityType = EntityType.DAIEngineVersion;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateDAIEngineVersion',
    rpc: DAIEngineVersionService_CreateDAIEngineVersion,
    type: EntityActionType.Create,
    responseKey: 'daiEngineVersion',
    requestNameKey: 'daiEngineVersionId',
    requestPayloadKey: 'daiEngineVersion',
    requestNameKeyPrefix: 'daiEngineVersion/',
    entityType,
  } as EntityAction<DAIEngineVersion, CreateDAIEngineVersionRequest, CreateDAIEngineVersionResponse>,
  [EntityActionType.Get]: {
    name: 'GetDAIEngineVersion',
    rpc: DAIEngineVersionService_GetDAIEngineVersion,
    type: EntityActionType.Get,
    requestNameKey: 'name' as unknown,
    responseKey: 'daiEngineVersion',
    entityType,
  } as EntityAction<DAIEngineVersion, GetDAIEngineVersionRequest, GetDAIEngineVersionResponse>,
  [EntityActionType.List]: {
    name: 'ListDAIEngineVersion',
    rpc: DAIEngineVersionService_ListDAIEngineVersions,
    type: EntityActionType.List,
    responseKey: 'daiEngineVersions',
    entityType,
  } as EntityAction<DAIEngineVersion, ListDAIEngineVersionsRequest, ListDAIEngineVersionsResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateDAIEngineVersion',
    rpc: DAIEngineVersionService_UpdateDAIEngineVersion,
    type: EntityActionType.Update,
    responseKey: 'daiEngineVersion',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'daiEngineVersion',
    entityType,
  } as EntityAction<DAIEngineVersion, UpdateDAIEngineVersionRequest, UpdateDAIEngineVersionResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteDAIEngineVersion',
    rpc: DAIEngineVersionService_DeleteDAIEngineVersion,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<DAIEngineVersion, DeleteDAIEngineVersionRequest, DeleteDAIEngineVersionResponse>,
  [EntityActionType.Assign]: {
    name: 'AssignDAIEngineVersionAliases',
    rpc: DAIEngineVersionService_AssignDAIEngineVersionAliases,
    type: EntityActionType.Assign,
    responseKey: 'daiEngineVersions',
    requestNameKey: 'name',
    requestPayloadKey: 'aliases',
    payloadKey: 'aliases',
    entityType,
  } as EntityAction<DAIEngineVersion, AssignDAIEngineVersionAliasesRequest, AssignDAIEngineVersionAliasesResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'icon',
    name: 'Icon',
    fieldName: 'icon',
    minWidth: listIconSize,
    maxWidth: listIconSize,
    onRender: onRenderDAIIconCell,
  },
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 180,
    maxWidth: 180,
    onRender: onRenderDisplayAndIdColumnWithTitle,
  },
  {
    key: 'aliases',
    name: 'Aliases',
    fieldName: 'aliases',
    minWidth: 120,
    maxWidth: 120,
    onRender: onRenderStringArrayCell,
  },
  {
    key: 'deprecated',
    name: 'Deprecated',
    fieldName: 'deprecated',
    minWidth: 150,
    maxWidth: 150,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'image',
    name: 'Image',
    fieldName: 'image',
    minWidth: 200,
    maxWidth: 1000,
    onRender,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<DAIEngineVersion>[] = [
  {
    name: 'name',
    type: EntityFieldType.Semver,
    label: 'Name',
    description: 'DAI Engine Version resource name.',
    required: true,
    minimum: '1.10.4',
    readOnlyOnEdit: true,
  },
  {
    name: 'aliases',
    type: EntityFieldType.LatestAndAliases,
    label: 'Aliases',
    description: `
      Aliases for the specified Driverless AI Engine version.
      For example {"latest", "prerelease"}.`,
    permitActions: [AIEMUserActionType.DAI_ENGINE_VERSION_ASSIGN_ALIASES],
  },
  {
    name: 'deprecated',
    type: EntityFieldType.Boolean,
    label: 'Deprecated',
    description: 'Indicates whether DAIVersion is deprecated.',
  },
  {
    name: 'image',
    type: EntityFieldType.Text,
    label: 'Image',
    description: 'Name of the Docker image used when using this DAIEngineVersion.',
    required: true,
  },
  {
    name: 'imagePullPolicy',
    type: EntityFieldType.SelectEnum,
    label: 'Image pull policy',
    description: `
      Image pull policy applied when using this DAIVersion.
      When unset, server will choose a default one.`,
    options: getOptionsFromEnum(ImagePullPolicy, 'IMAGE_PULL_POLICY_', [ImagePullPolicy.UNSPECIFIED]),
  },
  {
    name: 'imagePullSecrets',
    type: EntityFieldType.StringArray,
    label: 'Image pull secrets',
    description: `
      List of references to k8s secrets that can be used for pulling an image of this DAIEngineVersion
      from a private container image registry or repository.`,
  },
];

export const daiEngineVersionEntity: Entity<DAIEngineVersion> = {
  type: EntityType.DAIEngineVersion,
  name: 'daiEngineVersions',
  createButtonLabel: 'Add DAI Engine Version',
  displayName: 'DAI Engine Version',
  displayNameKey: 'name',
  actions,
  columns,
  fields,
  requestNameKeyPrefix: 'workspaces/global/daiEngineVersions/',
  emptyModel: {
    name: '',
    deprecated: false,
    aliases: [],
    image: '',
    imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
    imagePullSecrets: [],
  },
  canBeReordered: false,
};
