import { Engine_State, Engine_Type } from '../gen/ai/h2o/engine/v1/engine_pb';
import { AIEMOpType, EngineState } from './types';

type TypeCondition = Partial<{
  [P in Engine_Type]: boolean;
}>;

type StateCondition = Partial<{
  [P in Engine_Type & Engine_State]: boolean;
}>;

type OpCondition = {
  types: TypeCondition;
  states: StateCondition;
};

const opsConditions = new Map<AIEMOpType, OpCondition>([
  [
    AIEMOpType.create,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true, [Engine_Type.NOTEBOOK]: true, [Engine_Type.H2O]: true },
      states: {},
    },
  ],
  [
    AIEMOpType.edit,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true, [Engine_Type.NOTEBOOK]: true },
      states: { [Engine_State.FAILED]: true, [Engine_State.PAUSED]: true },
    },
  ],
  [
    AIEMOpType.get,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true, [Engine_Type.NOTEBOOK]: true, [Engine_Type.H2O]: true },
      states: {
        [Engine_State.FAILED]: true,
        [Engine_State.PAUSED]: true,
        [Engine_State.PAUSING]: true,
        [Engine_State.TERMINATED]: true,
        [Engine_State.TERMINATING]: true,
        [Engine_State.RUNNING]: true,
      },
    },
  ],
  [
    AIEMOpType.list,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true, [Engine_Type.H2O]: true },
      states: {},
    },
  ],
  [
    AIEMOpType.openLog,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true, [Engine_Type.H2O]: true },
      states: { [Engine_State.CONNECTING]: true, [Engine_State.RUNNING]: true, [Engine_State.STARTING]: true },
    },
  ],
  [
    AIEMOpType.resume,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true, [Engine_Type.NOTEBOOK]: true },
      states: { [Engine_State.FAILED]: true, [Engine_State.PAUSED]: true },
    },
  ],
  [
    AIEMOpType.pause,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true, [Engine_Type.NOTEBOOK]: true },
      states: { [Engine_State.CONNECTING]: true, [Engine_State.RUNNING]: true, [Engine_State.STARTING]: true },
    },
  ],
  [
    AIEMOpType.terminate,
    {
      types: { [Engine_Type.H2O]: true },
      states: { [Engine_State.CONNECTING]: true, [Engine_State.RUNNING]: true, [Engine_State.STARTING]: true },
    },
  ],
  [
    AIEMOpType.delete,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true, [Engine_Type.NOTEBOOK]: true, [Engine_Type.H2O]: true },
      states: {
        [Engine_State.CONNECTING]: true,
        [Engine_State.FAILED]: true,
        [Engine_State.PAUSED]: true,
        [Engine_State.PAUSING]: true,
        [Engine_State.RUNNING]: true,
        [Engine_State.STARTING]: true,
        [Engine_State.TERMINATING]: true, // TODO confirm if this is really right, terminate terminating or terminated engines may lead to unexpected issues
        [Engine_State.TERMINATED]: true,
      },
    },
  ],
  [
    AIEMOpType.update,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true, [Engine_Type.NOTEBOOK]: true },
      states: { [Engine_State.PAUSED]: true },
    },
  ],
  [
    AIEMOpType.resize,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true },
      states: {
        [Engine_State.CONNECTING]: true,
        [Engine_State.FAILED]: true,
        [Engine_State.PAUSED]: true,
        [Engine_State.PAUSING]: true,
        [Engine_State.RUNNING]: true,
        [Engine_State.STARTING]: true,
        [Engine_State.TERMINATING]: false,
        [Engine_State.TERMINATED]: false,
        [Engine_State.UNSPECIFIED]: true,
        [Engine_State.DELETING]: false,
      },
    },
  ],
  [
    AIEMOpType.upgrade,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true },
      states: { [Engine_State.FAILED]: true, [Engine_State.PAUSED]: true },
    },
  ],
  [
    AIEMOpType.view,
    {
      types: { [Engine_Type.DRIVERLESS_AI]: true, [Engine_Type.NOTEBOOK]: true, [Engine_Type.H2O]: true },
      states: {
        [Engine_State.CONNECTING]: true,
        [Engine_State.DELETING]: true,
        [Engine_State.FAILED]: true,
        [Engine_State.PAUSED]: true,
        [Engine_State.PAUSING]: true,
        [Engine_State.RUNNING]: true,
        [Engine_State.STARTING]: true,
        [Engine_State.TERMINATING]: true,
        [Engine_State.TERMINATED]: true,
      },
    },
  ],
]);

export const hasOp = (op: AIEMOpType, type?: Engine_Type, state?: EngineState) => {
  if (!type || !state) return false;
  const _op = opsConditions.get(op);
  return _op && _op.types[type] && _op.states[state];
};
