// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file mlops/gauge_metric/gaugemetric.proto (package gauge_metric, syntax proto3)
/* eslint-disable */

import { RPC } from "../../runtime";

/**
 * @generated from message gauge_metric.MlopsGaugeMetricRequest
 */
export type MlopsGaugeMetricRequest = {
/**
 * Start date in YYYY-MM-DD format
 *
 * @generated from field: string start_date = 1;
 */
startDate?: string;
/**
 * End date in YYYY-MM-DD format
 *
 * @generated from field: string end_date = 2;
 */
endDate?: string;
/**
 * List of Gauge type
 *
 * @generated from field: repeated string type = 3;
 */
type?: string[];
}
;
/**
 * @generated from message gauge_metric.MlopsGaugeMetricResponse
 */
export type MlopsGaugeMetricResponse = {
/**
 * @generated from field: repeated gauge_metric.MlopsGaugeMetricEvent events = 1;
 */
events?: MlopsGaugeMetricEvent[];
}
;
/**
 * @generated from message gauge_metric.MlopsGaugeMetricEvent
 */
export type MlopsGaugeMetricEvent = {
/**
 * Time Interval
 *
 * @generated from field: string ts = 1;
 */
ts?: string;
/**
 * Resource name
 *
 * @generated from field: string type = 2;
 */
type?: string;
/**
 * Total count
 *
 * @generated from field: int32 total_count = 3;
 */
totalCount?: number;
}
;
/**
 * @generated from message gauge_metric.GaugeMetricDailyStatRequest
 */
export type GaugeMetricDailyStatRequest = {
/**
 * Date in YYYY-MM-DD format
 *
 * @generated from field: string date = 1;
 */
date?: string;
}
;
/**
 * @generated from rpc gauge_metric.MlopsGaugeMetricService.GetMlopsGaugeMetricTotalCount
 */
export const MlopsGaugeMetricService_GetMlopsGaugeMetricTotalCount = new RPC<MlopsGaugeMetricRequest,MlopsGaugeMetricResponse>("GET", "/getMlopsGaugeMetricTotalCount");
/**
 * @generated from rpc gauge_metric.MlopsGaugeMetricService.GetGaugeMetricDailyStats
 */
export const MlopsGaugeMetricService_GetGaugeMetricDailyStats = new RPC<GaugeMetricDailyStatRequest,MlopsGaugeMetricResponse>("GET", "/getGaugeMetricDailyStats");
