// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/notebook_engine_image_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { NotebookEngineImage } from "./notebook_engine_image_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for `CreateNotebookEngineImage`.
 *
 * @generated from message ai.h2o.engine.v1.CreateNotebookEngineImageRequest
 */
export type CreateNotebookEngineImageRequest = {
/**
 * Required. The resource name of the workspace to associate with the NotebookEngineImage.
 * Format is `workspaces/*`.
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Required. The NotebookEngineImage resource to create.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineImage notebook_engine_image = 2;
 */
notebookEngineImage: NotebookEngineImage;
/**
 * Optional. Specify the NotebookEngineImage ID, which will become a part of the NotebookEngineImage
 * resource name. If not specified, the server will generate one.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string notebook_engine_image_id = 3;
 */
notebookEngineImageId?: string;
}
;
/**
 * Response message for `CreateNotebookEngineImage`.
 *
 * @generated from message ai.h2o.engine.v1.CreateNotebookEngineImageResponse
 */
export type CreateNotebookEngineImageResponse = {
/**
 * The created NotebookEngineImage.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineImage notebook_engine_image = 1;
 */
notebookEngineImage?: NotebookEngineImage;
}
;
/**
 * Request message for `GetNotebookEngineImage`.
 *
 * @generated from message ai.h2o.engine.v1.GetNotebookEngineImageRequest
 */
export type GetNotebookEngineImageRequest = {
/**
 * Required. The name of the NotebookEngineImage to retrieve.
 * Format is `workspaces/*\/notebookEngineImages/*`
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for `GetNotebookEngineImage`.
 *
 * @generated from message ai.h2o.engine.v1.GetNotebookEngineImageResponse
 */
export type GetNotebookEngineImageResponse = {
/**
 * The requested NotebookEngineImage.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineImage notebook_engine_image = 1;
 */
notebookEngineImage?: NotebookEngineImage;
}
;
/**
 * Request message for listing NotebookEngineImages.
 *
 * @generated from message ai.h2o.engine.v1.ListNotebookEngineImagesRequest
 */
export type ListNotebookEngineImagesRequest = {
/**
 * Required. The resource name of the workspace to associate with the NotebookEngineImage.
 * Format is `workspaces/*`.
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Optional. Maximum number of NotebookEngineImages to return in a response.
 * If unspecified (or set to 0), at most 50 NotebookEngineImages will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Optional. Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListNotebookEngineImagesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for ListNotebookEngineImages.
 *
 * @generated from message ai.h2o.engine.v1.ListNotebookEngineImagesResponse
 */
export type ListNotebookEngineImagesResponse = {
/**
 * Collection of NotebookEngineImages.
 *
 * @generated from field: repeated ai.h2o.engine.v1.NotebookEngineImage notebook_engine_images = 1;
 */
notebookEngineImages?: NotebookEngineImage[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for updating an existing NotebookEngineImage.
 *
 * @generated from message ai.h2o.engine.v1.UpdateNotebookEngineImageRequest
 */
export type UpdateNotebookEngineImageRequest = {
/**
 * Required. NotebookEngineImage resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineImage notebook_engine_image = 1;
 */
notebookEngineImage: NotebookEngineImage;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {"display_name", "image", "enabled", "image_pull_policy", "image_pull_secrets"}.
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 10;
 */
updateMask: string;
}
;
/**
 * Response message for UpdateNotebookEngineImage.
 *
 * @generated from message ai.h2o.engine.v1.UpdateNotebookEngineImageResponse
 */
export type UpdateNotebookEngineImageResponse = {
/**
 * NotebookEngineImage resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngineImage notebook_engine_image = 1;
 */
notebookEngineImage?: NotebookEngineImage;
}
;
/**
 * Request message for deleting an existing NotebookEngineImage.
 *
 * @generated from message ai.h2o.engine.v1.DeleteNotebookEngineImageRequest
 */
export type DeleteNotebookEngineImageRequest = {
/**
 * Required. NotebookEngineImage resource name.
 * Format is `workspaces/*\/notebookEngineImages/*`.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteNotebookEngineImage.
 *
 * @generated from message ai.h2o.engine.v1.DeleteNotebookEngineImageResponse
 */
export type DeleteNotebookEngineImageResponse = {
}
;
/**
 * Creates a new NotebookEngineImage.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineImageService.CreateNotebookEngineImage
 */
export const NotebookEngineImageService_CreateNotebookEngineImage = new RPC<CreateNotebookEngineImageRequest,CreateNotebookEngineImageResponse>("POST", "/v1/{parent=workspaces/*}/notebookEngineImages", "notebookEngineImage");
/**
 * Returns a specific NotebookEngineImage.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineImageService.GetNotebookEngineImage
 */
export const NotebookEngineImageService_GetNotebookEngineImage = new RPC<GetNotebookEngineImageRequest,GetNotebookEngineImageResponse>("GET", "/v1/{name=workspaces/*/notebookEngineImages/*}");
/**
 * Returns a collection of NotebookEngineImages.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineImageService.ListNotebookEngineImages
 */
export const NotebookEngineImageService_ListNotebookEngineImages = new RPC<ListNotebookEngineImagesRequest,ListNotebookEngineImagesResponse>("GET", "/v1/{parent=workspaces/*}/notebookEngineImages");
/**
 * Updates a NotebookEngineImage.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineImageService.UpdateNotebookEngineImage
 */
export const NotebookEngineImageService_UpdateNotebookEngineImage = new RPC<UpdateNotebookEngineImageRequest,UpdateNotebookEngineImageResponse>("PATCH", "/v1/{notebookEngineImage.name=workspaces/*/notebookEngineImages/*}", "notebookEngineImage");
/**
 * Deletes a NotebookEngineImage.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineImageService.DeleteNotebookEngineImage
 */
export const NotebookEngineImageService_DeleteNotebookEngineImage = new RPC<DeleteNotebookEngineImageRequest,DeleteNotebookEngineImageResponse>("DELETE", "/v1/{name=workspaces/*/notebookEngineImages/*}");
