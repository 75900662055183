import { fetchWrapRPC } from '../services/api';
import {
  BatchFilterResourcesRequest,
  BatchResolvePermissionsRequest,
  QueryService_BatchFilterResources,
  QueryService_BatchResolvePermissions,
  QueryService_ResolveActions,
  QueryService_ResolvePermission,
  ResolveActionsRequest,
  ResolvePermissionRequest,
} from './gen/ai/h2o/authorization/v1/query_api_pb';
import { ListRolesRequest, RoleService_ListRoles } from './gen/ai/h2o/authorization/v1/role_api_pb';
import {
  CreateRoleBindingRequest,
  DeleteRoleBindingRequest,
  ListRoleBindingsRequest,
  RoleBindingService_CreateRoleBinding,
  RoleBindingService_DeleteRoleBinding,
  RoleBindingService_ListRoleBindings,
} from './gen/ai/h2o/authorization/v1/role_binding_api_pb';
import { GroupService_ListGroups, ListGroupsRequest } from './gen/ai/h2o/user/v1/group_api_pb';
import { ListUsersRequest, UserService_ListUsers } from './gen/ai/h2o/user/v1/user_api_pb';
import {
  CreateWorkspaceRequest,
  DeleteWorkspaceRequest,
  ListWorkspacesRequest,
  UndeleteWorkspaceRequest,
  UpdateWorkspaceRequest,
  WorkspaceService_CreateWorkspace,
  WorkspaceService_DeleteWorkspace,
  WorkspaceService_ListWorkspaces,
  WorkspaceService_UndeleteWorkspace,
  WorkspaceService_UpdateWorkspace,
} from './gen/ai/h2o/workspace/v1/workspace_api_pb';

export class AuthzServiceImpl {
  private basePath: string;
  constructor(basePath?: string) {
    this.basePath = basePath || '';
  }

  public setBasePath = (path: string) => {
    this.basePath = path;
  };

  // Workspaces
  public async getWorkspaces(req: ListWorkspacesRequest) {
    return await fetchWrapRPC(WorkspaceService_ListWorkspaces, { basePath: this.basePath })({ ...req });
  }
  public async createWorkspace(req: CreateWorkspaceRequest) {
    return await fetchWrapRPC(WorkspaceService_CreateWorkspace, { basePath: this.basePath })({ ...req });
  }
  public async updateWorkspace(req: UpdateWorkspaceRequest) {
    return await fetchWrapRPC(WorkspaceService_UpdateWorkspace, { basePath: this.basePath })({ ...req });
  }
  public async deleteWorkspace(req: DeleteWorkspaceRequest) {
    return await fetchWrapRPC(WorkspaceService_DeleteWorkspace, { basePath: this.basePath })({ ...req });
  }
  public async undeleteWorkspace(req: UndeleteWorkspaceRequest) {
    return await fetchWrapRPC(WorkspaceService_UndeleteWorkspace, { basePath: this.basePath })({ ...req });
  }

  // Users
  public async getUsers(req: ListUsersRequest) {
    return await fetchWrapRPC(UserService_ListUsers, { basePath: this.basePath })({ ...req });
  }

  public async getGroups(req: ListGroupsRequest) {
    return await fetchWrapRPC(GroupService_ListGroups, { basePath: this.basePath })({ ...req });
  }

  // Roles
  public async getRoles(req: ListRolesRequest) {
    return await fetchWrapRPC(RoleService_ListRoles, { basePath: this.basePath })({ ...req });
  }
  public async createRoleBinding(req: CreateRoleBindingRequest) {
    return await fetchWrapRPC(RoleBindingService_CreateRoleBinding, { basePath: this.basePath })({ ...req });
  }
  public async getRoleBindings(req: ListRoleBindingsRequest) {
    return await fetchWrapRPC(RoleBindingService_ListRoleBindings, { basePath: this.basePath })({ ...req });
  }
  public async deleteRoleBinding(req: DeleteRoleBindingRequest) {
    return await fetchWrapRPC(RoleBindingService_DeleteRoleBinding, { basePath: this.basePath })({ ...req });
  }

  // Permissions
  public async resolvePermission(req: ResolvePermissionRequest) {
    return await fetchWrapRPC(QueryService_ResolvePermission, { basePath: this.basePath })({ ...req });
  }
  public async resolveActions(req: ResolveActionsRequest) {
    return await fetchWrapRPC(QueryService_ResolveActions, { basePath: this.basePath })({ ...req });
  }
  public async batchFilterResources(req: BatchFilterResourcesRequest) {
    return await fetchWrapRPC(QueryService_BatchFilterResources, { basePath: this.basePath })({ ...req });
  }
  public async batchResolvePermission(req: BatchResolvePermissionsRequest) {
    return await fetchWrapRPC(QueryService_BatchResolvePermissions, { basePath: this.basePath })({ ...req });
  }
}
