import { IStyle, ITextFieldStyles } from '@fluentui/react';
import { IH2OTheme, TextField, useClassNames, useTheme } from '@h2oai/ui-kit';
import * as React from 'react';

import { ClassNamesFromIStyles } from '../../../../utils/models';

interface TimePickerProps {
  label?: string;
  onChange?: (hours: number, minutes: number) => void;
  initialHours?: number;
  initialMinutes?: number;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  label = 'Time',
  onChange,
  initialHours = 0,
  initialMinutes = 0,
  disabled = false,
}) => {
  const theme = useTheme();
  const [hours, setHours] = React.useState<string>(initialHours.toString().padStart(2, '0'));
  const [minutes, setMinutes] = React.useState<string>(initialMinutes.toString().padStart(2, '0'));
  const [hoursError, setHoursError] = React.useState<string>('');
  const [minutesError, setMinutesError] = React.useState<string>('');

  const textFieldStyles: Partial<ITextFieldStyles> = {
    root: {
      width: 60,
    },
  };

  interface ITimePickerStyles {
    root?: IStyle;
    label?: IStyle;
    separator?: IStyle;
    container?: IStyle;
  }

  const timePickerStyles = (theme: IH2OTheme): Partial<ITimePickerStyles> => ({
    root: {
      width: 'fit-content',
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      marginBottom: 4,
    },
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      height: 'fit-content',
      gap: '8px',
    },
    separator: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '32px',
      color: theme.palette?.gray700,
      margin: 0,
    },
  });

  const classNames = useClassNames<ITimePickerStyles, ClassNamesFromIStyles<ITimePickerStyles>>(
    'timepicker',
    timePickerStyles(theme)
  );

  const validateHours = (value: string): boolean => {
    const hoursNum = Number.parseInt(value, 10);
    if (isNaN(hoursNum) || hoursNum < 0 || hoursNum > 23) {
      setHoursError(`0 ${'\u2264'} hh < 24`);
      return false;
    }
    setHoursError('');
    return true;
  };

  const validateMinutes = (value: string): boolean => {
    const minutesNum = Number.parseInt(value, 10);
    if (isNaN(minutesNum) || minutesNum < 0 || minutesNum > 59) {
      setMinutesError(`0 ${'\u2264'} mm < 60`);
      return false;
    }
    setMinutesError('');
    return true;
  };

  const handleHoursChange = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue === undefined) return;
    if (newValue === '') {
      setHours(newValue);
      return;
    }
    if (!/^\d*$/.test(newValue)) return;
    if (newValue.length > 2) return;
    setHours(newValue);
    if (validateHours(newValue) && minutes.length > 0 && validateMinutes(minutes)) {
      onChange?.(Number.parseInt(newValue, 10), Number.parseInt(minutes, 10));
    }
  };

  const handleMinutesChange = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue === undefined) return;
    if (newValue === '') {
      setMinutes(newValue);
      return;
    }
    if (!/^\d*$/.test(newValue)) return;
    if (newValue.length > 2) return;
    setMinutes(newValue);
    if (validateMinutes(newValue) && hours.length > 0 && validateHours(hours)) {
      onChange?.(Number.parseInt(hours, 10), Number.parseInt(newValue, 10));
    }
  };

  const handleHoursBlur = () => {
    if (hours === '') {
      setHours('00');
      return;
    }

    if (validateHours(hours)) {
      const formattedHours = Number.parseInt(hours, 10).toString().padStart(2, '0');
      setHours(formattedHours);
      onChange?.(Number.parseInt(formattedHours, 10), Number.parseInt(minutes, 10));
    }
  };

  const handleMinutesBlur = () => {
    if (minutes === '') {
      setMinutes('00');
      return;
    }

    if (validateMinutes(minutes)) {
      const formattedMinutes = Number.parseInt(minutes, 10).toString().padStart(2, '0');
      setMinutes(formattedMinutes);
      onChange?.(Number.parseInt(hours, 10), Number.parseInt(formattedMinutes, 10));
    }
  };

  return (
    <div className={classNames.root}>
      {label && <label className={classNames.label}>{label}</label>}
      <div className={classNames.container}>
        <TextField
          aria-label="Hours"
          value={hours}
          onChange={handleHoursChange}
          onBlur={handleHoursBlur}
          styles={textFieldStyles}
          placeholder="HH"
          disabled={disabled}
          errorMessage={hoursError}
          maxLength={2}
        />
        <p className={classNames.separator}>:</p>
        <TextField
          aria-label="Minutes"
          value={minutes}
          onChange={handleMinutesChange}
          onBlur={handleMinutesBlur}
          styles={textFieldStyles}
          placeholder="MM"
          disabled={disabled}
          errorMessage={minutesError}
          maxLength={2}
        />
      </div>
    </div>
  );
};
