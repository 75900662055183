import { Loader, loaderStylesSpinnerXLarge, useTheme } from '@h2oai/ui-kit';
import { Chart, ChartData, ChartOptions } from 'chart.js/auto';
import { useEffect, useRef, useState } from 'react';
import 'chartjs-adapter-date-fns';

import {
  MlopsGaugeMetricRequest,
  MlopsGaugeMetricResponse,
} from '../../../../../admin-center/gen/mlops/gauge_metric/gaugemetric_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { getGaugeMetricComponentColor } from '../../../common/utils';

interface MlopsGaugeMetricPlotProps {
  startDate: string;
  endDate: string;
  gaugeType: string[];
}

const GaugeMetricPlot = ({ startDate, endDate, gaugeType }: MlopsGaugeMetricPlotProps) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  const [loading, setLoading] = useState(true);
  const service = useAdminCenterService();
  const theme = useTheme();

  useEffect(() => {
    const fetchAndUpdateChartData = async () => {
      setLoading(true);
      try {
        const request: MlopsGaugeMetricRequest = {
          startDate: startDate,
          endDate: endDate,
          type: gaugeType,
        };
        const response: MlopsGaugeMetricResponse = await service.getMlopsGaugeMetrics(request);
        const formattedData = (response.events ?? []).map((event) => {
          const utcDate = event.ts ? new Date(event.ts) : null;

          return {
            ts: utcDate
              ? utcDate.toLocaleDateString('en-CA', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
              : '-',
            type: event.type ?? '-',
            totalCount: event.totalCount ?? 0,
          };
        });

        const groupedData: { [date: string]: { [type: string]: number } } = {};
        formattedData.forEach(({ ts, type, totalCount }) => {
          if (!groupedData[ts]) {
            groupedData[ts] = {};
          }
          groupedData[ts][type] = totalCount;
        });

        const labels = Object.keys(groupedData).sort();
        const datasets = gaugeType.map((type) => ({
          label: type,
          data: labels.map((label) => groupedData[label]?.[type] || 0),
          borderColor: getGaugeMetricComponentColor(theme, type, ''),
          backgroundColor: getGaugeMetricComponentColor(theme, type, ''),
          fill: false,
          stepped: true,
        }));

        const chartData: ChartData<'line'> = {
          labels,
          datasets,
        };

        if (chartRef.current) {
          const ctx = chartRef.current.getContext('2d');
          if (ctx) {
            const options: ChartOptions<'line'> = {
              scales: {
                x: {
                  type: 'category',
                  title: {
                    display: true,
                    text: 'Date',
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: 'Total Count',
                  },
                  beginAtZero: true,
                },
              },
            };

            // Destroy existing chart instance if it exists
            if (chartInstanceRef.current) {
              chartInstanceRef.current.destroy();
            }

            // Create a new chart instance
            chartInstanceRef.current = new Chart(ctx, {
              type: 'line',
              data: chartData,
              options,
            });
          }
        }
      } catch (error) {
        console.error('Error fetching AI unit consumption data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndUpdateChartData();

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [startDate, endDate, gaugeType]);

  return (
    <div>
      {loading && <Loader styles={loaderStylesSpinnerXLarge} label={'Processing...'} />}
      <canvas ref={chartRef} width="100%" height="20" style={{ display: loading ? 'none' : 'block' }}></canvas>
    </div>
  );
};

export default GaugeMetricPlot;
