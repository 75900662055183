export enum AIEMUserActionType {
  // DAI Engine
  DAI_ENGINE_CREATE = 'actions/enginemanager/daiEngines/CREATE',
  DAI_ENGINE_GET = 'actions/enginemanager/daiEngines/GET',
  DAI_ENGINE_LIST = 'actions/enginemanager/daiEngines/LIST',
  DAI_ENGINE_UPDATE = 'actions/enginemanager/daiEngines/UPDATE',
  DAI_ENGINE_DELETE = 'actions/enginemanager/daiEngines/DELETE',
  DAI_ENGINE_PAUSE = 'actions/enginemanager/daiEngines/PAUSE',
  DAI_ENGINE_RESUME = 'actions/enginemanager/daiEngines/RESUME',
  DAI_ENGINE_DOWNLOAD_LOGS = 'actions/enginemanager/daiEngines/DOWNLOAD_LOGS',
  DAI_ENGINE_UPGRADE = 'actions/enginemanager/daiEngines/UPGRADE_DAI_ENGINE_VERSION',
  DAI_ENGINE_RESIZE = 'actions/enginemanager/daiEngines/RESIZE_STORAGE',
  DAI_ENGINE_MIGRATE_CREATOR = 'actions/enginemanager/daiEngines/MIGRATE_CREATOR',

  // H2O Engine
  H2O_ENGINE_CREATE = 'actions/enginemanager/h2oEngines/CREATE',
  H2O_ENGINE_GET = 'actions/enginemanager/h2oEngines/GET',
  H2O_ENGINE_LIST = 'actions/enginemanager/h2oEngines/LIST',
  H2O_ENGINE_DELETE = 'actions/enginemanager/h2oEngines/DELETE',
  H2O_ENGINE_TERMINATE = 'actions/enginemanager/h2oEngines/TERMINATE',
  H2O_ENGINE_DOWNLOAD_LOGS = 'actions/enginemanager/h2oEngines/DOWNLOAD_LOGS',

  // Notebook Engine
  NOTEBOOK_ENGINE_CREATE = 'actions/enginemanager/notebookEngines/CREATE',
  NOTEBOOK_ENGINE_GET = 'actions/enginemanager/notebookEngines/GET',
  NOTEBOOK_ENGINE_LIST = 'actions/enginemanager/notebookEngines/LIST',
  NOTEBOOK_ENGINE_DELETE = 'actions/enginemanager/notebookEngines/DELETE',
  NOTEBOOK_ENGINE_PAUSE = 'actions/enginemanager/notebookEngines/PAUSE',
  NOTEBOOK_ENGINE_DOWNLOAD_RESUME = 'actions/enginemanager/notebookEngines/RESUME',
  NOTEBOOK_ENGINE_DOWNLOAD_ACCESS = 'actions/enginemanager/notebookEngines/ACCESS',

  // DAI Engine Version
  DAI_ENGINE_VERSION_CREATE = 'actions/enginemanager/daiEngineVersions/CREATE',
  DAI_ENGINE_VERSION_GET = 'actions/enginemanager/daiEngineVersions/GET',
  DAI_ENGINE_VERSION_LIST = 'actions/enginemanager/daiEngineVersions/LIST',
  DAI_ENGINE_VERSION_UPDATE = 'actions/enginemanager/daiEngineVersions/UPDATE',
  DAI_ENGINE_VERSION_DELETE = 'actions/enginemanager/daiEngineVersions/DELETE',
  DAI_ENGINE_VERSION_ASSIGN_ALIASES = 'actions/enginemanager/daiEngineVersions/ASSIGN_ALIASES',

  // H2O Engine Version
  H2O_ENGINE_VERSION_CREATE = 'actions/enginemanager/h2oEngineVersions/CREATE',
  H2O_ENGINE_VERSION_GET = 'actions/enginemanager/h2oEngineVersions/GET',
  H2O_ENGINE_VERSION_LIST = 'actions/enginemanager/h2oEngineVersions/LIST',
  H2O_ENGINE_VERSION_UPDATE = 'actions/enginemanager/h2oEngineVersions/UPDATE',
  H2O_ENGINE_VERSION_DELETE = 'actions/enginemanager/h2oEngineVersions/DELETE',
  H2O_ENGINE_VERSION_ASSIGN_ALIASES = 'actions/enginemanager/h2oEngineVersions/ASSIGN_ALIASES',

  // Notebook Engine Image
  NOTEBOOK_ENGINE_IMAGES_CREATE = 'actions/enginemanager/notebookEngineImages/CREATE',
  NOTEBOOK_ENGINE_IMAGES_GET = 'actions/enginemanager/notebookEngineImages/GET',
  NOTEBOOK_ENGINE_IMAGES_LIST = 'actions/enginemanager/notebookEngineImages/LIST',
  NOTEBOOK_ENGINE_IMAGES_UPDATE = 'actions/enginemanager/notebookEngineImages/UPDATE',
  NOTEBOOK_ENGINE_IMAGES_DELETE = 'actions/enginemanager/notebookEngineImages/DELETE',

  // DAI Engine Profile
  DAI_ENGINE_PROFILES_CREATE = 'actions/enginemanager/daiEngineProfiles/CREATE',
  DAI_ENGINE_PROFILES_GET = 'actions/enginemanager/daiEngineProfiles/GET',
  DAI_ENGINE_PROFILES_LIST = 'actions/enginemanager/daiEngineProfiles/LIST',
  DAI_ENGINE_PROFILES_UPDATE = 'actions/enginemanager/daiEngineProfiles/UPDATE',
  DAI_ENGINE_PROFILES_DELETE = 'actions/enginemanager/daiEngineProfiles/DELETE',

  // H2O Engine Profile
  H2O_ENGINE_PROFILES_CREATE = 'actions/enginemanager/h2oEngineProfiles/CREATE',
  H2O_ENGINE_PROFILES_GET = 'actions/enginemanager/h2oEngineProfiles/GET',
  H2O_ENGINE_PROFILES_LIST = 'actions/enginemanager/h2oEngineProfiles/LIST',
  H2O_ENGINE_PROFILES_UPDATE = 'actions/enginemanager/h2oEngineProfiles/UPDATE',
  H2O_ENGINE_PROFILES_DELETE = 'actions/enginemanager/h2oEngineProfiles/DELETE',

  // Notebook Engine Profile
  NOTEBOOK_ENGINE_PROFILES_CREATE = 'actions/enginemanager/notebookEngineProfiles/CREATE',
  NOTEBOOK_ENGINE_PROFILES_GET = 'actions/enginemanager/notebookEngineProfiles/GET',
  NOTEBOOK_ENGINE_PROFILES_LIST = 'actions/enginemanager/notebookEngineProfiles/LIST',
  NOTEBOOK_ENGINE_PROFILES_UPDATE = 'actions/enginemanager/notebookEngineProfiles/UPDATE',
  NOTEBOOK_ENGINE_PROFILES_DELETE = 'actions/enginemanager/notebookEngineProfiles/DELETE',
}
