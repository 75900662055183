import { IButtonStyles, IStyle, KeyCodes } from '@fluentui/react';
import { IContextualMenuItem, IContextualMenuListProps } from '@fluentui/react/lib/ContextualMenu';
import { Icon } from '@fluentui/react/lib/Icon';
import { ISearchBoxStyles, SearchBox } from '@fluentui/react/lib/SearchBox';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { Button, IH2OTheme, IStyleFunctionOrObject, Link, useClassNames, useTheme } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useRoles } from '../../authz/providers/RoleProvider';
import { ClassNamesFromIStyles } from '../../utils/models';
import { useWorkspaces } from './WorkspaceProvider';

interface INavigationStyles {
  workspaceWidget: IStyle;
  widgetTitle: IStyle;
  contextualMenu: IStyle;
  nav: IStyle;
}
const navigationStyles = (theme: IH2OTheme): Partial<INavigationStyles> => ({
    workspaceWidget: {
      backgroundColor: theme.semanticColors?.bodyBackground,
      padding: '20px 10px',
      borderRadius: 8,
      position: 'absolute',
      bottom: 90,
      maxWidth: 209,
    },
    widgetTitle: {
      color: theme.semanticColors?.messageBarTitleText,
      marginTop: 0,
    },
    contextualMenu: { width: 226 },
    nav: {
      marginTop: 14,
    },
  }),
  filteredItemsStyle: React.CSSProperties = {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBoxStyles: ISearchBoxStyles = {
    root: { margin: '8px' },
  },
  accessControlStyles: IStyleFunctionOrObject<IButtonStyles> | IStyleFunctionOrObject<IButtonStyles>[] | undefined = {
    label: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 18,
    },
    root: {
      height: 20,
      minHeight: 20,
      padding: '0 8px',
      border: 'none',
    },
  };

const WorkspaceNavWidget = () => {
  const history = useHistory(),
    theme = useTheme(),
    classNames = useClassNames<INavigationStyles, ClassNamesFromIStyles<INavigationStyles>>(
      'navigationPane',
      navigationStyles(theme)
    ),
    { workspaces, activateWorkspace, activeWorkspace } = useWorkspaces(),
    { permissions } = useRoles(),
    menuItems: IContextualMenuItem[] = React.useMemo(
      () =>
        workspaces?.map((w) => ({
          key: w.name || '',
          text: w.displayName,
          iconProps: w.name === activeWorkspace?.name ? { iconName: 'BoxCheckmarkSolid' } : undefined,
          onClick: () => activateWorkspace(w.name || ''),
        })) || [],
      [activateWorkspace, workspaces, activeWorkspace]
    ),
    [items, setItems] = React.useState(menuItems),
    onAbort = React.useCallback(() => {
      setItems(menuItems);
    }, [menuItems]),
    onChange = React.useCallback(
      (_ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue === undefined) return;
        const filteredItems = menuItems.filter(
          (item) => item.text && item.text.toLowerCase().indexOf(newValue.toLowerCase()) !== -1
        );

        if (!filteredItems || !filteredItems.length) {
          filteredItems.push({
            key: 'no_results',
            onRender: (_item, _dismissMenu) => (
              <div key="no_results" style={filteredItemsStyle}>
                <Icon iconName="SearchIssue" title="No actions found" />
                <span>No workspace found</span>
              </div>
            ),
          });
        }

        setItems(filteredItems);
      },
      [menuItems]
    ),
    onKeyDown = React.useCallback((e) => {
      /* Key Up, but we are not at the beginning of the text: stop event propagation to prevent ContextualMenu to focus */
      if (e.target.selectionStart > 0 && e.which === KeyCodes.up) {
        e.stopPropagation();
      }
      /* Key Down, but we are not at the end of the text: stop event propagation to prevent ContextualMenu to focus */
      if (e.target.selectionStart !== e.target.value.length && e.which === KeyCodes.down) {
        e.stopPropagation();
      }
    }, []),
    onDismiss = React.useCallback(() => {
      setItems(menuItems);
    }, [menuItems]),
    renderMenuList = React.useCallback(
      (menuListProps?: IContextualMenuListProps, defaultRender?: IRenderFunction<IContextualMenuListProps>) => {
        return (
          <div className={classNames.contextualMenu}>
            <div style={{ borderBottom: '1px solid #ccc' }}>
              <SearchBox
                ariaLabel="Filter workspaces by text"
                placeholder="Filter workspaces"
                onAbort={onAbort}
                onChange={onChange}
                onKeyDown={onKeyDown}
                styles={searchBoxStyles}
              />
            </div>
            {defaultRender?.(menuListProps)}
            <br />
            <Link
              style={{ marginLeft: 12 }}
              onClick={() => {
                history.push('/orchestrator/workspaces');
              }}
            >
              Manage workspaces
            </Link>
          </div>
        );
      },
      [onAbort, onChange, onKeyDown]
    ),
    menuProps = React.useMemo(
      () => ({
        onRenderMenuList: renderMenuList,
        shouldFocusOnMount: true,
        items,
        focusZoneProps: {
          /* Allow up and down arrows to move focus out of the SearchBox */
          shouldInputLoseFocusOnArrowKey: () => true,
        },
        onDismiss,
      }),
      [items, renderMenuList, onDismiss]
    );

  React.useEffect(() => {
    const newItems: IContextualMenuItem[] =
      workspaces?.map((w) => ({
        key: w.name || '',
        text: w.displayName,
        iconProps: w.name === activeWorkspace?.name ? { iconName: 'BoxCheckmarkSolid' } : undefined,
        onClick: () => activateWorkspace(w.name || ''),
      })) || [];
    setItems(newItems);
  }, [workspaces, activeWorkspace, activateWorkspace]);

  return (
    <div className={classNames.workspaceWidget} id="left-nav-panel-workspace-widget">
      <h4 className={classNames.widgetTitle} id="left-nav-panel-workspace-widget-title">
        Active workspace
      </h4>
      <Button
        title="Manage workspaces"
        iconName="Stack"
        text={activeWorkspace?.displayName || 'No workspace selected'}
        menuIconName="ChevronDown"
        menuProps={menuProps}
        styles={{ root: { width: '189px' } }}
      />
      <Button
        title="Access control"
        text="Access control"
        onClick={() => {
          history.push(`/orchestrator/${activeWorkspace?.name}/accessControl`);
        }}
        iconName="AddGroup"
        styles={accessControlStyles}
        style={{ visibility: permissions.canViewAccessControl ? 'visible' : 'hidden' }}
        buttonContainerStyles={{ root: { marginTop: 12, textAlign: 'center' } }}
      />
    </div>
  );
};

export default WorkspaceNavWidget;
