// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/dai_engine_profile_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { DAIEngineProfile } from "./dai_engine_profile_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message Create method.
 *
 * @generated from message ai.h2o.engine.v1.CreateDAIEngineProfileRequest
 */
export type CreateDAIEngineProfileRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/daiEngineProfiles/CREATE
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The DAIEngineProfile resource to create.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineProfile dai_engine_profile = 2;
 */
daiEngineProfile: DAIEngineProfile;
/**
 * Specify the DAIEngineProfile ID, which will become a part of the DAIEngineProfile
 * resource name.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string dai_engine_profile_id = 3;
 */
daiEngineProfileId: string;
}
;
/**
 * Response message for Create method.
 *
 * @generated from message ai.h2o.engine.v1.CreateDAIEngineProfileResponse
 */
export type CreateDAIEngineProfileResponse = {
/**
 * Created DAIEngineProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineProfile dai_engine_profile = 1;
 */
daiEngineProfile?: DAIEngineProfile;
}
;
/**
 * Request message for Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetDAIEngineProfileRequest
 */
export type GetDAIEngineProfileRequest = {
/**
 * DAIEngineProfile resource name.
 * Format: workspaces/{workspace}/daiEngineProfiles/{dai_engine_profile}
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/daiEngineProfiles/GET
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetDAIEngineProfileResponse
 */
export type GetDAIEngineProfileResponse = {
/**
 * DAIEngineProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineProfile dai_engine_profile = 1;
 */
daiEngineProfile?: DAIEngineProfile;
}
;
/**
 * Request message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListDAIEngineProfilesRequest
 */
export type ListDAIEngineProfilesRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/daiEngineProfiles/LIST
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of DAIEngineProfiles to return in a response.
 * If unspecified (or set to 0), at most 50 DAIEngineProfiles will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListDAIEngineProfilesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListDAIEngineProfilesResponse
 */
export type ListDAIEngineProfilesResponse = {
/**
 * Collection of DAIEngineProfiles.
 *
 * @generated from field: repeated ai.h2o.engine.v1.DAIEngineProfile dai_engine_profiles = 1;
 */
daiEngineProfiles?: DAIEngineProfile[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for ListAssigned method.
 *
 * @generated from message ai.h2o.engine.v1.ListAssignedDAIEngineProfilesRequest
 */
export type ListAssignedDAIEngineProfilesRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/daiEngineProfiles/LIST_ASSIGNED
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of DAIEngineProfiles to return in a response.
 * If unspecified (or set to 0), at most 50 DAIEngineProfiles will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListDAIEngineProfilesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for ListAssigned method.
 * (-- api-linter: core::0132::response-unknown-fields=disabled
 *     aip.dev/not-precedent: Not a standard List method response. --)
 *
 * @generated from message ai.h2o.engine.v1.ListAssignedDAIEngineProfilesResponse
 */
export type ListAssignedDAIEngineProfilesResponse = {
/**
 * Collection of DAIEngineProfiles.
 *
 * @generated from field: repeated ai.h2o.engine.v1.DAIEngineProfile dai_engine_profiles = 1;
 */
daiEngineProfiles?: DAIEngineProfile[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for Update method.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDAIEngineProfileRequest
 */
export type UpdateDAIEngineProfileRequest = {
/**
 * Required. DAIEngineProfile resource.
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/daiEngineProfiles/UPDATE
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineProfile dai_engine_profile = 1;
 */
daiEngineProfile: DAIEngineProfile;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are:
 * - display_name
 * - priority
 * - enabled
 * - assigned_oidc_roles_enabled
 * - assigned_oidc_roles
 * - max_running_engines
 * - cpu_constraint
 * - gpu_constraint
 * - memory_bytes_constraint
 * - storage_bytes_constraint
 * - max_idle_duration_constraint
 * - max_running_duration_constraint
 * - max_non_interaction_duration
 * - max_unused_duration
 * - configuration_override
 * - base_configuration
 * - config_editability
 * - yaml_pod_template_spec
 * - yaml_gpu_tolerations
 * - triton_enabled
 * - gpu_resource_name
 * - data_directory_storage_class
 *
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response for Update method.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDAIEngineProfileResponse
 */
export type UpdateDAIEngineProfileResponse = {
/**
 * DAIEngineProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineProfile dai_engine_profile = 1;
 */
daiEngineProfile?: DAIEngineProfile;
}
;
/**
 * Request message for Delete method.
 *
 * @generated from message ai.h2o.engine.v1.DeleteDAIEngineProfileRequest
 */
export type DeleteDAIEngineProfileRequest = {
/**
 * DAIEngineProfile resource name.
 * Format: workspaces/{workspace}/daiEngineProfiles/{dai_engine_profile}
 *
 * Authorization may require the following permission on the profile's parent workspace:
 * * actions/enginemanager/daiEngineProfiles/DELETE
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for Delete method.
 *
 * @generated from message ai.h2o.engine.v1.DeleteDAIEngineProfileResponse
 */
export type DeleteDAIEngineProfileResponse = {
}
;
/**
 * Creates a new DAIEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineProfileService.CreateDAIEngineProfile
 */
export const DAIEngineProfileService_CreateDAIEngineProfile = new RPC<CreateDAIEngineProfileRequest,CreateDAIEngineProfileResponse>("POST", "/v1/{parent=workspaces/*}/daiEngineProfiles", "daiEngineProfile");
/**
 * Get DAIEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineProfileService.GetDAIEngineProfile
 */
export const DAIEngineProfileService_GetDAIEngineProfile = new RPC<GetDAIEngineProfileRequest,GetDAIEngineProfileResponse>("GET", "/v1/{name=workspaces/*/daiEngineProfiles/*}");
/**
 * Returns a collection of DAIEngineProfiles.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineProfileService.ListDAIEngineProfiles
 */
export const DAIEngineProfileService_ListDAIEngineProfiles = new RPC<ListDAIEngineProfilesRequest,ListDAIEngineProfilesResponse>("GET", "/v1/{parent=workspaces/*}/daiEngineProfiles");
/**
 * Returns assigned DAIEngineProfiles that match OIDC roles of the caller.
 * (-- api-linter: core::0136::http-uri-suffix=disabled
 *     aip.dev/not-precedent: Not a standard List method. --)
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineProfileService.ListAssignedDAIEngineProfiles
 */
export const DAIEngineProfileService_ListAssignedDAIEngineProfiles = new RPC<ListAssignedDAIEngineProfilesRequest,ListAssignedDAIEngineProfilesResponse>("GET", "/v1/{parent=workspaces/*}/daiEngineProfiles:listAssigned");
/**
 * Updates a DAIEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineProfileService.UpdateDAIEngineProfile
 */
export const DAIEngineProfileService_UpdateDAIEngineProfile = new RPC<UpdateDAIEngineProfileRequest,UpdateDAIEngineProfileResponse>("PATCH", "/v1/{daiEngineProfile.name=workspaces/*/daiEngineProfiles/*}", "daiEngineProfile");
/**
 * Deletes a DAIEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineProfileService.DeleteDAIEngineProfile
 */
export const DAIEngineProfileService_DeleteDAIEngineProfile = new RPC<DeleteDAIEngineProfileRequest,DeleteDAIEngineProfileResponse>("DELETE", "/v1/{name=workspaces/*/daiEngineProfiles/*}");
