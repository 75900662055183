import { Label, Stack, Toggle } from '@fluentui/react';
import { IStyleSetBase } from '@fluentui/style-utilities';
import { useClassNames } from '@h2oai/ui-kit';
import { Fragment, useCallback, useEffect, useMemo, useReducer, useRef } from 'react';

import { EntityField } from '../../../aiem/entity/types';
import { ProfileConstraintNumeric } from '../../../aiem/gen/ai/h2o/engine/v1/profile_constraint_pb';
import { ConstraintType, constraintTypeMapAttributes, constraintTypeWithCumulativeMax } from '../../../aiem/types';
import SpinnerWithTooltip from '../../../components/AIEnginesPage/components/SpinnerWithTooltip/SpinnerWithTooltip';
import { EntityFieldInputProps } from './BasicEntityModelComponents';
import { defaultConstraintHeaderRowStyles, defaultEntityFormRowStyles } from './DefaultEntityFormRowStyles';
import { LabelAndDescription } from './LabelAndDescription';

type ConstraintSetSubFieldContainerProps = {
  children?: React.ReactNode;
  rootStyles?: IStyleSetBase;
};

const ConstraintSetSubFieldContainer = ({ children, rootStyles = {} }: ConstraintSetSubFieldContainerProps) => (
  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { minWidth: 150, ...rootStyles } }}>
    {children}
  </Stack>
);

interface ConstraintSetState {
  min: number;
  max: number;
  defaultValue: number;
  cumulativeMax: number;
  infinite: boolean;
  cumulativeInfinite: boolean;
}

enum ConstraintSetAction {
  MIN = 'min',
  INFINITE = 'infinite',
  CUMULATIVE_INFINITE = 'cumulativeInfinite',
  MAX = 'max',
  DEFAULT_VALUE = 'defaultValue',
  CUMULATIVE_MAX = 'cumulativeMax',
}

type ConstraintSetActions = { type: ConstraintSetAction; value: any };

type ConstraintSetReducerFunction = (state: ConstraintSetState, action: ConstraintSetActions) => ConstraintSetState;

const constraintSetReducer: ConstraintSetReducerFunction = (
  state: ConstraintSetState,
  action: ConstraintSetActions
): ConstraintSetState => {
  const newState = { ...state };
  switch (action.type) {
    case ConstraintSetAction.MIN:
      newState.min = action.value;
      break;
    case ConstraintSetAction.MAX:
      newState.max = action.value;
      break;
    case ConstraintSetAction.DEFAULT_VALUE:
      newState.defaultValue = action.value;
      break;
    case ConstraintSetAction.CUMULATIVE_MAX:
      newState.cumulativeMax = action.value;
      break;
    case ConstraintSetAction.INFINITE:
      newState.infinite = action.value;
      break;
    case ConstraintSetAction.CUMULATIVE_INFINITE:
      newState.cumulativeInfinite = action.value;
      break;
  }
  return newState;
};

export default function ConstraintSetModelField<EntityModel>({
  field,
  model,
  onChange: onChangeModel,
}: EntityFieldInputProps<EntityModel>) {
  const { constraintType = ConstraintType.CPU, label, name, description, disabled } = field;
  const attributes = constraintTypeMapAttributes[constraintType] || {};
  const hasCumulativeMax = field.constraintType && constraintTypeWithCumulativeMax.includes(field.constraintType);
  const { max: maxMax, min: minMin, suffix, ToView, FromView } = attributes;
  const constraint: ProfileConstraintNumeric = (model[name] as ProfileConstraintNumeric) || {
    min: '0',
    max: '0',
    default: '0',
    ...(hasCumulativeMax ? { cumulativeMax: '0' } : {}),
  };

  const convertToView = useCallback(
    (value): number => {
      const newValue = Number(ToView ? ToView(value) : value);
      if (Number.isNaN(newValue)) {
        return 0;
      } else {
        return newValue;
      }
    },
    [ToView]
  );

  const firstRender = useRef<boolean>(true);
  const originalMax = useRef<string | number | null | undefined>(ToView ? ToView(constraint.max) : constraint.max);
  const originalCumulativeMax = useRef<string | number | null | undefined>(
    ToView ? ToView(constraint.cumulativeMax) : constraint.cumulativeMax
  );
  const [constraintSetState, constraintSetDispatch] = useReducer<ConstraintSetReducerFunction>(constraintSetReducer, {
    min: convertToView(constraint.min),
    max: convertToView(constraint.max),
    defaultValue: convertToView(constraint.default),
    cumulativeMax: convertToView(constraint.cumulativeMax),
    infinite: !Boolean(constraint.max),
    cumulativeInfinite: !Boolean(constraint.cumulativeMax),
  });

  const cumulativeConstrainMin = constraintSetState.max
    ? constraintSetState.max + 1
    : constraintSetState.defaultValue
    ? constraintSetState.defaultValue + 1
    : 0;

  const updateMin = (value: number) => {
    if (value === undefined) {
      return;
    }
    constraintSetDispatch({ type: ConstraintSetAction.MIN, value });
    // if the default is below the min now, we adjust that too:
    if (value > constraintSetState.defaultValue) {
      constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value });
    }
    // if the max is below the min now, we adjust that too
    if (value > constraintSetState.max && !constraintSetState.infinite) {
      constraintSetDispatch({ type: ConstraintSetAction.MAX, value });
    }
    // if the cumulativeMax is below or equal the min now, we adjust that too
    if (value >= constraintSetState.cumulativeMax) {
      constraintSetDispatch({ type: ConstraintSetAction.CUMULATIVE_MAX, value: value + 1 });
    }
  };
  const updateMax = (value: number) => {
    if (value === undefined) {
      return;
    }
    constraintSetDispatch({ type: ConstraintSetAction.MAX, value });
    originalMax.current = value;
    // if the max is below the default, adjust the default:
    if (value < constraintSetState.defaultValue) {
      constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value });
    }
    // if the max is below the min now, we adjust that too
    if (value < constraintSetState.min) {
      constraintSetDispatch({ type: ConstraintSetAction.MIN, value });
    }
    // if the cumulativeMax is below or equal the max now, we adjust that too
    if (value >= constraintSetState.cumulativeMax) {
      constraintSetDispatch({ type: ConstraintSetAction.CUMULATIVE_MAX, value: value + 1 });
    }
  };
  const updateDefaultValue = (value: number) => {
    constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value });
  };
  const updateCumulativeMax = (value: number) => {
    constraintSetDispatch({ type: ConstraintSetAction.CUMULATIVE_MAX, value });
    originalCumulativeMax.current = value;
  };
  const updateInfinite = (value: boolean) => {
    if (!value) {
      const newValue = originalMax.current
        ? Math.max(Number(originalMax.current), constraintSetState.min, constraintSetState.defaultValue)
        : constraintSetState.defaultValue;
      constraintSetDispatch({ type: ConstraintSetAction.MAX, value: newValue });
      if (originalMax.current === undefined) {
        constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value: newValue });
      }
    }
    if (value) {
      constraintSetDispatch({ type: ConstraintSetAction.MAX, value: null });
    }
    constraintSetDispatch({ type: ConstraintSetAction.INFINITE, value });
  };
  const updateCumulativeInfinite = (value: boolean) => {
    if (!value) {
      const newValue = originalCumulativeMax.current
        ? Math.max(
            Number(originalCumulativeMax.current),
            constraintSetState.max + 1,
            constraintSetState.defaultValue + 1,
            constraintSetState.min + 1
          )
        : constraintSetState.defaultValue + 1;

      constraintSetDispatch({ type: ConstraintSetAction.CUMULATIVE_MAX, value: newValue });
    }
    if (value) {
      constraintSetDispatch({ type: ConstraintSetAction.CUMULATIVE_MAX, value: null });
    }
    constraintSetDispatch({ type: ConstraintSetAction.CUMULATIVE_INFINITE, value });
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!onChangeModel) {
      return;
    }
    const newConstraintSet = {
      min: FromView ? FromView(constraintSetState.min) : constraintSetState.min,
      default: FromView ? FromView(constraintSetState.defaultValue) : constraintSetState.defaultValue,
    };
    if (!constraintSetState.cumulativeInfinite) {
      newConstraintSet['cumulativeMax'] = FromView
        ? FromView(constraintSetState.cumulativeMax)
        : constraintSetState.cumulativeMax;
    }
    if (!constraintSetState.infinite) {
      newConstraintSet['max'] = FromView ? FromView(constraintSetState.max) : constraintSetState.max;
    }
    onChangeModel(name, newConstraintSet);
  }, [constraintSetState]);

  return (
    <Stack horizontal styles={{ root: defaultEntityFormRowStyles }}>
      <LabelAndDescription label={label} description={description} required={field.required} />
      <ConstraintSetSubFieldContainer>
        <SpinnerWithTooltip
          disabled={disabled}
          onChange={
            disabled
              ? () => null
              : (_: any, value: number) => {
                  updateMin(value);
                }
          }
          value={convertToView(constraint.min)}
          min={minMin || 0}
          max={maxMax}
          suffix={suffix}
        />
      </ConstraintSetSubFieldContainer>

      <Stack
        horizontal
        styles={{
          root: {
            border: '1px solid var(--h2o-gray300, #D1D5DB)',
            borderRadius: 4,
            background: 'var(--h2o-gray200, #f3f4f6)',
          },
        }}
      >
        <ConstraintSetSubFieldContainer rootStyles={{ minWidth: 70 }}>
          <Toggle
            disabled={disabled}
            checked={constraintSetState.infinite}
            onChange={
              disabled
                ? () => null
                : (_, checked) => {
                    const value = Boolean(checked);
                    updateInfinite(value);
                  }
            }
          />
        </ConstraintSetSubFieldContainer>

        <ConstraintSetSubFieldContainer>
          {constraintSetState.infinite ? (
            'No limit'
          ) : (
            <SpinnerWithTooltip
              onChange={
                disabled
                  ? () => null
                  : (_: any, value: number) => {
                      updateMax(value);
                    }
              }
              value={convertToView(constraint.max)}
              min={0}
              max={maxMax}
              suffix={suffix}
              disabled={disabled || constraintSetState.infinite}
            />
          )}
        </ConstraintSetSubFieldContainer>
      </Stack>

      <ConstraintSetSubFieldContainer>
        <SpinnerWithTooltip
          disabled={disabled}
          onChange={
            disabled
              ? () => null
              : (_: any, value: number) => {
                  updateDefaultValue(value);
                }
          }
          value={convertToView(constraint.default)}
          min={constraintSetState.min}
          max={constraintSetState.max || constraintSetState.cumulativeMax || undefined}
          suffix={suffix}
        />
      </ConstraintSetSubFieldContainer>

      {hasCumulativeMax && (
        <Stack
          horizontal
          styles={{
            root: {
              border: '1px solid var(--h2o-gray300, #D1D5DB)',
              borderRadius: 4,
              background: 'var(--h2o-gray200, #f3f4f6)',
            },
          }}
        >
          <ConstraintSetSubFieldContainer>
            <Toggle
              disabled={disabled}
              checked={constraintSetState.cumulativeInfinite}
              onChange={(_, checked) => {
                const value = Boolean(checked);
                updateCumulativeInfinite(value);
              }}
            />
          </ConstraintSetSubFieldContainer>
          <ConstraintSetSubFieldContainer>
            {constraintSetState.cumulativeInfinite ? (
              'No limit'
            ) : (
              <SpinnerWithTooltip
                disabled={disabled}
                onChange={
                  disabled
                    ? () => null
                    : (_: any, value: number) => {
                        updateCumulativeMax(value);
                      }
                }
                value={convertToView(constraintSetState.cumulativeMax)}
                min={cumulativeConstrainMin}
                suffix={suffix}
              />
            )}
          </ConstraintSetSubFieldContainer>
        </Stack>
      )}
    </Stack>
  );
}

type ConstraintFieldsTableHeaderLabelProps = {
  label: string;
  width?: number | string;
  rootStyles?: IStyleSetBase;
};

export function ConstraintFieldsTableHeaderLabel({
  label,
  width: minWidth = 150,
  rootStyles,
}: ConstraintFieldsTableHeaderLabelProps) {
  return (
    <Stack horizontalAlign="center" styles={{ root: { minWidth, ...rootStyles } }}>
      <Label styles={{ root: { fontWeight: 700 } }}>{label}</Label>
    </Stack>
  );
}

type ConstraintFieldsTableProps<EntityModel> = Omit<EntityFieldInputProps<EntityModel>, 'field'> & {
  fields: EntityField<EntityModel>[];
  compact?: boolean;
};

const constraintFieldsTableStyles = {
  root: {
    '&+& .h2o-ConstraintFieldsTable-tableHeader': {
      display: 'none',
    },
  },
  tableHeader: {
    ...defaultEntityFormRowStyles,
    ...defaultConstraintHeaderRowStyles,
  },
};

export function ConstraintFieldsTable<EntityModel>(props: ConstraintFieldsTableProps<EntityModel>) {
  const { entityType, fields, model, onChange, disabled } = props;
  const hasCumulativeMax = useMemo(() => {
    return fields.some(
      (field) => field.constraintType && constraintTypeWithCumulativeMax.includes(field.constraintType)
    );
  }, [fields]);
  const classNames = useClassNames<any, any>('ConstraintFieldsTable', constraintFieldsTableStyles);

  return (
    <Stack className={classNames.root}>
      <Stack horizontal className={classNames.tableHeader}>
        <ConstraintFieldsTableHeaderLabel label="Min" />
        <ConstraintFieldsTableHeaderLabel label="No limit" rootStyles={{ minWidth: 70 }} />
        <ConstraintFieldsTableHeaderLabel label="Max" />
        <ConstraintFieldsTableHeaderLabel label="Default" />
        {hasCumulativeMax && (
          <>
            <ConstraintFieldsTableHeaderLabel label="No cumulative limit" />
            <ConstraintFieldsTableHeaderLabel label="Cumulative max" />
          </>
        )}
      </Stack>
      {fields.map((field) => (
        <Fragment key={String(field.name)}>
          <ConstraintSetModelField<EntityModel>
            field={field}
            model={model}
            onChange={disabled ? () => null : onChange}
            entityType={entityType}
            disabled={disabled}
          />
        </Fragment>
      ))}
    </Stack>
  );
}
