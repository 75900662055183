import { IComboBox, IComboBoxOption } from '@fluentui/react';
import { ComboBox, DatePicker } from '@h2oai/ui-kit';
import { useState } from 'react';

import GaugeMetricPlot from './components/MLOps/GaugeMetricPlot/GaugeMetricPlot';
import GaugeMetricTable from './components/MLOps/GaugeMEtricTable/GaugeMetricTable';
import {
  comboBoxStyle,
  endDatePickerStyle,
  menuItemStyle,
  mlopsSummaryTopStyles,
  mlopsTopStyles,
  startDatePickerStyle,
  summaryDatePickerAreaStyle,
  summaryDatePickerStyle,
} from './components/MLOps/MLOps.styles';
import { MLOpsGaugeType, MLOpsGaugeTypeKey } from './constants';
import PageWrapper from './PageWrapper';

const metricOptions: IComboBoxOption[] = [
  {
    key: MLOpsGaugeTypeKey.user,
    text: MLOpsGaugeType.user,
  },
  {
    key: MLOpsGaugeTypeKey.project,
    text: MLOpsGaugeType.project,
  },
  {
    key: MLOpsGaugeTypeKey.dataset,
    text: MLOpsGaugeType.dataset,
  },
  {
    key: MLOpsGaugeTypeKey.experiment,
    text: MLOpsGaugeType.experiment,
  },
  {
    key: MLOpsGaugeTypeKey.registeredModel,
    text: MLOpsGaugeType.registeredModel,
  },
  {
    key: MLOpsGaugeTypeKey.registeredModelVersion,
    text: MLOpsGaugeType.registeredModelVersion,
  },
  {
    key: MLOpsGaugeTypeKey.deployment,
    text: MLOpsGaugeType.deployment,
  },
];

export default function GaugeMetricEvent() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  sevenDaysAgo.setHours(0, 0, 0, 0);

  const [selectedMetricKeys, setSelectedMetricKeys] = useState<string[]>([]);
  const [selectedTexts, setSelectedTexts] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date>(today);
  const [summaryDate, setSummaryDate] = useState<Date>(today);

  const handleStartDateChange = (date: Date | null | undefined) => {
    if (date) {
      setStartDate(date);
    }
  };
  const handleEndDateChange = (date: Date | null | undefined) => {
    if (date) {
      setEndDate(date);
    }
  };
  const handleSummaryDateChange = (date: Date | null | undefined) => {
    if (date) {
      setSummaryDate(date);
    }
  };
  const onMetricChange = (_event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option) {
      const { key, selected, text } = option;

      if (selected) {
        // Add selected key and text
        setSelectedMetricKeys((prevKeys) => [...prevKeys, key as string]);
        setSelectedTexts((prevTexts) => [...prevTexts, text]);
      } else {
        // Remove unselected key and text
        setSelectedMetricKeys((prevKeys) => prevKeys.filter((k) => k !== key));
        setSelectedTexts((prevTexts) => prevTexts.filter((t) => t !== text));
      }
    }
  };
  return (
    <PageWrapper>
      <div style={mlopsTopStyles}>
        <div style={menuItemStyle}>
          <ComboBox
            multiSelect={true}
            placeholder="Select Gauge Type"
            options={metricOptions}
            selectedKey={selectedMetricKeys}
            onChange={onMetricChange}
            required={true}
            style={comboBoxStyle}
          />
          <DatePicker
            placeholder="Start date"
            style={startDatePickerStyle}
            value={startDate}
            onSelectDate={handleStartDateChange}
          />
          <DatePicker
            placeholder="End date"
            style={endDatePickerStyle}
            value={endDate}
            onSelectDate={handleEndDateChange}
          />
        </div>
      </div>
      <div>
        <GaugeMetricPlot
          startDate={startDate.toISOString()}
          endDate={endDate.toISOString()}
          gaugeType={selectedTexts}
        />
      </div>
      <div style={mlopsSummaryTopStyles}>
        <div>
          <h3>Total Count View</h3>
        </div>
        <div style={summaryDatePickerAreaStyle}>
          <DatePicker
            placeholder="Select date"
            style={summaryDatePickerStyle}
            value={summaryDate}
            onSelectDate={handleSummaryDateChange}
          />
        </div>
      </div>
      <div>
        <GaugeMetricTable date={summaryDate.toISOString()} />
      </div>
    </PageWrapper>
  );
}
