import { Image } from '@h2oai/ui-kit';
import { CSSProperties } from 'react';

import { useEnv } from '../../utils/hooks';

interface RenderNodeProps {
  style?: CSSProperties;
}

export const ProductLogo = ({ style }: RenderNodeProps) => {
  const env = useEnv();

  if (!env?.logoUrl) return <Image src="/logo.svg" alt="Logo" style={style} />;

  const trimmedString = env?.logoUrl.trim();

  // Check if it's an SVG string
  if (trimmedString.includes('<svg')) {
    return <span dangerouslySetInnerHTML={{ __html: env?.logoUrl }} />;
  }

  // Check if it's an image URL (PNG, JPG, etc.)
  if (/\.(png|jpe?g|gif|webp|bmp|ico|svg)$/i.test(env?.logoUrl)) {
    return <Image src={env?.logoUrl} alt="Logo" style={style} />;
  }

  return <span>Unknown node type</span>;
};
