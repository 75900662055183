import { BasicTable, useTheme } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import {
  GetFwCommitHistoryRequest,
  GetFwCommitHistoryResponse,
} from '../../../../../admin-center/gen/fw_management/fw_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { TableBackgroundStyle } from '../FWManagement.styles';

export default function CommitHistoryTable() {
  const service = useAdminCenterService();
  const theme = useTheme();
  const [commitHistory, setCommitHistory] = useState<GetFwCommitHistoryResponse | null>(null);
  const fetchFwCommitHistory = async () => {
    try {
      const request: GetFwCommitHistoryRequest = {};

      const response = await service.getFwCommitHistory(request);
      setCommitHistory(response);
    } catch (error) {
      console.error('Error fetching login statistics:', error);
    }
  };

  useEffect(() => {
    fetchFwCommitHistory();
  }, []);

  const formatTimestamp = (timestamp?: number) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp * 1000).toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const ipData =
    commitHistory?.commits
      ?.sort((a, b) => (b.timestamp ?? 0) - (a.timestamp ?? 0))
      .map((commit) => ({
        author: commit.modifiedBy,
        timeStamp: formatTimestamp(commit.timestamp),
        addedIps: (commit.ingressExtended ?? []).join(', '),
        removedIps: (commit.ingressPurged ?? []).join(', '),
        addedPorts: (commit.portsExtended ?? []).join(', '),
        removedPorts: (commit.portsPurged ?? []).join(', '),
      })) ?? [];

  return (
    <div style={TableBackgroundStyle(theme)}>
      <BasicTable
        columns={[
          {
            key: 'author',
            fieldName: 'author',
            name: 'Author',
            minWidth: 250,
          },
          {
            key: 'timeStamp',
            fieldName: 'timeStamp',
            name: 'Timestamp',
            minWidth: 200,
          },
          {
            key: 'addedIps',
            fieldName: 'addedIps',
            name: 'IPs Added',
            minWidth: 150,
          },
          {
            key: 'removedIps',
            fieldName: 'removedIps',
            name: 'IPs Removed',
            minWidth: 150,
          },
          {
            key: 'addedPorts',
            fieldName: 'addedPorts',
            name: 'Ports Added',
            minWidth: 150,
          },
          {
            key: 'removedPorts',
            fieldName: 'removedPorts',
            name: 'Ports Removed',
            minWidth: 150,
          },
        ]}
        items={ipData}
      />
    </div>
  );
}
