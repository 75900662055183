import { Stack } from '@fluentui/react';
import { IPivotItem, PageHeader, Pivot, useAppNavigationContext } from '@h2oai/ui-kit';
import { useEffect } from 'react';

import { daiEngineProfileEntity } from '../../aiem/entity/DAIEngineProfile.entity';
import { daiEngineVersionEntity } from '../../aiem/entity/DAIEngineVersion.entity';
import { h2oEngineProfileEntity } from '../../aiem/entity/H2OEngineProfile.entity';
import { h2oEngineVersionEntity } from '../../aiem/entity/H2OEngineVersion.entity';
import { notebookEngineImageEntity } from '../../aiem/entity/NotebookEngineImageEntity';
import { notebookEngineProfileEntity } from '../../aiem/entity/NotebookEngineProfile.entity';
import { AIEMSettingsEntitiesMap } from '../../aiem/entity/services';
import { EntityType } from '../../aiem/entity/types';
import { DAIEngineProfile } from '../../aiem/gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import { DAIEngineVersion } from '../../aiem/gen/ai/h2o/engine/v1/dai_engine_version_pb';
import { H2OEngineProfile } from '../../aiem/gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import { H2OEngineVersion } from '../../aiem/gen/ai/h2o/engine/v1/h2o_engine_version_pb';
import { NotebookEngineImage } from '../../aiem/gen/ai/h2o/engine/v1/notebook_engine_image_pb';
import { NotebookEngineProfile } from '../../aiem/gen/ai/h2o/engine/v1/notebook_engine_profile_pb';
import { AIEMUserActionType } from '../../aiem/userActions';
import { stackStylesNoLeftNav } from '../../themes/themes';
import { NotebookEntityDataContainer } from '../NotebookAdminSettingsPage/components/NotebookEntityDataContainer';
import EntityDataContainer from './components/EntityDataContainer';
import { ListWithCollapsedSettings } from './components/ListWithCollapsedSettings/ListWithCollapsedSettings';

const DAIEngineProfileActions = [
  AIEMUserActionType.DAI_ENGINE_PROFILES_CREATE,
  AIEMUserActionType.DAI_ENGINE_PROFILES_GET,
  AIEMUserActionType.DAI_ENGINE_PROFILES_LIST,
  AIEMUserActionType.DAI_ENGINE_PROFILES_UPDATE,
  AIEMUserActionType.DAI_ENGINE_PROFILES_DELETE,
];
const H2OEngineProfileActions = [
  AIEMUserActionType.H2O_ENGINE_PROFILES_CREATE,
  AIEMUserActionType.H2O_ENGINE_PROFILES_GET,
  AIEMUserActionType.H2O_ENGINE_PROFILES_LIST,
  AIEMUserActionType.H2O_ENGINE_PROFILES_UPDATE,
  AIEMUserActionType.H2O_ENGINE_PROFILES_DELETE,
];
const NotebookEngineProfileActions = [
  AIEMUserActionType.NOTEBOOK_ENGINE_PROFILES_CREATE,
  AIEMUserActionType.NOTEBOOK_ENGINE_PROFILES_GET,
  AIEMUserActionType.NOTEBOOK_ENGINE_PROFILES_LIST,
  AIEMUserActionType.NOTEBOOK_ENGINE_PROFILES_UPDATE,
  AIEMUserActionType.NOTEBOOK_ENGINE_PROFILES_DELETE,
];

const DAIEngineVersionActions = [
  AIEMUserActionType.DAI_ENGINE_VERSION_CREATE,
  AIEMUserActionType.DAI_ENGINE_VERSION_GET,
  AIEMUserActionType.DAI_ENGINE_VERSION_LIST,
  AIEMUserActionType.DAI_ENGINE_VERSION_UPDATE,
  AIEMUserActionType.DAI_ENGINE_VERSION_DELETE,
];
const H2OEngineVersionActions = [
  AIEMUserActionType.H2O_ENGINE_VERSION_CREATE,
  AIEMUserActionType.H2O_ENGINE_VERSION_GET,
  AIEMUserActionType.H2O_ENGINE_VERSION_LIST,
  AIEMUserActionType.H2O_ENGINE_VERSION_UPDATE,
  AIEMUserActionType.H2O_ENGINE_VERSION_DELETE,
];

const NotebookEngineImageActions = [
  AIEMUserActionType.NOTEBOOK_ENGINE_IMAGES_CREATE,
  AIEMUserActionType.NOTEBOOK_ENGINE_IMAGES_GET,
  AIEMUserActionType.NOTEBOOK_ENGINE_IMAGES_LIST,
  AIEMUserActionType.NOTEBOOK_ENGINE_IMAGES_UPDATE,
  AIEMUserActionType.NOTEBOOK_ENGINE_IMAGES_DELETE,
];

export default function AIEMAdminSettingsPage() {
  const { enableWorkspacesDropdown, disableWorkspacesDropdown } = useAppNavigationContext();

  const items: IPivotItem[] = [
    {
      key: `pivot-item-content-default-dai-engine-profile`,
      headerText: 'DAI Engine Profiles',
      content: (
        <ListWithCollapsedSettings<DAIEngineProfile>
          entity={daiEngineProfileEntity}
          userActions={DAIEngineProfileActions}
        />
      ),
      loading: false,
    },
    {
      key: `pivot-item-content-default-h2o-engine-profile`,
      headerText: 'H2O Engine Profiles',
      content: (
        <ListWithCollapsedSettings<H2OEngineProfile>
          entity={h2oEngineProfileEntity}
          userActions={H2OEngineProfileActions}
        />
      ),
      loading: false,
    },
    {
      key: `pivot-item-content-default-notebook-engine-profile`,
      headerText: 'Notebook Engine Profiles',
      content: (
        <ListWithCollapsedSettings<NotebookEngineProfile>
          entity={notebookEngineProfileEntity}
          userActions={NotebookEngineProfileActions}
        />
      ),
      loading: false,
    },
    {
      key: `pivot-item-content-dai-engine-version`,
      headerText: 'DAI Engine Version',
      content: (
        <EntityDataContainer<DAIEngineVersion, EntityType>
          apiKey="aiEngineManagerApiUrl"
          entity={daiEngineVersionEntity}
          entitiesMap={AIEMSettingsEntitiesMap}
          activeWorkspaceName="workspaces/global"
          userActions={DAIEngineVersionActions}
        />
      ),
      loading: false,
    },
    {
      key: `pivot-item-content-h2o-engine-version`,
      headerText: 'H2O Engine Version',
      content: (
        <EntityDataContainer<H2OEngineVersion, EntityType>
          apiKey="aiEngineManagerApiUrl"
          entity={h2oEngineVersionEntity}
          entitiesMap={AIEMSettingsEntitiesMap}
          activeWorkspaceName="workspaces/global"
          userActions={H2OEngineVersionActions}
        />
      ),
      loading: false,
    },
    {
      key: `pivot-item-content-notebook-engine-image`,
      headerText: 'Notebook Engine Image',
      content: (
        <NotebookEntityDataContainer<NotebookEngineImage>
          entity={notebookEngineImageEntity}
          apiKey="aiEngineManagerApiUrl"
          userActions={NotebookEngineImageActions}
        />
      ),
    },
  ];

  useEffect(() => {
    enableWorkspacesDropdown();
    return disableWorkspacesDropdown;
  }, []);

  return (
    <Stack styles={stackStylesNoLeftNav}>
      <Stack horizontal horizontalAlign="space-between" style={{ marginBottom: '20px' }}>
        <Stack.Item disableShrink>
          <PageHeader pageTitle="AI Engine Manager Admin Settings" />
        </Stack.Item>
      </Stack>
      <Pivot items={items}></Pivot>
    </Stack>
  );
}
