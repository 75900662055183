import { IComboBoxOption } from '@fluentui/react';
import { IconName, NextPageTokens, PaginatedSearchComboBox } from '@h2oai/ui-kit';

import type { Group } from '../../../../authz/gen/ai/h2o/user/v1/group_pb';
import type { User } from '../../../../authz/gen/ai/h2o/user/v1/user_pb';
import { useAuthzService } from '../../../../authz/hooks';
import { MembersDropdownProps } from './types';

const PAGE_SIZE = 1000;

const dataToOptions = (data?: User[] | Group[], isGroup = false): IComboBoxOption[] => {
  return (
    data?.map((group) => ({
      key: group.name || '',
      text: group.displayName || '',
      data: isGroup ? { ...group, icon: IconName.Group } : group,
    })) || []
  );
};

export const MembersDropdown = (props: MembersDropdownProps) => {
  const { onUserChange, prepopulatedUser, disableOptions, fetchGroups = false } = props;
  const authzService = useAuthzService();
  const fetchUsers = async (
    searchQuery: string,
    nextPageToken: NextPageTokens
  ): Promise<{ items: IComboBoxOption[]; nextPageToken: NextPageTokens }> => {
    const requestPayload = { pageSize: PAGE_SIZE, filter: `"${searchQuery}"` };

    const membersRequest = authzService.getUsers({ ...requestPayload, pageToken: nextPageToken['getUsers'] });
    const groupsRequest = fetchGroups
      ? authzService.getGroups({ ...requestPayload, pageToken: nextPageToken['getGroups'] })
      : Promise.resolve(null);
    const [members, memberGroups] = await Promise.all([membersRequest, groupsRequest]);

    const userItems = dataToOptions(members.users);
    const groupsItems = memberGroups ? dataToOptions(memberGroups.groups, true) : [];

    return {
      items: [...groupsItems, ...userItems],
      nextPageToken:
        members?.nextPageToken || memberGroups?.nextPageToken
          ? {
              ...(members?.nextPageToken ? { getUsers: members?.nextPageToken } : {}),
              ...(memberGroups?.nextPageToken ? { getGroups: memberGroups?.nextPageToken } : {}),
            }
          : '',
    };
  };

  return (
    <PaginatedSearchComboBox
      fetchItems={fetchUsers}
      placeholder="Select a user"
      prepopulatedItem={
        prepopulatedUser
          ? {
              key: prepopulatedUser.name || '',
              text: prepopulatedUser.displayName || '',
              data: prepopulatedUser,
            }
          : undefined
      }
      disableOptions={disableOptions}
      onOptionChange={onUserChange}
    />
  );
};
