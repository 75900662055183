import { Stack, Text } from '@fluentui/react';
import { Button, FontSizes, FontWeights, buttonStylesPrimary, useHaicPageTitle, useTheme } from '@h2oai/ui-kit';
import { type ReactElement, useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { ErrorCode, getHTTPResponse } from '../services/HTTPResponse';
import { useEnv } from '../utils/hooks';

export interface ErrorPageProps {
  code?: ErrorCode;
  message?: string;
  pageTitle?: string;
  imageSrc?: string;
  actionButtons?: ReactElement;
  customHeader?: string;
}

function ErrorPage({
  pageTitle = 'An Error Occurred',
  code,
  message,
  imageSrc,
  actionButtons,
  customHeader,
}: ErrorPageProps) {
  if (!code) {
    code = ErrorCode.Unknown;
    message = 'invalid format';
  }
  const theme = useTheme(),
    httpResponse = getHTTPResponse({ code, message }),
    { status } = httpResponse,
    image = imageSrc
      ? imageSrc
      : [404, 401].includes(httpResponse.status!)
      ? `${httpResponse.status!.toString()}.svg`
      : '500.svg',
    env = useEnv();

  useHaicPageTitle(pageTitle, env?.cloudInstanceName);
  return (
    <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { paddingTop: 150 } }}>
      <Stack>
        <Stack
          styles={{
            root: {
              padding: 4,
              marginBottom: 8,
              backgroundColor: theme.semanticColors?.buttonPrimaryBackground,
              width: 'max-content',
              height: 37,
            },
          }}
        >
          <Text styles={{ root: { fontSize: FontSizes.xlarge, fontWeight: FontWeights.bold } }}>
            {customHeader || `${status} Error`}
          </Text>
        </Stack>
        <Stack
          styles={{ root: { fontSize: FontSizes.xlarge, fontWeight: FontWeights.normal, whiteSpace: 'pre-line' } }}
        >
          {message || httpResponse?.message}
        </Stack>
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          styles={{
            root: { paddingTop: '15px', paddingBottom: '15px', height: '40vh', minHeight: '400px', maxWidth: '60vw' },
          }}
        >
          <img alt={'Error'} src={`/${image}`} style={{ height: '100%' }}></img>
        </Stack>
        <Stack horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 16 }}>
          {actionButtons ? (
            actionButtons
          ) : (
            <Button
              styles={buttonStylesPrimary}
              text="Return home"
              href="/"
              data-test={`${code}-error-page-return-home-button`}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export const Error403Page = () => {
  const auth = useAuth();
  const logout = useCallback(async () => {
    await auth.removeUser();
    await auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
  }, []);

  return (
    <ErrorPage
      pageTitle="403 Error"
      code={ErrorCode.PermissionDenied}
      message="It seems like you need permission to view this page."
      imageSrc="403.svg"
      actionButtons={<Button onClick={logout}>Logout</Button>}
    />
  );
};
export const Error404Page = () => (
  <ErrorPage
    pageTitle="404 Error"
    code={ErrorCode.NotFound}
    message="Sorry, we can't seem to find what you are looking for."
    imageSrc="404.svg"
  />
);
export const Error500Page = () => (
  <ErrorPage
    pageTitle="500 Error"
    code={ErrorCode.Internal}
    message="Internal server error. Please refresh this page or try again later."
    imageSrc="500.svg"
  />
);
export const NotFoundPage = () => {
  const [errorMessage, setErrorMessage] = useState<string>(
    'We searched high and low, but we could not find what you are looking for.'
  );
  useEffect(() => {
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());

    const notFoundInstance = queryParams?.instance_id;
    if (notFoundInstance) console.warn(`Instance ${notFoundInstance} not found`);

    const notFoundAlias = queryParams?.alias;
    if (notFoundAlias) console.warn(`Alias ${notFoundAlias} not found`);

    if (notFoundAlias || notFoundInstance) {
      setErrorMessage('We searched high and low, but the app instance could not be found.');
    }
  }, [window.location.search]);

  return (
    <ErrorPage
      pageTitle={errorMessage}
      code={ErrorCode.NotFound}
      message={`${errorMessage}`}
      imageSrc="noResults.svg"
    />
  );
};
export const OopsPage = () => {
  const refreshPage = () => window.location.reload();
  return (
    <ErrorPage
      pageTitle="Error"
      code={ErrorCode.Oops}
      message={`
        We’re sorry, but something didn’t go as expected.
        
        Don't worry—we’re most likely already working on it!
        Try refreshing the page, or check back in a bit.
      `}
      imageSrc="500.svg"
      customHeader="Oops, something went wrong"
      actionButtons={
        <Button onClick={refreshPage} styles={buttonStylesPrimary}>
          Refresh page
        </Button>
      }
    />
  );
};

export default ErrorPage;
