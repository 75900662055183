import { IColumn } from '@fluentui/react';
import { ListCell } from '@h2oai/ui-kit';

import { onRenderBooleanCell } from '../../utils/render';
import { DAIEngineProfile, DAIEngineProfile_ConfigEditability } from '../gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import {
  CreateDAIEngineProfileRequest,
  CreateDAIEngineProfileResponse,
  DAIEngineProfileService_CreateDAIEngineProfile,
  DAIEngineProfileService_DeleteDAIEngineProfile,
  DAIEngineProfileService_GetDAIEngineProfile,
  DAIEngineProfileService_ListDAIEngineProfiles,
  DAIEngineProfileService_UpdateDAIEngineProfile,
  DeleteDAIEngineProfileRequest,
  DeleteDAIEngineProfileResponse,
  GetDAIEngineProfileRequest,
  GetDAIEngineProfileResponse,
  ListDAIEngineProfilesRequest,
  ListDAIEngineProfilesResponse,
  UpdateDAIEngineProfileRequest,
  UpdateDAIEngineProfileResponse,
} from '../gen/ai/h2o/engine/v1/dai_engine_profile_service_pb';
import { BigIntString } from '../gen/runtime';
import { engineProfileBaseFields, engineProfileFieldsConfig } from './constants';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';
import { onRender, onRenderDisplayAndId } from './utils';

const entityType = EntityType.DAIEngineProfile;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateDAIEngineProfile',
    rpc: DAIEngineProfileService_CreateDAIEngineProfile,
    type: EntityActionType.Create,
    responseKey: 'daiEngineProfile',
    requestNameKey: 'daiEngineProfileId',
    requestPayloadKey: 'daiEngineProfile',
    requestNameKeyPrefix: 'daiEngineProfile/',
    entityType,
  } as EntityAction<DAIEngineProfile, CreateDAIEngineProfileRequest, CreateDAIEngineProfileResponse>,
  [EntityActionType.Get]: {
    name: 'GetDAIEngineProfile',
    rpc: DAIEngineProfileService_GetDAIEngineProfile,
    type: EntityActionType.Get,
    requestNameKey: 'name' as unknown,
    responseKey: 'daiEngineProfile',
    entityType,
  } as EntityAction<DAIEngineProfile, GetDAIEngineProfileRequest, GetDAIEngineProfileResponse>,
  [EntityActionType.List]: {
    name: 'ListDAIEngineProfile',
    rpc: DAIEngineProfileService_ListDAIEngineProfiles,
    type: EntityActionType.List,
    responseKey: 'daiEngineProfiles',
    entityType,
  } as EntityAction<DAIEngineProfile, ListDAIEngineProfilesRequest, ListDAIEngineProfilesResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateDAIEngineProfile',
    rpc: DAIEngineProfileService_UpdateDAIEngineProfile,
    type: EntityActionType.Update,
    responseKey: 'daiEngineProfile',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'daiEngineProfile',
    entityType,
  } as EntityAction<DAIEngineProfile, UpdateDAIEngineProfileRequest, UpdateDAIEngineProfileResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteDAIEngineProfile',
    rpc: DAIEngineProfileService_DeleteDAIEngineProfile,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<DAIEngineProfile, DeleteDAIEngineProfileRequest, DeleteDAIEngineProfileResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'icon',
    name: 'Icon',
    minWidth: 50,
    maxWidth: 50,
    onRender: () => <ListCell styles={{ root: { paddingLeft: 12 } }} iconProps={{ iconName: 'Settings' }} />,
  },
  {
    key: 'displayName',
    name: 'Display Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 250,
    onRender: onRenderDisplayAndId,
  },
  {
    key: 'priority',
    name: 'Priority',
    fieldName: 'priority',
    minWidth: 80,
    maxWidth: 120,
    onRender,
  },
  {
    key: 'enabled',
    name: 'Enabled',
    fieldName: 'enabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

const engineProfileFields = engineProfileFieldsConfig('DAI');

export const fields: EntityField<DAIEngineProfile>[] = [
  ...engineProfileBaseFields('DAI'),
  engineProfileFields.storageBytesConstraint,
  engineProfileFields.maxIdleDurationConstraint,
  engineProfileFields.maxRunningDurationConstraint,
  engineProfileFields.maxNonInteractionDuration,
  engineProfileFields.maxUnusedDuration,
  engineProfileFields.maxRunningEngines,
  engineProfileFields.tritonEnabled,
  engineProfileFields.configEditability,
  engineProfileFields.baseConfiguration,
  engineProfileFields.configurationOverride,
  engineProfileFields.yamlPodTemplateSpec,
  engineProfileFields.yamlGpuTolerations,
  {
    name: 'gpuResourceName',
    type: EntityFieldType.Text,
    label: 'GPU Resource Name',
    description: 'Name of the Kubernetes resource name for GPUs.',
    inputDefaultValue: 'nvidia.com/gpu',
  },
  {
    name: 'dataDirectoryStorageClass',
    type: EntityFieldType.Text,
    label: 'Storage Class Name',
    description: `
      Name of the Kubernetes StorageClass to be used. 
      Defaults to the default storage class of the Kubernetes cluster.
    `,
  },
];

export const daiEngineProfileEntity: Entity<DAIEngineProfile> = {
  type: EntityType.DAIEngineProfile,
  name: 'daiEngineProfiles',
  createButtonLabel: 'Add DAI Engine Profile',
  displayName: 'DAI Engine Profile',
  displayNameKey: 'displayName',
  actions,
  columns,
  fields,
  requestNameKeyPrefix: 'workspaces/global/daiEngineProfiles/',
  emptyModel: {
    name: 'new-dai-engine-profile-id',
    displayName: 'New DAI Engine Profile',
    priority: 0,
    enabled: true,
    assignedOidcRolesEnabled: false,
    assignedOidcRoles: [],
    maxRunningEngines: 0,
    cpuConstraint: {
      min: '1' as BigIntString,
      default: '2' as BigIntString,
    },
    gpuConstraint: {
      min: '0' as BigIntString,
      default: '0' as BigIntString,
    },
    memoryBytesConstraint: {
      min: '4294967296' as BigIntString,
      default: '8589934592' as BigIntString,
    },
    storageBytesConstraint: {
      min: '4294967296' as BigIntString,
      default: '8589934592' as BigIntString,
    },
    maxIdleDurationConstraint: {
      min: '0s' as BigIntString,
      default: '3600s' as BigIntString,
    },
    maxRunningDurationConstraint: {
      min: '0s' as BigIntString,
      default: '432000s' as BigIntString,
    },
    maxNonInteractionDuration: '1209600s',
    maxUnusedDuration: '1209600s',
    configurationOverride: {},
    baseConfiguration: {},
    configEditability: DAIEngineProfile_ConfigEditability.FULL,
    yamlPodTemplateSpec: '',
    yamlGpuTolerations: '',
    tritonEnabled: false,
  },
  canBeReordered: false,
};
