import { IComboBox, IComboBoxOption } from '@fluentui/react';
import {
  Button,
  ComboBox,
  Item,
  Loader,
  LoaderType,
  Panel,
  Slider,
  buttonStylesGhost,
  buttonStylesPrimary,
  itemStylesCategory,
  useTheme,
} from '@h2oai/ui-kit';
import React, { useCallback, useEffect, useState } from 'react';

import {
  GetAIUnitAlertingRequest,
  UpdateAIUnitAlertingRequest,
} from '../../../../admin-center/gen/aiUnitAlerting/aiUnitAlerting_pb';
import { GetUserRequest } from '../../../../admin-center/gen/user_management/user_management_pb';
import { useAdminCenterService } from '../../../../admin-center/hooks';
import { ComboBoxStyles, LoaderContainerStyle, SidePanelButtonStyle } from './AIUnitConsumption.styles';
import { TimePicker } from './TimePicker';

interface IPPanelProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertMessageBar: (isSuccess: boolean) => void;
}

export default function AlertSidePanel({ isOpen, setOpen, setAlertMessageBar }: IPPanelProps) {
  const [loading, setLoading] = useState(true);
  const [requestProcessing, setRequestProcessing] = useState(false);
  const [hour, setHour] = useState<number>(0);
  const [min, setMin] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState(90);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const [userOptions, setUserOptions] = useState<IComboBoxOption[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IComboBoxOption[]>([]);
  const [isFormModified, setIsFormModified] = useState<boolean>(false);
  const [initialFormValues, setInitialFormValues] = useState<{
    hour: number | undefined;
    min: number | undefined;
    threshold: number | undefined;
    users: string[] | undefined;
  }>({
    hour: undefined,
    min: undefined,
    threshold: undefined,
    users: undefined,
  });
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const service = useAdminCenterService();
  const theme = useTheme();

  const handleTimeChange = (hours: number, minutes: number) => {
    setHour(hours);
    setMin(minutes);
  };
  const onChange = useCallback((value, _range, e) => {
    setSliderValue(value);
    const { name, value: inputValue } = e.target;
    console.warn('change', name, value, inputValue);
  }, []);

  const onChanged = useCallback((e, value) => {
    setSliderValue(value);
    const { name, value: inputValue } = e.target;
    console.warn('changed', name, value, inputValue);
  }, []);

  useEffect(() => {
    setFilteredUsers(
      userOptions.filter((userOption) => userOption.text.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [searchTerm]);

  useEffect(() => {
    const formModified = () =>
      (initialFormValues.hour && initialFormValues.hour !== hour) ||
      (initialFormValues.min && initialFormValues.min !== min) ||
      (initialFormValues.threshold && initialFormValues.threshold !== sliderValue) ||
      (initialFormValues.users && !areArraysEqual(initialFormValues.users, selectedPeople));

    setIsFormModified(formModified() || false);
  }, [hour, min, sliderValue, selectedPeople, initialFormValues]);

  const handleComboboxChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (event.type === 'blur' || !option) return;

    setSelectedPeople((prevSelected) => {
      if (option.selected) {
        return prevSelected.includes(option.key as string) ? prevSelected : [...prevSelected, option.key as string];
      } else {
        return prevSelected.filter((key) => key !== option.key);
      }
    });
  };

  const handleItemRemoved = (data: any, _: React.MouseEvent<unknown>) => {
    setSelectedPeople((prevSelected) => {
      return prevSelected.filter((key) => key !== data.email);
    });
  };

  const handleSubmitClick = useCallback(async () => {
    setRequestProcessing(true);
    try {
      const request: UpdateAIUnitAlertingRequest = {
        hour: hour,
        minute: min,
        timeZone: browserTimeZone ?? '',
        threshold: Number.isFinite(sliderValue) ? Math.round(sliderValue) : 90,
        users: selectedPeople ?? [],
      };
      const response = await service.updateAIUnitAlertingData(request);
      if (response.success) {
        setAlertMessageBar(true);
      } else {
        setAlertMessageBar(false);
      }
    } catch (error) {
      setAlertMessageBar(false);
    } finally {
      setRequestProcessing(false);
      setOpen(false);
    }
  }, [browserTimeZone, hour, min, sliderValue, selectedPeople]);

  const fetchUserData = async () => {
    try {
      const userReq: GetUserRequest = {};
      const userResponse = await service.getUsers(userReq);
      const users =
        userResponse.users?.map((user) => ({
          firstName: user.firstName ?? 'Unknown',
          lastName: user.lastName ?? 'Unknown',
          email: user.email ?? 'Unknown',
          id: user.id ?? 'Unknown',
          status: user.status ?? 'Unknown',
          login: user.login ?? 'Unknown',
        })) || [];
      const userOptions = users.map((user) => ({
        text: `${user.firstName} ${user.lastName} (${user.email})`,
        key: user.email,
        id: user.email,
      }));
      setUserOptions(userOptions);

      const emailReq: GetAIUnitAlertingRequest = {};
      const emailResponse = await service.getAIUnitAlertingData(emailReq);
      setHour(emailResponse.hour || 0);
      setMin(emailResponse.minute || 0);
      setSelectedPeople(emailResponse.users ?? []);
      setSliderValue(
        emailResponse.threshold !== undefined && Number.isFinite(emailResponse.threshold) ? emailResponse.threshold : 90
      );
      setInitialFormValues({
        hour: emailResponse.hour,
        min: emailResponse.minute,
        threshold: emailResponse.threshold,
        users: emailResponse.users,
      });
    } catch (error) {
      console.error('Error fetching User data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const onRenderFooterContent = useCallback(
    () => (
      <>
        <div style={SidePanelButtonStyle}>
          <Button
            styles={buttonStylesGhost}
            text="Cancel"
            onClick={() => {
              setOpen(false);
            }}
            style={{ marginRight: 10 }}
          />
          <Button
            styles={buttonStylesPrimary}
            text="Set Alert"
            onClick={handleSubmitClick}
            disabled={!isFormModified}
          />
        </div>
      </>
    ),
    [handleSubmitClick, isFormModified]
  );

  return (
    <>
      <Panel
        isLightDismiss
        isOpen={isOpen}
        onDismiss={() => {
          setOpen(false);
        }}
        closeButtonAriaLabel="Close"
        isFooterAtBottom={true}
        onRenderFooterContent={onRenderFooterContent}
        customWidth="500px"
        type={7}
      >
        <h2>
          <strong>AI Unit Alert</strong>
        </h2>
        {loading ? (
          <div>
            <Loader
              type={LoaderType.spinner}
              style={{ ...LoaderContainerStyle(theme), opacity: 1 }}
              label="Loading..."
            />
          </div>
        ) : (
          <>
            {requestProcessing && (
              <div style={LoaderContainerStyle(theme)}>
                <Loader type={LoaderType.spinner} label="Processing Request..." />
              </div>
            )}
            <TimePicker
              onChange={handleTimeChange}
              label={`Alert Time - ${browserTimeZone} (HH:MM)`}
              initialHours={hour}
              initialMinutes={min}
            />
            <br />
            <Slider
              label="AI Unit Consumption Threshold"
              value={sliderValue}
              min={0}
              max={100}
              onChange={onChange}
              onChanged={onChanged}
            />
            <br />
            <div>
              <ComboBox
                label="Alert Receivers"
                required
                multiSelect
                scrollSelectedToTop
                autoComplete={'on'}
                allowFreeform={true}
                persistMenu={true}
                openOnKeyboardFocus={true}
                onChange={(event, option) => {
                  handleComboboxChange(event, option);
                  setSearchTerm('');
                }}
                options={
                  filteredUsers.length > 0
                    ? filteredUsers
                    : [{ key: 'no-match', text: 'No matches found', disabled: true }]
                }
                placeholder="Search Users"
                selectedKey={selectedPeople}
                styles={ComboBoxStyles()}
                text={searchTerm}
                onInputValueChange={(value) => {
                  setSearchTerm(value);
                }}
              />
              {selectedPeople.map((selectedUser) => (
                <Item
                  key={selectedUser}
                  data={{
                    email: selectedUser,
                    hidden: false,
                    id: selectedUser,
                    style: {
                      backgroundColor: theme.palette?.gray200,
                      color: theme.palette?.gray800,
                      marginRight: '10px',
                      marginBottom: '8px',
                    },
                  }}
                  hasClose
                  idField="id"
                  labelField="email"
                  styleField="style"
                  styles={itemStylesCategory}
                  onClose={handleItemRemoved}
                  titleField="email"
                />
              ))}
            </div>
          </>
        )}
      </Panel>
    </>
  );
}

function areArraysEqual(a: string[] | undefined, b: string[] | undefined): boolean {
  if (!a || !b) return a === b;
  if (a.length !== b.length) return false;

  const countMap = (arr: string[]) =>
    arr.reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

  const mapA = countMap(a);
  const mapB = countMap(b);

  return Object.keys(mapA).every((key) => mapA[key] === mapB[key]);
}
