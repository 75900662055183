// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file user_management/user_management.proto (package user_management, syntax proto3)
/* eslint-disable */

import { RPC } from "../runtime";

/**
 * @generated from message user_management.GetUserRequest
 */
export type GetUserRequest = {
}
;
/**
 * @generated from message user_management.GetUserResponse
 */
export type GetUserResponse = {
/**
 * @generated from field: repeated user_management.UserProfile users = 1;
 */
users?: UserProfile[];
}
;
/**
 * @generated from message user_management.GetAdminRequest
 */
export type GetAdminRequest = {
}
;
/**
 * @generated from message user_management.GetAdminResponse
 */
export type GetAdminResponse = {
/**
 * @generated from field: repeated user_management.UserProfile admins = 1;
 */
admins?: UserProfile[];
}
;
/**
 * @generated from message user_management.UserProfile
 */
export type UserProfile = {
/**
 * @generated from field: string id = 1;
 */
id?: string;
/**
 * @generated from field: string email = 2;
 */
email?: string;
/**
 * @generated from field: string first_name = 3;
 */
firstName?: string;
/**
 * @generated from field: string last_name = 4;
 */
lastName?: string;
/**
 * @generated from field: string login = 5;
 */
login?: string;
/**
 * @generated from field: string status = 6;
 */
status?: string;
}
;
/**
 * @generated from message user_management.MemberInfo
 */
export type MemberInfo = {
/**
 * @generated from field: string first_name = 1;
 */
firstName?: string;
/**
 * @generated from field: string last_name = 2;
 */
lastName?: string;
/**
 * @generated from field: string email = 3;
 */
email?: string;
}
;
/**
 * @generated from message user_management.AddMemberRequest
 */
export type AddMemberRequest = {
/**
 * @generated from field: repeated user_management.MemberInfo members = 1;
 */
members?: MemberInfo[];
/**
 * @generated from field: string member_level = 2;
 */
memberLevel?: string;
/**
 * @generated from field: string modified_by = 3;
 */
modifiedBy?: string;
/**
 * @generated from field: double timestamp = 4;
 */
timestamp?: number;
}
;
/**
 * @generated from message user_management.AddMemberResponse
 */
export type AddMemberResponse = {
/**
 * @generated from field: string status_update = 1;
 */
statusUpdate?: string;
}
;
/**
 * @generated from message user_management.RemoveMemberRequest
 */
export type RemoveMemberRequest = {
/**
 * @generated from field: repeated user_management.MemberInfo members = 1;
 */
members?: MemberInfo[];
/**
 * @generated from field: string member_level = 2;
 */
memberLevel?: string;
/**
 * @generated from field: string modified_by = 3;
 */
modifiedBy?: string;
/**
 * @generated from field: double timestamp = 4;
 */
timestamp?: number;
}
;
/**
 * @generated from message user_management.RemoveMemberResponse
 */
export type RemoveMemberResponse = {
/**
 * @generated from field: string status_update = 1;
 */
statusUpdate?: string;
}
;
/**
 * @generated from message user_management.GetUmCommitHistoryRequest
 */
export type GetUmCommitHistoryRequest = {
}
;
/**
 * @generated from message user_management.GetUmCommitHistoryResponse
 */
export type GetUmCommitHistoryResponse = {
/**
 * @generated from field: repeated user_management.Commit commits = 1;
 */
commits?: Commit[];
}
;
/**
 * @generated from message user_management.ResendActivationEmailsRequest
 */
export type ResendActivationEmailsRequest = {
/**
 * @generated from field: repeated string userEmails = 1;
 */
userEmails?: string[];
}
;
/**
 * @generated from message user_management.ResendActivationEmailsResponse
 */
export type ResendActivationEmailsResponse = {
/**
 * @generated from field: bool isSuccess = 1;
 */
isSuccess?: boolean;
/**
 * @generated from field: repeated string failedEmails = 2;
 */
failedEmails?: string[];
}
;
/**
 * @generated from message user_management.Commit
 */
export type Commit = {
/**
 * @generated from field: string commit_id = 1;
 */
commitId?: string;
/**
 * @generated from field: string action = 2;
 */
action?: string;
/**
 * @generated from field: string group = 3;
 */
group?: string;
/**
 * @generated from field: string action_type = 4;
 */
actionType?: string;
/**
 * @generated from field: repeated string entry = 5;
 */
entry?: string[];
/**
 * @generated from field: string modified_by = 6;
 */
modifiedBy?: string;
/**
 * @generated from field: double timestamp = 7;
 */
timestamp?: number;
}
;
/**
 * @generated from rpc user_management.UserManagementService.GetUsers
 */
export const UserManagementService_GetUsers = new RPC<GetUserRequest,GetUserResponse>("GET", "/getUsers");
/**
 * @generated from rpc user_management.UserManagementService.GetAdmins
 */
export const UserManagementService_GetAdmins = new RPC<GetAdminRequest,GetAdminResponse>("GET", "/getAdmins");
/**
 * @generated from rpc user_management.UserManagementService.AddMembers
 */
export const UserManagementService_AddMembers = new RPC<AddMemberRequest,AddMemberResponse>("POST", "/addMembers");
/**
 * @generated from rpc user_management.UserManagementService.RemoveMembers
 */
export const UserManagementService_RemoveMembers = new RPC<RemoveMemberRequest,RemoveMemberResponse>("POST", "/removeMembers");
/**
 * @generated from rpc user_management.UserManagementService.GetUmCommitHistory
 */
export const UserManagementService_GetUmCommitHistory = new RPC<GetUmCommitHistoryRequest,GetUmCommitHistoryResponse>("GET", "/getUmCommitHistory");
/**
 * @generated from rpc user_management.UserManagementService.ResendActivationEmails
 */
export const UserManagementService_ResendActivationEmails = new RPC<ResendActivationEmailsRequest,ResendActivationEmailsResponse>("POST", "/resendActivationEmails");
