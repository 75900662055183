import { concatStyleSets } from '@fluentui/react';
import {
  Button,
  IButtonProps,
  IconName,
  buttonStylesSplit,
  buttonStylesSplitSecondary,
  buttonStylesSplitSmall as uiKitButtonStylesSplitSmall,
  useTheme,
} from '@h2oai/ui-kit';

import { restrictionTooltipMessage } from '../../../authz/providers/PermissionsProvider/PermissionsProvider';

export interface EntityModelItemActionButtonProps<EntityModel> {
  model: EntityModel;
  onEdit?: (model: EntityModel) => void;
  onDelete?: (model: EntityModel) => void;
  onSetLatest?: (model: EntityModel) => void;
  disableEdit: boolean;
  disableDelete: boolean;
}

const buttonStylesSplitSmall = concatStyleSets(uiKitButtonStylesSplitSmall, { root: { width: 60 } });

export function EntityModelItemActionButton<EntityModel>(props: EntityModelItemActionButtonProps<EntityModel>) {
  const { model, onEdit, onDelete, onSetLatest, disableEdit, disableDelete } = props;
  const { palette, semanticColors } = useTheme();
  const buttonProps: IButtonProps = {
    split: true,
    menuIconName: IconName.ChevronDown,
    primaryDisabled: disableEdit,
    tooltip: restrictionTooltipMessage(disableEdit),
  };
  const aliasesFieldName = 'aliases';
  const aliasesField = model[aliasesFieldName] as string[] | undefined;
  const canBeLatest = aliasesField && !aliasesField.includes('latest');

  return (
    <>
      <Button
        {...buttonProps}
        menuItems={[
          ...(canBeLatest
            ? [
                {
                  key: 'setLatest',
                  text: 'Set Latest',
                  onClick: () => onSetLatest!(model),
                  style: { color: semanticColors?.textPrimary, maxHeight: 32 },
                },
              ]
            : []),
          {
            key: 'delete',
            text: 'Delete',
            onClick: disableDelete ? () => null : () => onDelete!(model),
            iconProps: { iconName: 'Delete', style: { color: 'var(--h2o-red400)' } },
            style: { color: palette?.red500, maxHeight: 32, ...(disableDelete ? { opacity: 0.5 } : {}) },
            disabled: disableDelete,
          },
        ]}
        styles={[buttonStylesSplit, buttonStylesSplitSecondary, buttonStylesSplitSmall]}
        onClick={disableEdit ? () => null : () => onEdit!(model)}
        text="Edit"
      />
    </>
  );
}
