import { Deployment } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_pb';
import { ListProjectDeploymentsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_service_pb';
import { DeploymentService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_service_connect';
import {
  CreateModelIngestionRequest,
  CreateModelIngestionResponse,
} from '@buf/h2oai_mlops-model-ingestion.bufbuild_es/ai/h2o/mlops/ingest/v1/ingest_service_pb';
import { ModelParameters } from '@buf/h2oai_mlops-model-ingestion.bufbuild_es/ai/h2o/mlops/ingest/v1/ingestion_pb';
import { ModelIngest as ModelIngestService } from '@buf/h2oai_mlops-model-ingestion.connectrpc_es/ai/h2o/mlops/ingest/v1/ingest_service_connect';
import { Artifact } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_pb';
import {
  CreateArtifactRequest,
  CreateArtifactResponse,
  UpdateArtifactRequest,
  UpdateArtifactResponse,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_service_pb';
import { Metadata } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/entity_pb';
import { ExperimentParameters } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/experiment_pb';
import {
  BatchDeleteExperimentRequest,
  CreateExperimentResponse,
  DeleteExperimentRequest,
  GetExperimentRequest,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/experiment_service_pb';
import { KeySelection } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/listing_pb';
import { RegisteredModel } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_pb';
import {
  DeleteRegisteredModelRequest,
  GetRegisteredModelRequest,
  UpdateRegisteredModelRequest,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_service_pb';
import { RegisteredModelVersion } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_pb';
import {
  CreateModelVersionRequest,
  CreateModelVersionResponse,
  DeleteModelVersionRequest,
  ListModelVersionsForModelRequest,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_service_pb';
import { ArtifactService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/artifact_service_connect';
import { ExperimentService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/experiment_service_connect';
import { RegisteredModelService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_service_connect';
import { RegisteredModelVersionService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_version_service_connect';
import { createClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IStyle, MessageBarType } from '@fluentui/react';
import {
  Button,
  ClassNamesFromIStyles,
  Dropdown,
  FileUpload,
  IH2OTheme,
  Loader,
  TextField,
  buttonStylesLink,
  buttonStylesPrimary,
  buttonStylesStealth,
  useClassNames,
  useTheme,
} from '@h2oai/ui-kit';
import { useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import WidgetList, { WidgetListProps } from '../../components/WidgetList/WidgetList';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { formatError } from '../../utils/utils';
import { ContextMenuIconButton } from '../Orchestrator/Workflows';
import { getRandomAdjective } from './adjectives';
import { FormSection } from './BatchScoringDetail';
import { useConfirmDialog } from './ConfirmDialogProvider';
import { ENDPOINTS, ROUTES } from './constants';
import { capitalize } from './DeploymentTabDetail';
import ModelMetadataView from './ModelMetadataView';
import { HistoryState, RegisteredModelVersionItem, getDeploymentExperimentsIds } from './Models';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

export type ModelDetailNavParams = { project_id: string; model_id?: string };
type ModelDetailParams = { model_id: string; project_id: string; action?: string };

interface IModelDetailStyles {
  form: IStyle;
  loader: IStyle;
  toastActions: IStyle;
}
export const modelDetailStyles = (theme: IH2OTheme): Partial<IModelDetailStyles> => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 20,
    margin: '0px 40px',
  },
  loader: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 540,
    backgroundColor: theme.semanticColors?.bodyBackground,
  },
  toastActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    paddingTop: 6,
    paddingBottom: 6,
  },
});

const columns = [
  {
    key: 'spacer',
    name: '',
    minWidth: 60,
    maxWidth: 60,
    data: {
      listCellProps: {
        styles: {
          root: {
            marginLeft: 24,
          },
        },
      },
    },
  },
  {
    key: 'version',
    name: 'Version',
    fieldName: 'version',
    minWidth: 100,
    maxWidth: 150,
  },
  {
    key: 'user',
    name: 'Created by',
    fieldName: 'createdByName',
    minWidth: 150,
    maxWidth: 220,
  },
  {
    key: 'createdAt',
    name: 'Created at',
    fieldName: 'createdTimeLocal',
    minWidth: 150,
    maxWidth: 250,
  },
  {
    key: 'deployments',
    name: 'Active deployments',
    minWidth: 150,
    maxWidth: 150,
    fieldName: 'activeDeployments',
    data: {
      listCellProps: {
        onRenderText: ({ activeDeployments, onNavigateToDeploymentDetail }: RegisteredModelVersionItem) =>
          activeDeployments?.length ? (
            <div>
              <Dropdown
                placeholder={`${activeDeployments?.length} ${
                  activeDeployments?.length === 1 ? 'deployment' : 'deployments'
                }`}
                options={
                  activeDeployments?.map((deployment) => ({
                    key: deployment.id,
                    text: deployment.displayName,
                  })) || []
                }
                onRenderItem={(item) => (
                  <Button
                    menuIconProps={{
                      iconName: 'Forward',
                      // TODO: Theme color.
                      style: { fontSize: 12, color: 'black' },
                    }}
                    text={item?.text}
                    onClick={() => onNavigateToDeploymentDetail(`${item!.key}`)()}
                    styles={[
                      buttonStylesLink,
                      {
                        textContainer: {
                          color: 'black',
                          fontSize: 12,
                        },
                        flexContainer: {
                          selectors: {
                            '&:hover span': { color: 'var(--h2o-blue500)' },
                            '&:hover i': { color: 'var(--h2o-blue500) !important' },
                          },
                        },
                        root: { paddingLeft: 8, paddingRight: 8 },
                      },
                    ]}
                  />
                )}
                calloutProps={{
                  calloutMaxWidth: 220,
                  calloutMinWidth: 200,
                  styles: {
                    root: {
                      padding: '8px 0px',
                    },
                  },
                }}
                styles={{
                  title: {
                    border: 'none',
                    padding: '0px 35px 0px 0px',
                    fontSize: 12,
                  },
                  caretDownWrapper: {
                    i: {
                      fontSize: 12,
                    },
                  },
                }}
              />
            </div>
          ) : (
            'No deployments'
          ),
      },
    },
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: '',
        onRenderText: ({
          deleteModelVersion,
          viewOnly,
          onDeployModelVersion,
          onBatchScoreModelVersion,
        }: RegisteredModelVersionItem) =>
          // TODO: Use theme prop for colors.
          viewOnly ? null : (
            <ContextMenuIconButton
              items={[
                {
                  key: 'deploy',
                  text: 'Deploy version',
                  onClick: onDeployModelVersion,
                  iconProps: { iconName: 'PublishContent', style: { color: 'var(--h2o-blue500)' } },
                  style: { color: 'var(--h2o-blue500)' },
                },
                {
                  key: 'batchScore',
                  text: 'Batch score',
                  onClick: onBatchScoreModelVersion,
                  iconProps: { iconName: 'AssessmentGroup', style: { color: 'var(--h2o-yellow700)' } },
                  style: { color: 'var(--h2o-yellow700)' },
                },
                {
                  key: 'delete',
                  text: 'Delete version',
                  // TODO: Implement onClickDelete.
                  onClick: () => {
                    (async () => {
                      await deleteModelVersion?.();
                    })();
                  },
                  style: { color: 'var(--h2o-red400)', display: viewOnly ? 'none' : undefined },
                  iconProps: {
                    iconName: 'DeleteRows',
                    style: { color: 'var(--h2o-red400)' },
                  },
                },
              ]}
            />
          ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

const ModelDetail = () => {
  const history = useHistory(),
    theme = useTheme(),
    { showDialog } = useConfirmDialog(),
    params = useParams<ModelDetailParams>(),
    isNew = React.useMemo(() => params?.model_id === 'add-new', [params.model_id]),
    isNewVersionUpload = React.useMemo(() => params?.action === 'add-new-version', [params.action]),
    classNames = useClassNames<IModelDetailStyles, ClassNamesFromIStyles<IModelDetailStyles>>(
      'modelDetail',
      modelDetailStyles(theme)
    ),
    [version, setVersion] = React.useState<number>(1),
    [modelName, setModelName] = React.useState<string>(isNew ? `${capitalize(getRandomAdjective())} model` : ''),
    [modelDescription, setModelDescription] = React.useState<string>(''),
    [loadingMessage, setLoadingMessage] = React.useState<string>('Adding new model... Just a moment.'),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID, permissions } = useProjects(),
    [loading, setLoading] = React.useState(false),
    [artifactFile, setArtifactFile] = React.useState<File | null>(null),
    // TODO: Handle loading of all pages of deployments.
    [, setNextPageTokenDeployments] = React.useState<string>(),
    [nextPageTokenVersions, setNextPageTokenVersions] = React.useState<string>(),
    [isLoadingMoreVersions, setIsLoadingMoreVersions] = React.useState(false),
    [registeredModelVersionsItems, setRegisteredModelVersionsItems] = React.useState<RegisteredModelVersionItem[]>(),
    [deployments, setDeployments] = React.useState<Deployment[]>(),
    loadStateRef = React.useRef({
      createArtifact: false,
      uploadArtifact: false,
      createModelIngestion: false,
      createExperiment: false,
      updateArtifact: false,
      registerExperimentAsModel: false,
      updateRegisteredModel: false,
      fetchRegisteredModel: false,
      createModelVersion: false,
      countModelVersions: false,
      fetchModelVersions: false,
      deletingExperiments: false,
      fetchProjectDeployments: false,
    }),
    stepsCount = isNewVersionUpload ? 6 : 8,
    getLoadingStatusMessage = () => {
      if (loadStateRef.current.createArtifact) return `[1/${stepsCount}] Creating artifact...`;
      if (loadStateRef.current.uploadArtifact) return `[2/${stepsCount}] Uploading artifact...`;
      if (loadStateRef.current.createModelIngestion) return `[3/${stepsCount}] Ingesting model...`;
      if (loadStateRef.current.createExperiment) return `[4/${stepsCount}] Creating experiment...`;
      if (loadStateRef.current.updateArtifact) return `[5/${stepsCount}] Updating artifact...`;
      if (loadStateRef.current.createModelVersion) return '[6/6] Creating model version...';
      if (loadStateRef.current.registerExperimentAsModel) return '[6/8] Registering experiment as model...';
      if (loadStateRef.current.updateRegisteredModel) return `${isNew ? '[7/8] ' : ''}Updating registered model...`;
      if (loadStateRef.current.fetchModelVersions) return `${isNew ? '[8/8] ' : ''}Fetching model versions...`;
      if (loadStateRef.current.fetchRegisteredModel) return 'Fetching model data...';
      if (loadStateRef.current.countModelVersions) return 'Counting model versions...';
      if (loadStateRef.current.deletingExperiments) return 'Deleting model experiments...';
      if (loadStateRef.current.fetchProjectDeployments) return 'Fetching project deployments...';
      return 'Adding new model... Just a moment.';
    },
    evaluateLoading = () => {
      if (
        !loadStateRef.current.createArtifact &&
        !loadStateRef.current.uploadArtifact &&
        !loadStateRef.current.createModelIngestion &&
        !loadStateRef.current.createExperiment &&
        !loadStateRef.current.updateArtifact &&
        !loadStateRef.current.registerExperimentAsModel &&
        !loadStateRef.current.updateRegisteredModel &&
        !loadStateRef.current.fetchRegisteredModel &&
        !loadStateRef.current.createModelVersion &&
        !loadStateRef.current.countModelVersions &&
        !loadStateRef.current.deletingExperiments &&
        !loadStateRef.current.fetchProjectDeployments
      ) {
        setLoading(false);
      }
    },
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    ingestTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.ingest}/`,
    }),
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    deploymentClient = createClient(DeploymentService, deploymentTransport),
    experimentClient = createClient(ExperimentService, storageTransport),
    artifactClient = createClient(ArtifactService, storageTransport),
    registeredModelClient = createClient(RegisteredModelService, storageTransport),
    registerModelVersionClient = createClient(RegisteredModelVersionService, storageTransport),
    modelIngestionClient = createClient(ModelIngestService, ingestTransport),
    createExperiment = React.useCallback(
      async (metadata: Metadata, parameters: ModelParameters) => {
        loadStateRef.current.createExperiment = true;
        setLoading(true);
        setLoadingMessage(getLoadingStatusMessage());
        try {
          const response: CreateExperimentResponse = await experimentClient.createExperiment({
            experiment: {
              // TODO: Make sure it works even when last version is deleted and then recreated. Not supported by API yet.
              displayName: `${modelName}-v${version}`,
              description: modelDescription,
              metadata,
              parameters,
            },
            projectId: ACTIVE_PROJECT_ID,
          });
          return response?.experiment;
        } catch (err) {
          const message = `Failed to create experiment: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.createExperiment = false;
          evaluateLoading();
        }
      },
      [addToast, ACTIVE_PROJECT_ID, modelName, modelDescription, version]
    ),
    registerExperimentAsModel = React.useCallback(
      async (experimentId: string) => {
        loadStateRef.current.registerExperimentAsModel = true;
        setLoading(true);
        setLoadingMessage(getLoadingStatusMessage());
        try {
          /*
          Registering the experiment as a model is usually a two-step process:
          1. Registering the model - RegisteredModelService.createRegisteredModel
          2. Creating a model version - RegisteredModelVersionService.createModelVersion (with the registered model id)
          When omitting "registeredModelId" from second step, a new model will be created automatically (no need to do first step).
          */
          const modelVersionBody = new CreateModelVersionRequest({
            registeredModelVersion: new RegisteredModelVersion({
              experimentId,
              version,
            }),
            registeredModelDisplayName: modelName,
            projectId: ACTIVE_PROJECT_ID,
          });
          const response: CreateModelVersionResponse = await registerModelVersionClient.createModelVersion(
            modelVersionBody
          );
          return response?.registeredModelVersion?.registeredModelId;
        } catch (err) {
          const message = `Failed to register experiment as model: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.registerExperimentAsModel = false;
          evaluateLoading();
        }
      },
      [addToast, registerModelVersionClient, ACTIVE_PROJECT_ID, modelName, version]
    ),
    createModelIngestion = React.useCallback(
      async (artifactId: string) => {
        loadStateRef.current.createModelIngestion = true;
        setLoading(true);
        setLoadingMessage(getLoadingStatusMessage());
        try {
          const modelIngestionBody = new CreateModelIngestionRequest({
            artifactId,
          });
          const response: CreateModelIngestionResponse = await modelIngestionClient.createModelIngestion(
            modelIngestionBody
          );

          if (!response.ingestion) {
            const noResponseMsg = 'No ingestion found in the response.';
            console.error(noResponseMsg);
            throw new Error(noResponseMsg);
          } else if (!response.ingestion.modelMetadata || !response.ingestion.modelParameters) {
            const noMetadataMsg = 'No model metadata or parameters found in the ingestion response.';
            console.error(noMetadataMsg);
            throw new Error(noMetadataMsg);
          } else {
            return response?.ingestion;
          }
        } catch (err) {
          const message = `Failed to ingest model: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.createModelIngestion = false;
          evaluateLoading();
        }
      },
      [addToast]
    ),
    createArtifact = React.useCallback(async () => {
      loadStateRef.current.createExperiment = true;
      setLoading(true);
      setLoadingMessage(getLoadingStatusMessage());
      try {
        const artifact = new Artifact({ entityId: ACTIVE_PROJECT_ID, mimeType: 'application/zip' });
        const artifactRequestBody = new CreateArtifactRequest({
          artifact,
        });
        const response: CreateArtifactResponse = await artifactClient.createArtifact(artifactRequestBody);
        return response?.artifact;
      } catch (err) {
        const message = `Failed to create artifact: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        return undefined;
      } finally {
        loadStateRef.current.createArtifact = false;
        evaluateLoading();
      }
    }, [addToast, ACTIVE_PROJECT_ID]),
    updateArtifact = React.useCallback(
      async (artifact: Artifact) => {
        loadStateRef.current.updateArtifact = true;
        setLoading(true);
        setLoadingMessage(getLoadingStatusMessage());
        try {
          const artifactRequestBody = new UpdateArtifactRequest({
            artifact,
            updateMask: {
              paths: ['type,entity_id'],
            },
          });
          const response: UpdateArtifactResponse = await artifactClient.updateArtifact(artifactRequestBody);
          return response?.artifact;
        } catch (err) {
          const message = `Failed to update artifact: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.updateArtifact = false;
          evaluateLoading();
        }
      },
      [addToast]
    ),
    // Helper to chunk the file into Blobs.
    chunkFile = (file: File, chunkSize: number): Blob[] => {
      const chunks: Blob[] = [];
      let offset = 0;
      while (offset < file.size) {
        const chunk = file.slice(offset, offset + chunkSize);
        chunks.push(chunk);
        offset += chunkSize;
      }
      return chunks;
    },
    encodeBase64 = (uint8Array: Uint8Array): string => {
      let binary = '';
      for (let i = 0; i < uint8Array.byteLength; i++) {
        binary += String.fromCharCode(uint8Array[i]);
      }
      return btoa(binary);
    },
    // Function to stream file to Google ByteStream API.
    streamFileToGoogleByteStream = async (
      file: File,
      resourceName: string,
      baseUrl: string,
      chunkSize: number = 256 * 1024 // 256 KB per chunk
    ): Promise<void> => {
      loadStateRef.current.uploadArtifact = true;
      setLoading(true);
      setLoadingMessage(getLoadingStatusMessage());
      const chunks = chunkFile(file, chunkSize);
      let writeOffset = 0;

      const messages = [];
      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i];
        const isLastChunk = i === chunks.length - 1;

        // Prepare the data as a protobuf-compatible JSON request.
        messages.push({
          resourceName,
          writeOffset: writeOffset === 0 ? undefined : writeOffset,
          finishWrite: isLastChunk,
          data: encodeBase64(new Uint8Array(await chunk.arrayBuffer())), // Convert Blob to base64 string.
        });

        writeOffset += chunk.size;
      }

      for (const message of messages) {
        const requestBody = JSON.stringify(message);
        const url = new URL(`${baseUrl}/google.bytestream.ByteStream/Write`);

        const response = await fetch(url.toString(), {
          method: 'POST',
          headers: {
            // TODO: Check if headers are correct.
            'Content-Type': 'application/octet-stream',
            'Accept-Encoding': 'gzip, deflate, br',
            'Keep-Alive': 'timeout=5, max=1000',
          },
          body: requestBody,
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          const message = `Error uploading chunk at offset ${writeOffset}: ${errorMessage}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          break;
        } else {
          if (message.finishWrite) {
            // TODO: Display success.
          }
        }
      }

      loadStateRef.current.uploadArtifact = false;
      evaluateLoading();
    },
    listModelVersionsForModel = React.useCallback(
      async (registeredModelId: string, modelName: string) => {
        loadStateRef.current.fetchModelVersions = true;
        try {
          const listModelVersionsForModelBody = new ListModelVersionsForModelRequest({
            registeredModelId,
          });
          const response = await registerModelVersionClient.listModelVersionsForModel(listModelVersionsForModelBody);
          const modelVersions = response?.modelVersions;
          if (!modelVersions) console.error('No model versions found in the response.');
          return modelVersions;
        } catch (err) {
          const message = `Failed to fetch model versions for ${modelName} (${registeredModelId}): ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.fetchModelVersions = false;
          evaluateLoading();
        }
      },
      [addToast, registerModelVersionClient]
    ),
    updateRegisteredModel = React.useCallback(
      async (registeredModel: RegisteredModel) => {
        loadStateRef.current.updateRegisteredModel = true;
        setLoading(true);
        setLoadingMessage(getLoadingStatusMessage());
        try {
          const updateRegisteredModelBody = new UpdateRegisteredModelRequest({
            registeredModel,
            updateMask: {
              paths: isNew ? ['description'] : ['description', 'name'],
            },
          });
          await registeredModelClient.updateRegisteredModel(updateRegisteredModelBody);
          const modelVersions = await listModelVersionsForModel(registeredModel.id, registeredModel.displayName);
          const currentVersion = modelVersions?.find(
            (modelVersion) => modelVersion.registeredModelId === registeredModel.id
          );
          addToast({
            messageBarType: MessageBarType.success,
            message: `Model "${modelName}" ${isNew ? 'added' : 'updated'} successfully.`,
            timeout: 10000,
            actions: currentVersion?.id ? (
              <div className={classNames.toastActions}>
                <Button
                  text="Batch score now"
                  styles={buttonStylesStealth}
                  onClick={() =>
                    history.push(
                      `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.BATCH_SCORING}/start-new/from-model/${currentVersion?.id}`
                    )
                  }
                />
                <Button
                  text="Deploy now"
                  styles={buttonStylesStealth}
                  onClick={() =>
                    history.push(
                      `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/create-new/deploy/${currentVersion?.id}`
                    )
                  }
                />
              </div>
            ) : undefined,
          });
          history.goBack();
        } catch (err) {
          const message = `Failed to update model: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.updateRegisteredModel = false;
          evaluateLoading();
        }
      },
      [addToast, modelName, isNew]
    ),
    createModelVersion = React.useCallback(
      async (registeredModelId: string, experimentId: string) => {
        loadStateRef.current.createModelVersion = true;
        setLoading(true);
        setLoadingMessage(getLoadingStatusMessage());
        try {
          const modelVersionBody = new CreateModelVersionRequest({
            registeredModelVersion: new RegisteredModelVersion({
              registeredModelId,
              experimentId,
              version,
            }),
            registeredModelDisplayName: modelName,
            projectId: ACTIVE_PROJECT_ID,
          });
          const response = await registerModelVersionClient.createModelVersion(modelVersionBody);
          addToast({
            messageBarType: MessageBarType.success,
            message: `Version "${version}" of "${modelName}" was added successfully.`,
            timeout: 10000,
            actions: response.registeredModelVersion?.id ? (
              <div className={classNames.toastActions}>
                <Button
                  text="Batch score now"
                  styles={buttonStylesStealth}
                  onClick={() =>
                    history.push(
                      `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.BATCH_SCORING}/start-new/from-model/${response.registeredModelVersion?.id}`
                    )
                  }
                />
                <Button
                  text="Deploy now"
                  styles={buttonStylesStealth}
                  onClick={() =>
                    history.push(
                      `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/create-new/deploy/${response.registeredModelVersion?.id}`
                    )
                  }
                />
              </div>
            ) : undefined,
          });
          if ((history?.location?.state as HistoryState)?.from === 'model-selector') {
            history.push(
              `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/create-new/deploy/${response.registeredModelVersion?.id}`
            );
          } else {
            history.goBack();
          }
          // If history should navigate to model detail, re-fetch model versions.
          if ((history?.location?.state as HistoryState)?.from !== 'models') {
            void getRegisteredModel(params.model_id);
            void getRegisteredModelVersions(params.model_id);
          }
        } catch (err) {
          const message = `Failed to create model version: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.createModelVersion = false;
          evaluateLoading();
        }
      },
      [addToast, modelName, ACTIVE_PROJECT_ID, version]
    ),
    uploadArtifactFile = React.useCallback(
      async (file: File) => {
        const artifact = await createArtifact();
        const artifactId = artifact?.id;
        if (!artifactId) return;

        const baseUrl = `${mlopsApiUrl}/storage`;
        await streamFileToGoogleByteStream(file, artifactId, baseUrl);

        const ingestion = await createModelIngestion(artifactId);
        if (!ingestion || !ingestion.modelMetadata || !ingestion.modelParameters) return;
        const experiment = await createExperiment(
          new Metadata(ingestion.modelMetadata),
          new ExperimentParameters(ingestion.modelParameters)
        );
        if (!experiment) return;
        const updatedArtifact = await updateArtifact(
          new Artifact({
            id: artifactId,
            type: ingestion.artifactType,
            entityId: experiment.id,
          })
        );
        if (!updatedArtifact) return;
        if (isNewVersionUpload) {
          await createModelVersion(params.model_id, experiment.id);
        } else {
          const registeredModelId = await registerExperimentAsModel(experiment.id);
          if (!registeredModelId) return;
          const registeredModel = new RegisteredModel({
            id: registeredModelId,
            description: modelDescription,
            projectId: ACTIVE_PROJECT_ID,
          });
          await updateRegisteredModel(registeredModel);
        }
      },
      [
        addToast,
        createArtifact,
        createModelIngestion,
        createExperiment,
        updateArtifact,
        registerExperimentAsModel,
        createModelVersion,
        updateRegisteredModel,
        ACTIVE_PROJECT_ID,
        params.model_id,
        modelDescription,
        isNewVersionUpload,
        mlopsApiUrl,
      ]
    ),
    onUploadModel = React.useCallback(
      (files: File[]) => {
        if (files && files.length) {
          setArtifactFile(files[0]);
        } else {
          setArtifactFile(null);
        }
      },
      [uploadArtifactFile]
    ),
    onClickAddModel = React.useCallback(() => {
      if (isNew || isNewVersionUpload) {
        if (artifactFile) uploadArtifactFile(artifactFile);
      } else {
        void updateRegisteredModel(
          new RegisteredModel({
            id: params.model_id,
            displayName: modelName,
            description: modelDescription,
            projectId: ACTIVE_PROJECT_ID,
          })
        );
      }
    }, [
      artifactFile,
      uploadArtifactFile,
      updateRegisteredModel,
      modelName,
      modelDescription,
      isNew,
      params.model_id,
      ACTIVE_PROJECT_ID,
      isNewVersionUpload,
    ]),
    batchDeleteExperiment = React.useCallback(
      async (experimentIds: string[]) => {
        try {
          if (!ACTIVE_PROJECT_ID) return;
          loadStateRef.current.deletingExperiments = true;
          const deleteExperimentsBody = new BatchDeleteExperimentRequest({
            experimentRequest: experimentIds.map(
              (id) => new DeleteExperimentRequest({ id, projectId: ACTIVE_PROJECT_ID })
            ),
          });
          await experimentClient.batchDeleteExperiment(deleteExperimentsBody);
        } catch (err) {
          const message = `Failed to delete model experiments: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.deletingExperiments = false;
          evaluateLoading();
        }
      },
      [addToast, ACTIVE_PROJECT_ID]
    ),
    deleteModel = React.useCallback(
      async (modelId: string, relatedExperimentIds?: string[]) => {
        try {
          const deleteRegisteredModelBody = new DeleteRegisteredModelRequest({
            modelId,
          });
          await registeredModelClient.deleteRegisteredModel(deleteRegisteredModelBody);
          if (relatedExperimentIds) await batchDeleteExperiment(relatedExperimentIds);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Model deleted successfully.',
          });
          history.goBack();
        } catch (err) {
          const message = `Failed to delete model: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [addToast, ACTIVE_PROJECT_ID, history]
    ),
    deleteModelVersion = React.useCallback(
      async (modelVersionId: string, experimentId: string) => {
        try {
          const deleteModelVersionBody = new DeleteModelVersionRequest({
            id: modelVersionId,
          });
          await registerModelVersionClient.deleteModelVersion(deleteModelVersionBody);
          if (experimentId) await batchDeleteExperiment([experimentId]);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Model version deleted successfully.',
          });
          await getRegisteredModelVersions(params.model_id);
        } catch (err) {
          const message = `Failed to delete model version: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [addToast, ACTIVE_PROJECT_ID, params.model_id]
    ),
    getRegisteredModelVersions = React.useCallback(
      async (registeredModelId: string, pageToken?: string) => {
        loadStateRef.current.countModelVersions = true;

        if (pageToken) {
          setIsLoadingMoreVersions(true);
        } else {
          setLoading(true);
          setLoadingMessage(getLoadingStatusMessage());
        }
        try {
          const response = await registerModelVersionClient.listModelVersionsForModel(
            new ListModelVersionsForModelRequest({ registeredModelId })
          );
          const modelVersions = response?.modelVersions;
          if (!modelVersions) console.error('No model versions found in the response.');

          setNextPageTokenVersions(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );

          const sortedModelVersions = modelVersions.sort(
            (a, b) => Number(a.createdTime?.seconds || 0) - Number(b.createdTime?.seconds || 0)
          );

          const allVersionsMetadata = await Promise.all(
            sortedModelVersions.map((modelVersion) => getExperimentMetadata(modelVersion.experimentId))
          );

          const newItems = sortedModelVersions.map(
            (modelVersion, idx) =>
              ({
                ...modelVersion,
                createdTimeLocal:
                  modelVersion.createdTime?.seconds !== undefined
                    ? new Date(Number(modelVersion.createdTime.seconds) * 1000).toLocaleString()
                    : '',
                createdByName: modelVersion.createdBy,
                deleteModelVersion: () => {
                  showDialog?.({
                    title: 'Delete model version?',
                    description: `This will delete version ${modelVersion.version} of the model. This action cannot be undone.`,
                    onConfirm: () => deleteModelVersion(modelVersion.id, modelVersion.experimentId),
                  });
                },
                onDeployModelVersion: () => {
                  history.push(
                    `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/create-new/deploy/${modelVersion.id}`
                  );
                },
                onBatchScoreModelVersion: () => {
                  history.push(
                    `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.BATCH_SCORING}/start-new/from-model/${modelVersion.id}`
                  );
                },
                onNavigateToDeploymentDetail: (deploymentId: string) => () => {
                  history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/${deploymentId}`);
                },
                // TODO: Optimize by pre-computing active deployments.
                activeDeployments: deployments?.filter((deployment) =>
                  getDeploymentExperimentsIds(deployment)?.includes(modelVersion.experimentId)
                ),
                hasLeftOpenClose: true,
                collapsed: true,
                contents: (
                  <ModelMetadataView
                    metadata={allVersionsMetadata?.[idx]?.metadata}
                    parameters={allVersionsMetadata?.[idx]?.parameters}
                  />
                ),
              } as RegisteredModelVersionItem)
          );
          setRegisteredModelVersionsItems((items) => (pageToken ? [...(items || []), ...(newItems || [])] : newItems));
          // Find the one with the highest version number.
          const count = modelVersions.reduce((max, modelVersion) => {
            const version = modelVersion.version;
            return version > max ? version : max;
          }, 0);
          setVersion(count ? count + 1 : 1);
        } catch (err) {
          const message = `Failed to get registered model versions: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setRegisteredModelVersionsItems(undefined);
        } finally {
          loadStateRef.current.countModelVersions = false;
          evaluateLoading();
          setIsLoadingMoreVersions(false);
        }
      },
      [addToast, deployments]
    ),
    getRegisteredModel = React.useCallback(
      async (modelId: string) => {
        loadStateRef.current.fetchRegisteredModel = true;
        setLoading(true);
        setLoadingMessage(getLoadingStatusMessage());
        try {
          const response = await registeredModelClient.getRegisteredModel(new GetRegisteredModelRequest({ modelId }));
          const model = response?.registeredModel;
          if (model) {
            setModelName(model.displayName);
            setModelDescription(model.description);
          }
        } catch (err) {
          const message = `Failed to fetch model data: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.fetchRegisteredModel = false;
          evaluateLoading();
        }
      },
      [addToast, params.model_id]
    ),
    fetchDeployments = React.useCallback(
      // TODO: Fetch all pages of deployments.
      async (pageToken?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.fetchProjectDeployments = true;
        setLoading(true);
        try {
          const listDeploymentsBody = new ListProjectDeploymentsRequest({
            projectId: ACTIVE_PROJECT_ID,
            paging: {
              pageSize: 20,
              pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
            },
          });
          const response = await deploymentClient.listProjectDeployments(listDeploymentsBody);
          const deploymentItems: Deployment[] | undefined = response?.deployment;
          if (response && !deploymentItems) console.error('No deployments found in the response.');
          setNextPageTokenDeployments(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );

          setDeployments((items) => (pageToken ? [...(items || []), ...(deploymentItems || [])] : deploymentItems));
        } catch (err) {
          const message = `Failed to fetch deployments: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setDeployments(undefined);
        } finally {
          loadStateRef.current.fetchProjectDeployments = false;
          evaluateLoading();
        }
      },
      [addToast, ACTIVE_PROJECT_ID, permissions]
    ),
    onDeleteModel = React.useCallback(() => {
      showDialog?.({
        title: 'Delete model?',
        description: 'This will delete the model and all its versions. This action cannot be undone.',
        onConfirm: () => {
          // Setter is used to avoid stale closure.
          setRegisteredModelVersionsItems((versionItems) => {
            const modelExperiments = (versionItems || []).map((modelVersion) => modelVersion.experimentId);
            deleteModel(params.model_id, modelExperiments);
            return versionItems;
          });
        },
      });
    }, [params.model_id, deleteModel]),
    getExperimentMetadata = React.useCallback(
      async (experimentId: string) => {
        try {
          const response = await experimentClient.getExperiment(
            new GetExperimentRequest({
              id: experimentId,
              responseMetadata: new KeySelection({
                // TODO: Get these from service instead of hardcoding once available.
                pattern: [
                  'source',
                  'score',
                  'dai/score',
                  'scorer',
                  'dai/scorer',
                  'test_score',
                  'dai/test_score',
                  'validation_score',
                  'dai/validation_score',
                  'tool_version',
                  'dai/tool_version',
                  'model_parameters',
                  'dai/model_parameters',
                  'model_type',
                  'tool',
                  'mlflow/flavors/python_function/loader_module',
                  'input_schema',
                  'output_schema',
                ],
              }),
            })
          );
          const experiment = response?.experiment;
          if (!experiment) console.error('No experiment found in the response.');
          return { metadata: experiment?.metadata, parameters: experiment?.parameters };
        } catch (err) {
          const message = `Failed to fetch experiment metadata: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return { metadata: undefined, parameters: undefined };
        }
      },
      [addToast]
    ),
    actionProps = React.useMemo(
      () =>
        permissions.canWrite
          ? {
              actionIcon: 'Add',
              actionTitle: 'Add new model version',
              // TODO: Implement using changing state instead of navigation.
              onActionClick: () =>
                history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/${params.model_id}/add-new-version`),
            }
          : undefined,
      [permissions]
    ),
    widgetListProps: WidgetListProps = {
      columns,
      items: registeredModelVersionsItems,
      loading: !!loading,
      delayLoader: false,
      isLoadingMore: isLoadingMoreVersions,
      onLoadMore: nextPageTokenVersions
        ? () => void getRegisteredModelVersions(params.model_id, nextPageTokenVersions)
        : undefined,
      actionProps,
      NoItemsContent: <div></div>,
      ErrorContent: FailedToLoadView({
        title: 'Failed to load model versions',
        description: 'Please try again later. If the problem persists, contact our support.',
        actionTitle: 'Retry',
        onActionClick: () => void getRegisteredModelVersions(params.model_id),
        actionIcon: 'Refresh',
      }),
      compact: true,
    };

  React.useEffect(() => {
    if (params.model_id && params.model_id !== 'add-new') {
      if (!deployments) {
        if (ACTIVE_PROJECT_ID) void fetchDeployments();
      } else {
        void getRegisteredModel(params.model_id);
        void getRegisteredModelVersions(params.model_id);
      }
    }
  }, [
    params.project_id,
    params.model_id,
    getRegisteredModel,
    getRegisteredModelVersions,
    deployments,
    ACTIVE_PROJECT_ID,
  ]);

  return (
    <PageWrapper>
      <Header customPageTitle={isNew ? 'Add model' : isNewVersionUpload ? 'Add new model version' : 'Model details'} />
      <div className={classNames.form}>
        {!loading ? (
          <>
            <TextField
              label="Name"
              required
              value={modelName}
              onChange={(_ev, newValue) => setModelName(newValue || '')}
              readOnly={!permissions.canWrite}
              disabled={isNewVersionUpload}
              styles={{ root: { maxWidth: 360 } }}
            />
            <TextField
              label="Description"
              value={modelDescription}
              onChange={(_ev, newValue) => setModelDescription(newValue || '')}
              readOnly={!permissions.canWrite}
              disabled={isNewVersionUpload}
              styles={{ root: { maxWidth: 360 } }}
            />
            {isNew || isNewVersionUpload ? (
              <TextField label="Version" value={`${version}`} readOnly disabled styles={{ root: { maxWidth: 360 } }} />
            ) : null}
            {!permissions.canWrite ? null : (
              <>
                {isNew || isNewVersionUpload ? (
                  <FileUpload
                    fileExtensions={['.zip']}
                    label="Model file"
                    maxFileSize={100}
                    uploadCallback={onUploadModel}
                    styles={{ root: { margin: '10px 0' } }}
                  />
                ) : null}
                <Button
                  text={isNew ? 'Add model' : isNewVersionUpload ? 'Add new version' : 'Save changes'}
                  primary
                  style={isNew ? undefined : { margin: '10px 0' }}
                  styles={buttonStylesPrimary}
                  disabled={isNew || isNewVersionUpload ? !modelName || !artifactFile : !modelName}
                  onClick={onClickAddModel}
                />
              </>
            )}
            {!isNew && !isNewVersionUpload ? (
              <>
                <FormSection title="Model versions">
                  {registeredModelVersionsItems?.length === 0 ? <p>No model versions found for this model.</p> : null}
                  <Button
                    text="Add new model version"
                    iconName="Add"
                    styles={buttonStylesStealth}
                    onClick={() =>
                      history.push(
                        `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/${params.model_id}/add-new-version`
                      )
                    }
                  />
                  <div style={{ height: 25 }} />
                  <WidgetList {...widgetListProps} />
                </FormSection>
                <Button
                  text="Delete model"
                  onClick={onDeleteModel}
                  style={{
                    color: 'var(--h2o-red400)',
                    borderColor: 'var(--h2o-red400)',
                  }}
                  iconProps={{ iconName: 'Delete', style: { color: 'var(--h2o-red400)' } }}
                />
              </>
            ) : null}
          </>
        ) : (
          <div className={classNames.loader}>
            <Loader label={loadingMessage} />
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
export default ModelDetail;
