import { Icon } from '@fluentui/react';
import { Button, Link, MessageBar, buttonStylesDefault, buttonStylesPrimary } from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import {
  FwIpRequest,
  FwPortRequest,
  GetIsFileChangedRequest,
  UpdateRemoteTfvarsRequest,
} from '../../admin-center/gen/fw_management/fw_management_pb';
import { useAdminCenterService } from '../../admin-center/hooks';
import CommitHistoryDialog from './components/FWManagement/CommitTable/CommitHistoryDialog';
import {
  fwManagementTopStyles,
  fwTableOptionStyles,
  fwTypeAreaStyle,
  pageTopButtonStyle,
  saveChangeButtonStyle,
  sidePanelStyle,
  tableAreaStyle,
  tableStyle,
  titleAreaStyle,
  titleStyle,
} from './components/FWManagement/FWManagement.styles';
import IPSidePanel from './components/FWManagement/IPTable/IPSidePanel';
import IPTable from './components/FWManagement/IPTable/IPTable';
import PortSidePanel from './components/FWManagement/PortTable/PortSidePanel';
import PortTable from './components/FWManagement/PortTable/PortTable';
import PageWrapper from './PageWrapper';

export default function FWManagement() {
  const [isIPPanelOpen, setIPPanelOpen] = useState(false);
  const [isPortPanelOpen, setPortPanelOpen] = useState(false);
  const [fwIps, setFwIps] = useState<string[]>([]);
  const [fwPorts, setFwPorts] = useState<string[]>([]);
  const [hasFwUpdate, setHasFwUpdate] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [showCommitDialog, setShowCommitDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const service = useAdminCenterService();

  const handleAddIPButtonClick = () => {
    setIPPanelOpen(true);
  };
  const handleAddPortButtonClick = () => {
    setPortPanelOpen(true);
  };
  const handleSaveChange = useCallback(async () => {
    try {
      const request: UpdateRemoteTfvarsRequest = {};
      const response = await service.updateRemoteTfvars(request);
      if (response.statusUpdate) {
        setResponseMessage(response.statusUpdate);
      } else {
        setResponseMessage('❌ Error: Failed to update');
      }
      await FetchFwData();
    } catch (error) {
      setResponseMessage(`❌ Network error: ${error}`);
    }
  }, []);

  const handleShowCommitHistory = () => {
    setShowCommitDialog(true);
  };

  const FetchFwData = async () => {
    setIsLoading(true);
    try {
      const ipReq: FwIpRequest = {};
      const portRequest: FwPortRequest = {};
      const hasUpdateRequest: GetIsFileChangedRequest = {};
      const ipResponse = await service.getFwIps(ipReq);
      const portResponse = await service.getFwPorts(portRequest);
      const hasUpdateResponse = await service.getHasUpdate(hasUpdateRequest);
      const ips = ipResponse.fwIps || [];
      const ports = portResponse.fwPorts || [];
      const hasUpdate = hasUpdateResponse.hasUpdate || false;

      setFwIps(ips);
      setFwPorts(ports);
      setHasFwUpdate(hasUpdate);
    } catch (error) {
      console.error('Error fetching FW data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    FetchFwData();
  }, []);

  return (
    <PageWrapper>
      {responseMessage && (
        <MessageBar timeout={5000} onDismiss={() => setResponseMessage(null)}>
          {responseMessage}
        </MessageBar>
      )}
      {showCommitDialog && <CommitHistoryDialog onClose={() => setShowCommitDialog(false)} />}
      <div style={fwManagementTopStyles}>
        <div style={pageTopButtonStyle}>
          <Button styles={buttonStylesDefault} text="Commit History" onClick={handleShowCommitHistory} />
          <Button
            styles={buttonStylesPrimary}
            text="Save Changes"
            disabled={!hasFwUpdate}
            onClick={handleSaveChange}
            style={saveChangeButtonStyle}
          />
        </div>
      </div>
      <div>
        <p>
          Use these settings to control who and what has access to your cloud environment and what your cloud
          environment has access to. <br></br>
          Please note all changes happen locally first! After adding and removing all addresses, apply the changes to
          your environment with the “Apply Changes” button.
        </p>
      </div>
      <div style={fwTypeAreaStyle}>
        <div style={titleAreaStyle}>
          <h2 style={titleStyle}>Inbound IPs</h2>
          <Link
            href="https://docs.h2o.ai/wave-mc-admin-center/guide/admin-management/firewall-management#adding-inbound-ips"
            underline={false}
          >
            Documentation <Icon iconName="Link" />
          </Link>
        </div>
        <div style={tableAreaStyle}>
          <div style={fwTableOptionStyles}>
            <Button styles={buttonStylesPrimary} text="Add Inbound IPs" onClick={handleAddIPButtonClick} />
            {isIPPanelOpen && (
              <div style={sidePanelStyle}>
                <IPSidePanel isOpen={isIPPanelOpen} setOpen={setIPPanelOpen} onSubmitSuccess={FetchFwData} />
              </div>
            )}
          </div>
          <div style={tableStyle}>
            <IPTable fwIps={fwIps} onSubmitSuccess={FetchFwData} isLoading={isLoading} />
          </div>
        </div>
      </div>
      <div style={fwTypeAreaStyle}>
        <div style={titleAreaStyle}>
          <h2 style={titleStyle}>Outbound Ports</h2>
          <Link
            href="https://docs.h2o.ai/wave-mc-admin-center/guide/admin-management/firewall-management#adding-outbound-ports"
            underline={false}
          >
            Documentation <Icon iconName="Link" />
          </Link>
        </div>
        <div style={tableAreaStyle}>
          <div style={fwTableOptionStyles}>
            <Button styles={buttonStylesPrimary} text="Add Outbound Ports" onClick={handleAddPortButtonClick} />
            {isPortPanelOpen && (
              <div style={sidePanelStyle}>
                <PortSidePanel isOpen={isPortPanelOpen} setOpen={setPortPanelOpen} onSubmitSuccess={FetchFwData} />
              </div>
            )}
          </div>
          <div style={tableStyle}>
            <PortTable fwPorts={fwPorts} onSubmitSuccess={FetchFwData} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
