import { fetchWrapRPC } from '../services/api';
import { AiemEventsRequest, AiemService_GetAiemEvents } from './gen/aiem/aiem_pb';
import {
  AiUnitAlertingService_GetAIUnitAlertingData,
  AiUnitAlertingService_UpdateAIUnitAlertingData,
  GetAIUnitAlertingRequest,
  UpdateAIUnitAlertingRequest,
} from './gen/aiUnitAlerting/aiUnitAlerting_pb';
import {
  AiUnitHourlyComponentRequest,
  AiUnitHourlyCummulativeRequest,
  AiUnitHourlyService_GetHourlyAiUnitComponentUsage,
  AiUnitHourlyService_GetHourlyAiUnitCummulativeUsage,
  AiUnitHourlyService_GetHourlyAiUnitSummary,
  AiUnitHourlySummaryRequest,
} from './gen/aiUnitHourly/aiUnitHourly_pb';
import {
  AiUnitMaxRequest,
  AiUnitMaxService_GetAiUnitMaxStats,
  AiUnitMaxService_GetHourlyAiUnitMaxConsumption,
  AiUnitMaxStatsRequest,
} from './gen/aiUnitMax/aiUnitMax_pb';
import { AppEventService_GetAppEvents, AppEventsRequest } from './gen/app_event/appEvent/appevent_pb';
import {
  InstanceEventService_GetInstanceEvents,
  InstanceEventsRequest,
} from './gen/app_event/instanceEvent/instanceevent_pb';
import { NatGwRequest, NatGwService_GetNatGwIps } from './gen/environment_info/natgw_pb';
import {
  FWManagementService_DeleteLocalFwIps,
  FWManagementService_DeleteLocalFwPorts,
  FWManagementService_GetFwCommitHistory,
  FWManagementService_GetFwIps,
  FWManagementService_GetFwPorts,
  FWManagementService_GetIsFileChanged,
  FWManagementService_UpdateLocalFwIps,
  FWManagementService_UpdateLocalFwPorts,
  FWManagementService_UpdateRemoteTfvars,
  FwIpLocalDeleteRequest,
  FwIpLocalUpdateRequest,
  FwIpRequest,
  FwPortLocalDeleteRequest,
  FwPortLocalUpdateRequest,
  FwPortRequest,
  GetFwCommitHistoryRequest,
  GetIsFileChangedRequest,
  UpdateRemoteTfvarsRequest,
} from './gen/fw_management/fw_management_pb';
import {
  GaugeMetricDailyStatRequest,
  MlopsGaugeMetricRequest,
  MlopsGaugeMetricService_GetGaugeMetricDailyStats,
  MlopsGaugeMetricService_GetMlopsGaugeMetricTotalCount,
} from './gen/mlops/gauge_metric/gaugemetric_pb';
import {
  LoginEventsRequest,
  LoginService_GetLoginEvents,
  LoginService_GetLoginStatistics,
} from './gen/user_login/userlogin_pb';
import {
  AddMemberRequest,
  GetAdminRequest,
  GetUmCommitHistoryRequest,
  GetUserRequest,
  RemoveMemberRequest,
  ResendActivationEmailsRequest,
  UserManagementService_AddMembers,
  UserManagementService_GetAdmins,
  UserManagementService_GetUmCommitHistory,
  UserManagementService_GetUsers,
  UserManagementService_RemoveMembers,
  UserManagementService_ResendActivationEmails,
} from './gen/user_management/user_management_pb';

export class AdminCenterServiceImpl {
  private basePath: string;
  constructor(basePath?: string) {
    this.basePath = basePath || '';
  }

  public setBasePath = (path: string) => {
    this.basePath = path;
  };

  public async getNatGwPublicIps(req: NatGwRequest) {
    return await fetchWrapRPC(NatGwService_GetNatGwIps, { basePath: this.basePath })({ ...req });
  }

  public async getUserLoginEvents(req: LoginEventsRequest) {
    return await fetchWrapRPC(LoginService_GetLoginEvents, { basePath: this.basePath })({ ...req });
  }

  public async getUserLoginEventsStats(req: LoginEventsRequest) {
    return await fetchWrapRPC(LoginService_GetLoginStatistics, { basePath: this.basePath })({ ...req });
  }

  public async getAIEMEvents(req: AiemEventsRequest) {
    return await fetchWrapRPC(AiemService_GetAiemEvents, { basePath: this.basePath })({ ...req });
  }

  public async getAppEvents(req: AppEventsRequest) {
    return await fetchWrapRPC(AppEventService_GetAppEvents, { basePath: this.basePath })({ ...req });
  }

  public async getInstanceEvents(req: InstanceEventsRequest) {
    return await fetchWrapRPC(InstanceEventService_GetInstanceEvents, { basePath: this.basePath })({ ...req });
  }

  public async getAiUnitMaxConsumption(req: AiUnitMaxRequest) {
    return await fetchWrapRPC(AiUnitMaxService_GetHourlyAiUnitMaxConsumption, { basePath: this.basePath })({ ...req });
  }

  public async getAiUnitMaxStats(req: AiUnitMaxStatsRequest) {
    return await fetchWrapRPC(AiUnitMaxService_GetAiUnitMaxStats, { basePath: this.basePath })({ ...req });
  }

  public async getMlopsGaugeMetrics(req: MlopsGaugeMetricRequest) {
    return await fetchWrapRPC(MlopsGaugeMetricService_GetMlopsGaugeMetricTotalCount, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getMlopsGaugeDailyMetrics(req: GaugeMetricDailyStatRequest) {
    return await fetchWrapRPC(MlopsGaugeMetricService_GetGaugeMetricDailyStats, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getHourlyAiUnitCummulativeUsage(req: AiUnitHourlyCummulativeRequest) {
    return await fetchWrapRPC(AiUnitHourlyService_GetHourlyAiUnitCummulativeUsage, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getHourlyAiUnitComponentUsage(req: AiUnitHourlyComponentRequest) {
    return await fetchWrapRPC(AiUnitHourlyService_GetHourlyAiUnitComponentUsage, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getHourlyAiUnitSummary(req: AiUnitHourlySummaryRequest) {
    return await fetchWrapRPC(AiUnitHourlyService_GetHourlyAiUnitSummary, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getFwIps(req: FwIpRequest) {
    return await fetchWrapRPC(FWManagementService_GetFwIps, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getFwPorts(req: FwPortRequest) {
    return await fetchWrapRPC(FWManagementService_GetFwPorts, { basePath: this.basePath })({
      ...req,
    });
  }

  public async updateLocalFwIps(req: FwIpLocalUpdateRequest) {
    return await fetchWrapRPC(FWManagementService_UpdateLocalFwIps, { basePath: this.basePath })({
      ...req,
    });
  }

  public async updateLocalFwPorts(req: FwPortLocalUpdateRequest) {
    return await fetchWrapRPC(FWManagementService_UpdateLocalFwPorts, { basePath: this.basePath })({
      ...req,
    });
  }

  public async deleteLocalFwIps(req: FwIpLocalDeleteRequest) {
    return await fetchWrapRPC(FWManagementService_DeleteLocalFwIps, { basePath: this.basePath })({
      ...req,
    });
  }

  public async deleteLocalFwPorts(req: FwPortLocalDeleteRequest) {
    return await fetchWrapRPC(FWManagementService_DeleteLocalFwPorts, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getHasUpdate(req: GetIsFileChangedRequest) {
    return await fetchWrapRPC(FWManagementService_GetIsFileChanged, { basePath: this.basePath })({
      ...req,
    });
  }

  public async updateRemoteTfvars(req: UpdateRemoteTfvarsRequest) {
    return await fetchWrapRPC(FWManagementService_UpdateRemoteTfvars, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getFwCommitHistory(req: GetFwCommitHistoryRequest) {
    return await fetchWrapRPC(FWManagementService_GetFwCommitHistory, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getUsers(req: GetUserRequest) {
    return await fetchWrapRPC(UserManagementService_GetUsers, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getAdmins(req: GetAdminRequest) {
    return await fetchWrapRPC(UserManagementService_GetAdmins, { basePath: this.basePath })({
      ...req,
    });
  }

  public async addMembers(req: AddMemberRequest) {
    return await fetchWrapRPC(UserManagementService_AddMembers, { basePath: this.basePath })({
      ...req,
    });
  }

  public async removeMembers(req: RemoveMemberRequest) {
    return await fetchWrapRPC(UserManagementService_RemoveMembers, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getUmCommitHistory(req: GetUmCommitHistoryRequest) {
    return await fetchWrapRPC(UserManagementService_GetUmCommitHistory, { basePath: this.basePath })({
      ...req,
    });
  }

  public async updateAIUnitAlertingData(req: UpdateAIUnitAlertingRequest) {
    return await fetchWrapRPC(AiUnitAlertingService_UpdateAIUnitAlertingData, { basePath: this.basePath })({
      ...req,
    });
  }

  public async getAIUnitAlertingData(req: GetAIUnitAlertingRequest) {
    return await fetchWrapRPC(AiUnitAlertingService_GetAIUnitAlertingData, { basePath: this.basePath })({
      ...req,
    });
  }

  public async resendActivationEmail(req: ResendActivationEmailsRequest) {
    return await fetchWrapRPC(UserManagementService_ResendActivationEmails, { basePath: this.basePath })({
      ...req,
    });
  }
}
