import { Button, Panel, TextField, buttonStylesGhost, buttonStylesPrimary, useTheme } from '@h2oai/ui-kit';
import React, { useCallback, useState } from 'react';

import { FwIpLocalUpdateRequest } from '../../../../../admin-center/gen/fw_management/fw_management_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { useUser } from '../../../../../utils/hooks';
import { sidePanelFooterStyle } from '../FWManagement.styles';

interface IPPanelProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitSuccess: () => Promise<void>;
}

export default function IPSidePanel({ isOpen, setOpen, onSubmitSuccess }: IPPanelProps) {
  const service = useAdminCenterService();
  const user = useUser();
  const theme = useTheme();
  const [ipValue, setIPValue] = useState('');
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const handleIPChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setIPValue(newValue || '');
  };

  const dismissPanel = useCallback(() => {
    setOpen(false);
    setResponseMessage(null);
  }, [setOpen]);
  const isFormValid = useCallback(() => {
    return ipValue !== null && ipValue !== undefined && ipValue !== '';
  }, [ipValue]);

  const handleSubmitClick = useCallback(async () => {
    if (!isFormValid()) return;

    const ipList: string[] = ipValue
      ? ipValue
          .split(',')
          .map((ip) => ip.trim())
          .filter((ip) => ip !== '')
      : [];

    try {
      const request: FwIpLocalUpdateRequest = {
        newFwIps: ipList,
        modifiedBy: user?.name ?? '',
        timestamp: Date.now() / 1000,
      };
      const response = await service.updateLocalFwIps(request);
      if (response.statusUpdate) {
        setResponseMessage(response.statusUpdate);
      } else {
        setResponseMessage('❌ Error: Failed to update');
      }
      await onSubmitSuccess();
    } catch (error) {
      setResponseMessage(`❌ Network error: ${error}`);
    }
  }, [ipValue, onSubmitSuccess, isFormValid]);

  const onRenderFooterContent = useCallback(
    () => (
      <>
        <div style={sidePanelFooterStyle}>
          <Button styles={buttonStylesGhost} text="Cancel" onClick={dismissPanel} style={{ marginRight: 10 }} />
          <Button styles={buttonStylesPrimary} text="Add IP" onClick={handleSubmitClick} disabled={!isFormValid()} />
        </div>
      </>
    ),
    [dismissPanel, handleSubmitClick, isFormValid]
  );

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      onRenderFooterContent={onRenderFooterContent}
      customWidth="500px"
      type={7}
    >
      <h2>
        <strong>Add Inbound IP</strong>
      </h2>
      <h3>Grant access to your cloud environment</h3>
      <TextField
        label="Inbound IP(s)"
        placeholder="Add comma seperated IPs. Ex: 176.230.223.145/32, 176.230.223,.145/33"
        value={ipValue}
        onChange={handleIPChange}
        required={true}
      />
      {responseMessage && (
        <p
          style={{
            marginTop: 10,
            color: responseMessage.includes('❌') ? theme.palette?.red300 : theme.palette?.green300,
          }}
        >
          {responseMessage}
        </p>
      )}
    </Panel>
  );
}
