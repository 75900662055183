import { MessageBarType } from '@fluentui/react';
import { DetailsList, Item, MessageBar, useTheme } from '@h2oai/ui-kit';
import { useEffect, useMemo, useState } from 'react';

import { AppEventsRequest, AppEventsResponse } from '../../../../../admin-center/gen/app_event/appEvent/appevent_pb';
import { useAdminCenterService } from '../../../../../admin-center/hooks';
import { customTableStyles } from '../../../common/common.styles';
import { getDummyList } from '../../../common/utils';
import { AppEventName, AppEventOriginalName, AppEventTableColKey, AppEventTableColName, NA } from '../../../constants';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { tableStyle } from '../AppEvent.styles';
import {
  createdEventStyles,
  deletedEventStyles,
  likedEventStyles,
  pinnedEventStyles,
  updatedEventStyles,
} from './AppTable.styles';

interface AppEvent {
  appName: string;
  appEvent: string;
  appUser: string;
  appDate: string;
  appTime: string;
}

interface AppEventTableProps {
  startDate: string;
  endDate: string;
  searchQuery: string;
}

const dummyList = getDummyList<AppEvent>(8);

export default function AppTable({ startDate, endDate, searchQuery }: AppEventTableProps) {
  const service = useAdminCenterService();
  const theme = useTheme();
  const [appEventData, setAppEventData] = useState<AppEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAppEvents = async () => {
    setIsLoading(true);
    try {
      const request: AppEventsRequest = {
        startDate: startDate,
        endDate: endDate,
      };

      const response: AppEventsResponse = await service.getAppEvents(request);

      const APP_EVENT_MAP: Record<string, string> = {
        [AppEventOriginalName.create]: AppEventName.create,
        [AppEventOriginalName.delete]: AppEventName.delete,
        [AppEventOriginalName.like]: AppEventName.like,
        [AppEventOriginalName.pin]: AppEventName.pin,
        [AppEventOriginalName.update]: AppEventName.update,
        nan: NA,
      };
      const getAppEventColumnEntry = (event: string | undefined): string => {
        if (!event) return NA;
        return APP_EVENT_MAP[event.toLowerCase()] || event;
      };

      const getAppTitleColumnEntry = (event: string | undefined): string => {
        if (!event) return NA;
        try {
          const parsedEvent = JSON.parse(event);
          if (parsedEvent && typeof parsedEvent === 'object' && 'appEvent' in parsedEvent) {
            const events = parsedEvent['appEvent'];
            const firstEventKey = Object.keys(events)[0];

            if (firstEventKey && events[firstEventKey] && events[firstEventKey]['app']) {
              return events[firstEventKey]['app']['title'];
            }
          }
        } catch (e) {
          return NA;
        }
        return NA;
      };

      const formattedData = (response.events ?? []).map((event) => {
        const utcDate = event.ts ? new Date(event.ts) : null;

        return {
          appName: getAppTitleColumnEntry(event.label) ?? '-',
          appEvent: getAppEventColumnEntry(event.stream) ?? '-',
          appUser: event.userName ?? '-',
          appDate: utcDate
            ? utcDate.toLocaleDateString('en-CA', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
            : '-',
          appTime: utcDate
            ? utcDate.toLocaleTimeString('en-US', {
                hour12: false,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
            : '-',
        };
      });

      setAppEventData(formattedData);
    } catch (error) {
      console.error('Error fetching login events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAppEvents();
  }, [startDate, endDate]);

  const filteredData = useMemo(() => {
    const searchTerm = searchQuery.toLowerCase();
    return appEventData.filter(
      (item) =>
        item.appName.toLowerCase().includes(searchTerm) ||
        item.appEvent.toLowerCase().includes(searchTerm) ||
        item.appUser.toLowerCase().includes(searchTerm) ||
        item.appDate.toLowerCase().includes(searchTerm) ||
        item.appTime.toLowerCase().includes(searchTerm)
    );
  }, [appEventData, searchQuery]);

  const columns = [
    {
      key: AppEventTableColKey.name,
      fieldName: AppEventTableColKey.name,
      name: AppEventTableColName.name,
      minWidth: 200,
      onRender: (item: AppEvent) => <>{!isLoading ? item.appName : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AppEventTableColKey.event,
      fieldName: AppEventTableColKey.event,
      name: AppEventTableColName.event,
      minWidth: 200,
      onRender: (item: any) => {
        if (isLoading) return <SkeletonLoader width="65%" height="100%" />;
        const event = item.appEvent as AppEventName;
        const eventStyles = {
          [AppEventName.create]: createdEventStyles,
          [AppEventName.delete]: deletedEventStyles,
          [AppEventName.like]: likedEventStyles,
          [AppEventName.pin]: pinnedEventStyles,
          [AppEventName.update]: updatedEventStyles,
        };
        return (
          <div>
            <Item
              data={{
                id: '',
                title: event,
              }}
              styles={eventStyles[event] || {}}
              idField="id"
              labelField="title"
            />
          </div>
        );
      },
    },
    {
      key: AppEventTableColKey.user,
      fieldName: AppEventTableColKey.user,
      name: AppEventTableColName.user,
      minWidth: 200,
      onRender: (item: AppEvent) => <>{!isLoading ? item.appUser : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AppEventTableColKey.date,
      fieldName: AppEventTableColKey.date,
      name: AppEventTableColName.date,
      minWidth: 200,
      onRender: (item: AppEvent) => <>{!isLoading ? item.appEvent : <SkeletonLoader width="65%" height="100%" />}</>,
    },
    {
      key: AppEventTableColKey.time,
      fieldName: AppEventTableColKey.time,
      name: AppEventTableColName.time,
      minWidth: 200,
      onRender: (item: AppEvent) => <>{!isLoading ? item.appTime : <SkeletonLoader width="65%" height="100%" />}</>,
    },
  ];

  return (
    <div style={tableStyle(theme)}>
      {!isLoading && appEventData.length === 0 ? (
        <MessageBar messageBarType={MessageBarType.warning}>
          {'No data available for the selected date range'}
        </MessageBar>
      ) : (
        <DetailsList columns={columns} styles={customTableStyles(theme)} items={isLoading ? dummyList : filteredData} />
      )}
    </div>
  );
}
