import { Stack } from '@fluentui/react';
import { useTheme } from '@h2oai/ui-kit';
import { useState } from 'react';
import { parse, stringify } from 'yaml';

import { CodeArea } from '../../../components/CodeArea/CodeArea';
import { EntityFieldInputProps } from './BasicEntityModelComponents';
import { defaultEntityFormRowStyles, defaultEntityLabelColumnWidth } from './DefaultEntityFormRowStyles';
import { LabelAndDescription } from './LabelAndDescription';

export function YamlEntityModelField<EntityModel>({
  field,
  model,
  onChange,
  compact,
  textAreaStyles,
}: EntityFieldInputProps<EntityModel>) {
  const { name, label, description, disabled } = field;
  const initialValue = (model as any)[name] as string;
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | undefined>(undefined);
  const { semanticColors } = useTheme();

  return (
    <Stack horizontal={!compact} tokens={{ childrenGap: 15 }} styles={{ root: defaultEntityFormRowStyles }}>
      <Stack styles={{ root: { minWidth: defaultEntityLabelColumnWidth, maxWidth: defaultEntityLabelColumnWidth } }}>
        <LabelAndDescription label={label} description={description} />
        {error && (
          <Stack
            styles={{
              root: {
                padding: 10,
                borderRadius: 5,
                color: 'white',
                opacity: 0.7,
                backgroundColor: semanticColors?.buttonDanger,
                borderColor: semanticColors?.buttonDangerActiveBorder,
              },
            }}
          >
            {error}
          </Stack>
        )}
      </Stack>
      <CodeArea
        disabled={disabled}
        styles={textAreaStyles}
        cols={compact ? 40 : 94}
        rows={compact ? 14 : 21}
        defaultValue={value}
        onChange={
          disabled
            ? () => null
            : ({ target }) => {
                const v = target?.value;
                setValue(v);
                try {
                  const parsedData = parse(v);
                  setError(undefined);
                  onChange!(name, stringify(parsedData));
                } catch (_e: any) {
                  setError(_e.message);
                }
              }
        }
      />
    </Stack>
  );
}
