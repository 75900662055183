import { Toggle } from '@fluentui/react';
import { DatePicker, Search } from '@h2oai/ui-kit';
import { useState } from 'react';

import { aiemMenuStyle, aiemTopStyles } from './components/AIEM/AIEM.styles';
import AIEMTable from './components/AIEM/AIEMTable/AIEMTable';
import PageWrapper from './PageWrapper';

export default function AIEM() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  sevenDaysAgo.setHours(0, 0, 0, 0);

  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date>(today);
  const [searchQuery, setSearchQuery] = useState('');
  const [showComputeResource, setShowComputeResource] = useState<boolean>(true);

  const handleStartDateChange = (date: Date | null | undefined) => {
    if (date) {
      setStartDate(date);
    }
  };
  const handleEndDateChange = (date: Date | null | undefined) => {
    if (date) {
      setEndDate(date);
    }
  };
  const handleToggleChange = (_event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    setShowComputeResource(checked || false);
  };

  return (
    <PageWrapper>
      <div>
        <p>AI Engine Manager events updates hourly. All dates and times displayed are in your time zone.</p>
      </div>
      <div style={aiemTopStyles}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Search
            emptyMessage="No results found"
            placeholder="Search by Engine/User..."
            onSearchTextChange={(text: string) => setSearchQuery(text)}
            style={{ width: '250px', marginRight: '20px' }}
          />
        </div>
        <div style={aiemMenuStyle}>
          <Toggle
            inlineLabel={true}
            label={'Show Compute Resource'}
            style={{ marginRight: '20px' }}
            checked={showComputeResource}
            onChange={handleToggleChange}
          />
          <DatePicker
            placeholder="Select start date"
            style={{ marginRight: '10px', width: '170px' }}
            value={startDate}
            onSelectDate={handleStartDateChange}
          />
          <DatePicker
            placeholder="Select end date"
            style={{ width: '170px' }}
            value={endDate}
            onSelectDate={handleEndDateChange}
          />
        </div>
      </div>

      <div style={{ marginTop: '10px' }}>
        <AIEMTable
          startDate={startDate.toISOString()}
          endDate={endDate.toISOString()}
          showComputeResource={showComputeResource}
          searchQuery={searchQuery}
        />
      </div>
    </PageWrapper>
  );
}
