// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/notebook_engine_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { NotebookEngine } from "./notebook_engine_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for creating a new NotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.CreateNotebookEngineRequest
 */
export type CreateNotebookEngineRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/notebookEngines/CREATE
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The NotebookEngine resource to create.
 * NON_EMPTY_DEFAULT fields that are not set in this request will be set to a value based on the selected profile.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngine notebook_engine = 2;
 */
notebookEngine: NotebookEngine;
/**
 * Specify the NotebookEngine ID, which will become a part of the NotebookEngine
 * resource name.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string notebook_engine_id = 3;
 */
notebookEngineId: string;
}
;
/**
 * Response message for NotebookEngineService.CreateNotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.CreateNotebookEngineResponse
 */
export type CreateNotebookEngineResponse = {
/**
 * NotebookEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngine notebook_engine = 1;
 */
notebookEngine?: NotebookEngine;
}
;
/**
 * Request message for getting a single NotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.GetNotebookEngineRequest
 */
export type GetNotebookEngineRequest = {
/**
 * NotebookEngine resource name.
 * Format: workspaces/{workspace}/notebookEngines/{notebook_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/notebookEngines/GET
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for NotebookEngineService.GetNotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.GetNotebookEngineResponse
 */
export type GetNotebookEngineResponse = {
/**
 * NotebookEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngine notebook_engine = 1;
 */
notebookEngine?: NotebookEngine;
}
;
/**
 * Request message for listing NotebookEngines in a workspace.
 *
 * @generated from message ai.h2o.engine.v1.ListNotebookEnginesRequest
 */
export type ListNotebookEnginesRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/notebookEngines/LIST
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of NotebookEngines to return in a response.
 * If unspecified (or set to 0), at most 50 NotebookEngines will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListNotebookEnginesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for NotebookEngineService.ListNotebookEngines.
 *
 * @generated from message ai.h2o.engine.v1.ListNotebookEnginesResponse
 */
export type ListNotebookEnginesResponse = {
/**
 * Collection of NotebookEngines.
 *
 * @generated from field: repeated ai.h2o.engine.v1.NotebookEngine notebook_engines = 1;
 */
notebookEngines?: NotebookEngine[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * Total number of NotebookEngines matched by the List request.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Request message for resuming an existing NotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.ResumeNotebookEngineRequest
 */
export type ResumeNotebookEngineRequest = {
/**
 * NotebookEngine resource name.
 * Format: workspaces/{workspace}/notebookEngines/{notebook_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/notebookEngines/RESUME
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response for NotebookEngineService.ResumeNotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.ResumeNotebookEngineResponse
 */
export type ResumeNotebookEngineResponse = {
/**
 * NotebookEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngine notebook_engine = 1;
 */
notebookEngine?: NotebookEngine;
}
;
/**
 * Request message for pausing a running NotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.PauseNotebookEngineRequest
 */
export type PauseNotebookEngineRequest = {
/**
 * NotebookEngine resource name.
 * Format: workspaces/{workspace}/notebookEngines/{notebook_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/notebookEngines/PAUSE
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response for NotebookEngineService.PauseNotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.PauseNotebookEngineResponse
 */
export type PauseNotebookEngineResponse = {
/**
 * NotebookEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngine notebook_engine = 1;
 */
notebookEngine?: NotebookEngine;
}
;
/**
 * Request message for updating an existing NotebookEngine.
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/notebookEngines/UPDATE
 *
 * @generated from message ai.h2o.engine.v1.UpdateNotebookEngineRequest
 */
export type UpdateNotebookEngineRequest = {
/**
 * NotebookEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngine notebook_engine = 1;
 */
notebookEngine: NotebookEngine;
/**
 * The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {"profile", "cpu", "gpu", "memory_bytes", "display_name",
 * "max_idle_duration", "max_running_duration"}.
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response for NotebookEngineService.UpdateNotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.UpdateNotebookEngineResponse
 */
export type UpdateNotebookEngineResponse = {
/**
 * NotebookEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.NotebookEngine notebook_engine = 1;
 */
notebookEngine?: NotebookEngine;
}
;
/**
 * Request message for deleting an existing NotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.DeleteNotebookEngineRequest
 */
export type DeleteNotebookEngineRequest = {
/**
 * NotebookEngine resource name.
 * Format: workspaces/{workspace}/notebookEngines/{notebook_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/notebookEngines/DELETE
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for NotebookEngineService.DeleteNotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.DeleteNotebookEngineResponse
 */
export type DeleteNotebookEngineResponse = {
/**
 * NotebookEngine resource being deleted. May be empty in case delete was instant.
 *
 * @generated from field: optional ai.h2o.engine.v1.NotebookEngine notebook_engine = 1;
 */
notebookEngine?: NotebookEngine;
}
;
/**
 * Request message for accessing a NotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.AccessNotebookEngineRequest
 */
export type AccessNotebookEngineRequest = {
/**
 * NotebookEngine resource name.
 * Format: workspaces/{workspace}/notebookEngines/{notebook_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/notebookEngines/ACCESS
 *
 * @generated from field: string notebook_engine = 1;
 */
notebookEngine: string;
}
;
/**
 * Response message for accessing a NotebookEngine.
 *
 * @generated from message ai.h2o.engine.v1.AccessNotebookEngineResponse
 */
export type AccessNotebookEngineResponse = {
/**
 * Link to access the NotebookEngine.
 *
 * @generated from field: string uri = 1;
 */
uri?: string;
}
;
/**
 * Creates a new NotebookEngine within a specified workspace.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineService.CreateNotebookEngine
 */
export const NotebookEngineService_CreateNotebookEngine = new RPC<CreateNotebookEngineRequest,CreateNotebookEngineResponse>("POST", "/v1/{parent=workspaces/*}/notebookEngines", "notebookEngine");
/**
 * Returns a specific NotebookEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineService.GetNotebookEngine
 */
export const NotebookEngineService_GetNotebookEngine = new RPC<GetNotebookEngineRequest,GetNotebookEngineResponse>("GET", "/v1/{name=workspaces/*/notebookEngines/*}");
/**
 * Returns a collection of NotebookEngines within a workspace.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineService.ListNotebookEngines
 */
export const NotebookEngineService_ListNotebookEngines = new RPC<ListNotebookEnginesRequest,ListNotebookEnginesResponse>("GET", "/v1/{parent=workspaces/*}/notebookEngines");
/**
 * Resumes an existing NotebookEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineService.ResumeNotebookEngine
 */
export const NotebookEngineService_ResumeNotebookEngine = new RPC<ResumeNotebookEngineRequest,ResumeNotebookEngineResponse>("POST", "/v1/{name=workspaces/*/notebookEngines/*}:resume");
/**
 * Pauses a NotebookEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineService.PauseNotebookEngine
 */
export const NotebookEngineService_PauseNotebookEngine = new RPC<PauseNotebookEngineRequest,PauseNotebookEngineResponse>("POST", "/v1/{name=workspaces/*/notebookEngines/*}:pause");
/**
 * Updates a NotebookEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineService.UpdateNotebookEngine
 */
export const NotebookEngineService_UpdateNotebookEngine = new RPC<UpdateNotebookEngineRequest,UpdateNotebookEngineResponse>("PATCH", "/v1/{notebookEngine.name=workspaces/*/notebookEngines/*}", "notebookEngine");
/**
 * Deletes a NotebookEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineService.DeleteNotebookEngine
 */
export const NotebookEngineService_DeleteNotebookEngine = new RPC<DeleteNotebookEngineRequest,DeleteNotebookEngineResponse>("DELETE", "/v1/{name=workspaces/*/notebookEngines/*}");
/**
 * Access NotebookEngine.
 * (-- api-linter: core::0136::http-uri-suffix=disabled
 *     aip.dev/not-precedent: False positive. --)
 *
 * @generated from rpc ai.h2o.engine.v1.NotebookEngineService.AccessNotebookEngine
 */
export const NotebookEngineService_AccessNotebookEngine = new RPC<AccessNotebookEngineRequest,AccessNotebookEngineResponse>("GET", "/v1/{notebookEngine=workspaces/*/notebookEngines/*}:access");
