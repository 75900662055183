import { memo } from 'react';

import { AIEMOpType, AIEngine } from '../../../../aiem/engine/types';
import { AIEMUserActionType } from '../../../../aiem/userActions';
import { usePermissions } from '../../../../authz/providers/PermissionsProvider/PermissionsProvider';
import { AIEMPanelView } from '../AIEMPanelView/AIEMPanelView';
import { AIEMPanel } from './AIEMPanel';

export type AIEMPanelWrapperProps = {
  onDismiss: () => void;
  op: AIEMOpType;
  engine: AIEngine;
  onSave: () => void;
};

const aiEnginesCreateSelectionActions = [
  AIEMUserActionType.DAI_ENGINE_CREATE,
  AIEMUserActionType.NOTEBOOK_ENGINE_CREATE,
  AIEMUserActionType.H2O_ENGINE_CREATE,
];

const AIEMPanelComponent = ({ onDismiss, engine, op, onSave }: AIEMPanelWrapperProps) => {
  const [canCreateDAI, canCreateNotebook, canCreateH2O] = usePermissions(aiEnginesCreateSelectionActions);
  switch (op) {
    case AIEMOpType.create:
    case AIEMOpType.edit:
      return (
        <AIEMPanel
          data-test="aiem-panel"
          engine={engine}
          onSave={onSave}
          onDismiss={onDismiss}
          op={op}
          canCreateDAI={canCreateDAI}
          canCreateNotebook={canCreateNotebook}
          canCreateH2O={canCreateH2O}
        />
      );
    case AIEMOpType.view:
      return <AIEMPanelView data-test="aiem-panel" engine={engine} onDismiss={onDismiss} />;
    default:
      throw new Error(`The operation "${op}" is not supported by AIEMPanel.`);
  }
};

export const AIEMPanelWrapper = memo(AIEMPanelComponent);
