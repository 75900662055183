import { MessageBarType } from '@fluentui/react';
import { useTheme, useToast } from '@h2oai/ui-kit';
import { ReactNode, useCallback, useMemo } from 'react';

import { TypeVersionSelection, TypeVersionSummary, VersionsSummary } from '../../components/AIEnginesPage/filter.utils';
import { useWorkspaces } from '../../pages/Orchestrator/WorkspaceProvider';
import { getAccessToken } from '../../services/api';
import { useCloudPlatformDiscovery, useStateQueryParams } from '../../utils/hooks';
import { handleErrMsg } from '../../utils/utils';
import { defaultBasePath, defaultWorkspaceName, defaultLatest as latest } from '../defaults';
import { Engine_State, Engine_Type } from '../gen/ai/h2o/engine/v1/engine_pb';
import { ListEnginesResponse } from '../gen/ai/h2o/engine/v1/engine_service_pb';
import { getIdFromName } from '../utils';
import { constraintSetTypeMap, engineTypeService, getEndpointCall } from './services';
import {
  AIEMError,
  AIEMOpType,
  AIEngine,
  EngineConstraintSet,
  EngineListRequest,
  EngineListResponse,
  EngineResponse,
  EngineState,
  EngineVersion,
  ValidEngineType,
} from './types';

export enum FilterLogicalOperatorParenthesisType {
  and = ' AND ',
  or = ' OR ',
  closeAnd = ') AND ',
  doubleCloseAnd = ')) AND ',
  tripleCloseAnd = '))) AND ',
  closeOr = ') OR ',
  doubleCloseOr = ')) OR ',
  tripleCloseOr = '))) OR ',
  open = '(',
  doubleOpen = '((',
  tripleOpen = '(((',
  close = ')',
  doubleClose = '))',
  tripleClose = ')))',
  andOpen = ' AND (',
  andDoubleOpen = ' AND ((',
  andTripleOpen = ' AND (((',
  orOpen = ' OR (',
  orDoubleOpen = ' OR ((',
  orTripleOpen = ' OR (((',
}

export type EngineQueryParamsType = {
  searchTerm: string;
  versions: TypeVersionSelection;
  states: string[];
};

enum EngineQueryParamsKeys {
  search = 'search',
  versions = 'versions',
  states = 'states',
}

const invalidCloseTypes = [
    FilterLogicalOperatorParenthesisType.and,
    FilterLogicalOperatorParenthesisType.or,
    FilterLogicalOperatorParenthesisType.andOpen,
    FilterLogicalOperatorParenthesisType.orOpen,
    FilterLogicalOperatorParenthesisType.doubleOpen,
    FilterLogicalOperatorParenthesisType.open,
    FilterLogicalOperatorParenthesisType.tripleOpen,
    FilterLogicalOperatorParenthesisType.or,
  ],
  invalidCloseParenthesisTypes = [
    FilterLogicalOperatorParenthesisType.closeAnd,
    FilterLogicalOperatorParenthesisType.closeOr,
  ],
  invalidDoubleCloseParenthesisTypes = [
    FilterLogicalOperatorParenthesisType.doubleCloseAnd,
    FilterLogicalOperatorParenthesisType.doubleCloseOr,
  ],
  invalidTripleCloseParenthesisTypes = [
    FilterLogicalOperatorParenthesisType.tripleCloseAnd,
    FilterLogicalOperatorParenthesisType.tripleCloseOr,
  ];

export enum FilterValueEnclosingType {
  None = 'none',
  Quote = 'quote',
  Contains = 'contains',
}

export type FilterCondition = {
  name: string;
  value: string;
  enclosingType?: FilterValueEnclosingType;
  openType?: FilterLogicalOperatorParenthesisType;
  closeType?: FilterLogicalOperatorParenthesisType;
};

export const getFilterExpression = (originalConditions: FilterCondition[]) => {
  // this was causing a bug where the originalConditions were being mutated
  // and impacting the UI filter state management
  const conditions = JSON.parse(JSON.stringify(originalConditions)),
    { length } = conditions;
  if (length) {
    const lastCondition = conditions[length - 1],
      { closeType } = lastCondition;
    if (closeType && invalidCloseTypes.includes(closeType)) {
      lastCondition.closeType = undefined;
    }
    if (closeType && invalidCloseParenthesisTypes.includes(closeType)) {
      lastCondition.closeType = FilterLogicalOperatorParenthesisType.close;
    }
    if (closeType && invalidDoubleCloseParenthesisTypes.includes(closeType)) {
      lastCondition.closeType = FilterLogicalOperatorParenthesisType.doubleClose;
    }
    if (closeType && invalidTripleCloseParenthesisTypes.includes(closeType)) {
      lastCondition.closeType = FilterLogicalOperatorParenthesisType.tripleClose;
    }
  }

  const getEnclosing = (type: FilterValueEnclosingType = FilterValueEnclosingType.None, close = false) =>
    type === FilterValueEnclosingType.None
      ? ``
      : `${close && type === FilterValueEnclosingType.Contains ? '*' : ''}"${
          !close && type === FilterValueEnclosingType.Contains ? '*' : ''
        }`;

  const filter = [...conditions]
    ?.map(
      ({ openType, name, enclosingType = FilterValueEnclosingType.None, value, closeType }) =>
        `${openType || ''}${name}=${getEnclosing(enclosingType)}${value}${getEnclosing(enclosingType, true)}${
          closeType || ''
        }`
    )
    .join('');

  return filter;
};

export type BadgeData = {
  id: string;
  title: string;
  backgroundColor?: string;
  borderLeft?: string;
  color?: string;
  renderer?: () => ReactNode | undefined;
};

export function useEngine() {
  const { ACTIVE_WORKSPACE_NAME } = useWorkspaces();
  const { addToast } = useToast(),
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    basePath = Object(cloudPlatformDiscovery)?.aiEngineManagerApiUrl || defaultBasePath,
    requestConfig = { basePath, bearerToken: getAccessToken },
    requestConfigWithParent = { basePath, bearerToken: getAccessToken, parent: ACTIVE_WORKSPACE_NAME },
    defaultErrorMessage = 'Error',
    { palette, semanticColors: Colors } = useTheme(),
    defaultColor = palette?.gray500,
    defaultBackgroundColor = palette?.white,
    EngineStateMap = new Map<EngineState, BadgeData>([
      [
        Engine_State.CONNECTING,
        {
          id: Engine_State.CONNECTING,
          title: 'Connecting',
          color: defaultColor,
          backgroundColor: defaultBackgroundColor,
        },
      ],
      [
        Engine_State.DELETING,
        {
          id: Engine_State.DELETING,
          title: 'Deleting',
          color: defaultColor,
          backgroundColor: defaultBackgroundColor,
        },
      ],
      [
        Engine_State.FAILED,
        {
          id: Engine_State.FAILED,
          title: 'Failed',
          color: palette?.red900,
          backgroundColor: palette?.red100,
        },
      ],
      [
        Engine_State.PAUSED,
        {
          id: Engine_State.PAUSED,
          title: 'Paused',
          color: palette?.gray900,
          backgroundColor: palette?.gray200,
        },
      ],
      [
        Engine_State.PAUSING,
        {
          id: Engine_State.PAUSING,
          title: 'Pausing',
          color: defaultColor,
          backgroundColor: defaultBackgroundColor,
        },
      ],
      [
        Engine_State.TERMINATED,
        {
          id: Engine_State.TERMINATED,
          title: 'Terminated',
          color: palette?.gray900,
          backgroundColor: palette?.gray200,
        },
      ],
      [
        Engine_State.TERMINATING,
        {
          id: Engine_State.TERMINATING,
          title: 'Terminating',
          color: defaultColor,
          backgroundColor: defaultBackgroundColor,
        },
      ],
      [
        Engine_State.RUNNING,
        {
          id: Engine_State.RUNNING,
          title: 'Running',
          color: palette?.green900,
          backgroundColor: palette?.green100,
        },
      ],
      [
        Engine_State.STARTING,
        {
          id: Engine_State.STARTING,
          title: 'Starting',
          color: defaultColor,
          backgroundColor: defaultBackgroundColor,
        },
      ],
      [
        Engine_State.UNSPECIFIED,
        {
          id: Engine_State.UNSPECIFIED,
          title: 'Unspecified',
          color: palette?.white,
          backgroundColor: palette?.black,
        },
      ],
    ]),
    addErrorToast = useCallback(
      (baseError: string, error: AIEMError): void => {
        addToast({
          messageBarType: MessageBarType.error,
          message: `${baseError}: ${handleErrMsg(error.message)}`,
        });
      },
      [addToast]
    ),
    listEngines = useCallback(async (params?: EngineListRequest, errorMessage = defaultErrorMessage) => {
      try {
        if (!params || !params?.parent) {
          params = { ...params, parent: defaultWorkspaceName };
        }
        const res = (await getEndpointCall(
          requestConfigWithParent,
          AIEMOpType.list
        )(params as any)) as EngineListResponse;
        return (res as ListEnginesResponse).engines;
      } catch (error: unknown) {
        addErrorToast(errorMessage, error as AIEMError);
      }
      return undefined;
    }, []),
    searchEngines = useCallback(async (criteria: FilterCondition[], parent = defaultWorkspaceName) => {
      const filter = getFilterExpression(criteria);
      return await listEngines({ filter, parent } as EngineListRequest);
    }, []),
    listVersions = useCallback(
      async (
        engineType: ValidEngineType,
        errorMessage = defaultErrorMessage,
        suppressToastError = false
      ): Promise<EngineVersion[]> => {
        const versions: EngineVersion[] = [];
        try {
          const list: EngineVersion[] = await engineTypeService[engineType].listVersions(requestConfig);

          versions.push(
            ...(list?.map(({ name, aliases }) => {
              const isDefault = aliases?.includes(latest);
              const version = `${getIdFromName(name)}${isDefault ? ` (${latest})` : ''}`;
              return { name: version, isDefault, key: name, type: engineType } as EngineVersion;
            }) || [])
          );
        } catch (error: unknown) {
          if (suppressToastError) {
            // eslint-disable-next-line no-throw-literal
            throw error as AIEMError;
          } else {
            addErrorToast(errorMessage, error as AIEMError);
          }
        }
        return versions;
      },
      [addErrorToast, basePath]
    ),
    opOnEngine = useCallback(
      async (engine: AIEngine, op: AIEMOpType = AIEMOpType.get, abort = false, errorMessage = defaultErrorMessage) => {
        try {
          const savedEngine = { ...engine };
          // we don't send engineType:
          if (savedEngine.engineType !== undefined) {
            delete savedEngine['engineType'];
          }
          const res = (await getEndpointCall(
            requestConfigWithParent,
            op,
            engine?.engineType || Engine_Type.DRIVERLESS_AI
          )(savedEngine as any, abort)) as EngineResponse;
          return op === AIEMOpType.delete ? {} : (res?.engine as AIEngine);
        } catch (error: any) {
          if (op !== AIEMOpType.checkId && error.code !== 9) addErrorToast(errorMessage, error as AIEMError);
          if (error.code === 9) throw error;
        }
        return undefined;
      },
      [addErrorToast, basePath]
    ),
    getConstraintSet = useCallback(
      async (nameRaw: string = defaultWorkspaceName, engineType: Engine_Type = Engine_Type.DRIVERLESS_AI) => {
        const constraintSetType = constraintSetTypeMap.get(engineType);
        const name = `${nameRaw}/${constraintSetType}`;
        const constraintSet = (await getEndpointCall(
          requestConfig,
          AIEMOpType.constraintSet,
          engineType
        )({ name } as any)) as EngineConstraintSet;
        return constraintSet;
      },
      [basePath]
    ),
    getEngineTypeData = useCallback(
      (type?: Engine_Type): BadgeData | undefined => {
        const EngineTypeMap = new Map<Engine_Type, BadgeData>([
          [
            Engine_Type.DRIVERLESS_AI,
            {
              id: Engine_Type.DRIVERLESS_AI,
              title: 'DriverlessAI',
              color: palette?.yellow900,
              backgroundColor: palette?.yellow100,
              borderLeft: `4px solid ${palette?.yellow500}`,
            },
          ],
          [
            Engine_Type.H2O,
            {
              id: Engine_Type.H2O,
              title: 'H2O',
              color: palette?.blue900,
              backgroundColor: palette?.blue100,
              borderLeft: `4px solid ${palette?.blue500}`,
            },
          ],
          [
            Engine_Type.UNSPECIFIED,
            {
              id: Engine_Type.UNSPECIFIED,
              title: 'Unspecified',
              color: palette?.white,
              backgroundColor: palette?.gray500,
              borderLeft: `4px solid ${palette?.black}`,
            },
          ],
        ]);
        return type ? EngineTypeMap.get(type) : undefined;
      },
      [palette]
    ),
    checkEngineId = useCallback(
      async (engineType?: Engine_Type, id?: string, parent = defaultWorkspaceName) => {
        if (!engineType || !id) {
          return;
        }
        const engineNameMap = {
          [Engine_Type.DRIVERLESS_AI]: 'dai',
          [Engine_Type.NOTEBOOK]: 'notebook',
          [Engine_Type.H2O]: 'h2o',
        };
        const name = `${parent}/${engineNameMap[engineType]}Engines/${id}`;

        const engine = (await opOnEngine({ engineType, name } as AIEngine, AIEMOpType.checkId)) || null;
        return !Boolean(engine);
      },
      [opOnEngine]
    ),
    engineTypeLogo: {
      [P in keyof Engine_Type as string]: {
        src: string;
        backgroundColor: string | undefined;
      };
    } = {
      [Engine_Type.DRIVERLESS_AI]: { src: '/driverlessDarkIcon.png', backgroundColor: Colors?.textPrimary },
      [Engine_Type.NOTEBOOK]: { src: '/notebookLab-logo.png', backgroundColor: '#000' },
      [Engine_Type.H2O]: { src: '/h2o3DarkLogo.png', backgroundColor: Colors?.textPrimary },
      [Engine_Type.UNSPECIFIED]: { src: '/logo512.png', backgroundColor: undefined },
    };

  return {
    EngineStateMap,
    basePath,
    getConstraintSet,
    checkEngineId,
    engineTypeLogo,
    getEngineTypeData,
    listEngines,
    listVersions,
    opOnEngine,
    searchEngines,
  };
}

const getVersionsFromParam = (param: string) => {
  const types = param.split(';');
  if (!types?.length) return {};
  const result: TypeVersionSelection = {};
  types.forEach((t) => {
    const parts = t.split('=');
    if (!parts || parts.length < 2) return;
    const [key, value] = parts;
    const values = value.split(',');
    result[key] = values.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: true,
      }),
      {}
    );
  });
  return result;
};

export const filterSummarizeSelection = (
  selection: TypeVersionSelection
): { filtered: TypeVersionSelection; summary: TypeVersionSummary } => {
  const filtered = {};
  let totalVersions = 0,
    totalSelected = 0;
  const types: { [P in keyof Engine_Type as string]: VersionsSummary } = {};
  Object.keys(selection).forEach((typeName) => {
    const versionsObj = selection[typeName] || {},
      versions: string[] = [];
    let typeTotalVersions = 0,
      typeSelectedVersions = 0,
      anySelected = false;
    Object.keys(versionsObj).forEach((v) => {
      totalVersions++;
      typeTotalVersions++;
      if (versionsObj[v]) {
        totalSelected++;
        typeSelectedVersions++;
        anySelected = true;
        versions.push(v);
        if (!filtered[typeName]) filtered[typeName] = {};
        filtered[typeName][v] = true;
      }
    });
    types[typeName] = { anySelected, totalVersions: typeTotalVersions, totalSelected: typeSelectedVersions, versions };
  });
  return { filtered, summary: { totalVersions, totalSelected, anySelected: Boolean(totalSelected), types } };
};

// manages Engine Page filter states
export function useEngineQueryParams() {
  const [queryParams, setQueryParams] = useStateQueryParams();
  const params = useMemo<EngineQueryParamsType>(
    () => ({
      searchTerm: queryParams[EngineQueryParamsKeys.search] || '',
      [EngineQueryParamsKeys.versions]: getVersionsFromParam(queryParams[EngineQueryParamsKeys.versions] || ''),
      [EngineQueryParamsKeys.states]: queryParams.states ? queryParams.states.split(',') : [],
    }),
    [queryParams]
  );
  const setSearchTermParam = useCallback((search: string) => {
    setQueryParams(({ [EngineQueryParamsKeys.search]: _omitted, ...old }) =>
      !search ? old : { ...old, [EngineQueryParamsKeys.search]: search }
    );
  }, []);
  const setStates = useCallback((states: string[]) => {
    setQueryParams(({ [EngineQueryParamsKeys.states]: _omitted, ...old }) =>
      !states.length ? old : { ...old, [EngineQueryParamsKeys.states]: states.join(',') }
    );
  }, []);
  // sanitize and cast states here
  const setTypeVersions = useCallback((value: TypeVersionSelection) => {
    const { filtered } = filterSummarizeSelection(value);
    const versions = Object.keys(filtered)
      .map((t) => `${t}=${Object.keys(filtered[t]).join(',')}`)
      .join(';');
    setQueryParams(({ [EngineQueryParamsKeys.versions]: _omitted, ...old }) => {
      return !versions ? old : { ...old, [EngineQueryParamsKeys.versions]: versions };
    });
  }, []);
  const setParams = useCallback((searchTerm: string, states: string[], versionMap: TypeVersionSelection) => {
    const { filtered } = filterSummarizeSelection(versionMap);
    const versions = Object.keys(filtered)
      .map((t) => `${t}=${Object.keys(filtered[t]).join(',')}`)
      .join(';');
    const statesParams = states.join(',');
    const nextParams = {};
    if (searchTerm) nextParams[EngineQueryParamsKeys.search] = searchTerm;
    if (versions) nextParams[EngineQueryParamsKeys.versions] = versions;
    if (statesParams) nextParams[EngineQueryParamsKeys.states] = statesParams;
    setQueryParams(() => nextParams);
  }, []);

  return { params, setParams, setSearchTermParam, setTypeVersions, setStates };
}
