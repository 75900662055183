import { IDropdownOption, MessageBarType, Stack } from '@fluentui/react';
import {
  Accordion,
  Dropdown,
  KeyValuePairSubmitOutcome,
  type KeyValuePairValidationFn,
  type KeyValueValidation,
  TextField,
  useToast,
} from '@h2oai/ui-kit';
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { defaultVersion } from '../../../../aiem/defaults';
import { useEngine } from '../../../../aiem/engine/hooks';
import { AIEMOpType, AIEngine, EngineVersion, ValidEngineType } from '../../../../aiem/engine/types';
import { DAIEngine } from '../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_pb';
import {
  DAIEngineProfile,
  DAIEngineProfile_ConfigEditability,
} from '../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import { Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { H2OEngineProfile } from '../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import { useEngineProfilesService } from '../../../../aiem/hooks';
import { ConstraintType } from '../../../../aiem/types';
import { getIdFromName } from '../../../../aiem/utils';
import { EntityModelTypes } from '../../../../pages/AIEngineSettingsPage/components/ListWithCollapsedSettings/types';
import { useFormAttributes } from '../../../../utils/utils';
import { EditablePanelFooter } from '../../../EditablePanelFooter/EditablePanelFooter';
import { H2OEngineDefaultProfiles, H2OEngineProfileOptionKeyType } from '../../constants';
import { validateId } from '../../utils';
import { AdvancedConfiguration } from '../AdvancedConfiguration/AdvancedConfiguration';
import { ConstraintInput } from '../ConstraintInput/ConstraintInput';
import { DisplayAndId } from '../DisplayAndId/DisplayAndId';
import ConfigureCustomEngineProfile from './components/ConfigureCustomEngineProfile';

export type EngineConfigurationProps = {
  op: AIEMOpType;
  engine: AIEngine;
  onSave: () => any;
  onCancel: () => any;
};

const versionKeyMap = {
  [Engine_Type.DRIVERLESS_AI]: 'daiEngineVersion',
  [Engine_Type.NOTEBOOK]: 'notebookImage',
  [Engine_Type.H2O]: 'h2oEngineVersion',
};
const versionInfoKeyMap = {
  [Engine_Type.DRIVERLESS_AI]: 'daiEngineVersionInfo',
  [Engine_Type.NOTEBOOK]: 'notebookImageInfo',
  [Engine_Type.H2O]: 'h2oEngineVersionInfo',
};

export function EngineConfiguration({ op, engine, onCancel, onSave }: EngineConfigurationProps) {
  const engineProfilesService = useEngineProfilesService();
  const { opOnEngine } = useEngine();
  const { inputContainerProps, inputRowProps, sliderContainerProps, sliderFormRowProps } = useFormAttributes();
  const { listVersions } = useEngine();
  const { addToast } = useToast();

  const createSuccessToast = useCallback(
    (displayName: string, op: AIEMOpType) => {
      addToast({
        messageBarType: MessageBarType.success,
        message:
          op === AIEMOpType.create
            ? `Engine "${displayName}" has been successfully created and it is now starting.`
            : `Engine "${displayName}" has been successfully updated.`,
      });
    },
    [addToast]
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [engineState, setEngineState] = useState<AIEngine>({ ...engine });
  const [selectedProfileOption, setSelectedProfileOption] = useState<EntityModelTypes>();
  const [selectedVersion, setSelectedVersion] = useState<string>(defaultVersion);
  const [versions, setVersions] = useState<IDropdownOption[]>([]);
  const isNewOrUpdated = useCallback((): boolean => {
    if (op === AIEMOpType.create) {
      return true;
    }
    const { profileInfo: engineProfileInfo, ...restEngine } = engine;
    const { profileInfo, ...restEngineState } = engineState;
    return JSON.stringify(restEngine) !== JSON.stringify(restEngineState);
  }, [engine, engineState]);
  const advancedConfigurationRef = useRef<any>();
  const waitingForEngineStateUpdate = useRef<boolean>(false);
  const [profileOptions, setProfileOptions] = useState<IDropdownOption[]>([]);
  const [selectedConfigurationOption, setSelectedConfigurationOption] = useState<IDropdownOption>(
    H2OEngineDefaultProfiles[0]
  );
  const engineType = engine?.engineType;
  const isDAIEngine = engineType === Engine_Type.DRIVERLESS_AI;
  const isNotebookEngine = engineType === Engine_Type.NOTEBOOK;
  const isH2OEngine = engineType === Engine_Type.H2O;
  const versionKey = versionKeyMap[engineType || Engine_Type.DRIVERLESS_AI];
  const versionInfoKey = versionInfoKeyMap[engineType || Engine_Type.DRIVERLESS_AI];
  const isConfigEditabilityDisabled =
    (selectedProfileOption as DAIEngineProfile)?.configEditability === DAIEngineProfile_ConfigEditability.DISABLED;
  const isH2ODefaultProfileSelected =
    selectedConfigurationOption.key === H2OEngineProfileOptionKeyType.compressed ||
    selectedConfigurationOption.key === H2OEngineProfileOptionKeyType.raw;
  const isEngineValid = useMemo(() => {
    const { displayName, id, memoryBytes } = engineState;
    const version = engineState[versionKey];

    if (!selectedProfileOption) return false;

    if (op === AIEMOpType.create) {
      const containsValues = Boolean(displayName && validateId(id) && version && memoryBytes);
      const { memoryBytesConstraint } = selectedProfileOption;
      const validMemoryConstraintMin = Number(memoryBytes) >= Number(memoryBytesConstraint.min);
      const validMemoryConstraintMax =
        !memoryBytesConstraint?.max || Number(memoryBytes) <= Number(memoryBytesConstraint?.max);

      return containsValues && validMemoryConstraintMin && validMemoryConstraintMax;
    } else {
      return Boolean(displayName && version);
    }
  }, [engineState, selectedProfileOption]);
  const isProfileInList = profileOptions.some((option) => option.key === selectedProfileOption?.name);

  const updateEngine = (eng: Partial<AIEngine>) => setEngineState({ ...engineState, ...eng });
  const handleVersionChange = (_e: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    setSelectedVersion(option?.key as string);
    updateEngine({
      [versionKey]: option?.key as string,
      [versionInfoKey]: option?.data,
    });
  };
  const onDisplayNameChange = (value: string) => updateEngine({ displayName: value });
  const onIdChange = (value: string) => updateEngine({ id: value });
  const saveEngine = async () => {
    if (op === AIEMOpType.create) {
      const res = await opOnEngine(engineState, AIEMOpType.create, undefined, undefined);
      if (res) {
        onSave();
        createSuccessToast(String(engineState!.displayName), AIEMOpType.create);
      } else {
        onCancel();
      }
    } else {
      const res = await opOnEngine(engineState, AIEMOpType.update, undefined, undefined);
      if (res) {
        onSave();
        createSuccessToast(String(engineState!.displayName), AIEMOpType.update);
      } else {
        onCancel();
      }
    }
  };

  const getEngineFromConstraintSet = (constraints: EntityModelTypes): Partial<AIEngine> => {
    return op === AIEMOpType.create
      ? {
          cpu: Number(constraints?.cpuConstraint?.default),
          gpu: Number(constraints?.gpuConstraint?.default),
          memoryBytes: constraints?.memoryBytesConstraint?.default,
          storageBytes: (constraints as DAIEngineProfile)?.storageBytesConstraint?.default,
          maxIdleDuration: String(constraints?.maxIdleDurationConstraint?.default),
          maxRunningDuration: String(constraints?.maxRunningDurationConstraint?.default),
          config: (constraints as DAIEngineProfile)?.baseConfiguration,
          ...(isH2OEngine
            ? { nodeCount: Number((constraints as H2OEngineProfile)?.nodeCountConstraint?.default) }
            : {}),
        }
      : {
          cpu: Number(engine.cpu),
          gpu: Number(engine.gpu),
          memoryBytes: engine.memoryBytes,
          storageBytes: engine.storageBytes,
          maxIdleDuration: engine.maxIdleDuration,
          maxRunningDuration: engine.maxRunningDuration,
          ...(isDAIEngine ? { config: (engine as DAIEngine).config } : {}),
          ...(isH2OEngine ? { nodeCount: Number(engine.nodeCount) } : {}),
        };
  };
  const loadVersions = useCallback(async (): Promise<EngineVersion | undefined> => {
    let defaultVersion;
    const versions = await listVersions(engineType as ValidEngineType),
      options =
        versions?.map((version: EngineVersion) => {
          const { key, name: text, isDefault } = version;
          if ((isDefault || isNotebookEngine) && key) {
            setSelectedVersion(key);
            defaultVersion = version;
          }
          return { key, text, data: version } as IDropdownOption;
        }) || [];
    setVersions(options);
    return defaultVersion;
  }, [listVersions, setSelectedVersion, setVersions]);
  const fetchProfileOptions = async (): Promise<EntityModelTypes | null> => {
    const responseKeyMap = {
      [Engine_Type.DRIVERLESS_AI]: 'daiEngineProfiles',
      [Engine_Type.H2O]: 'h2oEngineProfiles',
      [Engine_Type.NOTEBOOK]: 'notebookEngineProfiles',
    };
    const listEnginesMap = {
      [Engine_Type.DRIVERLESS_AI]: (params: any) => engineProfilesService.listAssignedDAIEngineProfiles(params),
      [Engine_Type.H2O]: (params: any) => engineProfilesService.listAssignedH2OEngineProfiles(params),
      [Engine_Type.NOTEBOOK]: (params: any) => engineProfilesService.listNotebookEngineProfiles(params),
    };
    let activeProfile: IDropdownOption;

    try {
      const responseKey = responseKeyMap[engineType || Engine_Type.DRIVERLESS_AI];
      const requestParams = { parent: 'workspaces/global', pageSize: 1000 };
      const response = await listEnginesMap[engineType || Engine_Type.DRIVERLESS_AI](requestParams);
      const enabledProfileOptions = (response[responseKey] || []).filter(
        (profile: DAIEngineProfile | H2OEngineProfile) => profile?.enabled
      );

      const profileMapper = (profile: DAIEngineProfile | H2OEngineProfile, showId = false) => ({
        key: profile.name || '',
        text: `${profile.displayName} ${showId ? `(${getIdFromName(profile.name)})` : ''}`,
        data: profile,
      });

      const profileOptions = enabledProfileOptions.map((profile: DAIEngineProfile | H2OEngineProfile) =>
        profileMapper(profile, true)
      );

      if (op === AIEMOpType.create) {
        activeProfile = profileOptions[0];
      } else {
        activeProfile = profileOptions.find((profile: IDropdownOption) => profile.key === engine.profile) ?? {
          data: { name: engine.profile },
        };
      }

      setProfileOptions(profileOptions);
      handleProfileOptionSelect(undefined, activeProfile.data);

      return activeProfile.data;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      return null;
    }
  };
  const prepareConfiguration = async () => {
    setIsLoading(true);
    try {
      const loadedSet = await fetchProfileOptions();
      if (!loadedSet) return;
      const defaultEngine = getEngineFromConstraintSet(loadedSet);

      if (op === AIEMOpType.create && engine.type !== Engine_Type.NOTEBOOK) {
        const defaultVersion = await loadVersions();
        updateEngine({
          ...defaultEngine,
          profile: loadedSet.name,
          [versionKey]: defaultVersion?.key,
          [versionInfoKey]: defaultVersion,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleProfileOptionSelect = (_: any, option: EntityModelTypes) => {
    setSelectedProfileOption(option);
    handleConfigurationOptionSelect(undefined, H2OEngineDefaultProfiles[0]);
  };
  const handleConfigurationOptionSelect = (_: any, option?: IDropdownOption) => {
    if (!option) return;
    setSelectedConfigurationOption(option);
    selectedProfileOption &&
      updateEngine({
        ...getEngineFromConstraintSet(selectedProfileOption),
      });
  };
  const validateAdvancedConfiguration: KeyValuePairValidationFn = ({ key, value }): KeyValueValidation => {
    const message: KeyValueValidation = {};
    const { configEditability, configurationOverride } = selectedProfileOption as DAIEngineProfile;

    if (configEditability !== DAIEngineProfile_ConfigEditability.FULL) return message;

    if (configurationOverride?.hasOwnProperty(key || '')) {
      message.keyValidation = `"${key}" key already exists in the configuration_override.`;
    }
    if (!key) {
      message.keyValidation = 'The key may not be empty.';
    }
    if (!value) {
      message.valueValidation = 'The value may not be empty.';
    }
    return message;
  };

  const onClickSave = useCallback(() => {
    if (advancedConfigurationRef.current) {
      waitingForEngineStateUpdate.current = true;
      const saveOutcome = advancedConfigurationRef.current.submitKeyValue();
      switch (saveOutcome) {
        case KeyValuePairSubmitOutcome.UNSUCCESSFUL:
          waitingForEngineStateUpdate.current = false;
          saveEngine();
          break;
        case KeyValuePairSubmitOutcome.SUCCESSFUL:
          waitingForEngineStateUpdate.current = true;
          break;
        case KeyValuePairSubmitOutcome.INVALID:
          waitingForEngineStateUpdate.current = false;
          break;
        default:
          saveEngine();
      }
    } else {
      void saveEngine();
    }
  }, [saveEngine, advancedConfigurationRef.current, waitingForEngineStateUpdate.current]);

  useEffect(() => {
    if (waitingForEngineStateUpdate.current) {
      waitingForEngineStateUpdate.current = false;
      void saveEngine();
    }
  }, [engineState, selectedProfileOption]);

  useEffect(() => {
    if (selectedProfileOption && op === AIEMOpType.create) {
      const newEngineState = {
        ...getEngineFromConstraintSet(selectedProfileOption),
        ...(!isH2ODefaultProfileSelected ? { profile: selectedProfileOption.name } : {}),
      } as AIEngine;
      updateEngine(newEngineState);
    }

    if (selectedProfileOption && op !== AIEMOpType.create) {
      const newEngineState = {
        profile: selectedProfileOption.name,
        profileInfo: undefined,
        ...getEngineFromConstraintSet(selectedProfileOption),
      } as AIEngine;
      updateEngine(newEngineState);
    }
  }, [selectedProfileOption]);

  useEffect(() => {
    void prepareConfiguration();
  }, []);

  return (
    <div style={{ paddingBottom: '50px' }}>
      <form>
        <Stack>
          <DisplayAndId
            engine={engine}
            onDisplayNameChange={onDisplayNameChange}
            onIdChange={onIdChange}
            editableId={op === AIEMOpType.create}
          />
          <Stack {...inputRowProps}>
            <Stack {...inputContainerProps}>
              <Dropdown
                label="Profile"
                selectedKey={selectedProfileOption?.name}
                options={profileOptions}
                onChange={(_, option) => handleProfileOptionSelect(undefined, option?.data)}
              />
            </Stack>
          </Stack>
          {!isLoading && profileOptions.length === 0 && (
            <Stack>
              <p style={{ margin: '-8px 0 20px', color: 'var(--h2o-red500, #ba2525)' }}>
                There are no available profiles to chose.
              </p>
            </Stack>
          )}
          {!isLoading && !isProfileInList && (
            <Stack>
              <p style={{ margin: '-8px 0 20px', color: 'var(--h2o-red500, #ba2525)' }}>
                Profile &quot;{getIdFromName(selectedProfileOption?.name)}&quot; is disabled or deleted. Please select a
                different profile.
              </p>
            </Stack>
          )}
          {selectedProfileOption && (
            <Stack>
              {isH2OEngine && (
                <Stack {...inputRowProps}>
                  <Stack {...inputContainerProps}>
                    <Dropdown
                      label="Dataset Parameters"
                      selectedKey={selectedConfigurationOption?.key}
                      options={H2OEngineDefaultProfiles}
                      onChange={(_, option) => handleConfigurationOptionSelect(undefined, option)}
                    />
                  </Stack>
                </Stack>
              )}
              {(op === AIEMOpType.create || op === AIEMOpType.view) && (
                <Stack {...inputRowProps}>
                  <Stack {...inputContainerProps}>
                    {op === AIEMOpType.create && (
                      <Dropdown
                        label={isNotebookEngine ? 'Image' : 'Version'}
                        placeholder={`Select a${isNotebookEngine ? 'n Image' : ' Version'}`}
                        options={versions}
                        selectedKey={selectedVersion}
                        onChange={handleVersionChange}
                      />
                    )}
                    {op === AIEMOpType.view && (
                      <TextField
                        name="version"
                        label="Version"
                        value={engineState?.version}
                        onChange={() => null}
                        readOnly
                      />
                    )}
                  </Stack>
                </Stack>
              )}
              <ConfigureCustomEngineProfile
                selectedConfigurationOption={selectedConfigurationOption}
                engine={engineState}
                constraintSet={selectedProfileOption}
                modifyEngine={updateEngine}
                operationCreate={op === AIEMOpType.create}
              />
              <Accordion title="Timeout Configuration" isClose styles={{ title: { fontWeight: 400 } }}>
                <Stack {...sliderFormRowProps} styles={{ root: { width: 400, height: 77 } }}>
                  <Stack {...sliderContainerProps}>
                    <ConstraintInput
                      constraintType={ConstraintType.MAXIDLEDURATION}
                      constraint={selectedProfileOption?.maxIdleDurationConstraint}
                      value={engineState?.[ConstraintType.MAXIDLEDURATION]}
                      onChange={(_event, value) => {
                        const fieldName = ConstraintType.MAXIDLEDURATION as string;
                        updateEngine({ [fieldName]: value });
                      }}
                      label="Max Idle Time (Hours)"
                      tooltip="Specify the maximum idle time of the Driverless AI instance. Instance will pause if it is idle for longer
                    than max idle time. When the instance pauses, it can be started again."
                    />
                  </Stack>
                </Stack>
                <Stack {...sliderFormRowProps} styles={{ root: { width: 400, height: 77 } }}>
                  <Stack {...sliderContainerProps}>
                    <ConstraintInput
                      label="Max Up Time (Hours)"
                      constraintType={ConstraintType.MAXRUNNINGDURATION}
                      constraint={selectedProfileOption?.maxRunningDurationConstraint}
                      value={engineState?.[ConstraintType.MAXRUNNINGDURATION]}
                      onChange={(_event, value) => {
                        const fieldName = ConstraintType.MAXRUNNINGDURATION as string;
                        updateEngine({ [fieldName]: value });
                      }}
                      tooltip="Set the duration after which the instance automatically pauses. When the instance pauses, it can be started again."
                    />
                  </Stack>
                </Stack>
              </Accordion>

              {isDAIEngine && !isConfigEditabilityDisabled && (
                <Accordion
                  title="Advanced Configuration (config.toml)"
                  styles={{
                    title: { fontWeight: 400 },
                    root: { marginTop: 12, marginBottom: 40, height: 'unset !important' },
                  }}
                >
                  <AdvancedConfiguration
                    validation={validateAdvancedConfiguration}
                    editOnly={
                      (selectedProfileOption as DAIEngineProfile)?.configEditability ===
                      DAIEngineProfile_ConfigEditability.BASE_CONFIG_ONLY
                    }
                    ref={advancedConfigurationRef}
                    config={(engineState as DAIEngine).config || {}}
                    onConfigChange={(updatedConfig) => {
                      updateEngine({ config: updatedConfig });
                    }}
                  />
                </Accordion>
              )}
            </Stack>
          )}
        </Stack>
      </form>
      <EditablePanelFooter
        onCancel={onCancel}
        onSave={onClickSave}
        closeButtonText={op === AIEMOpType.create ? 'Back' : 'Close'}
        saveButtonText={op === AIEMOpType.create ? `Create` : `Save`}
        saveButtonDisabled={!selectedProfileOption || !isEngineValid || !isNewOrUpdated()}
      />
    </div>
  );
}
